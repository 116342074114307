import React from 'react';
import { useApolloClient } from '@apollo/client';
import DragAndDrop, { DragAndDropProps } from '../DragAndDrop';
import * as GENERATE_SIGNED_URL from './GeneratePostFileSignedUrl.graphql';
import {
  GeneratePostFileSignedUrl,
  GeneratePostFileSignedUrlVariables,
} from './__generated__/GeneratePostFileSignedUrl';

type PostFileFieldProps = Omit<DragAndDropProps, 'generateSignedUrl'>;

const PostFileField = (props: PostFileFieldProps) => {
  const client = useApolloClient();

  const fetchFileUrl = async (fileName: string) => {
    const { data } = await client.query<GeneratePostFileSignedUrl, GeneratePostFileSignedUrlVariables>({
      query: GENERATE_SIGNED_URL,
      variables: { fileName },
    });

    return data.generatePostFileSignedUrl;
  };

  return <DragAndDrop generateSignedUrl={fetchFileUrl} {...props} />;
};

export default PostFileField;
