import { v4 as uuidv4 } from 'uuid';
import { useQuery } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import GET_RESPONDENT_COUNT from '@src/components/organisms/FormReport/GetFormRespondentsCount.graphql';
import {
  GetFormRespondentsCount,
  GetFormRespondentsCountVariables,
} from '@src/components/organisms/FormReport/__generated__/GetFormRespondentsCount';
import { FormStatus } from '@src/__generated__/globalTypes';
import GET_FORM from './queries/GetForm.graphql';
import { GetForm, GetFormVariables } from './queries/__generated__/GetForm';

interface UseGetFormProps {
  formId: string;
}

const useGetForm = ({ formId }: UseGetFormProps) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  const { data, loading } = useQuery<GetForm, GetFormVariables>(GET_FORM, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        id: formId,
      },
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const { data: dataRespondentCount } = useQuery<GetFormRespondentsCount, GetFormRespondentsCountVariables>(
    GET_RESPONDENT_COUNT,
    {
      variables: {
        input: {
          id: Number(formId),
        },
      },
      onError: error => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
    }
  );

  const description = data?.getForm?.description || '';
  const hash = data?.getForm?.hash || '';
  const id = data?.getForm?.id || '';
  const questions =
    data?.getForm?.questions.map(question => {
      const { id: questionId, image, isRequired, options, order, questionType, title: questionTitle } = question;

      return {
        id: questionId,
        genId: uuidv4(),
        image,
        isRequired,
        options,
        order,
        questionType,
        title: questionTitle,
      };
    }) || [];
  const respondentCount = dataRespondentCount?.getFormRespondentsCount?.total || 0;
  const status = data?.getForm?.status || FormStatus.DRAFT;
  const thankDescription = data?.getForm?.thankDescription || '';
  const thankTitle = data?.getForm?.thankTitle || '';
  const title = data?.getForm?.title || '';

  return {
    description,
    hash,
    id,
    isLoading: loading,
    questions,
    respondentCount,
    status,
    thankDescription,
    thankTitle,
    title,
  };
};

export default useGetForm;
