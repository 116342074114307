import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import InitialLoading from '@src/components/molecules/InitialLoading';
import { getCallbackUrl } from '@src/libs/auth';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { ErrorTypes } from '@src/libs/error';
import { useQueryHelper } from '@src/libs/hooks';
import { FlowTypes } from '@src/pages/LinkInBio/helpers';
import { linkInBioState as linkInBioRecoilState, useRecoilState } from '@src/recoilAtoms';
import { ROUTES } from '@src/shared/routes';
import { BioSectionType, BioSocialMediaType, SocialAccountType } from '@src/__generated__/globalTypes';
import { useAuthProviderResponse } from '../hooks';
import useLinkInBioConnect from './useLinkInBioConnect';

interface Account {
  id: string;
  name: string;
  socialAccountType: SocialAccountType;
  url: string;
}

const LinkInBioConnect = () => {
  const [linkInBioState, setLinkInBioState] = useRecoilState(linkInBioRecoilState);
  const { enqueueSnackbar, history, t } = useQueryHelper();
  const { provider, response } = useAuthProviderResponse(ROUTES.LINK_IN_BIO);
  const { socialAuthConnect, socialAuthReconnect } = useLinkInBioConnect();

  useEffect(() => {
    connect();
  }, []);

  const addSections = ({ id, name, socialAccountType, url }: Account) => {
    const sections = [...(linkInBioState.sections || [])];
    const nextOrder = sections.length + 1;
    sections.push({
      genId: uuidv4(),
      order: nextOrder,
      sectionType: BioSectionType.SOCIAL_MEDIA,
      socialMedia: {
        socialMedia: socialAccountType === SocialAccountType.INSTAGRAM ? BioSocialMediaType.INSTAGRAM : provider,
        linkUrl: url,
        linkName: name,
        socialAccountId: id,
        socialAccountType,
      },
    });

    setLinkInBioState({ ...linkInBioState, sections });
  };

  const connect = async () => {
    const callbackUrl = getCallbackUrl(provider, FE_REDIRECT_MAPPING.LINK_IN_BIO_CONNECT);

    if (linkInBioState.socialAccountId && linkInBioState.socialAccountType) {
      await socialAuthReconnect({
        variables: {
          input: {
            callbackUrl,
            id: String(linkInBioState.socialAccountId),
            response,
            socialAccountType: linkInBioState.socialAccountType,
          },
        },
      })
        .then(result => {
          const accounts = result.data?.anyXSocialAuthReconnectForLinkBio?.accounts;
          if (accounts) {
            const selectedAccount = accounts.find(
              account =>
                account.id === String(linkInBioState.socialAccountId) &&
                account.socialAccountType === linkInBioState.socialAccountType
            );
            if (selectedAccount) {
              addSections(selectedAccount);
            }
            history.push(ROUTES.LINK_IN_BIO);
          }
        })
        .catch(err => {
          if ([ErrorTypes.FB_REQUIRED_PERMISSION_MISSING, ErrorTypes.VIDEO_PERMISSION_MISSING].includes(err.message)) {
            setLinkInBioState({ currentFlow: FlowTypes.MY_LINK_IN_BIO });
            switch (err.message) {
              case ErrorTypes.FB_REQUIRED_PERMISSION_MISSING:
                history.push(ROUTES.SETTINGS_SOCIAL_CONNECT_FACEBOOK_GRANT_PERMISSION);
                break;
              default:
                history.push(
                  `${ROUTES.SETTINGS_SOCIAL_CONNECT_TIKTOK_GRANT_PERMISSION}?id=${linkInBioState.socialAccountId}`
                );
            }
          } else {
            enqueueSnackbar(t(err.message), { variant: 'error' });
            history.push(ROUTES.LINK_IN_BIO);
          }
        });
    } else {
      await socialAuthConnect({
        variables: {
          input: {
            callbackUrl,
            provider,
            response,
          },
        },
      })
        .then(result => {
          const connectedAccounts = result.data?.anyXSocialAuthConnectForLinkBio?.connectedAccounts[0];
          if (connectedAccounts) {
            addSections(connectedAccounts);
          }
          history.push(ROUTES.LINK_IN_BIO);
        })
        .catch(err => {
          if ([ErrorTypes.FB_REQUIRED_PERMISSION_MISSING, ErrorTypes.VIDEO_PERMISSION_MISSING].includes(err.message)) {
            setLinkInBioState({ currentFlow: FlowTypes.MY_LINK_IN_BIO });
            switch (err.message) {
              case ErrorTypes.FB_REQUIRED_PERMISSION_MISSING:
                history.push(ROUTES.SETTINGS_SOCIAL_CONNECT_FACEBOOK_GRANT_PERMISSION);
                break;
              default:
                history.push(ROUTES.SETTINGS_SOCIAL_CONNECT_TIKTOK_GRANT_PERMISSION);
            }
          } else {
            enqueueSnackbar(t(err.message), { variant: 'error' });
            history.push(ROUTES.LINK_IN_BIO);
          }
        });
    }
  };

  return <InitialLoading />;
};

export default LinkInBioConnect;
