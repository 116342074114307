import { ApolloError } from '@apollo/client';
import { ExecutionResult } from 'graphql/execution';

export const UNEXPECTED_ERROR = 'UnexpectedError';
export const FAILED_TO_UPDATE = 'failedToUpdate';

export const getMessagesFromApolloError = (e: ApolloError): string[] => e.graphQLErrors.map(error => error.message);
export const getMessagesFromFetchResult = (e: ExecutionResult | void): string[] =>
  e && e.errors ? e.errors.map(error => error.message) : [UNEXPECTED_ERROR];

export enum ErrorTypes {
  CAMPAIGN_CLOSED_OUT_OF_COUPON = 'CAMPAIGN_CLOSED_OUT_OF_COUPON',
  FB_REQUIRED_PERMISSION_MISSING = 'FB_REQUIRED_PERMISSION_MISSING',
  QUOTA_EXCEED_LIMIT_FOR_BROADCAST = 'QUOTA_EXCEED_LIMIT_FOR_BROADCAST',
  VIDEO_PERMISSION_MISSING = 'VIDEO_PERMISSION_MISSING',
}
