import { useMutation } from '@apollo/client';
import SOCIAL_AUTH_RECONNECT from './mutation/AnyXSocialAuthReconnectTtcm.graphql';
import {
  AnyXSocialAuthReconnectTtcm,
  AnyXSocialAuthReconnectTtcmVariables,
} from './mutation/__generated__/AnyXSocialAuthReconnectTtcm';

const useTtcmReconnect = () => {
  const [socialAuthReconnectTtcm] = useMutation<AnyXSocialAuthReconnectTtcm, AnyXSocialAuthReconnectTtcmVariables>(
    SOCIAL_AUTH_RECONNECT
  );

  return {
    socialAuthReconnectTtcm,
  };
};

export default useTtcmReconnect;
