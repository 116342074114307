import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDeepCompareEffect, useQueryHelper } from '@src/libs/hooks';
import { UpdateUserDetailInput } from '@src/__generated__/globalTypes';
import UPDATE_USER_DETAIL from './mutations/UpdateUserDetail.graphql';
import { UpdateUserDetail, UpdateUserDetailVariables } from './mutations/__generated__/UpdateUserDetail';
import UserDetail from './UserDetail';
import GET_USER_DETAIL from './queries/GetUserDetail.graphql';
import { GetUserDetail, GetUserDetailVariables } from './queries/__generated__/GetUserDetail';

const Index = () => {
  const initialUserDetail = {
    userId: 0,
    name: '',
    email: '',
  } as UpdateUserDetailInput;
  const [userDetail, setUserDetail] = useState<UpdateUserDetailInput>(initialUserDetail);
  const { enqueueSnackbar, params, t } = useQueryHelper();
  const { userId } = params;

  const validationSchema = yup.object().shape({
    name: yup.string().required('requiredNameMessage'),
    email: yup.string().email('invalidEmailMessage').required('requiredEmailMessage'),
  });

  const methods = useForm<UpdateUserDetailInput>({
    defaultValues: userDetail,
    resolver: yupResolver(validationSchema),
  });

  useDeepCompareEffect(() => {
    methods.reset(userDetail);
  }, [userDetail]);

  const [updateUserDetail] = useMutation<UpdateUserDetail, UpdateUserDetailVariables>(UPDATE_USER_DETAIL, {
    onCompleted: () => {
      enqueueSnackbar(t('succeededInUpdating'), { variant: 'success' });
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  useQuery<GetUserDetail, GetUserDetailVariables>(GET_USER_DETAIL, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        userId: Number(userId),
      },
    },
    onCompleted: ({ getUserDetail }) => {
      if (getUserDetail) {
        const { email, name } = getUserDetail;
        setUserDetail({
          email: email || '',
          name,
          userId: Number(userId),
        });
      }
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const onSubmit = async (values: UpdateUserDetailInput) => {
    updateUserDetail({
      variables: {
        input: values,
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <UserDetail />
      </form>
    </FormProvider>
  );
};

export default Index;
