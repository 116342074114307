import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import OnlyAvailableOnWebIndicator from '@src/components/molecules/OnlyAvailableOnWebIndicator';
import { useDeepCompareEffect, usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { ROUTES } from '@src/shared/routes';
import Form, { dateFormat, FormInformation } from './Form';
import ADD_EMAIL_TEMPLATE from './mutations/AddEmailTemplate.graphql';
import { AddEmailTemplate, AddEmailTemplateVariables } from './mutations/__generated__/AddEmailTemplate';
import UPDATE_EMAIL_TEMPLATE from './mutations/UpdateEmailTemplate.graphql';
import { UpdateEmailTemplate, UpdateEmailTemplateVariables } from './mutations/__generated__/UpdateEmailTemplate';

interface IndexProps extends FormInformation {
  backUrl?: string;
}

const Index = ({ backUrl, fans, id, isDraft, message, scheduleDate, sender, sendNow, subject, tags }: IndexProps) => {
  const { enqueueSnackbar, history, t } = useQueryHelper();
  const { isMobileView } = usePageLayout();

  const defaultValues = {
    fans,
    id,
    isDraft,
    message,
    scheduleDate,
    sender,
    sendNow,
    subject,
    tags,
  };
  const validationSchema = yup.object().shape(
    {
      fans: yup.array().when('tags', {
        is: items => items && items.length <= 0,
        then: yup.array().required('requiredRecipient'),
      }),
      message: yup.string().required('requiredMessage'),
      scheduleDate: yup.string().when('sendNow', {
        is: false,
        then: yup.string().required('requiredScheduleDateMessage'),
      }),
      sender: yup.string().email().required('requiredEmailMessage'),
      subject: yup.string().required('requiredSubjectMessage'),
      tags: yup.array().when('fans', {
        is: items => items && items.length <= 0,
        then: yup.array().required('requiredRecipient'),
      }),
    },
    [['fans', 'tags']]
  );
  const methods = useForm<FormInformation>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  useDeepCompareEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues]);

  const [addEmail] = useMutation<AddEmailTemplate, AddEmailTemplateVariables>(ADD_EMAIL_TEMPLATE, {
    onCompleted: () => {
      enqueueSnackbar(t('succeededInCreating'), { variant: 'success' });
      sendAmplitudeEvent(eventTypes.composeEmail);
      history.push(ROUTES.EMAILS);
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const [updateEmail] = useMutation<UpdateEmailTemplate, UpdateEmailTemplateVariables>(UPDATE_EMAIL_TEMPLATE, {
    refetchQueries: ['GetEmailTemplate'],
    onCompleted: () => {
      enqueueSnackbar(t('succeededInUpdating'), { variant: 'success' });
      sendAmplitudeEvent(eventTypes.editEmail, { emailId: id });
      history.push(ROUTES.EMAILS);
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const onSubmit = async (values: FormInformation) => {
    if (!values.id) {
      await addEmail({
        variables: { input: { ...values, scheduleDate: values.sendNow ? null : values.scheduleDate } },
      });
    } else {
      await updateEmail({
        variables: { input: { ...values, id: values.id, scheduleDate: values.sendNow ? null : values.scheduleDate } },
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {isMobileView ? <OnlyAvailableOnWebIndicator to={backUrl || ''} /> : <Form onSubmit={onSubmit} />}
      </form>
    </FormProvider>
  );
};

export default Index;
export { dateFormat };
