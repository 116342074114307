import { css } from '@emotion/react';
import { ReactNode } from 'react';

interface MenuItemProps {
  children: ReactNode;
  onClick: () => void;
  className?: string;
}
export const MenuItem = ({ children, ...restProps }: MenuItemProps) => (
  <div css={styles.menuItem} {...restProps}>
    {children}
  </div>
);

const styles = {
  menuItem: css`
    display: flex;
    align-items: center;
    padding: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;

    &:hover {
      background: #f6f8fa;
    }
  `,
};
