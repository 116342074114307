import { ComponentProps, Fragment } from 'react';
import { Drawer as DrawerUI, DialogPrimitives } from '@anymindgroup/any-ui-react';
import { css, Global } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useDirLayout } from '@src/libs/hooks';

export const Drawer = (props: ComponentProps<typeof DrawerUI>) => {
  const { t } = useTranslation();
  const { isRtl, dir } = useDirLayout();

  return (
    <Fragment>
      <DrawerUI
        {...props}
        execText={props.execText || t('OK')}
        cancelText={props.cancelText || t('Cancel')}
        side={isRtl ? 'left' : 'right'}
        dir={dir}
      />
      <Global
        styles={css`
          .close-dialog {
            cursor: pointer;
          }
        `}
      />
    </Fragment>
  );
};

export { DialogPrimitives };
