import { useApolloClient, useMutation } from '@apollo/client';
import { downloadFile } from '@src/libs/requests/downloadFile';
import { CRM_URL } from '@src/libs/requests';
import { useQueryHelper } from '@src/libs/hooks';
import axios from 'axios';
import { RemoveFans, RemoveFansVariables } from './__generated__/RemoveFans';
import * as REMOVE_FANS from './RemoveFans.graphql';
import { ImportFans, ImportFansVariables } from './__generated__/ImportFans';
import * as IMPORT_FANS from './ImportFans.graphql';
import { GenerateImportFansUploadURL } from './__generated__/GenerateImportFansUploadURL';
import * as GENERATE_IMPORT_FANS_UPLOAD_URL from './GenerateImportFansUploadURL.graphql';

export default () => {
  const client = useApolloClient();
  const { t, enqueueSnackbar } = useQueryHelper();
  const [removeFansMutation] = useMutation<RemoveFans, RemoveFansVariables>(REMOVE_FANS, {
    refetchQueries: ['GetFans'],
  });

  const [importFansMutation] = useMutation<ImportFans, ImportFansVariables>(IMPORT_FANS, {
    refetchQueries: ['GetFans'],
  });

  const downloadSampleFanCSV = async () => {
    const url = new URL(`${CRM_URL}fans/template`);

    const { error } = await downloadFile({
      url,
      filenamePrefix: `manual-upload-template fans`,
    });

    if (error) {
      enqueueSnackbar(t('failedToDownload'), { variant: 'error' });
    }
  };

  const exportFanCSV = async (ids: Set<number>) => {
    const param = ids.size > 0 ? Array.from(ids).join(',') : '*';
    const url = new URL(`${CRM_URL}fans/export/?ids=${param}`);

    await downloadFile({ url, filenamePrefix: 'fans', extension: '.csv' });
  };

  const fetchFileUrl = async () => {
    const { data } = await client.query<GenerateImportFansUploadURL>({
      query: GENERATE_IMPORT_FANS_UPLOAD_URL,
    });

    return data?.generateImportFansUploadURL || null;
  };

  const uploadSelectedFile = async (acceptedFile: File) => {
    const signedUrlResp = await fetchFileUrl();

    if (signedUrlResp) {
      uploadFileToGCSAndOurBackend(signedUrlResp.signedUrl, acceptedFile);
    }
  };

  const uploadFileToGCSAndOurBackend = async (signedUrl: string, accepted: File) => {
    try {
      await axios(signedUrl, {
        method: 'PUT',
        data: accepted,
      });
      const urlObjFromSignedUrl = new URL(signedUrl);
      // full path url without search
      const uploadFileUrl = urlObjFromSignedUrl.origin + urlObjFromSignedUrl.pathname;
      await importFansMutation({
        variables: { input: { uploadFileUrl } },
      });
      enqueueSnackbar(t('Your CSV file was successfully uploaded'), {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(t(error?.message || 'fileUploadFail'), { variant: 'error' });
    }
  };

  return {
    removeFansMutation,
    downloadSampleFanCSV,
    exportFanCSV,
    uploadSelectedFile,
  };
};
