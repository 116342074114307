import { useQuery } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import * as GET_LINE_CHANNELS from '@src/libs/queries/GetLineChannels.graphql';
import { GetLineChannels } from '@src/libs/queries/__generated__/GetLineChannels';
import { useState } from 'react';

export type LineConnectStatus = 'pending' | 'connectLine' | 'connected';

const useGetLineChannels = () => {
  const { enqueueSnackbar, t } = useQueryHelper();
  const [lineConnectStatus, setLineConnectStatus] = useState<LineConnectStatus>('pending');

  const { data, loading } = useQuery<GetLineChannels>(GET_LINE_CHANNELS, {
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
    onCompleted: ({ getLineChannels }) => {
      if (!getLineChannels?.id) {
        setLineConnectStatus('connectLine');
      } else {
        setLineConnectStatus('connected');
      }
    },
  });

  return {
    data,
    loading,
    lineConnectStatus,
    hasNoLineConnected: lineConnectStatus !== 'connected',
  };
};

export default useGetLineChannels;
