import { InfluencerType } from '@src/__generated__/globalTypes';
import { useTranslation } from 'react-i18next';
import { useAuthData } from './hooks';

export const useHelpCenterUrl = () => {
  const { i18n } = useTranslation();
  const { creatorType } = useAuthData();
  const isProInfluencer = creatorType === InfluencerType.PRO;

  // help links for EN Normal & PRO influencer will be default fallback
  const helpLinks = {
    helpCenterUrl: isProInfluencer
      ? 'https://anymind-group.gitbook.io/anycreator-help-page-english/'
      : 'https://anymind-group.gitbook.io/anycreator-help-page/',
    lineConnectHelp:
      'https://anymind-group.gitbook.io/anyx-help-page-english/faq/how-to-check-channel-id-and-channel-secret',
    switchBusinessAccUrl:
      'https://anymind-group.gitbook.io/anycreator-help-page/getting-started/change-to-instagram-business-account',
    anycreatorBenefits: isProInfluencer
      ? 'https://anymind-group.gitbook.io/anycreator-help-page-english/getting-started/anyxs-function'
      : 'https://anymind-group.gitbook.io/anycreator-help-page/getting-started/anyxs-function',
    howToConnectIgToFb: isProInfluencer
      ? 'https://anymind-group.gitbook.io/anycreator-help-page-english/faq/fail-to-connect-instagram-to-facebook'
      : 'https://anymind-group.gitbook.io/anycreator-help-page/faq/fail-to-connect-instagram-to-facebook',
    howToGrantPermissions: isProInfluencer
      ? 'https://anymind-group.gitbook.io/anycreator-help-page-english/faq/how-to-grant-permissions'
      : 'https://anymind-group.gitbook.io/anycreator-help-page/faq/how-to-grant-permissions',
  };

  switch (i18n.language) {
    case 'ja': {
      if (isProInfluencer) {
        helpLinks.helpCenterUrl = 'https://anymind-group.gitbook.io/anycreator-help/';
      } else {
        helpLinks.helpCenterUrl = 'https://anymind-group.gitbook.io/anycreator/';
        helpLinks.lineConnectHelp =
          'https://anymind-group.gitbook.io/anyx/hajimeni/channel-idtochannel-secretno#2-noakauntowoshiwokurikku';
        helpLinks.switchBusinessAccUrl =
          'https://anymind-group.gitbook.io/anycreatorhelp/hajimeni/instagramwobijinesuakauntoherieru';
        helpLinks.anycreatorBenefits = 'https://anymind-group.gitbook.io/anycreator-help/hajimeni/untitled';
        helpLinks.howToConnectIgToFb =
          'https://anymind-group.gitbook.io/anycreator-help/yokuaru/instagramtofacebooknogadekinai';
      }

      break;
    }

    case 'th': {
      if (isProInfluencer) {
        // links are not implemented so we will fallback to default EN_PRO
        break;
      } else {
        helpLinks.helpCenterUrl = 'https://anymind-group.gitbook.io/th-anycreator-help-page/';
        helpLinks.anycreatorBenefits = 'https://anymind-group.gitbook.io/th-anycreator-help-page/master-1';
        helpLinks.howToConnectIgToFb =
          'https://anymind-group.gitbook.io/th-anycreator-help-page/HowToConnect/instagram';
        helpLinks.howToGrantPermissions =
          'https://anymind-group.gitbook.io/th-anycreator-help-page/FAQ/grant-permission';
      }

      break;
    }
  }

  return helpLinks;
};
