import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { css } from '@emotion/react';
import { ViewportType } from '@src/libs/theme';
import PostForm from '@src/components/organisms/CastingMarketplace/YourJob/PostForm';
import useCampaign from '@src/pages/Casting/YourJob/Details/useCampaign';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import { useQueryHelper } from '@src/libs/hooks';
import { generatePath, ROUTES } from '@src/shared/routes';

const AddPost = () => {
  const { params } = useQueryHelper();
  const isDesktopView = useMediaQuery({ query: `(min-width: ${ViewportType.TABLET}px)` });
  const engagementId = Number(params.id);
  const { campaignDetails } = useCampaign(engagementId);

  return (
    <div css={styles.container}>
      {isDesktopView && (
        <BackNavigator
          title="Upload Draft Post"
          to={generatePath(
            campaignDetails?.hasDraftPosts ? ROUTES.CASTING_YOUR_JOB_ID_POST : ROUTES.CASTING_YOUR_JOB_ID,
            { id: engagementId }
          )}
        />
      )}

      <div css={styles.formContainer}>
        <PostForm engagementId={engagementId} />
      </div>
    </div>
  );
};

const styles = {
  container: css`
    padding: 24px;
  `,
  formContainer: css`
    margin-top: 16px;
  `,
};

export default AddPost;
