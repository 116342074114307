import { useContext } from 'react';
import { InfluencerType, UserRoles } from '@src/__generated__/globalTypes';
import { AdminStore } from '@src/Context';

interface AuthDataProps {
  creatorType: InfluencerType | null;
  role: UserRoles | null;
  userId: number | null;
}

export default () => {
  const { state, dispatch } = useContext(AdminStore);

  const updateAuthData = ({ creatorType, role, userId }: AuthDataProps) => {
    dispatch({ type: 'AUTH_USER', payload: { creatorType, role, userId } });
  };

  return { ...state, updateAuthData };
};
