import { ToastContainer, toast } from 'react-toastify';
import * as React from 'react';
import { css } from '@emotion/react';
import 'react-toastify/dist/ReactToastify.css';
import Icomoon from '@src/components/atoms/Icomoon';
import TextCutter from '@src/components/atoms/TextCutter';
import { useDirLayout } from '@src/libs/hooks';

const Notification = () => {
  const { isRtl } = useDirLayout();

  return (
    <ToastContainer
      enableMultiContainer
      position={isRtl ? 'top-left' : 'top-right'}
      hideProgressBar
      closeOnClick
      draggable
      closeButton={() => <Icomoon color="#fff" css={{ filter: 'contrast(0.5)' }} icon="clear" size={24} />}
      css={styles.root}
      limit={3}
    />
  );
};

export const customIcons = {
  [toast.TYPE.SUCCESS]: { icon: undefined, header: 'Success' },
  [toast.TYPE.ERROR]: { icon: <Icomoon color="#ff5f5f" icon="clear-filled" size={24} />, header: 'Error' },
  [toast.TYPE.INFO]: { icon: undefined, header: 'Information' },
  [toast.TYPE.WARNING]: {
    icon: <Icomoon color="#ffb63d" icon="warning" size={24} />,
    header: 'Warning',
  },
  [toast.TYPE.DEFAULT]: { icon: undefined, header: '' },
};

interface ToastWrapperProps {
  header: string;
  message: string;
}
export const ToastWrapper = ({ header, message }: ToastWrapperProps) => (
  <div>
    {header ? <h3 css={styles.toastHeader}>{header}</h3> : null}
    <TextCutter lines={2} text={message} css={styles.toastContent} />
  </div>
);

const styles = {
  root: css`
    .Toastify__toast {
      align-items: center;
    }

    .Toastify__toast-body {
      padding: 0;
    }

    .Toastify__toast--error {
      border-left: 3px solid #ff5f5f;
    }

    .Toastify__toast--warning {
      border-left: 3px solid #ffb63d;
    }

    .Toastify__toast--success {
      border-left: 3px solid #40b87c;
    }

    .Toastify__toast--info {
      border-left: 3px solid #3892e5;
    }
  `,
  toastHeader: css`
    font-weight: 600;
    font-size: 14px;
    color: #27313b;
  `,
  toastContent: css`
    font-size: 12px;
    color: #27313b;
  `,
};

export default Notification;
