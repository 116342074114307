import styled from '@emotion/styled';
import { ReactNode } from 'react';

const I = styled.span`
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 20px;
  opacity: 0.9;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  [dir='rtl'] & {
    transform: scaleX(-1);
  }
`;

interface IconProps {
  onClick?: () => void;
  className?: string;
  children: ReactNode;
}

export const Icon = (props: IconProps) => {
  const { children, onClick, className } = props;

  return (
    <I className={className} onClick={onClick}>
      {children}
    </I>
  );
};
