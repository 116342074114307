import isAfter from 'date-fns/isAfter';
import * as jwtDecode from 'jwt-decode';
import {
  AnyXAuthSocialAccountType,
  BioSocialMediaType,
  SocialAccountType,
  AuthSocialAccountType,
} from '@src/__generated__/globalTypes';
import { ROUTES } from '@src/shared/routes';
import { FE_REDIRECT_MAPPING } from '../constant';
import { switchSocialMediaName } from '../SocialMedia';
import { Token } from './types';

export const setToken = (token: string, refreshToken?: string | null) => {
  if (window.localStorage) {
    localStorage.setItem('accessToken', token);

    if (refreshToken) {
      localStorage.setItem('refreshToken', refreshToken);
    }
  }
};

export const getToken = () => localStorage.getItem('accessToken') || '';

export const getRefreshToken = () => localStorage.getItem('refreshToken');

export const removeToken = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
};

export const checkSignedIn = () => {
  const token = getToken();
  if (!token) {
    return false;
  }

  let decoded: Token;

  try {
    decoded = jwtDecode<Token>(token);
  } catch (e) {
    removeToken();

    return false;
  }

  const expire = decoded.exp * 1000;
  const isNotExpired = isAfter(expire, Date.now());

  const isSignedIn = isNotExpired;

  if (!isSignedIn) {
    removeToken();
  }

  return isSignedIn;
};

export const getRedirectTypePath = (redirectType: FE_REDIRECT_MAPPING): string => {
  switch (redirectType) {
    case FE_REDIRECT_MAPPING.SIGNIN:
    case FE_REDIRECT_MAPPING.TALENT_SIGNIN:
      return 'signin';
    case FE_REDIRECT_MAPPING.TALENT_SIGNUP:
    case FE_REDIRECT_MAPPING.SIGNUP:
      return 'signup';
    case FE_REDIRECT_MAPPING.SIGNUP_ENABLED:
      return 'signup-enabled';
    case FE_REDIRECT_MAPPING.CONNECT:
      return 'connect';
    case FE_REDIRECT_MAPPING.SIGNUP_CONNECT:
      return 'signup-connect';
    case FE_REDIRECT_MAPPING.JOIN_CAMPAIGN:
      return 'join_campaign';
    case FE_REDIRECT_MAPPING.ANALYTICS_IG_RECONNECT:
      return 'analytics-reconnect';
    case FE_REDIRECT_MAPPING.LINE_INSTAGRAM_SIGNIN:
      return 'signin_with_line';
    case FE_REDIRECT_MAPPING.TIKTOK_RECONNECT:
      return 'tiktok_reconnect';
    case FE_REDIRECT_MAPPING.LINK_IN_BIO_CONNECT:
      return 'link_in_bio_connect';
    case FE_REDIRECT_MAPPING.MOBILE_TIKTOK_CONNECT:
      return 'mobile_tiktok_connect';
    case FE_REDIRECT_MAPPING.TTCM_RECONNECT:
      return 'ttcm_reconnect';
    default:
      return '';
  }
};

export const getProvider = (
  socialMedia: AnyXAuthSocialAccountType | BioSocialMediaType | SocialAccountType | AuthSocialAccountType
) =>
  (socialMedia === SocialAccountType.INSTAGRAM ? AuthSocialAccountType.FACEBOOK : socialMedia) as AuthSocialAccountType;

export const getRedirectPath = (
  socialMedia: SocialAccountType | AuthSocialAccountType,
  redirectType: FE_REDIRECT_MAPPING
): string => {
  const redirectPath = `redirect/${getRedirectTypePath(redirectType)}`;

  const socialMediaName = switchSocialMediaName(socialMedia);

  return `${redirectPath}/${socialMediaName}`;
};

export const getCallbackUrl = (
  socialMedia: AnyXAuthSocialAccountType | AuthSocialAccountType | BioSocialMediaType | SocialAccountType,
  redirectType: FE_REDIRECT_MAPPING
): string => {
  const origin = location.origin;
  if (socialMedia === AuthSocialAccountType.TWITTER) {
    return `${origin}/redirect/twitter`;
  }

  const provider = getProvider(socialMedia);

  return `${origin}/${getRedirectPath(provider, redirectType)}`;
};

/*
 Connect
 */
export enum ConnectType {
  CONNECT_FB_PAGE = 'CONNECT_FB_PAGE',
  CONNECT_IG_ACCOUNT = 'CONNECT_IG_ACCOUNT',
  CONNECT_SHOPIFY = 'CONNECT_SHOPIFY',
  RECONNECT_IG_ACCOUNT = 'RECONNECT_IG_ACCOUNT',
  RECONNECT_TIKTOK = 'RECONNECT_TIKTOK',
  RECONNECT_TTCM = 'RECONNECT_TTCM',
}

const toConnectType = (key: string | null, initial = null): ConnectType | null => {
  switch (key) {
    case 'CONNECT_FB_PAGE':
      return ConnectType.CONNECT_FB_PAGE;
    case 'CONNECT_IG_ACCOUNT':
      return ConnectType.CONNECT_IG_ACCOUNT;
    case 'RECONNECT_IG_ACCOUNT':
      return ConnectType.RECONNECT_IG_ACCOUNT;
    case 'RECONNECT_TIKTOK':
      return ConnectType.RECONNECT_TIKTOK;
    case 'RECONNECT_TTCM':
      return ConnectType.RECONNECT_TTCM;
    default:
      return initial;
  }
};

export interface ConnectState {
  connectType: ConnectType;
  reconnectIgAccountId: string;
  reconnectTiktokAccountId: string;
  campaignId: string;
}

export const getConnectState = (): ConnectState | undefined => {
  const connectTypeString = localStorage.getItem('connectType') || '';
  const connectType = toConnectType(connectTypeString);
  localStorage.removeItem('connectType');

  const reconnectIgAccountId = localStorage.getItem('reconnectIgAccountId') || '';
  localStorage.removeItem('reconnectIgAccountId');

  const reconnectTiktokAccountId = localStorage.getItem('reconnectTiktokAccountId') || '';
  localStorage.removeItem('reconnectTiktokAccountId');

  const campaignId = localStorage.getItem('campaignId') || '';
  localStorage.removeItem('campaignId');

  return connectType ? { connectType, reconnectIgAccountId, reconnectTiktokAccountId, campaignId } : undefined;
};

export const getAppRootRoute = () => ROUTES.HOME;
