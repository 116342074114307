import css from '@emotion/css';
import {
  CampaignPostStatusForInfluencer,
  CampaignStatusForInfluencer,
  EngagementCampaignPostStatus,
  PaymentRequestStatusForInfluencer,
  SocialAccountStatus,
  MarketplaceCampaignPostStatus,
} from '../../__generated__/globalTypes';
import { CampaignStatus } from '../campaign';
import { darkPurple, darkGreen, mainRed, darkGrey } from '../pallete/theme';
import * as ThemeColors from '../theme_new';

export const getCampaignStatusColor = (status?: CampaignStatus | CampaignStatusForInfluencer | null) => {
  switch (status) {
    case CampaignStatus.UPCOMING:
      return purple;

    case CampaignStatus.POST_NOW:
      return green;

    case CampaignStatus.JOIN_NOW:
    case CampaignStatus.ONGOING:
      return blue;

    case CampaignStatus.SUSPENDED:
      return yellow;

    case CampaignStatus.FINISHED:
    default:
      return gray;
  }
};

// For Report page
export const getCampaignPostStatusColor = (status?: CampaignPostStatusForInfluencer | null) => {
  switch (status) {
    case null: // Not posted yet
    case CampaignPostStatusForInfluencer.REVIEWING:
      return purple;

    case CampaignPostStatusForInfluencer.EFFECTIVE:
      return blue;

    case CampaignPostStatusForInfluencer.WARNING:
      return yellow;

    case CampaignPostStatusForInfluencer.INEFFECTIVE:
    default:
      return gray;
  }
};

export const getEngagementPostStatusColor = (status?: EngagementCampaignPostStatus | null) => {
  switch (status) {
    case EngagementCampaignPostStatus.REVIEWING:
      return ThemeColors.darkPurple;

    case EngagementCampaignPostStatus.READY_TO_POST:
    case EngagementCampaignPostStatus.APPROVED:
      return ThemeColors.darkGreen;

    case EngagementCampaignPostStatus.EFFECTIVE:
    case EngagementCampaignPostStatus.WARNING_SOLVED:
      return ThemeColors.darkBlue;

    case EngagementCampaignPostStatus.WARNING:
    case EngagementCampaignPostStatus.REJECT:
      return ThemeColors.darkYellow;

    case EngagementCampaignPostStatus.INEFFECTIVE:
      return ThemeColors.darkGrey;

    default:
      return ThemeColors.darkGrey;
  }
};

export const getEngagementPostStatusTextColor = (status?: EngagementCampaignPostStatus | null) => {
  switch (status) {
    case EngagementCampaignPostStatus.APPROVED:
    case EngagementCampaignPostStatus.READY_TO_POST:
      return ThemeColors.darkGreenText;

    case EngagementCampaignPostStatus.REJECT:
      return ThemeColors.darkYellowText;

    default:
      return undefined;
  }
};

export const getMarketplacePostStatusColor = (status?: MarketplaceCampaignPostStatus | null) => {
  switch (status) {
    case MarketplaceCampaignPostStatus.APPROVED:
      return ThemeColors.darkGreen;

    case MarketplaceCampaignPostStatus.EFFECTIVE:
    case MarketplaceCampaignPostStatus.WARNING_SOLVED:
      return ThemeColors.darkBlue;

    case MarketplaceCampaignPostStatus.WARNING:
      return ThemeColors.darkYellow;

    case MarketplaceCampaignPostStatus.INEFFECTIVE:
      return ThemeColors.darkGrey;

    default:
      return ThemeColors.darkGrey;
  }
};

export const getSocialAccountStatusColor = (status?: SocialAccountStatus | null) => {
  switch (status) {
    case SocialAccountStatus.SIGNED_UP:
      return blue;

    case SocialAccountStatus.SCRAPING:
      return red;

    default:
      return gray;
  }
};

export const getPaymentStatusColor = (status?: PaymentRequestStatusForInfluencer | null) => {
  switch (status) {
    case PaymentRequestStatusForInfluencer.REVIEWING:
      return darkPurple;

    case PaymentRequestStatusForInfluencer.TRANSFERRED:
      return darkGreen;

    case PaymentRequestStatusForInfluencer.REJECTED:
    case PaymentRequestStatusForInfluencer.FAILED:
      return mainRed;

    default:
      return darkGrey;
  }
};

const purple = css`
  background-color: rgba(142, 68, 173, 0.15);
  color: rgba(142, 68, 173, 0.7);
`;
const blue = css`
  background-color: rgba(52, 152, 219, 0.15);
  color: rgba(52, 152, 219, 0.8);
`;
const green = css`
  background-color: rgba(39, 174, 96, 0.15);
  color: rgba(39, 174, 96, 0.7);
`;
const yellow = css`
  background-color: rgba(241, 196, 15, 0.15);
  color: rgba(241, 196, 15, 0.85);
`;
const red = css`
  background-color: rgba(231, 76, 60, 0.1);
  color: rgba(231, 76, 60, 0.7);
`;
const gray = css`
  background-color: rgba(52, 73, 94, 0.05);
  color: rgba(52, 73, 94, 0.6);
`;
