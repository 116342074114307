import * as React from 'react';
import ContactForm from '@src/components/organisms/Help/ContactForm';
import { usePageLayout, useUpdateDocumentTitle } from '@src/libs/hooks';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';

const Template = () => {
  const title = 'pageTitle.Contact';
  const pageTitle = 'Contact';
  useUpdateDocumentTitle({ title, pageTitle });
  const { isMobileView } = usePageLayout();

  return (
    <div css={{ margin: '16px' }}>
      <div css={{ marginBottom: '16px' }}>{!isMobileView && <BackNavigator title="Contact" />}</div>

      <ContactForm />
    </div>
  );
};

export default Template;
