import React, { SyntheticEvent } from 'react';
import { css } from '@emotion/react';
import Icomoon from '@src/components/atoms/Icomoon';
import { useDirLayout } from '@src/libs/hooks';
import { ErrorIcon } from '@anymindgroup/any-ui-react';

interface MenuControlIconProps {
  hasRemoveIcon?: boolean;
  onClose?: (e: SyntheticEvent<any>) => void;
  height?: string;
  width?: string;
  color?: string;
}

export const MenuControlIcon = ({
  hasRemoveIcon,
  color = '#c5d0da',
  onClose,
  width = '18px',
  height = '32px',
}: MenuControlIconProps) => {
  const { dir } = useDirLayout();
  const rtlCss = dir === 'rtl' ? [{ right: 'unset', left: '2px' }] : [];

  return hasRemoveIcon ? (
    <ErrorIcon
      color={color}
      width={width}
      height={height}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        onClose?.(e);
      }}
      css={[styles.closeButton, ...rtlCss]}
    />
  ) : (
    <div css={[styles.arrowButton, ...rtlCss]}>
      <Icomoon icon="arrow-down" />
    </div>
  );
};

const styles = {
  arrowButton: css`
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    width: 35px;
  `,
  closeButton: css`
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 0;

    &:hover {
      filter: brightness(0.9);
    }
  `,
};
