import React from 'react';
import { useTranslation } from 'react-i18next';
import { Option } from '@src/libs/form';
import { DisabledProps } from '../types';
import MenuItem from './MenuItem';

interface MenuItemsProps extends DisabledProps {
  options: Option[];
  hideDeselectOption?: boolean;
  handleRemove: (e: React.MouseEvent<HTMLDivElement>) => void;
  handleClick: (option: Option) => (e: React.MouseEvent<HTMLDivElement>) => void;
}

const MenuItems = (props: MenuItemsProps) => {
  const { options, hideDeselectOption, handleRemove, handleClick, disabled } = props;
  const { t } = useTranslation();

  return (
    <div>
      {!hideDeselectOption && (
        <MenuItem label={t('Selector.Please Select')} handleClick={handleRemove} disabled={disabled} />
      )}

      {options.map(option => (
        <MenuItem
          key={option.value}
          icon={option.icon}
          label={option.label}
          handleClick={handleClick(option)}
          disabled={disabled}
        />
      ))}
    </div>
  );
};

export default MenuItems;
