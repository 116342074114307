import { css } from '@emotion/core';

const maxContent = css`
  div {
    width: max-content;
  }
`;
const overflowHidden = css`
  div {
    overflow: hidden;
    max-height: 32px;
  }
`;
const twoLineHeader = css`
  white-space: pre-line;
  line-height: 1.4em;
  text-align: left;

  /* asian characters might be without whitespace we better keep them inline */
  word-break: keep-all;

  span {
    align-self: center;
  }
`;

const paddingSides = css`
  td:first-of-type {
    padding-left: 16px;
  }

  td:last-of-type {
    padding-right: 16px;
  }
`;
const preLine = css`
  span {
    line-height: 16px;
  }
`;
const flexStart = css`
  div {
    justify-content: flex-start;
  }
`;
const spreadButtonsStyles = css`
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;

const lastChildPaddingRight = css`
  th:last-of-type {
    padding-right: 16px;
  }
`;

const displayFlexColumn = css`
  display: flex;
  flex-direction: column;
`;

export default {
  maxContent,
  paddingSides,
  twoLineHeader,
  overflowHidden,
  preLine,
  flexStart,
  spreadButtonsStyles,
  lastChildPaddingRight,
  displayFlexColumn,
};
