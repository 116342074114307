import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '@src/shared/routes';

const Casting = () => {
  const history = useHistory();

  useEffect(() => {
    history.push(ROUTES.CASTING_YOUR_JOB);
  }, []);

  return null;
};

export default Casting;
