import { useState } from 'react';
import { SelectedTagsInfo } from './types';
import { GetFans_getFans_fans_tags } from './__generated__/GetFans';

export default () => {
  const [selectedFansId, setSelectedFansId] = useState<Set<number>>(new Set());
  const [selectedTags, setSelectedTags] = useState<SelectedTagsInfo>({});
  const [fansIdFromCurrentPage, setFansIdFromCurrentPage] = useState<number[]>([]);
  const [totalFanCount, setTotalFanCount] = useState<number>(0);

  const isAllSelectedFromCurrentPage =
    fansIdFromCurrentPage.length > 0 && fansIdFromCurrentPage.every(id => selectedFansId.has(id));

  const toggleFanSelection = (id: number, tags: GetFans_getFans_fans_tags[]) => {
    const newSet = new Set(selectedFansId);
    const newTags = { ...selectedTags };

    if (selectedFansId.has(id)) {
      newSet.delete(id);
      delete newTags[id];
    } else {
      newSet.add(id);
      newTags[id] = tags;
    }

    setSelectedFansId(newSet);
    setSelectedTags(newTags);
  };

  const handleCheckAll = () => {
    const newSet = new Set(selectedFansId);

    fansIdFromCurrentPage.map(id => (isAllSelectedFromCurrentPage ? newSet.delete(id) : newSet.add(id)));

    setSelectedFansId(newSet);
  };

  const resetSelection = () => {
    setSelectedFansId(new Set());
  };

  return {
    selectedFansId,
    selectedTags,
    isAllSelectedFromCurrentPage,
    totalFanCount,
    setTotalFanCount,
    toggleFanSelection,
    setFansIdFromCurrentPage,
    handleCheckAll,
    resetSelection,
  };
};
