import { useQuery } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import CREATOR_ADDRESS_INFORMATION from './queries/CreatorAddressInformation.graphql';
import { CreatorAddressInformation } from './queries/__generated__/CreatorAddressInformation';

const useGetCreatorAddress = () => {
  const { enqueueSnackbar, t } = useQueryHelper();

  const { data } = useQuery<CreatorAddressInformation>(CREATOR_ADDRESS_INFORMATION, {
    fetchPolicy: 'no-cache',
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  return {
    creatorAddressInformation: data?.creatorAddressInformation,
  };
};

export default useGetCreatorAddress;
