import { Spinner } from '@anymindgroup/any-ui-react';

interface CircularProgressProps {
  size?: string;
  thickness?: string;
  color?: string;
  className?: string;
}
export const CircularProgress = ({ size = '64px', thickness = '4px', color, className }: CircularProgressProps) => (
  <Spinner size={size} thickness={thickness} color={color} className={className} css={{ margin: 'auto' }} />
);
