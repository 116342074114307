import { format } from 'date-fns';
import React from 'react';
import { useParams } from 'react-router';
import { css } from '@emotion/react';
import Grid from '@src/components/atoms/Grid';
import { ListIndicator } from '@src/components/molecules/Indicator';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import EmailForm, { dateFormat } from '@src/components/organisms/Emails/Form';
import StatusIndicator from '@src/components/organisms/Emails/StatusIndicator';
import { usePageLayout, usePersonalInformation } from '@src/libs/hooks';
import { ROUTES } from '@src/shared/routes';
import { EmailTemplateStatus } from '@src/__generated__/globalTypes';
import useGetEmailTemplate from './useGetEmailTemplate';

const Edit = () => {
  const { userPersonalInfo } = usePersonalInformation();
  const { isMobileView } = usePageLayout();
  const params = useParams<Record<any, string>>();
  const { getEmailTemplate, loading } = useGetEmailTemplate({ emailId: Number(params.id) });

  return (
    <div css={styles.container}>
      {!isMobileView && (
        <div css={styles.navigatorContainer}>
          <BackNavigator title="Compose" to={ROUTES.EMAILS} />
          {getEmailTemplate?.status && <StatusIndicator status={getEmailTemplate?.status} />}
        </div>
      )}

      <div css={styles.infoContainer}>
        <Grid md={8} sm={10} xs={12}>
          {loading ? (
            <ListIndicator />
          ) : (
            <EmailForm
              backUrl={ROUTES.EMAILS}
              fans={getEmailTemplate?.fans || []}
              id={getEmailTemplate?.id}
              isDraft={getEmailTemplate?.status === EmailTemplateStatus.DRAFT}
              message={getEmailTemplate?.message || ''}
              scheduleDate={getEmailTemplate?.scheduleDate || format(new Date(), dateFormat)}
              sender={getEmailTemplate?.sender || userPersonalInfo?.email || ''}
              sendNow={getEmailTemplate ? !!getEmailTemplate?.sendNow : true}
              subject={getEmailTemplate?.subject || ''}
              tags={getEmailTemplate?.tags || []}
            />
          )}
        </Grid>
      </div>
    </div>
  );
};

const styles = {
  container: css`
    padding: 16px 24px;
  `,
  infoContainer: css`
    display: flex;
    justify-content: center;
  `,
  navigatorContainer: css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;

    & > div:nth-of-type(1) {
      margin-right: 16px;
    }
  `,
};

export default Edit;
