import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useHistory } from 'react-router';
import { checkSignedIn } from '@src/libs/auth';
import { ROUTES } from '@src/shared/routes';

const PrivateRoute = ({ component: Component, ...rest }: RouteProps) => {
  const history = useHistory();
  const isSignedIn = checkSignedIn();

  if (!isSignedIn) {
    history.push({ pathname: ROUTES.ROOT, state: { from: history.location } });
  }

  return <Route {...rest} render={props => (Component ? <Component {...props} /> : null)} />;
};

export default PrivateRoute;
