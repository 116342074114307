import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Status, themeVars } from '@anymindgroup/any-ui-react';
import { ApolloError } from '@apollo/client';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import connectLineImg from '@src/assets/img/broadcast/connectLine.svg';
import Empty from '@src/components/atoms/Empty';
import Image from '@src/components/atoms/Image';
import * as List from '@src/components/atoms/List';
import { ThemeButton } from '@src/components/atoms/Button';
import { ListIndicator } from '@src/components/molecules/Indicator';
import { SliderComponents, StyledComponents } from '@src/components/molecules/SliderTable';
import BroadcastStatus from '@src/components/organisms/Broadcast/BroadcastStatus';
import { formatPercent, formatNumberWithCommas } from '@src/libs/format';
import { LineConnectStatus } from '@src/pages/Settings/ChannelConnect/useGetLineChannels';
import { generatePath, ROUTES } from '@src/shared/routes';
import { LineBroadcastStatus } from '@src/__generated__/globalTypes';

interface BroadcastListProps {
  error?: ApolloError;
  lineConnectStatus: LineConnectStatus;
  loading: boolean;
  items: {
    click: number | null;
    clickRate: number | null;
    createdDateTime: string;
    delivered: number | null;
    deliveredDateTime: string | null;
    id: string;
    imageUrl: string;
    open: number | null;
    openRate: number | null;
    status: LineBroadcastStatus;
    tags: string[];
    title: string;
  }[];
  isEmptyFilter: boolean;
}

const BroadcastList = ({ error, lineConnectStatus, loading, items, isEmptyFilter }: BroadcastListProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <SliderComponents.Container>
        <SliderComponents.SliderSection>
          <SliderComponents.SliderTable>
            <thead>
              <SliderComponents.HeaderRow>
                <SliderComponents.StickyHeaderTdWrapper>
                  <HeaderColumn title="Image" />
                  <HeaderColumn title="Title" />
                </SliderComponents.StickyHeaderTdWrapper>
                <HeaderColumn title="Tag" />
                <HeaderColumn title="Status" />
                <HeaderColumn title="Delivered" />
                <HeaderColumn title="Open" />
                <HeaderColumn title="Open Rate" />
                <HeaderColumn title="Click" />
                <HeaderColumn title="Click Rate" />
                <HeaderColumn css={{ minWidth: 140 }} title="Delivered Date" />
                <HeaderColumn css={{ minWidth: 140 }} title="Created Date" />
              </SliderComponents.HeaderRow>
            </thead>

            {!loading && (
              <tbody>
                {items.map(item => {
                  const {
                    click,
                    clickRate,
                    createdDateTime,
                    delivered,
                    deliveredDateTime,
                    id,
                    imageUrl,
                    open,
                    openRate,
                    status,
                    tags,
                    title,
                  } = item;

                  return (
                    <StyledComponents.StyledRowNew key={id} css={{ borderTop: 'none' }}>
                      <SliderComponents.StickyBodyRowWrapper css={styles.clearTopBorder}>
                        <List.TextColumn data={<Image height="56px" src={imageUrl} width="56px" />} />
                        <List.TextLinkColumn data={title} href={generatePath(ROUTES.BROADCAST_EDIT, { id })} />
                      </SliderComponents.StickyBodyRowWrapper>
                      <List.TextColumn
                        data={
                          <div css={{ display: 'flex', gap: 4 }}>
                            {tags.map((tag, tagIndex) => (
                              <Status customColor={themeVars.grey300} borderRadius="3px" key={tagIndex}>
                                {tag}
                              </Status>
                            ))}
                          </div>
                        }
                      />
                      <List.TextColumn data={<BroadcastStatus status={status} />} />
                      <List.TextColumn data={formatNumberWithCommas(delivered, 0)} />
                      <List.TextColumn data={formatNumberWithCommas(open, 0)} />
                      <List.TextColumn data={formatPercent(openRate)} />
                      <List.TextColumn data={formatNumberWithCommas(click, 0)} />
                      <List.TextColumn data={formatPercent(clickRate)} />
                      <List.TextColumn
                        css={{ minWidth: 140 }}
                        data={
                          status === LineBroadcastStatus.DRAFT || !deliveredDateTime
                            ? '-'
                            : format(new Date(deliveredDateTime + 'Z'), 'dd/MM/yyyy hh:mm')
                        }
                      />
                      <List.TextColumn
                        css={{ minWidth: 140 }}
                        data={createdDateTime ? format(new Date(createdDateTime + 'Z'), 'dd/MM/yyyy hh:mm') : '-'}
                      />
                    </StyledComponents.StyledRowNew>
                  );
                })}
              </tbody>
            )}
          </SliderComponents.SliderTable>
        </SliderComponents.SliderSection>
      </SliderComponents.Container>

      {(loading || items.length === 0) && (
        <ContentContainer>
          {loading ? (
            <ListIndicator />
          ) : lineConnectStatus !== 'connected' ? (
            <div css={styles.connectLineContainer}>
              <div>
                <img height="162" src={connectLineImg} width="208" />
              </div>
              <div>{t('Connect your LINE Official Account')}</div>
              <div>{t('To send the test broadcast, you need to connect your LINE Official')}</div>
              <div>
                <ThemeButton
                  css={{ width: 'fit-content' }}
                  href={ROUTES.SETTINGS_CHANNEL_CONNECT}
                  text="Connect LINE"
                />
              </div>
            </div>
          ) : error ? (
            <Empty title="UnexpectedError" content={error.message} />
          ) : isEmptyFilter ? (
            <Empty
              title="There is No Broadcast"
              content="You can send the same message to all users who are friends with your LINE Official Account"
            />
          ) : (
            <Empty title="No Broadcasts Found" content="Try changing the search term" />
          )}
        </ContentContainer>
      )}
    </div>
  );
};

const ContentContainer = styled.div`
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 500px;
  justify-content: center;
  width: 100%;
`;

const HeaderColumn = styled(List.HeaderColumn)`
  min-width: 100px;

  & > div > div {
    color: #27313b;
    font-size: 12px;
    font-weight: 600;
  }
`;

const styles = {
  connectLineContainer: css`
    display: grid;
    justify-content: center;
    text-align: center;
    width: 500px;

    & > div:nth-of-type(1) {
      display: flex;
      justify-content: center;
      margin-bottom: 32px;
    }

    & > div:nth-of-type(2) {
      color: #27313b;
      font-size: 18px;
      margin-bottom: 16px;
    }

    & > div:nth-of-type(3) {
      color: #27313b;
      font-size: 14px;
      margin-bottom: 24px;
    }

    & > div:nth-of-type(4) {
      display: flex;
      justify-content: center;
    }
  `,
  clearTopBorder: css`
    & > td {
      border-top: none;
    }
  `,
};

export default BroadcastList;
