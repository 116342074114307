import React from 'react';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import { RadioIcon } from '@src/components/atoms/RadioButtonElement';
import { FilterByDateRange } from '@src/__generated__/globalTypes';

interface DateOptionGroupProps {
  items: { label: string; value: FilterByDateRange }[];
  value: FilterByDateRange | null;
  onClear: () => void;
  onChange: (val: FilterByDateRange) => void;
}

const DateOptionGroup = (props: DateOptionGroupProps) => {
  const { t } = useTranslation();
  const { items, value, onClear, onChange } = props;

  return (
    <div css={styles.wrapper}>
      {items.map(item => (
        <div key={item.value} css={styles.row} onClick={() => onChange(item.value)}>
          <RadioIcon css={{ marginRight: 8 }} isChecked={value === item.value} />
          <span>{t(item.label)}</span>
        </div>
      ))}
      <p css={styles.clearBtn} onClick={onClear}>
        {t('Clear')}
      </p>
    </div>
  );
};

const styles = {
  wrapper: css`
    display: flex;
    flex-direction: column;
  `,
  row: css`
    display: flex;
    align-items: center;

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }

    &:hover {
      cursor: pointer;
    }
  `,
  clearBtn: css`
    color: #3892e5;
    font-size: 14px;
    margin: 16px 0;

    &:hover {
      cursor: pointer;
    }
  `,
};

export default DateOptionGroup;
