import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import EmojiPicker from '@src/components/atoms/EmojiPicker';
import Label from '@src/components/atoms/Label';
import Textarea from '@src/components/atoms/Textarea';
import { FormInformation } from './helpers';

interface PlainTextInputProps {
  isDisabled?: boolean;
}

const PlainTextInput = ({ isDisabled }: PlainTextInputProps) => {
  const { t } = useTranslation();
  const {
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<FormInformation>();
  const [lineContent, selectedBlockIndex] = watch(['lineContent', 'selectedBlockIndex']);

  const plainTextContent = lineContent[selectedBlockIndex];
  const isError = !!errors.lineContent && !plainTextContent.plainTextMessage?.text;

  const onChangeText = (val: string) => {
    const items = [...lineContent];
    items[selectedBlockIndex] = { ...plainTextContent, plainTextMessage: { text: val } };
    setValue('lineContent', items);
  };

  const onClickEmoji = (emoji: string) => {
    const items = [...lineContent];
    items[selectedBlockIndex] = {
      ...plainTextContent,
      plainTextMessage: { text: plainTextContent.plainTextMessage?.text + emoji },
    };
    setValue('lineContent', items);
  };

  return (
    <div css={styles.container}>
      <Label css={styles.label} isRequired title="Input text" />
      <TextAreaContainer disabled={isDisabled} error={isError}>
        <Textarea
          css={styles.textarea}
          disabled={isDisabled}
          placeholder={t('Placeholder.Use this text to share information about your store with your customers')}
          value={plainTextContent?.plainTextMessage?.text || ''}
          onChange={e => onChangeText(e.target.value)}
        />
        <EmojiPicker onEmojiClick={emoji => onClickEmoji(emoji)} />
      </TextAreaContainer>
      {isError && <ErrorMessage message={'requiredFieldMessage'} />}
    </div>
  );
};

const TextAreaContainer = styled.div<{ disabled?: boolean; error: boolean }>`
  background-color: ${({ disabled }) => (disabled ? '#f2f2f2' : '#fff')};
  border: 1px solid ${({ error }) => (error ? '#ff6247' : '#dee5ec')};
  border-radius: 2px;
  box-shadow: inset 0 1px 2px rgba(39, 49, 59, 0.2);
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  position: relative;

  & > div > div {
    margin: 8px;
  }
`;

const styles = {
  container: css`
    margin: 24px;
  `,
  label: css`
    color: #27313b;
    font-size: 14px;
  `,
  textarea: css`
    border: none;
    box-shadow: none;
  `,
};

export default PlainTextInput;
