import React from 'react';
import { RouteComponentProps } from 'react-router';
import { css } from '@emotion/core';
import FanDetailsHeader from '@src/components/organisms/FanDetails/FanDetailsHeader';
import FanDetails from '@src/components/organisms/FanDetails';
import FanOrders from '@src/components/organisms/FanOrders';
import FanActivity from '@src/components/organisms/FanActivity';
import FanForms from '@src/components/organisms/FanForms';
import FormsResponse from '@src/components/organisms/FanForms/FormsResponse';
import { usePageLayout } from '@src/libs/hooks';
import FanNavigation from './FanNavigation';

const FanDetailsPage = (props: RouteComponentProps<{ id: string }>) => {
  const {
    match: {
      params: { id },
      path,
    },
  } = props;

  const { isMobileView } = usePageLayout();
  const showMobileTabs = isMobileView && path === '/fans/:id';
  const showFanNavigation = !isMobileView || showMobileTabs;

  const renderContent = () => {
    switch (path) {
      case '/fans/:id/activity':
        return <FanActivity />;
      case '/fans/:id/orders':
        return <FanOrders />;
      case '/fans/:id/forms/:submissionId':
        return <FormsResponse />;
      case '/fans/:id/forms':
        return <FanForms />;
      default:
        // works for both /fans/:id and /fans/:id/details
        return <FanDetails />;
    }
  };

  return (
    <div css={styles.container}>
      {showFanNavigation && <FanDetailsHeader id={id} />}
      <div css={[styles.mainContent, isMobileView && styles.mobileContent]}>
        {showFanNavigation && <FanNavigation />}
        {renderContent()}
      </div>
    </div>
  );
};

const styles = {
  container: css`
    margin: 16px 24px;
  `,
  mainContent: css`
    background: #fff;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
    border-radius: 5px;
  `,
  mobileContent: css`
    box-shadow: none;
    background-color: transparent;
  `,
};

export default FanDetailsPage;
