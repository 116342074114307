import { useQuery } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import * as GET_FAN_ORDERS from './GetFanOrders.graphql';
import { GetFanOrders, GetFanOrdersVariables } from './__generated__/GetFanOrders';

interface UseGetFanOrdersProps {
  fanId: number;
}

const useGetFanOrders = ({ fanId }: UseGetFanOrdersProps) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  const { data, loading } = useQuery<GetFanOrders, GetFanOrdersVariables>(GET_FAN_ORDERS, {
    variables: {
      input: {
        fanId,
      },
    },
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
  });

  return {
    data,
    loading,
  };
};

export default useGetFanOrders;
