import React from 'react';
import { css } from '@emotion/react';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import FacebookInstruction from '@src/components/organisms/SignUpFacebook/FacebookInstruction';
import { ROUTES } from '@src/shared/routes';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { UNEXPECTED_ERROR } from '@src/libs/error';
import { usePageLayout, useQueryHelper, useRedirectWithinIframe } from '@src/libs/hooks';
import { useRedirectUrl } from '@src/libs/hooks/useRedirectUrl';
import { ViewportType } from '@src/libs/theme';
import { SocialAccountType } from '@src/__generated__/globalTypes';

const FacebookGrantPermission = () => {
  const { enqueueSnackbar, t } = useQueryHelper();
  const { isMobileView } = usePageLayout();
  const { getRedirectUrl } = useRedirectUrl(FE_REDIRECT_MAPPING.CONNECT);
  const { startRedirectProcess } = useRedirectWithinIframe();

  const onClickEditSettings = async () => {
    try {
      const redirectUrl = await getRedirectUrl(SocialAccountType.FACEBOOK);

      if (redirectUrl) {
        startRedirectProcess({ redirectUrl, redirectType: FE_REDIRECT_MAPPING.CONNECT });
      }
    } catch (error) {
      enqueueSnackbar(t(error.message || UNEXPECTED_ERROR), { variant: 'error' });
    }
  };

  return (
    <div css={styles.container}>
      {!isMobileView && (
        <BackNavigator css={{ marginBottom: 24 }} title="Grant permission" to={ROUTES.SETTINGS_SOCIAL_CONNECT} />
      )}

      <div css={{ display: 'flex', justifyContent: 'center' }}>
        <div css={{ width: 750 }}>
          <FacebookInstruction onClickEditSettings={onClickEditSettings} />
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: css`
    margin: 16px 24px;

    @media (max-width: ${ViewportType.TABLET}px) {
      margin: 16px;
    }
  `,
};

export default FacebookGrantPermission;
