import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import addressCard from '@src/assets/icon/chat/addressCard.svg';
import clipboard from '@src/assets/icon/chat/clipboardLightOrange.svg';
import clock from '@src/assets/icon/chat/clock.svg';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/theme';
import { generatePath, ROUTES } from '@src/shared/routes';
import Icomoon from '@src/components/atoms/Icomoon';
import { Icon } from '@src/components/atoms/Icon';
import Activity from './Activity';
import FanDetail from './FanDetail';
import Empty from './Empty';
import Header from './Header';
import Orders from './Orders';
import GET_LINE_CHAT from './queries/GetLineChat.graphql';
import { GetLineChat, GetLineChatVariables } from './queries/__generated__/GetLineChat';

const ChatCustomerInformation = () => {
  const { enqueueSnackbar, params, path, t } = useQueryHelper();
  const { isMobileView } = usePageLayout();
  const { activeChat, fanId } = params;
  const pathOptions = activeChat && fanId ? { activeChat, fanId } : undefined;

  const cards = [
    {
      icon: addressCard,
      title: 'Fan Detail',
      to: generatePath(ROUTES.CHAT_ACTIVE_ID_FAN_ID_FAN_DETAILS, pathOptions),
    },
    {
      icon: clock,
      title: 'Activity',
      to: generatePath(ROUTES.CHAT_ACTIVE_ID_FAN_ID_ACTIVITY, pathOptions),
    },
    {
      icon: clipboard,
      title: 'Orders',
      to: generatePath(ROUTES.CHAT_ACTIVE_ID_FAN_ID_ORDERS, pathOptions),
    },
  ];
  const isActivity = path === ROUTES.CHAT_ACTIVE_ID_FAN_ID_ACTIVITY;
  const isFanDetail = path === ROUTES.CHAT_ACTIVE_ID_FAN_ID_FAN_DETAILS;
  const isOrders = path === ROUTES.CHAT_ACTIVE_ID_FAN_ID_ORDERS;

  const { data } = useQuery<GetLineChat, GetLineChatVariables>(GET_LINE_CHAT, {
    skip: !activeChat || !fanId,
    variables: {
      chatId: activeChat,
      fanId: Number(fanId),
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const customerInfo = data?.getFanHeader;
  const lineInfo = data?.getLineChat;

  return (
    <div css={styles.container}>
      {activeChat && fanId ? (
        <div>
          {!(isMobileView && (isActivity || isFanDetail || isOrders)) && (
            <Header
              avatar={lineInfo?.avatar || ''}
              currencyId={customerInfo?.currencyId || ''}
              created={customerInfo?.created || ''}
              firstName={customerInfo?.firstName || ''}
              lastName={customerInfo?.lastName || ''}
              lastOrder={customerInfo?.lastOrder || ''}
              lineName={lineInfo?.name || ''}
              totalOrders={customerInfo?.totalOrders || 0}
              totalSpent={customerInfo?.totalSpent || 0}
            />
          )}

          <ContentContainer isMobileView={isMobileView} isNavigatorPage={!isActivity && !isFanDetail && !isOrders}>
            {isFanDetail ? (
              <FanDetail />
            ) : isActivity ? (
              <Activity />
            ) : isOrders ? (
              <Orders />
            ) : (
              <div>
                {cards.map((card, index) => {
                  const { icon, title, to } = card;

                  return (
                    <Card key={index} isMobileView={isMobileView} to={to}>
                      <div>
                        <img alt="icon" height="32" src={icon} width="32" />
                      </div>
                      <div>{t(`MenuTitle.${title}`)}</div>
                      <Icon>
                        <Icomoon css={{ transform: 'rotate(270deg)' }} icon="arrow-down" />
                      </Icon>
                    </Card>
                  );
                })}
              </div>
            )}
          </ContentContainer>
        </div>
      ) : (
        <Empty />
      )}
    </div>
  );
};

const Card = styled(Link, { shouldForwardProp: prop => isPropValid(prop) })<{ isMobileView: boolean }>`
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  padding: 12px;
  gap: 12px;
  ${({ isMobileView }) =>
    !isMobileView
      ? 'border-radius: 7px; box-shadow: 0 2px 5px rgba(85, 98, 111, 0.15); margin-bottom: 16px;'
      : 'border-bottom: 1px solid #dee5ec;'}

  & > div:nth-of-type(2) {
    color: #27313b;
    display: flex;
    flex: 1;
    font-size: 16px;
    font-weight: 600;
  }
`;

const ContentContainer = styled.div<{ isMobileView: boolean; isNavigatorPage: boolean }>`
  padding: ${({ isMobileView, isNavigatorPage }) => (isMobileView && isNavigatorPage ? '8px 0' : '16px')};
`;

const styles = {
  container: css`
    box-shadow: 0 2px 5px #dee5ec, 0 1px 4px rgba(222, 229, 236, 0.5);

    @media (max-width: ${ViewportType.TABLET}px) {
      box-shadow: none;
    }
  `,
};

export default ChatCustomerInformation;
