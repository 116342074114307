import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { useAuthData, useDeepCompareEffect, useQueryHelper } from '@src/libs/hooks';
import { ROUTES } from '@src/shared/routes';
import SOCIAL_RATE_CARD from './queries/GetSocialRateCardForInfluencer.graphql';
import UPDATE_INFLUENCER_RATE_CARDS from './mutations/UpdateInfluencerRateCards.graphql';
import {
  UpdateInfluencerRateCards,
  UpdateInfluencerRateCardsVariables,
} from './mutations/__generated__/UpdateInfluencerRateCards';
import { GetSocialRateCardForInfluencer } from './queries/__generated__/GetSocialRateCardForInfluencer';
import SnsBudget, { Information } from './SnsBudget';

const Index = () => {
  const { userId } = useAuthData();
  const { enqueueSnackbar, history, t } = useQueryHelper();

  const { data, loading } = useQuery<GetSocialRateCardForInfluencer>(SOCIAL_RATE_CARD, {
    fetchPolicy: 'no-cache',
    variables: {
      pk: userId,
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const [updateInfluencerRateCards] = useMutation<UpdateInfluencerRateCards, UpdateInfluencerRateCardsVariables>(
    UPDATE_INFLUENCER_RATE_CARDS,
    {
      onCompleted: () => {
        enqueueSnackbar(t('succeededInUpdating'), { variant: 'success' });
        sendAmplitudeEvent(eventTypes.editSettingsSnsBudget);
      },
      onError: error => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
    }
  );

  if (!loading && !data?.getInfluencerRateCardForInfluencer) {
    // we do not show SNS budget for TA/PA influencer
    history.push(ROUTES.SETTINGS);
  }

  const currency = data?.getInfluencerRateCardForInfluencer?.currencyId || '';
  const facebookPrice = data?.getInfluencerRateCardForInfluencer?.facebook?.price.toString() || '0';
  const instagramPrice = data?.getInfluencerRateCardForInfluencer?.instagram?.price.toString() || '0';
  const instagramStoryPrice = data?.getInfluencerRateCardForInfluencer?.instagramStory?.price.toString() || '0';
  const tiktokPrice = data?.getInfluencerRateCardForInfluencer?.tiktok?.price.toString() || '0';
  const twitterPrice = data?.getInfluencerRateCardForInfluencer?.twitter?.price.toString() || '0';
  const youtubePrice = data?.getInfluencerRateCardForInfluencer?.youtube?.price.toString() || '0';
  const hasFBAccount =
    !isEmpty(data?.influencerProfileV2?.facebookAccount) || !isEmpty(data?.influencerProfileV2?.facebookPages);
  const hasIGAccount = !isEmpty(data?.influencerProfileV2?.instagramAccounts);
  const hasTTAccount = !isEmpty(data?.influencerProfileV2?.tiktokAccounts);
  const hasTWAccount = !isEmpty(data?.influencerProfileV2?.twitterAccounts);
  const hasYTAcccount = !isEmpty(data?.influencerProfileV2?.youtubeAccounts);

  const validationSchema = yup.object().shape({
    ...(hasFBAccount && {
      facebookPrice: yup.string().matches(/(^\d{1,}\.\d{2}$)|(^\d{1,}$)/, 'invalidPriceMessage'),
    }),
    ...(hasIGAccount && {
      instagramPrice: yup.string().matches(/(^\d{1,}\.\d{2}$)|(^\d{1,}$)/, 'invalidPriceMessage'),
      instagramStoryPrice: yup.string().matches(/(^\d{1,}\.\d{2}$)|(^\d{1,}$)/, 'invalidPriceMessage'),
    }),
    ...(hasTTAccount && {
      tiktokPrice: yup.string().matches(/(^\d{1,}\.\d{2}$)|(^\d{1,}$)/, 'invalidPriceMessage'),
    }),
    ...(hasTWAccount && {
      twitterPrice: yup.string().matches(/(^\d{1,}\.\d{2}$)|(^\d{1,}$)/, 'invalidPriceMessage'),
    }),
    ...(hasYTAcccount && {
      youtubePrice: yup.string().matches(/(^\d{1,}\.\d{2}$)|(^\d{1,}$)/, 'invalidPriceMessage'),
    }),
  });

  const onSubmit = async (values: Information) => {
    updateInfluencerRateCards({
      variables: {
        input: {
          ...(hasFBAccount && { facebook: Number(values.facebookPrice) }),
          ...(hasIGAccount && {
            instagram: Number(values.instagramPrice),
            instagramStory: Number(values.instagramStoryPrice),
          }),
          ...(hasTTAccount && { tiktok: Number(values.tiktokPrice) }),
          ...(hasTWAccount && { twitter: Number(values.twitterPrice) }),
          ...(hasYTAcccount && { youtube: Number(values.youtubePrice) }),
        },
      },
    });
  };

  const defaultValues = {
    facebookPrice,
    instagramPrice,
    instagramStoryPrice,
    tiktokPrice,
    twitterPrice,
    youtubePrice,
  };

  const methods = useForm<Information>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  useDeepCompareEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <SnsBudget
          currency={currency}
          hasFBAccount={hasFBAccount}
          hasIGAccount={hasIGAccount}
          hasTTAccount={hasTTAccount}
          hasTWAccount={hasTWAccount}
          hasYTAccount={hasYTAcccount}
        />
      </form>
    </FormProvider>
  );
};

export default Index;
