import { useMutation } from '@apollo/client';
import SOCIAL_AUTH_CONNECT from './mutations/AnyXSocialAuthConnectForLinkBio.graphql';
import SOCIAL_AUTH_RECONNECT from './mutations/AnyXSocialAuthReconnectForLinkBio.graphql';
import {
  AnyXSocialAuthConnectForLinkBio,
  AnyXSocialAuthConnectForLinkBioVariables,
} from './mutations/__generated__/AnyXSocialAuthConnectForLinkBio';
import {
  AnyXSocialAuthReconnectForLinkBio,
  AnyXSocialAuthReconnectForLinkBioVariables,
} from './mutations/__generated__/AnyXSocialAuthReconnectForLinkBio';

const useLinkInBioConnect = () => {
  const [socialAuthConnect] = useMutation<AnyXSocialAuthConnectForLinkBio, AnyXSocialAuthConnectForLinkBioVariables>(
    SOCIAL_AUTH_CONNECT
  );
  const [socialAuthReconnect] = useMutation<
    AnyXSocialAuthReconnectForLinkBio,
    AnyXSocialAuthReconnectForLinkBioVariables
  >(SOCIAL_AUTH_RECONNECT);

  return {
    socialAuthConnect,
    socialAuthReconnect,
  };
};

export default useLinkInBioConnect;
