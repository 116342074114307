import { useMutation } from '@apollo/client';
import { useAuthSetup } from '@src/components/organisms/AuthSetUp/useAuthSetup';
import { removeToken, setToken } from '@src/libs/auth';
import { useQueryHelper } from '@src/libs/hooks';
import SWITCH_INFLUECER_FOR_STAFF from './mutations/SwitchInfluencerForStaff.graphql';
import {
  SwitchInfluencerForStaff,
  SwitchInfluencerForStaffVariables,
} from './mutations/__generated__/SwitchInfluencerForStaff';

const UseMenuMutation = () => {
  const { enqueueSnackbar, history, t } = useQueryHelper();
  const { setUp } = useAuthSetup();

  const [switchInfluencer] = useMutation<SwitchInfluencerForStaff, SwitchInfluencerForStaffVariables>(
    SWITCH_INFLUECER_FOR_STAFF,
    {
      refetchQueries: ['GetAvailableInfluencersForStaff'],
      onCompleted: async results => {
        const token = results.switchInfluencerForStaff?.token;
        if (token) {
          enqueueSnackbar(t('succeededInUpdating'), { variant: 'success' });
          setToken(token);
          try {
            await setUp(token);
          } catch (error) {
            removeToken();
            console.error(error);
            enqueueSnackbar(t((error as { message: string }).message), { variant: 'error' });
          }
          // reload the page when switch influencer account
          history.go(0);
        }
      },
      onError: error => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
    }
  );

  return {
    switchInfluencer,
  };
};

export default UseMenuMutation;
