import React from 'react';
import { css } from '@emotion/core';
import { useQuery } from '@apollo/client';
import { ThemeButton } from '@src/components/atoms/Button';
import { SliderComponents } from '@src/components/molecules/SliderTable';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import { LIMIT_10 } from '@src/libs/constant';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { getOffset, getPageInfo, usePaging } from '@src/libs/paging';
import useGetLineChannels from '@src/pages/Settings/ChannelConnect/useGetLineChannels';
import { ROUTES } from '@src/shared/routes';
import BroadcastList from './BroadcastList';
import BroadcastListFilter from './BroadcastListFilter';
import BroadcastListSummary from './BroadcastListSummary';
import GET_LINE_BROADCASTS from './queries/GetLineBroadcasts.graphql';
import { GetLineBroadcasts, GetLineBroadcastsVariables } from './queries/__generated__/GetLineBroadcasts';
import useFilter from './useFilter';

const Listing = () => {
  const { enqueueSnackbar, t } = useQueryHelper();
  const currentPage = usePaging();
  const { hasNoLineConnected, lineConnectStatus } = useGetLineChannels();
  const { isMobileView } = usePageLayout();
  const { filter, isEmptyFilter, setFilter } = useFilter();
  const { from, keyword, status, tag, to } = filter;

  const { data, loading, error } = useQuery<GetLineBroadcasts, GetLineBroadcastsVariables>(GET_LINE_BROADCASTS, {
    skip: hasNoLineConnected,
    variables: {
      limit: LIMIT_10, // in AnyChat they use 10 as default
      offset: getOffset(currentPage, LIMIT_10),
      ...(from ? { from } : {}),
      ...(keyword ? { keyword } : {}),
      ...(status ? { status } : {}),
      ...(tag ? { tag } : {}),
      ...(to ? { to } : {}),
    },
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
  });

  const lineBroadcastSummary = data?.getLineBroadcastSummary;
  const lineBroadcastItems = data?.getLineBroadcasts?.broadcasts || [];
  const totalItems = data?.getLineBroadcastsCount?.total || 0;

  const { firstIndex, lastIndex, totalPages } = getPageInfo(currentPage, totalItems, LIMIT_10);

  return (
    <div css={styles.container}>
      {!isMobileView && (
        <div css={styles.navigatorContainer}>
          <BackNavigator title="Broadcast for LINE" />
          <ThemeButton css={{ width: 'fit-content' }} href={ROUTES.BROADCAST_ADD} text="Add Broadcast" theme="blue" />
        </div>
      )}

      <div css={styles.detailsContainer}>
        <BroadcastListSummary lineBroadcastSummary={lineBroadcastSummary} />
        <BroadcastListFilter filter={filter} setFilter={setFilter} />
        <BroadcastList
          error={error}
          loading={loading}
          lineConnectStatus={lineConnectStatus}
          items={lineBroadcastItems}
          isEmptyFilter={isEmptyFilter}
        />
      </div>

      <SliderComponents.Pager
        currentPage={currentPage}
        first={firstIndex}
        last={lastIndex}
        totalCount={totalItems}
        totalPages={totalPages}
      />
    </div>
  );
};

const styles = {
  container: css`
    padding: 24px;
  `,
  detailsContainer: css`
    border-radius: 3px;
    box-shadow: 0 2px 1px rgba(110, 124, 137, 0.05), 0 0 2px rgba(110, 124, 137, 0.25);
  `,
  navigatorContainer: css`
    align-items: center;
    display: flex;
    margin-bottom: 16px;

    & > div:nth-of-type(1) {
      flex: 1;
    }
  `,
};

export default Listing;
