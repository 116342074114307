import styled from '@emotion/styled';
import * as React from 'react';

interface TermsTextProps {
  className?: string;
  subTitle?: string;
  children: React.ReactNode;
}

const TermsText = (props: TermsTextProps) => {
  const { className, subTitle, children } = props;

  return (
    <Wrapper className={className}>
      {subTitle && <SubTitle>{subTitle}</SubTitle>}
      <Paragraph>{children}</Paragraph>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-bottom: 16px;
`;

const SubTitle = styled.h3`
  padding: 24px 0 8px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.22;
  color: #27313b;
`;

const Paragraph = styled.p`
  line-height: 1.38;
  letter-spacing: normal;
  font-size: 13px;
  color: #27313b;
`;

export default TermsText;
