import React from 'react';
import { Redirect, useLocation } from 'react-router';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { UNEXPECTED_ERROR } from '@src/libs/error';
import { AuthSocialAccountType } from '@src/__generated__/globalTypes';
import { getRedirectPath } from '@src/libs/auth';
import { useQueryHelper } from '@src/libs/hooks';
import InitialLoading from '@src/components/molecules/InitialLoading';
import { ROUTES } from '@src/shared/routes';

const RedirectTwitter = () => {
  const { search } = useLocation();
  const { t, enqueueSnackbar } = useQueryHelper();
  const response = search.substr(1);
  const redirectType = localStorage.getItem('redirectType') as FE_REDIRECT_MAPPING | null;
  localStorage.removeItem('redirectType');

  if (!redirectType) {
    enqueueSnackbar(t(UNEXPECTED_ERROR), { variant: 'error' });
    if (window.opener) {
      window.close();
    }

    return <Redirect to={ROUTES.ROOT} />;
  }

  const redirectPath = getRedirectPath(AuthSocialAccountType.TWITTER, redirectType);
  const redirectUrl = `/${redirectPath}?${response}`;

  if (window.opener) {
    window.close();
    window.opener.postMessage({ redirectPath: redirectUrl }, '*');

    return <InitialLoading />;
  }

  return <Redirect to={redirectUrl} />;
};

export default RedirectTwitter;
