import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import TextForm from '@src/components/molecules/TextForm';
import { ThemeButton } from '@src/components/atoms/Button';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import Icomoon from '@src/components/atoms/Icomoon';
import { css } from '@emotion/core';
import YearMonthDayPicker from '@src/components/molecules/YearMonthDayPicker';
import useFanCountries from '@src/pages/Fans/useFanCountries';
import SingleSelectField from '@src/components/molecules/SingleSelectField';
import SentenceForm from '@src/components/molecules/SentenceForm';
import { getOptions } from '@src/libs/form';
import { FanGender } from '@src/__generated__/globalTypes';
import { usePageLayout } from '@src/libs/hooks';
import { mainBlack } from '@src/libs/pallete';
import useProvinceOptions from './useProvinceOptions';

export interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  contactNumber: string;
  birthday: string;
  gender: string;
  countryId: string;
  province: string;
  city: string;
  postalCode: string;
  address1: string;
  address2: string;
  tags: string[];
  tagEntry: string;
}

interface FanDetaisFormProps {
  editable: boolean;
}

const FanDetailsForm = ({ editable }: FanDetaisFormProps) => {
  const { t } = useTranslation();
  const { fanCountries } = useFanCountries();
  const { isMobileView } = usePageLayout();

  const {
    formState: { errors, isSubmitting },
    register,
    setValue,
    watch,
  } = useFormContext<FormValues>();
  const [countryId, birthday, gender, province, tags, tagEntry] = watch([
    'countryId',
    'birthday',
    'gender',
    'province',
    'tags',
    'tagEntry',
  ]);

  const { provinceOptions, refetch } = useProvinceOptions({ countryId });

  const countryOptions = useMemo(() => getOptions(fanCountries), [fanCountries.length]);

  const genderOptions = [
    { value: FanGender.FEMALE, label: 'Female' },
    { value: FanGender.MALE, label: 'Male' },
    { value: FanGender.OTHER, label: 'Other' },
  ];

  useEffect(() => {
    refetch({
      input: {
        countryId,
      },
    });
  }, [countryId]);

  const handleChangeCountry = (value: string) => {
    setValue('countryId', value);
    // Reset province after changing country
    if (countryId !== value) {
      setValue('province', '');
    }
  };

  const handleClickAddTag = () => {
    setValue('tags', [...tags, ...tagEntry.split(',').filter(item => !!item)]);
    setValue('tagEntry', '');
  };

  const handleClickDelete = (tag: string) => {
    if (editable) {
      setValue(
        'tags',
        tags.filter(item => item !== tag)
      );
    }
  };

  return (
    <div>
      <div css={styles.container}>
        <FieldRow>
          <Label>{t('Name')}:</Label>
          <Col>
            <TextForm
              css={styles.textForm}
              isRequired={true}
              error={!!errors.firstName}
              placeholder=""
              disabled={!editable}
              {...register('firstName')}
            />
            <ErrorMessage message={errors.firstName?.message} />
          </Col>
          <Col>
            <TextForm
              css={styles.textForm}
              isRequired={true}
              error={!!errors.lastName}
              placeholder=""
              disabled={!editable}
              {...register('lastName')}
            />
            <ErrorMessage message={errors.lastName?.message} />
          </Col>
        </FieldRow>
        <FieldRow>
          <Label>{t('Email')}:</Label>
          <Col>
            <TextForm
              css={styles.textForm}
              error={!!errors.email}
              placeholder="anychat@anymindgroup.com"
              disabled={!editable}
              {...register('email')}
            />
            <ErrorMessage message={errors.email?.message} />
          </Col>
        </FieldRow>
        <FieldRow>
          <Label>{t('Phone Number')}:</Label>
          <TextForm
            css={styles.textForm}
            error={!!errors.contactNumber}
            placeholder="0123456789"
            disabled={!editable}
            {...register('contactNumber')}
          />
        </FieldRow>
        <FieldRow>
          <Label>{t('Birth day')}:</Label>
          <YearMonthDayPicker
            format="yyyy-MM-dd"
            error={!!errors.birthday}
            maxDate={new Date()}
            value={birthday || ''}
            onChange={value => setValue('birthday', value as string)}
            css={styles.pickerStyle}
          />
        </FieldRow>
        <FieldRow>
          <Label>{t('Selector.Gender')}:</Label>
          <SingleSelectField
            placeholder="Select Gender"
            error={!!errors.gender}
            name="gender"
            options={genderOptions}
            value={gender || ''}
            setFieldValue={(_attr, value) => setValue('gender', value)}
            css={styles.selector}
          />
        </FieldRow>
        <FieldRow>
          <Label>{t('Selector.Country')}:</Label>
          <SingleSelectField
            placeholder="Select Country"
            error={!!errors.countryId}
            name="country"
            options={countryOptions}
            value={countryId || ''}
            setFieldValue={(_, value) => handleChangeCountry(value)}
            css={styles.selector}
            disabled={!editable}
          />
        </FieldRow>
        <FieldRow>
          <Label>{t('Selector.Province')}:</Label>
          {/* Show TextFrom from for Shopify tagged fan */}
          {editable ? (
            <SingleSelectField
              placeholder="Select Province"
              error={!!errors.province}
              name="province"
              options={provinceOptions}
              value={province || ''}
              setFieldValue={(_attr, value) => setValue('province', value)}
              css={styles.selector}
              disabled={!editable}
            />
          ) : (
            <TextForm css={styles.textForm} placeholder="" disabled={true} {...register('province')} />
          )}
        </FieldRow>
        <FieldRow>
          <Label>{t('City')}:</Label>
          <TextForm
            css={styles.textForm}
            error={!!errors.city}
            placeholder={t('City')}
            disabled={!editable}
            {...register('city')}
          />
        </FieldRow>
        <FieldRow>
          <Label>{t('Postal Code')}:</Label>
          <TextForm
            css={styles.textForm}
            error={!!errors.postalCode}
            placeholder="〒106-6131"
            disabled={!editable}
            {...register('postalCode')}
          />
        </FieldRow>
        <FieldRow>
          <Label>{t('Address 1')}:</Label>
          <SentenceForm
            css={styles.sentenceForm}
            error={!!errors.address1}
            placeholder="Address 1"
            disabled={!editable}
            {...register('address1')}
          />
        </FieldRow>
        <FieldRow>
          <Label>{t('Address 2')}:</Label>
          <SentenceForm
            css={styles.sentenceForm}
            error={!!errors.address2}
            placeholder="Address 2"
            disabled={!editable}
            {...register('address2')}
          />
        </FieldRow>
        <FieldRow>
          <Label>{t('Tags')}:</Label>
          <div css={styles.tagField}>
            <div css={styles.tagTextBoxWrapper}>
              <Col>
                <TextForm
                  css={styles.textForm}
                  error={!!errors.tagEntry}
                  placeholder={t('Tags')}
                  disabled={!editable}
                  {...register('tagEntry')}
                />
              </Col>
              <ThemeButton
                disabled={!tagEntry}
                customPalette={mainBlack}
                text="Add Tag"
                width="max-content"
                fontSize="12px"
                onClick={handleClickAddTag}
              />
            </div>
            <div css={styles.tagsContainer}>
              {tags
                .filter(item => item)
                .map((tag, index) => (
                  <p key={index + tag} css={styles.tag}>
                    {tag}
                    <Icomoon
                      css={[styles.icon, !editable && { cursor: 'default' }]}
                      icon="clear"
                      onClick={() => handleClickDelete(tag)}
                    />
                  </p>
                ))}
            </div>
          </div>
        </FieldRow>

        {!editable && (
          <div css={styles.disableMessage}>
            ※{t('Annotation.this account is linked with Shopify, so you cannot edit information')}
          </div>
        )}

        {isMobileView && (
          <div css={[styles.submitBtnWrapper, styles.mobileSubmitWrapper]}>
            <ThemeButton disabled={isSubmitting} text="Save" type="submit" theme="blue" width="100%" />
          </div>
        )}
      </div>
      {!isMobileView && (
        <div css={styles.submitBtnWrapper}>
          <ThemeButton disabled={isSubmitting} text="Save" type="submit" theme="blue" width="max-content" />
        </div>
      )}
    </div>
  );
};

const styles = {
  container: css`
    max-width: 600px;
    padding: 24px;
    background-color: #fff;
  `,
  disableMessage: css`
    color: #6e7c89;
    font-size: 14px;
  `,
  mobileSubmitWrapper: css`
    padding-right: 0;

    input {
      width: 100%;
    }
  `,
  pickerStyle: css`
    width: 50%;

    & > div:nth-of-type(2) {
      margin: 0 8px;
    }

    /* stylelint-disable */
    & > div > div {
      border-radius: 3px;
      min-height: 32px;

      & > div > input {
        min-height: 32px;
      }
    }
    /* stylelint-enable */
  `,
  tagBtn: css`
    width: fit-content;
    padding: 0 32px;
    line-height: 32px;
    border-radius: 3px;
    background-color: #27313b;
  `,
  sentenceForm: css`
    flex: 1;

    textarea {
      border-radius: 3px;
      height: 56px;
      padding: 8px 12px;
    }
  `,
  submitBtnWrapper: css`
    height: 64px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #eef3f7;
    padding-right: 24px;
  `,
  selector: css`
    width: 100%;

    /* stylelint-disable */
    & > div > div {
      min-height: 32px;
      border-radius: 3px;

      input {
        min-height: 32px;
      }
    }
    /* stylelint-enable */
  `,
  textForm: css`
    flex: 1;

    /* stylelint-disable */
    & input {
      border-radius: 3px;
      height: 32px;
    }
    /* stylelint-enable */
  `,
  icon: css`
    color: #fff;
    width: 16px;
    cursor: pointer;
    filter: brightness(0.5);

    &:hover {
      filter: brightness(0.7);
    }
  `,
  marginRight8: css`
    margin-right: 8px;
  `,
  tag: css`
    display: inline-flex;
    align-items: center;
    width: max-content;
    background-color: #eef3f7;
    border-radius: 3px;
    height: 24px;
    padding: 0 8px;
    color: #27313b;
  `,
  tagTextBoxWrapper: css`
    display: flex;
    flex: 1;
    gap: 8px;
  `,
  tagField: css`
    display: flex;
    flex: 1;
    flex-direction: column;
  `,
  tagsContainer: css`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 12px;
  `,
};
const FieldRow = styled.div`
  display: flex;
  margin-bottom: 16px;
  flex: 1;
  gap: 8px;
`;
const Label = styled.p`
  font-size: 14px;
  color: #27313b;
  line-height: 32px;
  min-width: 116px;
`;
const Col = styled.div`
  flex: 1;
`;

export default FanDetailsForm;
