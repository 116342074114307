import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import TextForm from '@src/components/molecules/TextForm';

interface FormValues {
  lineLoginChannelId: string;
  liffId: string;
}

const Step4 = ({ formState: { errors }, register }: UseFormReturn<FormValues>) => {
  const { t } = useTranslation();

  return (
    <form>
      <TextForm
        isRequired
        placeholder={t('TextForm.LINE login Channel ID')}
        title="LINE login Channel ID"
        {...register('lineLoginChannelId')}
      />
      <ErrorMessage message={errors.lineLoginChannelId?.message} />
      <TextForm
        css={{ marginTop: 24 }}
        isRequired
        placeholder={t('TextForm.LIFF ID')}
        title="LIFF ID"
        {...register('liffId')}
      />
      <ErrorMessage message={errors.liffId?.message} />
    </form>
  );
};

export default Step4;
