import i18next from 'i18next';

export default () => {
  const isRtl = ['ar'].includes(i18next.language);

  return {
    dir: (isRtl ? 'rtl' : 'ltr') as 'rtl' | 'ltr',
    isRtl,
  };
};
