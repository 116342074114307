import { format } from 'date-fns';
import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import cursorIcon from '@src/assets/icon/castingMarketplace/cursorRed.svg';
import lockIcon from '@src/assets/icon/castingMarketplace/lockOrange.svg';
import notesIcon from '@src/assets/icon/castingMarketplace/notesGreen.svg';
import Empty from '@src/components/atoms/Empty';
import * as List from '@src/components/atoms/List';
import { SliderComponents, StyledComponents } from '@src/components/molecules/SliderTable';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import { formatNumberWithCommas } from '@src/libs/format';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { getOffset, getPageInfo, usePaging } from '@src/libs/paging';
import { LIMIT } from '@src/libs/paging/constant';
import { ViewportType } from '@src/libs/theme';
import { generatePath, ROUTES } from '@src/shared/routes';
import useCampaign from '@src/pages/Marketplace/YourJob/Details/useCampaign';
import useReport from './useReport';

const AffiliateReport = () => {
  const { params, t } = useQueryHelper();
  const { isMobileView } = usePageLayout();
  const currentPage = usePaging();
  const campaignId = Number(params.id);
  const { dataReport, dataReportSummary } = useReport({ campaignId, limit: LIMIT, offset: getOffset(currentPage) });
  const { data: dataCampaign } = useCampaign(campaignId);

  const campaignDetails = dataCampaign?.marketplaceForInfluencer;
  const currency = campaignDetails?.currency || '';
  const isRevenuePerClick = typeof campaignDetails?.revenuePerClick === 'number';
  const reportList = dataReport?.marketplaceAffiliateReportForInfluencer || [];
  const isEmptyData = reportList.length < 1;
  const reportSummary = dataReportSummary?.marketplaceAffiliateReportSummaryForInfluencer;
  const totalCount = dataReport?.marketplaceAffiliateReportCountForInfluencer?.totalNumber || 0;
  const { firstIndex, lastIndex, totalPages } = getPageInfo(currentPage, totalCount, LIMIT);

  const summaryItems = [
    { icon: notesIcon, title: 'Label.Commissions (estimated)', value: reportSummary?.commission || 0 },
    { icon: lockIcon, title: 'Label.Orders (estimated)', value: reportSummary?.conversions || 0 },
    { icon: cursorIcon, isHidden: !isRevenuePerClick, title: 'Label.Clicks', value: reportSummary?.clicks || 0 },
  ];

  return (
    <div css={styles.container}>
      {!isMobileView && (
        <BackNavigator
          css={{ marginBottom: 16 }}
          title="Campaign's Report"
          to={generatePath(ROUTES.MARKETPLACE_YOUR_JOB_ID, { id: campaignId })}
        />
      )}

      <div css={styles.description}>{t('Annotation.The report serves as an estimate of your daily conversions')}</div>

      <div css={styles.summaryCardContainer}>
        {summaryItems
          .filter(item => !item.isHidden)
          .map((item, index) => {
            const { icon, title, value } = item;

            return (
              <div css={styles.summaryCard} key={index}>
                <div>
                  <div>{t(title)}</div>
                  <div>{formatNumberWithCommas(value, 0)}</div>
                </div>
                <img height="32" src={icon} width="32" />
              </div>
            );
          })}
      </div>

      <div css={styles.titleContainer}>{t('Title.Daily Report')}</div>
      <TableWrapper isEmptyData={isEmptyData}>
        <SliderComponents.Container>
          <SliderComponents.SliderSection>
            <SliderComponents.SliderTable>
              <thead css={{ backgroundColor: '#f6f8fa' }}>
                <SliderComponents.HeaderRow>
                  <List.HeaderColumn title="Date" />
                  <List.HeaderColumn css={{ padding: 0 }} title="Order" />
                  {isRevenuePerClick && <List.HeaderColumn css={{ padding: 0 }} title="Click" />}
                  <List.HeaderColumn css={{ padding: 0 }} title="Commission" />
                </SliderComponents.HeaderRow>
              </thead>

              <tbody>
                {reportList.map((item, index) => {
                  const { date, commission, clicks, conversions } = item;

                  return (
                    <StyledComponents.StyledRowNew key={index}>
                      <List.TextColumn
                        css={styles.dateColumn}
                        data={date ? format(new Date(date), 'MMM dd, yyyy hh:mm a') : '-'}
                      />
                      <List.TextColumn css={{ textAlign: 'center' }} data={formatNumberWithCommas(conversions, 0)} />
                      {isRevenuePerClick && (
                        <List.TextColumn css={{ textAlign: 'center' }} data={formatNumberWithCommas(clicks, 0)} />
                      )}
                      <List.TextColumn data={`${formatNumberWithCommas(commission, 0)} ${currency}`} />
                    </StyledComponents.StyledRowNew>
                  );
                })}
              </tbody>
            </SliderComponents.SliderTable>
          </SliderComponents.SliderSection>
        </SliderComponents.Container>

        {isEmptyData && (
          <Empty
            content="You can track performance on daily basis"
            css={styles.emptyContent}
            title="There are no reports"
          />
        )}
      </TableWrapper>

      <SliderComponents.Pager
        css={styles.pager}
        currentPage={currentPage}
        first={firstIndex}
        last={lastIndex}
        totalCount={totalCount}
        totalPages={totalPages}
      />
    </div>
  );
};

const TableWrapper = styled(SliderComponents.Wrapper)<{ isEmptyData: boolean }>`
  border-left: none;
  border-right: none;
  box-shadow: 4px 0 7px -4px rgba(222, 229, 236, 0.5), -4px 0 2px -4px #dee5ec;
  margin: 0;
  padding: 0;
  ${({ isEmptyData }) => isEmptyData && 'border-radius: 0 0 13px 13px;'}
`;

const styles = {
  container: css`
    padding: 16px 24px;

    @media (max-width: ${ViewportType.TABLET}px) {
      padding: 16px;
    }
  `,
  dateColumn: css`
    width: 75%;

    @media (max-width: ${ViewportType.TABLET}px) {
      width: fill-available;
    }
  `,
  description: css`
    color: #27313b;
    font-size: 14px;
    margin-bottom: 12px;

    @media (max-width: ${ViewportType.TABLET}px) {
      font-size: 12px;
    }
  `,
  emptyContent: css`
    background-color: #fff;
    border-radius: 0 0 13px 13px;
    border-top: 1px solid #dee5ec;

    & > h6 {
      color: #27313b;
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 16px;
    }

    & > p {
      color: #6e7c89;
      font-size: 16px;
    }
  `,
  pager: css`
    background-color: #fff;
    border-radius: 0 0 13px 13px;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
    padding: 12px 8px;
  `,
  summaryCard: css`
    background-color: #fff;
    border-radius: 13px;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
    display: flex;
    flex: 1;
    padding: 24px;

    & > div:nth-of-type(1) {
      display: grid;
      flex: 1;
      gap: 8px;

      & > div:nth-of-type(1) {
        color: #27313b;
        font-size: 11px;
      }

      & > div:nth-of-type(2) {
        color: #27313b;
        font-size: 20px;
        font-weight: 600;
      }
    }
  `,
  summaryCardContainer: css`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 16px;

    @media (max-width: ${ViewportType.TABLET}px) {
      & > div:nth-of-type(1) {
        flex-basis: 100%;
      }
    }
  `,
  titleContainer: css`
    background-color: #fff;
    border-radius: 13px 13px 0 0;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
    color: #27313b;
    font-size: 16px;
    font-weight: 600;
    padding: 24px;
  `,
};

export default AffiliateReport;
