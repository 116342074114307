import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import React, { useMemo, useState } from 'react';
import { generatePath, Link } from 'react-router-dom';
import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { PopoverPrimitives } from '@src/components/molecules/Popover';
import anyCreatorLogo from '@src/assets/icon/anyCreatorBlack.svg';
import picIcon from '@src/assets/icon/chat/pic.svg';
import Icomoon from '@src/components/atoms/Icomoon';
import { getDateLocal } from '@src/components/atoms/List/DateColumn';
import TutorialTool, { TutorialTypes } from '@src/components/organisms/TutorialTool';
import { useAuthData, useQueryHelper, useUuumInfluencerView } from '@src/libs/hooks';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import { ViewportType } from '@src/libs/theme';
import { notificationIcon, notificationRedirectUrl } from '@src/pages/Notification/helper';
import useNotification from '@src/pages/Notification/useNotification';
import { dynamicTitleState, selectedChatState, toolbarBackState, useRecoilState } from '@src/recoilAtoms';
import TextCutter from '@src/components/atoms/TextCutter';
import { ROUTES } from '@src/shared/routes';
import { NotificationId, UserRoles } from '@src/__generated__/globalTypes';
import { closeIcon } from '@src/assets/htmlCodes';
import { HamburgerIcon } from '@src/assets/reactIcons/hamburger';
import { Icon } from '@src/components/atoms/Icon';

interface MobileToolbarProps {
  backPath?: string;
  toolbarTitle?: string;
  onClickMenu?: () => void;
}

const MobileToolbar = ({ backPath, toolbarTitle, onClickMenu }: MobileToolbarProps) => {
  const [isOpen, setOpen] = useState(false);
  const { role } = useAuthData();
  const { isUuumView } = useUuumInfluencerView();
  const { data, clickNotification } = useNotification();
  const { history, i18n, params, pathname, t, search, path } = useQueryHelper();
  const redirectPath = backPath ? generatePath(backPath, params as any) + search : '';
  const isActiveChatPath = path === ROUTES.CHAT_ACTIVE_ID;
  const isDynamicTitle = toolbarTitle === '_dynamic'; // if need to pass another _dynamic title - we can make recoil atom for this
  const isHomePage = pathname === ROUTES.HOME;
  const isStaff = role === UserRoles.STAFF;
  const notificationLimit = 3;
  const [{ pic, fanId }, setSelectedChartState] = useRecoilState(selectedChatState);
  const [{ title, interpolation }] = useRecoilState(dynamicTitleState);
  const [{ backMethod }] = useRecoilState(toolbarBackState);

  const tutorialType = useMemo(() => {
    switch (pathname) {
      case ROUTES.CASTING_YOUR_JOB:
        return TutorialTypes.CASTING;
      case ROUTES.MARKETPLACE_SEARCH_JOB:
        return TutorialTypes.MARKETPLACE_SEARCH_JOB;
      case ROUTES.PAYMENT:
        return TutorialTypes.PAYMENT;
      default:
        return '';
    }
  }, [pathname]);

  const hasNotice = data?.getNotifications?.notifications
    .slice(0, notificationLimit)
    .find(notification => !notification.isClicked);
  const notifications =
    data?.getNotifications?.notifications
      .filter(({ notificationId }) =>
        isStaff
          ? [
              NotificationId.PAYMENT_COMPLETED,
              NotificationId.PAYMENT_FAILED,
              NotificationId.PAYMENT_REQUEST_DONE,
            ].includes(notificationId)
          : true
      )
      .slice(0, notificationLimit) || [];

  return (
    <>
      <div css={styles.toolbar}>
        {backPath || backMethod ? (
          <IconContainer onClick={() => (backMethod ? backMethod() : history.push(redirectPath))}>
            <Icon>
              <Icomoon css={{ transform: 'rotate(90deg)' }} icon="arrow-down" />
            </Icon>
          </IconContainer>
        ) : (
          <IconContainer onClick={onClickMenu}>{<HamburgerIcon />}</IconContainer>
        )}
        <div css={styles.logoContainer}>
          {toolbarTitle ? (
            <TextCutter
              css={styles.title}
              text={!isDynamicTitle ? t(`MenuTitle.${toolbarTitle}`) : t(title, { ...interpolation })}
              lines={1}
            />
          ) : !isUuumView ? (
            <Link to={ROUTES.ROOT}>
              <img alt="logo" height="32" src={anyCreatorLogo} />
            </Link>
          ) : null}
        </div>

        {isHomePage ? (
          <HintContainer>
            <PopoverPrimitives.Root
              open={isOpen}
              onOpenChange={open => {
                setOpen(open);
              }}
            >
              <PopoverPrimitives.Trigger>
                {
                  <div css={{ position: 'relative' }}>
                    {hasNotice && <div css={styles.notificationIndicator} />}
                    <Icomoon icon="notification-black" size={28} />
                  </div>
                }
              </PopoverPrimitives.Trigger>
              <PopoverPrimitives.Portal>
                <PopoverPrimitives.Content
                  sideOffset={14}
                  side="bottom"
                  align={'center'}
                  css={{ background: 'white', borderRadius: '5px' }}
                >
                  <PopoverPrimitives.Arrow width={16} height={20} fill="white" css={{ marginLeft: '6px' }} />
                  <div css={styles.menu}>
                    <div css={styles.menuTitleContainer}>
                      <div>{t('Notification')}</div>
                      <PopoverPrimitives.Close asChild>
                        <span css={{ fontSize: '24px' }}>{closeIcon}</span>
                      </PopoverPrimitives.Close>
                    </div>
                    {notifications.map(notification => {
                      const { content, created, id, isClicked, notificationId } = notification;
                      const campaignId = content?.campaign_id || '';
                      const campaignName = content?.campaign_name;
                      const message = t(notificationId.toLowerCase(), {
                        ...(campaignName ? { campaign_name: campaignName } : {}),
                      });

                      return (
                        <NotificationContainer
                          isClicked={isClicked}
                          key={id}
                          to={notificationRedirectUrl(campaignId, notificationId)}
                          onClick={() =>
                            !isClicked
                              ? clickNotification({
                                  variables: {
                                    input: { id },
                                  },
                                })
                              : null
                          }
                        >
                          <div>
                            <div>
                              <div>
                                <img height="30" src={notificationIcon(notificationId)} width="30" />
                              </div>
                            </div>
                            <div>
                              <TextCutter lines={2} text={message} />
                              <div>
                                <Icomoon icon="clock-outlined" size={12} />
                                <span>
                                  {formatDistanceToNow(new Date(created), {
                                    addSuffix: true,
                                    locale: getDateLocal(i18n.language as AppLanguage),
                                  })}
                                </span>
                              </div>
                            </div>
                          </div>
                        </NotificationContainer>
                      );
                    })}
                    <div css={styles.menuActionContainer}>
                      <Link to={ROUTES.NOTIFICATION}>{t('Button.View all notifications')}</Link>
                    </div>
                  </div>
                </PopoverPrimitives.Content>
              </PopoverPrimitives.Portal>
            </PopoverPrimitives.Root>
          </HintContainer>
        ) : (
          tutorialType && (
            <HintContainer>
              <TutorialTool type={tutorialType} />
            </HintContainer>
          )
        )}
        {isActiveChatPath ? (
          <div css={styles.chatMenu}>
            <img
              src={picIcon}
              alt="PIC"
              css={[styles.chatMenuItem, !pic ? styles.greyscaleIcon : '']}
              onClick={() =>
                setSelectedChartState(prevState => ({
                  ...prevState,
                  isChartStateMenuOpen: !prevState.isChartStateMenuOpen,
                }))
              }
            />
            <Link to={fanId ? `${pathname}/${fanId}` : '#'} css={{ display: 'flex', alignItems: 'center' }}>
              <HamburgerIcon color="#6E7C89" />
            </Link>
          </div>
        ) : null}
      </div>
    </>
  );
};

const HintContainer = styled.div`
  display: flex;
  flex-basis: 10%;
  justify-content: center;

  @media (max-width: ${ViewportType.SMALL}px) {
    flex-basis: 15%;
  }
`;

const IconContainer = styled.div`
  display: flex;
  flex-basis: 10%;
  justify-content: center;

  & i {
    font-size: 25px;
  }

  @media (max-width: ${ViewportType.SMALL}px) {
    flex-basis: 15%;
  }
`;

const NotificationContainer = styled(Link, { shouldForwardProp: prop => isPropValid(prop) })<{
  isClicked: boolean;
}>`
  background-color: ${({ isClicked }) => (isClicked ? '#fff' : '#f1f8ff')} !important;

  & > div {
    border-bottom: 1px solid #eef3f7;
    display: flex;
    gap: 8px;
    padding: 16px;

    & > div:nth-of-type(1) {
      & > div {
        align-items: center;
        background-color: #fff;
        border-radius: 12px;
        display: flex;
        height: 40px;
        justify-content: center;
        width: 40px;
      }
    }

    /* stylelint-disable no-descending-specificity, value-no-vendor-prefix */
    & > div:nth-of-type(2) {
      width: 100%;

      & > div:nth-of-type(1) {
        color: #27313b;
        display: -webkit-box !important;
        font-size: 14px;
        font-weight: ${({ isClicked }) => (isClicked ? 400 : 600)};
      }

      & > div:nth-of-type(2) {
        align-items: center;
        color: #6e7c89;
        display: flex;
        font-size: 12px;
        gap: 4px;
      }
    }
  }
`;

const styles = {
  logoContainer: css`
    display: flex;
    flex-basis: 80%;
    justify-content: center;

    @media (max-width: ${ViewportType.SMALL}px) {
      flex-basis: 70%;
    }
  `,
  title: css`
    color: #27313b;
    font-size: 18px;
    font-weight: 600;
  `,
  chatMenu: css`
    display: flex;
    column-gap: 8px;
    padding: 0 16px;
  `,
  chatMenuItem: css`
    width: 24px;
    height: 24px;
    cursor: pointer;
    color: #6e7c89;

    &:hover {
      opacity: 0.8;
    }
  `,
  greyscaleIcon: css`
    filter: grayscale(1);
  `,
  menu: css`
    width: calc(100vw - 32px);
    border-radius: 5px;
    box-shadow: 0 0 20.1223px rgba(39, 49, 59, 0.25);
    top: 58px !important;
    overflow: inherit;

    & > ul {
      padding: 0;
    }
  `,
  menuActionContainer: css`
    display: flex;
    justify-content: center;
    padding: 8px 0;

    & > a {
      border: 1px solid #3892e5;
      border-radius: 15px;
      color: #3892e5;
      font-size: 12px;
      padding: 8px 16px;
    }
  `,
  menuArrow: css`
    background-color: #fff;
    border: inherit;
    border-radius: 8px;
    clip-path: polygon(50% 0%, 100% 50%, 0% 100%);
    display: block;
    height: 30px;
    position: absolute;
    right: 12px;
    transform: rotate(135deg);
    top: -12px;
    width: 30px;
    z-index: 10;

    @media (max-width: ${ViewportType.MEDIUM}px) {
      right: 0;
    }
  `,
  menuTitleContainer: css`
    border-bottom: 1px solid #eef3f7;
    display: flex;
    padding: 8px 16px;
    align-items: center;

    & > div {
      display: flex;
      color: #27313b;
      flex: 1;
      font-size: 16px;
      font-weight: 600;
    }
  `,
  notificationIndicator: css`
    background-color: #ff5f5f;
    border-radius: 50%;
    height: 10px;
    position: absolute;
    right: 0;
    top: 0;
    width: 10px;
  `,
  toolbar: css`
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #e5ebf1;
    box-shadow: 0 1px 2px 0 #dee5ec;
    display: flex;
    height: 56px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 4;
  `,
};

export default MobileToolbar;
