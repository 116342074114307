import { SerializedStyles } from '@emotion/core';
import React, { forwardRef, Ref } from 'react';
import { Input } from '@anymindgroup/any-ui-react';
import '@anymindgroup/any-ui-react/dist/style.css';
import Label from '@src/components/atoms/Label';
import { TextSearchProps } from '@src/components/atoms/InputText/TextSearch';
import { useDirLayout } from '@src/libs/hooks';

export interface SearchFormProps extends Omit<TextSearchProps, 'onKeyPress'> {
  className?: string;
  title?: string;
  isRequired?: boolean;
  help?: string;
  onEnterKeyPress?: () => void;
  inputStyle?: SerializedStyles;
  searchable?: boolean;
  height?: string;
  width?: string;
}

const SearchForm = forwardRef((props: SearchFormProps, ref: Ref<HTMLDivElement>) => {
  const {
    className,
    title,
    isRequired,
    help,
    placeholder,
    inputStyle,
    value,
    name,
    onBlur,
    onChange,
    onEnterKeyPress,
    autoFocus,
    disabled,
    searchable = true,
    width,
    height = '32px',
  } = props;
  const { dir } = useDirLayout();

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!!onEnterKeyPress && e.charCode === 13) {
      onEnterKeyPress();
    }
  };

  return (
    <div className={className} ref={ref}>
      {title ? <Label title={title} isRequired={isRequired} help={help} /> : null}
      <Input
        searchable={searchable}
        name={name}
        value={value}
        width={width}
        height={height}
        required={isRequired}
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={onChange}
        onKeyPress={onKeyPress}
        autoFocus={autoFocus}
        disabled={disabled}
        css={[inputStyle]}
        dir={dir}
      />
    </div>
  );
});

SearchForm.displayName = 'SearchForm';

export default SearchForm;
