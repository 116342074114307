import styled from '@emotion/styled';
import { useMediaQuery } from 'react-responsive';
import { format, isDate, isValid, parse } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import classNames from 'react-day-picker/src/classNames';
import 'react-day-picker/lib/style.css';
import { useTranslation } from 'react-i18next';
import { localeUtils } from '@src/libs/date';
import { localizedDateFormatter } from '@src/components/atoms/List/DateColumn';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import { Modifier } from 'react-day-picker';
import { DEFAULT_FNS_DATE_FORMAT } from '../../../libs/constant';
import { CustomInput, Navbar, NavbarProps } from './Components';
import { DAYPICKER_INPUT_FORMAT, getDefaultDate, placeholderSingle } from './helpers';

interface DatePickerProps {
  value: string;
  handleChangeDay: (day: string) => void;
  error?: boolean;
  disabled?: boolean;
  rangeTo?: Date;
  disabledRange?: Modifier | Modifier[];
  dateFormat?: string;
  inputFormat?: string;
  placeholder?: string;
  showExpand?: boolean;
  showInSingleMonth?: boolean;
}

const DayPickerHandInput = ({
  value,
  disabled,
  error,
  handleChangeDay,
  rangeTo,
  disabledRange,
  inputFormat,
  placeholder,
  showExpand,
  showInSingleMonth,
}: DatePickerProps) => {
  const { i18n } = useTranslation();
  // State
  const defaultInputState = {
    from: getDefaultDate(value),
  };
  const [state, setState] = useState<{
    from: Date | null;
  }>(defaultInputState);

  const inputRef = useRef(null);
  const isMobile = showInSingleMonth || useMediaQuery({ maxWidth: '450px' });

  useEffect(() => {
    setState(defaultInputState);
  }, [value]);

  // Handlers
  const handleDayClick = (day: Date) => {
    setState({
      from: day,
    });
    handleChangeDay(format(day, DEFAULT_FNS_DATE_FORMAT));
  };

  const handleChange = (day: Date) => {
    if (!day || !isValid(day)) {
      return;
    }
    handleDayClick(new Date(day));
  };

  const parseDate = (str: string, parseFormat: string) => {
    if (str.length !== parseFormat.length) {
      return undefined;
    }
    const parsed = parse(str, parseFormat, new Date());

    if (isDate(parsed) && isValid(parsed)) {
      return parsed;
    }

    return undefined;
  };
  const formatDate = (date: Date, pickerFormat: string) => format(date, pickerFormat);

  return (
    <WidgetBlock>
      <DayPickerInput
        formatDate={formatDate}
        format="MMM dd, yyyy"
        placeholder={placeholder || placeholderSingle}
        parseDate={parseDate}
        value={
          state.from
            ? localizedDateFormatter(state.from, inputFormat || DAYPICKER_INPUT_FORMAT, i18n.language as AppLanguage)
            : ''
        }
        onDayChange={handleChange}
        inputProps={{ error: !!error, disabled: !!disabled, showExpand }} // to pass props in CustomInput
        component={CustomInput}
        ref={inputRef}
        onBlur={() => null} // prevent blur effect after change month
        dayPickerProps={{
          numberOfMonths: isMobile ? 1 : 2,
          // @ts-ignore
          localeUtils,
          locale: i18n.language,
          selectedDays: state.from || undefined,
          ...(rangeTo && { disabledDays: { after: rangeTo } }),
          // eslint-disable-next-line react/display-name
          navbarElement: (navbarProps: NavbarProps) => <Navbar {...navbarProps} isMobile={isMobile} />,
          classNames: { ...classNames, ...classes },
          captionElement: () => null,
          initialMonth: state.from || new Date(),
          disabledDays: disabledRange,
        }}
        classNames={{
          overlayWrapper: 'DayPickerInput-OverlayWrapper',
          overlay: 'DayPickerInput-Overlay',
          container: classes.inputContainer,
        }}
      />
    </WidgetBlock>
  );
};

const classes = {
  tooltip: 'tooltip',
  button: 'button',
  actions: 'actions',
  months: 'months',
  today: 'today',
  selected: 'selected',
  day: 'day',
  disabled: 'disabled',

  container: 'container',
  wrapper: 'wrapper',
  inputContainer: 'inputContainer',
};

const WidgetBlock = styled.div`
  flex-direction: column;

  & .DayPickerInput-Overlay {
    z-index: 3;

    .tooltip {
      font-size: 12px;
      letter-spacing: 0.02em;
    }

    .button {
      color: rgb(23, 156, 215);
    }

    .actions {
      align-items: baseline;
    }

    .months {
      display: flex;
      flex-wrap: nowrap;
    }

    .today {
      color: tomato;
    }

    .selected {
      background-color: #4a90e2;
      color: #fff;
      border-radius: 0 !important;
    }

    .day {
      display: table-cell;
      padding: 0.5em;
      border-radius: 0;
      vertical-align: middle;
      text-align: center;
      cursor: pointer;

      .outside {
        background-color: #fff !important;
      }

      &:not($selected) {
        &:hover {
          background-color: #e1e2e3;
        }
      }
    }

    .disabled {
      opacity: 0.3;
      background-color: #fff !important;
      color: #000;
      cursor: unset;
      outline: none;

      &:hover {
        background-color: #fff !important;
        color: #000;
      }
    }

    .container {
      input {
        width: 200px;
        height: 38px;
        border: 1px solid #dee5ec;
        border-radius: 20px;
      }

      left: 20px !important;
    }

    .wrapper {
      outline: none;
      font-size: 14px;
    }

    .inputContainer {
      width: 100%;
    }
  }
`;

export default DayPickerHandInput;
