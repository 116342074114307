import { useHistory, useLocation, useParams } from 'react-router';
import { getAuthSocialAccountType, getSocialAccountType } from '../utils/utils';

export const useProviderResponse = (redirectTo: string) => {
  const { search } = useLocation();
  const { provider = '' } = useParams<{ provider: string }>();
  const history = useHistory();

  const response = search.substr(1);

  if (!response) {
    history.push(redirectTo);
  }

  return { provider: getSocialAccountType(provider), response };
};

export const useAuthProviderResponse = (redirectTo?: string) => {
  const { search } = useLocation();
  const { provider = '' } = useParams<{ provider: string }>();
  const history = useHistory();

  const response = search.substr(1);

  if (redirectTo && !response) {
    history.push(redirectTo);
  }

  return { provider: getAuthSocialAccountType(provider), response };
};
