import styled from '@emotion/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface ErrorMessageProps {
  className?: string;
  message?: string;
  withoutTranslation?: boolean;
}

const ErrorMessage = (props: ErrorMessageProps) => {
  const { className, message, withoutTranslation } = props;
  const { t } = useTranslation();

  return message ? <Message className={className}>{withoutTranslation ? message : t(message)}</Message> : null;
};

const Message = styled.p`
  padding: 2px 0 0 2px;
  color: tomato;
  font-size: 12px;
`;

export default ErrorMessage;
