import styled from '@emotion/styled';
import * as List from '../../atoms/List';
import SliderComponents from './BaseComponents';

const StyledStickyWrapper = styled(SliderComponents.StickyWrapper)`
  width: 200px;
  background-color: #f6f8fa;
`;

const StickyTableRow = styled(SliderComponents.TitleWrapper)`
  height: 72px;
  display: flex;
  padding: 0;
  flex-direction: row;
  justify-content: unset;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 35px;
  margin: 0 23px 0;
  padding: 8px 15px;
  background-color: #fff;
  border: 1px solid #dee5ec;
  border-top: none;
  border-bottom: none;
`;

const StyledRow = styled(SliderComponents.Row)`
  height: 64px;

  td {
    border-top: 1px solid #efefef;
    padding: 0 8px;
  }
`;
const StyledRowNew = styled.tr<{ isRowChecked?: boolean }>`
  height: 64px;

  td {
    background-color: ${({ isRowChecked }) => (!!isRowChecked ? '#fffde7' : '#fff')};
    border-top: 1px solid #efefef;
    padding: 0 8px;
  }

  &:hover {
    background-color: #f6f8fa;

    td {
      background-color: #f6f8fa;
    }
  }

  a:hover {
    color: #179cd7;
    text-decoration: underline;
  }
`;

const NumberColumn = styled(List.TextColumn)`
  & div {
    justify-content: flex-end;
  }
`;
const DateColumn = styled(List.DateColumn)``;

const StyledHeaderColumn = styled(List.HeaderColumn)`
  padding: 0;
  vertical-align: bottom;

  & > div {
    padding-left: 16px;
    height: 44px;
    border-top: 1px solid #dee5ec;
  }
`;
const AbsoluteTableHeaderRow = styled.th`
  position: absolute;
  top: 5px;
  z-index: 2;
  font-size: 11px;
  font-weight: 600;
`;
const AbsoluteTableHeaderDiv = styled.div`
  position: absolute;
  top: -12px;
  left: 3px;
  z-index: 2;
  font-size: 11px;
  font-weight: 600;
`;

export default {
  StyledStickyWrapper,
  StickyTableRow,
  ButtonWrapper,
  StyledRow,
  NumberColumn,
  DateColumn,
  StyledRowNew,
  StyledHeaderColumn,
  AbsoluteTableHeaderRow,
  AbsoluteTableHeaderDiv,
};
