import styled from '@emotion/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ViewportType } from '@src/libs/theme';

interface EmptyProps {
  className?: string;
  title?: string;
  content?: string;
}
const Empty = ({ className, content, title }: EmptyProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper className={className}>
      <div>
        <Title>{t(title || 'General.Nothing has been found')}</Title>
        <Content>{t(content || 'General.Please try a different query')}</Content>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  align-items: center;
  text-align: center;
  display: grid;
  flex-direction: column;
  flex: 1;
  height: 446px;

  @media (max-width: ${ViewportType.TABLET}px) {
    height: 223px;
    padding: 30px;
  }
`;

const Title = styled.h6`
  color: #757575;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.02em;
`;

const Content = styled.p`
  margin-top: 8px;
  color: #757575;
  font-size: 14px;
`;

export default Empty;
