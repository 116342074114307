import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Avatar from '@src/components/atoms/Avatar';
import { SocialButton } from '@src/components/atoms/Button';
import { formatNumberWithCommas } from '@src/libs/format';
import { defaultInfluencerAvatar } from '@src/libs/image';
import { switchImage, switchText } from '@src/libs/SocialMedia';
import { SocialAccountType } from '@src/__generated__/globalTypes';

declare const IS_DISABLED_ON_PROD: boolean;

interface SocialAccountConnectorProps {
  accounts: {
    avatar: string | null;
    followersCount: number | null;
    id: string;
    isBusiness?: boolean | null;
    name: string;
    needReconnect?: boolean;
    url: string | null;
  }[];
  socialMedia: SocialAccountType;
  onClickConnect: () => void;
  onClickReconnect?: (socialAccountId: string) => void;
}

const SocialAccountConnector = ({
  accounts,
  socialMedia,
  onClickConnect,
  onClickReconnect,
}: SocialAccountConnectorProps) => {
  const { t } = useTranslation();
  const isIgAccount = socialMedia === SocialAccountType.INSTAGRAM;

  return (
    <div css={styles.container}>
      {accounts.length > 0 ? (
        <>
          {accounts.map(account => {
            const { avatar, id, isBusiness, followersCount, name, needReconnect } = account;

            return (
              <div css={styles.connectedSocialAccountContent} key={`${id}_${account.name}`}>
                <div>
                  <img alt="socialMedia" height="24" src={switchImage(socialMedia, true)} width="24" />
                  <Avatar size={24} src={defaultInfluencerAvatar(avatar)} />
                </div>
                <div>
                  <div>{name}</div>
                  <div>{`${formatNumberWithCommas(followersCount, 0)} ${t('Followers')}`}</div>
                </div>
                {((isIgAccount && !isBusiness) || (!IS_DISABLED_ON_PROD && needReconnect)) && (
                  <div>
                    <StyledSocialButton
                      css={styles.reconnectBtn}
                      light
                      socialMedia={socialMedia}
                      title="Reconnect"
                      onClick={() => onClickReconnect?.(id)}
                    />
                  </div>
                )}
              </div>
            );
          })}
          <div css={styles.addAccountContainer}>
            <StyledSocialButton
              light
              socialMedia={socialMedia}
              title="Add Account"
              onClick={onClickConnect}
              css={isIgAccount ? { borderWidth: 0 } : {}}
            />
          </div>
        </>
      ) : (
        <div css={styles.socialAccountContent}>
          <div css={{ gap: '8px' }}>
            <img alt="socialMedia" height="24" src={switchImage(socialMedia, true)} width="24" />
            <span>{switchText(socialMedia)}</span>
          </div>
          <div>
            <StyledSocialButton light socialMedia={socialMedia} title="Connect" onClick={onClickConnect} />
          </div>
        </div>
      )}
    </div>
  );
};

const StyledSocialButton = styled(SocialButton)`
  border-radius: 5px;
  height: 32px;
  justify-content: flex-end;
  margin-top: 0;
  padding-right: 16px;
  width: max-content;

  & > img {
    height: 16px;
    width: 16px;
  }

  .prefix-icon {
    position: relative;
  }

  & > span {
    font-size: 12px;
    font-weight: 600;

    [dir='rtl'] & {
      padding: 0 0 0 16px;
    }
  }
`;

const styles = {
  addAccountContainer: css`
    padding: 16px;
  `,
  connectedSocialAccountContent: css`
    align-items: center;
    border-bottom: 1px solid #eef3f7;
    display: flex;
    flex-wrap: wrap;
    padding: 16px;
    gap: 16px;

    & > div:nth-of-type(1) {
      display: flex;
      flex-wrap: wrap;

      & > img:nth-of-type(1) {
        [dir='rtl'] & {
          margin-left: -4px;
        }
      }
      /* stylelint-disable no-descending-specificity */
      & > img:nth-of-type(2) {
        margin-left: -4px;

        [dir='rtl'] & {
          margin-left: 0;
        }
      }
    }

    & > div:nth-of-type(2) {
      display: grid;
      width: 75%;

      & > div:nth-of-type(1) {
        color: #27313b;
        font-size: 14px;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      & > div:nth-of-type(2) {
        color: #6e7c89;
        font-size: 12px;
      }
    }
  `,
  container: css`
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
    margin-bottom: 16px;
  `,
  reconnectBtn: css`
    padding: 0 16px;

    & > div > i {
      display: none;
    }
  `,
  socialAccountContent: css`
    display: flex;
    flex-wrap: wrap;
    padding: 16px;

    & > div:nth-of-type(1) {
      align-items: center;
      display: flex;
      flex-wrap: wrap;

      & > span {
        color: #27313b;
        font-size: 14px;
        font-weight: 600;
        margin-left: 16px;
      }
    }

    & > div:nth-of-type(2) {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
    }
  `,
};

export default SocialAccountConnector;
