import { CampaignSocialMediaType, Genders, ORDER, SocialAccountType } from '../__generated__/globalTypes';

export const APP_NAME = 'AnyCreator';
export const LIMIT = 20;
export const LIMIT_100 = 100;
export const LIMIT_10 = 10;
export type MenusType = typeof MENUS[keyof typeof MENUS];
export const MENUS = {
  Profile: 'Profile',
  Payment: 'Payment',
  Help: 'Help',
  Contact: 'Contact',
  YourJob: 'Your Job',
  SearchJob: 'Search Job',
  Notification: 'Notification',
  ProfileSettings: 'Profile Settings',
  SocialAccountConnect: 'Social Account Connect',
  PaymentInformation: 'Payment Information',
  AddressInformation: 'Address Information',
  PaymentHistory: 'Payment History',
  CampaignsDetail: 'Campaigns Detail',
  ProfileConnect: 'Profile Connect',
  EditProfile: 'Edit Profile',
  PostList: 'Post List',
  UploadDraftPost: 'Upload Draft Post',
  CampaignReport: 'Campaign Report',
  PostEdit: 'Post Edit',
  PostFeed: 'Post Feed',
  StoryFeed: 'Story Feed',
  NotFound: 'Not Found',
  VideoList: 'Video List',
  TagRankingList: 'Tag Ranking List',
} as const;
export const GENDERS = [
  { value: Genders.FEMALE, label: 'Female' },
  { value: Genders.MALE, label: 'Male' },
  { value: Genders.NOT_APPLICABLE, label: 'Other' },
];

export const PROVIDERS = [
  { value: 'facebook', label: 'Facebook' },
  { value: 'instagram', label: 'Instagram' },
  { value: 'twitter', label: 'Twitter' },
  { value: 'google-oauth2', label: 'YouTube' },
];

export const COUNTRY_IDS = [
  { value: 'JP', label: 'Japan' },
  { value: 'HK', label: 'Hong Kong' },
  { value: 'ID', label: 'Indonesia' },
  { value: 'KH', label: 'Cambodia' },
  { value: 'MM', label: 'Myanmar' },
  { value: 'MY', label: 'Malaysia' },
  { value: 'PH', label: 'Philippines' },
  { value: 'SG', label: 'Singapore' },
  { value: 'TH', label: 'Thailand' },
  { value: 'TW', label: 'Taiwan' },
  { value: 'VN', label: 'Vietnam' },
  { value: 'IN', label: 'India' },
  { value: 'AE', label: 'United Arab Emirates' },
  { value: 'SA', label: 'Saudi Arabia' },
  { value: 'KW', label: 'Kuwait' },
];

export const MENA_COUNTRIES = [
  { value: 'AE', label: 'United Arab Emirates' },
  { value: 'SA', label: 'Saudi Arabia' },
  { value: 'KW', label: 'Kuwait' },
];

export const ALL_SOCIAL_MEDIAS = [
  {
    label: 'Facebook',
    value: CampaignSocialMediaType.FACEBOOK,
  },
  {
    label: 'Instagram',
    value: CampaignSocialMediaType.INSTAGRAM,
  },
  {
    label: 'Instagram Story',
    value: CampaignSocialMediaType.INSTAGRAM_STORY,
  },
  {
    label: 'Twitter',
    value: CampaignSocialMediaType.TWITTER,
  },
  {
    label: 'YouTube',
    value: CampaignSocialMediaType.YOUTUBE,
  },
];

export const SOCIAL_MEDIAS = ALL_SOCIAL_MEDIAS.filter(socialMedias =>
  [
    CampaignSocialMediaType.FACEBOOK,
    CampaignSocialMediaType.INSTAGRAM_STORY,
    CampaignSocialMediaType.INSTAGRAM,
    CampaignSocialMediaType.YOUTUBE,
    CampaignSocialMediaType.TWITTER,
  ].includes(socialMedias.value)
);

export const ALL_SOCIAL_ACCOUNTS = [
  {
    label: 'YouTube',
    value: SocialAccountType.YOUTUBE,
  },
  {
    label: 'Instagram',
    value: SocialAccountType.INSTAGRAM,
  },
  {
    label: 'Twitter',
    value: SocialAccountType.TWITTER,
  },
  {
    label: 'Facebook',
    value: SocialAccountType.FACEBOOK,
  },
  {
    label: 'Facebook Page',
    value: SocialAccountType.FACEBOOK_PAGE,
  },
];

export const ENABLED_INTERCOM_COUNTRIES = ['VN', 'ID', 'TH', 'JP', 'PH'];

/**
 * Date Format
 */
export const DEFAULT_FNS_DATE_FORMAT = 'yyyy-MM-dd';

// TODO Implement this everywhere when BE RedirectPath is removed.
export enum FE_REDIRECT_MAPPING {
  CONNECT = 'CONNECT',
  JOIN_CAMPAIGN = 'JOIN_CAMPAIGN',
  SIGNIN = 'SIGNIN',
  SIGNUP = 'SIGNUP',
  SIGNUP_CONNECT = 'SIGNUP_CONNECT',
  SIGNUP_ENABLED = 'SIGNUP_ENABLED',
  TALENT_SIGNIN = 'TALENT_SIGNIN',
  TALENT_SIGNUP = 'TALENT_SIGNUP',
  TIKTOK_RECONNECT = 'TIKTOK_RECONNECT',
  ANALYTICS_IG_RECONNECT = 'ANALYTICS_IG_RECONNECT',
  SHOPIFY_CONNECT = 'SHOPIFY_CONNECT',
  LINE_INSTAGRAM_SIGNIN = 'LINE_INSTAGRAM_SIGNIN',
  LINK_IN_BIO_CONNECT = 'LINK_IN_BIO_CONNECT',
  MOBILE_TIKTOK_CONNECT = 'MOBILE_TIKTOK_CONNECT',
  TTCM_RECONNECT = 'TTCM_RECONNECT',
}

export enum AGENCY_TYPES {
  PARTNER = 'Partner',
  TALENT_AGENCY = 'TalentAgnecy',
}

export const getOrderType = (order?: string | null): ORDER | null => {
  switch (order) {
    case 'ASC':
      return ORDER.ASC;
    case 'DESC':
      return ORDER.DESC;
    default:
      return null;
  }
};

export const JAPANESE_LANG = 'ja';

export const POLL_INTERVAL = 5000;

export const CURRENCY_DECIMALS = [
  { decimals: 2, value: 'AED' },
  { decimals: 3, value: 'BHD' },
  { decimals: 2, value: 'CNY' },
  { decimals: 2, value: 'EGP' },
  { decimals: 2, value: 'HKD' },
  { decimals: 2, value: 'IDR' },
  { decimals: 2, value: 'INR' },
  { decimals: 3, value: 'JOD' },
  { decimals: 0, value: 'JPY' },
  { decimals: 2, value: 'KHR' },
  { decimals: 3, value: 'KWD' },
  { decimals: 2, value: 'MMK' },
  { decimals: 2, value: 'MYR' },
  { decimals: 2, value: 'PHP' },
  { decimals: 2, value: 'QAR' },
  { decimals: 3, value: 'OMR' },
  { decimals: 2, value: 'SAR' },
  { decimals: 2, value: 'SGD' },
  { decimals: 2, value: 'THB' },
  { decimals: 2, value: 'TWD' },
  { decimals: 2, value: 'USD' },
  { decimals: 0, value: 'VND' },
];
