import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { useHelpCenterUrl } from '@src/libs/help';
import { ThemeButton } from '@src/components/atoms/Button';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import SentenceForm from '@src/components/molecules/SentenceForm';
import { css } from '@emotion/react';
import { FormValues } from './types';

const ContactForm = () => {
  const { helpCenterUrl } = useHelpCenterUrl();
  const {
    formState: { errors, isSubmitting },
    register,
  } = useFormContext<FormValues>();

  return (
    <div css={styles.container}>
      <div css={styles.inquiryWrapper}>
        <p css={styles.paragraph}>
          <Trans i18nKey="Help.ContactGotAQuestion">
            text0
            <a href={helpCenterUrl} css={styles.faqLink} rel="noopener noreferrer" target="_blank">
              FAQs
            </a>
            text2
          </Trans>
        </p>
        <SentenceForm
          isRequired={true}
          error={!!errors.enquiry}
          placeholder="Enter the details of your inquiry"
          {...register('enquiry')}
        />
        <ErrorMessage message={errors.enquiry?.message} />
      </div>
      <div css={styles.submitWrapper}>
        <ThemeButton
          text="Send"
          type="submit"
          theme="blue"
          width="max-content"
          disabled={isSubmitting}
          css={styles.submit}
        />
      </div>
    </div>
  );
};

const styles = {
  container: css`
    background-color: #fff;
    width: 100%;
    max-width: 648px;
    margin: 0 auto;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
    border-radius: 5px;
  `,
  inquiryWrapper: css`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    border-bottom: 1px solid #eef3f7;
  `,
  paragraph: css`
    font-weight: 400;
    font-size: 14px;
  `,
  faqLink: css`
    all: unset;
    color: #3892e5;
    cursor: pointer;

    &:hover: {
      text-decoration: underline;
    }
  `,
  submitWrapper: css`
    padding: 16px;
  `,
  submit: css`
    min-width: 79px;
    margin-left: auto;
  `,
};

export default ContactForm;
