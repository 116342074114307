import { Select, themeVars } from '@anymindgroup/any-ui-react';
import styled from '@emotion/styled';
import { addMonths } from 'date-fns';
import React, { Dispatch, forwardRef, Ref, SetStateAction } from 'react';
import type { NavbarElementProps } from 'react-day-picker/types/Props';
import { useTranslation } from 'react-i18next';
import Icomoon from '@src/components/atoms/Icomoon';
import { getSelectedRangeValue } from './helpers';
import { Period, PickerDate, Range, RangeListOption, SELECTED_RANGE } from './types';
import { useRange } from './useDatepickerState';

export interface NavbarProps extends NavbarElementProps {
  locale: string;
  isMobile?: boolean;
  rangeList?: RangeListOption[];
  hoverFrom?: PickerDate;
  hoverTo?: PickerDate;
  initialPeriod?: Period;
  setState?: Dispatch<SetStateAction<Range>>;
}
export const Navbar = ({
  hoverFrom,
  hoverTo,
  initialPeriod,
  rangeList,
  isMobile,
  localeUtils,
  locale,
  setState,
  month,
  onNextClick,
  onPreviousClick,
}: NavbarProps) => {
  const rangeValue = getSelectedRangeValue({ from: hoverFrom as Date, to: hoverTo as Date, initialPeriod, rangeList });
  const rangesCallbacks = useRange({ setState, initialPeriod });
  const { t } = useTranslation();

  return (
    <NavbarWrapper>
      {rangeList?.length ? (
        <div style={{ padding: '14px' }}>
          <label
            aria-label="date-picker-label"
            style={{ fontWeight: 400, fontSize: '14px', color: themeVars.mainGrey, lineHeight: '20px' }}
          >
            {t('Date range')}
          </label>
          <Select
            value={rangeValue}
            onChange={(option: RangeListOption | null) => {
              if (option?.value && option.value !== SELECTED_RANGE.CUSTOM) {
                rangesCallbacks[option.value]();
              }
            }}
            options={rangeList?.map(el => ({ value: el.value, label: el.label || el.value, range: el.range }))}
          />
        </div>
      ) : null}
      {isMobile ? (
        <CurrentMonthWrapper>
          <CurrentMonth>
            <Icomoon css={{ transform: 'rotate(90deg)' }} icon="arrow-down" onClick={() => onPreviousClick()} />
          </CurrentMonth>
          {isMobile && <p>{localeUtils.formatMonthTitle(month, locale)}</p>}
          <NextMonth>
            <Icomoon css={{ transform: 'rotate(270deg)' }} icon="arrow-down" onClick={() => onNextClick()} />
          </NextMonth>
        </CurrentMonthWrapper>
      ) : (
        <CurrentMonthWrapper>
          <CurrentMonth>
            <Icomoon css={{ transform: 'rotate(90deg)' }} icon="arrow-down" onClick={() => onPreviousClick()} />
            <p>{localeUtils.formatMonthTitle(month, locale)}</p>
          </CurrentMonth>
          <NextMonth>
            <p>{localeUtils.formatMonthTitle(addMonths(month, 1), locale)}</p>
            <Icomoon css={{ transform: 'rotate(270deg)' }} icon="arrow-down" onClick={() => onNextClick()} />
          </NextMonth>
        </CurrentMonthWrapper>
      )}
    </NavbarWrapper>
  );
};

const CurrentMonthWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 0 10px;

  img {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
`;
const CurrentMonth = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;

  p {
    margin-left: 30px;
  }
`;
const NextMonth = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;

  p {
    margin-right: 30px;
  }
`;
const NavbarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 10px 0 10px;

  span {
    margin: 0 10px;
  }
`;

interface CustomInputProps {
  error: boolean;
  disabled: boolean;
  showExpand?: boolean;
}

export const CustomInput = forwardRef((props: CustomInputProps, ref: Ref<HTMLInputElement>) => {
  const { error, disabled, showExpand, ...rest } = props;

  return (
    <StyledPickerInput error={error} disabled={disabled} ref={ref} showExpand={showExpand}>
      <Icomoon icon="calendar" size={21} />
      <input {...rest} disabled={disabled} ref={ref} />
      {showExpand && <Icomoon className="arrow-down" icon="arrow-down" />}
    </StyledPickerInput>
  );
});
CustomInput.displayName = 'CustomInput';

const StyledPickerInput = styled.div<{ disabled: boolean; error: boolean; showExpand?: boolean }>`
  align-self: center;
  height: 38px;
  background-color: #fff;
  position: relative;
  border: 1px solid #dee5ec;
  border-radius: 20px;
  font-size: 13px;
  line-height: 3em;
  letter-spacing: 0.02em;
  box-sizing: border-box;
  ${({ disabled }) => disabled && 'background-color: #f7f7f7; cursor: default;'}
  ${({ error }) => error && 'border-color: tomato;'}
  ${({ showExpand }) => (showExpand ? 'padding: 0 40px' : 'padding-left: 40px')};

  /* stylelint-disable no-descending-specificity */
  span:nth-of-type(1),
  svg {
    color: #6e7c89;
    position: absolute;
    left: 10px;
    top: 4px;

    [dir='rtl'] & {
      left: unset;
      right: 10px;
    }
  }

  .arrow-down {
    left: unset;
    right: 10px;
    transform: translate(0, 50%);

    [dir='rtl'] & {
      right: unset;
      left: 10px;
    }
  }

  span:nth-of-type(2) {
    color: #6e7c89;
    position: absolute;
    right: 10px;
    top: 10px;
  }

  input {
    width: 100%;
  }
`;
