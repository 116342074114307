import { format, parse } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useFormContext } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import Select, { components, OptionProps } from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Popover, MenuItem } from '@src/components/molecules/Popover';
import { ThemeButton } from '@src/components/atoms/Button';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import Icomoon from '@src/components/atoms/Icomoon';
import Label from '@src/components/atoms/Label';
import { RadioIcon } from '@src/components/atoms/RadioButtonElement';
import { DayPickerHandInput } from '@src/components/molecules/DayPicker';
import TextForm from '@src/components/molecules/TextForm';
import { getDateTimeInPickerFormat } from '@src/libs/date';
import {
  DeliveryTimingType,
  FontSize,
  LineBroadcastStatus,
  LineMessageType,
  MediaType,
  RecipientType,
} from '@src/__generated__/globalTypes';
import BlockCard from '../BlockCard';
import { dateFormat, FormInformation, FormType } from '../helpers';
import { TimePicker } from '../StyledComponents';
import FormAction from './FormAction';

interface DefaultInputProps {
  isDisabled?: boolean;
  tags: { name: string }[];
  totalRecipients: number;
  onSubmit: (information: FormInformation) => Promise<void>;
}

const DefaultInput = ({ isDisabled, tags, totalRecipients, onSubmit }: DefaultInputProps) => {
  const [date, setDate] = useState<string>('');
  const [time, setTime] = useState<string>('');
  const { t } = useTranslation();
  const {
    formState: { errors },
    register,
    setValue,
    watch,
  } = useFormContext<FormInformation>();
  const [deliveryTiming, lineContent, recipient, status] = watch([
    'deliveryTiming',
    'lineContent',
    'recipient',
    'status',
  ]);

  const blockOptions = [
    { icon: 'edit-box-grey-light', title: 'Plain Text', value: LineMessageType.PLAIN_TEXT },
    { icon: 'image-grey', title: 'Rich Message', value: LineMessageType.RICH },
    { icon: 'cupboard-grey-light', title: 'Button Type', value: LineMessageType.BUTTON },
    { icon: 'three-cube-horizontal-grey-light', title: 'Image Type', value: LineMessageType.IMAGES },
    // anyChat not include this option
    // { icon: 'youtube-grey-light', title: 'Image / Video', value: LineMessageType.IMAGE_VIDEO },
  ];
  const maxCards = 5;
  const lineContentLength = lineContent.length;

  useEffect(() => {
    if (deliveryTiming.scheduleDate) {
      const selectedDate = parse(deliveryTiming.scheduleDate || '', dateFormat, new Date());
      const { date: dateString, time: timeString } = getDateTimeInPickerFormat(selectedDate);

      setDate(dateString);
      setTime(timeString);
    } else {
      setDate('');
      setTime('');
    }
  }, [deliveryTiming.scheduleDate]);

  const onClickAddBlock = (type: LineMessageType) => {
    setValue('lineContent', [
      ...lineContent,
      {
        genId: uuidv4(),
        type,
        ...(type === LineMessageType.BUTTON
          ? {
              buttonMessage: {
                cards: [
                  {
                    actionLabels: [{ genId: uuidv4(), label: '', url: '' }],
                    description: {
                      fontSize: FontSize.SM,
                      text: '',
                      weighted: false,
                    },
                    genId: uuidv4(),
                    imageUrl: '',
                    title: {
                      fontSize: FontSize.LG,
                      text: '',
                      weighted: true,
                    },
                  },
                  {
                    actionLabels: [{ genId: uuidv4(), label: '', url: '' }],
                    description: {
                      fontSize: FontSize.SM,
                      text: '',
                      weighted: false,
                    },
                    genId: uuidv4(),
                    imageUrl: '',
                    title: {
                      fontSize: FontSize.LG,
                      text: '',
                      weighted: true,
                    },
                  },
                ],
              },
            }
          : {}),
        ...(type === LineMessageType.IMAGES
          ? {
              imageMessage: {
                images: [
                  {
                    genId: uuidv4(),
                    imageUrl: '',
                    label: '',
                    url: '',
                  },
                  {
                    genId: uuidv4(),
                    imageUrl: '',
                    label: '',
                    url: '',
                  },
                ],
              },
            }
          : {}),
        ...(type === LineMessageType.IMAGE_VIDEO
          ? {
              media: {
                image: {
                  fileName: '',
                  mimeType: '',
                  previewUrl: '',
                  url: '',
                },
                type: MediaType.IMAGE,
              },
            }
          : {}),
        ...(type === LineMessageType.PLAIN_TEXT
          ? {
              text: '',
            }
          : {}),
        ...(type === LineMessageType.RICH
          ? {
              imageUrl: '',
              url: '',
            }
          : {}),
      },
    ]);
    setValue('selectedBlockIndex', lineContentLength);
    setValue(
      'formType',
      type === LineMessageType.BUTTON
        ? FormType.BUTTON_TYPE
        : type === LineMessageType.IMAGES
        ? FormType.IMAGE_TYPE
        : type === LineMessageType.IMAGE_VIDEO
        ? FormType.IMAGE_VIDEO_TYPE
        : type === LineMessageType.PLAIN_TEXT
        ? FormType.PLAIN_TEXT
        : FormType.RICH_MESSAGE
    );
  };

  const onChangeDeliveryTiming = (dateString: string, timeString: string) => {
    const parseDate = format(new Date(Date.parse(`${dateString} ${timeString}`)), dateFormat);
    setValue('deliveryTiming', { ...deliveryTiming, scheduleDate: parseDate });
  };

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) {
      return;
    }

    const destinationIndex = destination.index;
    const sourceIndex = source.index;
    const items = [...lineContent];
    const dragCard = items[sourceIndex];
    items.splice(sourceIndex, 1);
    items.splice(destinationIndex, 0, dragCard);
    setValue('lineContent', items);
  };

  const MenuOption = (props: OptionProps<{ label: string; value: string }, true>) => {
    const { label } = props;

    return (
      <components.Option {...props}>
        <div css={styles.tagMenuItem}>
          <div>{label}</div>
        </div>
      </components.Option>
    );
  };

  return (
    <div css={styles.container}>
      <div css={{ padding: 24 }}>
        <InputContainer>
          <TextForm
            disabled={isDisabled}
            error={!!errors.title}
            isRequired
            placeholder={t('Placeholder.Input the title')}
            title="Message Title"
            {...register('title')}
          />
          <ErrorMessage message={errors.title?.message} />
          <div css={styles.titleHint}>
            {t('Annotation.This is displayed as a display message on chat list, and also as a notification')}
          </div>
        </InputContainer>

        <InputContainer>
          <StyledLabel title="Delivery Schedule" />
          <RadioGroup
            disabled={isDisabled}
            onClick={() =>
              setValue('deliveryTiming', {
                scheduleDate: '',
                type: DeliveryTimingType.IMMEDIATELY_SEND,
              })
            }
          >
            <RadioIcon isChecked={deliveryTiming.type === DeliveryTimingType.IMMEDIATELY_SEND} />
            <div>{t('Immediately')}</div>
          </RadioGroup>
          <RadioGroup
            css={{ marginTop: 8 }}
            disabled={isDisabled}
            onClick={() =>
              setValue('deliveryTiming', {
                scheduleDate: format(
                  new Date(new Date().setHours(new Date().getHours() + 1)).setMinutes(0),
                  dateFormat
                ),
                type: DeliveryTimingType.SCHEDULE_SEND,
              })
            }
          >
            <RadioIcon isChecked={deliveryTiming.type === DeliveryTimingType.SCHEDULE_SEND} />
            <div>{t('Specific time')}</div>
          </RadioGroup>

          {deliveryTiming.type === DeliveryTimingType.SCHEDULE_SEND && (
            <div css={styles.dateTimeContainer}>
              <div css={styles.datepickerContainer}>
                <DayPickerHandInput
                  disabled={isDisabled}
                  inputFormat="yyyy-MM-dd"
                  showExpand
                  showInSingleMonth
                  placeholder="2020-01-01"
                  value={date}
                  handleChangeDay={val => onChangeDeliveryTiming(val, time)}
                />
              </div>
              <TimePicker
                disabled={isDisabled}
                disableClearValue
                name=""
                value={time}
                setFieldValue={(_name, val) => onChangeDeliveryTiming(date, val)}
                css={{ minWidth: '80px' }}
              />
            </div>
          )}
        </InputContainer>

        <InputContainer>
          <div css={styles.recipientsTitleContainer}>
            <StyledLabel title="Recipients" />
            <div>
              <Trans i18nKey="Estimated recipients">
                Estimated <span>{{ totalRecipients: totalRecipients || '-' } as any}</span>
              </Trans>
            </div>
          </div>
          <RadioGroup
            disabled={isDisabled}
            onClick={() =>
              setValue('recipient', {
                type: RecipientType.ALL,
                tagNames: [],
              })
            }
          >
            <RadioIcon isChecked={recipient.type === RecipientType.ALL} />
            <div>{t('All Fans who have added your LINE Account as a friend.')}</div>
          </RadioGroup>
          <RadioGroup
            css={{ margin: '8px 0 16px 0' }}
            disabled={isDisabled}
            onClick={() =>
              setValue('recipient', {
                ...recipient,
                type: RecipientType.WITH_CUSTOMER_TAGS,
              })
            }
          >
            <RadioIcon isChecked={recipient.type === RecipientType.WITH_CUSTOMER_TAGS} />
            <div>{t('Fans with specific tag')}</div>
          </RadioGroup>

          {recipient.type === RecipientType.WITH_CUSTOMER_TAGS && (
            <>
              <Select
                components={{ Option: MenuOption }}
                css={styles.select}
                isDisabled={isDisabled}
                isClearable={false}
                isMulti
                options={tags.map(tag => ({ label: tag.name, value: tag.name }))}
                placeholder={t('Select Tags')}
                styles={{
                  control: base => ({
                    ...base,
                    border: `1px solid ${!!errors.recipient ? '#ff6247' : '#dee5ec'}`,
                    minHeight: 32,
                  }),
                  indicatorSeparator: base => ({
                    ...base,
                    display: 'none',
                  }),
                  multiValueLabel: base => ({
                    ...base,
                    backgroundColor: '#eef3f7',
                    color: '#27313b',
                    fontSize: 12,
                  }),
                  multiValueRemove: base => ({
                    ...base,
                    backgroundColor: '#eef3f7',
                  }),
                  placeholder: base => ({
                    ...base,
                    color: '#e9e9e9',
                  }),
                }}
                value={recipient.tagNames.map(tag => ({ label: tag, value: tag }))}
                onChange={val =>
                  setValue('recipient', {
                    ...recipient,
                    tagNames: val?.map(item => item.value) || [],
                  })
                }
              />
              {errors.recipient && <ErrorMessage message={'requiredFieldMessage'} />}
            </>
          )}
        </InputContainer>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {droppableProvided => (
              <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                {lineContent.map((item, index) => {
                  const { buttonMessage, imageMessage, plainTextMessage, richMessage } = item;
                  const buttonTypeMessageError =
                    LineMessageType.BUTTON &&
                    buttonMessage?.cards.find(card => card.actionLabels.find(action => !action.label || !action.url));
                  const imageMessageError =
                    item.type === LineMessageType.IMAGES &&
                    imageMessage?.images.find(image => !image.imageUrl || !image.url);
                  const plainTextMessageError = item.type === LineMessageType.PLAIN_TEXT && !plainTextMessage?.text;
                  const richMessageError = item.type === LineMessageType.RICH && !richMessage?.imageUrl;

                  return (
                    <Draggable
                      draggableId={item.genId}
                      index={index}
                      isDragDisabled={isDisabled || lineContent.length <= 1}
                      key={item.genId}
                    >
                      {(draggableProvider, draggableSnapshot) => (
                        <div
                          ref={draggableProvider.innerRef}
                          {...draggableProvider.draggableProps}
                          {...draggableProvider.dragHandleProps}
                        >
                          <BlockCard
                            contentType={item.type}
                            error={
                              !!errors.lineContent &&
                              !!(
                                buttonTypeMessageError ||
                                imageMessageError ||
                                plainTextMessageError ||
                                richMessageError
                              )
                            }
                            isDeletable={!isDisabled && lineContent.length > 1}
                            isDragging={draggableSnapshot.isDragging}
                            key={item.genId}
                            onClick={() => setValue('selectedBlockIndex', index)}
                            onDelete={() =>
                              setValue('lineContent', [...lineContent.slice(0, index), ...lineContent.slice(index + 1)])
                            }
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {!isDisabled && (
          <>
            <Popover
              renderTrigger={() => (
                <ThemeButton
                  disabled={lineContentLength >= maxCards}
                  text="Add Block"
                  prefixIcon={<Icomoon icon="plus" />}
                  size="large"
                  width="max-content"
                />
              )}
              align="start"
              renderButtonBlock={() => null}
              disabled={lineContentLength >= maxCards}
            >
              <div css={{ width: '230px', padding: '8px 0' }}>
                {blockOptions.map((option, index) => {
                  const { icon, title, value } = option;

                  return (
                    <MenuItem css={styles.menuItem} key={index} onClick={() => onClickAddBlock(value)}>
                      <Icomoon icon={icon} size={24} />
                      <div>{t(title)}</div>
                    </MenuItem>
                  );
                })}
              </div>
            </Popover>
            <div css={styles.cardCount}>{`${lineContentLength} / ${t('Card Maximum', {
              count: maxCards,
            })}`}</div>
          </>
        )}
      </div>

      {![LineBroadcastStatus.DELIVERED, LineBroadcastStatus.SCHEDULED, LineBroadcastStatus.SENDING].includes(
        status as LineBroadcastStatus
      ) && (
        <div css={styles.actionContainer}>
          <FormAction tags={tags} onSubmit={onSubmit} />
        </div>
      )}
    </div>
  );
};

const InputContainer = styled.div`
  margin-bottom: 24px;
`;

const RadioGroup = styled.div<{ disabled?: boolean }>`
  display: flex;
  cursor: pointer;
  flex-wrap: wrap;
  gap: 8px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  /* stylelint-disable no-descending-specificity */
  & > div:nth-of-type(2) {
    color: #27313b;
    font-size: 13px;
  }
`;

const StyledLabel = styled(Label)`
  color: #27313b;
  font-size: 14px;
  font-weight: 600;
  line-height: unset;
  margin-bottom: 16px;
`;

const styles = {
  actionContainer: css`
    --paddingSides: 24px;

    border-top: 1px solid #dee5ec;
    bottom: 0;
    padding: 16px var(--paddingSides);
    position: absolute;
    width: calc(100% - var(--paddingSides) * 2);
  `,
  cardCount: css`
    color: #c5d0da;
    font-size: 12px;
    margin-top: 4px;
  `,
  container: css`
    height: 100%;
    position: relative;
  `,
  datepickerContainer: css`
    & > div > div > div {
      border-color: #dee5ec;
      border-radius: 3px;
      height: 32px;
      width: 296px;

      & > span {
        color: #6e7c89;
        font-size: 24px;
        top: 2px !important;
      }

      & > input {
        height: 32px;
      }
    }
  `,
  dateTimeContainer: css`
    display: flex;
    gap: 8px;
    margin-top: 16px;
  `,
  menu: css`
    & > .MuiMenu-paper {
      background-color: #fff;
      border: 1px solid #dee5ec;
      border-radius: 3px;
      box-shadow: none;
      width: 232px;
    }
  `,
  menuItem: css`
    display: flex;
    gap: 4px;
    padding: 8px 16px !important;

    & > div {
      color: #6e7c89;
      font-size: 14px;
      font-weight: 600;
    }
  `,
  recipientsTitleContainer: css`
    display: flex;
    flex-wrap: wrap;

    & > label {
      display: flex;
      flex: 1;
    }

    & > div {
      background-color: #dbdee2;
      border-radius: 35px;
      font-size: 12px;
      height: fit-content;
      padding: 2px 8px;

      & > span {
        color: #27313b;
        font-size: 12px;
        font-weight: 600;
      }
    }
  `,
  select: css`
    & > div > div:nth-of-type(2) {
      & > div {
        padding: 0 8px;
      }
    }
  `,
  tagMenuItem: css`
    & > div {
      background-color: #dee5ec;
      border-radius: 3px;
      color: #27313b;
      font-size: 12px;
      padding: 4px 8px;
      width: fit-content;
    }
  `,
  titleHint: css`
    color: #6e7c89;
    font-size: 11px;
    margin-top: 4px;
  `,
};

export default DefaultInput;
