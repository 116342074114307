import range from 'lodash/range';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ThemeButton } from '@src/components/atoms/Button';
import Icomoon from '@src/components/atoms/Icomoon';
import { DialogModal } from '@src/components/molecules/DialogModal';
import { tutorials, TutorialTypes } from './helpers';

interface TutorialToolProps {
  type: TutorialTypes;
}

interface Tutorials {
  description: string;
  descriptionTitle: string;
  icon: string;
  iconColor?: string;
  image: string;
  title: string;
}

const TutorialTool = ({ type }: TutorialToolProps) => {
  const [currStep, setCurrStep] = useState<number>(0);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const tutorialLength = tutorials[type].length;
  const tutorialSteps = tutorials[type][currStep] as Tutorials;

  useEffect(() => {
    setIsDialogOpen(false);
  }, []);

  useEffect(() => {
    setCurrStep(0);
  }, [isDialogOpen]);

  return (
    <Fragment>
      <DialogModal
        contentStyle={{
          animation: 'fadeIn linear 0.3s',
          borderRadius: 9,
          maxWidth: 343,
          width: '90%',
        }}
        isOpen={isDialogOpen}
        closeModal={() => setIsDialogOpen(false)}
        footerNode={
          <div>
            <div css={styles.dotsContainer}>
              {range(tutorialLength).map(index => (
                <Dot active={currStep === index} key={index} />
              ))}
            </div>
            <div css={styles.dialogActionContainer}>
              <ThemeButton
                text={currStep === 0 ? 'Skip' : 'Prev'}
                size="large"
                borderRadius="5px"
                onClick={() => (currStep === 0 ? setIsDialogOpen(false) : setCurrStep(step => step - 1))}
              />
              <ThemeButton
                theme="blue"
                size="large"
                borderRadius="5px"
                text={currStep === tutorialLength - 1 ? 'Start' : 'Next'}
                onClick={() =>
                  currStep === tutorialLength - 1 ? setIsDialogOpen(false) : setCurrStep(step => step + 1)
                }
              />
            </div>
          </div>
        }
      >
        {
          <div css={{ marginTop: '24px' }}>
            {tutorialSteps && (
              <Fragment>
                <div css={styles.dialogContentContainer}>
                  <div css={styles.dialogContentTitle}>
                    <Icomoon
                      icon={tutorialSteps.icon}
                      size={24}
                      {...(tutorialSteps.iconColor && { color: tutorialSteps.iconColor })}
                    />
                    <div>{t(tutorialSteps.title)}</div>
                  </div>

                  <div css={styles.dialogContentImg}>
                    <img alt="image" src={tutorialSteps.image} />
                  </div>

                  <div css={styles.dialogContentDescription}>
                    <div>{t(tutorialSteps.descriptionTitle)}</div>
                    <div>{t(tutorialSteps.description)}</div>
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        }
      </DialogModal>
      <Icomoon css={{ cursor: 'pointer' }} icon="help-filled" size={24} onClick={() => setIsDialogOpen(true)} />
    </Fragment>
  );
};

const Dot = styled.div<{ active: boolean }>`
  background-color: ${({ active }) => (active ? '#3892e5' : '#afd3f5')};
  border-radius: 50%;
  height: 8px;
  margin-right: 3px;
  width: 8px;
`;

const styles = {
  dialogActionContainer: css`
    align-items: center;
    background-color: #fff;
    display: flex;
    padding: 16px;
    gap: 8px;
    border-radius: 9px;
  `,
  dialogContentContainer: css`
    min-height: 500px;
    padding: 8px;
  `,
  dialogContentDescription: css`
    & > div:nth-of-type(1) {
      color: #27313b;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 16px;
    }

    & > div:nth-of-type(2) {
      color: #27313b;
      font-size: 14px;
    }
  `,
  dialogContentImg: css`
    margin-bottom: 16px;
    margin-top: 40px;
    width: 100%;

    & > img {
      width: 100%;
    }
  `,
  dialogContentTitle: css`
    align-items: center;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    height: 40px;
    padding: 16px 0 0 0;
    position: absolute;
    top: 0;
    width: calc(100% - 26px);

    & > svg {
      margin: 0 8px;
    }

    /* stylelint-disable no-descending-specificity */
    & > div {
      color: #27313b;
      display: flex;
      flex: 1;
      font-size: 18px;
      font-weight: 700;
    }
  `,
  dotsContainer: css`
    background-color: #fff;
    border-bottom: 1px solid #dee5ec;
    bottom: 72px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 16px 0;
  `,
};

export { TutorialTypes };
export default TutorialTool;
