import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import yourJobBackground from '@src/assets/img/yourJobBackground.png';
import { ROUTES } from '@src/shared/routes';

const EmptyJob = () => {
  const { t } = useTranslation();

  return (
    <div css={styles.container}>
      <div css={styles.infoContainer}>
        <div>
          <img alt="backgroundImg" height="120" src={yourJobBackground} width="170" />
        </div>
        <div>{t('Annotation.Empty Your Job Page')}</div>
        <div>
          <Link css={styles.connectBtn} to={ROUTES.MARKETPLACE_SEARCH_JOB}>
            {t('Button.Go to see jobs')}
          </Link>
        </div>
      </div>
    </div>
  );
};

const styles = {
  connectBtn: css`
    align-items: center;
    background-color: #fff;
    border: 1px solid #dee5ec;
    border-radius: 5px;
    color: #6e7c89;
    display: flex;
    font-size: 12px;
    font-weight: 600;
    height: 40px;
    justify-content: center;
    width: 156px;
  `,
  container: css`
    align-items: center;
    display: flex;
    justify-content: center;
    height: 70vh;
  `,
  infoContainer: css`
    text-align: center;
    width: 300px;

    & > div:nth-of-type(1) {
      display: grid;
      justify-content: center;
    }

    & > div:nth-of-type(2) {
      color: #6e7c89;
      font-size: 14px;
      margin: 16px 0;
    }

    & > div:nth-of-type(3) {
      display: flex;
      justify-content: center;
    }
  `,
};

export default EmptyJob;
