import { useState } from 'react';
import { useQueryHelper } from '@src/libs/hooks';
import { useQuery } from '@apollo/client';
import * as GET_FAN_COUNTRIES from './GetFanCountries.graphql';
import { GetFanCountries } from './__generated__/GetFanCountries';

const useFanCountries = () => {
  const [fanCountries, setFanCountries] = useState<{ id: string; name: string }[]>([]);
  const { enqueueSnackbar, t } = useQueryHelper();

  useQuery<GetFanCountries>(GET_FAN_COUNTRIES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ getFanCountries }: GetFanCountries) => {
      setFanCountries(getFanCountries?.countries || []);
    },
    onError: error => {
      enqueueSnackbar(t(error.message || 'UnexpectedError'), { variant: 'error' });
    },
  });

  return {
    fanCountries,
  };
};

export default useFanCountries;
