import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import shopifyIcon from '@src/assets/icon/shopify.svg';
import shopifyImg from '@src/assets/img/shopify.svg';
import Icomoon from '@src/components/atoms/Icomoon';
// import MenuButton from '@src/components/molecules/MenuButton';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { useQueryHelper, useRedirectWithinIframe } from '@src/libs/hooks';
import { checkmarkCode } from '@src/assets/htmlCodes';
import { ThemeButton } from '@src/components/atoms/Button';
import { DialogModal } from '@src/components/molecules/DialogModal';
import REMOVE_SHOPIFY_ACCOUNT from './mutations/RemoveShopifyAccount.graphql';
import { RemoveShopifyAccount, RemoveShopifyAccountVariables } from './mutations/__generated__/RemoveShopifyAccount';
import GET_SHOPIFY_ACCOUNT from './queries/GetShopifyAccount.graphql';
import { GetShopifyAccount } from './queries/__generated__/GetShopifyAccount';
import { useRedirectUrl } from './useRedirectUrl';
import * as Styled from './Styled';

enum DialogStatus {
  CLOSE = 'CLOSE',
  CONNECT_SHOPIFY = 'CONNECT_SHOPIFY',
  DELETE_SHOPIFY = 'DELETE_SHOPIFY',
}

export const ShopifyChannel = () => {
  const [dialogStatus, setDialogStatus] = useState<DialogStatus>(DialogStatus.CLOSE);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [storeName, setStoreName] = useState<string>('');
  const { enqueueSnackbar, t } = useQueryHelper();
  const { startRedirectProcess } = useRedirectWithinIframe();
  const { isLoading, getShopifyRedirectUrl } = useRedirectUrl();

  const { styles } = Styled;

  const { data } = useQuery<GetShopifyAccount>(GET_SHOPIFY_ACCOUNT, {
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const [removeShopifyAccount] = useMutation<RemoveShopifyAccount, RemoveShopifyAccountVariables>(
    REMOVE_SHOPIFY_ACCOUNT,
    {
      refetchQueries: ['GetShopifyAccount'],
      onCompleted: () => {
        enqueueSnackbar(t('succeededInDeleting'), { variant: 'success' });
        setDialogStatus(DialogStatus.CLOSE);
      },
      onError: error => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
    }
  );

  const onClickConnectShopify = async () => {
    const redirectUrl = await getShopifyRedirectUrl(storeName);
    if (redirectUrl) {
      localStorage.setItem('shopifyStoreName', storeName);
      startRedirectProcess({ redirectUrl, redirectType: FE_REDIRECT_MAPPING.SHOPIFY_CONNECT });
    }
  };

  const onClickDeleteShopify = () => {
    if (shopifyAccount) {
      setIsSubmitting(true);
      removeShopifyAccount({
        variables: {
          input: {
            id: shopifyAccount?.id,
          },
        },
      }).finally(() => setIsSubmitting(false));
    }
  };

  const shopifyAccount = data?.getShopifyAccount || null;

  return (
    <>
      <DialogModal
        contentStyle={{
          backgroundColor: '#fff',
          borderRadius: 3,
          maxWidth: 648,
          padding: 0,
          width: '90%',
        }}
        isOpen={dialogStatus !== DialogStatus.CLOSE}
        closeModal={() => setDialogStatus(DialogStatus.CLOSE)}
      >
        <div>
          {dialogStatus === DialogStatus.CONNECT_SHOPIFY ? (
            <>
              <Styled.DialogContent>
                <Styled.DialogHeader>{t('Title.Channel Connection')}</Styled.DialogHeader>
                <Styled.DialogSubHeader>{t('Annotation.Select Sales Channel')}</Styled.DialogSubHeader>
                <Styled.DialogImageContainer>
                  <img alt="shopify" height="200" src={shopifyImg} width="228" />
                </Styled.DialogImageContainer>
                <div>
                  <Styled.StyledTextForm
                    isRequired
                    placeholder="Store name"
                    title="Shopify Store Name"
                    value={storeName}
                    onChange={e => setStoreName(e.target.value)}
                  />
                </div>
              </Styled.DialogContent>

              <Styled.DialogAction>
                <ThemeButton text="Cancel" width="max-content" onClick={() => setDialogStatus(DialogStatus.CLOSE)} />
                <ThemeButton
                  width="max-content"
                  text="Save"
                  theme="blue"
                  disabled={!storeName}
                  loading={isLoading || isLoading}
                  onClick={onClickConnectShopify}
                />
              </Styled.DialogAction>
            </>
          ) : dialogStatus === DialogStatus.DELETE_SHOPIFY ? (
            <>
              <Styled.DialogContent>
                <Styled.DialogHeader>
                  <Icomoon color="#ff5f5f" icon="warning" size={24} />
                  {t('Title.Delete Channel')}
                </Styled.DialogHeader>
                <Styled.DialogSubHeader>
                  {t('Annotation.Are you sure you want to delete Channel', { name: 'Shopify' })}
                </Styled.DialogSubHeader>
              </Styled.DialogContent>
              <Styled.DialogAction>
                <ThemeButton text="Cancel" width="max-content" onClick={() => setDialogStatus(DialogStatus.CLOSE)} />
                <ThemeButton
                  width="max-content"
                  text="Delete"
                  theme="alertRed"
                  disabled={isSubmitting}
                  onClick={onClickDeleteShopify}
                />
              </Styled.DialogAction>
            </>
          ) : null}
        </div>
      </DialogModal>

      <div css={styles.channelContent}>
        <div css={{ gap: '8px' }}>
          <img alt="shopify" height="24" src={shopifyIcon} width="21" />
          <span>Shopify</span>
        </div>
        {shopifyAccount ? (
          <div css={{ gap: '8px' }}>
            <ThemeButton
              text="Connected"
              prefixIcon={<span css={styles.checkmark}>{checkmarkCode}</span>}
              fontSize="12px"
              css={[styles.connectButton, { pointerEvents: 'none' }]}
            />
            {/* temporary hide delete option as AnyChat does not support delete function for now */}
            {/* <MenuButton options={[{ title: 'Delete', onClick: () => setDialogStatus(DialogStatus.DELETE_SHOPIFY) }]} /> */}
          </div>
        ) : (
          <ThemeButton
            theme="blue"
            text="Connect"
            onClick={() => setDialogStatus(DialogStatus.CONNECT_SHOPIFY)}
            fontSize="12px"
            css={styles.connectButton}
          />
        )}
      </div>
    </>
  );
};
