import styled from '@emotion/styled';
import * as List from '../../atoms/List';

export { default as EmptyTable } from './EmptyTable';

export const Wrapper = styled.section`
  margin: 4px 16px 0;
  padding: 0 12px;
  background-color: #fff;
  border: 1px solid #dee5ec;
  border-radius: 2px;
  position: relative;
`;

export const HeaderRow = styled.tr`
  border-bottom: 1px solid #efefef;
`;

export const Row = styled.tr`
  border-top: 1px solid #efefef;

  &:hover {
    background-color: #f9f9f9;
  }
`;

export const TextLinkCell = styled(List.TextLinkColumn)`
  ${Row}:hover & > div:first-of-type a {
    color: #179cd7;
    text-decoration: underline;
  }
`;

export const ThumbnailCell = styled(List.ThumbnailColumn)`
  padding: 8px;

  ${Row}:hover & p {
    color: #179cd7;
    text-decoration: underline;
  }
`;
