import React, { useEffect } from 'react';
import InitialLoading from '@src/components/molecules/InitialLoading';
import { getConnectState } from '@src/libs/auth';
import { ErrorTypes, UNEXPECTED_ERROR } from '@src/libs/error';
import { useQueryHelper } from '@src/libs/hooks';
import { generatePath, ROUTES } from '@src/shared/routes';
import { useAuthProviderResponse } from '../hooks';
import useTtcmReconnect from './useTtcmReconnect';

const TtcmReconnect = () => {
  const { enqueueSnackbar, history, t } = useQueryHelper();
  const connectState = getConnectState();
  const failedRedirectPath = connectState?.campaignId
    ? generatePath(ROUTES.CASTING_YOUR_JOB_ID, { id: connectState.campaignId })
    : ROUTES.CASTING_YOUR_JOB;
  const { provider, response } = useAuthProviderResponse(failedRedirectPath);
  const { socialAuthReconnectTtcm } = useTtcmReconnect();

  const socialAuthReconnect = async () => {
    if (!provider) {
      enqueueSnackbar(t(UNEXPECTED_ERROR), { variant: 'error' });
      history.push(failedRedirectPath);

      return;
    }

    const variables = {
      input: {
        id: connectState?.reconnectTiktokAccountId || '',
        response,
      },
    };
    await socialAuthReconnectTtcm({
      variables,
    })
      .then(result => {
        if (result.data?.anyXSocialAuthReconnectTtcm?.ok) {
          history.push(
            connectState?.campaignId
              ? `${generatePath(ROUTES.CASTING_YOUR_JOB_ID, { id: connectState.campaignId })}?uploadDialog=true`
              : ROUTES.CASTING_YOUR_JOB
          );
        }
      })
      .catch(err => {
        if (err.message === ErrorTypes.VIDEO_PERMISSION_MISSING) {
          history.push(
            `${ROUTES.SETTINGS_SOCIAL_CONNECT_TIKTOK_GRANT_PERMISSION}?id=${connectState?.reconnectTiktokAccountId}`
          );
        } else {
          enqueueSnackbar(t(err.message), { variant: 'error' });
          history.push(failedRedirectPath);
        }
      });
  };

  useEffect(() => {
    socialAuthReconnect();
  }, []);

  return <InitialLoading />;
};

export default TtcmReconnect;
