import { useMutation } from '@apollo/client';
import SOCIAL_AUTH_RECONNECT_TIKTOK from './mutations/AnyXSocialAuthReconnectTikTok.graphql';
import {
  AnyXSocialAuthReconnectTikTok,
  AnyXSocialAuthReconnectTikTokVariables,
} from './mutations/__generated__/AnyXSocialAuthReconnectTikTok';

const useTiktokReconnect = () => {
  const [socialAuthReconnectTikTok] = useMutation<
    AnyXSocialAuthReconnectTikTok,
    AnyXSocialAuthReconnectTikTokVariables
  >(SOCIAL_AUTH_RECONNECT_TIKTOK);

  return {
    socialAuthReconnectTikTok,
  };
};

export default useTiktokReconnect;
