import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import Stepper from '@src/components/atoms/Stepper';
import {
  AnyXEngagementCampaignStatus,
  AnyXMarketplaceCampaignStatus,
  CampaignPostStatusForInfluencer,
  CampaignType,
} from '@src/__generated__/globalTypes';

interface StatusStepperProps {
  isGiftingCampaign?: boolean | null;
  isTtcmCampaign?: boolean;
  postStatus?: CampaignPostStatusForInfluencer | null;
  status: AnyXEngagementCampaignStatus | AnyXMarketplaceCampaignStatus;
  type: CampaignType;
}

const StatusStepper = ({ isGiftingCampaign, isTtcmCampaign, postStatus, status, type }: StatusStepperProps) => {
  const { t } = useTranslation();
  const outOfCouponWithoutPostStatus = status === AnyXMarketplaceCampaignStatus.OUT_OF_COUPON && postStatus === null;
  const isWarning = [
    AnyXEngagementCampaignStatus.INEFFECTIVE,
    AnyXEngagementCampaignStatus.WARNING_EFFECTIVE,
    AnyXEngagementCampaignStatus.WARNING_REVIEWING,
    AnyXMarketplaceCampaignStatus.WARNING_EFFECTIVE,
    AnyXMarketplaceCampaignStatus.INEFFECTIVE,
  ].includes(status);

  const activeStep = () => {
    if (type === CampaignType.ENGAGEMENT) {
      switch (status) {
        case AnyXEngagementCampaignStatus.DRAFTING:
          return 0;
        case AnyXEngagementCampaignStatus.REVIEWING:
        case AnyXEngagementCampaignStatus.WARNING_REVIEWING:
          return 1;
        case AnyXEngagementCampaignStatus.POSTING:
          return 2;
        default:
          return 3;
      }
    } else {
      switch (status) {
        case AnyXMarketplaceCampaignStatus.OUT_OF_COUPON:
        case AnyXMarketplaceCampaignStatus.POSTING:
          return isGiftingCampaign ? 1 : 0;
        case AnyXMarketplaceCampaignStatus.EFFECTIVE:
        case AnyXMarketplaceCampaignStatus.WARNING_EFFECTIVE:
          return isGiftingCampaign ? 2 : 1;
        case AnyXMarketplaceCampaignStatus.REVIEWING:
          return isGiftingCampaign ? 3 : 2;
        case AnyXMarketplaceCampaignStatus.SHIPPED:
          return 0;
        case AnyXMarketplaceCampaignStatus.WAITING_FOR_SHIPMENT:
          return -1;
        default:
          return isGiftingCampaign ? 4 : 3;
      }
    }
  };

  const steps = () => {
    if (type === CampaignType.ENGAGEMENT) {
      switch (status) {
        case AnyXEngagementCampaignStatus.INEFFECTIVE:
          return [
            { label: t('Drafting') },
            { label: t('Reviewing') },
            { label: t('Posting') },
            { label: t('Ineffective') },
          ];
        case AnyXEngagementCampaignStatus.WARNING_EFFECTIVE:
          return [
            { label: t('Drafting') },
            { label: t('Reviewing') },
            { label: t('Posting') },
            { label: t('Warning') },
          ];
        case AnyXEngagementCampaignStatus.WARNING_REVIEWING:
          return [
            { label: t('Drafting') },
            { label: t(isTtcmCampaign ? 'Reviewing' : 'Warning') },
            { label: t('Posting') },
            { label: t(isTtcmCampaign ? 'Complete' : 'Effective') },
          ];
        default:
          return [
            { label: t('Drafting') },
            { label: t('Reviewing') },
            { label: t('Posting') },
            { label: t(isTtcmCampaign ? 'Complete' : 'Effective') },
          ];
      }
    } else {
      switch (status) {
        case AnyXMarketplaceCampaignStatus.INEFFECTIVE:
          return [
            ...(isGiftingCampaign ? [{ label: t('Shipped') }] : []),
            { label: t('Posting') },
            { label: t('Effective') },
            { label: t('Reviewing') },
            { label: t('Ineffective') },
          ];
        case AnyXMarketplaceCampaignStatus.WARNING_EFFECTIVE:
          return [
            ...(isGiftingCampaign ? [{ label: t('Shipped') }] : []),
            { label: t('Posting') },
            { label: t('Warning') },
            { label: t('Reviewing') },
            { label: t('Approved') },
          ];
        default:
          return [
            ...(isGiftingCampaign ? [{ label: t('Shipped') }] : []),
            { label: t(outOfCouponWithoutPostStatus ? 'Pending' : 'Posting') },
            { label: t(outOfCouponWithoutPostStatus ? 'Posting' : 'Effective') },
            { label: t('Reviewing') },
            { label: t('Approved') },
          ];
      }
    }
  };

  return <StyledStepper activeColor="#3892e5" activeStep={activeStep()} isWarning={isWarning} steps={steps()} />;
};

const StyledStepper = styled(Stepper)<{ activeColor: string }>`
  & > div {
    height: 6px;
    width: 6px;

    &.completeStepIndicator {
      background-color: ${({ activeColor }) => activeColor};
    }

    &.activeStepIndicator {
      background-color: #fff;
      border: 5px solid ${({ activeColor }) => activeColor};
      height: 14px;
      width: 14px;
    }

    & > div {
      font-size: 11px;
      font-weight: 400;

      &.completeStepLabel {
        color: ${({ activeColor }) => activeColor};
      }

      &.activeStepLabel {
        color: ${({ activeColor }) => activeColor};
        font-weight: 600;
      }
    }
  }

  & > hr {
    border-top: 1px solid #c5d0da;

    &.activeStepConnector,
    &.completeStepConnector {
      border-color: ${({ activeColor }) => activeColor};
    }
  }
`;

export default StatusStepper;
