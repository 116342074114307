import React from 'react';
import { Switch, SwitсhProps } from '@anymindgroup/any-ui-react';
import { useDirLayout } from '@src/libs/hooks';
import '@anymindgroup/any-ui-react/dist/style.css';

const SwitchToggle = (props: SwitсhProps) => {
  const { dir } = useDirLayout();

  return <Switch {...props} dir={dir} />;
};

export default SwitchToggle;
