import { Trans } from 'react-i18next';
import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { css } from '@emotion/react';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import searchJobBackground from '@src/assets/img/searchJobBackground.png';
import { ThemeButton } from '@src/components/atoms/Button';
import Grid from '@src/components/atoms/Grid';
import Icomoon from '@src/components/atoms/Icomoon';
import { ListIndicator } from '@src/components/molecules/Indicator';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import { LIMIT } from '@src/libs/constant';
import { formatNumberWithCommas } from '@src/libs/format';
import { useAuthData, usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/theme';
import { paymentState as paymentRecoilState, useRecoilState } from '@src/recoilAtoms';
import { PaymentRequestStatusForInfluencer, UserRoles } from '@src/__generated__/globalTypes';
import { ROUTES } from '@src/shared/routes';
import { DialogModal } from '@src/components/molecules/DialogModal';
import CREATE_PAYMENT_REQUEST from './mutations/CreatePaymentReq.graphql';
import { CreatePaymentReq } from './mutations/__generated__/CreatePaymentReq';
import PAYMENT_INFORMATION from './queries/PaymentInformation.graphql';
import { PaymentInformation, PaymentInformationVariables } from './queries/__generated__/PaymentInformation';

const Payment = () => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [paymentState, setPaymentState] = useRecoilState(paymentRecoilState);
  const { role } = useAuthData();
  const { enqueueSnackbar, history, t } = useQueryHelper();
  const { isMobileView } = usePageLayout();

  const { data, loading } = useQuery<PaymentInformation, PaymentInformationVariables>(PAYMENT_INFORMATION, {
    variables: { offset: 0, limit: LIMIT },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const [createPaymentRequest] = useMutation<CreatePaymentReq>(CREATE_PAYMENT_REQUEST, {
    refetchQueries: ['PaymentInformation'],
    onCompleted: () => {
      enqueueSnackbar(t('succeededInCreating'), { variant: 'success' });
      sendAmplitudeEvent(eventTypes.requestPayment);
      setIsDialogOpen(false);
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const grossRevenueCurrency = data?.paymentRequest?.currency || '';
  const grossRevenue = data?.paymentRequest?.grossRevenue || 0;
  const hasIncorrectBankAcc = !!data?.influencerPaymentRequests.paymentRequests.find(
    payment => payment.status === PaymentRequestStatusForInfluencer.FAILED
  );
  const hasPaymentInformation = !!data?.influencerPaymentInformation?.accountNumber;
  const minimumPaymentAmount = data?.paymentRequest?.minimumPaymentAmount || 0;
  const payableCampaigns = data?.payableCampaign || [];
  const isLessThanPayableAmount = grossRevenue < minimumPaymentAmount;
  const isPayableCampaigns = payableCampaigns.length > 0;
  const amount = grossRevenueCurrency
    ? `${formatNumberWithCommas(minimumPaymentAmount)} ${t(grossRevenueCurrency)}`
    : formatNumberWithCommas(minimumPaymentAmount);

  const onClickRedirectPaymentInformation = () => {
    setPaymentState({ ...paymentState, isNavigateFromPayment: true });
    history.push(ROUTES.SETTINGS_PAYMENT_INFORMATION);
  };

  const onClickRequestPayment = () => {
    setIsSubmitting(true);
    createPaymentRequest().finally(() => setIsSubmitting(false));
  };

  return (
    <>
      <DialogModal
        contentStyle={{
          animation: 'fadeIn linear 0.3s',
          borderRadius: 9,
          maxWidth: 343,
          width: '90%',
        }}
        isOpen={isDialogOpen}
        closeModal={() => setIsDialogOpen(false)}
        footerNode={
          <div css={styles.dialogAction}>
            <ThemeButton text="Close" fontSize="12px" onClick={() => setIsDialogOpen(false)} css={styles.button} />
            {!isLessThanPayableAmount && (
              <ThemeButton
                theme="blue"
                fontSize="12px"
                disabled={isSubmitting}
                text="Confirm"
                onClick={onClickRequestPayment}
                css={styles.button}
              />
            )}
          </div>
        }
      >
        <div>
          <div css={styles.dialogContent}>
            {isLessThanPayableAmount ? (
              <>
                <div>{t('The payable amount has not been reached')}</div>
                <div>
                  <Trans i18nKey="In order to get your money">
                    In order to get your money, the requestable revenue has to be at least
                    <span css={styles.warningFont}>{{ amount } as any}</span>.
                  </Trans>
                </div>
              </>
            ) : (
              <>
                <div>{t('Request Payment')}</div>
                <div>{t('Are you sure you would like to request a payment')}</div>
              </>
            )}
          </div>
        </div>
      </DialogModal>

      <div css={styles.container}>
        <div>
          {!isMobileView && (
            <div css={{ marginBottom: 24 }}>
              <BackNavigator title="Payment" />
            </div>
          )}

          <div css={styles.infoContainer}>
            <Grid lg={8} sm={10} xs={12}>
              {hasIncorrectBankAcc || !hasPaymentInformation ? (
                <div css={styles.warningIndicator}>
                  <Icomoon color="#ffb63d" icon="warning" size={20} />
                  <div>
                    <span>
                      {t(hasIncorrectBankAcc ? 'Incorrect bank account' : 'Bank account has not been registered')}
                    </span>
                    <div>
                      <span>
                        {t(
                          hasIncorrectBankAcc
                            ? `We couldn't transfer the money because your registered bank account is incorrect! Click the button below to correct it`
                            : 'You need to register your bank account to receive your reward'
                        )}
                        <span onClick={onClickRedirectPaymentInformation}>{`>${t('settings')}`}</span>
                      </span>
                    </div>
                  </div>
                </div>
              ) : null}

              <div css={styles.pendingRevenueContainer}>
                <div>
                  <div>
                    <div>{t('Payment.Pending Revenue')}</div>
                    <div>
                      <span>{formatNumberWithCommas(grossRevenue, 0)}&nbsp;</span>
                      <span>{grossRevenueCurrency}</span>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <ThemeButton
                      disabled={!isPayableCampaigns || hasIncorrectBankAcc || !hasPaymentInformation}
                      text="Request Payment"
                      theme="blue"
                      onClick={() => setIsDialogOpen(true)}
                    />
                    <ThemeButton href={ROUTES.PAYMENT_HISTORY} text="Payment History" />
                  </div>
                </div>
              </div>

              {loading ? (
                <ListIndicator />
              ) : isPayableCampaigns ? (
                <>
                  <div css={styles.payableCampaignsHeader}>
                    <div>{t('Payable finished campaigns')}</div>
                    <div>
                      <span>{payableCampaigns.length}</span>
                      <span>{t('HeaderColumn.Campaign')}</span>
                    </div>
                  </div>
                  <div css={styles.payableCampaignsContainer}>
                    {payableCampaigns.map(campaign => {
                      const { currency, id, revenue, thumbNail, title } = campaign;

                      return (
                        <div css={styles.payableCampaignContainer} key={id}>
                          <div>
                            <div>
                              <img alt="campaignImg" height="72" src={thumbNail} width="112" />
                            </div>
                            <div>{title}</div>
                          </div>
                          <div>
                            <div>{t('Revenue')}</div>
                            <div>
                              <span>{formatNumberWithCommas(revenue, 0)}&nbsp;</span>
                              <span>{currency}</span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : (
                <div css={styles.emptyJobContainer}>
                  <div>
                    <div>
                      <img alt="emptyJob" height="124" src={searchJobBackground} width="120" />
                    </div>
                    <span>{t('Annotation.There are no completed projects at this time')}</span>
                    <span>{t(`Annotation.Join the job and get rewarded`)}</span>
                    {role !== UserRoles.CREATOR_STAFF && (
                      <ThemeButton
                        text="Search Job"
                        size="large"
                        href={ROUTES.MARKETPLACE_SEARCH_JOB}
                        css={[styles.searchJobBtn, styles.button]}
                      />
                    )}
                  </div>
                </div>
              )}
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  container: css`
    display: flex;
    justify-content: center;
    margin: 32px 80px;

    @media (max-width: ${ViewportType.TABLET}px) {
      margin: 16px 16px 32px 16px;
    }

    & > div {
      max-width: 1080px;
      width: 100%;
    }
  `,
  dialogAction: css`
    display: flex;
    justify-content: center;
    padding: 16px;
    gap: 16px;
  `,
  dialogCloseBtn: css`
    align-items: center;
    background-color: #000;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    position: absolute;
    right: -10px;
    top: -15px;
    width: 40px;

    & > i {
      margin: 0;
    }
  `,
  dialogContent: css`
    border-bottom: 1px solid #dee5ec;
    display: grid;
    padding: 24px 16px;

    & > div:nth-of-type(1) {
      color: #27313b;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 16px;
    }

    & > div:nth-of-type(2) {
      color: #27313b;
      font-size: 14px;
    }
  `,
  emptyJobContainer: css`
    align-items: center;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
    display: flex;
    height: 342px;
    justify-content: center;
    width: 100%;

    /* stylelint-disable no-descending-specificity */
    & > div {
      display: grid;
      text-align: center;
      max-width: 300px;
      justify-items: center;

      & > span:nth-of-type(1) {
        color: #27313b;
        font-size: 14px;
        margin-top: 16px;
      }

      & > span:nth-of-type(2) {
        color: #6e7c89;
        font-size: 12px;
        margin-top: 16px;
      }
    }
  `,
  infoContainer: css`
    display: flex;
    justify-content: center;
  `,
  payableCampaignContainer: css`
    padding: 24px;

    & > div:nth-of-type(1) {
      align-items: center;
      border-bottom: 1px dashed #dee5ec;
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 16px;

      & > div:nth-of-type(1) {
        background-color: #000;
        height: 72px;
        margin-right: 16px;
        width: 112px;

        & > img {
          object-fit: contain;
        }
      }

      & > div:nth-of-type(2) {
        color: #27313b;
        display: flex;
        flex: 1;
        font-size: 14px;
      }
    }

    & > div:nth-of-type(2) {
      display: flex;
      flex-wrap: wrap;
      padding-top: 16px;

      & > div:nth-of-type(1) {
        color: #27313b;
        display: flex;
        flex-basis: 50%;
        font-size: 14px;
      }

      & > div:nth-of-type(2) {
        display: flex;
        flex-basis: 50%;
        justify-content: flex-end;

        & > span:nth-of-type(1) {
          color: #27313b;
          font-size: 14px;
          font-weight: 600;
        }

        & > span:nth-of-type(2) {
          color: #27313b;
          font-size: 14px;
        }
      }
    }
  `,
  payableCampaignsContainer: css`
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;

    & > div:not(:last-child) {
      border-bottom: 1px solid #eef3f7;
    }
  `,
  payableCampaignsHeader: css`
    align-items: center;
    display: flex;
    margin-bottom: 10px;

    & > div:nth-of-type(1) {
      color: #27313b;
      display: flex;
      flex-basis: 50%;
      font-size: 14px;
    }

    & > div:nth-of-type(2) {
      align-items: center;
      display: flex;
      flex-basis: 50%;
      justify-content: flex-end;

      & > span:nth-of-type(1) {
        color: #27313b;
        font-size: 14px;
        font-weight: 600;
      }

      & > span:nth-of-type(2) {
        color: #6e7c89;
        font-size: 11px;
        margin-left: 4px;
      }
    }
  `,
  pendingRevenueContainer: css`
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
    display: flex;
    height: 128px;
    margin: 0 0 24px 0;

    .btn-text {
      padding: 0 8px;
    }

    & > div:nth-of-type(1) {
      align-items: center;
      border-right: 1px solid #dee5ec;
      display: flex;
      flex-basis: 49.5%;

      & > div {
        padding: 0 24px;

        & > div:nth-of-type(1) {
          color: #27313b;
          font-size: 12px;
        }

        & > div:nth-of-type(2) {
          & > span:nth-of-type(1) {
            color: #27313b;
            font-size: 18px;
            font-weight: 600;
          }

          & > span:nth-of-type(2) {
            color: #27313b;
            font-size: 12px;
            font-weight: 600;
          }
        }
      }
    }

    & > div:nth-of-type(2) {
      align-items: center;
      display: flex;
      flex-basis: 50%;

      & > div {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        padding: 0 24px;
      }
    }
  `,
  searchJobBtn: css`
    height: 40px;
    margin-top: 24px;
    width: 156px;
  `,
  warningFont: css`
    color: #ff5f5f;
  `,
  warningIndicator: css`
    background-color: #fff;
    border-left: 4px solid #ffb63d;
    border-radius: 5px;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
    padding: 12px;

    & > svg {
      margin-right: 12px;
    }

    & > div {
      display: grid;
      flex: 1;

      & > span {
        color: #27313b;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 8px;
      }

      & > div {
        color: #6e7c89;
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;

        & > span > span {
          color: #6e7c89;
          cursor: pointer;
          display: initial;
          font-size: 14px;
          margin: 0 8px;
          text-decoration: underline;
        }
      }
    }
  `,
  button: css`
    @media (max-width: ${ViewportType.TABLET}px) {
      height: 40px;
    }
  `,
};

export default Payment;
