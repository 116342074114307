import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { Trans } from 'react-i18next';
import FileDrop from '@src/components/molecules/FileDrop';
import { useQueryHelper } from '@src/libs/hooks';
import Dialog from '@src/components/molecules/Dialog';

interface ImportFansModalProps {
  open: boolean;
  onClose: () => void;
  onImportFile: (file: File) => void;
}

const ImportFansModal = (props: ImportFansModalProps) => {
  const childRef = useRef<{
    requestUpload: () => Promise<void>;
    newFile: File;
  }>(null);
  const { open, onClose, onImportFile } = props;
  const { t } = useQueryHelper();
  // currently only fan csv download access the bucket and both stg and prod using the same url
  const sampleFanCsvPath = `https://storage.googleapis.com/castingasia-public/2022-06-21/manual-upload-template-fans.csv`;

  const onClickImportFans = () => {
    if (childRef.current?.newFile) {
      onImportFile(childRef.current.newFile);
    }
  };

  return (
    <Dialog visible={open} onClose={onClose} onExec={onClickImportFans} execText="Import Customers">
      <Heading>{t('Import customers by CSV')}</Heading>
      <div css={{ display: 'flex' }}>
        <Trans i18nKey="Fan upload annotation">
          text0
          <DownloadTemplate download rel="noopener noreferrer" target="_blank" to={{ pathname: sampleFanCsvPath }}>
            {{ text: t('sample CSV') } as any}
          </DownloadTemplate>
        </Trans>
      </div>
      <FileDrop ref={childRef} accept=".csv, text/csv" multiple={false} notes={[]} />
    </Dialog>
  );
};

const DownloadTemplate = styled(Link)`
  cursor: pointer;
  color: #0093ff;
  margin: 0 6px;
  text-decoration: underline;
`;
const Heading = styled.h2`
  font-weight: 600;
  font-size: 18px;
  color: #27313b;
  margin-bottom: 16px;
`;

export default ImportFansModal;
