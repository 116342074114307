import React, { useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useHistory } from 'react-router';
import { sendAmplitudeEvent } from '@src/amplitude';
import { checkSignedIn } from '@src/libs/auth';
import { useUuumInfluencerView } from '@src/libs/hooks';
import { ROUTES } from '@src/shared/routes';
import { SocialAccountType } from '@src/__generated__/globalTypes';

interface PublicRouteProps extends RouteProps {
  amplitudeEvent?: string;
}

const PublicRoute = ({ amplitudeEvent, component: Component, ...rest }: PublicRouteProps) => {
  const history = useHistory();
  const isSignedIn = checkSignedIn();
  const { isUuumView } = useUuumInfluencerView();

  useEffect(() => {
    if (amplitudeEvent) {
      sendAmplitudeEvent(amplitudeEvent);
    }
  }, [amplitudeEvent]);

  if (isSignedIn) {
    history.push(isUuumView ? `${ROUTES.ANALYTICS}?sm=${SocialAccountType.YOUTUBE}` : ROUTES.HOME);
  }

  return <Route {...rest} render={props => (Component ? <Component {...props} /> : null)} />;
};

export default PublicRoute;
