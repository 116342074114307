import React from 'react';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { arrowTopLeft, arrowTopRight } from '@src/assets/htmlCodes';
import anyCreatorLogo from '@src/assets/icon/anyCreatorLogo.svg';
import exchangeFBIcon from '@src/assets/icon/exchangeFB.svg';
import Switch from '@src/components/molecules/Switch';
import { useDirLayout } from '@src/libs/hooks';
import { switchImage } from '@src/libs/SocialMedia';
import { SocialAccountType } from '@src/__generated__/globalTypes';

export const defaultWidth = 247;

interface AccessPermissionProps {
  width?: number;
}

const AccessPermission = ({ width = defaultWidth }: AccessPermissionProps) => {
  const { t } = useTranslation();
  const { isRtl } = useDirLayout();
  const options = [
    { sample: 'sample@anymindgroup.com', title: 'Receive your email address' },
    {
      sample: 'sample_business_01',
      title: 'Access profile and posts from the Instagram account connected to your Page',
    },
    { sample: 'sample_business_01', title: 'Manage comments for the Instagram account connected to your Page' },
    { sample: 'sample_business_01', title: 'Access insights for the Instagram account connected to your Page' },
  ];
  const quotient = width / defaultWidth;
  const exchangeIconSize = quotient * 8;
  const iconSize = quotient * 12;

  return (
    <div>
      <div css={styles.accessPermissionContainer(quotient)}>
        <div css={styles.toolbar(quotient)}>
          <img height={iconSize} src={switchImage(SocialAccountType.FACEBOOK, true)} width={iconSize} />
          <img height={exchangeIconSize} src={exchangeFBIcon} width={exchangeIconSize} />
          <img height={iconSize} src={anyCreatorLogo} width={iconSize} />
        </div>

        <div css={styles.contentContainer(quotient)}>
          <div>{t('Facebook access permission page title')}</div>
          <div>
            <AiOutlineExclamationCircle color="#839ed8" size={quotient * 10} />
            <div>{t('Facebook access permission page hint')}</div>
          </div>

          <div>
            {options.map((option, optionIndex) => {
              const { sample, title } = option;

              return (
                <div css={styles.option(quotient)} key={optionIndex}>
                  <div>
                    <div>{t(title)}</div>
                    <div>{sample}</div>
                  </div>
                  <div>
                    <Switch
                      css={styles.switch(quotient)}
                      customBgColor="#4379de"
                      isChecked
                      labelOn=""
                      handleChange={() => null}
                    />
                  </div>
                </div>
              );
            })}
          </div>

          <div css={styles.actionContainer(quotient)}>
            <div>{t('Button.Back')}</div>
            <div>{t('Button.Done')}</div>
          </div>

          <div css={styles.scrollbar(quotient)} />
          <div css={styles.highlightedBox(quotient)} />
          <div css={styles.arrow(quotient)}>{isRtl ? arrowTopLeft : arrowTopRight}</div>
        </div>
      </div>

      <div css={styles.message(quotient)}>
        <div>{t('Turn all toggles')}</div>
        <Switch customBgColor="#3992e5" isChecked handleChange={() => null} />
      </div>
    </div>
  );
};

const styles = {
  accessPermissionContainer: (quotient: number) => css`
    border: 1px solid #dee5ec;
    border-radius: 5px;
    box-shadow: 0 2px 1px rgba(110, 124, 137, 0.1), 0 3px 5px rgba(110, 124, 137, 0.2);
    height: ${quotient * 377}px;
    position: relative;
    width: ${quotient * 211}px;
  `,
  actionContainer: (quotient: number) => css`
    background-color: #fff;
    border-top: 1px solid #e0dfe0;
    bottom: 0;
    display: flex;
    gap: ${quotient * 4}px;
    left: 0;
    padding: ${quotient * 18}px;
    position: absolute;
    width: fill-available;

    & > div:nth-of-type(1) {
      align-items: center;
      border: 1px solid #bcbcbf;
      border-radius: 3px;
      color: #4e4f53;
      display: flex;
      flex: 1;
      font-size: ${quotient * 8}px;
      font-weight: 600;
      justify-content: center;
      padding: ${quotient * 6}px 0;
    }

    & > div:nth-of-type(2) {
      align-items: center;
      background-color: #4f82f7;
      border: 1px solid #4f82f7;
      border-radius: 3px;
      color: #fff;
      display: flex;
      flex: 1;
      font-size: ${quotient * 8}px;
      font-weight: 600;
      justify-content: center;
      padding: ${quotient * 6}px 0;
    }
  `,
  arrow: (quotient: number) => css`
    bottom: ${quotient * 15}px;
    color: #ff5f5f;
    font-family: 'Proxima Nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: ${quotient * 45}px;
    position: absolute;
    right: ${quotient * 50}px;

    [dir='rtl'] & {
      left: ${quotient * 50}px;
      right: unset;
    }
  `,
  contentContainer: (quotient: number) => css`
    padding: ${quotient * 8}px ${quotient * 19}px;

    & > div:nth-of-type(1) {
      color: #060508;
      font-size: ${quotient * 10}px;
      font-weight: 600;
      margin-bottom: ${quotient * 24}px;
    }

    & > div:nth-of-type(2) {
      align-items: center;
      border: 1px solid #e6e4e7;
      border-left: ${quotient * 2}px solid #4278e0;
      display: flex;
      height: ${quotient * 18}px;
      margin-bottom: ${quotient * 24}px;
      padding: ${quotient * 6}px;
      position: relative;

      [dir='rtl'] & {
        border-left: none;
        border-right: ${quotient * 2}px solid #4278e0;
      }

      /* stylelint-disable no-descending-specificity */
      & > div {
        background-color: #fff;
        color: #89898b;
        font-size: ${quotient * 8}px;
        font-weight: 500;
        padding: ${quotient * 5}px ${quotient * 7}px;
        position: absolute;
        right: 0;
        top: 0;
        width: 85%;

        [dir='rtl'] & {
          left: 0;
          right: unset;
        }
      }
    }
  `,
  highlightedBox: (quotient: number) => css`
    border: ${quotient * 2}px solid #ff5f5f;
    border-radius: 3px;
    height: ${quotient * 175}px;
    position: absolute;
    right: ${quotient * 20}px;
    top: ${quotient * 142}px;
    width: ${quotient * 25}px;

    [dir='rtl'] & {
      left: ${quotient * 20}px;
      right: unset;
    }
  `,
  message: (quotient: number) => css`
    align-items: center;
    color: #ff5f5f;
    display: flex;
    font-size: ${quotient * 16}px;
    font-weight: 600;
    gap: 8px;
    margin-top: ${quotient * 21}px;
    justify-content: center;

    & button {
      height: ${quotient * 24}px;
      width: ${quotient * 48}px;

      & > span {
        border: 1px solid #d8d6d8;
        height: ${quotient * 16}px;
        transform: translate(${quotient * 23}px) !important;
        width: ${quotient * 16}px;

        [dir='rtl'] & {
          transform: translate(${quotient * -28}px) !important;
        }
      }

      & > label {
        font-size: ${quotient * 12}px;
        right: unset;
        top: ${quotient * 5}px;

        [dir='rtl'] & {
          right: ${quotient * 5}px;
        }
      }
    }
  `,
  option: (quotient: number) => css`
    border-bottom: 1px solid #e6e4e7;
    display: flex;
    padding: ${quotient * 6}px 0;

    & > div:nth-of-type(1) {
      width: 80%;

      & > div:nth-of-type(1) {
        color: #606062;
        font-size: ${quotient * 8}px;
        font-weight: 600;
        margin-bottom: ${quotient * 2}px;
      }

      & > div:nth-of-type(2) {
        color: #9b9b9c;
        font-size: ${quotient * 7}px;
      }
    }

    & > div:nth-of-type(2) {
      align-items: center;
      display: flex;
      flex: 1;
      justify-content: center;
    }
  `,
  scrollbar: (quotient: number) => css`
    background-color: #98a3af;
    border-radius: 35px;
    height: ${quotient * 90}px;
    position: absolute;
    right: ${quotient * 5}px;
    top: ${quotient * 150}px;
    width: ${quotient * 2}px;
  `,
  switch: (quotient: number) => css`
    & > button {
      height: ${quotient * 8}px;
      width: ${quotient * 12}px;

      & > span {
        border: 1px solid #d8d6d8;
        height: ${quotient * 8}px;
        position: absolute;
        top: -1px;
        transform: translate(${quotient}px) !important;
        width: ${quotient * 8}px;

        [dir='rtl'] & {
          transform: translate(${quotient * -6}px) !important;
        }
      }
    }
  `,
  toolbar: (quotient: number) => css`
    align-items: center;
    background: linear-gradient(#e1e1e1 10%, #f8f7fb 35%);
    display: flex;
    gap: ${quotient * 6}px;
    padding: ${quotient * 8}px;
    position: relative;

    &::after {
      background: linear-gradient(to right, #367bed 85%, #eceef1 15%);
      border-top: 1px solid #e2e4e6;
      bottom: 0;
      content: '';
      left: 0;
      height: ${quotient * 3}px;
      position: absolute;
      width: 100%;

      [dir='rtl'] & {
        background: linear-gradient(to left, #367bed 85%, #eceef1 15%);
      }
    }
  `,
};

export default AccessPermission;
