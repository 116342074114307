import React from 'react';
import { useTranslation } from 'react-i18next';
import { LANGUAGE_OPTIONS } from '../../../libs/i18n';
import LanguageSwitcher from './LanguageSwitcher';

export interface LanguageSwitcherProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  visible: boolean;
}

const LanguageSwitcherComponent = (props: LanguageSwitcherProps) => {
  const { i18n } = useTranslation();
  const { onClick: extraOperation, visible } = props;

  const languages = LANGUAGE_OPTIONS.map(item => {
    const { value, label: title } = item;
    const onClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
      await i18n.changeLanguage(value);
      if (!!extraOperation) {
        extraOperation(event);
      }
    };

    return {
      title,
      onClick,
    };
  });

  return <LanguageSwitcher visible={visible} languages={languages} />;
};

export default LanguageSwitcherComponent;
