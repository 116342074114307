export const COLORS = {
  black: {
    light2: '#d5d5d5',
    light: '#757575',
    base: '#3b4146',
  },
  green: {
    base: '#00ac56',
    dark: '#00984c',
  },
  blue: {
    base: '#179cd7',
    dark: '#178BC3',
  },
  red: {
    base: '#ff2b51',
    dark: '#eb294b',
  },
};

const FOCUSED = '#179cd7';

export const FORM = {
  BORDER: '#e0e8ed',
  DISABLED: '#f2f2f2',
  ERROR: 'tomato',
  FOCUSED,
};

export enum ViewportType {
  SMALL = 556,
  MEDIUM = 761,
  TABLET = 940,
  LARGE = 1271,
  EXTRA_LARGE = 1920,
}

export enum SignUpAndSignInBreakpoint {
  MEDIUM = 870,
}

// TODO: Remove old breakpoints when applying new design is finished
export enum ViewportBreakpoint {
  MOBILE = 450,
  MEDIUM = 768,
  DESKTOP = 1200,
  LARGE = 1400, // Mainly used for Analytics Profile Pages
}
// For iframe we don't have sidebar
export const DesktopSidebarWidth = window.location === window.parent.location ? 192 : 0;
