import styled from '@emotion/styled';
import * as React from 'react';
import { Spinner } from '@anymindgroup/any-ui-react';

interface PageLoadingProps {
  className?: string;
}
const PageLoading = (props: PageLoadingProps) => {
  const { className } = props;

  return (
    <Wrapper className={className}>
      <Spinner size={'64px'} thickness={'4px'} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  margin: 50%;
  background-color: #fff;
`;

export default PageLoading;
