import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ThemeButton } from '@src/components/atoms/Button';
import Icomoon from '@src/components/atoms/Icomoon';
import Label from '@src/components/atoms/Label';
import TextForm from '@src/components/molecules/TextForm';
import { FontSize } from '@src/__generated__/globalTypes';
import BlockCard from '../BlockCard';
import { FormInformation, FormType, LineContentButtonCard } from '../helpers';
import { DragAndDrop, FontSizePicker } from '../StyledComponents';
import ActionLabelCard from './ActionLabelCard';

interface ButtonTypeInputProps {
  isDisabled?: boolean;
}

const ButtonTypeInput = ({ isDisabled }: ButtonTypeInputProps) => {
  const [contentIndex, setContentIndex] = useState<number>(0);
  const { t } = useTranslation();
  const {
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<FormInformation>();
  const [formType, lineContent, selectedBlockIndex] = watch(['formType', 'lineContent', 'selectedBlockIndex']);

  const buttonTypeContent = lineContent[selectedBlockIndex];
  const buttonTypeContentLength = buttonTypeContent.buttonMessage?.cards.length || 0;
  const maxActionCards = 3;
  const maxCards = 12;
  const selectedContent = buttonTypeContent.buttonMessage?.cards[contentIndex];
  const actionLabelsLength = selectedContent?.actionLabels?.length || 0;

  const onChangeCard = (lineContentButtonCard: LineContentButtonCard) => {
    const cards = [...(buttonTypeContent.buttonMessage?.cards || [])];
    cards[contentIndex] = lineContentButtonCard;

    const items = [...lineContent];
    items[selectedBlockIndex] = {
      ...buttonTypeContent,
      buttonMessage: {
        cards,
      },
    };
    setValue('lineContent', items);
  };

  const onChangeCardActionLabel = (index: number, val: { label: string; url: string }) => {
    const cards = [...(buttonTypeContent.buttonMessage?.cards || [])];
    cards[contentIndex].actionLabels[index] = { ...cards[contentIndex].actionLabels[index], ...val };

    const items = [...lineContent];
    items[selectedBlockIndex] = {
      ...buttonTypeContent,
      buttonMessage: {
        cards,
      },
    };
    setValue('lineContent', items);
  };

  const onClickAdd = () => {
    const cards = [...(buttonTypeContent.buttonMessage?.cards || [])];
    cards.push({
      actionLabels: [{ genId: uuidv4(), label: '', url: '' }],
      description: {
        fontSize: FontSize.LG,
        text: '',
        weighted: false,
      },
      genId: uuidv4(),
      imageUrl: '',
      title: {
        fontSize: FontSize.SM,
        text: '',
        weighted: false,
      },
    });

    const items = [...lineContent];
    items[selectedBlockIndex] = {
      ...buttonTypeContent,
      buttonMessage: {
        cards,
      },
    };
    setValue('lineContent', items);
  };

  const onClickAddAction = () => {
    const cards = [...(buttonTypeContent.buttonMessage?.cards || [])];
    cards[contentIndex].actionLabels.push({
      genId: uuidv4(),
      label: '',
      url: '',
    });

    const items = [...lineContent];
    items[selectedBlockIndex] = {
      ...buttonTypeContent,
      buttonMessage: {
        cards,
      },
    };
    setValue('lineContent', items);
  };

  const onClickDelete = (index: number) => {
    const cards = [...(buttonTypeContent.buttonMessage?.cards || [])];
    cards.splice(index, 1);

    const items = [...lineContent];
    items[selectedBlockIndex] = {
      ...buttonTypeContent,
      buttonMessage: {
        cards,
      },
    };
    setValue('lineContent', items);
  };

  const onClickDeleteActionLabel = (index: number) => {
    const cards = [...(buttonTypeContent.buttonMessage?.cards || [])];
    cards[contentIndex].actionLabels.splice(index, 1);

    const items = [...lineContent];
    items[selectedBlockIndex] = {
      ...buttonTypeContent,
      buttonMessage: {
        cards,
      },
    };
    setValue('lineContent', items);
  };

  const onDragEndBlock = ({ destination, source }: DropResult) => {
    if (!destination) {
      return;
    }

    const destinationIndex = destination.index;
    const sourceIndex = source.index;
    const cards = [...(buttonTypeContent.buttonMessage?.cards || [])];
    const dragCard = cards[sourceIndex];
    cards.splice(sourceIndex, 1);
    cards.splice(destinationIndex, 0, dragCard);

    const items = [...lineContent];
    items[selectedBlockIndex] = {
      ...buttonTypeContent,
      buttonMessage: {
        cards,
      },
    };
    setValue('lineContent', items);
  };

  const onDragEndLabel = ({ destination, source }: DropResult) => {
    if (!destination) {
      return;
    }

    const destinationIndex = destination.index;
    const sourceIndex = source.index;
    const cards = [...(buttonTypeContent.buttonMessage?.cards || [])];
    const actionLabels = cards[contentIndex].actionLabels;
    const dragCard = actionLabels[sourceIndex];
    actionLabels.splice(sourceIndex, 1);
    actionLabels.splice(destinationIndex, 0, dragCard);

    const items = [...lineContent];
    items[selectedBlockIndex] = {
      ...buttonTypeContent,
      buttonMessage: {
        cards,
      },
    };
    setValue('lineContent', items);
  };

  return (
    <div css={{ margin: 24 }}>
      {formType === FormType.BUTTON_TYPE ? (
        <>
          <DragDropContext onDragEnd={onDragEndBlock}>
            <Droppable droppableId="droppable">
              {droppableProvided => (
                <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                  {buttonTypeContent.buttonMessage?.cards.map((item, index) => {
                    const error =
                      !!errors.lineContent && !!item.actionLabels.find(action => !action.label || !action.url);

                    return (
                      <Draggable
                        draggableId={item.genId}
                        index={index}
                        isDragDisabled={isDisabled || buttonTypeContentLength <= 1}
                        key={item.genId}
                      >
                        {(draggableProvider, draggableSnapshot) => (
                          <div
                            ref={draggableProvider.innerRef}
                            {...draggableProvider.draggableProps}
                            {...draggableProvider.dragHandleProps}
                          >
                            <BlockCard
                              error={error}
                              isDeletable={!isDisabled && buttonTypeContentLength > 1}
                              isDragging={draggableSnapshot.isDragging}
                              key={item.genId}
                              onClick={() => setContentIndex(index)}
                              onDelete={() => onClickDelete(index)}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {droppableProvided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          {!isDisabled && (
            <>
              <ThemeButton
                text="Add Card"
                size="large"
                width="max-content"
                prefixIcon={<Icomoon icon="plus" />}
                onClick={onClickAdd}
                disabled={buttonTypeContentLength >= maxCards}
              />
              <div css={styles.cardCount}>{`${buttonTypeContentLength} / ${t('Card Maximum', {
                count: maxCards,
              })}`}</div>
            </>
          )}
        </>
      ) : selectedContent ? (
        <div>
          <Label css={styles.label} title={t('Image')} />
          <DragAndDrop
            disabled={isDisabled}
            imageUrl={selectedContent.imageUrl}
            onChange={val => onChangeCard({ ...selectedContent, imageUrl: val })}
          />

          <TextFieldContainer css={{ marginTop: 24 }}>
            <div>
              <TextForm
                disabled={isDisabled}
                placeholder={t('TextForm.Title Text')}
                title="Title Text"
                value={selectedContent.title.text}
                onChange={e =>
                  onChangeCard({ ...selectedContent, title: { ...selectedContent.title, text: e.target.value } })
                }
              />
            </div>
            <div>
              <FontSizePicker
                disabled={isDisabled}
                isBold={selectedContent.title.weighted}
                value={selectedContent.title.fontSize}
                onChange={val =>
                  onChangeCard({
                    ...selectedContent,
                    title: { ...selectedContent.title, fontSize: val.value, weighted: val.isBold },
                  })
                }
              />
            </div>
          </TextFieldContainer>

          <TextFieldContainer css={{ marginTop: 16 }}>
            <div>
              <TextForm
                disabled={isDisabled}
                placeholder={t('TextForm.Description Text')}
                title="Description Text"
                value={selectedContent.description.text}
                onChange={e =>
                  onChangeCard({
                    ...selectedContent,
                    description: { ...selectedContent.description, text: e.target.value },
                  })
                }
              />
            </div>
            <div>
              <FontSizePicker
                disabled={isDisabled}
                isBold={selectedContent.description.weighted}
                value={selectedContent.description.fontSize}
                onChange={val =>
                  onChangeCard({
                    ...selectedContent,
                    description: { ...selectedContent.description, fontSize: val.value, weighted: val.isBold },
                  })
                }
              />
            </div>
          </TextFieldContainer>

          <DragDropContext onDragEnd={onDragEndLabel}>
            <Droppable droppableId="droppable">
              {droppableProvided => (
                <div css={{ marginTop: 46 }} ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                  {selectedContent?.actionLabels.map((item, index) => (
                    <Draggable
                      draggableId={item.genId}
                      index={index}
                      isDragDisabled={isDisabled || actionLabelsLength <= 1}
                      key={item.genId}
                    >
                      {(draggableProvider, draggableSnapshot) => (
                        <div
                          ref={draggableProvider.innerRef}
                          {...draggableProvider.draggableProps}
                          {...draggableProvider.dragHandleProps}
                        >
                          <ActionLabelCard
                            actionLabel={item}
                            disabled={isDisabled}
                            error={!!errors.lineContent}
                            isDeletable={!isDisabled && actionLabelsLength > 1}
                            isDragging={draggableSnapshot.isDragging}
                            key={item.genId}
                            onChange={val => onChangeCardActionLabel(index, val)}
                            onDelete={() => onClickDeleteActionLabel(index)}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {droppableProvided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          {!isDisabled && (
            <>
              <ThemeButton
                disabled={actionLabelsLength >= maxActionCards}
                skipTranslation
                prefixIcon={<Icomoon icon="plus" />}
                text="Add Action Button"
                size="large"
                width="max-content"
                onClick={onClickAddAction}
              />
              <div css={styles.cardCount}>{`${actionLabelsLength} / ${t('Card Maximum', {
                count: maxActionCards,
              })}`}</div>
            </>
          )}
        </div>
      ) : null}
    </div>
  );
};

const TextFieldContainer = styled.div`
  align-items: flex-end;
  display: flex;
  gap: 4px;

  & > div:nth-of-type(1) {
    display: flex;
    flex: 1;

    & > div {
      width: 100%;
    }
  }
`;

const styles = {
  cardCount: css`
    color: #c5d0da;
    font-size: 12px;
    margin-top: 4px;
  `,
  label: css`
    color: #27313b;
    font-size: 14px;
  `,
};

export default ButtonTypeInput;
