import React from 'react';
import Grid from '@src/components/atoms/Grid';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import { useAuthData, usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { ROUTES } from '@src/shared/routes';
import { InfluencerType } from '@src/__generated__/globalTypes';
import * as Styled from './Styled';
import { LineChannel } from './LineChannel';
import { ShopifyChannel } from './ShopifyChannel';

const Settings = () => {
  const { creatorType } = useAuthData();
  const { t, history } = useQueryHelper();
  const { isMobileView } = usePageLayout();

  if (creatorType !== InfluencerType.PRO) {
    history.push(ROUTES.SETTINGS);

    return null;
  }

  const { styles } = Styled;

  return (
    <>
      <div css={styles.container}>
        {!isMobileView && <BackNavigator title="Channel Connect" to={ROUTES.SETTINGS} />}

        <div css={styles.infoContainer}>
          <Grid md={8} sm={10} xs={12}>
            <div css={styles.channelContainer}>
              <div css={styles.channelHeader}>{t('Title.Channel Connection')}</div>

              <ShopifyChannel />

              <LineChannel />
            </div>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default Settings;
