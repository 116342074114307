import { useQuery } from '@apollo/client';
import GET_PERSONAL_INFORMATION from '@src/libs/queries/GetPersonalUserDetail.graphql';
import { GetPersonalUserDetail } from '@src/libs/queries/__generated__/GetPersonalUserDetail';
import useQueryHelper from './useQueryHelper';

export default () => {
  const { t, enqueueSnackbar } = useQueryHelper();

  const { data, loading } = useQuery<GetPersonalUserDetail>(GET_PERSONAL_INFORMATION, {
    fetchPolicy: 'no-cache',
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  return { loading, userPersonalInfo: data?.getPersonalUserDetail };
};
