import { css } from '@emotion/react';
import { ThemeButton } from '@src/components/atoms/Button';
import { ViewportType } from '@src/libs/theme';

export const FormSubmitSection = ({ isSubmitting }: { isSubmitting: boolean }) => (
  <div css={styles.formActionSection}>
    <ThemeButton theme="blue" disabled={isSubmitting} text="Save" type="submit" />
  </div>
);

const styles = {
  formActionSection: css`
    background-color: #fff;
    border-top: 1px solid #eef3f7;
    display: flex;
    justify-content: flex-end;
    padding: 16px;

    & > button {
      width: max-content;

      @media (max-width: ${ViewportType.SMALL}px) {
        width: 100%;
        height: 40px;
        border-radius: 5px;
      }
    }
  `,
};
