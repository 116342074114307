import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import Icomoon from '@src/components/atoms/Icomoon';
import { AnyXEngagementCampaignStatus } from '@src/__generated__/globalTypes';

interface ReasonWidgetProps {
  content: string | null;
  status: AnyXEngagementCampaignStatus;
  title: string | null;
}

const ReasonWidget = ({ content, status, title }: ReasonWidgetProps) => {
  const { t } = useTranslation();

  const { color, description, headline, icon, iconColor } = (() => {
    switch (status) {
      case AnyXEngagementCampaignStatus.POSTING:
        return {
          color: '#ffb63d',
          description: 'Checking the change post',
          headline: 'Post reflection time',
          icon: 'clock-anti-clockwise',
          iconColor: '#eccc9c',
        };
      case AnyXEngagementCampaignStatus.REVIEWING:
        return {
          color: '#ffb63d',
          description: 'After posting, it will take around 20 minutes for the report to take',
          headline: 'Checking the post again',
          icon: 'clock-anti-clockwise',
          iconColor: '#eccc9c',
        };
      default:
        return {
          color: '#ff5f5f',
          description: content || '',
          headline: title || 'Invalid contents',
          icon: 'warning',
          iconColor: '#ff5f5f',
        };
    }
  })();

  return (
    <Container color={color}>
      <div>
        <Icomoon color={iconColor} icon={icon} size="18" />
      </div>
      <div>
        <div>{t(headline)}</div>
        <div>{t(description)}</div>
      </div>
    </Container>
  );
};

const Container = styled.div<{ color: string }>`
  border-left: 3px solid ${({ color }) => color};
  border-radius: 3px;
  box-shadow: 0 1px 5px rgba(39, 49, 59, 0.2);
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
  width: 100%;

  & > div:nth-of-type(2) {
    display: grid;
    flex-basis: 88%;
    margin-left: 8px;

    & > div:nth-of-type(1) {
      color: #27313b;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    & > div:nth-of-type(2) {
      color: #6e7c89;
      font-size: 13px;
    }
  }
`;

export default ReasonWidget;
