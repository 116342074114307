import React, { useState } from 'react';
import styled from '@emotion/styled';
import emptyThumbnail from '@src/assets/icon/emptyThumbnail.svg';
import { useTranslation } from 'react-i18next';

interface ImageProps {
  src?: string | null;
  fallbackImg?: string;
  className?: string;
  width?: string;
  height?: string;
  onClick?: () => void;
  alt?: string;
  loading?: 'eager' | 'lazy';
  hasNoImgText?: boolean;
}
const Image = ({
  src,
  fallbackImg,
  width,
  height,
  onClick,
  alt,
  className,
  loading = 'lazy',
  hasNoImgText = true,
}: ImageProps) => {
  const emptyImg = fallbackImg || emptyThumbnail;
  const [isOnError, setOnError] = useState(false);
  const { t } = useTranslation();

  return isOnError || !src ? (
    <NoImageWrapper css={{ height, width }} className={className}>
      <StyledImg src={emptyImg} alt={alt || ''} onClick={onClick} css={{ maxWidth: '100%', maxHeight: '100%' }} />
      {hasNoImgText ? <EmptyText>{t('No image')}</EmptyText> : null}
    </NoImageWrapper>
  ) : (
    <StyledImg
      src={src || emptyImg}
      alt={alt || ''}
      height={height}
      width={width}
      loading={loading}
      onClick={onClick}
      className={className}
      onError={() => setOnError(true)}
    />
  );
};

const StyledImg = styled.img`
  object-fit: contain;
  background-color: #dee5ec;
`;
const NoImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #dee5ec;
`;
const EmptyText = styled.p`
  margin-top: 8px;
  text-align: center;
  font-weight: 600;
  font-size: 13px;
  color: #fff;
`;

export default Image;
