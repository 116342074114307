import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { css } from '@emotion/react';
import { ThemeButton } from '@src/components/atoms/Button';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import Label from '@src/components/atoms/Label';
import TextForm from '@src/components/molecules/TextForm';
import Icomoon from '@src/components/atoms/Icomoon';
import BlockCard from './BlockCard';
import { FormInformation, FormType } from './helpers';
import { DragAndDrop } from './StyledComponents';

interface ImageTypeInputProps {
  isDisabled?: boolean;
}

const ImageTypeInput = ({ isDisabled }: ImageTypeInputProps) => {
  const [contentIndex, setContentIndex] = useState<number>(0);
  const { t } = useTranslation();
  const {
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<FormInformation>();
  const [formType, lineContent, selectedBlockIndex] = watch(['formType', 'lineContent', 'selectedBlockIndex']);

  const imageTypeContent = lineContent[selectedBlockIndex];
  const maxCards = 10;
  const selectedContent = imageTypeContent.imageMessage?.images[contentIndex];
  const imagesLength = imageTypeContent.imageMessage?.images.length || 0;

  const onChangeImageContent = (val: { genId: string; imageUrl: string; label?: string; url: string }) => {
    const images = [...(imageTypeContent.imageMessage?.images || [])];
    images[contentIndex] = val;

    const items = [...lineContent];
    items[selectedBlockIndex] = {
      ...imageTypeContent,
      imageMessage: {
        images,
      },
    };
    setValue('lineContent', items);
  };

  const onClickAdd = () => {
    const images = [...(imageTypeContent.imageMessage?.images || [])];
    images.push({
      genId: uuidv4(),
      imageUrl: '',
      label: '',
      url: '',
    });

    const items = [...lineContent];
    items[selectedBlockIndex] = {
      ...imageTypeContent,
      imageMessage: {
        images,
      },
    };
    setValue('lineContent', items);
  };

  const onClickDelete = (index: number) => {
    const images = [...(imageTypeContent.imageMessage?.images || [])];
    images.splice(index, 1);

    const items = [...lineContent];
    items[selectedBlockIndex] = {
      ...imageTypeContent,
      imageMessage: {
        images,
      },
    };

    setValue('lineContent', items);
  };

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) {
      return;
    }

    const destinationIndex = destination.index;
    const sourceIndex = source.index;
    const images = [...(imageTypeContent.imageMessage?.images || [])];
    const dragCard = images[sourceIndex];
    images.splice(sourceIndex, 1);
    images.splice(destinationIndex, 0, dragCard);

    const items = [...lineContent];
    items[selectedBlockIndex] = {
      ...imageTypeContent,
      imageMessage: {
        images,
      },
    };
    setValue('lineContent', items);
  };

  return (
    <div css={{ padding: 24 }}>
      {formType === FormType.IMAGE_TYPE ? (
        <>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {droppableProvided => (
                <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                  {imageTypeContent.imageMessage?.images.map((item, index) => {
                    const error = !!errors.lineContent && (!item.imageUrl || !item.url);

                    return (
                      <Draggable
                        draggableId={item.genId}
                        index={index}
                        isDragDisabled={isDisabled || imagesLength <= 1}
                        key={item.genId}
                      >
                        {(draggableProvider, draggableSnapshot) => (
                          <div
                            ref={draggableProvider.innerRef}
                            {...draggableProvider.draggableProps}
                            {...draggableProvider.dragHandleProps}
                          >
                            <BlockCard
                              error={error}
                              isDeletable={!isDisabled && imagesLength > 1}
                              isDragging={draggableSnapshot.isDragging}
                              key={item.genId}
                              onClick={() => setContentIndex(index)}
                              onDelete={() => onClickDelete(index)}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {droppableProvided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          {!isDisabled && (
            <>
              <ThemeButton
                text="Add Card"
                prefixIcon={<Icomoon icon="plus" />}
                size="large"
                width="max-content"
                disabled={imagesLength >= maxCards}
                onClick={onClickAdd}
              />
              <div css={styles.cardCount}>{`${imagesLength} / ${t('Card Maximum', {
                count: maxCards,
              })}`}</div>
            </>
          )}
        </>
      ) : selectedContent ? (
        <>
          <Label css={styles.label} isRequired title={t('Image')} />
          <DragAndDrop
            disabled={isDisabled}
            error={!!errors.lineContent && !selectedContent.imageUrl}
            imageUrl={selectedContent.imageUrl}
            onChange={imageUrl => onChangeImageContent({ ...selectedContent, imageUrl })}
          />
          {errors.lineContent && !selectedContent.imageUrl && <ErrorMessage message={'requiredFieldMessage'} />}

          <div css={{ marginTop: 24 }}>
            <TextForm
              disabled={isDisabled}
              placeholder={t('Placeholder.See more')}
              title="Action Label"
              value={selectedContent.label}
              onChange={e => onChangeImageContent({ ...selectedContent, label: e.target.value })}
            />
          </div>

          <div css={{ marginTop: 16 }}>
            <TextForm
              disabled={isDisabled}
              error={!!errors.lineContent && !selectedContent.url}
              isRequired
              placeholder="http://anymindgroup.com"
              title="URL"
              value={selectedContent.url}
              onChange={e => onChangeImageContent({ ...selectedContent, url: e.target.value })}
            />
            {errors.lineContent && !selectedContent.url && <ErrorMessage message={'requiredFieldMessage'} />}
          </div>
        </>
      ) : null}
    </div>
  );
};

const styles = {
  addCardBtn: css`
    border: 1px solid #dee5ec;
    border-radius: 3px;
    color: #6e7c89;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 4px;
    width: 112px;
  `,
  cardCount: css`
    color: #c5d0da;
    font-size: 12px;
    margin-top: 4px;
  `,
  label: css`
    color: #27313b;
    font-size: 14px;
  `,
};

export default ImageTypeInput;
