import React, { ReactElement, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useDirLayout, usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/theme';
import { ROUTES } from '@src/shared/routes';
import IntercomInitializer from '../Intercom';
import Footer from '../Footer';
import Menu from './Menu';
import { menuWidth } from './helpers';
import MobileMenu from './MobileMenu';
import MobileToolbar from './MobileToolbar';

export interface LayoutProps {
  backPath?: string;
  hideFooter?: boolean;
  toolbarTitle?: string;
  skipBottomPadding?: boolean;
}

interface MainProps extends LayoutProps {
  children: ReactElement | ReactElement[] | null;
}

const Layout = ({ children, backPath, hideFooter, toolbarTitle, skipBottomPadding }: MainProps) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const { pathname, search } = useQueryHelper();
  const { isMobileView } = usePageLayout();
  const { dir, isRtl } = useDirLayout();
  const isHomePage = pathname === ROUTES.HOME;

  const menuRequiredWidth = !isMobileView ? menuWidth : 0;

  useEffect(() => {
    if (isMobileView) {
      setMobileMenuOpen(false);
    }
  }, [pathname, search]);

  useEffect(() => {
    if (!isMobileView) {
      setMobileMenuOpen(false);
    }
  }, [isMobileView]);

  return (
    <div dir={dir} css={{ fontSize: '14px' }}>
      {!isMobileView ? (
        <Menu isDesktopView />
      ) : (
        <>
          <MobileToolbar backPath={backPath} toolbarTitle={toolbarTitle} onClickMenu={() => setMobileMenuOpen(true)} />
          <MobileMenu open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)} />
        </>
      )}

      <PageContainer
        isMobileView={isMobileView}
        skipBottomPadding={skipBottomPadding}
        skipPosition={isMobileView && isHomePage}
        css={isRtl ? { marginRight: menuRequiredWidth } : { marginLeft: menuRequiredWidth }}
      >
        <div>
          {children}
          {!hideFooter && !isMobileView && (
            <div css={styles.footerContainer}>
              <Footer />
            </div>
          )}
        </div>
        <IntercomInitializer />
      </PageContainer>
    </div>
  );
};

const PageContainer = styled.div<{ isMobileView: boolean; skipBottomPadding?: boolean; skipPosition: boolean }>`
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  margin-bottom: 56px;
  min-height: 80vh;
  overflow: hidden;
  padding-bottom: ${({ skipBottomPadding }) => (skipBottomPadding ? 0 : '10%')};
  ${({ isMobileView }) => (isMobileView ? 'padding-top: 56px;' : '')}
  ${({ skipPosition }) => (!skipPosition ? 'position: relative;' : '')}

  @media (max-width: ${ViewportType.SMALL}px) {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
`;

const styles = {
  footerContainer: css`
    bottom: 16px;
    display: flex;
    justify-content: center;
    height: 24px;
    position: absolute;
    width: 100%;

    & > div {
      width: 85%;
    }
  `,
};

export default Layout;
