import CastingStepOne from '@src/assets/img/tutorial/casting/step1.png';
import CastingStepTwo from '@src/assets/img/tutorial/casting/step2.png';
import CastingStepThree from '@src/assets/img/tutorial/casting/step3.png';
import MarketplaceSearchJobStepOne from '@src/assets/img/tutorial/marketplace/searchJob/step1.png';
import MarketplaceSearchJobStepTwo from '@src/assets/img/tutorial/marketplace/searchJob/step2.png';
import paymentStepOne from '@src/assets/img/tutorial/payment/step1.png';
import paymentStepTwo from '@src/assets/img/tutorial/payment/step2.png';

export enum TutorialTypes {
  CASTING = 'CASTING',
  MARKETPLACE_SEARCH_JOB = 'MARKETPLACE_SEARCH_JOB',
  PAYMENT = 'PAYMENT',
}

export const tutorials = {
  [TutorialTypes.CASTING]: [
    {
      description: 'Tutorial.Text.CastingStepOne',
      descriptionTitle: 'Tutorial.Heading.CastingStepOne',
      icon: 'clipboard-blue',
      image: CastingStepOne,
      title: 'Tutorial.Title.CastingStepOne',
    },
    {
      description: 'Tutorial.Text.CastingStepTwo',
      descriptionTitle: 'Tutorial.Heading.CastingStepTwo',
      icon: 'clipboard-blue',
      image: CastingStepTwo,
      title: 'Tutorial.Title.CastingStepTwo',
    },
    {
      description: 'Tutorial.Text.CastingStepThree',
      descriptionTitle: 'Tutorial.Heading.CastingStepThree',
      icon: 'clipboard-blue',
      image: CastingStepThree,
      title: 'Tutorial.Title.CastingStepThree',
    },
  ],
  [TutorialTypes.MARKETPLACE_SEARCH_JOB]: [
    {
      description: 'Tutorial.Text.MarketplaceSearchJobStepOne',
      descriptionTitle: 'Tutorial.Heading.MarketplaceSearchJobStepOne',
      icon: 'binocular',
      iconColor: '#26313a',
      image: MarketplaceSearchJobStepOne,
      title: 'Tutorial.Title.MarketplaceSearchJobStepOne',
    },
    {
      description: 'Tutorial.Text.MarketplaceSearchJobStepTwo',
      descriptionTitle: 'Tutorial.Heading.MarketplaceSearchJobStepTwo',
      icon: 'binocular',
      iconColor: '#26313a',
      image: MarketplaceSearchJobStepTwo,
      title: 'Tutorial.Title.MarketplaceSearchJobStepTwo',
    },
  ],
  [TutorialTypes.PAYMENT]: [
    {
      description: 'Tutorial.Text.PaymentStepOne',
      descriptionTitle: 'Tutorial.Heading.PaymentStepOne',
      icon: 'wallet-blue-light',
      image: paymentStepOne,
      title: 'Tutorial.Title.PaymentStepOne',
    },
    {
      description: 'Tutorial.Text.PaymentStepTwo',
      descriptionTitle: 'Tutorial.Heading.PaymentStepTwo',
      icon: 'wallet-blue-light',
      image: paymentStepTwo,
      title: 'Tutorial.Title.PaymentStepTwo',
    },
  ],
};
