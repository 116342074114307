import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { useDeepCompareEffect, useQueryHelper } from '@src/libs/hooks';
import { formState, submitFormState, useSetRecoilState } from '@src/recoilAtoms';
import { ROUTES } from '@src/shared/routes';
import { QuestionType } from '@src/__generated__/globalTypes';
import { QuestionErrorMessages } from './DynamicInput';
import Form, { FormInformation, Refs } from './Form';
import ADD_FORM from './mutations/AddForm.graphql';
import { AddForm, AddFormVariables } from './mutations/__generated__/AddForm';
import UPDATE_FORM from './mutations/UpdateForm.graphql';
import { UpdateForm, UpdateFormVariables } from './mutations/__generated__/UpdateForm';

interface IndexProps extends FormInformation {
  isFormAnswerSubmitted: boolean;
  refs: Refs;
}

const Index = (props: IndexProps) => {
  const { description, hash, id, isFormAnswerSubmitted, questions, refs, status, title, thankDescription, thankTitle } =
    props;
  // form is able to draft edit/preview without saving, using recoil to save the draft information
  const setFormState = useSetRecoilState(formState);
  const setSubmitFormState = useSetRecoilState(submitFormState);
  const { enqueueSnackbar, history, t } = useQueryHelper();

  const validationSchema = yup.object().shape({
    description: yup.string().required('requiredDescriptionMessage'),
    questions: yup.array().of(
      yup.object().shape({
        options: yup.array().when('questionType', {
          is: type => [QuestionType.CHECKBOX, QuestionType.DROPDOWN].includes(type),
          then: yup.array().of(
            yup.object().shape({
              optionTitle: yup.string().required(QuestionErrorMessages.OPTION_TITLE),
            })
          ),
        }),
        questionType: yup.string().required(QuestionErrorMessages.TYPE),
        title: yup.string().when('questionType', {
          is: type => [QuestionType.CHECKBOX, QuestionType.DROPDOWN, QuestionType.SHORT_ANSWER].includes(type),
          then: yup.string().required(QuestionErrorMessages.TITLE),
        }),
      })
    ),
    title: yup.string().required('requiredTitleMessage'),
    thankDescription: yup.string().required('requiredThankDescriptionMessage'),
    thankTitle: yup.string().required('requiredThankTitleMessage'),
  });

  const defaultValues = {
    description,
    questions,
    status,
    title,
    thankDescription,
    thankTitle,
    ...(hash && { hash }),
    ...(id && { id }),
  };

  const methods = useForm<FormInformation>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  useDeepCompareEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues]);

  const [addForm] = useMutation<AddForm, AddFormVariables>(ADD_FORM, {
    onCompleted: () => {
      enqueueSnackbar(t('succeededInCreating'), { variant: 'success' });
      sendAmplitudeEvent(eventTypes.addForm);
      history.push(ROUTES.FORMS);
    },
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
  });

  const [updateForm] = useMutation<UpdateForm, UpdateFormVariables>(UPDATE_FORM, {
    refetchQueries: ['GetForm'],
    onCompleted: () => {
      enqueueSnackbar(t('succeededInUpdating'), { variant: 'success' });
      sendAmplitudeEvent(eventTypes.editForm, { formId: id });
    },
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
  });

  const onSubmit = async (values: FormInformation) => {
    if (values.id) {
      await updateForm({
        variables: {
          input: {
            description: values.description,
            id: values.id,
            questions: values.questions.map(question => ({
              id: question.id,
              image: question.image,
              isRequired: question.isRequired,
              options: question.options.map(option => {
                const { id: optionId, optionTitle, order } = option;

                return {
                  id: optionId,
                  optionTitle,
                  order,
                };
              }),
              order: question.order,
              questionType: question.questionType,
              title: question.title,
            })),
            thankDescription: values.thankDescription,
            thankTitle: values.thankTitle,
            title: values.title,
          },
        },
      });
    } else {
      await addForm({
        variables: {
          input: {
            description: values.description,
            hash: values.hash as string,
            questions: values.questions.map(question => ({
              image: question.image,
              isRequired: question.isRequired,
              options: question.options,
              order: question.order,
              questionType: question.questionType,
              title: question.title,
            })),
            thankDescription: values.thankDescription,
            thankTitle: values.thankTitle,
            title: values.title,
          },
        },
      });
    }
    // form draft information is save using recoil, to reset draft information after the form is submit
    setFormState(null);
    setSubmitFormState(null);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Form isFormAnswerSubmitted={isFormAnswerSubmitted} refs={refs} />
      </form>
    </FormProvider>
  );
};

export default Index;
