import styled from '@emotion/styled';
import { LocationDescriptor } from 'history';
import React, { ReactElement } from 'react';
import * as List from '../../atoms/List';

interface EmptyTableProps {
  className?: string;
  link?: {
    href: LocationDescriptor;
    title: string;
  };
  colSpan?: number;
  children?: ReactElement<any>;
  hasChildren?: boolean;
  hasBorderTop?: boolean;
  title?: string;
  linkDescription?: string;
  noLinkDescription?: string;
}

const EmptyTable = (props: EmptyTableProps) => {
  const {
    link,
    colSpan = 11,
    className,
    hasChildren = false,
    children,
    hasBorderTop = false,
    title,
    linkDescription,
    noLinkDescription,
  } = props;

  return (
    <StyledWrapper hasBorderTop={hasBorderTop} className={className}>
      <table>
        <tbody>
          <tr>
            {!hasChildren && (
              <List.EmptyColumn
                colSpan={colSpan}
                link={link}
                title={title}
                linkDescription={linkDescription}
                noLinkDescription={noLinkDescription}
              />
            )}
            {hasChildren && <ChildrenWrapper colSpan={colSpan}>{children}</ChildrenWrapper>}
          </tr>
        </tbody>
      </table>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.section<{ hasBorderTop: boolean }>`
  margin: 0 23px 0;
  background-color: #fff;
  border: 1px solid #dee5ec;
  ${({ hasBorderTop }) => (!hasBorderTop ? 'border-top: none' : '')};
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
`;

const ChildrenWrapper = styled.td`
  height: 360px;
  text-align: center;

  h3 {
    color: #757575;
    font-size: 20px;
    font-weight: 500;
  }

  p {
    margin-top: 8px;
    color: #757575;
    font-size: 14px;
  }
`;

export default EmptyTable;
