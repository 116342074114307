import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import emptyChat from '@src/assets/icon/chat/emptyChat.svg';
import noLineConnected from '@src/assets/icon/chat/noLineConnected.svg';
import noLineConnected_broadcast from '@src/assets/icon/chat/noLineConnected_broadcast.svg';
import { ROUTES } from '@src/shared/routes';

export const NoFansConnected = () => {
  const { t } = useTranslation();

  return (
    <div css={styles.noChats}>
      <div css={styles.addFanWrapper}>
        <img src={emptyChat} css={styles.emptyChat} />
        <div css={styles.addFan}>
          <span className="plus">+</span>
          <span>{` ${t('Button.Add')}`}</span>
        </div>
      </div>

      <div css={styles.addFanParagraph}>
        <h3 className="heading">{t('Add friends to your Chat Media')}</h3>
        <p className="text">{t('As for now, there are no fans to show')}</p>
        <p className="text">{t('Add friends to your Chat account in order to display fans insight')}</p>
      </div>

      <Link to={ROUTES.FANS_ADD} css={styles.addFanLink}>
        {t('Add Fan')}
      </Link>
    </div>
  );
};
export const NoLineConnected_Broadcast = () => {
  const { t } = useTranslation();

  return (
    <div css={[styles.noChats, { margin: 'auto', width: 500 }]}>
      <div css={styles.addFanWrapper}>
        <img src={noLineConnected_broadcast} css={styles.emptyChat} />
      </div>

      <div css={styles.addFanParagraph}>
        <h3 className="heading">{t('Connect your LINE Official Account')}</h3>
        <p className="text">{t('To send the test broadcast, you need to connect your LINE Official')}</p>
        <p className="text">{t('Account to AnyCreator')}</p>
      </div>

      <Link to={ROUTES.SETTINGS_CHANNEL_CONNECT} css={[styles.addFanLink, { height: 32 }]}>
        {t('Button.Connect LINE')}
      </Link>
    </div>
  );
};

export const NoLineConnected = () => {
  const { t } = useTranslation();

  return (
    <div css={styles.noChats}>
      <div css={styles.addFanWrapper}>
        <img src={noLineConnected} css={styles.emptyChat} />
      </div>

      <div css={styles.addFanParagraph}>
        <h3 className="heading">{t('Add friends to your Chat')}</h3>
        <p className="text">{t('Please add Chat and connect account in order to')}</p>
        <p className="text">{t('see your fan’s insight')}</p>
      </div>

      <Link to={ROUTES.SETTINGS_CHANNEL_CONNECT} css={styles.addFanLink}>
        {t('Button.Add Channel')}
      </Link>
    </div>
  );
};

const styles = {
  addFanWrapper: css`
    position: relative;
    align-self: center;
  `,
  noChats: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
  `,
  emptyChat: css`
    margin: auto;
    width: 180px;
    height: 180px;
  `,
  addFan: css`
    position: absolute;
    padding: 0 9px;
    border-radius: 16px;
    background-color: #3892e5;
    color: #fff;
    font-size: 13px;
    font-weight: bold;
    line-height: 140%;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -16px);

    span {
      vertical-align: text-bottom;

      &.plus {
        font-size: 18px;
      }
    }
  `,
  addFanParagraph: css`
    color: #27313b;
    margin-top: 24px;
    padding: 0 16px;

    .heading {
      font-size: 14px;
      line-height: 120%;
      text-align: center;
      margin-bottom: 16px;
    }

    .text {
      text-align: center;
      font-size: 12px;
      line-height: 120%;
      color: #6e7c89;
    }
  `,
  addFanLink: css`
    width: 165px;
    margin: 24px auto;
    text-align: center;
    border: 1px solid #dee5ec;
    background-color: #fff;
    height: 32px;
    line-height: 32px;
    border-radius: 3px;
    font-weight: 600;
    font-size: 12px;
    color: #6e7c89;

    &:hover {
      border-color: #6e7c89;
    }
  `,
};
