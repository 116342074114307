import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import ChatAvatar from '@src/components/organisms/ChatListSection/ChatAvatar';
import { ChatType } from '@src/components/organisms/ChatListSection/ChatsList/ChatInfoCard';
import { formatNumber } from '@src/libs/format';
import { usePageLayout } from '@src/libs/hooks';

interface HeaderProps {
  avatar: string;
  created: string;
  currencyId: string;
  firstName?: string;
  lastName?: string;
  lastOrder: string;
  lineName: string;
  totalOrders: number;
  totalSpent: number;
}

const Header = ({ avatar, created, currencyId, lastOrder, lineName, totalOrders, totalSpent }: HeaderProps) => {
  const { t } = useTranslation();
  const { isMobileView } = usePageLayout();

  return (
    <div css={styles.container}>
      <LineInfoContainer isMobileView={isMobileView}>
        <div css={styles.avatarContainer}>
          <ChatAvatar avatar={avatar} type={ChatType.Line} />
        </div>
        <div>
          <div>{lineName}</div>
        </div>
      </LineInfoContainer>

      <CreatorInfoContainer isMobileView={isMobileView}>
        <div>
          <div>{t('Customer for')}</div>
          <div>{created ? formatDistanceToNowStrict(new Date(created + 'Z')) : '-'}</div>
        </div>
        <div>
          <div>{t('Last order')}</div>
          <div>{lastOrder ? formatDistanceToNow(new Date(lastOrder + 'Z'), { addSuffix: true }) : '-'}</div>
        </div>
        <div>
          <div>{t('Order')}</div>
          <div>{`${totalOrders} ${t('orders')}`}</div>
        </div>
        <div>
          <div>{t('Total spent')}</div>
          <div>{`${currencyId} ${formatNumber(totalSpent || null)}`}</div>
        </div>
      </CreatorInfoContainer>
    </div>
  );
};

const CreatorInfoContainer = styled.div<{ isMobileView: boolean }>`
  border-radius: 3px;
  display: flex;
  flex-wrap: wrap;
  ${({ isMobileView }) => (!isMobileView ? 'border: 1px solid #eef3f7; padding: 12px;' : '')}

  & > div {
    box-shadow: 0 0 0 0 #eef3f7, 1px 0 0 0 #eef3f7;
    display: grid;
    flex-basis: 24.5%;

    & > div:nth-of-type(1) {
      color: #27313b;
      font-size: 10px;
      text-align: center;
    }

    & > div:nth-of-type(2) {
      color: #27313b;
      font-size: 12px;
      font-weight: 600;
      text-align: center;
    }
  }

  /* stylelint-disable no-descending-specificity */
  & > div:nth-of-type(4) {
    box-shadow: none;
  }
`;

const LineInfoContainer = styled.div<{ isMobileView: boolean }>`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;

  & > div:nth-of-type(1) {
    ${({ isMobileView }) =>
      isMobileView ? 'display: grid; flex-basis: 100%; justify-content: center;' : 'margin-right: 16px;'}
  }

  & > div:nth-of-type(2) {
    display: grid;
    flex: 1;
    height: fit-content;
    ${({ isMobileView }) => (isMobileView ? 'margin-bottom: 16px; text-align: center;' : '')}

    & > div:nth-of-type(1) {
      color: #27313b;
      font-size: 18px;
      font-weight: 600;
    }

    & > div:nth-of-type(2) {
      color: #6e7c89;
      font-size: 12px;
    }
  }
`;

const styles = {
  avatarContainer: css`
    & > div {
      align-items: center;
      display: flex;
      height: 64px;
      justify-content: center;
      width: 64px;

      & > img:nth-of-type(1) {
        height: 53px;
        right: 0;
        width: 53px;
      }

      & > img:nth-of-type(2) {
        height: 24px;
        right: 0;
        top: 0;
        width: 24px;
      }
    }
  `,
  container: css`
    background-color: #fff;
    border-bottom: 1px solid #dee5ec;
    display: grid;
    padding: 16px;
  `,
};

export default Header;
