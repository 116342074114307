import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { css } from '@emotion/react';
import { RecipientType } from '@src/__generated__/globalTypes';
import useFormInput from './useFormInput';
import { ButtonTypeInput, DefaultInput, ImageTypeInput, PlainTextInput, RichMessageInput } from './Inputs';
import { FormInformation, FormType } from './Inputs/helpers';

export type { FormInformation };
export { FormType };

interface FormInputProps {
  isDisabled?: boolean;
  onSubmit: (information: FormInformation) => Promise<void>;
}

const FormInput = ({ isDisabled, onSubmit }: FormInputProps) => {
  const [totalRecipients, setTotalRecipients] = useState<number>(0);
  const { tags, getEstimateRecipients } = useFormInput();
  const { watch } = useFormContext<FormInformation>();
  const [formType, recipient] = watch(['formType', 'recipient']);

  useEffect(() => {
    if (recipient.type === RecipientType.WITH_CUSTOMER_TAGS && !recipient.tagNames.length) {
      setTotalRecipients(0);
    } else {
      getEstimateRecipients({
        variables: {
          input: {
            recipientType: recipient.type,
            tagNames: recipient.type === RecipientType.WITH_CUSTOMER_TAGS ? recipient.tagNames : [],
          },
        },
      }).then(({ data }) => {
        setTotalRecipients(data?.getLineBroadcastEstimateRecipients?.total || 0);
      });
    }
  }, [recipient.tagNames, recipient.type]);

  return (
    <div css={styles.container}>
      {[FormType.BUTTON_TYPE, FormType.BUTTON_TYPE_CONTENT].includes(formType) ? (
        <ButtonTypeInput isDisabled={isDisabled} />
      ) : formType === FormType.PLAIN_TEXT ? (
        <PlainTextInput isDisabled={isDisabled} />
      ) : formType === FormType.RICH_MESSAGE ? (
        <RichMessageInput isDisabled={isDisabled} />
      ) : [FormType.IMAGE_TYPE, FormType.IMAGE_TYPE_CONTENT].includes(formType) ? (
        <ImageTypeInput isDisabled={isDisabled} />
      ) : (
        <DefaultInput isDisabled={isDisabled} tags={tags} totalRecipients={totalRecipients} onSubmit={onSubmit} />
      )}
    </div>
  );
};

const styles = {
  container: css`
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 2px 1px rgba(110, 124, 137, 0.05), 0 0 2px rgba(110, 124, 137, 0.25);
    height: 832px;
    min-width: 480px;
    overflow-y: auto;
    width: 480px;
  `,
};

export default FormInput;
