import { useQuery } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import ENGAGEMENT_CAMPAIGNS from './queries/AllEngagementCampaignsForYourJob.graphql';
import {
  AllEngagementCampaignsForYourJob,
  AllEngagementCampaignsForYourJobVariables,
} from './queries/__generated__/AllEngagementCampaignsForYourJob';

interface UseEngagementCampaignsProps {
  isFinished: boolean;
}

const useEngagementCampaigns = ({ isFinished }: UseEngagementCampaignsProps) => {
  const { enqueueSnackbar, t } = useQueryHelper();
  const { data, loading, refetch } = useQuery<
    AllEngagementCampaignsForYourJob,
    AllEngagementCampaignsForYourJobVariables
  >(ENGAGEMENT_CAMPAIGNS, {
    variables: {
      isFinished,
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const campaigns = data?.allEngagementCampaignsForYourJob || [];
  const finishedCampaignCount = data?.allEngagementCampaignsForYourJobCounts?.finishedCampaignCount || 0;
  const progressCampaignCount = data?.allEngagementCampaignsForYourJobCounts?.progressCampaignCount || 0;

  return {
    campaigns,
    finishedCampaignCount,
    loading,
    progressCampaignCount,
    refetch,
  };
};

export default useEngagementCampaigns;
