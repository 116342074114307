import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { css } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import Icomoon from '@src/components/atoms/Icomoon';
import Dialog from '@src/components/molecules/Dialog';
import OnlyAvailableOnWebIndicator from '@src/components/molecules/OnlyAvailableOnWebIndicator';
import { ErrorTypes } from '@src/libs/error';
import { useDeepCompareEffect, usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { generatePath, ROUTES } from '@src/shared/routes';
import { LineMessageType, RecipientType } from '@src/__generated__/globalTypes';
import Form, { FormInformation, FormType } from './Form';
import CREATE_BROADCAST_MSG from './mutations/CreateLineBroadcastMessage.graphql';
import SEND_BROADCAST_MSG from './mutations/SendLineBroadcastMessage.graphql';
import UPDATE_BROADCAST_MSG from './mutations/UpdateLineBroadcastMessage.graphql';
import {
  CreateLineBroadcastMessage,
  CreateLineBroadcastMessageVariables,
} from './mutations/__generated__/CreateLineBroadcastMessage';
import {
  SendLineBroadcastMessage,
  SendLineBroadcastMessageVariables,
} from './mutations/__generated__/SendLineBroadcastMessage';
import {
  UpdateLineBroadcastMessage,
  UpdateLineBroadcastMessageVariables,
} from './mutations/__generated__/UpdateLineBroadcastMessage';

interface IndexProps {
  isDisabled?: boolean;
  isEdit?: boolean;
  lineBroadcastMessage: Omit<FormInformation, 'isSend' | 'isTest' | 'formType' | 'selectedBlockIndex'>;
}

const Index = ({ isDisabled, isEdit, lineBroadcastMessage }: IndexProps) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const { enqueueSnackbar, i18n, history, t } = useQueryHelper();
  const { isMobileView } = usePageLayout();
  const isJPLanguage = i18n.language === 'ja';

  const defaultValues = {
    ...lineBroadcastMessage,
    formType: FormType.DEFAULT,
    isSend: false,
    isTest: false,
    selectedBlockIndex: 0,
  };
  const validationSchema = yup.object().shape({
    lineContent: yup.array().of(
      yup.object().shape({
        buttonMessage: yup.object().when('type', {
          is: type => type === LineMessageType.BUTTON,
          then: yup.object().shape({
            cards: yup.array().of(
              yup.object().shape({
                actionLabels: yup.array().of(
                  yup.object().shape({
                    label: yup.string().required(),
                    url: yup.string().required(),
                  })
                ),
              })
            ),
          }),
        }),
        imageMessage: yup.object().when('type', {
          is: type => type === LineMessageType.IMAGES,
          then: yup.object().shape({
            images: yup.array().of(
              yup.object().shape({
                imageUrl: yup.string().required(),
                url: yup.string().required(),
              })
            ),
          }),
        }),
        plainTextMessage: yup.object().when('type', {
          is: type => type === LineMessageType.PLAIN_TEXT,
          then: yup.object().shape({
            text: yup.string().required(),
          }),
        }),
        richMessage: yup.object().when('type', {
          is: type => type === LineMessageType.RICH,
          then: yup.object().shape({
            imageUrl: yup.string().required(),
          }),
        }),
      })
    ),
    recipient: yup.object().shape({
      tagNames: yup.array().when('type', {
        is: type => type === RecipientType.WITH_CUSTOMER_TAGS,
        then: yup.array().required(),
      }),
    }),
    title: yup.string().required('requiredTitleMessage'),
  });

  const methods = useForm<FormInformation>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  useDeepCompareEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues]);

  const [createBroadcastMessage] = useMutation<CreateLineBroadcastMessage, CreateLineBroadcastMessageVariables>(
    CREATE_BROADCAST_MSG
  );

  const [sendBroadcastMessage] = useMutation<SendLineBroadcastMessage, SendLineBroadcastMessageVariables>(
    SEND_BROADCAST_MSG,
    {
      refetchQueries: ['GetLineBroadcastMessage'],
    }
  );

  const [updateBroadcastMessage] = useMutation<UpdateLineBroadcastMessage, UpdateLineBroadcastMessageVariables>(
    UPDATE_BROADCAST_MSG,
    {
      refetchQueries: ['GetLineBroadcastMessage'],
    }
  );

  const onSubmit = async (values: FormInformation) => {
    const { deliveryTiming, id, isSend, isTest, lineContent, recipient, title } = values;

    let scheduleDate = '';
    if (deliveryTiming.scheduleDate) {
      const [dateValues, timeValues] = deliveryTiming.scheduleDate.split(' ');
      const [day, month, year] = dateValues.split('/');
      const [hours, minutes] = timeValues.split(':');

      scheduleDate = new Date(+year, +month - 1, +day, +hours, +minutes).toISOString();
    }

    const createUpdatebroadcastMessage = values.id ? updateBroadcastMessage : createBroadcastMessage;

    const input = {
      deliveryTiming: {
        type: deliveryTiming.type,
        ...(deliveryTiming.scheduleDate ? { scheduleDate } : { scheduleDate: null }),
      },
      lineContent: lineContent.map(content => {
        const { buttonMessage, imageMessage, plainTextMessage, richMessage, type } = content;

        return {
          type,
          ...(buttonMessage
            ? {
                buttonMessage: {
                  cards: buttonMessage.cards.map(card => {
                    const { actionLabels, description, imageUrl, title: cardTitle } = card;

                    return {
                      actionLabels: actionLabels.map(actionLabel => {
                        const { label, url } = actionLabel;

                        return {
                          label,
                          url,
                        };
                      }),
                      ...(description ? { description } : {}),
                      ...(imageUrl ? { imageUrl } : {}),
                      ...(title ? { title: cardTitle } : {}),
                    };
                  }),
                },
              }
            : {}),
          ...(imageMessage
            ? {
                imageMessage: {
                  images: imageMessage.images.map(image => {
                    const { imageUrl, label, url } = image;

                    return {
                      imageUrl,
                      url,
                      ...(label ? { label } : {}),
                    };
                  }),
                },
              }
            : {}),
          ...(plainTextMessage ? { plainTextMessage } : {}),
          ...(richMessage ? { richMessage } : {}),
        };
      }),
      recipient,
      title,
      ...(id ? { lineBroadcastMessageId: id } : {}),
      // set any type because of dynamic call function
    } as any;

    try {
      const { data } = await createUpdatebroadcastMessage({
        variables: {
          input,
        },
      });
      const lineBroadcastMessageId = (data as CreateLineBroadcastMessage).createLineBroadcastMessage?.messageId || id;
      if (isSend && lineBroadcastMessageId) {
        const { data: dataSend } = await sendBroadcastMessage({
          variables: {
            input: {
              isTest,
              lineBroadcastMessageId,
            },
          },
        });

        if (dataSend?.sendLineBroadcastMessage?.ok) {
          enqueueSnackbar(t('succeededInSending'), { variant: 'success' });
          sendAmplitudeEvent(eventTypes.sendBroadcast);
          history.push(generatePath(ROUTES.BROADCAST_EDIT, { id: lineBroadcastMessageId }));
        }
      } else if (!isEdit && lineBroadcastMessageId) {
        enqueueSnackbar(t('succeededInCreating'), { variant: 'success' });
        sendAmplitudeEvent(eventTypes.addBroadcast, { lineBroadcastMessageId });
        history.push(generatePath(ROUTES.BROADCAST_EDIT, { id: lineBroadcastMessageId }));
      } else {
        enqueueSnackbar(t('succeededInUpdating'), { variant: 'success' });
        sendAmplitudeEvent(eventTypes.editBroadcast, { lineBroadcastMessageId });
      }
    } catch (error) {
      if (error.message === ErrorTypes.QUOTA_EXCEED_LIMIT_FOR_BROADCAST) {
        setDialogOpen(true);
      } else {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div css={styles.container}>
          <Dialog
            execColor="red"
            execText="Close"
            hideCancel
            visible={dialogOpen}
            onClose={() => setDialogOpen(false)}
            onExec={() => setDialogOpen(false)}
          >
            <div css={styles.dialog}>
              <div>
                <Icomoon color="#ff5f5f" icon="warning" size={16} />
                <div>{t('Dialog.Your Broadcast could not be sent')}</div>
              </div>
              <div>
                {t(
                  'Annotation.The number of messages you can send through AnyChat this month has been reached the limit'
                )}
              </div>
              <div>{t('Annotation.Please upgrade the plan of your LINE official account')}</div>
              <Link
                rel="noopener noreferrer"
                target="_blank"
                to={{
                  pathname: isJPLanguage
                    ? 'https://www.linebiz.com/jp/manual/OfficialAccountManager/account-settings_plan/'
                    : 'https://help2.line.me/official_account/web/?contentId=20013997',
                }}
              >
                {t('Annotation.How to upgrade the plan')}
              </Link>
            </div>
          </Dialog>
          {isMobileView ? (
            <OnlyAvailableOnWebIndicator to={ROUTES.BROADCAST} />
          ) : (
            <Form isDisabled={isDisabled} isEdit={isEdit} onSubmit={onSubmit} />
          )}
        </div>
      </form>
    </FormProvider>
  );
};

const styles = {
  container: css`
    padding: 16px 24px;
  `,
  dialog: css`
    & > div:nth-of-type(1) {
      align-items: center;
      color: #27313b;
      display: flex;
      font-size: 18px;
      font-weight: 600;
      gap: 8px;
      margin-bottom: 24px;
    }

    & > div:nth-of-type(2) {
      color: #27313b;
      font-size: 14px;
      margin-bottom: 16px;
    }

    & > div:nth-of-type(3) {
      color: #27313b;
      font-size: 14px;
      margin-bottom: 4px;
    }

    & > a {
      color: #384dff;
      font-size: 14px;
      text-decoration: underline;
    }
  `,
};

export default Index;
