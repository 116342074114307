import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import css from '@emotion/css';
import Label from '../../../atoms/Label';
import { Option } from '../../SingleSelectField';
import { MenuControlIcon } from '../../../atoms/MenuControlIcon/MenuControlIcon';

interface SelectProps {
  label?: string;
  options: Option[];
  fieldValue: string;
  fieldName: string;
  isDisabled?: boolean;
  onChange: (field: any, value: string) => void;
  placeholder?: string;
  className?: string;
}
export const NativeSelect = (props: SelectProps) => {
  const { t } = useTranslation();
  const { onChange, fieldName, fieldValue, options, label, isDisabled, placeholder, className } = props;
  const selectOptions =
    placeholder && !isDisabled ? [{ label: t(`Selector.${placeholder}`), value: '' }, ...options] : options;

  return (
    <div className={className}>
      {!!label && <StyledLabel title={label} isRequired />}
      <Wrapper>
        <Select
          name="select"
          id="132"
          fieldValue={fieldValue}
          defaultValue={fieldValue}
          onChange={e => {
            onChange(fieldName, e.target.value);
          }}
          disabled={!!isDisabled}
        >
          {selectOptions.map(opt => (
            <option key={opt.value} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </Select>
        {/* pointer-events: none let click event propagate through custom icon and open Select */}
        <MenuControlIcon css={pointerEventsNone} />
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  position: relative;
  min-height: 38px;
`;

const Select = styled.select<{ fieldValue: string }>`
  width: 100%;
  height: 38px;
  padding-left: 12px;
  border: 1px solid #dee5ec;
  border-radius: 24px;
  box-sizing: border-box;
  color: ${({ fieldValue }) => (fieldValue === '' ? '#d6d6d6' : 'inherit')};
  letter-spacing: 0.02em;
  font-size: 14px;
`;
const StyledLabel = styled(Label)`
  color: #27313b;
`;

const pointerEventsNone = css`
  pointer-events: none;
`;
