import { useQueryHelper } from '@src/libs/hooks';
import React from 'react';
import { format } from 'date-fns';
import { css } from '@emotion/core';
import { useQuery } from '@apollo/client';
import trashIcon from '@src/assets/icon/mylink/trash.svg';
import { ListIndicator } from '@src/components/molecules/Indicator';
import Empty from '@src/components/atoms/Empty';
import Dialog from '@src/components/molecules/Dialog';
import GET_INDIVIDUAL_FORM_RESPONSE from './GetIndividualFormResponse.graphql';
import {
  GetIndividualFormResponse,
  GetIndividualFormResponseVariables,
} from './__generated__/GetIndividualFormResponse';
import FormIndividualNavigation from './FormIndividualNavigation';
import useFormResponse from './useFormResponse';
import FormPageResponse from './FormPageResponse';

const SUBMITTED_TIME_FORMATTER = 'M/d/y, h:mm aaa';

interface FormIndividualProps {
  totalResponse: number;
}

const FormIndividual = ({ totalResponse }: FormIndividualProps) => {
  const { t, params, enqueueSnackbar } = useQueryHelper();
  const { id, page = '1' } = params as { id: string; page: string };
  const { selectedSubmission, closeDialog, onClickDelete, handleDelete } = useFormResponse();

  const { data, loading, error } = useQuery<GetIndividualFormResponse, GetIndividualFormResponseVariables>(
    GET_INDIVIDUAL_FORM_RESPONSE,
    {
      variables: {
        id,
        page: Number(page),
      },
      onError: err => {
        enqueueSnackbar(t(err.message), { variant: 'error' });
      },
      fetchPolicy: 'no-cache',
    }
  );

  if (loading) {
    return <ListIndicator />;
  }

  if (!data?.getForm || error) {
    return <Empty />;
  }

  return (
    <>
      <div css={{ position: 'relative' }}>
        <div css={styles.topPanel}>
          <FormIndividualNavigation total={totalResponse} />
          <div css={styles.iconWrapper} onClick={() => onClickDelete(data.getFormResponseByPage?.submissionId)}>
            <img src={trashIcon} />
          </div>
        </div>
        <div css={styles.row}>
          <p css={styles.annotation}>{t('Responses cannot be edited')}</p>
          <p css={styles.formTitle}>{data?.getForm?.title}</p>
        </div>
        {data.getFormResponseByPage?.answers.length ? (
          <FormPageResponse answers={data.getFormResponseByPage.answers} formData={data.getForm} />
        ) : (
          <Empty />
        )}
        <p css={styles.created}>
          {`${t('Submitted')} ${format(
            new Date((data.getFormResponseByPage?.created as string) + 'Z'),
            SUBMITTED_TIME_FORMATTER
          )}`}
        </p>
      </div>
      <Dialog visible={!!selectedSubmission} onClose={closeDialog} onExec={handleDelete} execText="Delete">
        <p css={styles.heading}>{t('Are you sure you want to permanently delete this?')}</p>
        <p css={styles.bodyText}>
          {t('You might lost response data forever. Before continuing, export your responses')}
        </p>
        <p css={styles.warning}>{t('Do you want to delete this question and lost responses?')}</p>
      </Dialog>
    </>
  );
};

const styles = {
  iconWrapper: css`
    border: 1px solid #dee5ec;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  `,
  topPanel: css`
    padding: 0 16px 24px;
    border-bottom: 1px solid #dee5ec;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  row: css`
    padding: 24px 16px;

    &:not(:last-of-type) {
      border-bottom: 1px solid #dee5ec;
    }
  `,
  annotation: css`
    text-align: center;
    font-weight: 600;
    font-size: 10px;
    line-height: 100%;
    color: #c5d0da;
    margin-bottom: 16px;
  `,
  formTitle: css`
    font-weight: 600;
    font-size: 16px;
    color: #27313b;
  `,
  created: css`
    text-align: right;
    position: absolute;
    font-size: 12px;
    color: #6e7c89;
    bottom: -56px;
    width: 100%;
    right: -24px;
  `,
  heading: css`
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 18px;
    color: #27313b;
  `,
  bodyText: css`
    font-size: 14px;
    color: #27313b;
    margin-bottom: 16px;
  `,
  warning: css`
    font-size: 14px;
    color: #ff5f5f;
  `,
};

export default FormIndividual;
