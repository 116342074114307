import React from 'react';
import { css } from '@emotion/react';
import { selectedChatState, useRecoilValue } from '@src/recoilAtoms';
import ChatActionBar from './ChatActionBar';
import ChatWindow from './ChatWindow';
import { NoFansConnected } from './EmptyChats';

const ChatView = ({ hasMobileLayout }: { hasMobileLayout: boolean }) => {
  const recoilState = useRecoilValue(selectedChatState);

  return (
    <div css={styles.activeChatWrapper}>
      {recoilState.hasNoChats === true ? (
        <NoFansConnected />
      ) : (
        <>
          {!hasMobileLayout ? <ChatActionBar {...recoilState} /> : null}
          <ChatWindow {...recoilState} hasMobileLayout={hasMobileLayout} />
        </>
      )}
    </div>
  );
};

const styles = {
  activeChatWrapper: css`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
};

export default ChatView;
