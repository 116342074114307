import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Redirect } from 'react-router';
import { useIntercom } from 'react-use-intercom';
import { useGlobalLayoutProps, useAuthData, useQueryHelper } from '@src/libs/hooks';
import { ENABLED_INTERCOM_COUNTRIES } from '@src/libs/constant';
import { useMediaQuery } from 'react-responsive';
import { ViewportType } from '@src/libs/theme';
import { ROUTES } from '@src/shared/routes';
import {
  InfluencerDetailForIntercom,
  InfluencerDetailForIntercomVariables,
} from './__generated__/InfluencerDetailForIntercom';
import * as INFLUENCER_DETAIL_FOR_INTERCOM from './InfluencerDetailForIntercom.graphql';

const ALLOWED_INTERCOM_PAGES = ['/payment', '/settings'];

interface InfluencerInfo {
  email: string;
  name: string;
  userId: string;
  country: {
    id: string;
    name: string;
  };
}

const initInfluencerInfo = {
  email: '',
  name: '',
  userId: '',
  country: {
    id: '',
    name: '',
  },
};
const IntercomInitializer = () => {
  const { userId } = useAuthData();

  if (!userId) {
    return <Redirect to={ROUTES.ROOT} />;
  }

  const {
    globalLayout: { isMenuOpen, isDialogOpen, isUuum },
    updateGlobalLayout,
  } = useGlobalLayoutProps();
  const { boot, update } = useIntercom();
  const {
    pathname,
    i18n: { language },
  } = useQueryHelper();
  const isDesktopView = useMediaQuery({ minWidth: ViewportType.TABLET });

  const [influencerInfo, setInfluencerInfo] = useState<InfluencerInfo>(initInfluencerInfo);
  const isEnabledCountry = ENABLED_INTERCOM_COUNTRIES.includes(influencerInfo.country.id);
  const hasIntercomWidget =
    !!influencerInfo.userId &&
    !isDialogOpen &&
    !isMenuOpen &&
    !isUuum &&
    isEnabledCountry &&
    (ALLOWED_INTERCOM_PAGES.includes(pathname) || isDesktopView);

  React.useEffect(() => {
    if (influencerInfo.userId) {
      update({
        hideDefaultLauncher: !hasIntercomWidget,
      });
    }
  }, [pathname, isDesktopView, isMenuOpen, isDialogOpen, isUuum]);

  useEffect(() => {
    updateGlobalLayout({ hasContactForm: influencerInfo.country.id ? !isEnabledCountry : undefined });
  }, [influencerInfo.country.id]);

  useQuery<InfluencerDetailForIntercom, InfluencerDetailForIntercomVariables>(INFLUENCER_DETAIL_FOR_INTERCOM, {
    variables: { pk: userId },
    onCompleted: useCallback((data: InfluencerDetailForIntercom) => {
      const userInfo = {
        userId: data?.influencerDetail?.id.toString() || '',
        email: data?.influencerDetail?.email || '',
        name: data?.influencerDetail?.name || '',
        country: {
          id: data?.influencerDetail?.country?.id || '',
          name: data?.influencerDetail?.country?.name || '',
        },
      };
      setInfluencerInfo(userInfo);
      const isFromAllowedCountries = data.influencerDetail?.country.id
        ? ENABLED_INTERCOM_COUNTRIES.includes(data.influencerDetail.country.id)
        : false;
      const isOnAllowedPage = ALLOWED_INTERCOM_PAGES.includes(pathname) || isDesktopView;

      boot({
        ...userInfo,
        languageOverride: language || 'en',
        hideDefaultLauncher: !isFromAllowedCountries || !isOnAllowedPage || isUuum,
      });
    }, []),
  });

  return <></>;
};

export default IntercomInitializer;
