import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SerializedStyles } from '@emotion/core';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { getEngagementPostStatusTextColor } from '@src/libs/status/color';
import { getEngagementPostStatusHistoryMessage } from '@src/libs/status/message';
import { EngagementCampaignPostStatus } from '@src/__generated__/globalTypes';
import { CampaignPostDetailsHistory_engagementDraftPostHistoryForInfluencer_historyRecords } from './queries/__generated__/CampaignPostDetailsHistory';

interface PostHistoryProps {
  postHistory: CampaignPostDetailsHistory_engagementDraftPostHistoryForInfluencer_historyRecords[];
}

const PostHistory = ({ postHistory }: PostHistoryProps) => {
  const { t } = useTranslation();

  return (
    <div css={styles.container}>
      <div css={styles.title}>{t('History')}</div>
      {postHistory.length > 0 ? (
        postHistory.map(history => {
          const showMessageTitle = history.postStatus === EngagementCampaignPostStatus.READY_TO_POST || !history.reason;

          return (
            <div css={styles.historyContainer} key={history.id}>
              <div css={styles.dateTitle}>{format(new Date(history.createdDate), 'MMM dd, yyyy')}</div>
              {showMessageTitle ? (
                <HistoryTitle textColor={getEngagementPostStatusTextColor(history.postStatus)}>
                  {t(getEngagementPostStatusHistoryMessage(history.postStatus))}
                </HistoryTitle>
              ) : (
                <div css={styles.reasonContainer}>
                  <HistoryTitle>{history.reason}</HistoryTitle>
                  <div>{history.detailedReason}</div>
                </div>
              )}
            </div>
          );
        })
      ) : (
        <div css={styles.noHistory}>{t('No History')}</div>
      )}
    </div>
  );
};

const HistoryTitle = styled.div<{ textColor?: SerializedStyles }>`
  ${({ textColor }) => (textColor ? textColor : 'color: #27313b')};
  font-size: 13px;
  font-weight: 600;
`;

const styles = {
  container: css`
    background-color: #fff;
    padding: 8px 24px;

    & > div {
      border-bottom: 1px solid #dee5ec;
    }

    & > div:last-child {
      border: none;
    }
  `,
  dateTitle: css`
    color: #27313b;
    font-size: 13px;
  `,
  historyContainer: css`
    display: flex;
    flex-wrap: wrap;
    padding: 16px 0;

    & > div:nth-of-type(1) {
      display: flex;
      flex-basis: 20%;
    }

    & > div:nth-of-type(2) {
      display: flex;
      flex-basis: 80%;
    }
  `,
  noHistory: css`
    align-items: center;
    display: flex;
    justify-content: center;
    height: 200px;
    width: 100%;
  `,
  reasonContainer: css`
    display: flex;
    flex-wrap: wrap;

    /* stylelint-disable no-descending-specificity */
    & > div {
      display: flex;
      flex-basis: 100%;
    }

    & > div:nth-of-type(2) {
      color: #27313b;
      font-size: 12px;
      margin-top: 8px;
    }
  `,
  title: css`
    border-bottom: 1px solid #dee5ec;
    color: #27313b;
    font-size: 13px;
    font-weight: 600;
    padding: 16px 0;
  `,
};

export default PostHistory;
