import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentRequestStatusForInfluencer } from '../../../__generated__/globalTypes';
import { getPaymentStatusColor, getPaymentStatusMessage } from '../../../libs/status';
import { StatusColumn } from '../../atoms/List';

interface StatusColumnProps {
  className?: string;
  status: PaymentRequestStatusForInfluencer | null;
}

const PaymentStatusColumn = (props: StatusColumnProps) => {
  const { t } = useTranslation();
  const { className, status } = props;
  const color = getPaymentStatusColor(status);
  const message = getPaymentStatusMessage(status);

  return (
    <StatusColumn className={className} statusColor={color}>
      {t(`Payment.${message}`)}
    </StatusColumn>
  );
};

export default PaymentStatusColumn;
