import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { css } from '@emotion/react';
import { ViewportType } from '@src/libs/theme';
import PostForm from '@src/components/organisms/CastingMarketplace/YourJob/PostForm';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import { useQueryHelper } from '@src/libs/hooks';

const EditPost = () => {
  const { params } = useQueryHelper();
  const isDesktopView = useMediaQuery({ query: `(min-width: ${ViewportType.TABLET}px)` });
  const engagementId = Number(params.id);
  const postId = Number(params.postId);

  return (
    <div css={styles.container}>
      {isDesktopView && <BackNavigator title="Edit Post" to={`/casting/your_job/${engagementId}/post`} />}

      <div css={styles.formContainer}>
        <PostForm engagementId={engagementId} postId={postId} />
      </div>
    </div>
  );
};

const styles = {
  container: css`
    padding: 24px;
  `,
  formContainer: css`
    margin-top: 16px;
  `,
};

export default EditPost;
