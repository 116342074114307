import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

const MAX_NUMBER_OF_TAGS_TO_SHOW = 3;

interface TagColumnProps {
  className?: string;
  tags: string[];
}

const TagColumn = (props: TagColumnProps) => {
  const { t } = useTranslation();
  const { className, tags } = props;
  const nonEmptyTags = tags.filter(item => item);

  return (
    <TableElement className={className}>
      <Wrapper>
        <TableData>
          {nonEmptyTags.slice(0, MAX_NUMBER_OF_TAGS_TO_SHOW).map((item, index) => (
            <Tag key={`${item}${index}`}>{item}</Tag>
          ))}
          {nonEmptyTags.length > MAX_NUMBER_OF_TAGS_TO_SHOW && (
            <Tag css={{ backgroundColor: 'transparent' }}>
              +{t('Tags Count', { count: nonEmptyTags.length - MAX_NUMBER_OF_TAGS_TO_SHOW })}
            </Tag>
          )}
        </TableData>
      </Wrapper>
    </TableElement>
  );
};

const TableElement = styled.td`
  padding: 0 8px;
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
const TableData = styled.div`
  display: inline-block;
  white-space: nowrap;
  font-size: 13px;
  line-height: 16px;
`;
const Tag = styled.span`
  background-color: #eef3f7;
  padding: 4px;
  color: #27313b;
  font-size: 12px;
  border-radius: 3px;

  &:not(:last-of-type) {
    margin-right: 4px;
  }
`;

export default TagColumn;
