import React from 'react';
import { useFormContext } from 'react-hook-form';
import TextForm from '@src/components/molecules/TextForm';
import { ThemeButton } from '@src/components/atoms/Button';
import { css } from '@emotion/core';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

export interface FormValues {
  comment: string;
}

const CommentForm = () => {
  const { t } = useTranslation();
  const {
    formState: { errors, isSubmitting },
    register,
  } = useFormContext<FormValues>();

  return (
    <div css={formStyle}>
      <CommentWrapper>
        <TextForm css={textformStyle} error={!!errors.comment} placeholder={t('Comment')} {...register('comment')} />
        <ThemeButton theme="blue" disabled={isSubmitting} text="Post" css={submitBtnStyle} type="submit" />
      </CommentWrapper>

      <ErrorMessage message={errors.comment?.message} />
    </div>
  );
};

const formStyle = css`
  display: flex;
  border: 1px solid #dee5ec;
  padding: 8px;
  border-radius: 5px;
  flex-direction: column;
  margin-bottom: 32px;
`;
const textformStyle = css`
  flex: 1;

  /* stylelint-disable */
  & input {
    border-radius: 3px;
    height: 32px;
  }
  /* stylelint-enable */
`;
const submitBtnStyle = css`
  width: fit-content;

  .btn-text {
    padding: 0 16px;
  }
`;
const CommentWrapper = styled.div`
  display: flex;
  flex: 1;
  gap: 8px;
`;

export default CommentForm;
