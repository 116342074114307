import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import ProgressBar from '@src/components/atoms/ProgressBar';
import { formatNumber } from '@src/libs/format';

interface CampaignBudgetProgressBarProps {
  remainingAmount: number | null;
  totalAmount: number | null;
}

const CampaignBudgetProgressBar = ({ remainingAmount, totalAmount }: CampaignBudgetProgressBarProps) => {
  const { t } = useTranslation();
  const amountWidth = remainingAmount && totalAmount ? (100 * (totalAmount - remainingAmount)) / totalAmount : 100;

  return (
    <div>
      <div css={styles.headerContainer}>
        <div>{t('Label.Budget')}</div>
        <div>
          {amountWidth < 100 && <span css={styles.recruitingText}>{t('Recruiting')}</span>}
          <span>{`${formatNumber(amountWidth, 0)}%`}</span>
        </div>
      </div>
      <ProgressBar completed={amountWidth} completedColor={remainingAmount && totalAmount ? '#3892e5' : '#ff5f5f'} />
    </div>
  );
};

const styles = {
  headerContainer: css`
    display: flex;
    flex-wrap: wrap;

    & > div:nth-of-type(1) {
      color: #6e7c89;
      display: flex;
      flex-basis: 50%;
      font-size: 11px;
      font-weight: 600;
    }

    & > div:nth-of-type(2) {
      color: #27313b;
      display: flex;
      flex-basis: 50%;
      font-size: 11px;
      font-weight: 600;
      justify-content: flex-end;
      margin-bottom: 8px;
    }
  `,
  recruitingText: css`
    color: #3892e5;
    margin-right: 8px;
  `,
};

export default CampaignBudgetProgressBar;
