import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import InitialLoading from '@src/components/molecules/InitialLoading';
import { getCallbackUrl } from '@src/libs/auth';
import { useQueryHelper } from '@src/libs/hooks';
import { generatePath, ROUTES } from '@src/shared/routes';
import { SocialAccountType, AuthSocialAccountType } from '@src/__generated__/globalTypes';
import { ErrorTypes, UNEXPECTED_ERROR } from '@src/libs/error';
import { getSocialAccountType } from '../utils/utils';
import useCampaign from './useCampaign';
import { SocialAuthForJoinCampaignVariables } from './__generated__/SocialAuthForJoinCampaign';

interface UrlParams {
  provider: string;
}
const JoinCampaignRedirectComponent = () => {
  const { joinMarketplace, socialAuthJoinCampaign } = useCampaign();

  const { t, enqueueSnackbar, history, search } = useQueryHelper();
  const { provider: providerStr } = useParams<UrlParams>();
  const campaignId = localStorage.getItem('joinCampaignId');
  const socialAccountId = localStorage.getItem('joinAccountId');
  const socialMedia = localStorage.getItem('joinAccountSocialMedia') as SocialAccountType;
  const fbAccountId = localStorage.getItem('fbAccountId');
  const skipAuthVerification = localStorage.getItem('skipAuthVerification');
  const response = search.substr(1);
  let failToJoinRedirectUrl = campaignId
    ? generatePath(ROUTES.MARKETPLACE_SEARCH_JOB_ID, { id: campaignId })
    : ROUTES.MARKETPLACE_SEARCH_JOB;

  useEffect(() => {
    connectAndJoinCampaign();

    return () => {
      localStorage.removeItem('joinCampaignId');
      localStorage.removeItem('joinAccountId');
      localStorage.removeItem('joinAccountSocialMedia');
      localStorage.removeItem('fbAccountId');
      localStorage.removeItem('skipAuthVerification');
    };
  }, []);

  const connectAndJoinCampaign = async () => {
    const provider = getSocialAccountType(providerStr) as AuthSocialAccountType | null;
    if (!provider) {
      enqueueSnackbar(t(UNEXPECTED_ERROR), { variant: 'error' });
      history.push(failToJoinRedirectUrl);

      return;
    }

    if (!skipAuthVerification) {
      // in order to understand is account which user choose for join campaign is same as one he is using for login ot our platform
      // BE will throw an error if they are not match and we need to stop join campaign flow too
      const selectedSocialAccountId = [
        SocialAccountType.FACEBOOK,
        SocialAccountType.FACEBOOK_PAGE,
        SocialAccountType.INSTAGRAM,
      ].includes(socialMedia)
        ? fbAccountId
        : socialAccountId;

      const signInVariables: SocialAuthForJoinCampaignVariables = {
        input: {
          provider,
          response,
          callbackUrl: getCallbackUrl(provider, FE_REDIRECT_MAPPING.JOIN_CAMPAIGN),
          selectedSocialAccountId: selectedSocialAccountId || null,
        },
      };

      try {
        const { data } = await socialAuthJoinCampaign({ variables: signInVariables });
        if (data?.socialAuthForJoinCampaign?.ok) {
          enqueueSnackbar('Success to Connect', { variant: 'success' });
        }
      } catch (error) {
        console.error(error);
        if (error.message === ErrorTypes.FB_REQUIRED_PERMISSION_MISSING) {
          history.push(ROUTES.SETTINGS_SOCIAL_CONNECT_FACEBOOK_GRANT_PERMISSION);

          return;
        }

        enqueueSnackbar(t(error.message), { variant: 'error' });

        // redirect back to campaignPage
        history.push(failToJoinRedirectUrl);

        return;
      }
    }

    const joinVariables = {
      input: {
        campaignId: Number(campaignId),
        socialAccountId: Number(socialAccountId),
        socialMedia,
      },
    };

    try {
      await joinMarketplace({ variables: joinVariables });
      sendAmplitudeEvent(eventTypes.joinMarketplace, { campaignId });
      history.push(
        campaignId ? generatePath(ROUTES.MARKETPLACE_YOUR_JOB_ID, { id: campaignId }) : ROUTES.MARKETPLACE_YOUR_JOB
      );
    } catch (e) {
      console.error(e.message);
      if (e.message === "GraphQL error: you don't meet the campaign's condition") {
        enqueueSnackbar(t('NotMeetCampaignCondition'), { variant: 'error' });
      } else if (e.message === ErrorTypes.CAMPAIGN_CLOSED_OUT_OF_COUPON) {
        failToJoinRedirectUrl += '?outOfCoupon=true';
      } else {
        enqueueSnackbar(t(`${e.message || 'failedToUpdate'}`), { variant: 'error' });
      }
      history.push(failToJoinRedirectUrl);
    }
  };

  return <InitialLoading />;
};

export default JoinCampaignRedirectComponent;
