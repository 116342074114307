import styled from '@emotion/styled';
import React from 'react';
import ActivityTimeline from './ActivityTimeline';

const FanActivity = () => (
  <Container>
    <ActivityTimeline />
  </Container>
);

const Container = styled.div`
  padding: 24px;
  background-color: #fff;
`;

export default FanActivity;
