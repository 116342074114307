import React from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import clipboardLightOrange from '@src/assets/icon/chat/clipboardLightOrange.svg';
import emptyThumbnail from '@src/assets/icon/emptyThumbnail.svg';
import Empty from '@src/components/atoms/Empty';
import { localizedDateFormatter } from '@src/components/atoms/List/DateColumn';
import TextCutter from '@src/components/atoms/TextCutter';
import { ListIndicator } from '@src/components/molecules/Indicator';
import useGetFanOrders from '@src/components/organisms/FanOrders/useGetFanOrders';
import { getPaymentStatusText, getShippingStatusText } from '@src/components/organisms/FanOrders/helper';
import { formatNumberWithCommas } from '@src/libs/format';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import { ShopifyOrderPaymentStatus, ShopifyOrderShippingStatus } from '@src/__generated__/globalTypes';
import { generatePath, ROUTES } from '@src/shared/routes';
import { Icon } from '@src/components/atoms/Icon';
import Icomoon from '@src/components/atoms/Icomoon';

const Orders = () => {
  const { i18n, params, t } = useQueryHelper();
  const { isMobileView } = usePageLayout();
  const { activeChat, fanId } = params;
  const { data, loading } = useGetFanOrders({ fanId: Number(fanId) });
  const ORDER_TIME_FORMAT = `d MMM yyyy 'at' h:mm aaa`;

  const orders = data?.getFanOrders?.orders || [];
  const pathOptions = activeChat && fanId ? { activeChat, fanId } : undefined;

  return (
    <div css={styles.container}>
      {!isMobileView && (
        <div css={styles.navigatorContainer}>
          <Link to={generatePath(ROUTES.CHAT_ACTIVE_ID_FAN_ID, pathOptions)}>
            <Icon>
              <Icomoon css={{ transform: 'rotate(90deg)' }} icon="arrow-down" />
            </Icon>
          </Link>
          <div>
            <img alt="icon" height="32" src={clipboardLightOrange} width="32" />
          </div>
          <div>{t('MenuTitle.Orders')}</div>
        </div>
      )}

      <ContentContainer isMobileView={isMobileView}>
        {loading ? (
          <ListIndicator />
        ) : orders.length > 0 ? (
          orders.map(order => {
            const { currency, items, orderId, orderTime, paymentStatus, shippingStatus } = order;
            const paymentStatusText = getPaymentStatusText(paymentStatus);
            const shippingStatusText = getShippingStatusText(shippingStatus);

            return (
              <div css={styles.orderContainer} key={orderId}>
                <div css={styles.orderHeader}>
                  <div>#{orderId}</div>
                  <StatusIndicator status={paymentStatus}>{t(paymentStatusText)}</StatusIndicator>
                  <StatusIndicator status={shippingStatus}>{t(shippingStatusText)}</StatusIndicator>
                  <div>
                    <span>
                      {localizedDateFormatter(
                        new Date(orderTime + 'Z'),
                        ORDER_TIME_FORMAT,
                        i18n.language as AppLanguage
                      )}
                    </span>
                  </div>
                </div>

                {items.map((item, index) => {
                  const { image, price, productName, quantity, variant } = item;

                  return (
                    <div css={styles.itemContainer} key={index}>
                      <div>
                        <img alt="productImg" src={image || emptyThumbnail} />
                      </div>
                      <div>
                        <TextCutter lines={2} text={productName} />
                        <div>{`Size/Color : ${variant}`}</div>
                      </div>
                      <div>
                        <div>{`${currency}${formatNumberWithCommas(price)} x ${quantity}`}</div>
                        <div>{`${currency}${formatNumberWithCommas(price * quantity)}`}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })
        ) : (
          <Empty />
        )}
      </ContentContainer>
    </div>
  );
};

const ContentContainer = styled.div<{ isMobileView: boolean }>`
  display: grid;
  height: ${({ isMobileView }) => (isMobileView ? '90vh' : '65vh')};
  overflow-y: auto;
`;

const StatusIndicator = styled.div<{ status: ShopifyOrderPaymentStatus | ShopifyOrderShippingStatus | null }>`
  align-items: center;
  background-color: ${({ status }) =>
    status === ShopifyOrderPaymentStatus.PAID || status === ShopifyOrderShippingStatus.FULFILLED
      ? '#40b87c'
      : '#FFB63D'};
  border-radius: 30px;
  color: #fff;
  display: flex;
  font-size: 10px;
  font-weight: 600;
  height: 16px;
  margin-right: 4px;
  padding: 0 8px;
  width: fit-content;
`;

const styles = {
  container: css`
    display: grid;
  `,
  itemContainer: css`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;

    & > div:nth-of-type(1) {
      background-color: #dee5ec;
      height: 48px;
      width: 48px;

      & > img {
        object-fit: fill;
        width: 100%;
      }
    }

    & > div:nth-of-type(2) {
      border-right: 1px solid #eef3f7;
      display: grid;
      flex: 1;
      padding: 0 8px;

      & > p {
        color: #27313b;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 8px;
      }

      & > div {
        color: #6e7c89;
        font-size: 12px;
      }
    }

    /* stylelint-disable no-descending-specificity */
    & > div:nth-of-type(3) {
      display: grid;
      flex-basis: 30%;
      padding: 0 8px;

      & > div:nth-of-type(1) {
        color: #6e7c89;
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 3px;
      }

      & > div:nth-of-type(2) {
        color: #27313b;
        font-size: 15px;
        font-weight: 600;
      }
    }
  `,
  navigatorContainer: css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;

    & > a {
      align-items: center;
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0 2px 5px #dee5ec, 0 1px 4px rgba(222, 229, 236, 0.5);
      display: grid;
      height: 32px;
      justify-content: center;
      margin-right: 8px;
      width: 32px;

      & > i {
        margin: 0;
      }
    }

    & > div:nth-of-type(2) {
      color: #27313b;
      font-size: 16px;
      font-weight: 600;
      margin-left: 8px;
    }
  `,
  orderContainer: css`
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0 2px 5px #dee5ec;
    display: grid;
    margin-bottom: 16px;
    padding: 16px;
  `,
  orderHeader: css`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;

    & > div:nth-of-type(1) {
      color: #3892e5;
      font-size: 12px;
      font-weight: 600;
      margin-right: 4px;
      text-decoration: underline;
    }

    & > div:nth-of-type(4) {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      min-width: 0;

      & > span {
        color: #6e7c89;
        font-size: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  `,
};

export default Orders;
