import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import Grid from '@src/components/atoms/Grid';
import Label from '@src/components/atoms/Label';
import { ListIndicator } from '@src/components/molecules/Indicator';
import MultiSelectField from '@src/components/molecules/MultiSelectField';
import SentenceForm from '@src/components/molecules/SentenceForm';
import SingleSelectField from '@src/components/molecules/SingleSelectField';
import TextForm from '@src/components/molecules/TextForm';
import YearMonthDayPicker from '@src/components/molecules/YearMonthDayPicker';
import RegionSelector from '@src/components/organisms/RegionSelector';
import { COUNTRY_IDS, GENDERS } from '@src/libs/constant';
import { useInfluencerCategories, useTranslateOptions } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/theme';
import { Genders } from '@src/__generated__/globalTypes';
import { FormSubmitSection } from '@src/components/shared/FormSubmitSection';

export interface AccountInformation {
  brandName: string | null;
  categoryIds: number[];
  countryId: string;
  dateOfBirth: string | null;
  gender: Genders;
  introduce: string;
  name: string;
  regionId: number | null;
}

interface CreatorAccountProps {
  isLoading: boolean;
}

const CreatorAccount = ({ isLoading }: CreatorAccountProps) => {
  const { t } = useTranslation();
  const { influencerCategories } = useInfluencerCategories();
  const translatedCategories = useTranslateOptions(influencerCategories);
  const translatedCountries = useTranslateOptions(COUNTRY_IDS);
  const translatedGenders = useTranslateOptions(GENDERS);
  const {
    formState: { errors, isSubmitting },
    register,
    setValue,
    watch,
  } = useFormContext<AccountInformation>();
  const [
    // brandNameValue,
    categoryIdsValue,
    countryIdValue,
    dateOfBirthValue,
    genderValue,
    regionIdValue,
  ] = watch([
    // 'brandName',
    'categoryIds',
    'countryId',
    'dateOfBirth',
    'gender',
    'regionId',
  ]);

  return (
    <div css={styles.formContainer}>
      <Grid md={8} sm={10} xs={12}>
        {isLoading ? (
          <ListIndicator />
        ) : (
          <>
            <div css={styles.formInputSection}>
              <div css={styles.formTitle}>{t('Title.Creator Account')}</div>
              <div css={styles.formDescription}>
                {t('Annotation.Edit your information and set notifications to receive')}
              </div>

              <InputContainer>
                <StyledTextForm
                  error={!!errors.name}
                  isRequired
                  title="Creator Name"
                  placeholder="Creator Name"
                  {...register('name')}
                />
                <ErrorMessage message={errors.name?.message} />
              </InputContainer>

              {/* <InputContainer>
                <StyledTextForm
                  error={!!errors.brand}
                  isRequired
                  title="Brand Name"
                  placeholder="Brand Name"
                  {...register('brandName')}
                />
                <ErrorMessage message={errors.brandName?.message} />
              </InputContainer> */}

              <InputContainer>
                <MultiSelectField
                  css={styles.dropdownField}
                  error={!!errors.categoryIds}
                  isRequired
                  name="categoryIds"
                  options={translatedCategories}
                  title="Categories"
                  value={categoryIdsValue.map(id => id.toString()) || []}
                  setFieldValue={(_attr, value) =>
                    setValue(
                      'categoryIds',
                      value.map(val => Number(val))
                    )
                  }
                />
                <ErrorMessage message={errors.categoryIds?.message} />
              </InputContainer>

              <div css={styles.dobpickerContainer}>
                <Label isRequired title={t('Selector.Date of Birth')} />
                <YearMonthDayPicker
                  format="yyyy-MM-dd"
                  error={!!errors.dateOfBirth}
                  maxDate={new Date()}
                  value={dateOfBirthValue || ''}
                  onChange={value => setValue('dateOfBirth', value as string)}
                />
                <ErrorMessage message={errors.dateOfBirth?.message} />
              </div>

              <InputContainer>
                <StyledSingleSelectField
                  disabled
                  error={!!errors.countryId}
                  isRequired
                  name="countryId"
                  options={translatedCountries}
                  title="Country"
                  value={countryIdValue || ''}
                  setFieldValue={setValue}
                />
                <ErrorMessage message={errors.countryId?.message} />
              </InputContainer>

              <InputContainer>
                <RegionSelector
                  countryId={countryIdValue}
                  css={styles.dropdownField}
                  error={!!errors.regionId}
                  isRequired
                  name="regionId"
                  title="Region"
                  value={regionIdValue?.toString() || ''}
                  setFieldValue={setValue}
                />
                <ErrorMessage message={errors.regionId?.message} />
              </InputContainer>

              <InputContainer>
                <StyledSingleSelectField
                  disabled
                  error={!!errors.gender}
                  isRequired
                  name="gender"
                  options={translatedGenders}
                  title="Gender"
                  value={genderValue || ''}
                  setFieldValue={setValue}
                />
                <ErrorMessage message={errors.gender?.message} />
              </InputContainer>

              <InputContainer>
                <StyledSentenceForm placeholder="Introduction" title="Introduction" {...register('introduce')} />
              </InputContainer>
            </div>

            <FormSubmitSection isSubmitting={isSubmitting} />
          </>
        )}
      </Grid>
    </div>
  );
};

const InputContainer = styled.div`
  margin-bottom: 24px;
`;

const StyledSentenceForm = styled(SentenceForm)`
  & > label {
    font-size: 14px;
  }

  & > textarea {
    border-radius: 2px;
  }
`;

const StyledSingleSelectField = styled(SingleSelectField)`
  & > label {
    font-size: 14px;
  }

  & > div > div {
    border-radius: 2px;
    min-height: 32px;

    & > div > div {
      height: auto;
    }
  }

  & input {
    border-radius: 2px;
    min-height: 32px;
  }
`;

const StyledTextForm = styled(TextForm)`
  & > label {
    font-size: 14px;
  }

  & input {
    border-radius: 2px;
    height: 32px;
  }
`;

const styles = {
  container: css`
    margin: 32px 80px;

    @media (max-width: ${ViewportType.SMALL}px) {
      margin: 32px 16px;
    }
  `,
  dobpickerContainer: css`
    margin-bottom: 16px;
    width: 60%;

    @media (max-width: ${ViewportType.TABLET}px) {
      width: 100%;
    }

    & > label {
      font-size: 14px;
    }

    & > div > div:nth-of-type(2) {
      margin: 0 8px;
    }

    /* stylelint-disable */
    & > div > div > div {
      border-radius: 0;
      min-height: 32px;

      & > div > input {
        min-height: 32px;
      }
    }
    /* stylelint-enable */
  `,
  dropdownField: css`
    & > label {
      font-size: 14px;
    }

    /* stylelint-disable */
    & > div > div {
      border-radius: 2px;
      min-height: 32px;
    }

    & input {
      border-radius: 2px;
      min-height: 32px;
    }

    & button {
      border-radius: 5px;
    }
    /* stylelint-enable */
  `,
  formContainer: css`
    display: flex;
    justify-content: center;
  `,
  formDescription: css`
    color: #27313b;
    font-size: 14px;
    margin-bottom: 24px;
  `,
  formInputSection: css`
    background-color: #fff;
    padding: 24px;
  `,
  formTitle: css`
    color: #27313b;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px;
  `,
  titleContainer: css`
    align-items: center;
    display: flex;

    & > a {
      background-color: #fff;
      box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
      display: flex;
      height: 32px;
      justify-content: center;
      width: 32px;

      & > i {
        padding-top: 2px;
      }
    }

    /* stylelint-disable */
    & > div {
      font-size: 18px;
      font-weight: 600;
      margin-left: 16px;
    }
    /* stylelint-enable */
  `,
};

export default CreatorAccount;
