import { useMutation } from '@apollo/client';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { useQueryHelper } from '@src/libs/hooks';
import SIGN_IN_LINE_IG from './mutations/AnyXSocialAuthSignInInstagramLine.graphql';
import {
  AnyXSocialAuthSignInInstagramLine,
  AnyXSocialAuthSignInInstagramLineVariables,
} from './mutations/__generated__/AnyXSocialAuthSignInInstagramLine';

const useSignInInstagramLine = () => {
  const { enqueueSnackbar, t } = useQueryHelper();
  const keyLineIdToken = 'lineIdToken';

  const getLineIdToken = () => localStorage.getItem(keyLineIdToken) || '';

  const setLineIdToken = (lineIdToken: string) => {
    localStorage.setItem(keyLineIdToken, lineIdToken);
  };

  const removeLineIdToken = () => {
    localStorage.removeItem(keyLineIdToken);
  };

  const [signInInstagramLine] = useMutation<
    AnyXSocialAuthSignInInstagramLine,
    AnyXSocialAuthSignInInstagramLineVariables
  >(SIGN_IN_LINE_IG, {
    onCompleted: () => {
      sendAmplitudeEvent(eventTypes.signInInstagramLine);
      window.location.replace('https://line.me/R/nv/chat');
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  return {
    getLineIdToken,
    removeLineIdToken,
    setLineIdToken,
    signInInstagramLine,
  };
};

export default useSignInInstagramLine;
