import * as React from 'react';
import Thanks from '@src/components/organisms/Help/Thanks';
import { usePageLayout, useUpdateDocumentTitle } from '@src/libs/hooks';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';

const Template = () => {
  const title = 'pageTitle.Thanks';
  const pageTitle = 'Thanks';
  useUpdateDocumentTitle({ title, pageTitle });
  const { isMobileView } = usePageLayout();

  return (
    <div css={{ margin: '16px' }}>
      <div css={{ marginBottom: '16px' }}>{!isMobileView && <BackNavigator title="Contact" />}</div>
      <Thanks />
    </div>
  );
};

export default Template;
