import moment from 'moment';
import React from 'react';
import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Label from '@src/components/atoms/Label';
import TimePicker, { timeDefaultFormat } from '@src/components/atoms/TimePicker';
import { DayPickerHandInput } from '@src/components/molecules/DayPicker';
import RichEditor from '@src/components/molecules/RichEditor';
import TextForm from '@src/components/molecules/TextForm';
import { getDateTimeInPickerFormat } from '@src/libs/date';
import { useQueryHelper } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/theme';
import GET_EMAIL_OVERVIEW from './queries/GetEmailOverview.graphql';
import { GetEmailOverview, GetEmailOverviewVariables } from './queries/__generated__/GetEmailOverview';

interface OverviewProps {
  emailId: string;
}

const Overview = ({ emailId }: OverviewProps) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  const { data } = useQuery<GetEmailOverview, GetEmailOverviewVariables>(GET_EMAIL_OVERVIEW, {
    variables: {
      input: { emailId },
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const emailInfo = data?.getEmailOverview?.info;
  const statictics = data?.getEmailOverview?.stats;
  const recipientsCount = statictics?.recipientsCount || 0;
  const staticticsItems = [
    { count: recipientsCount, isTotal: true, title: 'Recipients' },
    { count: statictics?.openCount || 0, showRate: true, title: 'Open Rate' },
    { count: statictics?.clickCount || 0, showRate: true, title: 'Click Rate' },
    { count: statictics?.bounceCount || 0, showRate: false, title: 'Bounce Rate' },
    { count: statictics?.unsubscribeCount || 0, showRate: false, title: 'Unsubscribe Rate' },
  ];

  const getDateTime = () => {
    if (emailInfo?.sentAt) {
      const selectedDate = new Date(emailInfo.sentAt);
      const { date: dateString, time: timeString } = getDateTimeInPickerFormat(selectedDate);

      return {
        sentDate: dateString,
        sentTime: timeString,
      };
    }

    return {
      sentDate: '',
      sentTime: '',
    };
  };
  const { sentDate, sentTime } = getDateTime();

  return (
    <div css={styles.container}>
      <div css={styles.statisticsContainer}>
        {staticticsItems.map((item, index) => {
          const { count, isTotal, showRate, title } = item;
          const percentage = recipientsCount ? +((count / recipientsCount) * 100).toFixed(2) : 0;

          return (
            <div key={index}>
              <div>{t(`HeaderColumn.${title}`)}</div>
              {showRate ? (
                <div>
                  <div>
                    {percentage}
                    <span>%</span>
                  </div>
                  <div>{t('Recipients Count', { count })}</div>
                </div>
              ) : (
                <div>
                  <div>{count}</div>
                  {!isTotal && <div>{t('Recipients Count in Percentage', { count: percentage })}</div>}
                </div>
              )}
            </div>
          );
        })}
      </div>

      <div>
        <InputContainer>
          <StyledLabel title="Sent Date" />
          <div css={styles.dateContainer}>
            <div css={styles.datePickerContainer}>
              <DayPickerHandInput disabled value={sentDate} handleChangeDay={() => null} />
            </div>
            <div css={styles.timePickerContainer}>
              <TimePicker disabled use12Hours value={moment(sentTime, timeDefaultFormat)} />
            </div>
          </div>
        </InputContainer>

        <InputContainer>
          <StyledTextForm
            disabled
            placeholder=""
            title="To"
            value={emailInfo?.toEmails?.concat(emailInfo?.tags || []).join(', ') || ''}
          />
        </InputContainer>

        <InputContainer>
          <StyledTextForm disabled placeholder="" title="From" value={emailInfo?.fromEmail || ''} />
        </InputContainer>

        <InputContainer>
          <StyledTextForm disabled placeholder="" title="Subject" value={emailInfo?.subject || ''} />
        </InputContainer>

        <StyledLabel title="Message" />
        <div css={styles.richEditorContainer}>
          <RichEditor disable={true} height="auto" showToolbar={false} value={emailInfo?.message || ''} />
        </div>
      </div>
    </div>
  );
};

const InputContainer = styled.div`
  margin-bottom: 16px;
`;

const StyledLabel = styled(Label)`
  color: #6e7c89;
  font-size: 14px;
  font-weight: 600;
`;

const StyledTextForm = styled(TextForm)`
  & > label {
    color: #6e7c89;
    font-size: 14px;
    font-weight: 600;
  }

  & input {
    background-color: #fff;
    border: none;
    border-bottom: 1px solid #dee5ec;
  }
`;

const styles = {
  container: css`
    background-color: #fff;
    border-radius: 0 0 3px 3px;
    box-shadow: 0 2px 1px rgba(110, 124, 137, 0.05), 0 0 2px rgba(110, 124, 137, 0.25);
    padding: 24px;

    @media (max-width: ${ViewportType.TABLET}px) {
      padding: 16px;
    }
  `,
  dateContainer: css`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  `,
  datePickerContainer: css`
    margin-right: 8px;
    width: 30%;

    @media (max-width: ${ViewportType.MEDIUM}px) {
      width: 49%;
    }

    & > div > div > div {
      background-color: #fff;
      border: none;
      border-bottom: 1px solid #dee5ec;
      border-radius: 0;
      height: 32px;

      & > span {
        color: #6e7c89;
        font-size: 24px;
        top: 3px;
      }

      & > input {
        height: 32px;
      }
    }
  `,
  richEditorContainer: css`
    & > div > div > div > div > div:nth-of-type(3) {
      max-width: 50vw;
      width: 100%;

      @media (max-width: ${ViewportType.TABLET}px) {
        max-width: 85vw;
      }
    }

    & .se-resizing-bar {
      background-color: #fff;
      min-height: 0;

      /* stylelint-disable no-descending-specificity */
      & > div {
        display: none;
      }
    }
  `,
  statisticsContainer: css`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;

    & > div {
      align-items: flex-start;
      box-shadow: 1px 0 0 #eef3f7;
      display: grid;
      flex-basis: 20%;
      justify-content: center;
      height: fit-content;

      @media (max-width: ${ViewportType.MEDIUM}px) {
        align-items: center;
        box-shadow: 0 1px 0 #eef3f7;
        display: flex;
        flex-basis: 100%;
        padding: 16px;
      }

      & > div:nth-of-type(1) {
        color: #6e7c89;
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media (max-width: ${ViewportType.MEDIUM}px) {
          display: flex;
          flex: 65%;
          margin-bottom: 0;
        }
      }

      & > div:nth-of-type(2) {
        @media (max-width: ${ViewportType.MEDIUM}px) {
          display: grid;
          flex: 35%;
        }

        & > div:nth-of-type(1) {
          align-items: center;
          color: #27313b;
          display: flex;
          font-size: 16px;
          font-weight: 600;
          justify-content: center;
          margin-bottom: 8px;

          @media (max-width: ${ViewportType.MEDIUM}px) {
            margin-bottom: 4px;
          }

          & > span {
            color: #6e7c89;
            font-size: 12px;
          }
        }

        & > div:nth-of-type(2) {
          color: #6e7c89;
          display: flex;
          justify-content: center;
          font-size: 12px;
        }
      }
    }

    & > div:nth-of-type(1) {
      box-shadow: -1px 0 0 #eef3f7, 1px 0 0 #eef3f7;

      @media (max-width: ${ViewportType.MEDIUM}px) {
        box-shadow: 0 -1px 0 #eef3f7, 0 1px 0 #eef3f7;
      }
    }
  `,
  timePickerContainer: css`
    width: 30%;

    @media (max-width: ${ViewportType.MEDIUM}px) {
      width: 49%;
    }

    & > div {
      & > div {
        display: none;
      }

      & > span {
        width: 100%;

        & > input {
          background-color: #fff !important;
          border: none;
          border-bottom: 1px solid #dee5ec;
          width: 100% !important;
        }
      }
    }
  `,
};

export default Overview;
