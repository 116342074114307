import { useTranslation } from 'react-i18next';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { toast, TypeOptions } from 'react-toastify';
import { useCallback } from 'react';
import { customIcons, ToastWrapper } from '@src/components/organisms/Notification';

interface ToastProps {
  variant: TypeOptions;
}

const useQueryHelper = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const params = useParams<Record<any, string>>();
  const { path } = useRouteMatch();
  const { location } = history;

  const enqueueSnackbar = useCallback((message: string, props: ToastProps) => {
    const { icon, header } = customIcons[props.variant];

    return toast(() => ToastWrapper({ header: header ? t(header) : '', message }), {
      theme: 'light',
      type: props.variant,
      icon,
    });
  }, []);

  return {
    t,
    enqueueSnackbar,
    history,
    ...location,
    params,
    path,
    i18n,
  };
};

export default useQueryHelper;
