import React, { useState, useEffect, useMemo } from 'react';
import { useQueryHelper } from '@src/libs/hooks';
import { useQuery } from '@apollo/client';
import { CRMChatTypeFilter } from '@src/__generated__/globalTypes';
import debounce from 'lodash/debounce';
import { LIMIT, POLL_INTERVAL } from '@src/libs/constant';
import { css } from '@emotion/react';
import { selectedChatState, useSetRecoilState } from '@src/recoilAtoms';
import { NoFansConnected, NoLineConnected } from '../ChatView/EmptyChats';
import ChatListFilter from './ChatFilter';
import ChatsList from './ChatsList';
import * as GET_LINE_CHATS from './GetLineChats.graphql';
import { GetLineChats, GetLineChatsVariables, GetLineChats_getLineChats_chats } from './__generated__/GetLineChats';
import { ChatFilterFormValues } from './types';

interface ChatListSectionProps {
  hasMobileLayout?: boolean;
  needsToConnectLine?: boolean;
}
const ChatListSection = ({ hasMobileLayout, needsToConnectLine }: ChatListSectionProps) => {
  const { params, history, pathname, enqueueSnackbar, t } = useQueryHelper();
  const [filter, setFilter] = useState<ChatFilterFormValues>({ type: CRMChatTypeFilter.ACTIVATED, keyword: '' });
  const [currentLimit, setCurrentLimit] = useState(LIMIT);
  const [refetchLoading, setRefetchLoading] = useState(false);
  const [allChats, setAllChats] = useState<GetLineChats_getLineChats_chats[]>([]);
  const setSelectedChatState = useSetRecoilState(selectedChatState);

  const { data, refetch, fetchMore } = useQuery<GetLineChats, GetLineChatsVariables>(GET_LINE_CHATS, {
    variables: {
      input: {
        keyword: filter?.keyword,
        typeFilter: filter?.type,
        offset: 0,
        limit: currentLimit,
      },
      countInput: {
        keyword: filter?.keyword,
      },
    },
    pollInterval: POLL_INTERVAL,
    notifyOnNetworkStatusChange: true,
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    onCompleted: ({ getLineChats }) => {
      if (!!getLineChats?.chats) {
        setAllChats(getLineChats.chats);
      }
    },
  });

  const debouncedSearchHandler = useMemo(
    () =>
      debounce(
        (inputValue: string) =>
          refetch({
            input: {
              keyword: inputValue,
              typeFilter: filter?.type,
              offset: 0,
              limit: LIMIT,
            },
            countInput: {
              keyword: inputValue,
            },
          }),
        300
      ),
    []
  );

  const { activatedCount = 0, assignedToMe = 0 } = data?.getLineChatCount || {};

  useEffect(() => {
    const allChatsData = data?.getLineChats?.chats;

    if (!!allChatsData?.length && !hasMobileLayout && !params.activeChat) {
      history.replace(`${pathname}/${allChatsData[0].chatId}/${allChatsData[0].fanId || ''}`);
    }
  }, [data?.getLineChats?.chats.length]);

  useEffect(() => {
    if (data?.getLineChatCount?.activatedCount !== undefined) {
      setSelectedChatState(prevState => ({ ...prevState, hasNoChats: !data?.getLineChatCount?.activatedCount }));
    }
  }, [data?.getLineChatCount?.activatedCount]);

  const handleFetchMore = async () => {
    setRefetchLoading(true);

    await fetchMore({
      variables: {
        input: {
          keyword: filter?.keyword,
          typeFilter: filter?.type,
          offset: 0,
          limit: currentLimit + LIMIT,
        },
        countInput: {
          keyword: filter?.keyword,
        },
      },
    });

    setCurrentLimit(prevLimit => prevLimit + LIMIT);
    setRefetchLoading(false);
  };

  const renderEmptyListContent = () => {
    if (hasMobileLayout && activatedCount === 0) {
      return needsToConnectLine ? <NoLineConnected /> : <NoFansConnected />;
    }

    return <p css={styles.emptyList}>{t('There are no chats')}</p>;
  };

  const disabledFilter = !!needsToConnectLine || activatedCount === 0;

  return (
    <div css={styles.chatListsSection}>
      <ChatListFilter
        filter={filter}
        setFilter={setFilter}
        assignedToMeNum={assignedToMe}
        totalNum={activatedCount}
        onKeywordSearch={debouncedSearchHandler}
        disabled={disabledFilter}
      />
      {!allChats?.length ? (
        renderEmptyListContent()
      ) : (
        <ChatsList
          chats={allChats}
          loading={refetchLoading}
          fetchMore={handleFetchMore}
          assignedToMeNum={assignedToMe}
          totalNum={activatedCount}
          filter={filter}
          hasMobileLayout={hasMobileLayout}
        />
      )}
    </div>
  );
};

const styles = {
  chatListsSection: css`
    display: flex;
    flex-direction: column;
    height: 100%;
  `,
  emptyList: css`
    margin: auto;
    color: #27313b;
  `,
};

export default ChatListSection;
