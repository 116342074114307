import * as yup from 'yup';
import { SocialAccountType } from '../__generated__/globalTypes';

/*
Schema
*/

export const NameSchema = yup.string().required('requiredNameMessage');

export const CountrySchema = yup.string().required('requiredCountryMessage');
export const RegionSchema = yup.string().required('requiredRegionMessage');
export const BrandSchema = yup.string().nullable().required('requiredBrandMessage');
export const LanguageSchema = yup.string().required('requiredLanguageMessage');

// Influencer
export const GenderSchema = yup.string().required('requiredGenderMessage');
export const BirthdaySchema = yup.string().required('requiredBirthdayMessage');
export const InfluencerEmailSchema = yup.string().email('invalidEmailMessage').required('requiredEmailMessage');
export const InfluencerPhoneNumberSchema = yup.string().matches(/^\+?[0-9]+$/, 'invalidPhoneNumberMessage');
export const CategoriesSchema = yup
  .array()
  .of(yup.string().required('requiredCategoriesMessage'))
  .max(3, 'categoriesBeLessThanOrEqualMessage')
  .required('requiredCategoriesMessage');
export const IntroduceSchema = yup.string();

// PaymentInformation
// TODO Do separated validation
export const AccountNameSchema = yup.string().required('requiredAccountNameMessage');
export const FullNameSchema = yup.string().required('requiredFullNameMessage');
export const ProvinceSchema = yup.string().required('requiredProvinceMessage');
export const InfluencerPostalCodeSchema = yup.string().required('requiredPostalCodeMessage');
export const InfluencerCitySchema = yup.string().required('requiredCityMessage');
export const InfluencerAddressSchema = yup.string().required('requiredAddressMessage');
export const ApartmentAddressSchema = yup.string().required('requiredApartmentMessage');
export const BankSchema = yup.string().required('requiredBankMessage');
export const BranchSchema = yup.string().when(['isMenaInfluencer'], {
  is: true,
  then: yup.string().nullable(),
  otherwise: yup.string().when(['hasBranchId'], {
    is: true,
    then: yup.string().required('requiredBranchMessage'),
    otherwise: yup.string().nullable(),
  }),
});
const JPBranchSchema = yup.string().required('requiredBranchMessage');
const SubjectSchema = yup.string().required('requiredSubjectMessage');
export const AccountNumberSchema = yup.string().required('requiredAccountNumberMessage');
export const AddressSchema = yup.string().when('isMenaInfluencer', {
  is: false,
  then: yup.string().required('requiredAddressMessage'),
  otherwise: yup.string().nullable(),
});
export const CitySchema = yup.string().when('isMenaInfluencer', {
  is: false,
  then: yup.string().required('requiredCityMessage'),
  otherwise: yup.string().nullable(),
});
export const PostalCodeSchema = yup.string().when('isMenaInfluencer', {
  is: false,
  then: yup.string().required('requiredPostalCodeMessage'),
  otherwise: yup.string().nullable(),
});
export const IbanSchema = yup.string().when('isMenaInfluencer', {
  is: true,
  then: yup.string().required('requiredIbanMessage'),
  otherwise: yup.string().nullable(),
});
export const SwiftCode = yup.string().when('isMenaInfluencer', {
  is: true,
  then: yup.string().required('requiredSwiftCodeMessage'),
  otherwise: yup.string().nullable(),
});
export const BranchNameSchema = yup.string().when('isMenaInfluencer', {
  is: true,
  then: yup.string().required('requiredBranchNameMessage'),
  otherwise: yup.string().nullable(),
});
// ContactForm
export const InquirySchema = yup.object().shape({
  enquiry: yup.string().required('requiredInquiryMessage'),
});

// PriceCardForm
export const PriceSchema = yup.object().shape({
  price: yup.number().positive('priceMustBePositive').required('priceMustBeSet'),
});

// EngagementPostForm
export const EngagementPostSchema = yup.object().shape({
  planedPostDate: yup.string().required('requiredEngagementPlannedPostDate'),
  content: yup.string().required('requiredEngagementPostContent'),
  socialAccountId: yup.string().required('requiredSocialAccountId'),
  igSocialMedia: yup.string().when('socialAccountMedia', {
    is: (socialAccountMedia: SocialAccountType) => socialAccountMedia === SocialAccountType.INSTAGRAM,
    then: yup.string().required('requiredIgSocialMedia'),
    otherwise: yup.string().notRequired(),
  }),
});

export const PaymentInformationSchema = yup.object().shape({
  accountName: AccountNameSchema,
  email: InfluencerEmailSchema,
  bankId: BankSchema,
  branchId: BranchSchema,
  accountNumber: AccountNumberSchema,
  address: AddressSchema,
  cityId: CitySchema,
  postalCode: PostalCodeSchema,
  iban: IbanSchema,
  swiftCode: SwiftCode,
  branchName: BranchNameSchema,
});

export const JPPaymentInformationSchema = yup.object().shape({
  accountName: AccountNameSchema,
  bankId: BankSchema,
  branchId: JPBranchSchema,
  accountNumber: AccountNumberSchema,
  subject: SubjectSchema,
  fullName: FullNameSchema,
});

const FirstNameSchema = yup.string().required('requiredFirstNameMessage');
const LastNameSchema = yup.string().required('requiredLastNameMessage');
const FanEmailSchema = yup.string().email('invalidEmailMessage').required('requiredEmailMessage');
const ContactNumberSchema = yup.string();

export const FanSchema = yup.object().shape({
  firstName: FirstNameSchema,
  lastName: LastNameSchema,
  email: FanEmailSchema,
  contactNumber: ContactNumberSchema,
});

export const FanDetailsSchema = yup.object().shape({
  firstName: FirstNameSchema,
  lastName: LastNameSchema,
  email: yup.string().email('invalidEmailMessage'),
  contactNumber: yup.string(),
  birthday: yup.string(),
  gender: yup.string(),
  countryId: yup.string(),
  province: yup.string(),
  city: yup.string(),
  postalCode: yup.string(),
  address1: yup.string(),
  address2: yup.string(),
  tags: yup.string(),
});

export const FanActivityCommentSchema = yup.object().shape({
  comment: yup.string().required('requiredActivityCommentMessage'),
});

export const AddressInformationSchema = yup.object().shape({
  fullName: FullNameSchema,
  postalCode: InfluencerPostalCodeSchema,
  province: ProvinceSchema,
  city: InfluencerCitySchema,
  address: InfluencerAddressSchema,
  address2: ApartmentAddressSchema,
});

export const SignUpProfileSchema = yup.object().shape({
  name: NameSchema,
  categories: CategoriesSchema,
  gender: GenderSchema,
  country: CountrySchema,
  regionId: RegionSchema,
  email: InfluencerEmailSchema,
  phoneNumber: InfluencerPhoneNumberSchema,
  birthday: BirthdaySchema,
});

const SigninPasswordSchema = yup.string().required('requiredPasswordMessage');
export const SignInCredentialSchema = yup.object().shape({
  email: InfluencerEmailSchema,
  password: SigninPasswordSchema,
});

export const SignUpFormSchema = yup.object().shape({
  name: NameSchema,
  categories: CategoriesSchema,
  gender: GenderSchema,
  country: CountrySchema,
  regionId: RegionSchema,
  email: InfluencerEmailSchema.matches(/^[\w.+\\-]+@((?!yahoo\.com).)*$/, 'Yahoo mail is not supported'),
  birthday: BirthdaySchema,
  introduce: IntroduceSchema,
});

export const PasswordSchema = yup
  .string()
  .min(8, (props: any) => `leastLetterMessage_${props.min}`)
  .max(64, (props: any) => `lessLetterMessage_${props.max}`)
  .matches(/[\x21-\x7e]+/, 'onlyAlphanumericOrSymbolsMessage')
  .required('requiredPasswordMessage');
export const PasswordConfirmSchema = yup
  .string()
  .oneOf([yup.ref('password'), null], 'PasswordNotMatchMessage')
  .required('requiredPasswordConfirmMessage');

export const PasswordResetSchema = yup.object().shape({
  password: PasswordSchema,
  passwordConfirm: PasswordConfirmSchema,
});

export const ResetPasswordSchema = yup.object().shape({
  email: InfluencerEmailSchema,
});

export default yup;
