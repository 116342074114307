import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@anymindgroup/any-ui-react';
import type { CheckboxProps } from '@anymindgroup/any-ui-react';

export interface CheckBoxProps extends CheckboxProps {
  interpolation?: Record<string, string>;
  label?: ReactNode;
}

const CheckBox = ({ wrapper, label, interpolation, ...restProps }: CheckBoxProps) => {
  const { t } = useTranslation();

  const wrapperProps: CheckboxProps['wrapper'] = label
    ? {
        ...wrapper,
        label: typeof label === 'string' ? t(label, { ...interpolation }) : label,
      }
    : wrapper;

  return <Checkbox {...restProps} wrapper={wrapperProps} />;
};

export default CheckBox;
export { CheckboxProps };
