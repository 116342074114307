import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import anyCreatorLogo from '@src/assets/icon/anyCreatorBlack.svg';
import privateForm from '@src/assets/img/privateForm.svg';
import { ViewportType } from '@src/libs/theme';
import { usePageLayout } from '@src/libs/hooks';

const PrivateForm = () => {
  const { t } = useTranslation();
  const { isMobileView } = usePageLayout();

  return (
    <div css={styles.container}>
      <div css={styles.contentContainer}>
        <div>
          <div>
            <img
              alt="bgImg"
              height={isMobileView ? '94' : '126'}
              src={privateForm}
              width={isMobileView ? '134' : '180'}
            />
          </div>
          <div>{t(`Annotation.Private Form`)}</div>
        </div>
      </div>
      <div css={styles.footer}>
        <div>{t('Powered by')}</div>
        <Link to="https://any-creator.com/">
          <img alt="logo" height="32" src={anyCreatorLogo} width="151" />
        </Link>
      </div>
    </div>
  );
};

const styles = {
  container: css`
    padding-bottom: 40px;
  `,
  contentContainer: css`
    align-items: center;
    background-color: #fff;
    display: flex;
    height: 765px;
    justify-content: center;
    margin-bottom: 62px;

    @media (max-width: ${ViewportType.TABLET}px) {
      height: 540px;
    }

    & > div {
      display: grid;
      justify-content: center;
      padding: 0 40px;
      width: 100%;

      & > div:nth-of-type(1) {
        display: flex;
        justify-content: center;
        margin-bottom: 24px;
      }

      & > div:nth-of-type(2) {
        color: #27313b;
        font-size: 14px;
      }
    }
  `,
  footer: css`
    display: grid;
    justify-content: center;
    text-align: center;

    /* stylelint-disable no-descending-specificity */
    & > div:nth-of-type(1) {
      color: #000;
      font-size: 14px;
      margin-bottom: 8px;
    }
  `,
};

export default PrivateForm;
