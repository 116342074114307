import { ApolloError } from '@apollo/client';
import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import InitialLoading from '@src/components/molecules/InitialLoading';
import { useQueryHelper } from '@src/libs/hooks';
import { ConnectType, getCallbackUrl, getConnectState, removeToken, setToken } from '@src/libs/auth';
import { useAuthSetup } from '@src/components/organisms/AuthSetUp/useAuthSetup';
import { getMessagesFromFetchResult, ErrorTypes, UNEXPECTED_ERROR } from '@src/libs/error';
import { switchSocialMediaName } from '@src/libs/SocialMedia';
import { ROUTES } from '@src/shared/routes';
import { AnyXAuthSocialAccountType } from '@src/__generated__/globalTypes';
import { useAuthProviderResponse } from '../hooks';
import { AnyXSocialAuthSignIn, AnyXSocialAuthSignInVariables } from './__generated__/AnyXSocialAuthSignIn';
import * as SOCIAL_AUTH_SIGN_IN from './AnyXSocialAuthSignIn.graphql';

const SignInRedirect = () => {
  const [socialAuthSignIn] = useMutation<AnyXSocialAuthSignIn, AnyXSocialAuthSignInVariables>(SOCIAL_AUTH_SIGN_IN);
  const { setUp } = useAuthSetup();

  const { t, enqueueSnackbar, history } = useQueryHelper();

  const { provider, response } = useAuthProviderResponse(ROUTES.SIGN_IN);

  const signInRedirectCall = async () => {
    if (!provider) {
      enqueueSnackbar(t(UNEXPECTED_ERROR), { variant: 'error' });
      history.push(ROUTES.ROOT);

      return;
    }

    // Redirect with state for connecting FB pages and an IG account.
    let socialMediaProvider = provider;
    const connectState = await getConnectState();
    const isIgConnectType = connectState && connectState.connectType === ConnectType.CONNECT_IG_ACCOUNT;

    if (isIgConnectType) {
      socialMediaProvider = AnyXAuthSocialAccountType.INSTAGRAM;
    }

    const variables = {
      input: {
        provider: socialMediaProvider,
        response,
        callbackUrl: getCallbackUrl(socialMediaProvider, FE_REDIRECT_MAPPING.SIGNIN),
      },
    };

    const { token, refreshToken, errors } = await socialAuthSignIn({ variables })
      .then(result => {
        if (result && result.data && result.data.anyXSocialAuthSignIn) {
          const payload = result.data.anyXSocialAuthSignIn;

          return {
            token: payload.token,
            refreshToken: null,
            errors: [],
          };
        } else {
          return {
            token: null,
            refreshToken: null,
            errors: getMessagesFromFetchResult(result),
          };
        }
      })
      .catch((e: ApolloError) => ({
        token: null,
        refreshToken: null,
        // @ts-ignore, see issue -> https://github.com/apollographql/@apollo/client/issues/1496
        errors: [e?.graphQLErrors?.[0]?.message],
      }));

    // SocialAuthentication Failed
    if (!token || errors.length > 0) {
      errors.forEach(error => {
        console.error(error);
        // put if condition on top of enqueueSnackbar to avoid showing alert message
        if (socialMediaProvider === AnyXAuthSocialAccountType.TIKTOK && error === ErrorTypes.VIDEO_PERMISSION_MISSING) {
          history.push(ROUTES.SIGN_IN_TIKTOK_GRANT_PERMISSION);

          return;
        }

        if (
          socialMediaProvider === AnyXAuthSocialAccountType.FACEBOOK &&
          error === ErrorTypes.FB_REQUIRED_PERMISSION_MISSING
        ) {
          history.push(ROUTES.SIGN_IN_FACEBOOK_GRANT_PERMISSION);

          return;
        }

        enqueueSnackbar(t(error), { variant: 'error' });
        if (socialMediaProvider === AnyXAuthSocialAccountType.INSTAGRAM) {
          history.push(ROUTES.SIGN_IN_IG_UNABLE_CONNECT);

          return;
        }
        history.push(ROUTES.SIGN_IN_FAILED_CONNECT);
      });

      return;
    }

    // Sign In with token
    setToken(token, refreshToken);

    try {
      await setUp(token);
      const redirectUrlBeforeSignIn = localStorage.getItem('redirectUrlBeforeSignIn');
      if (window.opener) {
        window.opener.postMessage({ redirectPath: redirectUrlBeforeSignIn || ROUTES.ROOT }, '*');
        window.close();

        return;
      }
      sendAmplitudeEvent(eventTypes.signIn, { signInMethod: switchSocialMediaName(socialMediaProvider) });
      history.push(redirectUrlBeforeSignIn ? redirectUrlBeforeSignIn : ROUTES.ROOT);
      localStorage.removeItem('redirectUrlBeforeSignIn');
    } catch (e) {
      removeToken();
      console.error(e);
      enqueueSnackbar(t(e.message), { variant: 'error' });
      if (window.opener) {
        window.opener.postMessage({ redirectPath: ROUTES.ROOT, errorMsg: e.message }, '*');
        window.close();

        return;
      }
      history.push(ROUTES.ROOT);
    }

    return;
  };

  useEffect(() => {
    signInRedirectCall();
  }, []);

  return <InitialLoading />;
};

export default SignInRedirect;
