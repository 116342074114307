import styled from '@emotion/styled';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router';
import * as React from 'react';
import css from '@emotion/css';
import { useTranslation } from 'react-i18next';
import { ThemeButton } from '@src/components/atoms/Button';
import TextForm from '@src/components/molecules/TextForm';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import { SignUpAndSignInBreakpoint } from '@src/libs/theme';
import { FormValues } from './types';

interface NewPasswordFormProps {
  isPasswordReset?: boolean;
}

const NewPasswordForm = ({ isPasswordReset }: NewPasswordFormProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const {
    formState: { errors, isSubmitting },
    register,
  } = useFormContext<FormValues>();

  return (
    <div css={formStyle}>
      <Container>
        {pathname.includes('password_reset') && (
          <>
            <Heading>{t('Heading.Change your password')}</Heading>
            <SubHeading>{t('SubHeading.Please set a new password')}</SubHeading>
          </>
        )}
        {pathname.includes('password_register') && (
          <>
            <Heading>{t('Heading.Set a password and sign in')}</Heading>
            <SubHeading>{t('SubHeading.Email address and password registered')}</SubHeading>
          </>
        )}
        <InputWrapper>
          <TextFormStyled
            autoComplete="new-password"
            title="New Password"
            placeholder="Your Password"
            isRequired={true}
            error={!!errors.password}
            type="password"
            {...register('password')}
          />
          <ErrorMessage message={errors.password?.message} />
        </InputWrapper>
        <InputWrapper>
          <TextFormStyled
            autoComplete="new-password"
            title="Confirm new password"
            placeholder="Your Password"
            isRequired={true}
            error={!!errors.passwordConfirm}
            type="password"
            {...register('passwordConfirm')}
          />
          <ErrorMessage message={errors.passwordConfirm?.message} />
        </InputWrapper>
        <ButtonWrapper>
          <ThemeButton
            theme="blue"
            css={submitButtonStyle}
            text={isPasswordReset ? 'Update' : 'Sign Up'}
            loading={isSubmitting}
            type="submit"
          />
        </ButtonWrapper>
      </Container>
    </div>
  );
};

const submitButtonStyle = css`
  border-radius: 3px;
  flex: 1;
  transition: 0.2s;

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    border-radius: 5px;
    height: 40px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

const Heading = styled.p`
  font-size: 20px;
  line-height: 24px;
  color: #27313b;
  text-align: center;
  margin-bottom: 16px;
`;
const SubHeading = styled.p`
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #27313b;
  margin-bottom: 40px;
`;

const TextFormStyled = styled(TextForm)`
  & label {
    font-size: 14px;
  }

  & input {
    border-radius: 3px;
    height: 32px;

    @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
      height: 40px;
    }
  }
`;

const formStyle = css`
  max-width: 348px;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
`;

const InputWrapper = styled.div`
  flex: 1;
  margin-bottom: 24px;
`;

export default NewPasswordForm;
