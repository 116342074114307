import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { ApolloError } from '@apollo/client';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { useQueryHelper } from '@src/libs/hooks';
import InitialLoading from '@src/components/molecules/InitialLoading';
import { getMessagesFromFetchResult, ErrorTypes, UNEXPECTED_ERROR } from '@src/libs/error';
import { ConnectType, getCallbackUrl, getConnectState } from '@src/libs/auth';
import { switchSocialMediaName } from '@src/libs/SocialMedia';
import { ROUTES } from '@src/shared/routes';
import { AnyXAuthSocialAccountType } from '@src/__generated__/globalTypes';
import { useAuthProviderResponse } from '../hooks';
import SOCIAL_AUTH_CONNECT from './AnyXSocialAuthConnect.graphql';
import { AnyXSocialAuthConnect, AnyXSocialAuthConnectVariables } from './__generated__/AnyXSocialAuthConnect';
import { getOptions } from './helpers';

const ConnectComponent = () => {
  const { provider, response } = useAuthProviderResponse(ROUTES.SETTINGS_SOCIAL_CONNECT);
  const { t, enqueueSnackbar, history } = useQueryHelper();

  const [socialAuthConnect] = useMutation<AnyXSocialAuthConnect, AnyXSocialAuthConnectVariables>(SOCIAL_AUTH_CONNECT);

  const socialAuthConnectCall = async () => {
    const option = await getOptions();

    if (!provider) {
      enqueueSnackbar(t(UNEXPECTED_ERROR), { variant: 'error' });
      if (window.opener) {
        window.opener.postMessage({ redirectPath: ROUTES.SETTINGS_SOCIAL_CONNECT, errorMsg: UNEXPECTED_ERROR }, '*');
        window.close();

        return;
      }
      history.push(ROUTES.SETTINGS_SOCIAL_CONNECT);

      return;
    }

    // Redirect with state for connecting FB pages and an IG account.
    let socialMediaProvider = provider;
    const connectState = getConnectState();
    const isIgConnectType = connectState?.connectType === ConnectType.CONNECT_IG_ACCOUNT;

    if (isIgConnectType) {
      socialMediaProvider = AnyXAuthSocialAccountType.INSTAGRAM;
    }

    // Connect
    const variables = {
      input: {
        provider: socialMediaProvider,
        response,
        callbackUrl: getCallbackUrl(provider, FE_REDIRECT_MAPPING.CONNECT),
      },
    };

    const { ok, errors } = await socialAuthConnect({ variables })
      .then(result => {
        if (result.data?.anyXSocialAuthConnect?.ok) {
          return { ok: true, errors: [] };
        } else {
          return { ok: false, errors: getMessagesFromFetchResult(result) };
        }
      })
      .catch((e: ApolloError) => ({
        ok: false,
        errors: [e?.graphQLErrors?.[0]?.message],
      }));

    if (ok && errors.length === 0) {
      sendAmplitudeEvent(eventTypes.connectSocial, { socialMedia: switchSocialMediaName(socialMediaProvider) });
      if (option.shouldShowSuccessMessage) {
        enqueueSnackbar('Success to connect.', { variant: 'success' });
      }

      if (window.opener) {
        window.opener.postMessage({ redirectPath: ROUTES.SETTINGS_SOCIAL_CONNECT, state: connectState }, '*');
        window.close();

        return;
      }
      history.push(ROUTES.SETTINGS_SOCIAL_CONNECT);
    } else {
      errors.forEach(error => {
        console.error(error);
        if (
          [ErrorTypes.FB_REQUIRED_PERMISSION_MISSING, ErrorTypes.VIDEO_PERMISSION_MISSING].includes(error as ErrorTypes)
        ) {
          switch (error) {
            case ErrorTypes.FB_REQUIRED_PERMISSION_MISSING:
              history.push(ROUTES.SETTINGS_SOCIAL_CONNECT_FACEBOOK_GRANT_PERMISSION);
              break;
            default:
              history.push(ROUTES.SETTINGS_SOCIAL_CONNECT_TIKTOK_GRANT_PERMISSION);
          }

          return;
        } else {
          enqueueSnackbar(t(error), { variant: 'error' });
          if (window.opener) {
            window.opener.postMessage({ redirectPath: ROUTES.SETTINGS_SOCIAL_CONNECT, errorMsg: error }, '*');
            window.close();
          }

          history.push(
            isIgConnectType ? ROUTES.SETTINGS_SOCIAL_CONNECT_IG_UNABLE_CONNECT : ROUTES.SETTINGS_SOCIAL_CONNECT
          );

          return;
        }
      });
    }
  };

  useEffect(() => {
    socialAuthConnectCall();
  }, []);

  return <InitialLoading />;
};

export default ConnectComponent;
