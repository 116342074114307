import React from 'react';
import { LiffProvider } from 'react-liff';
import { RouteComponentProps } from 'react-router';
import LineIdTokenVerification from './LineIdTokenVerification';

const Index = ({ match }: RouteComponentProps<{ liffId: string }>) => {
  const { params, path } = match;
  const { liffId } = params;
  const isIgSignIn = path === '/liff_signin/:liffId';

  return (
    <LiffProvider liffId={liffId}>
      <LineIdTokenVerification isIgSignIn={isIgSignIn} />
    </LiffProvider>
  );
};

export default Index;
