import isEmpty from 'lodash/isEmpty';
import { useQuery } from '@apollo/client';
import { useAuthData, useQueryHelper } from '@src/libs/hooks';
import INFLUENCER_PROFILE from './InfluencerProfile.graphql';
import {
  InfluencerProfileForAnalytics,
  InfluencerProfileForAnalyticsVariables,
} from './__generated__/InfluencerProfileForAnalytics';

const useInfluencerProfile = () => {
  const { userId } = useAuthData();
  const { enqueueSnackbar, t } = useQueryHelper();

  const { data, loading } = useQuery<InfluencerProfileForAnalytics, InfluencerProfileForAnalyticsVariables>(
    INFLUENCER_PROFILE,
    {
      variables: { pk: userId as number },
      onError: error => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
    }
  );

  return {
    hasFBAccount:
      !isEmpty(data?.influencerProfileV2?.facebookAccount) || !isEmpty(data?.influencerProfileV2?.facebookPages),
    hasIGAccount: !isEmpty(data?.influencerProfileV2?.instagramAccounts),
    hasTWAccount: !isEmpty(data?.influencerProfileV2?.twitterAccounts),
    hasYTAccount: !isEmpty(data?.influencerProfileV2?.youtubeAccounts),
    hasTKAccount: !isEmpty(data?.influencerProfileV2?.tiktokAccounts),
    data,
    loading,
  };
};

export default useInfluencerProfile;
