import { useQuery } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import AFFILIATE_REPORT from './queries/MarketplaceAffiliateReportForInfluencer.graphql';
import AFFILIATE_REPORT_SUMMARY from './queries/MarketplaceAffiliateReportSummaryForInfluencer.graphql';
import {
  MarketplaceAffiliateReportForInfluencer,
  MarketplaceAffiliateReportForInfluencerVariables,
} from './queries/__generated__/MarketplaceAffiliateReportForInfluencer';
import {
  MarketplaceAffiliateReportSummaryForInfluencer,
  MarketplaceAffiliateReportSummaryForInfluencerVariables,
} from './queries/__generated__/MarketplaceAffiliateReportSummaryForInfluencer';

interface UseReportProps {
  campaignId: number;
  limit?: number;
  offset?: number;
}

const useReport = ({ campaignId, limit, offset }: UseReportProps) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  const { data: dataReport, loading: loadingReport } = useQuery<
    MarketplaceAffiliateReportForInfluencer,
    MarketplaceAffiliateReportForInfluencerVariables
  >(AFFILIATE_REPORT, {
    skip: limit === undefined || offset === undefined,
    variables: {
      campaignId,
      limit: limit as number,
      offset: offset as number,
    },
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
  });

  const { data: dataReportSummary, loading: loadingReportSummary } = useQuery<
    MarketplaceAffiliateReportSummaryForInfluencer,
    MarketplaceAffiliateReportSummaryForInfluencerVariables
  >(AFFILIATE_REPORT_SUMMARY, {
    variables: {
      campaignId,
    },
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
  });

  return {
    dataReport,
    dataReportSummary,
    loadingReport,
    loadingReportSummary,
  };
};

export default useReport;
