import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from '@anymindgroup/any-ui-react';
import { css } from '@emotion/core';
import { DayPickerRange } from '@src/components/molecules/DayPicker';
import { SELECTED_RANGE } from '@src/components/molecules/DayPicker/types';
import SearchForm from '@src/components/molecules/SearchForm';
import { ViewportType } from '@src/libs/theme';
import { LineBroadcastStatus } from '@src/__generated__/globalTypes';
import useCustomerTags from './useCustomerTags';
import { FilterItems } from './useFilter';

interface BroadcastListFilterProps {
  filter: FilterItems;
  setFilter: (filter: FilterItems) => void;
}

const BroadcastListFilter = ({ filter, setFilter }: BroadcastListFilterProps) => {
  const [disabledDateRange, setDisabledDateRange] = useState<boolean>(false);
  const [keyword, setKeyword] = useState<string>(filter.keyword);
  const { t } = useTranslation();
  const { tags } = useCustomerTags();
  const tagOptions = tags.map(({ name }) => ({
    label: name,
    value: name,
  }));
  const statusOptions = Object.keys(LineBroadcastStatus).map(status => ({
    label: status,
    value: status,
  }));

  useEffect(() => {
    // filterByDeliveredDate(undefined); // TODO: logic from anyChat, not sure we need to do the same
    setDisabledDateRange(filter.status === 'DRAFT');
  }, [filter.status]);

  return (
    <div css={styles.container}>
      <SearchForm
        css={{ flex: 1 }}
        placeholder={t(`TextForm.Search by Title`)}
        value={keyword}
        onChange={e => setKeyword(e.target.value)}
        onEnterKeyPress={() => setFilter({ ...filter, keyword })}
      />
      <Select
        css={{ minWidth: '150px' }}
        isClearable
        options={tagOptions}
        placeholder={t('Tag')}
        value={tagOptions.find(tag => tag.value === filter.tag)}
        onChange={option => setFilter({ ...filter, tag: option?.value || '' })}
      />
      <Select
        css={{ minWidth: '150px' }}
        isClearable
        options={statusOptions}
        placeholder={t('Status')}
        value={statusOptions.find(status => status.value === filter.status)}
        onChange={option => setFilter({ ...filter, status: (option?.value || '') as LineBroadcastStatus })}
      />
      <DayPickerRange
        css={{ height: '32px' }}
        handleChangePeriod={period => setFilter({ ...filter, from: period.startDate, to: period.endDate })}
        period={{ startDate: filter.from || '', endDate: filter.to || '' }}
        disabled={disabledDateRange}
        rangeList={[
          { value: SELECTED_RANGE.TODAY },
          { value: SELECTED_RANGE.LAST_SEVEN_DAYS },
          { value: SELECTED_RANGE.TODAY_TO_MONTH_AGO },
          { value: SELECTED_RANGE.LAST_MONTH },
          { value: SELECTED_RANGE.LAST_YEAR },
          { value: SELECTED_RANGE.WEEK_TO_DATE },
          { value: SELECTED_RANGE.MONTH_TO_DATE },
          { value: SELECTED_RANGE.YEAR_TO_DATE },
        ]}
      />
    </div>
  );
};

const styles = {
  container: css`
    background-color: #fff;
    border-bottom: 1px solid #dee5ec;
    border-top: 1px solid #dee5ec;
    display: flex;
    gap: 8px;
    padding: 16px;
    flex-wrap: wrap;

    @media (max-width: ${ViewportType.TABLET}px) {
      display: grid;
    }
  `,
};

export default BroadcastListFilter;
