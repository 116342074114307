import React from 'react';
import lineIcon from '@src/assets/icon/line_chat.svg';
import { useQueryHelper } from '@src/libs/hooks';
// import MenuButton from '@src/components/molecules/MenuButton';
import { DialogModal } from '@src/components/molecules/DialogModal';
import FormStepper from '@src/components/molecules/FormStepper';
import { css } from '@emotion/react';
import { ThemeButton } from '@src/components/atoms/Button';
import { checkmarkCode } from '@src/assets/htmlCodes';
import * as Styled from './Styled';
import { useConnectLineMachine, ConnectionStepsTypes } from './useConnectLineMachine';
import useGetLineChannels from './useGetLineChannels';

export const LineChannel = () => {
  const { t } = useQueryHelper();
  const { data } = useGetLineChannels();
  const { styles } = Styled;

  const lineAccount = data?.getLineChannels || null;
  const { machine, machineState, options, loading } = useConnectLineMachine();

  return (
    <>
      <div css={[styles.channelContent, customStyles.channelContent]}>
        <div css={{ gap: '8px' }}>
          <img alt="Line icon" height="24" src={lineIcon} width="21" />
          <span>LINE</span>
        </div>
        {lineAccount ? (
          <div>
            <ThemeButton
              text="Connected"
              prefixIcon={<span css={styles.checkmark}>{checkmarkCode}</span>}
              fontSize="12px"
              css={[styles.connectButton, { pointerEvents: 'none' }]}
            />
            {/* temporary hide delete option as AnyChat does not support delete function for now */}
            {/* <MenuButton options={[{ title: 'Delete', onClick: () => setIsOpened(true) }]} /> */}
          </div>
        ) : (
          <ThemeButton
            theme="blue"
            text="Connect"
            onClick={() => machine.transition(machine.value, 'next')}
            fontSize="12px"
            css={styles.connectButton}
          />
        )}
      </div>
      <DialogModal
        headerNode={
          <div css={styles.modalHeader}>
            <img src={lineIcon} height="32" width="32" alt="shopify_icon" />
            <h3 css={styles.modalTitle}>{t('LINE Connection')}</h3>
          </div>
        }
        isOpen={machineState !== ConnectionStepsTypes.IDLE}
        closeModal={() => machine.transition(machineState, 'prev')}
        footerNode={
          <div css={styles.modalFooter}>
            <ThemeButton text="Cancel" width="max-content" onClick={() => machine.transition(machineState, 'prev')} />
            <ThemeButton
              width="max-content"
              text={options.nextButtonText}
              theme="blue"
              disabled={loading}
              loading={loading}
              onClick={() => options.formSubmit()}
            />
          </div>
        }
      >
        <div css={customStyles.formContent}>
          <div css={customStyles.stepperWrapper}>
            <FormStepper<ConnectionStepsTypes>
              steps={[
                { value: ConnectionStepsTypes.STEP1 },
                { value: ConnectionStepsTypes.STEP2 },
                { value: ConnectionStepsTypes.STEP3 },
                { value: ConnectionStepsTypes.STEP4 },
                { value: ConnectionStepsTypes.STEP5 },
              ]}
              currentStep={machineState}
              css={customStyles.stepper}
            />
          </div>
          {options.formNode}
        </div>
      </DialogModal>
    </>
  );
};

const customStyles = {
  stepper: css`
    max-width: 310px;
  `,
  stepperWrapper: css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px 0 38px;
  `,
  formContent: css`
    padding: 32px 16px;
  `,
  channelContent: css`
    border-top: 1px solid #eef3f7;
  `,
};
