import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import timeSchedule from '@src/assets/icon/timeSchedule.svg';
import userLightBlue from '@src/assets/icon/userLightBlue.svg';
import { ThemeButton } from '@src/components/atoms/Button';
import SocialAccount from '@src/components/molecules/SocialAccount';
import { getSocialMediaUrlByCampaignType } from '@src/libs/campaign';
import { useQueryHelper } from '@src/libs/hooks';
import { useCopy } from '@src/libs/hooks/useCopy';
import { ViewportType } from '@src/libs/theme';
import { CampaignSocialMediaType, SocialAccountType } from '@src/__generated__/globalTypes';
import { DialogModal } from '@src/components/molecules/DialogModal';
import CAMPAIGN_JOINED_ACCOUNT from './queries/CampaignJoinedAccount.graphql';
import { CampaignJoinedAccount, CampaignJoinedAccountVariables } from './queries/__generated__/CampaignJoinedAccount';

interface PostButtonProps {
  campaignDetails: {
    hashtags: string[];
    socialMedias: CampaignSocialMediaType[];
  };
  campaignId: number;
  height?: number;
}

const PostButton = ({ campaignDetails, campaignId }: PostButtonProps) => {
  const { hashtags, socialMedias } = campaignDetails;
  const [dialog, setDialog] = useState<boolean>(false);
  const { enqueueSnackbar, t } = useQueryHelper();
  const { handleCopyHasHashtag, isCopying } = useCopy();
  const pathname = getSocialMediaUrlByCampaignType(socialMedias[0]);

  const { data } = useQuery<CampaignJoinedAccount, CampaignJoinedAccountVariables>(CAMPAIGN_JOINED_ACCOUNT, {
    variables: { campaignId },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const onClickPostNow = () => {
    window.open(pathname, '_blank');
    setDialog(false);
  };

  const marketplaceJoinedAccount = data?.marketplaceJoinedAccount;

  return (
    <div>
      <DialogModal
        isOpen={dialog}
        closeModal={() => setDialog(false)}
        footerNode={
          <div css={styles.dialogAction}>
            <ThemeButton text="Close" width="max-content" onClick={() => setDialog(false)} />
            <ThemeButton theme="blue" width="max-content" text="Post Now" onClick={onClickPostNow} />
          </div>
        }
      >
        <div css={styles.dialogContent}>
          <div css={styles.dialogTitle}>{t('Precautions before posting')}</div>
          <div css={styles.dialogNotes}>
            <div>
              <img alt="time" height="20" src={timeSchedule} width="20" />
            </div>
            <div>
              <div>{t('Post reflection time')}</div>
              <div>{t('After posting, it will take around 20 minutes for the report to take')}</div>
            </div>
          </div>

          <div css={styles.joinedAccountContainer}>
            <div>
              <img height="24" src={userLightBlue} width="24" />
              <div>{t('Account you will post from')}</div>
            </div>
            <div>
              <SocialAccount
                followers={marketplaceJoinedAccount?.followersCount || null}
                socialMedia={marketplaceJoinedAccount?.socialAccountType as SocialAccountType}
                src={marketplaceJoinedAccount?.avatar || ''}
                username={marketplaceJoinedAccount?.username || ''}
              />
            </div>
          </div>

          <div css={styles.dialogSubtitle}>
            <span>#</span>
            <span>{t(`Hashtags are a "MUST ITEM"`)}</span>
          </div>
          <div css={styles.dialogDescription}>{t('precautionsPostingDescription')}</div>
          <div css={styles.hashtagsContainer}>
            {hashtags.map((hashtag, index) => (
              <div css={styles.hashtag} key={index}>
                {`#${hashtag}`}
              </div>
            ))}
          </div>

          <div>
            <ThemeButton
              text="Copy Hashtags"
              fontSize="12px"
              width="max-content"
              disabled={isCopying}
              onClick={() => handleCopyHasHashtag(hashtags)}
            />
          </div>
        </div>
      </DialogModal>

      <ThemeButton
        theme="blue"
        text="Post Now"
        onClick={() => setDialog(true)}
        width="max-content"
        borderRadius="5px"
        fontSize="12px"
      />
    </div>
  );
};

const styles = {
  closeBtn: css`
    align-items: center;
    background-color: #000;
    border-radius: 50%;
    display: flex;
    height: 40px;
    justify-content: center;
    position: absolute;
    right: -16px;
    top: -16px;
    width: 40px;

    [dir='rtl'] & {
      right: unset;
      left: -16px;
    }
  `,
  dialogAction: css`
    display: flex;
    justify-content: flex-end;
    padding: 16px;
    gap: 8px;

    @media (max-width: ${ViewportType.SMALL}px) {
      & > button {
        height: 40px;
        width: fill-available;
      }
    }
  `,
  dialogContent: css`
    border-bottom: 1px solid #dee5ec;
    padding: 16px;
  `,
  dialogDescription: css`
    color: #27313b;
    font-size: 13px;
    margin-bottom: 16px;
  `,
  dialogNotes: css`
    border-left: 5px solid #3892e5;
    border-radius: 3px;
    box-shadow: 0 1px 5px rgba(39, 49, 59, 0.2);
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
    padding: 8px;

    & > div:nth-of-type(1) {
      display: flex;
      flex-basis: 10%;
      justify-content: center;
    }

    & > div:nth-of-type(2) {
      display: flex;
      flex-basis: 90%;
      flex-wrap: wrap;

      & > div:nth-of-type(1) {
        color: #27313b;
        display: flex;
        flex-basis: 100%;
        font-size: 13px;
        font-weight: 600;
        padding: 0 8px;
      }

      & > div:nth-of-type(2) {
        color: #6e7c89;
        display: flex;
        flex-basis: 100%;
        font-size: 13px;
        padding: 0 8px;
      }
    }
  `,
  dialogSubtitle: css`
    margin-bottom: 16px;

    & > span:nth-of-type(1) {
      color: #3892e5;
      font-size: 18px;
      font-weight: 700;
      margin-right: 8px;
    }

    & > span:nth-of-type(2) {
      color: #27313b;
      font-size: 14px;
      font-weight: 700;
    }
  `,
  dialogTitle: css`
    border-bottom: 1px solid #dee5ec;
    color: #27313b;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 16px;
    padding-bottom: 16px;
  `,
  hashtag: css`
    background-color: #d7e9fb;
    border-radius: 5px;
    color: #3892e5;
    font-size: 13px;
    margin-right: 8px;
    padding: 8px;
  `,
  hashtagsContainer: css`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
  `,
  joinedAccountContainer: css`
    border-bottom: 1px solid #dee5ec;
    margin-bottom: 16px;
    padding-bottom: 16px;

    /* stylelint-disable no-descending-specificity */
    & > div:nth-of-type(1) {
      align-items: center;
      display: flex;
      flex-wrap: wrap;

      & > div {
        color: #27313b;
        font-size: 14px;
        font-weight: 700;
        margin-left: 8px;
      }
    }
  `,
};

export default PostButton;
