import styled from '@emotion/styled';
import * as React from 'react';
import { useLocation } from 'react-router';
import { getLocationFromSearch, getPagingParams } from '../../../libs/paging';
import { formatIntNumber } from '../../../libs/format';
import PageArrow from './PageArrow';
import PageLink from './PageLink';
import { getPageNumbers, PAGER_BREAKPOINT } from './utils';

interface PagerProps {
  className?: string;
  currentPage: number;
  totalPages: number;
  totalCount: number;
  first: number;
  last: number;
}

const Pager = (props: PagerProps) => {
  const { className, currentPage, totalPages, totalCount, first, last } = props;

  if (totalPages <= 1) {
    return null;
  }

  // Pages
  const pageNumbers = getPageNumbers(currentPage, totalPages);
  const lastPage = pageNumbers[pageNumbers.length - 1];
  const hasFirstPage = pageNumbers[0] > 2;
  // Links
  const { search, pathname } = useLocation();
  const getLink = (page: number) => getLocationFromSearch(pathname, search, getPagingParams(page));

  return (
    <Wrapper className={className}>
      <PagerCounter>
        {formatIntNumber(first)}-{formatIntNumber(last)} / {formatIntNumber(totalCount)}
      </PagerCounter>
      <List>
        <PageArrow href={getLink(currentPage - 1)} disabled={currentPage <= 1} />
        {/* nav to first page */}
        {hasFirstPage && (
          <>
            <PageLink href={getLink(1)} page={1} disabled={false} />
            <SpreadNav>...</SpreadNav>
          </>
        )}
        {pageNumbers.map(n => (
          <PageLink key={n} href={getLink(n)} page={n} disabled={n === currentPage} />
        ))}
        {/* nav to last page */}
        {totalPages > 1 && (
          <>
            {lastPage < totalPages - 1 && <SpreadNav>...</SpreadNav>}
            <PageLink href={getLink(totalPages)} page={totalPages} disabled={totalPages === currentPage} />
          </>
        )}
        <PageArrow href={getLink(currentPage + 1)} disabled={currentPage >= totalPages} />
      </List>
    </Wrapper>
  );
};

const Wrapper = styled.nav`
  display: flex;
  align-items: center;
  padding: 12px 0;
  justify-content: space-between;

  @media (max-width: ${PAGER_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

const List = styled.ul`
  display: flex;
  align-items: center;
  column-gap: 12px;

  @media (max-width: ${PAGER_BREAKPOINT}px) {
    flex-wrap: wrap;
    justify-content: center;
    height: 80px;
  }
`;

const SpreadNav = styled.li`
  width: 24px;
`;

const PagerCounter = styled.div`
  font-size: 13px;
  color: #6e7c89;

  @media (max-width: ${PAGER_BREAKPOINT}px) {
    margin-bottom: 8px;
  }
`;

export default Pager;
