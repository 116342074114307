import { useQuery } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import CAMPAIGN_DETAILS from './queries/MarketplaceCampaignDetails.graphql';
import {
  MarketplaceCampaignDetails,
  MarketplaceCampaignDetailsVariables,
} from './queries/__generated__/MarketplaceCampaignDetails';

const useCampaign = (campaignId: number) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  const { data, loading } = useQuery<MarketplaceCampaignDetails, MarketplaceCampaignDetailsVariables>(
    CAMPAIGN_DETAILS,
    {
      variables: {
        pk: campaignId,
      },
      onError: error => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
    }
  );

  return {
    data,
    loading,
  };
};

export default useCampaign;
