import styled from '@emotion/styled';
import { LocationDescriptor } from 'history';
import * as React from 'react';
import { Link } from 'react-router-dom';
import Icomoon from '@src/components/atoms/Icomoon';
import { Icon } from '@src/components/atoms/Icon';

interface PageArrowProps {
  href: LocationDescriptor;
  disabled?: boolean;
}

const PageArrow = (props: PageArrowProps) => {
  const { href, disabled } = props;

  return (
    <div>
      {disabled ? (
        <Page>
          <Icon>
            <Icomoon css={{ transform: 'rotate(90deg)' }} icon="arrow-down" />
          </Icon>
        </Page>
      ) : (
        <Anchor to={href}>
          <Icon>
            <Icomoon css={{ transform: 'rotate(270deg)' }} icon="arrow-down" />
          </Icon>
        </Anchor>
      )}
    </div>
  );
};

const Page = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88px;
  height: 32px;
  border-radius: 3px;
  border: solid 1px #dee5ec;
  background-color: #dee5ec;
  color: #8694a2;
  opacity: 0.5;
`;

const Anchor = styled(Page.withComponent(Link))`
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
  opacity: 1;
  background-color: #fff;

  &:hover {
    border-color: #c5d0da;
    color: #3b4146;
  }

  &:active {
    background-color: #f6f8fa;
  }
`;

export default PageArrow;
