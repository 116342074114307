import React from 'react';
import { ThemeButton } from '@src/components/atoms/Button';
import { css } from '@emotion/react';
import { usePersonalInformation, useQueryHelper } from '@src/libs/hooks';
import TextCutter from '@src/components/atoms/TextCutter';
import Icomoon from '@src/components/atoms/Icomoon';
import { SelectedChatStateType } from '@src/recoilAtoms';
import { useMutation } from '@apollo/client';
import { checkmarkCode } from '@src/assets/htmlCodes';
import * as ASSIGN_CHAT_PIC from './AssignChatPic.graphql';
import * as UNASSIGN_CHAT_PIC from './UnassignChatPic.graphql';
import * as RESOLVE_CHAT from './ResolveChat.graphql';
import * as UNRESOLVE_CHAT from './UnresolveChat.graphql';
import { AssignChatPic, AssignChatPicVariables } from './__generated__/AssignChatPic';
import { UnassignChatPic, UnassignChatPicVariables } from './__generated__/UnassignChatPic';
import { ResolveChat, ResolveChatVariables } from './__generated__/ResolveChat';

const ChatActionBar = ({ pic, resolved, className }: SelectedChatStateType & { className?: string }) => {
  const { params, t, enqueueSnackbar } = useQueryHelper();
  const { userPersonalInfo } = usePersonalInformation();

  const [assignPic, assignPicCtx] = useMutation<AssignChatPic, AssignChatPicVariables>(ASSIGN_CHAT_PIC, {
    refetchQueries: ['GetLineChats'],
  });
  const [unassignPic, unassignPicCtx] = useMutation<UnassignChatPic, UnassignChatPicVariables>(UNASSIGN_CHAT_PIC, {
    refetchQueries: ['GetLineChats'],
  });
  const [resolveChat, resolveChatCtx] = useMutation<ResolveChat, ResolveChatVariables>(RESOLVE_CHAT, {
    refetchQueries: ['GetLineChats'],
  });
  const [unresolveChat, unresolveChatCtx] = useMutation<AssignChatPic, AssignChatPicVariables>(UNRESOLVE_CHAT, {
    refetchQueries: ['GetLineChats'],
  });

  const isAssignedToMe = userPersonalInfo?.name && pic === userPersonalInfo.name;
  const isAssigned = !!pic;
  const isDisabledResolve = (isAssigned && !isAssignedToMe) || resolveChatCtx.loading || unresolveChatCtx.loading;
  const variables = { input: { chatId: params.activeChat } };

  const handleAssignUnassignPic = async () => {
    try {
      if (isAssigned) {
        await unassignPic({ variables });
      } else {
        await assignPic({ variables });
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };
  const handleResolveUnresolveChat = async () => {
    try {
      if (resolved) {
        await unresolveChat({ variables });
      } else {
        await resolveChat({ variables });
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  return (
    <div css={styles.container} className={className}>
      {!isAssigned ? (
        <ThemeButton
          onClick={handleAssignUnassignPic}
          text="Assign to Me"
          width="max-content"
          css={styles.assignToMe}
          loading={assignPicCtx.loading}
          disabled={assignPicCtx.loading}
          prefixIcon={<span css={styles.addIcon}>+</span>}
        />
      ) : (
        <div>
          <div css={[styles.picContainer, unassignPicCtx.loading ? { opacity: 0.8 } : '']}>
            <TextCutter lines={1} text={`${t('PIC')}: ${pic}`} css={styles.picText} />
            {!unassignPicCtx.loading ? (
              <Icomoon color="#808080" css={styles.close} icon="clear" onClick={handleAssignUnassignPic} />
            ) : null}
          </div>
        </div>
      )}
      <ThemeButton
        onClick={handleResolveUnresolveChat}
        text={resolved ? 'Resolved' : 'Resolve'}
        width="max-content"
        css={resolved ? styles.resolved : styles.resolve}
        loading={resolveChatCtx.loading || unresolveChatCtx.loading}
        disabled={isDisabledResolve}
        prefixIcon={resolved ? <span css={[styles.addIcon, { fontSize: '18px' }]}>{checkmarkCode}</span> : undefined}
      />
    </div>
  );
};

export default ChatActionBar;

const styles = {
  container: css`
    display: flex;
    justify-content: end;
    column-gap: 8px;
    padding: 8px;
    border-bottom: 1px solid #dee5ec;
  `,
  assignToMe: css`
    width: 144px;
    font-size: 12px;

    .pallete_text {
      padding-left: 4px;
    }
  `,
  resolve: css`
    min-width: 96px;
    font-size: 12px;
  `,
  resolved: css`
    min-width: 96px;
    font-size: 12px;
    color: #3892e5;
  `,
  addIcon: css`
    width: 16px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 2px;
  `,
  close: css`
    width: 18px;
    height: 18px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  `,
  picContainer: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    height: 32px;
    background: #eef3f7;
    border: 1px solid #dee5ec;
    box-sizing: border-box;
    border-radius: 3px;
    min-width: 144px;
  `,
  picText: css`
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #6e7c89;
  `,
};
