import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { useDeepCompareEffect, useQueryHelper } from '@src/libs/hooks';
import Address, { AddressInformation } from './Address';
import UPDATE_ADDRESS_INFORMATION from './mutations/UpdateAddressInformation.graphql';
import { UpdateAddressInformationVariables } from './mutations/__generated__/UpdateAddressInformation';
import useGetCreatorAddress from './useGetCreatorAddress';

const Index = () => {
  const { enqueueSnackbar, t } = useQueryHelper();
  const { creatorAddressInformation } = useGetCreatorAddress();

  const validationSchema = yup.object().shape({
    address: yup.string().required('requiredAddressMessage'),
    address2: yup.string().required('requiredApartmentMessage'),
    city: yup.string().required('requiredCityMessage'),
    fullName: yup.string().required('requiredFullNameMessage'),
    postalCode: yup.string().required('requiredPostalCodeMessage'),
    province: yup.string().required('requiredProvinceMessage'),
  });

  const addressInfo = {
    address: creatorAddressInformation?.address || '',
    address2: creatorAddressInformation?.address2 || '',
    city: creatorAddressInformation?.city || '',
    fullName: creatorAddressInformation?.fullName || '',
    postalCode: creatorAddressInformation?.postalCode || '',
    province: creatorAddressInformation?.province || '',
  };

  const methods = useForm<AddressInformation>({
    defaultValues: addressInfo,
    resolver: yupResolver(validationSchema),
  });

  useDeepCompareEffect(() => {
    methods.reset(addressInfo);
  }, [addressInfo]);

  const [updateAddressInformation] = useMutation<UpdateAddressInformationVariables>(UPDATE_ADDRESS_INFORMATION, {
    onCompleted: () => {
      enqueueSnackbar(t('succeededInUpdating'), { variant: 'success' });
      sendAmplitudeEvent(eventTypes.editSettingsAddress);
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const onSubmit = async (values: AddressInformation) => {
    updateAddressInformation({
      variables: {
        input: values,
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Address />
      </form>
    </FormProvider>
  );
};

export default Index;
