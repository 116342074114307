import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextForm from '@src/components/molecules/TextForm';
import ErrorMessage from '@src/components/atoms/ErrorMessage';

interface FormValues {
  lineKid: string;
}

const Step3 = ({ formState: { errors }, register }: UseFormReturn<FormValues>) => {
  const { t } = useTranslation();

  return (
    <form>
      <TextForm
        title="Kid"
        isRequired
        placeholder={t('TextForm.Kid')}
        error={!!errors.lineKid}
        {...register('lineKid')}
      />
      <ErrorMessage message={errors.lineKid?.message} />
    </form>
  );
};

export default Step3;
