import styled from '@emotion/styled';
import TermsText from '../../atoms/TermsText';
import TermsTitle from '../../atoms/TermsTitle';
import TopPageFooter from '../TopPage/Footer';
import { SignUpAndSignInBreakpoint } from '../../../libs/theme';

export const PrivacyTitle = styled(TermsTitle)``;

export const PrivacyText = styled(TermsText)``;

export const Wrapper = styled.section`
  box-shadow: 0 1px 4px 0 #dee5ec, 0 2px 5px 0 #dee5ec;
  width: 100%;
  padding: 48px;
  background-color: #fff;
  border-radius: 3px;
  box-sizing: border-box;

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    border-radius: 0;
    padding: 24px 24px 16px;
  }
`;

export const Anchor = styled.a`
  display: inline;
  color: #179cd7;

  &:hover {
    text-decoration: underline;

    @media (max-width: 450px) {
      text-decoration: none;
    }
  }
`;

export const SubTitle = styled(TermsText)`
  color: #6e7c89;
  padding-bottom: 40px;
  text-align: center;
`;

export const ListWrapper = styled.ul`
  padding-left: 16px;
`;

export const ListItem = styled.li`
  list-style: outside;
  margin-bottom: 4px;
`;

export const Footer = styled(TopPageFooter)`
  padding-top: 40px;
`;
