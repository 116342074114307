import cloneDeep from 'lodash/cloneDeep';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import trash from '@src/assets/icon/mylink/trash.svg';
import { ThemeButton } from '@src/components/atoms/Button';
import { CheckBox } from '@src/components/atoms/CheckBox';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import Icomoon from '@src/components/atoms/Icomoon';
import { PostFileField } from '@src/components/molecules/FileField';
import TextForm from '@src/components/molecules/TextForm';
import SingleSelectField from '@src/components/molecules/SingleSelectField';
import Switch from '@src/components/molecules/Switch';
import { ViewportType } from '@src/libs/theme';
import { QuestionType } from '@src/__generated__/globalTypes';
import { DialogModal } from '@src/components/molecules/DialogModal';
import { closeIcon } from '@src/assets/htmlCodes';

enum DialogStatus {
  CLOSE = 'CLOSE',
  DELETE = 'DELETE',
  UPLOAD = 'UPLOAD',
}

export enum QuestionErrorMessages {
  OPTION_TITLE = 'requiredQuestionOptionTitleMessage',
  TITLE = 'requiredQuestionTitleMessage',
  TYPE = 'requiredQuestionTypeMessage',
}

export interface Questions {
  id?: any;
  genId: any;
  image: string | null;
  isRequired: boolean;
  options: {
    id?: string | null;
    order: number;
    optionTitle: string;
  }[];
  order: number;
  questionType: QuestionType;
  title: string;
}

interface DynamicInputProps {
  error: boolean;
  isDragging: boolean;
  isEmailExist: boolean;
  isFormAnswerSubmitted: boolean;
  isNameExist: boolean;
  value: Questions;
  onChange: (value: Questions) => void;
  onCopy?: (value: Questions) => void;
  onDelete: () => void;
}

export const DynamicInput = ({
  error,
  isDragging,
  isEmailExist,
  isFormAnswerSubmitted,
  isNameExist,
  value,
  onChange,
  onCopy,
  onDelete,
}: DynamicInputProps) => {
  const { image, isRequired, options, questionType, title } = value;
  const [dialogStatus, setDialogStatus] = useState<DialogStatus>(DialogStatus.CLOSE);
  const [uploadedImage, setUploadedImage] = useState<string | null>(image);
  const { t } = useTranslation();

  const dropdownOptions = [
    {
      icon: <SingleSelectIcon icon="short-answer" />,
      label: t('Short answer'),
      value: QuestionType.SHORT_ANSWER,
    },
    {
      icon: <SingleSelectIcon icon="checkbox" size={12} />,
      label: t('Checkboxes'),
      value: QuestionType.CHECKBOX,
    },
    {
      icon: <SingleSelectIcon icon="dropdown" />,
      label: t('Dropdown'),
      value: QuestionType.DROPDOWN,
    },
  ];
  const [dropDownOptions, setDropDownOption] = useState(dropdownOptions);

  useEffect(() => {
    let items = dropdownOptions;
    if (!isNameExist) {
      items = items.concat({
        icon: <SingleSelectIcon icon="user-outlined" />,
        label: t('Name'),
        value: QuestionType.NAME,
      });
    }

    if (!isEmailExist) {
      items = items.concat({
        icon: <SingleSelectIcon icon="mail-outlined" />,
        label: t('Email Address'),
        value: QuestionType.EMAIL,
      });
    }
    setDropDownOption(items);
  }, [isEmailExist, isNameExist]);

  const onChangeOption = (index: number, val: string) => {
    const items = [...options];
    items[index].optionTitle = val;
    onChange({ ...value, options: items });
  };

  const onChangeSelect = (val: QuestionType) => {
    onChange({
      ...value,
      image: null,
      isRequired: false,
      options: [QuestionType.CHECKBOX, QuestionType.DROPDOWN].includes(val) ? [{ optionTitle: '', order: 1 }] : [],
      questionType: val,
      title,
    });
    setUploadedImage(null);
  };

  const onChangeUploadImage = (val: string) => {
    setDialogStatus(DialogStatus.CLOSE);
    onChange({ ...value, image: val });
  };

  const onClickAddOption = () => {
    const nextOrder = options.length + 1;
    const items = options.concat({ optionTitle: '', order: nextOrder });
    onChange({ ...value, options: items });
  };

  const onClickAddOther = () => {
    const nextOrder = options.length + 1;
    const items = options.concat({ optionTitle: 'Other', order: nextOrder });
    onChange({ ...value, options: items });
  };

  const onClickRemoveImage = () => {
    setUploadedImage(null);
    onChange({ ...value, image: '' });
  };

  const onClickRemoveOption = (index: number) => {
    // having readonly properties with es6 clone, so using lodash cloneDeep
    const items = cloneDeep(options);
    items.splice(index, 1).map((item, key) => {
      item.order = key + 1;

      return items;
    });
    onChange({ ...value, options: items });
  };

  return (
    <>
      <DialogModal isOpen={dialogStatus !== DialogStatus.CLOSE} closeModal={() => setDialogStatus(DialogStatus.CLOSE)}>
        <>
          {dialogStatus === DialogStatus.DELETE ? (
            <>
              <div css={styles.dialogContent}>
                <div>{t('Are you sure you want to permanently delete this?')}</div>
                <div>
                  {t('Annotation.You might lost response data forever. Before continuing, export your responses')}
                </div>
                <div>{t('Annotation.Do you want to delete this question and lost responses')}</div>
              </div>

              <div css={styles.dialogAction}>
                <ThemeButton
                  text="No, cancel"
                  width="max-content"
                  onClick={() => setDialogStatus(DialogStatus.CLOSE)}
                  css={styles.button}
                />
                <ThemeButton
                  text="Yes, delete it"
                  width="max-content"
                  theme="alertRed"
                  onClick={onDelete}
                  css={styles.button}
                />
              </div>
            </>
          ) : (
            <>
              <div css={styles.dialogDropArea}>
                <div>{t('Title.Image Upload')}</div>
                <div>{`※${t('Annotation.You can upload one image')}`}</div>
                <PostFileField
                  accept="image/gif, image/jpeg, image/png"
                  css={styles.fileUploadContainer}
                  dropAreaCss={styles.fileUploadDropAreaCss}
                  initialFileUrls={[]}
                  multiple={false}
                  name="materialsUrl"
                  notes={[
                    t('DragAndDrop.Info'),
                    t('DragAndDrop.MaxSize', { maxSize: '10GB' }),
                    t('DragAndDrop.FileType', {
                      MIME: 'PNG, GIF, JPEG, AVI, MOV, MP4',
                    }),
                    t('DragAndDrop.ImageRatio'),
                  ]}
                  value={[]}
                  setFieldValue={(_attr, val) => onChangeUploadImage(val[0])}
                  setPreviewValue={images => setUploadedImage(images[0])}
                />
              </div>
            </>
          )}
        </>
      </DialogModal>
      <InputContainer isDragging={isDragging}>
        <div css={styles.dragIconContainer}>
          <Icomoon color="#c4d0da" icon="dots-horizontal" />
        </div>

        <div css={styles.contentContainer}>
          {questionType === QuestionType.NAME ? (
            <div css={styles.nameContentContainer}>
              <div>
                <StyledTextForm
                  disabled
                  isRequired={isRequired}
                  placeholder={t('TextForm.First Name')}
                  title="First Name"
                />
              </div>
              <div>
                <StyledTextForm
                  disabled
                  isRequired={isRequired}
                  placeholder={t('TextForm.Last Name')}
                  title="Last Name"
                />
              </div>
            </div>
          ) : questionType === QuestionType.EMAIL ? (
            <div>
              <StyledTextForm disabled isRequired={isRequired} placeholder="sample@example.com" title="Email Address" />
            </div>
          ) : (
            <div css={styles.multiContentContainer}>
              <div>
                <div>
                  <StyledTextForm
                    isRequired={isRequired}
                    placeholder={t('TextForm.Question Title')}
                    value={title}
                    onChange={e => onChange({ ...value, title: e.target.value })}
                  />
                  {error && !title && <ErrorMessage message={QuestionErrorMessages.TITLE} />}
                </div>
                <div css={{ display: 'flex', gap: '8px' }}>
                  <div>
                    <StyledSingleSelectField
                      name=""
                      options={dropDownOptions}
                      value={questionType}
                      setFieldValue={(_attr, val) => onChangeSelect(val as QuestionType)}
                    />
                    {error && !questionType && <ErrorMessage message={QuestionErrorMessages.TYPE} />}
                  </div>
                  <ActionIcon disabled={!!uploadedImage} onClick={() => setDialogStatus(DialogStatus.UPLOAD)}>
                    <Icomoon icon="image-outlined" />
                  </ActionIcon>
                </div>
              </div>

              <div>
                {uploadedImage && (
                  <div css={styles.uploadedImageContainer}>
                    <div>
                      <img alt="image" src={uploadedImage} />
                    </div>
                    <div>
                      <span onClick={onClickRemoveImage} css={styles.close}>
                        {closeIcon}
                      </span>
                    </div>
                  </div>
                )}
                {[QuestionType.CHECKBOX, QuestionType.DROPDOWN].includes(questionType) ? (
                  <>
                    {options.map((option, index) => (
                      <div css={styles.optionField} key={index}>
                        {questionType === QuestionType.CHECKBOX ? (
                          <CheckBox label="" checked={false} />
                        ) : (
                          <NumberText>{`${index + 1}.`}</NumberText>
                        )}
                        <div>
                          <StyledTextForm
                            placeholder={`${t('option')} ${index + 1}`}
                            value={option.optionTitle}
                            onChange={e => onChangeOption(index, e.target.value)}
                          />
                          {error && !option.optionTitle && (
                            <ErrorMessage message={QuestionErrorMessages.OPTION_TITLE} />
                          )}
                        </div>
                        <div>
                          {options.length > 1 && (
                            <span onClick={() => onClickRemoveOption(index)} css={styles.close}>
                              {closeIcon}
                            </span>
                          )}
                        </div>
                      </div>
                    ))}
                    <div css={styles.addOption}>
                      {questionType === QuestionType.CHECKBOX ? (
                        <CheckBox label="" checked={false} />
                      ) : (
                        <NumberText>{`${options.length + 1}.`}</NumberText>
                      )}
                      &nbsp;
                      <span onClick={onClickAddOption}>{t('Add option')}&nbsp;</span>
                      {questionType === QuestionType.CHECKBOX && (
                        <>
                          <span>{t('or')}&nbsp;</span>
                          <span onClick={onClickAddOther}>{t('add other')}</span>
                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <div css={styles.shortAnsContentContainer}>
                    <StyledTextForm disabled placeholder={t('TextForm.Short answer text')} />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div css={styles.actionContainer}>
          {![QuestionType.EMAIL, QuestionType.NAME].includes(questionType) && (
            <ActionIcon onClick={() => onCopy?.(value)}>
              <Icomoon icon="copy-outlined" />
            </ActionIcon>
          )}
          <ActionIcon onClick={() => (isFormAnswerSubmitted ? setDialogStatus(DialogStatus.DELETE) : onDelete())}>
            <img alt="delete" height="16" src={trash} width="16" />
          </ActionIcon>
          <div css={styles.divider} />
          <div css={styles.switchContainer}>
            <span>{t(`Required`)}</span>
            <Switch isChecked={isRequired} handleChange={() => onChange({ ...value, isRequired: !value.isRequired })} />
          </div>
        </div>
      </InputContainer>
    </>
  );
};

const ActionIcon = styled.div<{ disabled?: boolean }>`
  align-items: center;
  background-color: #fff;
  border: 1px solid #dee5ec;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  width: 32px;
  ${({ disabled }) =>
    disabled ? 'background-color: #eef3f7; border: 1px solid #dee5ec; cursor: default; pointer-events: none;' : ''}
`;

const InputContainer = styled.div<{ isDragging: boolean }>`
  background-color: #f6f8fa;
  border: 1px solid #dee5ec;
  border-radius: 3px;
  cursor: move;
  margin-bottom: 24px;
  opacity: ${({ isDragging }) => (isDragging ? 0.8 : 1)};
  padding: 0 24px;
`;

const NumberText = styled.div`
  color: #27313b;
  font-size: 14px;
  margin-right: 8px;
`;

const SingleSelectIcon = styled(Icomoon)`
  margin-right: 8px;
`;

const StyledSingleSelectField = styled(SingleSelectField)`
  & > label {
    font-size: 14px;
  }

  & > div > div {
    border-radius: 2px;
    min-height: 32px;

    & > div > div {
      align-items: center;
      display: flex;
    }
  }

  & input {
    border-radius: 2px;
    min-height: 30px;
  }
`;

const StyledTextForm = styled(TextForm)`
  & > label {
    font-size: 14px;
    font-weight: 600;
  }

  & input {
    border-radius: 3px;
    height: 32px;
  }
`;

const styles = {
  actionContainer: css`
    display: flex;
    justify-content: flex-end;
    padding: 16px 0;
    gap: 8px;
  `,
  addOption: css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px;

    /* stylelint-disable no-descending-specificity */
    & > div {
      padding: 0;
      width: fit-content;
    }

    & > span:nth-of-type(1) {
      color: #6e7c89;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      text-decoration: underline;
    }

    & > span:nth-of-type(2) {
      color: #6e7c89;
      font-size: 14px;
      font-weight: 600;
    }

    & > span:nth-of-type(3) {
      color: #3892e5;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
    }
  `,
  contentContainer: css`
    border-bottom: 1px solid #dee5ec;
    padding-bottom: 16px;
  `,
  dialog: {
    borderRadius: '5px',
    boxShadow: '0 0 12px rgba(0, 0, 0, 0.12), 0 12px 12px rgba(0, 0, 0, 0.24)',
    maxWidth: 648,
    padding: 0,
    width: '90%',
  },
  dialogAction: css`
    border-top: 1px solid #dee5ec;
    display: flex;
    justify-content: flex-end;
    padding: 16px;

    & > button:nth-of-type(2) {
      margin-left: 16px;
    }
  `,
  dialogCloseBtn: css`
    align-items: center;
    background-color: #000;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    position: absolute;
    right: -10px;
    top: -15px;
    width: 40px;

    & > i {
      margin: 0;
    }
  `,
  dialogContent: css`
    padding: 24px;

    & > div:nth-of-type(1) {
      color: #27313b;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 24px;
    }

    & > div:nth-of-type(2) {
      color: #27313b;
      font-size: 14px;
      margin-bottom: 24px;
    }

    & > div:nth-of-type(3) {
      color: #ff5f5f;
      font-size: 14px;
    }
  `,
  dialogDropArea: css`
    padding: 24px;

    & > div:nth-of-type(1) {
      color: #27313b;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 16px;
    }

    & > div:nth-of-type(2) {
      color: #27313b;
      font-size: 14px;
      margin-bottom: 16px;
    }
  `,
  divider: css`
    border-left: 1px solid #dee5ec;
  `,
  dragIconContainer: css`
    display: flex;
    justify-content: center;
  `,
  fileUploadContainer: css`
    & label {
      font-size: 13px;
    }
  `,
  fileUploadDropAreaCss: css`
    & .drop-area-notes {
      justify-content: center;
    }
  `,
  nameContentContainer: css`
    display: flex;
    flex-basis: 100%;
    gap: 20px;

    & > div {
      width: fill-available;
    }
  `,
  multiContentContainer: css`
    & > div:nth-of-type(1) {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      gap: 8px;

      & > div:nth-of-type(1) {
        display: grid;
        flex: 1;

        & > div {
          width: fill-available;
        }
      }

      & > div:nth-of-type(2) {
        display: flex;
        flex: 1;

        & > div:nth-of-type(1) {
          width: fill-available;
        }
      }
    }
  `,
  optionField: css`
    align-items: center;
    display: flex;
    margin-top: 24px;

    & > div:nth-of-type(1) {
      margin-right: 8px;
      padding: 0;
      width: fit-content;

      & > label {
        margin-right: 0;
      }
    }

    & > div:nth-of-type(2) {
      width: 350%;
    }

    & > div:nth-of-type(3) {
      display: flex;
      justify-content: flex-end;
      width: fill-available;
    }
  `,
  shortAnsContentContainer: css`
    margin-top: 16px;
    width: 40%;
  `,
  switchContainer: css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;

    & > span {
      color: #27313b;
      font-size: 14px;
    }
  `,
  uploadedImageContainer: css`
    align-items: flex-end;
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;

    & > div:nth-of-type(1) {
      background-color: #000;
      width: 213px;

      & > img {
        height: 120px;
        object-fit: contain;
        width: 100%;
      }
    }

    & > div:nth-of-type(2) {
      margin-left: 8px;
    }
  `,
  button: css`
    @media (max-width: ${ViewportType.MEDIUM}px) {
      border-radius: 3px;
      height: 40px;
      width: fill-available;
    }
  `,
  close: css`
    font-size: 24px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  `,
};

export default DynamicInput;
