import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Option } from '../../components/organisms/Profile/ProfileForm/types';
import useQueryHelper from './useQueryHelper';
import * as ALL_INFLUENCER_CATEGORIES from './AllInfluencerCategories.graphql';
import { AllInfluencerCategories } from './__generated__/AllInfluencerCategories';

const useInfluencerCategories = () => {
  const [influencerCategories, setInfluencerCategories] = useState<Option[]>([]);
  const { enqueueSnackbar, t } = useQueryHelper();

  useQuery<AllInfluencerCategories>(ALL_INFLUENCER_CATEGORIES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (infCategories: AllInfluencerCategories) => {
      setInfluencerCategories(
        infCategories.allInfluencerCategories?.map(item => ({ value: item.id.toString(), label: item.description })) ||
          []
      );
    },
    onError: error => {
      enqueueSnackbar(t(error.message || 'UnexpectedError'), { variant: 'error' });
    },
  });

  return {
    influencerCategories,
  };
};

export default useInfluencerCategories;
