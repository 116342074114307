import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { ThemeButton } from '@src/components/atoms/Button';

interface PromotionMethodConnectorProps {
  items: string[];
  title: string;
  to: string;
}

const PromotionMethodConnector = ({ items, title, to }: PromotionMethodConnectorProps) => {
  const { t } = useTranslation();

  return (
    <div css={styles.container}>
      {items.length > 0 ? (
        <div>
          {items.map((item, index) => (
            <div css={styles.methodContainer} key={index}>
              <div>{t(title)}</div>
              <div>{item}</div>
            </div>
          ))}
          <div css={styles.addBtnContainer}>
            <ThemeButton href={to} skipTranslation text={t(`Add Method`, { method: t(title) })} />
          </div>
        </div>
      ) : (
        <div css={styles.emptyMethodContainer}>
          <div>{t(title)}</div>
          <ThemeButton href={to} skipTranslation text={t(`Add Method`, { method: t(title) })} />
        </div>
      )}
    </div>
  );
};

const styles = {
  addBtnContainer: css`
    padding: 16px;

    & > a {
      min-width: 160px;
      padding: 0 8px;
      width: fit-content;

      & > div > span {
        font-size: 12px;
        padding: 0;
      }
    }
  `,
  container: css`
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
    margin-bottom: 16px;
  `,
  emptyMethodContainer: css`
    align-items: center;
    display: flex;
    padding: 16px;

    & > div {
      color: #27313b;
      display: flex;
      flex: 1;
      font-size: 14px;
      font-weight: 600;
    }

    & > a {
      min-width: 116px;
      padding: 0 8px;
      width: fit-content;

      & > div > span {
        font-size: 12px;
        padding: 0;
      }
    }
  `,
  methodContainer: css`
    border-bottom: 1px solid #eef3f7;
    padding: 16px;

    & > div:nth-of-type(1) {
      color: #27313b;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 6px;
    }

    & > div:nth-of-type(2) {
      color: #6e7c89;
      font-size: 12px;
    }
  `,
};

export default PromotionMethodConnector;
