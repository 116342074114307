import { useQuery } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import GET_EMAIL_TEMPLATE from './queries/GetEmailTemplate.graphql';
import { GetEmailTemplate, GetEmailTemplateVariables } from './queries/__generated__/GetEmailTemplate';

interface UseGetEmailTemplateProps {
  emailId: number;
}

const useGetEmailTemplate = ({ emailId }: UseGetEmailTemplateProps) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  const { data, loading } = useQuery<GetEmailTemplate, GetEmailTemplateVariables>(GET_EMAIL_TEMPLATE, {
    variables: {
      input: { id: emailId },
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  return {
    getEmailTemplate: data?.getEmailTemplate,
    loading,
  };
};

export default useGetEmailTemplate;
