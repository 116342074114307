import React from 'react';
import { useFormContext } from 'react-hook-form';
import { css } from '@emotion/react';
import { LineMessageType } from '@src/__generated__/globalTypes';
import { LineContent } from '../FormInput/Inputs/helpers';
import PreviewItem from './PreviewItem';

const FormPreview = () => {
  const {
    formState: { errors },
    watch,
  } = useFormContext<{ lineContent: LineContent[] }>();
  const lineContent = watch('lineContent');

  return (
    <div css={styles.container}>
      <div />
      <div>
        {lineContent.map((item, index) => {
          const { buttonMessage, imageMessage, plainTextMessage, richMessage, type } = item;
          const buttonMessageError =
            type === LineMessageType.BUTTON &&
            !!buttonMessage?.cards.find(
              card =>
                card.actionLabels.find(actionLabel => !actionLabel.label || !actionLabel.url) ||
                !card.description.text ||
                !card.title.text
            );
          const imageMessageError =
            type === LineMessageType.IMAGES && !!imageMessage?.images.find(image => !image.imageUrl || !image.url);
          const plainTextError = type === LineMessageType.PLAIN_TEXT && !plainTextMessage?.text;
          const richMessageError = type === LineMessageType.RICH && !richMessage?.imageUrl;
          const error =
            !!errors.lineContent && (buttonMessageError || imageMessageError || plainTextError || richMessageError);

          return <PreviewItem error={error} lineContent={item} key={index} />;
        })}
      </div>
    </div>
  );
};

const styles = {
  container: css`
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    height: 788px;
    justify-content: center;
    padding: 12px;
    position: relative;
    width: 400px;

    & > div:nth-of-type(1) {
      background-color: #fff;
      border-radius: 24px;
      height: 26px;
      position: absolute;
      top: 4px;
      width: 186px;
    }

    & > div:nth-of-type(2) {
      background-color: #f6f8fa;
      border-radius: 14px;
      height: 708px;
      overflow: auto;
      padding: 40px 18px;
      width: 376px;
    }
  `,
};

export default FormPreview;
