import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from '@emotion/styled';
import { ViewportType } from '@src/libs/theme';

// this component is created for flex-box use, and it's support responsive resize

type MediaQuerySize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface GridProps {
  alignItems?: string;
  children?: React.ReactElement | (React.ReactElement | boolean | null)[];
  container?: boolean;
  flexBasis?: string;
  justifyContent?: string;
  lg?: MediaQuerySize;
  md?: MediaQuerySize;
  sm?: MediaQuerySize;
  xl?: MediaQuerySize;
  xs?: MediaQuerySize;
}

const Grid = ({ children, flexBasis = 'initial', ...restProps }: GridProps) => {
  const { lg, md, sm, xl, xs } = restProps;

  if (xl || lg || md || sm || xs) {
    const isExtraLarge = useMediaQuery({ query: `(min-width: ${ViewportType.EXTRA_LARGE}px)` });
    const isLarge = useMediaQuery({ query: `(min-width: ${ViewportType.LARGE}px)` });
    const isMedium = useMediaQuery({ query: `(min-width: ${ViewportType.TABLET}px)` });
    const isSmall = useMediaQuery({ query: `(min-width: ${ViewportType.SMALL}px)` });
    const isExtraSmall = useMediaQuery({ query: '(min-width: 0px)' });

    if (isExtraLarge) {
      flexBasis = `${(xl! || lg || md || sm || xs) * 8.3}%`;
    } else if (isLarge) {
      flexBasis = `${(lg! || md || sm || xs) * 8.3}%`;
    } else if (isMedium) {
      flexBasis = `${(md! || sm || xs) * 8.3}%`;
    } else if (isSmall) {
      flexBasis = `${(sm! || xs) * 8.3}%`;
    } else if (isExtraSmall) {
      flexBasis = `${xs! * 8.3}%`;
    }
  }

  return (
    <StyledGrid flexBasis={flexBasis} {...restProps}>
      {children}
    </StyledGrid>
  );
};

const StyledGrid = styled.div<GridProps>`
  height: fit-content;
  ${({ alignItems, container, flexBasis, justifyContent }) => {
    let styles = container ? 'display: flex; flex-wrap: wrap;' : 'display: grid;';
    if (alignItems) {
      styles += `align-items: ${alignItems};`;
    }
    if (justifyContent) {
      styles += `justify-content: ${justifyContent};`;
    }
    if (flexBasis) {
      styles += `flex-basis: ${flexBasis};`;
    }

    return styles;
  }}
`;

export default Grid;
