import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { css } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import anyCreatorLogo from '@src/assets/icon/anyCreatorBlack.svg';
import formThanksPage from '@src/assets/img/formThanksPage.png';
import Grid from '@src/components/atoms/Grid';
import { useDeepCompareEffect, useQueryHelper } from '@src/libs/hooks';
import { QuestionType } from '@src/__generated__/globalTypes';
import { ViewportType } from '@src/libs/theme';
import Form, { SubmitFormInformation } from './SubmitForm';
import SUBMIT_FORM from './mutations/SubmitForm.graphql';
import SUBMIR_FORM_INTERNAL from './mutations/SubmitFormInternal.graphql';
import { SubmitForm, SubmitFormVariables } from './mutations/__generated__/SubmitForm';
import { SubmitFormInternal, SubmitFormInternalVariables } from './mutations/__generated__/SubmitFormInternal';

interface Options {
  id: string;
  optionTitle: string;
  order: number;
}

interface Questions {
  id: string;
  image: string | null;
  isRequired: boolean;
  options: Options[];
  order: number;
  questionType: QuestionType;
  title: string;
}

interface IndexProps {
  description: string;
  hash?: string;
  id: string;
  isMobileMode?: boolean;
  questions: Questions[];
  thankDescription: string;
  thankTitle: string;
  title: string;
}

const Index = ({ description, hash, id, isMobileMode, questions, thankDescription, thankTitle, title }: IndexProps) => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const { enqueueSnackbar, t } = useQueryHelper();

  const initialValues = {
    id,
    questions: questions.map(question => {
      const { id: questionId, image, isRequired, options, questionType, title: questionTitle } = question;

      return {
        answeredOptionIds: [],
        answeredTexts: [],
        questionId,
        image,
        isRequired,
        options,
        questionTitle,
        questionType,
      };
    }),
    recaptchaResponse: '',
  };

  const validationSchema = yup.object().shape({
    questions: yup.array().of(
      yup.object().shape(
        {
          answeredOptionIds: yup.array().when(['isRequired', 'questionType'], {
            is: (isRequired, questionType) =>
              isRequired && [QuestionType.CHECKBOX, QuestionType.DROPDOWN].includes(questionType),
            then: yup.array().min(1, 'requiredOptionMessage'),
          }),
          answeredTexts: yup
            .array()
            .when(['isRequired', 'questionType'], {
              is: (isRequired, questionType) => isRequired && questionType === QuestionType.EMAIL,
              then: yup.array().min(1, 'requiredEmailMessage').of(yup.string().required('requiredEmailMessage')),
            })
            .when(['answeredTexts', 'questionType'], {
              is: (answeredTexts, questionType) => questionType === QuestionType.EMAIL && answeredTexts?.length > 0,
              then: yup.array().of(yup.string().email('invalidEmailMessage')),
            })
            .when(['isRequired', 'questionType'], {
              is: (isRequired, questionType) => isRequired && questionType === QuestionType.NAME,
              then: yup.array().min(2, 'requiredNameMessage'),
            })
            .when(['isRequired', 'questionType'], {
              is: (isRequired, questionType) => isRequired && questionType === QuestionType.SHORT_ANSWER,
              then: yup.array().min(1, 'requiredFieldMessage'),
            }),
        },
        [['answeredTexts', 'answeredTexts']]
      )
    ),
  });

  const methods = useForm<SubmitFormInformation>({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  useDeepCompareEffect(() => {
    methods.reset(initialValues);
  }, [initialValues]);

  const [submitForm] = useMutation<SubmitForm, SubmitFormVariables>(SUBMIT_FORM, {
    onCompleted: () => {
      enqueueSnackbar(t('succeededInCreating'), { variant: 'success' });
      setIsSubmitted(true);
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const [submitFormInternal] = useMutation<SubmitFormInternal, SubmitFormInternalVariables>(SUBMIR_FORM_INTERNAL, {
    onCompleted: () => {
      enqueueSnackbar(t('succeededInCreating'), { variant: 'success' });
      setIsSubmitted(true);
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const onSubmit = async (values: SubmitFormInformation) => {
    const input = {
      id: values.id,
      questions: values.questions.map(question => ({
        answeredOptionIds: question.answeredOptionIds.map(optionId => Number(optionId)),
        answeredTexts: question.answeredTexts.map(text => text.trim()),
        questionId: question.questionId,
      })),
    };
    if (hash) {
      await submitForm({
        variables: {
          input: {
            ...input,
            recaptchaResponse: values.recaptchaResponse,
          },
        },
      });
    } else {
      await submitFormInternal({
        variables: {
          input,
        },
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {isSubmitted ? (
          <div css={styles.thanksContainer}>
            <Grid md={isMobileMode ? 10 : 5} sm={isMobileMode ? 10 : 6} xs={10}>
              <div>
                <img alt="thanksImage" height="120" src={formThanksPage} width="180" />
              </div>
              <div>{thankTitle}</div>
              <div>{thankDescription}</div>
            </Grid>
          </div>
        ) : (
          <div style={hash ? { paddingTop: 64 } : {}}>
            <Form
              description={description}
              hash={hash}
              formValues={initialValues}
              isMobileMode={isMobileMode}
              title={title}
              onSubmit={onSubmit}
            />
          </div>
        )}
        <div css={styles.footer}>
          <div>{t('Powered by')}</div>
          <Link target="_blank" to={{ pathname: 'https://any-creator.com/' }}>
            <img alt="logo" height="32" src={anyCreatorLogo} width="151" />
          </Link>
        </div>
      </form>
    </FormProvider>
  );
};

const styles = {
  footer: css`
    display: grid;
    justify-content: center;
    margin-top: 65px;
    text-align: center;

    & > div:nth-of-type(1) {
      color: #000;
      font-size: 14px;
      margin-bottom: 8px;
    }
  `,
  thanksContainer: css`
    align-items: center;
    background-color: #fff;
    display: flex;
    height: 747px;
    justify-content: center;

    @media (max-width: ${ViewportType.TABLET}px) {
      height: 540px;
    }

    /* stylelint-disable no-descending-specificity */
    & > div {
      display: grid;

      & > div:nth-of-type(1) {
        display: flex;
        justify-content: center;
        margin-bottom: 24px;
      }

      & > div:nth-of-type(2) {
        color: #27313b;
        display: flex;
        font-size: 16px;
        font-weight: 600;
        justify-content: center;
        margin-bottom: 16px;
      }

      & > div:nth-of-type(3) {
        color: #27313b;
        display: flex;
        font-size: 14px;
        justify-content: center;
      }
    }
  `,
};

export default Index;
