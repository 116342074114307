import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { ThemeButton } from '@src/components/atoms/Button';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import { HeaderColumn } from '@src/components/atoms/List';
import { EmptyTable } from '@src/components/molecules/Table';
import TextForm from '@src/components/molecules/TextForm';
import { LIMIT } from '@src/libs/constant';
import { useQueryHelper } from '@src/libs/hooks';
import { defaultInfluencerAvatar } from '@src/libs/image';
import { getOffset, getPageInfo, usePaging } from '@src/libs/paging';
import { ViewportType } from '@src/libs/theme';
import { SliderComponents, StyledComponents } from '@src/components/molecules/SliderTable';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import { ROUTES, generatePath } from '@src/shared/routes';
import { DialogModal } from '@src/components/molecules/DialogModal';
import CREATE_CREATOR_STAFF from './mutations/CreateCreatorStaff.graphql';
import REMOVE_CREATOR_STAFF from './mutations/RemoveCreatorStaff.graphql';
import { CreateCreatorStaff, CreateCreatorStaffVariables } from './mutations/__generated__/CreateCreatorStaff';
import { RemoveCreatorStaff, RemoveCreatorStaffVariables } from './mutations/__generated__/RemoveCreatorStaff';
import useGetCreatorStaffs from './useGetCreatorStaffs';

const Users = () => {
  const [dialogAdd, setDialogAdd] = useState<boolean>(false);
  const [dialogDelete, setDialogDelete] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<boolean>(false);
  const [isSubmitting, setIsSubmmiting] = useState(false);
  const [name, setName] = useState<string>('');
  const [selectedStaffId, setSelectedStaffId] = useState<number | null>(null);
  const { enqueueSnackbar, t } = useQueryHelper();
  const isDesktopView = useMediaQuery({ query: `(min-width: ${ViewportType.TABLET}px)` });
  const currentPage = usePaging();
  const { data } = useGetCreatorStaffs({ limit: LIMIT, offset: getOffset(currentPage) });

  const [createCreatorStaff] = useMutation<CreateCreatorStaff, CreateCreatorStaffVariables>(CREATE_CREATOR_STAFF, {
    refetchQueries: ['GetCreatorStaffs'],
    onCompleted: () => {
      enqueueSnackbar(t('succeededInCreating'), { variant: 'success' });
      sendAmplitudeEvent(eventTypes.addUser);
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const [removeCreatorStaff] = useMutation<RemoveCreatorStaff, RemoveCreatorStaffVariables>(REMOVE_CREATOR_STAFF, {
    refetchQueries: ['GetCreatorStaffs'],
    onCompleted: () => {
      enqueueSnackbar(t('succeededInDeleting'), { variant: 'success' });
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  function cancelDialogAdd() {
    setDialogAdd(false);
    setEmail('');
    setEmailError(false);
    setName('');
  }

  function onClickDelete(id: number) {
    setSelectedStaffId(id);
    setDialogDelete(true);
  }

  function onClickDeleteStaff() {
    if (selectedStaffId) {
      setIsSubmmiting(true);
      removeCreatorStaff({
        variables: {
          input: {
            creatorStaffId: selectedStaffId,
          },
        },
      })
        .then(() => setDialogDelete(false))
        .finally(() => setIsSubmmiting(false));
    }
  }

  // @ts-ignore: Not all code paths return a value
  function onClickSendInvite() {
    // eslint-disable-next-line no-useless-escape
    const isValidEmail = /^[\w-+\.]+@([\w-]+\.)+[\w-]{2,}$/.test(email);
    if (!isValidEmail) {
      setEmailError(true);

      return false;
    }

    setEmailError(false);
    setIsSubmmiting(true);
    createCreatorStaff({
      variables: {
        input: {
          name,
          email,
        },
      },
    })
      .then(() => cancelDialogAdd())
      .finally(() => setIsSubmmiting(false));
  }

  const avatar = data?.getCreatorStaffs?.avatar || '';
  const creatorName = data?.getCreatorStaffs?.name || '';
  const staffs = data?.getCreatorStaffs?.staffs || [];
  const selectedStaffName = staffs.find(staff => staff?.id === selectedStaffId)?.name || '';
  const staffCount = staffs.length;
  const totalCount = data?.getCreatorStaffsCount?.total || 0;
  const { firstIndex, lastIndex, totalPages } = getPageInfo(currentPage, totalCount, LIMIT);

  return (
    <div css={styles.container}>
      <DialogModal
        isOpen={dialogAdd}
        closeModal={() => setDialogAdd(false)}
        footerNode={
          <DialogAction>
            <ThemeButton text="Cancel" onClick={cancelDialogAdd} />
            <ThemeButton
              theme="blue"
              disabled={!name || !email || isSubmitting}
              text="Send invite"
              onClick={onClickSendInvite}
            />
          </DialogAction>
        }
      >
        <DialogContent>
          <DialogTitle>{t('Add Staff')}</DialogTitle>
          <TextFormContainer>
            <StyledTextForm
              isRequired
              placeholder="Name"
              title="Name"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </TextFormContainer>
          <TextFormContainer>
            <StyledTextForm
              isRequired
              placeholder="Email"
              title="Email address"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            {emailError && <ErrorMessage message={'invalidEmailMessage'} />}
          </TextFormContainer>
        </DialogContent>
      </DialogModal>

      <DialogModal
        isOpen={dialogDelete}
        closeModal={() => setDialogDelete(false)}
        footerNode={
          <DialogAction>
            <ThemeButton text="Cancel" onClick={() => setDialogDelete(false)} />
            <ThemeButton theme="alertRed" disabled={isSubmitting} text="Delete" onClick={onClickDeleteStaff} />
          </DialogAction>
        }
      >
        <DialogContent>
          <DialogTitle>{t('Dialog.Delete Staff')}</DialogTitle>
          <div>{`${t('Delete staff')} ${selectedStaffName}?`}</div>
        </DialogContent>
      </DialogModal>

      {isDesktopView && <BackNavigator title="Users" to={ROUTES.SETTINGS} />}

      <div css={styles.creatorContainer}>
        <div>{t('Creator')}</div>
        <div>
          <img alt="avatar" css={styles.avatar} height="64" src={defaultInfluencerAvatar(avatar)} width="64" />
          <span>{creatorName}</span>
        </div>
      </div>

      <div css={styles.addStaffContainer}>
        <div>{t('User Count', { staffCount: staffCount * currentPage, totalCount })}</div>
        <ThemeButton theme="blue" text="Add Staff" onClick={() => setDialogAdd(true)} />
      </div>

      <div css={styles.tableContainer}>
        {staffCount > 0 ? (
          !isDesktopView ? (
            staffs.map(staff => (
              <div css={styles.staffsContainer} key={staff!.id}>
                <Link to={generatePath(ROUTES.SETTINGS_USERS_USER_ID, { userId: staff.id })}>{staff!.name}</Link>
                <p>{staff?.email}</p>
              </div>
            ))
          ) : (
            <SliderComponents.SliderSection css={styles.sliderTableContainer}>
              <SliderComponents.SliderTable>
                <thead>
                  <SliderComponents.HeaderRow>
                    <StyledHeaderColumn title="Name" />
                    <StyledHeaderColumn title="Mail Address" />
                  </SliderComponents.HeaderRow>
                </thead>

                <tbody>
                  {staffs.map(staff => (
                    <StyledComponents.StyledRowNew css={styles.tableBodyRow} key={staff!.id}>
                      <td>
                        <Link to={`/settings/users/${staff!.id}`}>{staff!.name}</Link>
                      </td>
                      <td>
                        <div css={styles.emailContainer}>
                          <div>{staff!.email}</div>
                          <div>
                            <ThemeButton
                              css={styles.deleteBtn}
                              text="Delete"
                              onClick={() => onClickDelete(staff!.id)}
                            />
                          </div>
                        </div>
                      </td>
                    </StyledComponents.StyledRowNew>
                  ))}
                </tbody>
              </SliderComponents.SliderTable>
            </SliderComponents.SliderSection>
          )
        ) : (
          <EmptyTable css={styles.emptyTable} />
        )}
      </div>

      <SliderComponents.Pager
        currentPage={currentPage}
        first={firstIndex}
        last={lastIndex}
        totalCount={totalCount}
        totalPages={totalPages}
      />
    </div>
  );
};

const DialogAction = styled.div`
  background-color: #f6f8fa;
  display: flex;
  flex-basis: 100%;
  justify-content: flex-end;
  margin-top: 8px;
  padding: 16px;
  gap: 8px;

  & > button {
    width: max-content;

    @media (max-width: ${ViewportType.SMALL}px) {
      border-radius: 5px;
      width: 100%;
      height: 40px;
    }
  }
`;

const DialogContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 32px 16px 16px 16px;
`;

const DialogTitle = styled.div`
  color: #27313b;
  display: grid;
  flex-basis: 100%;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 24px;
`;

const StyledHeaderColumn = styled(HeaderColumn)`
  color: #27313b;
  border-bottom: 1px solid #dee5ec;
  font-size: 12px;
  font-weight: 600;
`;

const StyledTextForm = styled(TextForm)`
  & > label {
    font-size: 14px;
  }

  & input {
    border-radius: 0;
    height: 32px;
  }
`;

const TextFormContainer = styled.div`
  display: grid;
  flex-basis: 100%;
  margin-bottom: 16px;
`;

const styles = {
  addStaffContainer: css`
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #dee5ec;
    display: flex;
    justify-content: flex-end;
    height: 64px;
    padding: 8px 16px;

    & > div {
      color: #27313b;
      display: flex;
      flex: 1;
      font-size: 18px;
      font-weight: 600;
    }

    & > button {
      width: max-content;

      @media (max-width: ${ViewportType.SMALL}px) {
        border-radius: 5px;
      }
    }
  `,
  avatar: css`
    border-radius: 50%;
  `,
  container: css`
    margin: 16px 24px;

    @media (max-width: ${ViewportType.SMALL}px) {
      margin: 16px;
    }
  `,
  creatorContainer: css`
    background-color: #fff;
    margin: 24px 0;
    padding: 24px 16px;

    & > div:nth-of-type(1) {
      color: #27313b;
      font-size: 18px;
      font-weight: 600;
    }

    & > div:nth-of-type(2) {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      margin-top: 8px;

      & > span {
        color: #27313b;
        font-size: 14px;
        margin-left: 8px;
      }
    }
  `,
  deleteBtn: css`
    width: max-content;
  `,
  emailContainer: css`
    align-items: center;
    display: flex;

    /* stylelint-disable */
    & > div {
      display: grid;
      flex-basis: 50%;
    }
    /* stylelint-enable */

    & > div:nth-of-type(2) {
      justify-content: flex-end;
    }
  `,
  emptyTable: css`
    border: none;
    margin: 0;
  `,
  sliderTableContainer: css`
    width: 100%;
  `,
  staffsContainer: css`
    background-color: #fff;
    border-bottom: 1px solid #dee5ec;
    padding: 8px;

    & > a {
      color: #3892e5;
      font-size: 14px;
      margin-bottom: 8px;
    }

    & > p {
      color: #27313b;
      font-size: 14px;
    }
  `,
  tableBodyRow: css`
    & > td > a {
      color: #3892e5;
      font-size: 14px;
    }

    & > td:nth-of-type(1) {
      color: #3892e5;
      font-size: 14px;
    }

    &:hover {
      & > td {
        background-color: #fff;
      }
    }
  `,
  tableContainer: css`
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
  `,
};

export default Users;
