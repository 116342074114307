import { useQueryHelper } from '@src/libs/hooks';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { generatePath, ROUTES } from '@src/shared/routes';
import * as REMOVE_FORM_RESPONSE from './RemoveFormResponse.graphql';
import { RemoveFormResponse, RemoveFormResponseVariables } from './__generated__/RemoveFormResponse';

export default () => {
  const { t, enqueueSnackbar, history, params } = useQueryHelper();
  const { id } = params as { id: string; page: string };
  const [selectedSubmission, setSelectedSubmission] = useState<string | undefined>(undefined);

  const [removeFormResponseMutation] = useMutation<RemoveFormResponse, RemoveFormResponseVariables>(
    REMOVE_FORM_RESPONSE
  );

  const onClickDelete = (submissionId: string | undefined) => {
    setSelectedSubmission(submissionId);
  };

  const handleDelete = async () => {
    if (!!selectedSubmission) {
      try {
        await removeFormResponseMutation({
          variables: {
            input: {
              submissionId: Number(selectedSubmission),
            },
          },
        });
        history.push(generatePath(ROUTES.FORMS_EDIT_REPORT_RESPONDENT, { id }));
        enqueueSnackbar(t('succeededInDeleting'), { variant: 'success' });
      } catch (err) {
        const message = err?.message || 'failedToDelete';
        enqueueSnackbar(t(message), { variant: 'error' });
      } finally {
        closeDialog();
      }
    }
  };

  const closeDialog = () => {
    setSelectedSubmission(undefined);
  };

  return {
    selectedSubmission,
    closeDialog,
    onClickDelete,
    handleDelete,
  };
};
