import { useLocation } from 'react-router';
import { INITIAL_PAGE_NUMBER } from './constant';

export const getPageNumber = (params: URLSearchParams) => {
  const pageString = params.get('p');
  const pageNumber = Number(pageString) || INITIAL_PAGE_NUMBER;

  return pageNumber;
};

export const usePaging = (): number => {
  const { search } = useLocation();

  const params = new URLSearchParams(search);
  const currentPage = getPageNumber(params);

  return currentPage;
};
