import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import InitialLoading from '@src/components/molecules/InitialLoading';
import { useAuthSetup } from '@src/components/organisms/AuthSetUp/useAuthSetup';
import { setToken, ConnectType } from '@src/libs/auth';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { useQueryHelper, useRedirectWithinIframe } from '@src/libs/hooks';
import { useRedirectUrl } from '@src/libs/hooks/useRedirectUrl';
import { ROUTES } from '@src/shared/routes';
import { SocialAccountType } from '@src/__generated__/globalTypes';

const TiktokConnect = () => {
  const { enqueueSnackbar, search } = useQueryHelper();
  const { setUp } = useAuthSetup();
  const { getRedirectUrl } = useRedirectUrl(FE_REDIRECT_MAPPING.MOBILE_TIKTOK_CONNECT);
  const { startRedirectProcess } = useRedirectWithinIframe();
  const searchParams = new URLSearchParams(search);
  const socialAccountId = searchParams.get('id');
  const token = searchParams.get('token');

  if (!socialAccountId || !token) {
    return <Redirect to={ROUTES.SIGN_IN} />;
  }

  useEffect(() => {
    if (token) {
      setUpToken(token);
    }
  }, [token]);

  const setUpToken = async (accessToken: string) => {
    setToken(accessToken);
    try {
      await setUp(accessToken);
      const redirectUrl = await getRedirectUrl(SocialAccountType.TIKTOK);

      if (redirectUrl) {
        localStorage.setItem('connectType', ConnectType.RECONNECT_TIKTOK);
        localStorage.setItem('reconnectTiktokAccountId', socialAccountId as string);
        startRedirectProcess({ redirectUrl, redirectType: FE_REDIRECT_MAPPING.MOBILE_TIKTOK_CONNECT });
      }
    } catch (err) {
      enqueueSnackbar((err as { message: string }).message, { variant: 'error' });
    }
  };

  return <InitialLoading />;
};

export default TiktokConnect;
