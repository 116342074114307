import React, { useState, useMemo, useEffect } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ThemeButton } from '@src/components/atoms/Button';
import Dialog from '@src/components/molecules/Dialog';
import SingleSelectField from '@src/components/molecules/SingleSelectField';
import MultiTagsSearchSelector from '@src/components/molecules/MultiTagsSearchSelector';
import { OptionType } from '@src/components/molecules/MultiSearchSelector';
import { useMutation } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import CheckBoxForSelectAll from '@src/components/atoms/CheckBox/CheckBoxForSelectAll';
import * as ADD_FAN_TAGS from './AddFanTags.graphql';
import * as DELETE_FAN_TAGS from './DeleteFanTags.graphql';
import { AddFanTags, AddFanTagsVariables } from './__generated__/AddFanTags';
import { DeleteFanTags, DeleteFanTagsVariables } from './__generated__/DeleteFanTags';
import { SelectedTagsInfo } from './types';
import { getInitialTagsSelection } from './helper';

interface SelectionNoticeProps {
  selectedFansId: Set<number>;
  allSelectedTags: SelectedTagsInfo;
  totalFanCount: number;
  onConfirm: () => void;
  onSelectAll: () => void;
}

enum AllowedOperations {
  ADD = 'ADD',
  DELETE = 'DELETE',
}

const SelectionNotice = ({
  selectedFansId,
  allSelectedTags,
  totalFanCount,
  onConfirm,
  onSelectAll,
}: SelectionNoticeProps) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const [addFanTagsMutation] = useMutation<AddFanTags, AddFanTagsVariables>(ADD_FAN_TAGS, {
    refetchQueries: ['GetFans'],
  });
  const [deleteFanTagsMutation] = useMutation<DeleteFanTags, DeleteFanTagsVariables>(DELETE_FAN_TAGS, {
    refetchQueries: ['GetFans'],
  });
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedOption, setSelectedOption] = useState<AllowedOperations | undefined>(undefined);
  const [selectedTags, setSelectedTags] = useState<OptionType[]>([]);

  const handleOnExec = () => {
    setShowDeleteConfirmation(false);
    onConfirm();
  };

  const handleChange = (_: any, value: string) => {
    setSelectedOption(value as AllowedOperations);
  };

  const options = useMemo(
    () => [
      { label: t('Add Tags'), value: AllowedOperations.ADD },
      { label: t('Delete Tags'), value: AllowedOperations.DELETE },
    ],
    []
  );

  const handleExec = async () => {
    if (selectedOption === AllowedOperations.ADD) {
      try {
        await addFanTagsMutation({
          variables: {
            input: {
              fanIds: Array.from(selectedFansId),
              tags: selectedTags.map(item => item.label),
            },
          },
        });
        enqueueSnackbar(t('succeededInAddingTags'), { variant: 'success' });
      } catch (err) {
        const message = err?.message || 'failedToAdd';
        enqueueSnackbar(t(message), { variant: 'error' });
      } finally {
        setSelectedOption(undefined);
      }
    } else {
      try {
        await deleteFanTagsMutation({
          variables: {
            input: {
              fanIds: Array.from(selectedFansId),
              tagIds: selectedTags.map(item => Number(item.value)).filter(item => item),
            },
          },
        });
        enqueueSnackbar(t('succeededInDeletingTags'), { variant: 'success' });
      } catch (err) {
        const message = err?.message || 'failedToDelete';
        enqueueSnackbar(t(message), { variant: 'error' });
      } finally {
        setSelectedOption(undefined);
      }
    }
  };

  useEffect(() => {
    if (selectedOption === AllowedOperations.DELETE || selectedOption === AllowedOperations.ADD) {
      setSelectedTags(getInitialTagsSelection(allSelectedTags));
    } else {
      setSelectedTags([]);
    }
  }, [selectedOption]);

  return (
    <>
      <div css={styles.container}>
        <div css={styles.headerLeft}>
          <CheckBoxForSelectAll
            name="Checkbox"
            label="Selected"
            isCheckedAll={totalFanCount === selectedFansId.size}
            isCheckedNothing={selectedFansId.size === 0}
            interpolation={{ count: selectedFansId.size.toString() }}
            handleClick={onSelectAll}
          />
          <p css={styles.selectLink} onClick={onSelectAll}>
            {t('Select all items')}
          </p>
          <SingleSelectField
            name="Select"
            options={options}
            value={selectedOption}
            setFieldValue={handleChange}
            hideDeselectOption={true}
            css={styles.selector}
            placeholder="Other operations"
          />
        </div>
        <ThemeButton
          text="Delete"
          theme="red"
          borderRadius="5px"
          onClick={() => setShowDeleteConfirmation(true)}
          css={styles.button}
        />
      </div>

      <Dialog
        visible={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onExec={handleOnExec}
        execText="Delete"
        execColor="red"
      >
        <Heading>{t('Are you sure you want to permanently delete this?')}</Heading>
        <RegularText>{t('You might lost fans data forever. Before continuing, export fans')}</RegularText>
        <RegularText css={styles.warning}>
          {t('Do you want to delete this fan and lost information of the fans?')}
        </RegularText>
      </Dialog>

      <Dialog
        visible={selectedOption === AllowedOperations.ADD || selectedOption === AllowedOperations.DELETE}
        onClose={() => setSelectedOption(undefined)}
        onExec={handleExec}
        execText={selectedOption === AllowedOperations.ADD ? 'Add Tag' : 'Delete Tag'}
        execColor={selectedOption === AllowedOperations.DELETE ? 'red' : 'blue'}
        execDisabled={!selectedTags.length}
      >
        {selectedOption === AllowedOperations.ADD && (
          <>
            <Heading>{t('Add tags to selected files')}</Heading>
            <RegularText>{t('Search for tags or create new ones and tag the selected files')}</RegularText>
          </>
        )}
        {selectedOption === AllowedOperations.DELETE && (
          <>
            <Heading>{t('Remove tags from selected files')}</Heading>
            <RegularText>{t('Deletes the specified tags')}</RegularText>
          </>
        )}
        <MultiTagsSearchSelector
          isCreatable={selectedOption === AllowedOperations.ADD}
          placeholder="VIP,etc"
          value={selectedTags}
          // @ts-ignore type is OptionType[]
          setFieldValue={val => setSelectedTags(val)}
        />
      </Dialog>
    </>
  );
};

const styles = {
  container: css`
    height: 48px;
    background-color: #29323a;
    color: #fff;
    margin: 0 24px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  headerLeft: css`
    display: flex;
    align-items: center;
    gap: 16px;
  `,
  button: css`
    padding: 0 8px;
    width: fit-content;
  `,
  warning: css`
    color: #ff5f5f;
    margin-bottom: 0;
  `,
  selector: css`
    color: #6e7c89;

    & > label {
      font-size: 14px;
    }

    & > div > div {
      border-radius: 2px;
      min-height: 32px;

      & > div > div {
        height: auto;
      }
    }

    & p {
      display: none;
    }

    & input {
      border-radius: 2px;
      min-height: 32px;

      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #27313b;
        opacity: 1; /* Firefox */
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #27313b;
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #27313b;
      }
    }
  `,
  selectLink: css`
    font-size: 14px;
    color: #fff;
    text-decoration: underline;
    cursor: pointer;
  `,
};
const Heading = styled.h1`
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #27313b;
`;
const RegularText = styled.p`
  font-size: 14px;
  color: #27313b;
  margin-bottom: 16px;
`;

export default SelectionNotice;
