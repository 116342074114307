import styled from '@emotion/styled';
import * as React from 'react';
import { SignUpAndSignInBreakpoint } from '../../../libs/theme';

interface ButtonProps {
  title: string;
  handleButtonClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}
interface SwitcherProps {
  visible: boolean;
  handleSwitcherClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}
export type ChildProps = ButtonProps & SwitcherProps;

export interface LanguageMenuProps {
  visible: boolean;
  title: string;
  setInvisible: (event: React.MouseEvent<HTMLButtonElement>) => void;
  toggleVisible: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onRef: React.RefObject<HTMLDivElement>;
  children: (props: ChildProps) => React.ReactNode;
  className?: string;
}

const LanguageMenu = (props: LanguageMenuProps) => {
  const { visible, title, onRef, setInvisible, toggleVisible, children, className } = props;

  return (
    <Wrapper ref={onRef} className={className}>
      {children({ title, handleButtonClick: toggleVisible, visible, handleSwitcherClick: setInvisible })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    padding-right: 16px;
  }
`;

export default LanguageMenu;
