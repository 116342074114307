import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import ttcmInvitationImg from '@src/assets/img/castingMarketplace/ttcmInvitation.svg';
import ttcmPostVideoImg from '@src/assets/img/castingMarketplace/ttcmPostVideo.svg';
import ttcmUploadDraftVideoImg from '@src/assets/img/castingMarketplace/ttcmUploadDraftVideo.svg';
import searchJobBackgroundImg from '@src/assets/img/searchJobBackground.png';
import { DialogModal } from '@src/components/molecules/DialogModal';
import { ListIndicator } from '@src/components/molecules/Indicator';
import CampaignDetails from '@src/components/organisms/CastingMarketplace/YourJob/CampaignDetails';
import usePost, {
  CampaignInfluencerSocialAccounts_engagementPostSocialAccountsForInfluencer,
} from '@src/components/organisms/CastingMarketplace/YourJob/PostForm/usePost';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import { ConnectType } from '@src/libs/auth';
import { getSocialMediaUrlByCampaignType } from '@src/libs/campaign';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { usePageLayout, useQueryHelper, useRedirectWithinIframe } from '@src/libs/hooks';
import { useRedirectUrl } from '@src/libs/hooks/useRedirectUrl';
import { ViewportType } from '@src/libs/theme';
import {
  AnyXEngagementCampaignStatus,
  AuthSocialAccountType,
  CampaignSocialMediaType,
  CampaignStatusForInfluencer,
  SocialAccountType,
  TTCMStatus,
} from '@src/__generated__/globalTypes';
import { ThemeButton } from '@src/components/atoms/Button';
import { ROUTES, generatePath } from '@src/shared/routes';
import useCampaign from './useCampaign';

const Details = () => {
  const { isMobileView } = usePageLayout();
  const { enqueueSnackbar, history, params, search, t } = useQueryHelper();
  const searchParams = new URLSearchParams(search);
  const isUploadDialog = searchParams.get('uploadDialog');
  const [dialogOpen, setDialogOpen] = useState<boolean>(!!isUploadDialog || false);
  const { getRedirectUrl } = useRedirectUrl(FE_REDIRECT_MAPPING.TTCM_RECONNECT);
  const { startRedirectProcess } = useRedirectWithinIframe();
  const engagementId = Number(params.id);
  const { campaignDetails, loading } = useCampaign(engagementId);
  const { socialAccounts } = usePost(engagementId);
  const ttcmInvitationProcedure = [
    { description: 'TTCM invitation procedure one' },
    { description: 'TTCM invitation procedure two' },
    { description: 'TTCM invitation procedure three' },
    { description: 'TTCM invitation procedure four' },
  ];
  const ttcmPostVideoProcedure = [
    { description: 'TTCM post video procedure one' },
    { description: 'TTCM post video procedure two' },
    { description: 'TTCM post video procedure three' },
    { description: 'TTCM post video procedure four' },
  ];
  const ttcmUploadDraftVideoProcedure = [
    { description: 'TTCM upload draft video procedure one' },
    { description: 'TTCM upload draft video procedure two' },
    { description: 'TTCM upload draft video procedure three' },
    { description: 'TTCM upload draft video procedure four' },
  ];

  if (!loading && !campaignDetails) {
    history.push(ROUTES.CASTING_YOUR_JOB);
  }

  useEffect(() => {
    if (isUploadDialog) {
      searchParams.delete('uploadDialog');
      history.replace({ search: searchParams.toString() });
    }
  }, [isUploadDialog]);

  const hasDraftPosts = campaignDetails?.hasDraftPosts;
  const hasReport = campaignDetails?.hasReport;
  // TODO: to enable new flow when marketplace implement TTCM
  // const isTtcmCampaign = campaignDetails?.isTtcmCampaign;
  const isTtcmCampaign = false;
  const isCampaignFinish =
    campaignDetails?.anyXStatus === AnyXEngagementCampaignStatus.INEFFECTIVE ||
    campaignDetails?.status === CampaignStatusForInfluencer.FINISHED;
  const uploadDraftPost = campaignDetails?.status !== CampaignStatusForInfluencer.FINISHED && !hasDraftPosts;

  const { isNonTtcmAccounts, ttcmAccount } = socialAccounts.reduce(
    (accounts, value) => {
      const isTtcmAccount =
        isTtcmCampaign && value.socialMedia === SocialAccountType.TIKTOK && value.ttcmStatus === TTCMStatus.APPROVED;

      return {
        ...accounts,
        ...(!isTtcmAccount && { isNonTtcmAccounts: true }),
        // ...(isTtcmAccount && { ttcmAccount: value }),
      };
    },
    {
      isNonTtcmAccounts: false,
      ttcmAccount: null as CampaignInfluencerSocialAccounts_engagementPostSocialAccountsForInfluencer | null,
    }
  );

  const urlTiktokUsername = `${getSocialMediaUrlByCampaignType(CampaignSocialMediaType.TIKTOK)}${
    ttcmAccount ? `/@${ttcmAccount.username}` : ''
  }`;

  const isInvited = campaignDetails?.ttcmStatus === TTCMStatus.INVITED;
  const isTtcmInvitation =
    isTtcmCampaign && [TTCMStatus.INVITED, TTCMStatus.REJECTED].includes(campaignDetails?.ttcmStatus as TTCMStatus);
  const isTtcmPosting = isTtcmCampaign && campaignDetails?.anyXStatus === AnyXEngagementCampaignStatus.POSTING;

  const onClickDialog = () => {
    setDialogOpen(true);
  };

  const onCloseDialog = () => {
    setDialogOpen(false);
  };

  const onClickUploadDraftVideo = async () => {
    if (!campaignDetails?.isTtcmReady) {
      try {
        const redirectUrl = await getRedirectUrl(AuthSocialAccountType.TTCM);

        if (redirectUrl) {
          localStorage.setItem('campaignId', String(campaignDetails?.id));
          localStorage.setItem('connectType', ConnectType.RECONNECT_TTCM);
          localStorage.setItem('reconnectTiktokAccountId', String(ttcmAccount?.id));
          startRedirectProcess({ redirectUrl, redirectType: FE_REDIRECT_MAPPING.TTCM_RECONNECT });
        }
      } catch (err) {
        enqueueSnackbar(t(err.message), { variant: 'error' });
      }
    } else {
      setDialogOpen(true);
    }
  };

  return (
    <>
      <DialogModal
        closeIconCss={{
          backgroundColor: 'transparent',
          color: '#6e7C89',
          right: 0,
          top: 0,
        }}
        contentStyle={{
          border: 'none',
          borderRadius: 5,
          width: 600,
          height: 555,
          maxHeight: '90vh',
          maxWidth: '90%',
        }}
        footerNode={
          isTtcmInvitation && !isInvited && !isMobileView ? null : (
            <div css={styles.dialogActionContainer}>
              {isTtcmInvitation ? (
                isInvited ? (
                  <>
                    <ThemeButton text="Close" onClick={onCloseDialog} />
                    <ThemeButton href={urlTiktokUsername} targetBlank text="Open TikTok APP" theme="blue" />
                  </>
                ) : (
                  <ThemeButton css={{ width: 'fill-available' }} text="Close" onClick={onCloseDialog} />
                )
              ) : isTtcmPosting ? (
                <ThemeButton href={urlTiktokUsername} targetBlank text="Post Video" theme="blue" />
              ) : (
                <ThemeButton href={urlTiktokUsername} targetBlank text="Upload Draft Video" theme="blue" />
              )}
            </div>
          )
        }
        isOpen={dialogOpen}
        closeModal={onCloseDialog}
      >
        {isTtcmInvitation ? (
          isInvited ? (
            <div css={styles.dialogTtcmInvitation}>
              <div>{t('Title.Invitation from TikTok Creator Marketplace')}</div>
              <p>
                <Trans i18nKey="Annotation.To join campaign TikTok creator marketplace">
                  TTCM
                  {/* the paragraph need to be inline so not able to use Link component */}
                  <span
                    onClick={() =>
                      window.open(
                        'https://www.tiktok.com/creators/creator-portal/en-us/getting-paid-to-create/creator-marketplace/',
                        '_blank'
                      )
                    }
                  >
                    Learn more
                  </span>
                </Trans>
              </p>
              <div>
                <div>
                  <img height={isMobileView ? 122 : 130} src={ttcmInvitationImg} width={isMobileView ? 183 : 195} />
                </div>
                {!isMobileView && <div>{t('Procedure')}</div>}
                <InstructionContainer>
                  {ttcmInvitationProcedure.map((procedure, procedureIndex) => (
                    <div key={procedureIndex}>
                      <div>{`${procedureIndex + 1}. `}</div>
                      <div>
                        <Trans i18nKey={procedure.description}>
                          description <span css={{ fontWeight: 600 }}>hint</span>
                        </Trans>
                      </div>
                    </div>
                  ))}
                </InstructionContainer>
              </div>
            </div>
          ) : (
            <div css={styles.dialogTemporaryClosedContainer}>
              <div>
                <div>
                  <img
                    height={isMobileView ? 124 : 115}
                    src={searchJobBackgroundImg}
                    width={isMobileView ? 120 : 111}
                  />
                </div>
                <div>
                  <div>{t('Sorry')}</div>
                  <div>{t('Annotation.The campaign is temporarily closed')}</div>
                </div>
                <div>{t('Annotation.Please wait until the campaign information will be updated')}</div>
                {!isMobileView && (
                  <div>
                    <ThemeButton text="Close" onClick={onCloseDialog} />
                  </div>
                )}
              </div>
            </div>
          )
        ) : (
          <div css={styles.dialogUploadDraftVideoContainer}>
            <div>
              <img height="120" src={isTtcmPosting ? ttcmPostVideoImg : ttcmUploadDraftVideoImg} width="180" />
            </div>
            <div>{t(isTtcmPosting ? `Title.Let's post video` : `Title.Let's upload draft video`)}</div>
            <div>
              {t(
                isTtcmPosting
                  ? 'Annotation.Your video has been approved and you can now post it'
                  : 'Annotation.To post a video, you need to open TikTok app and upload a video'
              )}
            </div>
            <InstructionContainer>
              {(isTtcmPosting ? ttcmPostVideoProcedure : ttcmUploadDraftVideoProcedure).map(
                (procedure, procedureIndex) => (
                  <div key={procedureIndex}>
                    <div>{`${procedureIndex + 1}.`}</div>
                    <div>
                      <Trans i18nKey={procedure.description}>
                        description <span css={{ fontWeight: 600 }}>hint</span>
                      </Trans>
                    </div>
                  </div>
                )
              )}
            </InstructionContainer>
          </div>
        )}
      </DialogModal>

      <div css={styles.container}>
        {!isMobileView && !loading && (
          <div css={styles.headerContainer}>
            <div>
              <BackNavigator
                title="Campaigns Detail"
                to={`/casting/your_job${
                  campaignDetails?.status === CampaignStatusForInfluencer.FINISHED ? '#finished' : ''
                }`}
              />
            </div>
            <div>
              {isTtcmInvitation ? (
                <ThemeButton text="Accept Invitation" theme="blue" onClick={onClickDialog} />
              ) : hasReport ? (
                <>
                  <ThemeButton
                    text={isTtcmCampaign ? 'Video List' : 'Post List'}
                    href={generatePath(ROUTES.CASTING_YOUR_JOB_ID_POST, { id: engagementId })}
                    width="max-content"
                  />
                  <ThemeButton
                    text={isTtcmCampaign ? 'Check Report' : 'View Report'}
                    href={generatePath(ROUTES.CASTING_YOUR_JOB_ID_REPORT, { id: engagementId })}
                    width="max-content"
                  />
                </>
              ) : !isCampaignFinish && hasDraftPosts ? (
                <>
                  <ThemeButton
                    text={isTtcmCampaign ? 'Video List' : 'Post List'}
                    href={generatePath(ROUTES.CASTING_YOUR_JOB_ID_POST, { id: engagementId })}
                    width="max-content"
                  />
                  {isTtcmPosting && <ThemeButton text="Post Now" theme="blue" onClick={onClickDialog} />}
                </>
              ) : uploadDraftPost ? (
                <>
                  {isNonTtcmAccounts && (
                    <ThemeButton
                      href={generatePath(ROUTES.CASTING_YOUR_JOB_ID_POST_ADD, { id: engagementId })}
                      text="Upload Draft Post"
                      width="max-content"
                    />
                  )}
                  {!!ttcmAccount && (
                    <ThemeButton
                      text="Upload Draft Video"
                      theme="blue"
                      width="max-content"
                      onClick={onClickUploadDraftVideo}
                    />
                  )}
                </>
              ) : null}
            </div>
          </div>
        )}

        {loading ? <ListIndicator /> : campaignDetails && <CampaignDetails campaignDetails={campaignDetails} />}
      </div>

      {isMobileView &&
        !loading &&
        (isTtcmInvitation || uploadDraftPost || (!isCampaignFinish && hasDraftPosts) || hasReport) && (
          <div css={styles.actionContainer}>
            <div>
              {isTtcmInvitation ? (
                <ThemeButton text="Accept Invitation" theme="blue" onClick={onClickDialog} />
              ) : hasReport ? (
                <>
                  <ThemeButton
                    text={isTtcmCampaign ? 'Video List' : 'Post List'}
                    href={generatePath(ROUTES.CASTING_YOUR_JOB_ID_POST, { id: engagementId })}
                    size="large"
                  />
                  <ThemeButton
                    text={isTtcmCampaign ? 'Check Report' : 'View Report'}
                    href={generatePath(ROUTES.CASTING_YOUR_JOB_ID_REPORT, { id: engagementId })}
                    size="large"
                  />
                </>
              ) : !isCampaignFinish && hasDraftPosts ? (
                <>
                  <ThemeButton
                    text={isTtcmCampaign ? 'Video List' : 'Post List'}
                    href={generatePath(ROUTES.CASTING_YOUR_JOB_ID_POST, { id: engagementId })}
                    size="large"
                  />
                  {isTtcmPosting && <ThemeButton text="Post Now" theme="blue" onClick={onClickDialog} />}
                </>
              ) : uploadDraftPost ? (
                <>
                  {isNonTtcmAccounts && (
                    <ThemeButton
                      href={generatePath(ROUTES.CASTING_YOUR_JOB_ID_POST_ADD, { id: engagementId })}
                      size="large"
                      text="Upload Draft Post"
                    />
                  )}
                  {!!ttcmAccount && (
                    <ThemeButton
                      size="large"
                      text="Upload Draft Video"
                      theme="blue"
                      onClick={onClickUploadDraftVideo}
                    />
                  )}
                </>
              ) : null}
            </div>
          </div>
        )}
    </>
  );
};

const InstructionContainer = styled.div`
  color: #27313b;
  display: grid;
  gap: 8px;
  font-size: 13px;

  @media (max-width: ${ViewportType.TABLET}px) {
    font-size: 14px;
  }

  & > div {
    display: flex;
    gap: 4px;
  }
`;

const styles = {
  actionContainer: css`
    align-items: center;
    background-color: #fff;
    bottom: 0;
    box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.2);
    display: flex;
    height: 72px;
    position: fixed;
    width: 100%;

    & > div {
      display: flex;
      justify-content: flex-end;
      margin: 0 16px;
      width: 100%;
      gap: 16px;

      & > button {
        @media (max-width: ${ViewportType.TABLET}px) {
          height: 40px;
        }
      }
    }
  `,
  container: css`
    margin: 16px;

    @media (max-width: ${ViewportType.TABLET}px) {
      margin: 0;
    }
  `,
  dialogActionContainer: css`
    background-color: #fff;
    border-top: 1px solid #dee5ec;
    bottom: 0;
    display: flex;
    gap: 8px;
    left: 0;
    justify-content: flex-end;
    padding: 16px 24px;
    position: absolute;
    width: fill-available;

    /* stylelint-disable no-descending-specificity */
    & > button,
    a {
      width: fit-content;

      @media (max-width: ${ViewportType.TABLET}px) {
        height: 40px;
        width: fill-available;
      }
    }
  `,
  dialogTemporaryClosedContainer: css`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 0 32px;

    & > div {
      & > div:nth-of-type(1) {
        display: flex;
        justify-content: center;
        margin-bottom: 32px;

        @media (max-width: ${ViewportType.TABLET}px) {
          margin-bottom: 24px;
        }
      }

      & > div:nth-of-type(2) {
        display: flex;
        margin-bottom: 16px;
        justify-content: center;

        @media (max-width: ${ViewportType.TABLET}px) {
          display: grid;
        }

        & > div:nth-of-type(1) {
          color: #27313b;
          display: flex;
          font-size: 18px;
          justify-content: center;

          @media (max-width: ${ViewportType.TABLET}px) {
            font-size: 22px;
            margin-bottom: 4px;
          }
        }

        & > div:nth-of-type(2) {
          color: #27313b;
          font-size: 18px;

          @media (max-width: ${ViewportType.TABLET}px) {
            font-size: 14px;
          }
        }
      }

      & > div:nth-of-type(3) {
        color: #6e7c89;
        font-size: 14px;
        margin-bottom: 24px;
        text-align: center;
      }

      & > div:nth-of-type(4) {
        display: flex;
        justify-content: center;

        & button {
          height: 40px;

          @media (min-width: ${ViewportType.TABLET}px) {
            height: 32px;
            width: fit-content;
          }
        }

        @media (max-width: ${ViewportType.TABLET}px) {
          border-top: 1px solid #dee5ec;
          bottom: 0;
          left: 0;
          padding: 16px;
          position: absolute;
          width: fill-available;
        }
      }
    }
  `,
  dialogTtcmInvitation: css`
    padding: 40px 32px 32px 32px;

    & > div:nth-of-type(1) {
      color: #27313b;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 16px;
    }

    & > div:nth-of-type(2) {
      border: 1px solid #dee5ec;
      border-radius: 5px;
      padding: 32px;
      width: fill-available;

      & > div:nth-of-type(1) {
        display: flex;
        justify-content: center;
      }

      & > div:nth-of-type(2) {
        @media (min-width: ${ViewportType.TABLET}px) {
          color: #27313b;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 8px;
        }
      }
    }

    & > p {
      color: #27313b;
      font-size: 13px;
      font-weight: 400px;
      margin-bottom: 24px;

      @media (min-width: ${ViewportType.TABLET}px) {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
      }

      & > span {
        color: #3892e5;
        cursor: pointer;
        text-decoration: underline;

        @media (min-width: ${ViewportType.TABLET}px) {
          display: flex;
          flex-basis: 100%;
        }
      }
    }
  `,
  dialogUploadDraftVideoContainer: css`
    padding: 34px 16px;
    text-align: center;

    & > div:nth-of-type(1) {
      display: flex;
      justify-content: center;
      margin-bottom: 16px;
    }

    & > div:nth-of-type(2) {
      color: #27313b;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    & > div:nth-of-type(3) {
      color: #27313b;
      font-size: 14px;
      margin-bottom: 24px;
    }

    & > div:nth-of-type(4) {
      border: 1px solid #dee5ec;
      border-radius: 5px;
      padding: 24px 16px;
      text-align: left;
      width: fill-available;
    }
  `,
  headerContainer: css`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
    justify-content: space-between;

    & > div {
      display: flex;
      gap: 8px;

      & > div {
        color: #27313b;
        font-size: 13px;
      }
    }
  `,
};

export default Details;
