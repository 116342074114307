import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { MENA_COUNTRIES } from '@src/libs/constant';
import { getBankOptions, getOptions } from '@src/libs/form';
import { useAuthData, useDeepCompareEffect, useQueryHelper } from '@src/libs/hooks';
import { JPPaymentInformationSchema, PaymentInformationSchema } from '@src/libs/validation';
import { paymentState as paymentRecoilState, useRecoilState } from '@src/recoilAtoms';
import { ROUTES } from '@src/shared/routes';
import UPDATE_PAYMENT_INFORMATION from './mutations/UpdateInfluencerPaymentInformation.graphql';
import {
  UpdateInfluencerPaymentInformation,
  UpdateInfluencerPaymentInformationVariables,
} from './mutations/__generated__/UpdateInfluencerPaymentInformation';
import PaymentInformation, { Information } from './PaymentInformation';
import INFLUENCER_INFORMATION from './queries/InfluencerInformation.graphql';
import { InfluencerInformation, InfluencerInformationVariables } from './queries/__generated__/InfluencerInformation';

const Index = () => {
  const [paymentState, setPaymentState] = useRecoilState(paymentRecoilState);
  const { userId } = useAuthData();
  const { enqueueSnackbar, history, t } = useQueryHelper();

  useEffect(
    () => () => {
      if (paymentState.isNavigateFromPayment) {
        setPaymentState({ ...paymentState, isNavigateFromPayment: false });
      }
    },
    []
  );

  const { data, loading } = useQuery<InfluencerInformation, InfluencerInformationVariables>(INFLUENCER_INFORMATION, {
    fetchPolicy: 'no-cache',
    variables: {
      pk: userId as number,
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const [updatePaymentInformation] = useMutation<
    UpdateInfluencerPaymentInformation,
    UpdateInfluencerPaymentInformationVariables
  >(UPDATE_PAYMENT_INFORMATION, {
    onCompleted: () => {
      enqueueSnackbar(t('succeededInUpdating'), { variant: 'success' });
      sendAmplitudeEvent(eventTypes.editSettingsPayment);
      if (paymentState.isNavigateFromPayment) {
        history.push(ROUTES.PAYMENT);
      }
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const onSubmit = async (values: Information) => {
    const {
      accountName,
      accountNumber,
      address,
      bankId,
      branchId,
      branchName,
      cityId,
      email,
      fullName,
      iban,
      postalCode,
      subject,
      swiftCode,
    } = values;

    updatePaymentInformation({
      variables: {
        input: {
          accountName,
          accountNumber,
          address,
          bankId: Number(bankId),
          branchId: branchId ? Number(branchId) : null,
          branchName,
          cityId: cityId ? Number(cityId) : null,
          email,
          fullName,
          iban,
          postalCode,
          subject,
          swiftCode,
        },
      },
    });
  };

  const allBanks = data?.allBanksForInfluencer ? getBankOptions(data.allBanksForInfluencer) : [];
  const allCities = data?.allCitiesForInfluencer ? getOptions(data.allCitiesForInfluencer) : [];
  const country = data?.influencerProfileV2?.country;
  const hasBranchId = allBanks.some(({ hasBranches }) => hasBranches);
  const isMenaInfluencer = country ? !!MENA_COUNTRIES.find(menaCountry => menaCountry.value === country.id) : false;
  const isJPInfluencer = country?.id === 'JP';
  const paymentInformation = data?.influencerPaymentInformation;
  const defaultValues = {
    accountName: paymentInformation?.accountName || '',
    accountNumber: paymentInformation?.accountNumber || '',
    address: paymentInformation?.address || '',
    bankId: paymentInformation?.bankId?.toString() || '',
    branchId: paymentInformation?.branchId?.toString() || '',
    branchName: paymentInformation?.branchName || '',
    cityId: paymentInformation?.cityId?.toString() || '',
    email: data?.influencerPaymentInformation?.paymentEmail || '',
    fullName: paymentInformation?.fullName || '',
    hasBranchId,
    iban: paymentInformation?.iban || '',
    isMenaInfluencer,
    postalCode: paymentInformation?.postalCode || '',
    subject: paymentInformation?.subject || '',
    swiftCode: paymentInformation?.swiftCode || '',
  };

  const methods = useForm<Information>({
    defaultValues,
    resolver: yupResolver(isJPInfluencer ? JPPaymentInformationSchema : PaymentInformationSchema),
  });

  useDeepCompareEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <PaymentInformation
          allBanks={allBanks}
          allCities={allCities}
          isLoading={loading}
          isMenaInfluencer={isMenaInfluencer}
          isJPInfluencer={isJPInfluencer}
        />
      </form>
    </FormProvider>
  );
};

export default Index;
