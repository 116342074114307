/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AnyXAuthSocialAccountType {
  APPLE = "APPLE",
  FACEBOOK = "FACEBOOK",
  INSTAGRAM = "INSTAGRAM",
  TIKTOK = "TIKTOK",
  TWITTER = "TWITTER",
  YOUTUBE = "YOUTUBE",
}

/**
 * An enumeration.
 */
export enum AnyXEngagementCampaignStatus {
  DRAFTING = "DRAFTING",
  EFFECTIVE = "EFFECTIVE",
  INEFFECTIVE = "INEFFECTIVE",
  POSTING = "POSTING",
  REVIEWING = "REVIEWING",
  WARNING_EFFECTIVE = "WARNING_EFFECTIVE",
  WARNING_REVIEWING = "WARNING_REVIEWING",
}

/**
 * An enumeration.
 */
export enum AnyXMarketplaceCampaignStatus {
  APPROVED = "APPROVED",
  ASSESSING = "ASSESSING",
  EFFECTIVE = "EFFECTIVE",
  INEFFECTIVE = "INEFFECTIVE",
  OUT_OF_COUPON = "OUT_OF_COUPON",
  POSTING = "POSTING",
  REVIEWING = "REVIEWING",
  SHIPPED = "SHIPPED",
  WAITING_FOR_SHIPMENT = "WAITING_FOR_SHIPMENT",
  WARNING_EFFECTIVE = "WARNING_EFFECTIVE",
}

/**
 * An enumeration.
 */
export enum AppName {
  ANYCREATOR = "ANYCREATOR",
  ANYTAG = "ANYTAG",
}

export enum AuthSocialAccountType {
  FACEBOOK = "FACEBOOK",
  TIKTOK = "TIKTOK",
  TTCM = "TTCM",
  TWITTER = "TWITTER",
  YOUTUBE = "YOUTUBE",
}

export enum BioButtonStyle {
  BLACK_FULL_ROUNDED_EDGE_BUTTON = "BLACK_FULL_ROUNDED_EDGE_BUTTON",
  BLACK_ROUNDED_EDGE_BUTTON = "BLACK_ROUNDED_EDGE_BUTTON",
  BLACK_SHARP_EDGE_BUTTON = "BLACK_SHARP_EDGE_BUTTON",
  WHITE_FULL_ROUNDED_EDGE_BUTTON = "WHITE_FULL_ROUNDED_EDGE_BUTTON",
  WHITE_ROUNDED_EDGE_BUTTON = "WHITE_ROUNDED_EDGE_BUTTON",
  WHITE_SHARP_EDGE_BUTTON = "WHITE_SHARP_EDGE_BUTTON",
}

export enum BioFontStyle {
  KAISEI_DECOL = "KAISEI_DECOL",
  KOSUGI = "KOSUGI",
  M_PLUS_1 = "M_PLUS_1",
  NOTO_SANS_JP = "NOTO_SANS_JP",
  NOTO_SERIF_JP = "NOTO_SERIF_JP",
  POTTA_ONE = "POTTA_ONE",
  SAWARABI_MINCHO = "SAWARABI_MINCHO",
  SHIPPORI_MINCHO = "SHIPPORI_MINCHO",
  TRAIN_ONE = "TRAIN_ONE",
}

export enum BioLinkIcon {
  BOOK = "BOOK",
  CALENDAR = "CALENDAR",
  CART = "CART",
  CHAT = "CHAT",
  CLOUD = "CLOUD",
  COFFEE = "COFFEE",
  DOCUMENT = "DOCUMENT",
  ENVELOPE = "ENVELOPE",
  HEART = "HEART",
  HOUSE = "HOUSE",
  KEY = "KEY",
  LOCATION = "LOCATION",
  MOON = "MOON",
  MUSIC_NOTE = "MUSIC_NOTE",
  PHONE = "PHONE",
  SMILE_EMOJI = "SMILE_EMOJI",
  STAR = "STAR",
  SUN = "SUN",
  USER = "USER",
  VIDEO_CAMERA = "VIDEO_CAMERA",
}

export enum BioSectionType {
  IMAGE = "IMAGE",
  LINK = "LINK",
  PROFILE = "PROFILE",
  SOCIAL_MEDIA = "SOCIAL_MEDIA",
  TEXT = "TEXT",
}

export enum BioSocialMediaType {
  FACEBOOK = "FACEBOOK",
  INSTAGRAM = "INSTAGRAM",
  LINE = "LINE",
  TIKTOK = "TIKTOK",
  TWITTER = "TWITTER",
  YOUTUBE = "YOUTUBE",
}

export enum BioTheme {
  THEME_1 = "THEME_1",
  THEME_10 = "THEME_10",
  THEME_11 = "THEME_11",
  THEME_2 = "THEME_2",
  THEME_3 = "THEME_3",
  THEME_4 = "THEME_4",
  THEME_5 = "THEME_5",
  THEME_6 = "THEME_6",
  THEME_7 = "THEME_7",
  THEME_8 = "THEME_8",
  THEME_9 = "THEME_9",
}

export enum BioThemeV2 {
  THEME2_1 = "THEME2_1",
  THEME2_10 = "THEME2_10",
  THEME2_11 = "THEME2_11",
  THEME2_12 = "THEME2_12",
  THEME2_13 = "THEME2_13",
  THEME2_14 = "THEME2_14",
  THEME2_15 = "THEME2_15",
  THEME2_16 = "THEME2_16",
  THEME2_17 = "THEME2_17",
  THEME2_2 = "THEME2_2",
  THEME2_3 = "THEME2_3",
  THEME2_4 = "THEME2_4",
  THEME2_5 = "THEME2_5",
  THEME2_6 = "THEME2_6",
  THEME2_7 = "THEME2_7",
  THEME2_8 = "THEME2_8",
  THEME2_9 = "THEME2_9",
}

export enum CRMChatTypeFilter {
  ACTIVATED = "ACTIVATED",
  ASSIGNED_TO_ME = "ASSIGNED_TO_ME",
}

/**
 * An enumeration.
 */
export enum CampaignPostStatusForInfluencer {
  EFFECTIVE = "EFFECTIVE",
  INEFFECTIVE = "INEFFECTIVE",
  REVIEWING = "REVIEWING",
  WARNING = "WARNING",
}

export enum CampaignPromotionMethod {
  EMAIL_NEWSLETTER = "EMAIL_NEWSLETTER",
  FACEBOOK = "FACEBOOK",
  FACEBOOK_PAGE = "FACEBOOK_PAGE",
  INSTAGRAM = "INSTAGRAM",
  INSTAGRAM_STORY = "INSTAGRAM_STORY",
  MOBILE_APP = "MOBILE_APP",
  OFFLINE = "OFFLINE",
  OTHER_SOCIAL_MEDIA = "OTHER_SOCIAL_MEDIA",
  PODCAST = "PODCAST",
  TIKTOK = "TIKTOK",
  TWITTER = "TWITTER",
  WEBSITE = "WEBSITE",
  YOUTUBE = "YOUTUBE",
}

/**
 * An enumeration.
 */
export enum CampaignSocialMediaType {
  FACEBOOK = "FACEBOOK",
  INSTAGRAM = "INSTAGRAM",
  INSTAGRAM_STORY = "INSTAGRAM_STORY",
  OTHER = "OTHER",
  TIKTOK = "TIKTOK",
  TWITTER = "TWITTER",
  UNSELECT = "UNSELECT",
  YOUTUBE = "YOUTUBE",
}

/**
 * An enumeration.
 */
export enum CampaignStatusForInfluencer {
  FINISHED = "FINISHED",
  ONGOING = "ONGOING",
  OUT_OF_COUPON = "OUT_OF_COUPON",
  SUSPENDED = "SUSPENDED",
  UPCOMING = "UPCOMING",
}

/**
 * An enumeration.
 */
export enum CampaignType {
  AFFILIATE = "AFFILIATE",
  AUTO_MANAGED = "AUTO_MANAGED",
  ENGAGEMENT = "ENGAGEMENT",
  MARKETPLACE = "MARKETPLACE",
}

export enum ChangeType {
  ADDRESS1 = "ADDRESS1",
  ADDRESS2 = "ADDRESS2",
  BIRTHDAY = "BIRTHDAY",
  CITY = "CITY",
  COMMENT = "COMMENT",
  COUNTRY_ID = "COUNTRY_ID",
  EMAIL = "EMAIL",
  FIRST_NAME = "FIRST_NAME",
  GENDER = "GENDER",
  LAST_NAME = "LAST_NAME",
  ORDER_ID = "ORDER_ID",
  PHONE = "PHONE",
  PROVINCE = "PROVINCE",
  TAGS = "TAGS",
  ZIP = "ZIP",
}

export enum CustomerTagOrder {
  BY_ALPHABETICAL = "BY_ALPHABETICAL",
  BY_FREQUENTLY_USED = "BY_FREQUENTLY_USED",
}

export enum DeliveryTimingType {
  IMMEDIATELY_SEND = "IMMEDIATELY_SEND",
  SCHEDULE_SEND = "SCHEDULE_SEND",
}

export enum DisplayLanguage {
  ARABIC = "ARABIC",
  BAHASA = "BAHASA",
  CAMBODIA = "CAMBODIA",
  ENGLISH = "ENGLISH",
  JAPANESE = "JAPANESE",
  MYANMAR = "MYANMAR",
  SIMPLIFIED_CHINESE = "SIMPLIFIED_CHINESE",
  THAI = "THAI",
  TRADITIONAL_CHINESE = "TRADITIONAL_CHINESE",
  VIETNAMESE = "VIETNAMESE",
}

export enum EmailEventStatus {
  BLOCKED = "BLOCKED",
  BOUNCE = "BOUNCE",
  CLICK = "CLICK",
  DEFERRED = "DEFERRED",
  DELIVERED = "DELIVERED",
  DROPPED = "DROPPED",
  GROUP_RESUBSCRIBE = "GROUP_RESUBSCRIBE",
  GROUP_UNSUBSCRIBE = "GROUP_UNSUBSCRIBE",
  OPEN = "OPEN",
  PROCESSED = "PROCESSED",
  SPAMREPORT = "SPAMREPORT",
  UNSUBSCRIBE = "UNSUBSCRIBE",
}

export enum EmailEventStatusForSearch {
  BOUNCE = "BOUNCE",
  CLICK = "CLICK",
  OPEN = "OPEN",
}

export enum EmailListSortableField {
  CLICK_RATE = "CLICK_RATE",
  CREATED = "CREATED",
  OPEN_RATE = "OPEN_RATE",
}

export enum EmailTemplateStatus {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
  SCHEDULED = "SCHEDULED",
}

/**
 * An enumeration.
 */
export enum EngagementCampaignPostStatus {
  APPROVED = "APPROVED",
  EFFECTIVE = "EFFECTIVE",
  INEFFECTIVE = "INEFFECTIVE",
  READY_TO_POST = "READY_TO_POST",
  REJECT = "REJECT",
  REVIEWING = "REVIEWING",
  TTCM_REVIEWING = "TTCM_REVIEWING",
  WARNING = "WARNING",
  WARNING_SOLVED = "WARNING_SOLVED",
}

export enum FacebookPageAnalyticsPostsSortOrder {
  AD_IMPRESSIONS = "AD_IMPRESSIONS",
  COMMENT = "COMMENT",
  ENGAGEMENT = "ENGAGEMENT",
  NEGATIVE_RATE = "NEGATIVE_RATE",
  POSITIVE_RATE = "POSITIVE_RATE",
  POST_DATE = "POST_DATE",
  REACTIONS = "REACTIONS",
  SHARE = "SHARE",
  VIEWS = "VIEWS",
}

export enum FanActivityType {
  COMMENT_CREATE = "COMMENT_CREATE",
  FAN_CREATE = "FAN_CREATE",
  FAN_UPDATE = "FAN_UPDATE",
  ORDER_CANCEL = "ORDER_CANCEL",
  ORDER_CREATE = "ORDER_CREATE",
  ORDER_UPDATE = "ORDER_UPDATE",
}

export enum FanGender {
  FEMALE = "FEMALE",
  MALE = "MALE",
  OTHER = "OTHER",
}

export enum FanListSortableField {
  CREATED = "CREATED",
}

export enum FilterByDateRange {
  LAST_12_MONTHS = "LAST_12_MONTHS",
  LAST_30_DAYS = "LAST_30_DAYS",
  LAST_7_DAYS = "LAST_7_DAYS",
  LAST_90_DAYS = "LAST_90_DAYS",
}

export enum FontSize {
  LG = "LG",
  MD = "MD",
  SM = "SM",
  XL = "XL",
  XXL = "XXL",
}

export enum FormListSortableField {
  CREATED = "CREATED",
  TITLE = "TITLE",
  UPDATED = "UPDATED",
}

export enum FormStatus {
  DRAFT = "DRAFT",
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC",
}

export enum Genders {
  FEMALE = "FEMALE",
  MALE = "MALE",
  NOT_APPLICABLE = "NOT_APPLICABLE",
  UNKNOWN = "UNKNOWN",
  UPDATE_LATER = "UPDATE_LATER",
}

/**
 * An enumeration.
 */
export enum InfluencerType {
  NORMAL = "NORMAL",
  PRO = "PRO",
}

/**
 * An enumeration.
 */
export enum InstagramFeedPostSortField {
  COMMENT = "COMMENT",
  ENGAGEMENT = "ENGAGEMENT",
  ENGAGEMENT_RATE = "ENGAGEMENT_RATE",
  FOLLOW = "FOLLOW",
  IMPRESSIONS = "IMPRESSIONS",
  IMPRESSION_FROM_DISCOVERY = "IMPRESSION_FROM_DISCOVERY",
  IMPRESSION_FROM_HASHTAG = "IMPRESSION_FROM_HASHTAG",
  INTERACTION = "INTERACTION",
  LIKE = "LIKE",
  NON_FOLLOWER_REACH = "NON_FOLLOWER_REACH",
  POST_DATE = "POST_DATE",
  PRODUCT_BUTTON_CLICK = "PRODUCT_BUTTON_CLICK",
  PRODUCT_PAGE_VIEW = "PRODUCT_PAGE_VIEW",
  REACH = "REACH",
  SAVED = "SAVED",
  SHARE = "SHARE",
  VIEW = "VIEW",
}

/**
 * An enumeration.
 */
export enum InstagramInteractionUserSortField {
  COMMENT = "COMMENT",
  LIKE = "LIKE",
  MENTIONED_COMMENT = "MENTIONED_COMMENT",
  MENTIONED_LIKE = "MENTIONED_LIKE",
  MENTIONED_POST = "MENTIONED_POST",
  POST = "POST",
  TAGGED_COMMENT = "TAGGED_COMMENT",
  TAGGED_LIKE = "TAGGED_LIKE",
  TAGGED_POST = "TAGGED_POST",
}

/**
 * An enumeration.
 */
export enum InstagramReelPostSortField {
  COMMENT = "COMMENT",
  LIKES = "LIKES",
  POST_DATE = "POST_DATE",
  REACH = "REACH",
  SAVED = "SAVED",
  SHARES = "SHARES",
  VIEWS = "VIEWS",
}

/**
 * An enumeration.
 */
export enum InstagramStoryPostSortField {
  COMMENT = "COMMENT",
  EXITS = "EXITS",
  FOLLOW = "FOLLOW",
  IMPRESSIONS = "IMPRESSIONS",
  INTERACTION = "INTERACTION",
  LINK_CLICKS = "LINK_CLICKS",
  POST_DATE = "POST_DATE",
  PRODUCT_BUTTON_CLICK = "PRODUCT_BUTTON_CLICK",
  PRODUCT_PAGE_VIEW = "PRODUCT_PAGE_VIEW",
  REACH = "REACH",
  STICKER_TAPS = "STICKER_TAPS",
  TAPS_BACK = "TAPS_BACK",
  TAPS_FORWARD = "TAPS_FORWARD",
}

export enum LineBroadcastStatus {
  DELIVERED = "DELIVERED",
  DRAFT = "DRAFT",
  ERROR = "ERROR",
  SCHEDULED = "SCHEDULED",
  SENDING = "SENDING",
}

export enum LineMessageType {
  BUTTON = "BUTTON",
  IMAGES = "IMAGES",
  IMAGE_VIDEO = "IMAGE_VIDEO",
  PLAIN_TEXT = "PLAIN_TEXT",
  RICH = "RICH",
}

/**
 * An enumeration.
 */
export enum MarketplaceCampaignAppliedStatus {
  APPLYING = "APPLYING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

/**
 * An enumeration.
 */
export enum MarketplaceCampaignPostStatus {
  AFFILIATE = "AFFILIATE",
  APPROVED = "APPROVED",
  EFFECTIVE = "EFFECTIVE",
  INEFFECTIVE = "INEFFECTIVE",
  SHIPPED = "SHIPPED",
  TRACKING = "TRACKING",
  WARNING = "WARNING",
  WARNING_SOLVED = "WARNING_SOLVED",
}

/**
 * An enumeration.
 */
export enum MarketplaceCampaignType {
  AFFILIATE = "AFFILIATE",
  AFFILIATE_SPECIAL_DEAL = "AFFILIATE_SPECIAL_DEAL",
  NORMAL = "NORMAL",
  SELECTION = "SELECTION",
}

export enum MediaType {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}

export enum NotificationId {
  ENGAGEMENT_DRAFT_POST_APPROVED = "ENGAGEMENT_DRAFT_POST_APPROVED",
  ENGAGEMENT_DRAFT_POST_REJECTED = "ENGAGEMENT_DRAFT_POST_REJECTED",
  ENGAGEMENT_FETCHED = "ENGAGEMENT_FETCHED",
  ENGAGEMENT_FINISH = "ENGAGEMENT_FINISH",
  MARKETPLACE_CAMPAIGN_FINISHED = "MARKETPLACE_CAMPAIGN_FINISHED",
  MARKETPLACE_CAMPAIGN_JOINED = "MARKETPLACE_CAMPAIGN_JOINED",
  MARKETPLACE_CAMPAIGN_REACH_BUDGET = "MARKETPLACE_CAMPAIGN_REACH_BUDGET",
  MARKETPLACE_CAMPAIGN_STARTED = "MARKETPLACE_CAMPAIGN_STARTED",
  MARKETPLACE_CAMPAIGN_UPCOMING = "MARKETPLACE_CAMPAIGN_UPCOMING",
  MARKETPLACE_CAMPAIGN_UPDATE = "MARKETPLACE_CAMPAIGN_UPDATE",
  MARKETPLACE_SHIPPED = "MARKETPLACE_SHIPPED",
  MP_CAMPAIGNS_APPLICANT_INFLS_APPROVED = "MP_CAMPAIGNS_APPLICANT_INFLS_APPROVED",
  PAYMENT_COMPLETED = "PAYMENT_COMPLETED",
  PAYMENT_FAILED = "PAYMENT_FAILED",
  PAYMENT_REQUEST_DONE = "PAYMENT_REQUEST_DONE",
}

export enum ORDER {
  ASC = "ASC",
  DESC = "DESC",
}

export enum Order {
  ASC = "ASC",
  DESC = "DESC",
}

/**
 * An enumeration.
 */
export enum PaymentRequestStatusForInfluencer {
  FAILED = "FAILED",
  REJECTED = "REJECTED",
  REVIEWING = "REVIEWING",
  TRANSFERRED = "TRANSFERRED",
}

export enum PromotionMobileAppType {
  ANDROID = "ANDROID",
  APPLE = "APPLE",
}

export enum QuestionType {
  CHECKBOX = "CHECKBOX",
  DROPDOWN = "DROPDOWN",
  EMAIL = "EMAIL",
  NAME = "NAME",
  SHORT_ANSWER = "SHORT_ANSWER",
}

export enum RecipientType {
  ALL = "ALL",
  WITH_CUSTOMER_TAGS = "WITH_CUSTOMER_TAGS",
}

export enum ShopifyOrderPaymentStatus {
  AUTHORIZED = "AUTHORIZED",
  PAID = "PAID",
  PARTIALLY_PAID = "PARTIALLY_PAID",
  PARTIALLY_REFUNDED = "PARTIALLY_REFUNDED",
  PENDING = "PENDING",
  REFUNDED = "REFUNDED",
  VOIDED = "VOIDED",
}

export enum ShopifyOrderShippingStatus {
  FULFILLED = "FULFILLED",
  PARTIAL = "PARTIAL",
  RESTOCKED = "RESTOCKED",
}

export enum SocialAccountStatus {
  SCRAPING = "SCRAPING",
  SIGNED_UP = "SIGNED_UP",
}

export enum SocialAccountType {
  FACEBOOK = "FACEBOOK",
  FACEBOOK_PAGE = "FACEBOOK_PAGE",
  INSTAGRAM = "INSTAGRAM",
  LINE = "LINE",
  TIKTOK = "TIKTOK",
  TWITTER = "TWITTER",
  YOUTUBE = "YOUTUBE",
}

export enum SocialPostType {
  FACEBOOK = "FACEBOOK",
  FACEBOOK_PAGE = "FACEBOOK_PAGE",
  INSTAGRAM = "INSTAGRAM",
  INSTAGRAM_REEL = "INSTAGRAM_REEL",
  INSTAGRAM_STORY = "INSTAGRAM_STORY",
  TIKTOK = "TIKTOK",
  TWITTER = "TWITTER",
  YOUTUBE = "YOUTUBE",
}

/**
 * An enumeration.
 */
export enum TTCMStatus {
  APPROVED = "APPROVED",
  INVITED = "INVITED",
  NOT_INVITED = "NOT_INVITED",
  REJECTED = "REJECTED",
}

/**
 * An enumeration.
 */
export enum TutorialName {
  PAYMENT = "PAYMENT",
  SEARCH_JOB = "SEARCH_JOB",
  YOUR_JOB = "YOUR_JOB",
}

/**
 * An enumeration.
 */
export enum UserRoles {
  ADMINISTRATOR = "ADMINISTRATOR",
  ADVERTISER = "ADVERTISER",
  AGENCY = "AGENCY",
  CREATOR_STAFF = "CREATOR_STAFF",
  EXTERNAL = "EXTERNAL",
  INFLUENCER = "INFLUENCER",
  PARTNER = "PARTNER",
  STAFF = "STAFF",
  TALENT_AGENCY = "TALENT_AGENCY",
}

/**
 * An enumeration.
 */
export enum WarningReason {
  AD_FRAUD = "AD_FRAUD",
  API_ERROR = "API_ERROR",
  COUPON_MISSING = "COUPON_MISSING",
  DELETED_POST = "DELETED_POST",
  EXPIRED_TOKEN = "EXPIRED_TOKEN",
  INVALID_CONTENT = "INVALID_CONTENT",
  INVALID_TOKEN = "INVALID_TOKEN",
  SCRAPING_ERROR = "SCRAPING_ERROR",
}

export enum YoutubeAnalyticsComparePostsSortOrder {
  COMMENT = "COMMENT",
  DISLIKE = "DISLIKE",
  DURATION = "DURATION",
  LIKE = "LIKE",
  NEGATIVE_RATE = "NEGATIVE_RATE",
  POSITIVE_RATE = "POSITIVE_RATE",
  POST_DATE = "POST_DATE",
  VIEWS = "VIEWS",
}

export enum YoutubeAnalyticsPostsSortOrder {
  AVERAGE_VIEW_DURATION = "AVERAGE_VIEW_DURATION",
  AVERAGE_VIEW_PERCENTAGE = "AVERAGE_VIEW_PERCENTAGE",
  COMMENT = "COMMENT",
  DISLIKE = "DISLIKE",
  DURATION = "DURATION",
  ENGAGEMENT = "ENGAGEMENT",
  LIKE = "LIKE",
  NEGATIVE_RATE = "NEGATIVE_RATE",
  POSITIVE_RATE = "POSITIVE_RATE",
  POST_DATE = "POST_DATE",
  SHARE = "SHARE",
  VIEWS = "VIEWS",
}

export enum YoutubeAnalyticsTagRankingSortOrder {
  AVERAGE_COMMENT = "AVERAGE_COMMENT",
  AVERAGE_DISLIKE = "AVERAGE_DISLIKE",
  AVERAGE_ENGAGEMENT = "AVERAGE_ENGAGEMENT",
  AVERAGE_LIKE = "AVERAGE_LIKE",
  AVERAGE_SHARE = "AVERAGE_SHARE",
  ENGAGEMENT_RATE = "ENGAGEMENT_RATE",
  POST = "POST",
  VIEWS = "VIEWS",
}

export interface ActionLabelInput {
  label: string;
  url: string;
}

export interface AddCommentInput {
  fanId: number;
  comment: string;
}

export interface AddEmailTemplateInput {
  subject: string;
  fans: number[];
  tags: number[];
  sender: string;
  message: string;
  scheduleDate?: string | null;
  sendNow: boolean;
  isDraft: boolean;
}

export interface AddFanInput {
  firstName: string;
  lastName: string;
  email?: string | null;
  contactNumber?: string | null;
}

export interface AddFanTagsInput {
  tags: string[];
  fanIds: number[];
}

export interface AddFormInput {
  title: string;
  description: string;
  hash: string;
  thankTitle: string;
  thankDescription: string;
  questions: QuestionsInput[];
}

export interface AddReadTutorialInput {
  tutorialName: TutorialName;
}

/**
 *  ANY-X
 */
export interface AnyXCheckEmailInput {
  email: string;
}

export interface AnyXSocialAuthConnectForLinkBioInput {
  provider: AnyXAuthSocialAccountType;
  response: string;
  callbackUrl?: string | null;
}

export interface AnyXSocialAuthConnectInput {
  provider: AnyXAuthSocialAccountType;
  response: string;
  callbackUrl?: string | null;
}

export interface AnyXSocialAuthReconnectForLinkBioInput {
  id: string;
  socialAccountType: SocialAccountType;
  response: string;
  callbackUrl?: string | null;
}

export interface AnyXSocialAuthReconnectTTCMInput {
  id: string;
  response: string;
}

export interface AnyXSocialAuthReconnectTikTokInput {
  id: string;
  response: string;
}

export interface AnyXSocialAuthSignInInput {
  provider: AnyXAuthSocialAccountType;
  response: string;
  callbackUrl?: string | null;
}

export interface AnyXSocialAuthSignInInstagramLineInput {
  lineIDToken: string;
  fbOauth?: FacebookOauthInput | null;
}

export interface AnyXSocialAuthSignInOrSignUpInput {
  provider: AnyXAuthSocialAccountType;
  response: string;
  callbackUrl?: string | null;
}

export interface AnyXSocialAuthSignUpInput {
  provider: AnyXAuthSocialAccountType;
  uuid: string;
  name: string;
  countryId: string;
  regionId: number;
  email: string;
  dateOfBirth: string;
  gender: Genders;
  categoryIds: number[];
  phoneNumber: string;
}

export interface AnyXSocialAuthSignUpPartnerInput {
  provider: AnyXAuthSocialAccountType;
  uuid: string;
  name: string;
  countryId: string;
  regionId: number;
  email: string;
  dateOfBirth: string;
  gender: Genders;
  categoryIds: number[];
  phoneNumber: string;
  partnerUserOwnerHash: string;
}

export interface AnyXSocialAuthSignUpTalentInput {
  provider: AnyXAuthSocialAccountType;
  uuid: string;
  name: string;
  countryId: string;
  regionId: number;
  email: string;
  dateOfBirth: string;
  gender: Genders;
  categoryIds: number[];
  phoneNumber: string;
  talentAgencyOwnerHash: string;
}

export interface AssignChatPICInput {
  chatId: string;
}

export interface BioImageInput {
  image: string;
  linkUrl?: string | null;
}

export interface BioLinkInput {
  linkUrl: string;
  linkName: string;
  linkIcon?: BioLinkIcon | null;
}

export interface BioProfileInput {
  avatar?: string | null;
  title?: string | null;
  description?: string | null;
}

export interface BioSectionsInput {
  id?: number | null;
  isPublic: boolean;
  order: number;
  sectionType: BioSectionType;
  profile?: BioProfileInput | null;
  socialMedia?: BioSocialMediaInput | null;
  link?: BioLinkInput | null;
  text?: BioTextInput | null;
  image?: BioImageInput | null;
}

export interface BioSectionsInputV2 {
  id?: number | null;
  order: number;
  sectionType: BioSectionType;
  socialMedia?: BioSocialMediaInput | null;
  link?: BioLinkInput | null;
  text?: BioTextInput | null;
  image?: BioImageInput | null;
}

export interface BioSocialMediaInput {
  socialMedia: BioSocialMediaType;
  linkUrl: string;
  linkName: string;
  socialAccountId?: string | null;
  socialAccountType?: SocialAccountType | null;
}

export interface BioTextInput {
  text: string;
}

export interface ButtonMessageInput {
  cards: CardInput[];
}

export interface CardInput {
  actionLabels: ActionLabelInput[];
  description?: TextComponentInput | null;
  imageUrl?: string | null;
  title?: TextComponentInput | null;
}

/**
 *  `ClickNotificationInput` input defines the `notificationId` to be clicked.
 */
export interface ClickNotificationInput {
  id: string;
}

export interface ConnectFacebookPagesAndInstagramAccountsInput {
  igUserIds: string[];
  fbPageIds: string[];
}

export interface ConnectPromotionMethodsInput {
  methods: PromotionMethodInput[];
}

export interface CreateCreatorStaffInput {
  name: string;
  email: string;
}

export interface CreateEngagementPostInputForInfluencerV2 {
  campaignId: number;
  socialMedia: CampaignSocialMediaType;
  planedPostDate: any;
  content: string;
  materialsUrl?: (string | null)[] | null;
  socialAccountId: number;
  socialAccountMedia: SocialAccountType;
}

export interface CreateInfluencerBioInput {
  linkName: string;
}

export interface CreateLineBroadcastMessageInput {
  title: string;
  deliveryTiming: DeliveryTimingItemInput;
  recipient: RecipientItemInput;
  lineContent: LineContentItemInput[];
}

export interface CreateLineChannelInput {
  lineChannelId: string;
  lineChannelSecret: string;
  publicKeyId: number;
  lineKid: string;
  lineLoginChannelId: string;
  liffId: string;
}

export interface DeleteCommentInput {
  id: number;
}

export interface DeleteFanTagsInput {
  tagIds: number[];
  fanIds: number[];
}

export interface DeliveryTimingItemInput {
  scheduleDate?: string | null;
  type: DeliveryTimingType;
}

export interface EmailListOrderBy {
  field: EmailListSortableField;
  order: Order;
}

export interface FacebookOauthInput {
  response: string;
  callbackUrl: string;
}

export interface FacebookPageAnalyticsPostsSortInput {
  field?: FacebookPageAnalyticsPostsSortOrder | null;
  order?: Order | null;
}

export interface FanListOrderBy {
  field: FanListSortableField;
  order: Order;
}

export interface FormListOrderBy {
  field?: FormListSortableField | null;
  order?: Order | null;
}

export interface FormReportSummaryInput {
  id: number;
}

export interface FormRespondentsCountInput {
  id: number;
}

export interface FormRespondentsListInput {
  id: number;
  offset: number;
  limit: number;
}

export interface GetChatEventsInput {
  chatId: string;
  keyword?: string | null;
  newerThan?: string | null;
  olderThan?: string | null;
}

export interface GetCreatorTagsByIdInput {
  tagIds: number[];
}

export interface GetCreatorTagsInput {
  keyword: string;
}

export interface GetCustomerTagsInput {
  order: CustomerTagOrder;
}

export interface GetEmailOverviewInput {
  emailId: string;
}

export interface GetEmailTemplateInput {
  id: number;
}

export interface GetFanActivitiesInput {
  fanId: number;
  offset: number;
  limit: number;
}

export interface GetFanFormResponseInput {
  submissionId: string;
  fanId: string;
}

export interface GetFanInput {
  fanId: number;
}

export interface GetFanOrdersInput {
  fanId: number;
}

export interface GetFanParticipationFormListInput {
  fanId: string;
}

export interface GetFanProvincesInput {
  countryId: string;
}

export interface GetFormInput {
  id: string;
}

export interface GetFormTemplateInput {
  hash: string;
}

export interface GetInfluencerBioAnalyticsInput {
  startDate: string;
  endDate: string;
}

export interface GetLineBroadcastEstimateRecipientsInput {
  recipientType: RecipientType;
  tagNames: string[];
}

export interface GetLineBroadcastMessageInput {
  lineBroadcastMessageId: string;
}

export interface GetLineChatCountInput {
  keyword: string;
}

export interface GetLineChatsInput {
  keyword: string;
  typeFilter: CRMChatTypeFilter;
  tagName?: string | null;
  offset: number;
  limit: number;
}

export interface GetTagsAndFansRecipientsInput {
  keyword: string;
}

export interface ImageInput {
  fileName: string;
  mimeType: string;
  previewUrl: string;
  url: string;
}

export interface ImageItemInput {
  imageUrl: string;
  label?: string | null;
  url: string;
}

export interface ImageMessageInput {
  images: ImageItemInput[];
}

export interface ImageVideoMessageInput {
  media: MediaItemInput;
}

export interface ImportFansInput {
  uploadFileUrl: string;
}

export interface InfluencerAddYoutubeCompareAccountAnalyticsInput {
  url: string;
  socialAccountId: number;
}

export interface InfluencerDeleteYoutubeCompareAccountAnalyticsInput {
  compareAccountId: number;
  socialAccountId: number;
}

export interface InfluencerSignUpInput {
  name: string;
  email: string;
  phoneNumber?: string | null;
  categoryIds: number[];
  gender: Genders;
  dateOfBirth: string;
  countryId: string;
  regionId: number;
}

export interface InfluencerSocialAuthReconnectInstagramAccountAnalyticsInput {
  response: string;
  callbackUrl: string;
  analyticsAccountId: number;
}

export interface InstagramFeedPostOrderBy {
  field?: InstagramFeedPostSortField | null;
  order?: ORDER | null;
}

export interface InstagramInteractionUserOrderBy {
  field?: InstagramInteractionUserSortField | null;
  order?: ORDER | null;
}

export interface InstagramReelPostOrderBy {
  field?: InstagramReelPostSortField | null;
  order?: ORDER | null;
}

export interface InstagramStoryPostOrderBy {
  field?: InstagramStoryPostSortField | null;
  order?: ORDER | null;
}

export interface JoinMarketplaceAffiliateInput {
  campaignId: number;
  methodIds: number[];
}

export interface JoinMarketplaceV2Input {
  campaignId: number;
  socialAccountId: number;
  socialMedia: SocialAccountType;
  isMobile?: boolean | null;
}

export interface LineContentItemInput {
  type: LineMessageType;
  richMessage?: RichMessageInput | null;
  plainTextMessage?: PlainTextMessageInput | null;
  imageMessage?: ImageMessageInput | null;
  buttonMessage?: ButtonMessageInput | null;
  mediaMessage?: ImageVideoMessageInput | null;
}

export interface MediaItemInput {
  image?: ImageInput | null;
  type: MediaType;
  video?: VideoInput | null;
}

export interface ObtainJSONWebTokenInput {
  email: string;
  password: string;
  appName?: AppName | null;
}

export interface OptionInput {
  order: number;
  optionTitle: string;
}

export interface PlainTextMessageInput {
  text: string;
}

export interface PromotionMethodInput {
  method: CampaignPromotionMethod;
  mobileAppType?: PromotionMobileAppType | null;
  url?: string | null;
  description?: string | null;
  email?: string | null;
}

export interface PublishInfluencerBioInput {
  isPublic: boolean;
}

export interface QuestionsInput {
  questionType: QuestionType;
  order: number;
  isRequired: boolean;
  title: string;
  image?: string | null;
  options: OptionInput[];
}

export interface RecipientItemInput {
  type: RecipientType;
  tagNames: string[];
}

export interface RemoveAllFormResponsesInput {
  formId: number;
}

export interface RemoveCreatorStaffInput {
  creatorStaffId: number;
}

export interface RemoveEmailsInput {
  emailIds: number[];
}

export interface RemoveFansInput {
  fanIds: number[];
}

export interface RemoveFormResponseInput {
  submissionId: number;
}

export interface RemoveFormsInput {
  formIds: number[];
}

export interface RemoveShopifyAccountInput {
  id: number;
}

export interface ResendInfluencerForgotPasswordEmailInput {
  email: string;
}

export interface ResendInfluencerSignUpEmailInput {
  email: string;
}

export interface ResetPasswordInput {
  token?: string | null;
  password?: string | null;
}

export interface ResolveChatInput {
  chatId: string;
}

export interface RichMessageInput {
  imageUrl: string;
  url?: string | null;
}

export interface SendContactInput {
  enquiry: string;
}

export interface SendLineBroadcastMessageInput {
  lineBroadcastMessageId: string;
  isTest: boolean;
}

export interface SendMessageInput {
  message: string;
  chatId: string;
}

export interface ShopifyAuthRedirectUrlInput {
  shopName: string;
  callbackUrl: string;
}

export interface ShopifyValidateAuthInput {
  shopName: string;
  response: string;
}

/**
 * SocialAuthConnect
 * todo: remove it when move to new AnyX
 */
export interface SocialAuthConnectInput {
  provider: AuthSocialAccountType;
  response: string;
  callbackUrl?: string | null;
}

/**
 *  socialAuthForJoinCampaign
 */
export interface SocialAuthForJoinCampaignInput {
  provider: AuthSocialAccountType;
  response: string;
  callbackUrl?: string | null;
  selectedSocialAccountId?: string | null;
}

export interface SocialAuthRedirectUrlForInfluencerInput {
  provider: AuthSocialAccountType;
  callbackUrl: string;
}

/**
 *  SocialAuthSignUpConnect
 */
export interface SocialAuthSignUpConnectInput {
  provider: AuthSocialAccountType;
  response: string;
  callbackUrl?: string | null;
}

export interface SubmitFormInput {
  recaptchaResponse: string;
  id: string;
  questions: SubmitQuestion[];
}

export interface SubmitFormInternalInput {
  id: string;
  questions: SubmitQuestionInternal[];
}

export interface SubmitQuestion {
  questionId: string;
  answeredTexts: string[];
  answeredOptionIds: number[];
}

export interface SubmitQuestionInternal {
  questionId: string;
  answeredTexts: string[];
  answeredOptionIds: number[];
}

export interface SwitchInfluencerForStaffInput {
  influencerId: number;
}

export interface TextComponentInput {
  fontSize: FontSize;
  text: string;
  weighted: boolean;
}

export interface UUUMSocialAuthSignUpInput {
  uuid: string;
  name: string;
  countryId: string;
  regionId: number;
  email: string;
  dateOfBirth: string;
  gender: Genders;
  categoryIds: number[];
  phoneNumber: string;
}

export interface UnassignChatPICInput {
  chatId: string;
}

/**
 * `UnreadNotificationInput` input accepts a `checkpoint` to get notifications beyond the checkpoint. A value of `null`
 * can be provided to start from the latest notification.
 */
export interface UnreadNotificationInput {
  checkpoint?: number | null;
}

export interface UnresolveChatInput {
  chatId: string;
}

export interface UpdateAccountInformationInput {
  name: string;
  brandName: string;
  categoryIds: number[];
  dateOfBirth: string;
  countryId: string;
  regionId: number;
  gender: Genders;
  introduce: string;
}

export interface UpdateAddressInformationInput {
  fullName: string;
  postalCode: string;
  province: string;
  city: string;
  address: string;
  address2: string;
}

export interface UpdateBioSectionsInput {
  sections: BioSectionsInput[];
}

export interface UpdateCommentInput {
  activityId: number;
  comment: string;
}

export interface UpdateEmailTemplateInput {
  id: number;
  subject: string;
  fans: number[];
  tags: number[];
  sender: string;
  message: string;
  scheduleDate?: string | null;
  sendNow: boolean;
  isDraft: boolean;
}

export interface UpdateEngagementPostInputForInfluencerV2 {
  campaignId: number;
  socialMedia: CampaignSocialMediaType;
  planedPostDate: any;
  content: string;
  materialsUrl?: (string | null)[] | null;
  id: number;
  socialAccountId: number;
  socialAccountMedia: SocialAccountType;
}

export interface UpdateFanInput {
  id: number;
  firstName: string;
  lastName: string;
  email?: string | null;
  contactNumber?: string | null;
  birthday?: string | null;
  gender?: FanGender | null;
  countryId?: string | null;
  province?: string | null;
  city?: string | null;
  postalCode?: string | null;
  address1?: string | null;
  address2?: string | null;
  tags: string[];
}

export interface UpdateFormInput {
  id?: string | null;
  title: string;
  description: string;
  thankTitle: string;
  thankDescription: string;
  questions: UpdateQuestionInput[];
}

export interface UpdateFormStatusInput {
  id: number;
  status: FormStatus;
}

export interface UpdateInfluencerBioInput {
  theme?: BioTheme | null;
  themeV2?: BioThemeV2 | null;
  linkName: string;
  profile: BioProfileInput;
  sections: BioSectionsInputV2[];
  isPublic: boolean;
  isFinish: boolean;
}

export interface UpdateInfluencerRateCardsInput {
  facebook?: number | null;
  instagram?: number | null;
  instagramStory?: number | null;
  youtube?: number | null;
  twitter?: number | null;
  tiktok?: number | null;
}

export interface UpdateLineBroadcastMessageInput {
  lineBroadcastMessageId: string;
  title: string;
  deliveryTiming: DeliveryTimingItemInput;
  recipient: RecipientItemInput;
  lineContent: LineContentItemInput[];
}

export interface UpdateLinkBioThemeColorInput {
  theme?: BioTheme | null;
  button?: BioButtonStyle | null;
  fontColor?: string | null;
  bgColor?: string | null;
  buttonFontColor?: string | null;
  buttonBgColor?: string | null;
  media?: string | null;
  font?: BioFontStyle | null;
}

export interface UpdateOptionInput {
  id?: string | null;
  order: number;
  optionTitle: string;
}

export interface UpdatePaymentInformationInput {
  accountName: string;
  email: string;
  bankId: number;
  branchId?: number | null;
  branchName?: string | null;
  accountNumber: string;
  address?: string | null;
  cityId?: number | null;
  postalCode?: string | null;
  swiftCode?: string | null;
  iban?: string | null;
  subject?: string | null;
  fullName?: string | null;
}

export interface UpdatePersonalUserDetailInput {
  name: string;
  email: string;
  password?: string | null;
  displayLanguage: DisplayLanguage;
  notificationSetting?: boolean | null;
  crmNotificationSetting?: boolean | null;
  mkpNotificationSetting?: boolean | null;
  eggNotificationSetting?: boolean | null;
}

export interface UpdateQuestionInput {
  id?: string | null;
  questionType: QuestionType;
  order: number;
  isRequired: boolean;
  title: string;
  image?: string | null;
  options: UpdateOptionInput[];
}

export interface UpdateUserDetailInput {
  userId: number;
  name: string;
  email: string;
  notificationSetting?: boolean | null;
  crmNotificationSetting?: boolean | null;
  mkpNotificationSetting?: boolean | null;
  eggNotificationSetting?: boolean | null;
}

export interface UserDetailInput {
  userId: number;
}

export interface VerifyInput {
  token: string;
  clientMutationId?: string | null;
}

export interface VerifyLineIDTokenInput {
  idToken: string;
}

export interface VideoInput {
  fileName: string;
  mimeType: string;
  previewUrl: string;
  url: string;
}

export interface YoutubeAnalyticsComparePostsSort {
  field?: YoutubeAnalyticsComparePostsSortOrder | null;
  order?: Order | null;
}

export interface YoutubeAnalyticsPostsSortInput {
  field?: YoutubeAnalyticsPostsSortOrder | null;
  order?: Order | null;
}

export interface YoutubeAnalyticsTagRankingSortInput {
  field?: YoutubeAnalyticsTagRankingSortOrder | null;
  order?: Order | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
