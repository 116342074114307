import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import trashIcon from '@src/assets/icon/mylink/trash.svg';
import Icomoon from '@src/components/atoms/Icomoon';
import { LineMessageType } from '@src/__generated__/globalTypes';
import { FormInformation, FormType } from './helpers';

interface BlockCardProps {
  contentType?: LineMessageType;
  error?: boolean;
  isDeletable: boolean;
  isDragging: boolean;
  onClick?: () => void;
  onDelete: () => void;
}

const BlockCard = ({ contentType, error, isDeletable, isDragging, onClick, onDelete }: BlockCardProps) => {
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext<FormInformation>();
  const formTypeValue = watch('formType');

  const { icon, formType, title } = (() => {
    switch (contentType) {
      case LineMessageType.BUTTON:
        return {
          icon: 'cupboard-grey-light',
          formType: FormType.BUTTON_TYPE,
          title: 'Button Type',
        };
      case LineMessageType.IMAGES:
        return { icon: 'three-cube-horizontal-grey-light', formType: FormType.IMAGE_TYPE, title: 'Image Type' };
      case LineMessageType.IMAGE_VIDEO:
        // anyChat not include this option
        return { icon: 'youtube-grey-light', formType: FormType.IMAGE_VIDEO_TYPE, title: 'Image / Video' };
      case LineMessageType.PLAIN_TEXT:
        return {
          icon: 'edit-box-grey-light',
          formType: FormType.PLAIN_TEXT,
          title: 'Plain Text',
        };
      case LineMessageType.RICH:
        return {
          icon: 'image-grey',
          formType: FormType.RICH_MESSAGE,
          title: 'Rich Message',
        };
      default:
        return {
          icon: null,
          formType: formTypeValue === FormType.BUTTON_TYPE ? FormType.BUTTON_TYPE_CONTENT : FormType.IMAGE_TYPE_CONTENT,
          title: 'Card',
        };
    }
  })();

  const onClickBlock = () => {
    if (onClick) {
      onClick();
    }
    setValue('formType', formType);
  };

  return (
    <div css={styles.container}>
      <Block error={error} isDragging={isDragging} onClick={onClickBlock}>
        <Icomoon icon="six-dots-vertical" size={32} />
        <div>
          {icon && <Icomoon icon={icon} size={24} />}
          <div>{t(title)}</div>
        </div>
        <Icomoon className="arrow-right" css={{ transform: 'rotate(270deg)' }} icon="arrow-down" />
      </Block>
      <Delete disabled={!isDeletable} onClick={() => isDeletable && onDelete()}>
        <img height="16" src={trashIcon} width="16" />
      </Delete>
    </div>
  );
};

const Block = styled.div<{ error?: boolean; isDragging: boolean }>`
  align-items: center;
  background-color: ${({ error }) => (error ? '#fff2f3' : '#fff')};
  border: 1px solid ${({ error }) => (error ? '#ff6247' : '#dee5ec')};
  cursor: pointer;
  display: flex;
  flex: 1;
  gap: 8px;
  margin-bottom: 8px;
  max-height: 48px;
  opacity: ${({ isDragging }) => (isDragging ? '0.8' : '1')};
  padding: 8px;

  & > svg {
    cursor: grab;
  }

  & > div {
    align-items: center;
    display: flex;
    flex: 1;
    gap: 4px;

    & > div {
      color: #6e7c89;
      font-size: 14px;
      font-weight: 600;
    }
  }

  & > .arrow-right {
    visibility: hidden;
  }

  &:hover {
    background-color: ${({ error }) => (error ? '#fff2f3' : '#f6f8fa')};

    & > .arrow-right {
      visibility: visible;
    }
  }
`;

const Delete = styled.div<{ disabled: boolean }>`
  align-items: center;
  background-color: #fff;
  border-radius: 3px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: flex;
  height: 32px;
  justify-content: center;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  width: 32px;
`;

const styles = {
  container: css`
    align-items: center;
    display: flex;
    gap: 8px;
  `,
};

export default BlockCard;
