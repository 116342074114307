import { useQuery } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import PROMOTION_METHODS from './queries/InfluencerPromotionMethods.graphql';
import { InfluencerPromotionMethods } from './queries/__generated__/InfluencerPromotionMethods';

const usePromotionMethods = () => {
  const { enqueueSnackbar, t } = useQueryHelper();

  const { data, loading } = useQuery<InfluencerPromotionMethods>(PROMOTION_METHODS, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
  });

  return {
    data,
    loading,
  };
};

export default usePromotionMethods;
