import React, { CSSProperties, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Avatar from '@src/components/atoms/Avatar';
import { ThemeButton } from '@src/components/atoms/Button';
import { CheckBox } from '@src/components/atoms/CheckBox';
import Grid from '@src/components/atoms/Grid';
import { localizedDateFormatter } from '@src/components/atoms/List/DateColumn';
import { RadioIcon } from '@src/components/atoms/RadioButtonElement';
import Switch from '@src/components/molecules/Switch';
import AccessPermission from '@src/components/organisms/SignUpFacebook/AccessPermission';
import EditAccess, { defaultWidth } from '@src/components/organisms/SignUpTiktok/EditAccess';
import ManageAccess from '@src/components/organisms/SignUpTiktok/ManageAccess';
import { ConnectType } from '@src/libs/auth';
import { getAuthSocialAccountType } from '@src/libs/campaign';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { formatIntNumber, formatNumberWithCommas } from '@src/libs/format';
import { useDimensions, useRedirectWithinIframe, useQueryHelper } from '@src/libs/hooks';
import { useRedirectUrl } from '@src/libs/hooks/useRedirectUrl';
import { defaultInfluencerAvatar } from '@src/libs/image';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import { switchImage, switchSocialMediaName, switchText } from '@src/libs/SocialMedia';
import { ViewportBreakpoint, ViewportType } from '@src/libs/theme';
import useGetCreatorAddress from '@src/pages/Settings/Address/useGetCreatorAddress';
import { generatePath, ROUTES } from '@src/shared/routes';
import { CampaignSocialMediaType, SocialAccountType } from '@src/__generated__/globalTypes';
import { useMediaQuery } from 'react-responsive';
import { DialogModal } from '@src/components/molecules/DialogModal';
import CAMPAIGN_JOINABLE_ACCS from './queries/CampaignJoinableAccounts.graphql';
import {
  CampaignJoinableAccounts,
  CampaignJoinableAccountsVariables,
} from './queries/__generated__/CampaignJoinableAccounts';

declare const IS_DISABLED_ON_PROD: boolean;

enum DialogStatus {
  CLOSE = 'CLOSE',
  CHOOSE_SOCIAL_MEDIA = 'CHOOSE_SOCIAL_MEDIA',
  FB_GRANT_PERMISSION = 'FB_GRANT_PERMISSION',
  TERMS_CONDITION = 'TERMS_CONDITION',
  TIKTOK_RECONNECT = 'TIKTOK_RECONNECT',
  REQUIRED_SIGN_IN = 'REQUIRED_SIGN_IN',
}

interface JoinButtonProps {
  campaignDetails: {
    currency: string;
    endDate: any;
    id: number;
    isGiftingCampaign: boolean | null;
    maximumRevenuePerInfluencer: number | null;
    minimumPaymentAmount: number;
    socialMedias: CampaignSocialMediaType[];
    startDate: any;
  };
  height?: number;
  isAbleToJoin?: boolean | null;
  isSelection?: boolean;
}

const JoinButton = ({ campaignDetails, height = 32, isAbleToJoin, isSelection }: JoinButtonProps) => {
  const {
    currency,
    endDate,
    id: campaignId,
    isGiftingCampaign,
    maximumRevenuePerInfluencer,
    minimumPaymentAmount,
    socialMedias,
    startDate,
  } = campaignDetails;
  const [confirmedNotification, setConfirmedNotification] = useState<boolean>(false);
  const [dialog, setDialog] = useState<DialogStatus>(DialogStatus.CLOSE);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [selectedAccount, setSelectedAccount] = useState<{
    activeToken: boolean;
    id: number;
    selectedSocialAccountId: number;
    type: SocialAccountType;
  } | null>(null);
  const { enqueueSnackbar, history, i18n, t } = useQueryHelper();
  const { getRedirectUrl } = useRedirectUrl(FE_REDIRECT_MAPPING.JOIN_CAMPAIGN);
  const { getRedirectUrl: getRedirectReconnectUrl } = useRedirectUrl(FE_REDIRECT_MAPPING.TIKTOK_RECONNECT);
  const { startRedirectProcess } = useRedirectWithinIframe();
  const isDesktop = useMediaQuery({ minWidth: 1000 });
  const isTablet = useMediaQuery({ minWidth: ViewportBreakpoint.MOBILE, maxWidth: ViewportBreakpoint.MEDIUM });
  const isVerticalView = useMediaQuery({ maxWidth: ViewportBreakpoint.MEDIUM });
  const { creatorAddressInformation } = useGetCreatorAddress();
  const { width } = useDimensions(containerRef);
  const imageMinWidth = width / 2 - 40;
  const imageWidth = isDesktop ? defaultWidth : imageMinWidth < defaultWidth ? imageMinWidth : defaultWidth;
  const isAddressAvailable = !!(
    creatorAddressInformation?.address ||
    creatorAddressInformation?.address2 ||
    creatorAddressInformation?.city ||
    creatorAddressInformation?.fullName ||
    creatorAddressInformation?.postalCode ||
    creatorAddressInformation?.province
  );
  const socialMedia = socialMedias[0];
  const isFacebookCampaign = !IS_DISABLED_ON_PROD && socialMedia === CampaignSocialMediaType.FACEBOOK;
  const isTiktokCampaign = !IS_DISABLED_ON_PROD && socialMedia === CampaignSocialMediaType.TIKTOK;
  const tiktokGrantPermissionSteps = [
    {
      description: <StepDescription>{t('Annotation.Click Edit access')}</StepDescription>,
      image: <EditAccess width={imageWidth} />,
    },
    {
      description: (
        <StepDescription>
          <div>{t('Annotation.Turn on toggle')}</div>
          <Switch css={styles.switch} customBgColor="#66dca0" isChecked handleChange={() => null} />
        </StepDescription>
      ),
      image: <ManageAccess width={imageWidth} />,
    },
  ];

  const { data, loading } = useQuery<CampaignJoinableAccounts, CampaignJoinableAccountsVariables>(
    CAMPAIGN_JOINABLE_ACCS,
    {
      variables: {
        campaignId,
      },
      onError: error => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
    }
  );

  const joinableAccounts = (() => {
    let accounts: {
      activeToken: boolean;
      avatar: string | null;
      disabled: boolean;
      id: number;
      isBusinessAccount?: boolean;
      followersCount: number;
      selectedSocialAccountId: number;
      type: SocialAccountType;
      username: string;
    }[] = [];

    if (data?.marketplaceJoinableAccounts) {
      const { facebookAccount, facebookPages, instagramAccounts, tiktokAccounts, twitterAccounts, youtubeAccounts } =
        data.marketplaceJoinableAccounts;

      if (
        [
          CampaignSocialMediaType.FACEBOOK,
          CampaignSocialMediaType.INSTAGRAM,
          CampaignSocialMediaType.INSTAGRAM_STORY,
        ].includes(socialMedia) &&
        facebookAccount
      ) {
        if (socialMedia === CampaignSocialMediaType.FACEBOOK) {
          accounts = accounts.concat([
            { ...facebookAccount, selectedSocialAccountId: facebookAccount.id, type: SocialAccountType.FACEBOOK },
          ]);
          if (facebookPages.length > 0) {
            accounts = accounts.concat(
              facebookPages.map(fbPage => ({
                ...fbPage,
                selectedSocialAccountId: facebookAccount.id,
                type: SocialAccountType.FACEBOOK_PAGE,
              }))
            );
          }
        }
        if (
          [CampaignSocialMediaType.INSTAGRAM, CampaignSocialMediaType.INSTAGRAM_STORY].includes(socialMedia) &&
          instagramAccounts.length > 0
        ) {
          accounts = instagramAccounts.map(igAccount => ({
            ...igAccount,
            selectedSocialAccountId: facebookAccount.id,
            type: SocialAccountType.INSTAGRAM,
          }));
        }
      } else if (socialMedia === CampaignSocialMediaType.TIKTOK && tiktokAccounts.length > 0) {
        accounts = tiktokAccounts.map(tiktokAccount => ({
          ...tiktokAccount,
          selectedSocialAccountId: tiktokAccount.id,
          type: SocialAccountType.TIKTOK,
        }));
      } else if (socialMedia === CampaignSocialMediaType.TWITTER && twitterAccounts.length > 0) {
        accounts = twitterAccounts.map(twAccount => ({
          ...twAccount,
          selectedSocialAccountId: twAccount.id,
          type: SocialAccountType.TWITTER,
        }));
      } else if (socialMedia === CampaignSocialMediaType.YOUTUBE && youtubeAccounts.length > 0) {
        accounts = youtubeAccounts.map(ytAccount => ({
          ...ytAccount,
          selectedSocialAccountId: ytAccount.id,
          type: SocialAccountType.YOUTUBE,
        }));
      }
    }

    return accounts;
  })();

  const onClickChooseSocialMediaNext = () => {
    if (isFacebookCampaign) {
      setDialog(DialogStatus.FB_GRANT_PERMISSION);
    } else if (isTiktokCampaign) {
      if (!selectedAccount?.activeToken) {
        setDialog(DialogStatus.TIKTOK_RECONNECT);
      } else {
        onClickConnect();
      }
    } else {
      setDialog(DialogStatus.TERMS_CONDITION);
    }
  };

  const onClickConnect = async () => {
    if (selectedAccount) {
      localStorage.setItem('joinCampaignId', String(campaignId));
      localStorage.setItem('joinAccountSocialMedia', String(selectedAccount.type));
      localStorage.setItem('joinAccountId', String(selectedAccount.id));
      if (
        [SocialAccountType.FACEBOOK, SocialAccountType.FACEBOOK_PAGE, SocialAccountType.INSTAGRAM].includes(
          selectedAccount.type
        )
      ) {
        localStorage.setItem('fbAccountId', String(selectedAccount.selectedSocialAccountId));
      }

      const authSocialAccount = getAuthSocialAccountType(socialMedia);
      if (!authSocialAccount) {
        return;
      }

      if (selectedAccount.activeToken) {
        localStorage.setItem('skipAuthVerification', 'true');
        const provider = [
          SocialAccountType.FACEBOOK,
          SocialAccountType.FACEBOOK_PAGE,
          SocialAccountType.INSTAGRAM,
        ].includes(authSocialAccount)
          ? switchSocialMediaName(SocialAccountType.FACEBOOK)
          : switchSocialMediaName(authSocialAccount);
        history.push(generatePath(ROUTES.REDIRECT_JOIN_CAMPAIGN_PROVIDER, { provider }));
      } else {
        const redirectUrl = await getRedirectUrl(authSocialAccount);
        if (!redirectUrl) {
          return;
        }

        if (authSocialAccount === SocialAccountType.TWITTER) {
          // Set RedirectType for Twitter OAuth
          localStorage.setItem('redirectType', FE_REDIRECT_MAPPING.JOIN_CAMPAIGN);
        }
        startRedirectProcess({ redirectUrl, redirectType: FE_REDIRECT_MAPPING.JOIN_CAMPAIGN });
      }
    }

    return;
  };

  const onClickJoinNow = () => {
    if (!(isGiftingCampaign && !isAddressAvailable) && joinableAccounts.length === 1) {
      const { activeToken, id, selectedSocialAccountId, type } = joinableAccounts[0];
      setSelectedAccount({ activeToken, id, selectedSocialAccountId, type });
      setDialog(DialogStatus.TERMS_CONDITION);
    } else if (isFacebookCampaign || isTiktokCampaign) {
      setDialog(DialogStatus.TERMS_CONDITION);
    } else {
      setDialog(DialogStatus.CHOOSE_SOCIAL_MEDIA);
    }
  };

  const onClickTermConditionNext = () => {
    if (isFacebookCampaign) {
      setDialog(joinableAccounts.length > 1 ? DialogStatus.CHOOSE_SOCIAL_MEDIA : DialogStatus.FB_GRANT_PERMISSION);
    } else if (isTiktokCampaign) {
      setDialog(DialogStatus.CHOOSE_SOCIAL_MEDIA);
    } else if (selectedAccount?.activeToken) {
      onClickConnect();
    } else {
      if (isTiktokCampaign) {
        setDialog(DialogStatus.TIKTOK_RECONNECT);
      } else {
        setDialog(DialogStatus.REQUIRED_SIGN_IN);
      }
    }
  };

  const onClickTiktokReconnect = async () => {
    localStorage.setItem('campaignId', String(campaignId));
    localStorage.setItem('connectType', ConnectType.RECONNECT_TIKTOK);
    localStorage.setItem('reconnectTiktokAccountId', String(selectedAccount?.id));
    try {
      const redirectUrl = await getRedirectReconnectUrl(SocialAccountType.TIKTOK);

      if (redirectUrl) {
        startRedirectProcess({ redirectUrl, redirectType: FE_REDIRECT_MAPPING.TIKTOK_RECONNECT });
      }
    } catch (error) {
      enqueueSnackbar(t('UnexpectedError'), { variant: 'error' });
    }
  };

  const onCloseDialog = () => {
    setSelectedAccount(null);
    setDialog(DialogStatus.CLOSE);
  };

  const moreThanOneJoinableAccounts = joinableAccounts.length > 1;

  const popupStyles: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    width: isDesktop ? '900px' : isTablet ? '450px' : '90%',
    height: isVerticalView ? '700px' : '500px',
    maxHeight: '90vh',
    padding: '0',
    border: 'none',
    borderRadius: isVerticalView ? '15px' : '5px',
    maxWidth: 'unset',
  };

  return (
    <div>
      <DialogModal
        contentStyle={popupStyles}
        isOpen={dialog !== DialogStatus.CLOSE}
        closeModal={onCloseDialog}
        footerNode={null}
      >
        <ModalWrapper ref={containerRef}>
          {dialog === DialogStatus.CHOOSE_SOCIAL_MEDIA ? (
            joinableAccounts.length > 0 ? (
              <>
                <DialogContent>
                  <DialogTitle>
                    {t(isTiktokCampaign ? 'Connecting TikTok account' : 'Choose an account to join')}
                  </DialogTitle>
                  {!isTiktokCampaign && <DialogDescription>{t('ChooseJoiningAccountExplained')}</DialogDescription>}
                  {isGiftingCampaign && !isAddressAvailable ? (
                    <div css={styles.addressAlertContainer}>
                      <div>{t('Annotation.Please register your address to ship the product for this campaign')}</div>
                      <ThemeButton text="Settings" width="max-content" href={ROUTES.SETTINGS_ADDRESS} />
                    </div>
                  ) : (
                    <>
                      {isTiktokCampaign && (
                        <TiktokInfoContainer>
                          <div>
                            <ul>
                              <li>{t('Annotation.Make sure that your TikTok account is public')}</li>
                              <li>{t('Annotation.Make sure you will grant us by turning on the permission below')}</li>
                            </ul>
                          </div>
                          <div>{t('Annotation.Read your public videos on TikTok')}</div>
                          <div>{t('Annotation.TikTok’s access permission page')}</div>
                          <div>
                            {tiktokGrantPermissionSteps.map((step, index) => {
                              const { description, image } = step;

                              return (
                                <div css={styles.stepContainer} key={index}>
                                  <div>{index + 1}</div>
                                  <div>{image}</div>
                                  <div>{description}</div>
                                </div>
                              );
                            })}
                          </div>

                          {moreThanOneJoinableAccounts && (
                            <div>
                              <CheckBox
                                checked={confirmedNotification}
                                label="Label.I have confirmed this notification"
                                onCheckedChange={val => setConfirmedNotification(val)}
                              />
                            </div>
                          )}
                        </TiktokInfoContainer>
                      )}

                      {!moreThanOneJoinableAccounts ? (
                        <div css={{ display: 'flex', justifyContent: 'center' }}>
                          <CheckBox
                            checked={confirmedNotification}
                            label="Label.I have confirmed this notification"
                            onCheckedChange={val => setConfirmedNotification(val)}
                          />
                        </div>
                      ) : (
                        <>
                          {isTiktokCampaign && <div css={styles.selectAccountTitle}>{t('Select account')}</div>}
                          <div css={styles.socialMediasContainer}>
                            {joinableAccounts.map(account => {
                              const {
                                activeToken,
                                avatar,
                                disabled,
                                id,
                                followersCount,
                                selectedSocialAccountId,
                                type,
                                username,
                              } = account;

                              return (
                                <Grid key={id} md={6} xs={12}>
                                  <div
                                    css={styles.socialMediaContainer}
                                    onClick={() =>
                                      !disabled
                                        ? setSelectedAccount({ activeToken, id, selectedSocialAccountId, type })
                                        : null
                                    }
                                  >
                                    <RadioIcon isChecked={id === selectedAccount?.id} />
                                    <img
                                      alt="socialMedia"
                                      height="50"
                                      src={switchImage(socialMedia, true)}
                                      width="50"
                                    />
                                    <Avatar src={defaultInfluencerAvatar(avatar)} />
                                    <div>
                                      <div>{username}</div>
                                      <div>{`${formatNumberWithCommas(followersCount, 0)} ${t('Followers')}`}</div>
                                    </div>
                                    {disabled && (
                                      <div css={styles.socialMediaOverlay}>
                                        {t(`It's not match the condition Account`)}
                                      </div>
                                    )}
                                  </div>
                                </Grid>
                              );
                            })}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </DialogContent>
                <DialogAction>
                  <ThemeButton text="Close" width="max-content" onClick={onCloseDialog} />
                  <ThemeButton
                    theme="blue"
                    text="Next"
                    width="max-content"
                    disabled={!selectedAccount || (isTiktokCampaign && !confirmedNotification)}
                    onClick={onClickChooseSocialMediaNext}
                  />
                </DialogAction>
              </>
            ) : (
              <>
                <DialogContent>
                  <DialogTitle>{t('Choose an account to join')}</DialogTitle>
                  <DialogDescription>{t('ChooseJoiningAccountExplained')}</DialogDescription>
                  <div css={styles.connectSocialAcc}>
                    {t(`Please connect ${switchText(socialMedia)} account before joining this campaign`)}
                  </div>
                </DialogContent>
                <DialogAction>
                  <ThemeButton text="Close" width="max-content" href={ROUTES.SETTINGS_SOCIAL_CONNECT} />
                </DialogAction>
              </>
            )
          ) : dialog === DialogStatus.FB_GRANT_PERMISSION ? (
            <>
              <DialogContent>
                <DialogTitle>{t('pageTitle.Grant permission')}</DialogTitle>
                <DialogDescription>
                  {t(
                    'Annotation.In order to fetch your post to the campaign, AnyCreator needs access to your public information'
                  )}
                </DialogDescription>

                <div css={styles.fbGrantPermissionInfoContainer}>
                  <div>
                    {t('Annotation.Please make sure that you will turn on all toggles on the access permission page')}
                  </div>
                  <div>{t('Annotation.Facebook’s access permission page')}</div>
                  <div>
                    <AccessPermission />
                  </div>
                </div>

                <div css={{ display: 'flex', justifyContent: 'center' }}>
                  <CheckBox
                    checked={confirmedNotification}
                    label="I have confirmed this notification"
                    size="14px"
                    onCheckedChange={checked => setConfirmedNotification(checked)}
                  />
                </div>
              </DialogContent>
              <DialogAction>
                <div css={styles.fbGrantPermissionActionContainer}>
                  <div>
                    <ThemeButton
                      disabled={!confirmedNotification}
                      theme="blue"
                      text={selectedAccount?.activeToken ? 'Next' : 'Edit Settings'}
                      width="max-content"
                      onClick={onClickConnect}
                    />
                  </div>
                  <div>*{t('Annotation.Enable button with checking a checkbox below')}</div>
                </div>
              </DialogAction>
            </>
          ) : dialog === DialogStatus.TERMS_CONDITION ? (
            <>
              <DialogContent>
                <DialogTitle>{t('TermsConditionsPayment')}</DialogTitle>
                <DialogDescription>
                  {t('TheCampaignPeriodIs', {
                    start: localizedDateFormatter(startDate, 'PPP', i18n.language as AppLanguage),
                    end: localizedDateFormatter(endDate, 'PPP', i18n.language as AppLanguage),
                  })}
                </DialogDescription>
                {maximumRevenuePerInfluencer && (
                  <DialogDescription>
                    {t('RevenueLimitGuideLine', { price: formatIntNumber(maximumRevenuePerInfluencer), currency })}
                  </DialogDescription>
                )}
                <DialogDescription>
                  {t('RevenueQuestionMask', { price: formatIntNumber(minimumPaymentAmount), currency })}
                </DialogDescription>
                <DialogDescription>{t('Terms1')}</DialogDescription>
                <DialogDescription>{t('Terms2')}</DialogDescription>
                <DialogDescription>{t('Terms3')}</DialogDescription>
                <DialogDescription>{t('Terms4')}</DialogDescription>
              </DialogContent>
              <DialogAction>
                <ThemeButton text="Close" width="max-content" onClick={onCloseDialog} />
                <ThemeButton
                  theme="blue"
                  text={isFacebookCampaign || isTiktokCampaign ? 'Next' : 'Join Now'}
                  width="max-content"
                  onClick={onClickTermConditionNext}
                />
              </DialogAction>
            </>
          ) : dialog === DialogStatus.TIKTOK_RECONNECT ? (
            <>
              <DialogContent>
                <DialogTitle>{t('pageTitle.Grant permission')}</DialogTitle>
                <DialogDescription>
                  {t(
                    'Annotation.In order to fetch your post to the campaign, AnyCreator needs access to your public information'
                  )}
                </DialogDescription>
                <TiktokInfoContainer>
                  <div>{t('Tutorial.Text.TiktokGrantPermissionTwo')}</div>
                  <div>{t('Annotation.Read your public videos on TikTok')}</div>
                  <div>{t('Annotation.TikTok’s access permission page')}</div>
                  <div>
                    {tiktokGrantPermissionSteps.map((step, index) => {
                      const { description, image } = step;

                      return (
                        <div css={styles.stepContainer} key={index}>
                          <div>{index + 1}</div>
                          <div>{image}</div>
                          <div>{description}</div>
                        </div>
                      );
                    })}
                  </div>
                </TiktokInfoContainer>

                <div css={{ display: 'flex', justifyContent: 'center' }}>
                  <CheckBox
                    checked={confirmedNotification}
                    label="Label.I have confirmed this notification"
                    onCheckedChange={val => setConfirmedNotification(val)}
                  />
                </div>
              </DialogContent>
              <DialogAction>
                <ThemeButton
                  disabled={!confirmedNotification}
                  theme="blue"
                  text="Reconnect"
                  width="max-content"
                  onClick={onClickTiktokReconnect}
                />
              </DialogAction>
            </>
          ) : dialog === DialogStatus.REQUIRED_SIGN_IN ? (
            <>
              <DialogContent>
                <DialogTitle>{t('Required sign-in with social platform again')}</DialogTitle>
                <DialogDescription>{t('Dialog.OauthJoinCampaign')}</DialogDescription>
              </DialogContent>
              <DialogAction>
                <ThemeButton text="Close" width="max-content" onClick={onCloseDialog} />
                <ThemeButton theme="blue" text="OK" width="max-content" onClick={onClickConnect} />
              </DialogAction>
            </>
          ) : null}
        </ModalWrapper>
      </DialogModal>

      <ThemeButton
        css={{ height }}
        disabled={!isAbleToJoin || loading}
        theme="blue"
        text={isSelection ? 'Apply' : 'Join Now'}
        borderRadius="5px"
        fontSize="12px"
        onClick={onClickJoinNow}
      />
    </div>
  );
};

const DialogAction = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px;

  & > button:nth-of-type(2) {
    margin-left: 8px;
  }

  @media (max-width: ${ViewportType.SMALL}px) {
    & > button {
      height: 40px;
      width: fill-available;
    }
  }
`;

const DialogContent = styled.div`
  border-bottom: 1px solid #dee5ec;
  padding: 16px;
  overflow-y: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  font-size: 12px;
`;

const DialogDescription = styled.div`
  color: #27313b;
  font-size: 13px;
  margin-bottom: 16px;
`;

const DialogTitle = styled.div`
  color: #27313b;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
`;

const StepDescription = styled.div`
  align-items: center;
  color: #27313b;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  gap: 4px;
`;

const TiktokInfoContainer = styled.div`
  border: 1px solid #dee5ec;
  border-radius: 5px;
  margin-bottom: 32px;
  padding: 24px 16px;
  text-align: center;

  & > div:nth-of-type(1) {
    color: #27313b;
    display: flex;
    font-size: 14px;
    gap: 16px;
    justify-content: center;
    margin-bottom: 8px;

    & > ul {
      display: grid;
      gap: 16px;

      & > li {
        list-style: disc;
        text-align: left;
        white-space: pre-line;
        margin: 0 16px;
      }
    }
  }

  & > div:nth-of-type(2) {
    border-bottom: 1px solid #eef3f7;
    color: #27313b;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 24px;
    padding-bottom: 16px;
  }

  & > div:nth-of-type(3) {
    color: #27313b;
    font-size: 14px;
    margin-bottom: 24px;
  }

  & > div:nth-of-type(4) {
    display: flex;
    gap: 12px;
    justify-content: center;
  }

  & > div:nth-of-type(5) {
    border-top: 1px solid #eef3f7;
    display: flex;
    margin-top: 24px;
    justify-content: center;
    padding-top: 32px;
  }
`;

const styles = {
  addressAlertContainer: css`
    border-left: 4px solid #ffb63d;
    border-radius: 3px;
    box-shadow: 0 1px 5px rgba(39, 49, 59, 0.2);
    padding: 12px 16px;

    /* stylelint-disable no-descending-specificity */
    & > div {
      color: #27313b;
      font-size: 13px;
      font-weight: 600;
      margin-bottom: 12px;
    }

    & > button {
      border: 1px solid #dee5ec;
      border-radius: 5px;
      color: #6e7c89;
      font-size: 12px;
      font-weight: 600;
      height: 40px;
      width: 279px;

      @media (max-width: ${ViewportType.TABLET}px) {
        width: 100%;
      }
    }
  `,
  closeBtn: css`
    align-items: center;
    background-color: #000;
    border-radius: 50%;
    display: flex;
    height: 40px;
    justify-content: center;
    position: absolute;
    right: -16px;
    top: -16px;
    width: 40px;
    z-index: 10;
  `,
  connectSocialAcc: css`
    color: #27313b;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  `,
  fbGrantPermissionActionContainer: css`
    display: grid;
    gap: 4px;

    @media (max-width: ${ViewportType.SMALL}px) {
      width: fill-available;
    }

    & > div:nth-of-type(1) {
      display: flex;
      justify-content: flex-end;

      @media (max-width: ${ViewportType.SMALL}px) {
        & > button {
          height: 40px;
          width: fill-available;
        }
      }
    }

    & > div:nth-of-type(2) {
      color: #6e7c89;
      font-size: 11px;
    }
  `,
  fbGrantPermissionInfoContainer: css`
    border: 1px solid #dee5ec;
    border-radius: 5px;
    margin-bottom: 32px;
    padding: 24px 16px;

    & > div:nth-of-type(1) {
      border-bottom: 1px solid #eef3f7;
      color: #27313b;
      font-size: 14px;
      margin-bottom: 24px;
      padding-bottom: 16px;
    }

    & > div:nth-of-type(2) {
      color: #27313b;
      font-size: 14px;
      margin-bottom: 16px;
      text-align: center;
    }

    & > div:nth-of-type(3) {
      display: flex;
      justify-content: center;
    }
  `,
  selectAccountTitle: css`
    color: #27313b;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
  `,
  socialMediaContainer: css`
    align-items: center;
    border: 1px solid #dee5ec;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    margin: 4px;
    padding: 8px;
    position: relative;

    & > img:nth-of-type(1) {
      margin-left: 8px;
    }

    & > img:nth-of-type(2) {
      border: 3px solid #fff;
      margin-left: -10px;
    }

    & > div:nth-of-type(2) {
      display: grid;
      margin-left: 8px;
      width: 53%;

      @media (max-width: ${ViewportType.SMALL}px) {
        width: 50%;
      }

      & > div:nth-of-type(1) {
        color: #27313b;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }

      & > div:nth-of-type(2) {
        color: #6e7c89;
        font-size: 12px;
      }
    }
  `,
  socialMediaOverlay: css`
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    color: #fff;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    height: 100%;
    justify-content: center;
    margin-left: -8px !important;
    position: absolute;
    top: 0;
    width: 100%;
  `,
  socialMediasContainer: css`
    display: flex;
    flex-wrap: wrap;
  `,
  stepContainer: css`
    position: relative;

    & > div:nth-of-type(1) {
      align-items: center;
      background-color: #27313b;
      border-radius: 50%;
      color: #fff;
      display: flex;
      font-size: 12px;
      font-weight: 600;
      height: 24px;
      justify-content: center;
      left: -10px;
      position: absolute;
      top: -10px;
      width: 24px;
      z-index: 1;
    }

    & > div:nth-of-type(2) {
      margin-bottom: 8px;
    }

    & > div:nth-of-type(3) {
      display: flex;
      justify-content: center;
    }
  `,
  switch: css`
    & > button {
      height: 18px;
      width: 42px;

      & > span {
        height: 15px;
        transform: translate(18px) !important;
        width: 15px;
      }

      & > label {
        font-size: 10px;
        top: 3px;

        [dir='rtl'] & {
          right: 22px;
        }
      }

      [dir='rtl'] & {
        direction: ltr;
      }
    }
  `,
};

export default JoinButton;
