import React from 'react';
import { useTranslation } from 'react-i18next';
import { Status, StatusFill } from '@anymindgroup/any-ui-react';
import { LineBroadcastStatus } from '@src/__generated__/globalTypes';

type BroadcastStatusType = typeof LineBroadcastStatus[keyof typeof LineBroadcastStatus];

const BroadcastStatus = ({ status }: { status: BroadcastStatusType }) => {
  const { t } = useTranslation();
  const broadcastStatusColors: Record<LineBroadcastStatus, StatusFill> = {
    [LineBroadcastStatus.DRAFT]: 'yellow',
    [LineBroadcastStatus.SCHEDULED]: 'purple',
    [LineBroadcastStatus.DELIVERED]: 'green',
    [LineBroadcastStatus.ERROR]: 'red',
    [LineBroadcastStatus.SENDING]: 'grey',
  };

  return <Status color={broadcastStatusColors[status]}>{t(status)}</Status>;
};

export default BroadcastStatus;
