import * as React from 'react';
import css from '@emotion/css';
import { APP_NAME } from '@src/libs/constant';
import * as StyledComponents from './StyledComponents';

const PrivacyContentJP = () => (
  <StyledComponents.Wrapper>
    <StyledComponents.PrivacyTitle title={`${APP_NAME}プライバシーポリシー`} />
    <StyledComponents.PrivacyText>
      AnyMind Group株式会社（以下「当社」といいます。）は、当社の提供するサービス {`${APP_NAME}`}（
      <StyledComponents.Anchor href="https://app.any-creator.com/" target="_blank">
        https://app.any-creator.com/
      </StyledComponents.Anchor>
      、以下「本サービス」といいます。）における、ユーザーについての個人情報を含む利用者情報の取扱いについて、以下のとおりプライバシーポリシー（以下「本ポリシー」といいます。）を定めます。
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="1.	収集する利用者情報及び収集方法">
      <StyledComponents.PrivacyText>
        本ポリシーにおいて、「利用者情報」とは、ユーザーの識別に係る情報、通信サービス上の行動履歴、その他ユーザーまたはユーザーの端末に関連して生成または蓄積された情報であって、本ポリシーに基づき当社が収集するものを意味するものとします。
      </StyledComponents.PrivacyText>
      <StyledComponents.PrivacyText>
        本サービスにおいて当社が収集する利用者情報は、その収集方法に応じて、以下のようなものとなります。
      </StyledComponents.PrivacyText>
      <StyledComponents.PrivacyText css={orderedListStyle}>
        (1) ユーザーからご提供いただく情報 <br />
        本サービスを利用するために、または本サービスの利用を通じてユーザーからご提供いただく情報は以下のとおりです。
      </StyledComponents.PrivacyText>
      <StyledComponents.ListWrapper css={unorderedListStyle}>
        <StyledComponents.ListWrapper>
          <StyledComponents.ListItem>氏名、生年月日、性別、職業等プロフィールに関する情報</StyledComponents.ListItem>
          <StyledComponents.ListItem>メールアドレス、電話番号、住所等連絡先に関する情報</StyledComponents.ListItem>
          <StyledComponents.ListItem>銀行口座などの支払い先に関する情報</StyledComponents.ListItem>
          <StyledComponents.ListItem>ユーザーの肖像を含む静止画情報</StyledComponents.ListItem>
          <StyledComponents.ListItem>
            入力フォームその他当社が定める方法を通じてユーザーが入力または送信する情報
          </StyledComponents.ListItem>
        </StyledComponents.ListWrapper>
      </StyledComponents.ListWrapper>

      <StyledComponents.PrivacyText css={orderedListStyle}>
        (2)
        ユーザーが本サービスの利用において、他のサービスと連携を許可することにより、当該他のサービスからご提供いただく情報
        <br />
        ユーザーが、本サービスを利用するにあたり、ソーシャルネットワーキングサービス等の他のサービスとの連携を許可した場合には、その許可の際にご同意いただいた内容に基づき、以下の情報を当該外部サービスから収集します。また、当社は、ユーザーのソーシャルメディアでの通常のコメントに関する感情を分析する「ソーシャル・リスニング・ツール」を含む情報を測定・収集するソフトウェアツールを使用します。
      </StyledComponents.PrivacyText>
      <StyledComponents.ListWrapper css={unorderedListStyle}>
        <StyledComponents.ListWrapper>
          <StyledComponents.ListItem>当該外部サービスでユーザーが利用するID</StyledComponents.ListItem>
          <StyledComponents.ListItem>
            その他当該外部サービスのプライバシー設定によりユーザーが連携先に開示を認めた情報
          </StyledComponents.ListItem>
        </StyledComponents.ListWrapper>
      </StyledComponents.ListWrapper>

      <StyledComponents.PrivacyText css={orderedListStyle}>
        (3) ユーザーが本サービスを利用するにあたって、当社が収集する情報
        <br />
        当社は、本サービスへのアクセス状況やそのご利用方法に関する情報を収集することがあります。これには以下の情報が含まれます。
      </StyledComponents.PrivacyText>
      <StyledComponents.ListWrapper css={unorderedListStyle}>
        <StyledComponents.ListWrapper>
          <StyledComponents.ListItem>リファラ</StyledComponents.ListItem>
          <StyledComponents.ListItem>IPアドレス</StyledComponents.ListItem>
          <StyledComponents.ListItem>デバイスID</StyledComponents.ListItem>
          <StyledComponents.ListItem>位置データ</StyledComponents.ListItem>
          <StyledComponents.ListItem>
            ブラウザーの種類とバージョン等のコンピューター情報と接続情報
          </StyledComponents.ListItem>
          <StyledComponents.ListItem>タイムゾーン（時間帯）設定情報</StyledComponents.ListItem>
          <StyledComponents.ListItem>ブラウザーのプラグインの種類とバージョン</StyledComponents.ListItem>
          <StyledComponents.ListItem>オペレーティングシステム</StyledComponents.ListItem>
          <StyledComponents.ListItem>購入履歴</StyledComponents.ListItem>
          <StyledComponents.ListItem>サーバーアクセスログに関する情報</StyledComponents.ListItem>
          <StyledComponents.ListItem>Cookie、ADID、IDFAその他の識別子</StyledComponents.ListItem>
        </StyledComponents.ListWrapper>
      </StyledComponents.ListWrapper>
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="2. 利用目的">
      <StyledComponents.PrivacyText>
        本サービスのサービス提供にかかわる利用者情報の具体的な利用目的は以下のとおりです。
      </StyledComponents.PrivacyText>
      <StyledComponents.ListWrapper>
        <StyledComponents.ListItem>
          (1) 本サービスに関する登録の受付、本人確認、ユーザー認証、ユーザー設定の記録、利用料金の決
          <br />
          済計算等本サービスの提供、維持、保護及び改善のため
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          (2)
          当社の提供するサービス（AnyTag（URL：https://app.anytag.jp/））内に利用者情報を配信または表示し、SNSキャンペーンを実施する事業者と利用者のマッチング機会の創出、SNSキャンペーン商品の送付に利用するため
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>(3) ご協力いただいた調査等に対する謝礼等をお送りするため</StyledComponents.ListItem>
        <StyledComponents.ListItem>(4) ユーザーのトラフィック測定及び行動測定のため</StyledComponents.ListItem>
        <StyledComponents.ListItem>(5) 広告の配信、表示及び効果測定のため</StyledComponents.ListItem>
        <StyledComponents.ListItem>
          (6) 本サービスに関するご案内、お問い合わせ等への対応のため
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          (7) 本サービスに関する当社の規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>(8) 本サービスに関する規約等の変更などを通知するため</StyledComponents.ListItem>
      </StyledComponents.ListWrapper>
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="3. 利用中止要請の方法">
      ユーザーは、当社に対して電子メールを送信する（宛先：
      <StyledComponents.Anchor href="mailto:ac-jp@anymindgroup.com" target="_blank">
        ac-jp@anymindgroup.com
      </StyledComponents.Anchor>
      ）ことにより、利用者情報の全部または一部についてその収集又は利用の停止を求めることができ、この場合、当社は速やかに、当社の定めるところに従い、その利用を停止します。なお利用者情報の項目によっては、その収集または利用が本サービスの前提となるため、当社所定の方法により本サービスを退会した場合に限り、当社はその収集又は利用を停止します。
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="4. 第三者提供">
      <StyledComponents.PrivacyText>
        当社は、利用者情報のうち、個人情報については、あらかじめユーザーの同意を得ないで、第三者（日本国外にある者を含みます。但し、当社の親会社、最終親会社、子会社及び関連会社を除きます。）に提供しません。但し、次に掲げる必要があり第三者（日本国外にある者を含みます。）に提供する場合はこの限りではありません。
      </StyledComponents.PrivacyText>
      <StyledComponents.ListWrapper>
        <StyledComponents.ListItem>
          (1) 当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          (2) 合併その他の事由による事業の承継に伴って個人情報が提供される場合
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          (3)
          国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ユーザーの同意を得ることによって当該事務の遂行に支障を及ぼすおそれがある場合
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          (4) その他、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）その他の法令で認められる場合
        </StyledComponents.ListItem>
      </StyledComponents.ListWrapper>
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="5. 共同利用">
      <StyledComponents.ListWrapper>
        <StyledComponents.ListItem>
          (1) 共同して利用される個人情報の項目
          <br />
          　　本ポリシー第1項で定める情報全て
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          (2) 共同して利用する者の範囲
          <br />
          　　当社の提供するサービス（AnyTag（URL：https://app.anytag.jp/））内において、SNSキャンペーンの実施を検討する事業者
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          (3) 共同して利用する者の利用目的
          <br />
          　　SNSキャンペーンへの起用及びSNSキャンペーン商品の送付
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          (4) 個人情報の管理について責任を有する者の氏名または名称
          <br />
          　　AnyMind Japan株式会社
          <br />
          小堤　音彦
        </StyledComponents.ListItem>
      </StyledComponents.ListWrapper>
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="6. 個人情報の開示">
      当社は、ユーザーから、個人情報保護法の定めに基づき個人情報の開示を求められたときは、ユーザーご本人からのご請求であることを確認の上で、ユーザーに対し、遅滞なく開示を行います（当該個人情報が存在しないときにはその旨を通知いたします。）。但し、個人情報保護法その他の法令により、当社が開示の義務を負わない場合は、この限りではありません。なお、個人情報の開示につきましては、手数料（1件あたり1,000円）を頂戴しておりますので、あらかじめ御了承ください。
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="7. 個人情報の訂正及び利用停止等">
      <StyledComponents.ListWrapper>
        <StyledComponents.ListItem>
          (7-1)
          当社は、ユーザーから、(1)個人情報が真実でないという理由によって個人情報保護法の定めに基づきその内容の訂正を求められた場合、及び(2)あらかじめ公表された利用目的の範囲を超えて取扱われているという理由または偽りその他不正の手段により収集されたものであるという理由により、
          個人情報保護法の定めに基づきその利用の停止を求められた場合には、ユーザーご本人からのご請求であることを確認の上で遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正または利用停止を行い、その旨をユーザーに通知します。なお、訂正または利用停止を行わない旨の決定をしたときは、ユーザーに対しその旨を通知いたします。
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          (7-2)
          当社は、ユーザーから、ユーザーの個人情報について消去を求められた場合、当社が当該請求に応じる必要があると判断した場合は、ユーザーご本人からのご請求であることを確認の上で、個人情報の消去を行い、その旨をユーザーに通知します。
        </StyledComponents.ListItem>
        <StyledComponents.ListItem>
          (7-3)
          個人情報保護法その他の法令により、当社が訂正等または利用停止等の義務を負わない場合は、6-1および6-2の規定は適用されません。
        </StyledComponents.ListItem>
      </StyledComponents.ListWrapper>
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText
      subTitle="8. お問い合わせ窓口
"
    >
      ご意見、ご質問、苦情のお申出その他利用者情報の取扱いに関するお問い合わせは、下記の窓口までお願いいたします。
      <br />
      住所：〒106-0046
      <br />
      AnyMind Japan株式会社
      <br />
      個人情報取扱責任者：小堤 音彦
      <br />
      連絡先：03-6384-5540
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText subTitle="9. プライバシーポリシーの変更手続">
      当社は、必要に応じて、本ポリシーを変更します。但し、法令上ユーザーの同意が必要となるような本ポリシーの変更を行う場合、変更後の本ポリシーは、当社所定の方法で変更に同意したユーザーに対してのみ適用されるものとします。なお、当社は、本ポリシーを変更する場合には、変更後の本ポリシーの施行時期及び内容を当社のウェブサイト上での表示その他の適切な方法により周知し、またはユーザーに通知するものとし、施行日以降も本サービスの利用を継続した場合には、変更後の本ポリシーに同意したものとみなします。
    </StyledComponents.PrivacyText>

    <StyledComponents.PrivacyText>【2021年4月1日制定】</StyledComponents.PrivacyText>
  </StyledComponents.Wrapper>
);

const orderedListStyle = css`
  padding: 0 0 8px 16px;
`;

const unorderedListStyle = css`
  padding-left: 24px;
  margin-bottom: 8px;
`;

export default PrivacyContentJP;
