import { useMutation, useQuery } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import JOIN_MARKETPLACE from './mutations/JoinMarketplaceAffiliate.graphql';
import {
  JoinMarketplaceAffiliate,
  JoinMarketplaceAffiliateVariables,
} from './mutations/__generated__/JoinMarketplaceAffiliate';
import JOINABLE_PROMOTION_METHODS from './queries/MarketplaceJoinablePromotionMethods.graphql';
import { MarketplaceJoinablePromotionMethods } from './queries/__generated__/MarketplaceJoinablePromotionMethods';

const useJoinablePromotionMethods = () => {
  const { enqueueSnackbar, t } = useQueryHelper();

  const { data, loading } = useQuery<MarketplaceJoinablePromotionMethods>(JOINABLE_PROMOTION_METHODS, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
  });

  const [joinMarletplaceAffiliate] = useMutation<JoinMarketplaceAffiliate, JoinMarketplaceAffiliateVariables>(
    JOIN_MARKETPLACE
  );

  return {
    data,
    loading,
    joinMarletplaceAffiliate,
  };
};

export default useJoinablePromotionMethods;
