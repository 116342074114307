import { useMutation } from '@apollo/client';
import { ApolloError } from '@apollo/client';
import { getMessagesFromApolloError, getMessagesFromFetchResult } from '@src/libs/error';
import { useUuumInfluencerView } from '@src/libs/hooks';
import { AnyXAuthSocialAccountType, AuthSocialAccountType, Genders } from '@src/__generated__/globalTypes';
import * as SOCIAL_AUTH_SIGN_UP from './AnyXSocialAuthSignUp.graphql';
import * as SOCIAL_AUTH_PARTNER_SIGN_UP from './AnyXSocialAuthSignUpPartner.graphql';
import * as SOCIAL_AUTH_TALENT_SIGN_UP from './AnyXSocialAuthSignUpTalent.graphql';
import { AnyXSocialAuthSignUp, AnyXSocialAuthSignUpVariables } from './__generated__/AnyXSocialAuthSignUp';
import {
  AnyXSocialAuthSignUpPartner,
  AnyXSocialAuthSignUpPartnerVariables,
} from './__generated__/AnyXSocialAuthSignUpPartner';
import {
  AnyXSocialAuthSignUpTalent,
  AnyXSocialAuthSignUpTalentVariables,
} from './__generated__/AnyXSocialAuthSignUpTalent';
import { UuumSocialAuthSignUp, UuumSocialAuthSignUpVariables } from './__generated__/UuumSocialAuthSignUp';
import * as SOCIAL_AUTH_UUUM_SIGN_UP from './UuumSocialAuthSignUp.graphql';

interface SignupRequestPayload {
  agencyType?: string | null;
  agencyHash?: string | null;
  payload: {
    callbackUrl?: string;
    categoryIds: number[];
    countryId: string;
    dateOfBirth: string;
    email: string;
    gender: Genders;
    name: string;
    phoneNumber: string;
    provider: AnyXAuthSocialAccountType | AuthSocialAccountType;
    regionId: number;
    response: string;
    uuid: string;
  };
}

export const useSignup = () => {
  const { isUuumView } = useUuumInfluencerView();
  const [socialAuthSignUp] = useMutation<AnyXSocialAuthSignUp, AnyXSocialAuthSignUpVariables>(SOCIAL_AUTH_SIGN_UP);
  const [socialPartnerAuthSignUp] = useMutation<AnyXSocialAuthSignUpPartner, AnyXSocialAuthSignUpPartnerVariables>(
    SOCIAL_AUTH_PARTNER_SIGN_UP
  );
  const [socialTalentAuthSignUp] = useMutation<AnyXSocialAuthSignUpTalent, AnyXSocialAuthSignUpTalentVariables>(
    SOCIAL_AUTH_TALENT_SIGN_UP
  );
  const [uuumSocialAuthSignUp] = useMutation<UuumSocialAuthSignUp, UuumSocialAuthSignUpVariables>(
    SOCIAL_AUTH_UUUM_SIGN_UP
  );

  const signup = async ({ agencyType, agencyHash, payload }: SignupRequestPayload) => {
    if (isUuumView) {
      return await uuumSocialAuthSignUp({
        variables: {
          input: {
            uuid: payload.uuid,
            name: payload.name,
            countryId: payload.countryId,
            regionId: payload.regionId,
            email: payload.email,
            dateOfBirth: payload.dateOfBirth,
            gender: payload.gender,
            categoryIds: payload.categoryIds,
            phoneNumber: payload.phoneNumber,
          },
        },
      });
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { callbackUrl, response, ...anyXSocialSignUpPayload } = payload;

    switch (agencyType) {
      case 'partner':
        return await socialPartnerAuthSignUp({
          variables: {
            input: {
              ...anyXSocialSignUpPayload,
              provider: payload.provider as AnyXAuthSocialAccountType,
              partnerUserOwnerHash: agencyHash as string,
            },
          },
        });

      case 'talentAgency':
        return await socialTalentAuthSignUp({
          variables: {
            input: {
              ...anyXSocialSignUpPayload,
              provider: payload.provider as AnyXAuthSocialAccountType,
              talentAgencyOwnerHash: agencyHash as string,
            },
          },
        });

      default:
        return await socialAuthSignUp({
          variables: {
            input: {
              ...anyXSocialSignUpPayload,
              provider: payload.provider as AnyXAuthSocialAccountType,
            },
          },
        });
    }
  };

  const requestSignUp = async (props: SignupRequestPayload) =>
    await signup(props)
      .then(result => {
        if (result?.data) {
          let token = null;
          if ((result.data as AnyXSocialAuthSignUp).anyXSocialAuthSignUp) {
            token = (result.data as AnyXSocialAuthSignUp).anyXSocialAuthSignUp?.token || null;
          } else if ((result.data as AnyXSocialAuthSignUpPartner).anyXSocialAuthSignUpPartner) {
            token = (result.data as AnyXSocialAuthSignUpPartner).anyXSocialAuthSignUpPartner?.token || null;
          } else if ((result.data as AnyXSocialAuthSignUpTalent).anyXSocialAuthSignUpTalent) {
            token = (result.data as AnyXSocialAuthSignUpTalent).anyXSocialAuthSignUpTalent?.token || null;
            // for UUUM iframe users
          } else if ((result.data as UuumSocialAuthSignUp).socialSignUp) {
            token = (result.data as UuumSocialAuthSignUp).socialSignUp?.token || null;
          }

          return {
            token,
            refreshToken: null,
            errors: [] as string[],
          };
        } else {
          return {
            token: null,
            refreshToken: null,
            errors: getMessagesFromFetchResult(result),
          };
        }
      })
      .catch((e: ApolloError) => {
        if (window.opener) {
          window.opener.postMessage({ redirectPath: '/', errorMsg: e.message, needLocationReload: false }, '*');
        }

        return { token: null, refreshToken: null, errors: getMessagesFromApolloError(e) };
      });

  return {
    requestSignUp,
  };
};
