import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import TextForm from '@src/components/molecules/TextForm';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import Icomoon from '@src/components/atoms/Icomoon';
import { useHelpCenterUrl } from '@src/libs/help';

interface FormValues {
  lineChannelId: string;
  lineChannelSecret: string;
}

const Step1 = ({ formState: { errors }, register }: UseFormReturn<FormValues>) => {
  const { t } = useTranslation();
  const { lineConnectHelp } = useHelpCenterUrl();

  return (
    <form>
      <TextForm
        title="LINE Channel ID"
        isRequired
        placeholder={t('TextForm.LINE Channel ID')}
        error={!!errors.lineChannelId}
        {...register('lineChannelId')}
      />
      <ErrorMessage message={errors.lineChannelId?.message} />
      <TextForm
        title="LINE Channel secret"
        isRequired
        placeholder={t('TextForm.LINE Channel secret')}
        error={!!errors.lineChannelSecret}
        css={styles.channelSecret}
        {...register('lineChannelSecret')}
      />
      <ErrorMessage message={errors.lineChannelSecret?.message} />
      <div css={styles.descriptionWrapper}>
        <p css={styles.description}>
          {t('Please attach “Line Official Account” & “LINE Developers” in order to input LINE Channel ID/Secret')}
        </p>
        <div css={styles.helpLinkWrapper}>
          <Icomoon icon="help-filled" />
          <a href={lineConnectHelp} rel="noopener noreferrer" target="_blank" css={styles.helpLink}>
            {t('How to check LINE Channel ID & LINE Channel secret?')}
          </a>
        </div>
      </div>
    </form>
  );
};
export default Step1;

const styles = {
  channelSecret: css`
    margin-top: 24px;
  `,
  descriptionWrapper: css`
    margin: 32px 0 24px;
  `,
  description: css`
    font-size: 12px;
    line-height: 140%;
    color: #27313b;
  `,
  helpLinkWrapper: css`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 8px;
  `,
  helpLink: css`
    color: #3892e5;

    &:hover {
      text-decoration: underline;
    }
  `,
};
