import React from 'react';
import { css } from '@emotion/core';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import { switchSocialConnectIcon, switchSocialButtonColor, AuthenticationType } from '@src/libs/SocialMedia';
import ThemeButton, { ButtonProps } from './ThemeButton';

interface SocialButtonProps {
  className?: string;
  title: string;
  iconSize?: string;
  iconSrc?: string;
  onClick?: () => void;
  socialMedia?: SocialAccountType | AuthenticationType;
  disabled?: boolean;
  hasIcon?: boolean;
  comboButton?: boolean;
  hasArrow?: boolean;
  href?: string;
  light?: boolean;
  size?: ButtonProps['size'];
  width?: string;
  theme?: ButtonProps['theme'];
}

const SocialButton = (props: SocialButtonProps) => {
  const {
    className,
    socialMedia,
    iconSize = '16px',
    iconSrc,
    title,
    onClick,
    disabled,
    hasIcon = true,
    comboButton,
    href,
    light,
    size,
    width = '100%',
    theme = 'blue', // just to have font-color be #fff, background is custom anyway
  } = props;
  const background = socialMedia ? switchSocialButtonColor(socialMedia, light) : 'initial';
  const isInstagram = socialMedia === SocialAccountType.INSTAGRAM;
  const bgImage = comboButton
    ? 'linear-gradient(100deg, #4460a0 26%, #ce3396 74%, #ff6641)'
    : isInstagram
    ? light
      ? 'linear-gradient(116.92deg, #F9BB67 22.14%, #DA337E 90.57%)'
      : 'linear-gradient(72deg, #ae3bb0, #ff6641)'
    : 'none';
  const borderColor = bgImage !== 'none' ? bgImage : background;

  return (
    <ThemeButton
      theme={theme}
      prefixIcon={
        hasIcon ? (
          <img height={iconSize} width={iconSize} src={iconSrc || switchSocialConnectIcon(socialMedia, light)} />
        ) : undefined
      }
      text={title}
      onClick={onClick}
      href={href}
      disabled={disabled}
      fontSize={'12px'}
      size={size}
      width={width}
      css={[
        css`
          background: ${background};
          background-image: ${bgImage};
          border-color: ${borderColor};
          border-width: ${isInstagram ? '0' : '1px'};

          .btn-text {
            padding: 0 4px;
            margin: 0 auto;
          }

          &:hover {
            background: ${background};
            background-image: ${bgImage};
            opacity: 0.9;
          }

          .prefix-icon {
            padding: 0 12px;
            position: absolute;
            left: 0;

            [dir='rtl'] & {
              left: unset;
              right: 0;
              transform: scaleX(-1);
            }
          }
        `,
      ]}
      className={className}
    />
  );
};

export default SocialButton;
