import React, { useEffect } from 'react';
import { ApolloError } from '@apollo/client';
import InitialLoading from '@src/components/molecules/InitialLoading';
import { getConnectState } from '@src/libs/auth';
import { getMessagesFromFetchResult, ErrorTypes, UNEXPECTED_ERROR } from '@src/libs/error';
import { useQueryHelper } from '@src/libs/hooks';
import { switchSocialMediaName } from '@src/libs/SocialMedia';
import useCampaign from '@src/pages/Redirect/JoinCampaign/useCampaign';
import { generatePath, ROUTES } from '@src/shared/routes';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { useAuthProviderResponse } from '../hooks';
import useTiktokReconnect from './useTiktokReconnect';

const TiktokReconnect = () => {
  const { enqueueSnackbar, history, t } = useQueryHelper();
  const { provider, response } = useAuthProviderResponse(ROUTES.SETTINGS_SOCIAL_CONNECT);
  const { joinMarketplace } = useCampaign();
  const { socialAuthReconnectTikTok } = useTiktokReconnect();

  const reconnectTiktok = async () => {
    if (!provider) {
      enqueueSnackbar(t(UNEXPECTED_ERROR), { variant: 'error' });
      history.push(ROUTES.SETTINGS_SOCIAL_CONNECT);

      return;
    }

    const connectState = getConnectState();
    const variables = {
      input: {
        id: connectState?.reconnectTiktokAccountId || '',
        response,
      },
    };

    const { ok, errors } = await socialAuthReconnectTikTok({ variables })
      .then(result => {
        if (result.data?.anyXSocialAuthReconnectTikTok?.ok) {
          return { ok: true, errors: [] };
        } else {
          return { ok: false, errors: getMessagesFromFetchResult(result) };
        }
      })
      .catch((e: ApolloError) => ({
        ok: false,
        errors: [e?.graphQLErrors[0].message],
      }));

    if (ok && errors.length === 0) {
      if (connectState?.campaignId && connectState.reconnectTiktokAccountId) {
        try {
          const { data } = await joinMarketplace({
            variables: {
              input: {
                campaignId: Number(connectState.campaignId),
                socialAccountId: Number(connectState.reconnectTiktokAccountId),
                socialMedia: SocialAccountType.TIKTOK,
              },
            },
          });

          if (data?.joinMarketplaceV2?.ok) {
            sendAmplitudeEvent(eventTypes.joinMarketplace, { campaignId: connectState.campaignId });
            history.push(generatePath(ROUTES.MARKETPLACE_YOUR_JOB_ID, { id: connectState.campaignId }));
          }
        } catch (err) {
          enqueueSnackbar(t(err.message), { variant: 'error' });
          history.push(generatePath(ROUTES.MARKETPLACE_SEARCH_JOB_ID, { id: connectState.campaignId }));
        }
      } else {
        enqueueSnackbar('Success to Connect', { variant: 'success' });
        sendAmplitudeEvent(eventTypes.connectSocial, { socialMedia: switchSocialMediaName(SocialAccountType.TIKTOK) });
        history.push(ROUTES.SETTINGS_SOCIAL_CONNECT);
      }
    } else {
      let isTiktokGrantPermission = false;
      errors.forEach(error => {
        if (provider === SocialAccountType.TIKTOK && error === ErrorTypes.VIDEO_PERMISSION_MISSING) {
          isTiktokGrantPermission = true;
        } else {
          enqueueSnackbar(t(error), { variant: 'error' });
        }
      });

      history.push(
        isTiktokGrantPermission
          ? `${ROUTES.SETTINGS_SOCIAL_CONNECT_TIKTOK_GRANT_PERMISSION}?id=${connectState?.reconnectTiktokAccountId}`
          : ROUTES.SETTINGS_SOCIAL_CONNECT
      );
    }
  };

  useEffect(() => {
    reconnectTiktok();
  }, []);

  return <InitialLoading />;
};

export default TiktokReconnect;
