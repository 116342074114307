import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ThemeButton } from '@src/components/atoms/Button';
import Grid from '@src/components/atoms/Grid';
import { localizedDateFormatter } from '@src/components/atoms/List/DateColumn';
import TextCutter from '@src/components/atoms/TextCutter';
import { getDayfromDateString } from '@src/libs/date';
import { formatNumberWithCommas, formatPercent } from '@src/libs/format';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import { useQueryHelper } from '@src/libs/hooks';
import { switchImage } from '@src/libs/SocialMedia';
import {
  AnyXEngagementCampaignStatus,
  AnyXMarketplaceCampaignStatus,
  CampaignSocialMediaType,
  CampaignStatusForInfluencer,
  CampaignType,
  MarketplaceCampaignAppliedStatus,
  MarketplaceCampaignType,
  TTCMStatus,
} from '@src/__generated__/globalTypes';
import CampaignBudgetProgressBar from '../CampaignBudgetProgressBar';
import StatusIndicator from './StatusIndicator';

interface CampaignCardsProps {
  campaigns: {
    anyXStatus?: AnyXEngagementCampaignStatus | AnyXMarketplaceCampaignStatus | null;
    appliedStatus?: MarketplaceCampaignAppliedStatus | null;
    commissionRate?: number | null;
    currency: string;
    endDate: any;
    hasReport?: boolean;
    hasDraftPosts?: boolean;
    hasPostsCouponMissing?: boolean | null;
    id: number;
    isApplying?: boolean | null;
    isGiftingCampaign?: boolean | null;
    isTtcmCampaign?: boolean;
    marketplaceCampaignType?: MarketplaceCampaignType;
    remainingAmount?: number | null;
    revenuePerAction?: number | null;
    revenuePerClick?: number | null;
    revenuePerComment?: number | null;
    revenuePerFollower?: number | null;
    revenuePerLike?: number | null;
    revenuePerOrder?: number | null;
    revenuePerPost?: number | null;
    revenuePerShare?: number | null;
    revenuePerView?: number | null;
    rewardAmount?: number | null;
    socialMedias: CampaignSocialMediaType[];
    startDate: any;
    status: CampaignStatusForInfluencer;
    thumbNail: string;
    title: string;
    totalAmount?: number | null;
    ttcmStatus?: TTCMStatus | null;
    type: CampaignType;
  }[];
  isFinished?: boolean;
  isSearchCampaigns?: boolean;
  minCards?: number;
}

const CampaignCards = ({ campaigns, isFinished, isSearchCampaigns, minCards = 12 }: CampaignCardsProps) => {
  const [viewMore, setViewMore] = useState<boolean>(false);
  const { i18n, pathname, t } = useQueryHelper();
  const isJPLanguage = i18n.language === 'ja';

  const campaignList = viewMore ? campaigns : campaigns.slice(0, minCards);

  return (
    <div css={styles.container}>
      {campaignList.map(campaign => {
        const {
          anyXStatus,
          appliedStatus,
          commissionRate,
          currency,
          endDate,
          hasDraftPosts,
          hasPostsCouponMissing,
          hasReport,
          id,
          isGiftingCampaign,
          // isTtcmCampaign,
          marketplaceCampaignType,
          remainingAmount,
          revenuePerAction,
          revenuePerClick,
          revenuePerComment,
          revenuePerFollower,
          revenuePerLike,
          revenuePerOrder,
          revenuePerPost,
          revenuePerShare,
          revenuePerView,
          rewardAmount,
          socialMedias,
          startDate,
          status,
          title,
          thumbNail,
          totalAmount,
          ttcmStatus,
          type,
        } = campaign;

        const isCampaignFinish =
          (anyXStatus &&
            [AnyXEngagementCampaignStatus.INEFFECTIVE, AnyXMarketplaceCampaignStatus.INEFFECTIVE].includes(
              anyXStatus
            )) ||
          appliedStatus === MarketplaceCampaignAppliedStatus.REJECTED ||
          status === CampaignStatusForInfluencer.FINISHED;
        const isMarketplaceCampaign = type === CampaignType.MARKETPLACE;
        const isAffiliateCampaign =
          isMarketplaceCampaign && marketplaceCampaignType === MarketplaceCampaignType.AFFILIATE;
        const hasCardReward =
          isGiftingCampaign ||
          (isMarketplaceCampaign &&
            (commissionRate ||
              revenuePerAction ||
              revenuePerClick ||
              revenuePerComment ||
              revenuePerFollower ||
              revenuePerLike ||
              revenuePerOrder ||
              revenuePerPost ||
              revenuePerShare ||
              revenuePerView ||
              rewardAmount));
        const revenueRewards = [
          { title: 'RevenuePer.Action', value: revenuePerAction },
          { title: 'RevenuePer.Click', value: revenuePerClick },
          { title: 'RevenuePer.Comment', value: revenuePerComment },
          { title: 'RevenuePer.Follower', value: revenuePerFollower },
          { title: 'RevenuePer.Like', value: revenuePerLike },
          { title: 'RevenuePer.Order', value: revenuePerOrder },
          { title: 'RevenuePer.Post', value: revenuePerPost },
          { title: 'RevenuePer.Share', value: revenuePerShare },
          { title: 'RevenuePer.View', value: revenuePerView },
        ];

        const startDay = getDayfromDateString(startDate);
        const startDayNumber = isJPLanguage
          ? startDay.length > 2
            ? startDay.substring(0, 2)
            : startDay.substring(0, 1)
          : startDay.substring(0, 2);
        const startDayWord = isJPLanguage
          ? startDay.length > 2
            ? startDay.substring(2, 3)
            : startDay.substring(1, 2)
          : startDay.substring(2, 3);
        const endDay = getDayfromDateString(endDate);
        const endDayNumber = isJPLanguage
          ? endDay.length > 2
            ? endDay.substring(0, 2)
            : endDay.substring(0, 1)
          : endDay.substring(0, 2);
        const endDayWord = isJPLanguage
          ? endDay.length > 2
            ? endDay.substring(2, 3)
            : endDay.substring(1, 2)
          : endDay.substring(2, 3);

        return (
          <Grid key={id} lg={4} sm={6} xs={12}>
            <div css={styles.cardContainer}>
              <Link to={`${pathname}/${id}`}>
                <div css={styles.cardImgContainer}>
                  {!isFinished &&
                    (!isSearchCampaigns || status === CampaignStatusForInfluencer.OUT_OF_COUPON) &&
                    !(
                      status === CampaignStatusForInfluencer.UPCOMING &&
                      marketplaceCampaignType === MarketplaceCampaignType.NORMAL
                    ) &&
                    !(
                      isCampaignFinish &&
                      anyXStatus &&
                      [AnyXEngagementCampaignStatus.POSTING, AnyXMarketplaceCampaignStatus.POSTING].includes(anyXStatus)
                    ) &&
                    !(
                      isMarketplaceCampaign &&
                      appliedStatus === MarketplaceCampaignAppliedStatus.APPROVED &&
                      (!anyXStatus || anyXStatus === AnyXMarketplaceCampaignStatus.ASSESSING)
                    ) && (
                      <StatusIndicator
                        campaignStatus={anyXStatus}
                        campaignType={type}
                        hasPostsCouponMissing={hasPostsCouponMissing}
                        // TODO: to enable new flow when marketplace implement TTCM
                        // isTtcmCampaign={isTtcmCampaign}
                        ttcmStatus={ttcmStatus}
                      />
                    )}

                  {isCampaignFinish && (
                    <div css={styles.cardOverlayCampaignFinished}>
                      <div>
                        <div>{t('Label.Finished')}</div>
                        <div>{t('Annotation.We look forward to your next application')}</div>
                      </div>
                    </div>
                  )}
                  <img alt="campaignImg" src={thumbNail} />
                  <div css={styles.curveBottomLeft} />
                  <div css={styles.curveBottomRight} />
                </div>

                <div css={styles.cardInfoContainer}>
                  {marketplaceCampaignType === MarketplaceCampaignType.SELECTION &&
                    (isSearchCampaigns || appliedStatus === MarketplaceCampaignAppliedStatus.APPLYING) && (
                      <div css={styles.selectionIndicator}>{t('Selection')}</div>
                    )}

                  <CardTitleContainer isMarketplaceCampaign={isMarketplaceCampaign}>
                    {isMarketplaceCampaign ? <TextCutter lines={2} text={title} /> : <span>{title}</span>}
                  </CardTitleContainer>

                  <div css={styles.cardBudgetContainer}>
                    {!isAffiliateCampaign &&
                      !isGiftingCampaign &&
                      remainingAmount !== undefined &&
                      totalAmount !== undefined && (
                        <CampaignBudgetProgressBar remainingAmount={remainingAmount} totalAmount={totalAmount} />
                      )}
                  </div>

                  {hasCardReward ? (
                    <div css={styles.cardRewardPeriodContainer}>
                      <div>
                        <div>
                          {isMarketplaceCampaign && socialMedias[0] !== CampaignSocialMediaType.UNSELECT && (
                            <img alt="socialMediaIcon" height="15" src={switchImage(socialMedias[0])} width="15" />
                          )}
                          <span>{t('Reward amount')}</span>
                        </div>
                        {isGiftingCampaign ? (
                          <RewardAmount>{t('Gifting')}</RewardAmount>
                        ) : (
                          <div>
                            {!!commissionRate && (
                              <div>
                                <RewardAmount>{formatPercent(commissionRate, false, 0)}</RewardAmount>
                                &nbsp;
                                <Currency>{`% / ${t('Commission rate')}`}</Currency>
                              </div>
                            )}
                            {revenueRewards.map((revenue, revenueIndex) => {
                              const { title: revenueTitle, value } = revenue;

                              return (
                                value && (
                                  <div key={revenueIndex}>
                                    <RewardAmount>{formatNumberWithCommas(value, undefined, currency)}</RewardAmount>
                                    &nbsp;
                                    <Currency>{`${t(currency)} / ${t(revenueTitle)}`}</Currency>
                                  </div>
                                )
                              );
                            })}
                            {rewardAmount && (
                              <div>
                                <RewardAmount>{formatNumberWithCommas(rewardAmount, undefined, currency)}</RewardAmount>
                                &nbsp;
                                <Currency>{t(currency)}</Currency>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      <div>
                        <div>{t('Label.Campaign Period')}</div>
                        <div>
                          <MonthFont isJPLanguage={isJPLanguage}>
                            {localizedDateFormatter(new Date(startDate), 'MMM', i18n.language as AppLanguage)}
                          </MonthFont>
                          <DayFont>
                            {startDayNumber}
                            <DayWord>{startDayWord}</DayWord>
                          </DayFont>
                          <span css={styles.periodDash}>-</span>
                          <MonthFont isJPLanguage={isJPLanguage}>
                            {localizedDateFormatter(new Date(endDate), 'MMM', i18n.language as AppLanguage)}
                          </MonthFont>
                          <DayFont>
                            {endDayNumber}
                            <DayWord>{endDayWord}</DayWord>
                          </DayFont>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div css={styles.cardPeriodContainer}>
                      <div>{t('Label.Campaign Period')}</div>
                      <div>
                        <MonthFont isJPLanguage={isJPLanguage}>
                          {localizedDateFormatter(new Date(startDate), 'MMM', i18n.language as AppLanguage)}
                        </MonthFont>
                        <DayFont>
                          {startDayNumber}
                          <DayWord>{startDayWord}</DayWord>
                        </DayFont>
                        <span css={styles.periodDash}>-</span>
                        <MonthFont isJPLanguage={isJPLanguage}>
                          {localizedDateFormatter(new Date(endDate), 'MMM', i18n.language as AppLanguage)}
                        </MonthFont>
                        <DayFont>
                          {endDayNumber}
                          <DayWord>{endDayWord}</DayWord>
                        </DayFont>
                      </div>
                    </div>
                  )}
                </div>
              </Link>

              {((!isCampaignFinish && hasDraftPosts) || hasReport) && (
                <div css={styles.cardBtnContainer}>
                  {!isCampaignFinish && !isMarketplaceCampaign && (hasReport || hasDraftPosts) && (
                    <ButtonContainer>
                      <StyledLink to={`${pathname}/${id}/post`} margin={hasReport ? '0 8px 0 0' : 'auto'}>
                        {t('Button.Post List')}
                      </StyledLink>
                    </ButtonContainer>
                  )}
                  {hasReport && (
                    <ButtonContainer>
                      <StyledLink
                        to={isAffiliateCampaign ? `${pathname}/${id}/affiliate_report` : `${pathname}/${id}/report`}
                        margin={hasDraftPosts ? '0 0 0 8px' : 'auto'}
                      >
                        {t('Button.View Report')}
                      </StyledLink>
                    </ButtonContainer>
                  )}
                </div>
              )}
            </div>
          </Grid>
        );
      })}

      {campaigns.length > minCards && !viewMore && (
        <div css={styles.viewMoreBtnContainer}>
          <Grid md={4} sm={6} xs={12}>
            <ThemeButton text="View More" size="large" onClick={() => setViewMore(true)} />
          </Grid>
        </div>
      )}
    </div>
  );
};

const ButtonContainer = styled.div`
  width: fill-available;
`;

const CardTitleContainer = styled.div<{ isMarketplaceCampaign: boolean }>`
  display: grid;
  justify-content: center;
  ${({ isMarketplaceCampaign }) => (isMarketplaceCampaign ? 'height: 52px;' : '')}

  & > p {
    color: #27313b;
    font-size: 18px;
    font-weight: 600;
  }

  & > span {
    font-size: 18px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
`;

const Currency = styled.span`
  color: #6e7c89;
  font-size: 10px;
  font-weight: 600;
  word-break: keep-all;
`;

const DayFont = styled.span`
  color: #27313b;
  font-size: 12px;
  font-weight: 600;
`;

const DayWord = styled.span`
  color: #27313b;
  font-size: 11px;
  font-weight: 600;
`;

const MonthFont = styled.span<{ isJPLanguage: boolean }>`
  color: #27313b;
  font-size: 12px;
  font-weight: 600;
  ${({ isJPLanguage }) => !isJPLanguage && 'margin-right: 5px;'}
`;

const RewardAmount = styled.span`
  color: #27313b;
  font-size: 14px;
  font-weight: 600;
`;

const StyledLink = styled(Link, { shouldForwardProp: prop => isPropValid(prop) })<{ margin?: string }>`
  align-items: center;
  border: 1px solid #dee5ec;
  border-radius: 5px;
  color: #6e7c89;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 40px;
  justify-content: center;
  ${({ margin }) => (margin ? `margin: ${margin};` : '')}
  width: 100%;

  &:hover {
    background-color: #f6f8fa;
  }
`;

const styles = {
  cardBtnContainer: css`
    display: flex;
    padding: 0 24px 24px 24px;

    & > div {
      margin: 0 8px;
    }
  `,
  cardBudgetContainer: css`
    margin: 8px 0;
    min-height: 30px;
  `,
  cardContainer: css`
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0 3.9px 7.8px rgba(0, 0, 0, 0.05), 0 1.3px 9.1px #dee5ec;
    min-height: 327px;
    margin: 8px;
  `,
  cardImgContainer: css`
    background-color: #f6f8fa;
    position: relative;

    & > img {
      border-radius: 16px 16px 0 0;
      height: 208px;
      object-fit: cover;
      width: 100%;
    }
  `,
  cardInfoContainer: css`
    padding: 24px 24px 8px 24px;
  `,
  cardOverlayCampaignFinished: css`
    align-items: center;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 16px 16px 0 0;
    bottom: 0;
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;

    & > div {
      display: grid;
      height: fit-content;

      & > div:nth-of-type(1) {
        color: #fff;
        display: flex;
        flex-basis: 100%;
        font-size: 20px;
        font-weight: 600;
        justify-content: center;
        margin-bottom: 8px;
        text-transform: uppercase;
      }

      & > div:nth-of-type(2) {
        color: #fff;
        display: flex;
        flex-basis: 100%;
        font-size: 13px;
      }
    }
  `,
  cardOverlayCampaignSelectionIsApplying: css`
    align-items: center;
    background-color: rgba(255, 182, 61, 0.9);
    border-radius: 16px 16px 0 0;
    bottom: 0;
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;

    /* stylelint-disable no-descending-specificity */
    & > div {
      display: grid;
      height: fit-content;
      padding: 0 24px;

      & > div {
        color: #fff;
        display: flex;
        flex-basis: 100%;
        font-size: 13px;
        justify-content: center;
        text-align: center;
      }

      & > div:nth-of-type(1) {
        color: #fff;
        display: flex;
        flex-basis: 100%;
        font-size: 20px;
        font-weight: 600;
        justify-content: center;
        margin-bottom: 8px;
        text-transform: capitalize;
      }
    }
  `,
  cardPeriodContainer: css`
    align-items: center;
    background-color: #f6f8fa;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    height: 46px;
    margin: 16px 0 8px 0;

    & > div:nth-of-type(1) {
      align-items: center;
      color: #6e7c89;
      border-right: 1px solid #dee5ec;
      display: flex;
      flex-basis: 49%;
      font-size: 11px;
      font-weight: 600;
      height: 30px;
      justify-content: center;
    }

    & > div:nth-of-type(2) {
      align-items: center;
      display: flex;
      flex-basis: 49%;
      justify-content: center;
    }
  `,
  cardRewardPeriodContainer: css`
    display: flex;
    flex-wrap: wrap;
    padding: 16px 0;

    & > div {
      align-items: flex-start;
      display: grid;
      flex-basis: 50%;
      text-align: center;

      & > div:nth-of-type(1) {
        align-items: center;
        color: #6e7c89;
        display: flex;
        font-size: 11px;
        font-weight: 600;
        justify-content: center;
        margin-bottom: 3px;

        & > img {
          margin-right: 8px;
        }
      }
    }

    & > div:nth-of-type(1) {
      border-right: 1px solid #dee5ec;
      flex-basis: 49%;

      & > div:nth-of-type(2) {
        display: grid;
        justify-content: center;

        & > div {
          align-items: center;
          display: flex;
          justify-content: flex-start;
        }

        & > div:nth-of-type(n + 3) {
          display: none;
        }
      }
    }
  `,
  container: css`
    display: flex;
    flex-wrap: wrap;
  `,
  curveBottomLeft: css`
    height: 80px;
    margin-top: -80px;
    overflow: hidden;
    position: relative;
    width: 50%;

    &::before {
      border-radius: 50%;
      bottom: 0;
      box-shadow: -50px 50px 0 0 #fff;
      content: '';
      display: block;
      height: 200%;
      left: -16px;
      position: absolute;
      width: 240%;
    }
  `,
  curveBottomRight: css`
    float: right;
    height: 80px;
    margin-top: -80px;
    overflow: hidden;
    position: relative;
    width: 50%;

    &::before {
      border-radius: 50%;
      bottom: 0;
      box-shadow: 50px 50px 0 0 #fff;
      content: '';
      display: block;
      height: 200%;
      position: absolute;
      right: -16px;
      width: 240%;
    }
  `,
  periodDash: css`
    color: #27313b;
    font-weight: 600;
    margin: 0 5px;
  `,
  selectionIndicator: css`
    color: #ffb63d;
    font-size: 12px;
    font-weight: 700;
    margin-top: -22px;
    margin-bottom: 8px;
  `,
  viewMoreBtnContainer: css`
    display: flex;
    justify-content: center;
    margin-top: 32px;
    width: 100%;

    & button {
      border: 1px solid #dee5ec;
      border-radius: 5px;
      color: #6e7c89;
      font-weight: 600;
    }
  `,
};

export default CampaignCards;
