import React, { useEffect } from 'react';
import { css } from '@emotion/react';
import { ThemeButton } from '@src/components/atoms/Button';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import { ViewportType } from '@src/libs/theme';
import { formState, submitFormState, useSetRecoilState } from '@src/recoilAtoms';
import { usePageLayout } from '@src/libs/hooks';
import { ROUTES } from '@src/shared/routes';
import FormList from './FormList';
import FormListFilter from './FormListFilter';
import useFilter from './useFilter';

const Listings = () => {
  // form is able to draft edit/preview without saving, using recoil to save the draft information
  const setFormState = useSetRecoilState(formState);
  const setSubmitFormState = useSetRecoilState(submitFormState);
  const { filter, setFilter } = useFilter();
  const { isMobileView } = usePageLayout();

  useEffect(() => {
    // to reset form draft edit/preview information
    setFormState(null);
    setSubmitFormState(null);
  }, []);

  return (
    <div css={styles.container}>
      {!isMobileView && (
        <div css={styles.navigatorContainer}>
          <div>
            <BackNavigator title="Form" />
          </div>
          <div>
            <ThemeButton theme="blue" css={styles.addBtn} text="Add Form" href={ROUTES.FORMS_ADD} />
          </div>
        </div>
      )}

      <div css={styles.formListContainer}>
        <FormListFilter values={filter} onChange={setFilter} />
        <FormList filter={filter} />
      </div>
    </div>
  );
};

const styles = {
  addBtn: css`
    font-size: 12px;
    width: fit-content;

    .btn-text {
      padding: 0 16px;
    }
  `,
  container: css`
    padding: 24px;

    @media (max-width: ${ViewportType.TABLET}px) {
      padding: 16px;
    }
  `,
  formListContainer: css`
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
    margin-top: 16px;
  `,
  navigatorContainer: css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    & > div:nth-of-type(1) {
      display: flex;
      flex-basis: 50%;
    }

    & > div:nth-of-type(2) {
      display: flex;
      flex-basis: 50%;
      justify-content: flex-end;
    }
  `,
};

export default Listings;
