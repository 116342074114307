import React from 'react';
import { generatePath, BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { eventTypes, sendAmplitudeEvent } from './amplitude';
import { AppRoute, PrivateRoute, PublicRoute } from './components/organisms/Route';
import AuthSetUp from './components/organisms/AuthSetUp';
import * as pages from './pages';
import ScrollToTop from './ScrollToTop';
import { useUuumInfluencerView, usePageLayout } from './libs/hooks';
import { ROUTES } from './shared/routes';

const Routes = () => {
  const { isUuumView } = useUuumInfluencerView();
  const { isMobileView } = usePageLayout();
  const fansBackPach = isMobileView ? '/fans/:id' : '/fans';

  const renderWithAmplitudeEvent = (Component: () => JSX.Element | null, event: string) => {
    sendAmplitudeEvent(event);

    return <Component />;
  };

  return (
    <Router>
      <AuthSetUp hideLoadingPaths={[ROUTES.LIFF_ID]}>
        <ScrollToTop>
          <Switch>
            <PublicRoute
              amplitudeEvent={isUuumView ? eventTypes.viewSignUp : eventTypes.viewSignIn}
              path={ROUTES.ROOT}
              exact
              component={isUuumView ? pages.SignUp : pages.SignIn}
            />
            {/* sign-up */}
            <PublicRoute amplitudeEvent={eventTypes.viewSignUp} path={ROUTES.SIGN_UP} exact component={pages.SignUp} />
            <PublicRoute
              amplitudeEvent={eventTypes.viewSignUpEmail}
              path={ROUTES.SIGN_UP_EMAIL}
              exact
              component={pages.SignUpEmail}
            />
            <PublicRoute
              amplitudeEvent={eventTypes.viewSignUpIstagram}
              path={ROUTES.SIGN_UP_IG}
              exact
              component={pages.SignUpInstagram}
            />
            <PublicRoute
              amplitudeEvent={eventTypes.viewSignUpInstagramUnableConnect}
              path={ROUTES.SIGN_UP_IG_UNABLE_CONNECT}
              exact
              component={pages.SignUpInstagramUnableConnect}
            />
            <PublicRoute
              amplitudeEvent={eventTypes.viewSignUpTiktokGrantPermission}
              path={ROUTES.SIGN_UP_TIKTOK_GRANT_PERMISSION}
              exact
              component={pages.SignUpTiktokGrantPermission}
            />
            <PublicRoute
              amplitudeEvent={eventTypes.viewSignUpFacebookGrantPermission}
              path={ROUTES.SIGN_UP_FACEBOOK_GRANT_PERMISSION}
              exact
              component={pages.SignUpFacebookGrantPermission}
            />
            <PublicRoute
              amplitudeEvent={eventTypes.viewSignUpSuccess}
              path={ROUTES.SIGN_UP_SUCCESS}
              exact
              component={pages.SignUpSuccess}
            />
            <PublicRoute
              amplitudeEvent={eventTypes.viewSignUpEmail}
              path={ROUTES.SIGN_UP_PROFILE}
              exact
              component={pages.SignUpProfile}
            />
            <PrivateRoute path={ROUTES.SIGN_UP_CONNECT} exact component={pages.SignUpConnect} />
            {/* sign-in */}
            <PublicRoute amplitudeEvent={eventTypes.viewSignIn} path={ROUTES.SIGN_IN} exact component={pages.SignIn} />
            <PublicRoute
              amplitudeEvent={eventTypes.viewSignInInstagramUnableConnect}
              path={ROUTES.SIGN_IN_IG_UNABLE_CONNECT}
              exact
              component={pages.SignInInstagramUnableConnect}
            />
            <PublicRoute
              amplitudeEvent={eventTypes.viewSignInTiktokGrantPermission}
              path={ROUTES.SIGN_IN_TIKTOK_GRANT_PERMISSION}
              exact
              component={pages.SignInTiktokGrantPermission}
            />
            <PublicRoute
              amplitudeEvent={eventTypes.viewSignInFacebookGrantPermission}
              path={ROUTES.SIGN_IN_FACEBOOK_GRANT_PERMISSION}
              exact
              component={pages.SignInFacebookGrantPermission}
            />
            <PublicRoute
              amplitudeEvent={eventTypes.viewSignInFailedConnect}
              path={ROUTES.SIGN_IN_FAILED_CONNECT}
              exact
              component={pages.SignInFailedConnect}
            />
            <PublicRoute
              amplitudeEvent={eventTypes.viewSignInEmail}
              path={ROUTES.SIGN_IN_EMAIL}
              exact
              component={pages.SignInEmail}
            />
            <PublicRoute path={ROUTES.SIGN_IN_STAFF} exact component={pages.SignInStaff} />
            {/* password */}
            <Route
              path={ROUTES.PASSWORD_FORGOT}
              render={() => renderWithAmplitudeEvent(pages.PasswordForgot, eventTypes.viewPasswordForgot)}
            />
            <Route
              path={ROUTES.PASSWORD_REGISTER_TOKEN}
              render={() => renderWithAmplitudeEvent(pages.PasswordRegister, eventTypes.viewPasswordRegister)}
            />
            <Route
              path={ROUTES.PASSWORD_RESET_TOKEN}
              render={() => renderWithAmplitudeEvent(pages.PasswordReset, eventTypes.viewPasswordReset)}
            />
            {/* redirect */}
            <Route path={ROUTES.REDIRECT_SIGN_UP_ENABLED_PROVIDER} component={pages.RedirectSignUpEnabled} />
            <Route path={ROUTES.REDIRECT_TIKTOK_RECONNECT} component={pages.RedirectTiktokReconnect} />
            <Route path={ROUTES.REDIRECT_TWITTER} component={pages.RedirectTwitter} />
            <Route path={ROUTES.REDIRECT_SIGN_UP_PROVIDER} component={pages.RedirectSignUp} />
            <Route path={ROUTES.REDIRECT_SIGN_IN_PROVIDER} component={pages.RedirectSignIn} />
            <Route path={ROUTES.REDIRECT_SIGN_UP_CONNECT_PROVIDER} component={pages.RedirectSignUpConnect} />
            <Route path={ROUTES.REDIRECT_CONNECT_PROVIDER} component={pages.RedirectConnect} />
            <Route path={ROUTES.REDIRECT_JOIN_CAMPAIGN_PROVIDER} component={pages.RedirectJoinCampaign} />
            <Route path={ROUTES.REDIRECT_ANALYTICS_RECONNECT_PROVIDER} component={pages.RedirectAnalyticsReconnect} />
            <Route path={ROUTES.REDIRECT_SHOPIFY_CONNECT} component={pages.RedirectShopifyConnect} />
            <Route path={ROUTES.REDIRECT_SIGN_IN_WITH_LINE_PROVIDER} component={pages.RedirectLineInstagramSignIn} />
            <Route path={ROUTES.REDIRECT_LINK_IN_BIO_CONNECT} component={pages.RedirectLinkInBioConnect} />
            <Route path={ROUTES.REDIRECT_MOBILE_TIKTOK_CONNECT} component={pages.RedirectMobileTiktokConnect} />
            <Route path={ROUTES.REDIRECT_TTCM_RECONNECT} component={pages.RedirectTtcmReconnect} />
            {/* Home */}
            <AppRoute
              amplitudeEvent={eventTypes.viewHome}
              path={ROUTES.HOME}
              exact
              component={pages.Home}
              documentTitle="Home"
            />
            {/* Link In Bio */}
            <AppRoute
              amplitudeEvent={eventTypes.viewLinkInBio}
              path={ROUTES.LINK_IN_BIO}
              exact
              component={pages.LinkInBio}
              hideFooter
              skipBottomPadding
              toolbarTitle="_dynamic"
            />
            <AppRoute
              path={[ROUTES.LINK_IN_BIO_LINKS, ROUTES.LINK_IN_BIO_THEME_COLOR]}
              exact
              component={pages.MyLink}
              hideFooter
              skipBottomPadding
              toolbarTitle="Edit Mode"
            />
            {/* Social Media: My Link, Analytics, Marketplace, Casting */}
            <AppRoute
              amplitudeEvent={eventTypes.viewAnalyticsLinkInBio}
              path={ROUTES.ANALYTICS_LINK_IN_BIO}
              exact
              component={pages.MyLinkAnalytics}
            />
            <AppRoute path={ROUTES.ANALYTICS_ACTIVE_TAB} exact component={pages.Analytics} />
            <AppRoute
              amplitudeEvent={eventTypes.viewAnalyticsInstagramPostFeed}
              path={[ROUTES.ANALYTICS_IG_POST_FEED]}
              exact
              component={pages.InstagramStoryReelPostFeed}
              backPath={ROUTES.ANALYTICS}
              toolbarTitle="Post List"
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewAnalyticsInstagramStoryFeed}
              path={ROUTES.ANALYTICS_IG_STORY_FEED}
              exact
              component={pages.InstagramStoryReelPostFeed}
              backPath={ROUTES.ANALYTICS}
              toolbarTitle="Story List"
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewAnalyticsInstagramReelPosts}
              path={ROUTES.ANALYTICS_IG_REEL_POSTS}
              exact
              component={pages.InstagramStoryReelPostFeed}
              backPath={ROUTES.ANALYTICS}
              toolbarTitle="Reel List"
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewAnalyticsFacebookPostFeed}
              path={ROUTES.ANALYTICS_FB_POST_FEED}
              exact
              component={pages.FacebookPostFeed}
              backPath={ROUTES.ANALYTICS}
              toolbarTitle="Post List"
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewAnalyticsYoutubeVideosFeed}
              path={ROUTES.ANALYTICS_YT_VIDEOS_FEED}
              exact
              component={pages.YoutubeVideoList}
              backPath={ROUTES.ANALYTICS}
              toolbarTitle="Video List"
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewAnalyticsYoutubeTagRankingList}
              path={ROUTES.ANALYTICS_YT_TAG_RANKING_LIST}
              exact
              component={pages.TagRanking}
              backPath={ROUTES.ANALYTICS}
              toolbarTitle="Tag Ranking"
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewAnalyticsYoutubeCompareVideosFeed}
              path={ROUTES.ANALYTICS_YT_COMPARE_VIDEOS_FEED}
              exact
              component={pages.YoutubeCompareFeedVideos}
              backPath={ROUTES.ANALYTICS}
              toolbarTitle="Video List"
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewAnalyticsInstagramInteractionUserAnalysis}
              path={ROUTES.ANALYTICS_IG_INTERACTION_USER_ANALYSIS}
              exact
              component={pages.UserAnalysisList}
              backPath={ROUTES.ANALYTICS_IG_INTERACTION}
              toolbarTitle="_dynamic"
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewAnalyticsInstagramInteractionTaggedPosts}
              path={ROUTES.ANALYTICS_IG_INTERACTION_TAGGED_POSTS}
              exact
              component={pages.IgTaggedOrMentionedPostsList}
              backPath={ROUTES.ANALYTICS_IG_INTERACTION}
              toolbarTitle="_dynamic"
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewAnalyticsInstagramInteractionMentionedPosts}
              path={ROUTES.ANALYTICS_IG_INTERACTION_MENTIONED_POSTS}
              exact
              component={pages.IgTaggedOrMentionedPostsList}
              backPath={ROUTES.ANALYTICS_IG_INTERACTION}
              toolbarTitle="_dynamic"
            />
            {/* Initial campaign routes */}
            {/* 
              BE email notification still using old url, 
              handle redirect to new UI to avoid changes from BE  
            */}
            <Route
              exact
              path={ROUTES.INITIAL_ENGAGEMENT_JOB_ID}
              render={({ match }) => (
                <Redirect to={generatePath(ROUTES.CASTING_YOUR_JOB_ID, { id: match.params.id })} />
              )}
            />
            <Route
              exact
              path={ROUTES.INITIAL_ENGAGEMENT_JOB_ID_POST}
              render={({ match }) => (
                <Redirect to={generatePath(ROUTES.CASTING_YOUR_JOB_ID_POST, { id: match.params.id })} />
              )}
            />
            <Route
              exact
              path={ROUTES.INITIAL_ENGAGEMENT_JOB_ID_REPORT}
              render={({ match }) => (
                <Redirect to={generatePath(ROUTES.CASTING_YOUR_JOB_ID_REPORT, { id: match.params.id })} />
              )}
            />
            <Route
              exact
              path={ROUTES.INITIAL_MARKETPLACE_JOB_ID}
              render={({ match }) => (
                <Redirect to={generatePath(ROUTES.MARKETPLACE_YOUR_JOB_ID, { id: match.params.id })} />
              )}
            />
            <Redirect from={ROUTES.INITIAL_MARKETPLACE_SEARCH} to={ROUTES.MARKETPLACE_SEARCH_JOB} />
            <Route
              exact
              path={ROUTES.INITIAL_MARKETPLACE_SEARCH_ID}
              render={({ match }) => (
                <Redirect to={generatePath(ROUTES.MARKETPLACE_SEARCH_JOB_ID, { id: match.params.id })} />
              )}
            />
            {/* Marketplace */}
            <AppRoute
              amplitudeEvent={eventTypes.viewMarketplaceSearchJob}
              path={ROUTES.MARKETPLACE_SEARCH_JOB}
              exact
              component={pages.MarketplaceSearchJob}
              toolbarTitle="Search Job"
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewMarketplaceYourJob}
              path={ROUTES.MARKETPLACE_YOUR_JOB}
              exact
              component={pages.MarketplaceYourJobListings}
              toolbarTitle="Your Job"
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewMarketplaceSearchJobDetails}
              path={ROUTES.MARKETPLACE_SEARCH_JOB_ID}
              exact
              component={pages.MarketplaceYourJobDetails}
              backPath={ROUTES.MARKETPLACE_SEARCH_JOB}
              toolbarTitle="Campaigns Detail"
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewMarketplaceYourJobDetails}
              path={ROUTES.MARKETPLACE_YOUR_JOB_ID}
              exact
              component={pages.MarketplaceYourJobDetails}
              backPath={ROUTES.MARKETPLACE_YOUR_JOB}
              toolbarTitle="Campaigns Detail"
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewMarketplaceYourJobDetailsReport}
              path={ROUTES.MARKETPLACE_YOUR_JOB_ID_REPORT}
              exact
              component={pages.MarketplaceYourJobReports}
              backPath={ROUTES.MARKETPLACE_YOUR_JOB_ID}
              toolbarTitle="Campaign's Report"
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewMarketplaceYourJobAffiliateReport}
              path={ROUTES.MARKETPLACE_YOUR_JOB_ID_AFFILIATE_REPORT}
              exact
              component={pages.MarketplaceYourJobAffiliateReport}
              backPath={ROUTES.MARKETPLACE_YOUR_JOB_ID}
              toolbarTitle="Campaign's Report"
            />
            {/* Casting */}
            <AppRoute path={ROUTES.CASTING} exact component={pages.Casting} />
            <AppRoute
              amplitudeEvent={eventTypes.viewEngagementYourJob}
              path={ROUTES.CASTING_YOUR_JOB}
              exact
              component={pages.CastingYourJobListings}
              toolbarTitle="Your Job"
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewEngagementYourJobDetails}
              path={ROUTES.CASTING_YOUR_JOB_ID}
              exact
              component={pages.CastingYourJobDetails}
              backPath={ROUTES.CASTING_YOUR_JOB}
              toolbarTitle="Campaigns Detail"
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewEngagementYourJobDetailsPosts}
              path={ROUTES.CASTING_YOUR_JOB_ID_POST}
              exact
              component={pages.CastingYourJobPosts}
              backPath={ROUTES.CASTING_YOUR_JOB_ID}
              toolbarTitle="Post List"
            />
            <AppRoute
              path={ROUTES.CASTING_YOUR_JOB_ID_POST_ADD}
              exact
              component={pages.CastingYourJobAddPost}
              backPath={ROUTES.CASTING_YOUR_JOB_ID_POST}
              toolbarTitle="Upload Draft Post"
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewEngagementYourJobDetailsPostDetails}
              path={ROUTES.CASTING_YOUR_JOB_ID_POST_EDIT}
              exact
              component={pages.CastingYourJobEditPost}
              backPath={ROUTES.CASTING_YOUR_JOB_ID_POST}
              toolbarTitle="Edit Post"
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewEngagementYourJobDetailsReport}
              path={ROUTES.CASTING_YOUR_JOB_ID_REPORT}
              exact
              component={pages.CastingYourJobReports}
              backPath={ROUTES.CASTING_YOUR_JOB_ID}
              toolbarTitle="Campaign's Report"
            />
            {/* Fan management: Fans, Emails, Chat, Forms */}
            <AppRoute
              amplitudeEvent={eventTypes.viewFans}
              path={ROUTES.FANS}
              toolbarTitle="Fans"
              exact
              component={pages.Fans}
            />
            <AppRoute
              path={ROUTES.FANS_ADD}
              toolbarTitle="Add Fan"
              backPath={ROUTES.FANS}
              exact
              component={pages.AddFan}
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewFanDetails}
              path={ROUTES.FANS_EDIT}
              toolbarTitle="Fan's Details"
              backPath={ROUTES.FANS}
              exact
              component={pages.FanDetails}
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewFanDetails}
              path={ROUTES.FANS_EDIT_DETAILS}
              toolbarTitle="Fan's Details"
              backPath={fansBackPach}
              exact
              component={pages.FanDetails}
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewFanActivities}
              path={ROUTES.FANS_EDIT_ACTIVITY}
              toolbarTitle="Activity"
              backPath={fansBackPach}
              exact
              component={pages.FanDetails}
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewFanOrders}
              path={ROUTES.FANS_EDIT_ORDERS}
              toolbarTitle="Orders"
              backPath={fansBackPach}
              exact
              component={pages.FanDetails}
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewFanForms}
              path={ROUTES.FANS_EDIT_FORMS}
              toolbarTitle="Forms"
              backPath={fansBackPach}
              exact
              component={pages.FanDetails}
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewFanFormDetails}
              path={ROUTES.FANS_EDIT_FORMS_SUBMISSION_ID}
              toolbarTitle="Forms"
              backPath={ROUTES.FANS_EDIT_FORMS}
              exact
              component={pages.FanDetails}
            />
            {/* Emails */}
            <AppRoute
              amplitudeEvent={eventTypes.viewEmails}
              path={ROUTES.EMAILS}
              exact
              component={pages.EmailListing}
              toolbarTitle="Emails"
            />
            <AppRoute
              path={ROUTES.EMAILS_COMPOSE}
              exact
              component={pages.EmailCompose}
              backPath={ROUTES.EMAILS}
              toolbarTitle="Compose"
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewEmailDetails}
              path={ROUTES.EMAILS_EDIT_COMPOSE}
              exact
              component={pages.EmailEdit}
              backPath={ROUTES.EMAILS}
              toolbarTitle="Compose"
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewEmailReport}
              path={[ROUTES.EMAILS_EDIT_OVERVIEW, ROUTES.EMAILS_EDIT_RECIPIENT]}
              exact
              component={pages.EmailReports}
              backPath={ROUTES.EMAILS}
              toolbarTitle="Email Report"
            />
            {/* Messages Chat | Broadcast */}
            <AppRoute
              amplitudeEvent={eventTypes.viewChat}
              path={ROUTES.CHAT}
              exact
              component={pages.Chat}
              hideFooter
              toolbarTitle="Chat"
              skipBottomPadding
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewChat}
              path={ROUTES.CHAT_ACTIVE_ID}
              exact
              component={pages.Chat}
              hideFooter
              toolbarTitle="_dynamic"
              backPath={ROUTES.CHAT}
              skipBottomPadding
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewChat}
              path={ROUTES.CHAT_ACTIVE_ID_FAN_ID}
              exact
              component={pages.Chat}
              backPath={ROUTES.CHAT_ACTIVE_ID}
              hideFooter
              skipBottomPadding
              toolbarTitle="Details"
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewChat}
              path={ROUTES.CHAT_ACTIVE_ID_FAN_ID_FAN_DETAILS}
              exact
              component={pages.Chat}
              backPath={ROUTES.CHAT_ACTIVE_ID}
              hideFooter
              skipBottomPadding
              toolbarTitle="Fan Detail"
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewChat}
              path={ROUTES.CHAT_ACTIVE_ID_FAN_ID_ACTIVITY}
              exact
              component={pages.Chat}
              backPath={ROUTES.CHAT_ACTIVE_ID_FAN_ID}
              hideFooter
              skipBottomPadding
              toolbarTitle="Activity"
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewChat}
              path={ROUTES.CHAT_ACTIVE_ID_FAN_ID_ORDERS}
              exact
              component={pages.Chat}
              backPath={ROUTES.CHAT_ACTIVE_ID_FAN_ID}
              hideFooter
              skipBottomPadding
              toolbarTitle="Orders"
            />
            {/* Broadcast */}
            <AppRoute
              amplitudeEvent={eventTypes.viewBroadcast}
              path={ROUTES.BROADCAST}
              exact
              component={pages.BroadcastListing}
              toolbarTitle="Broadcast"
            />
            <AppRoute path={ROUTES.BROADCAST_ADD} exact component={pages.BroadcastAdd} toolbarTitle="Broadcast" />
            <AppRoute
              amplitudeEvent={eventTypes.viewBroadcastDetails}
              path={ROUTES.BROADCAST_EDIT}
              exact
              component={pages.BroadcastEdit}
              toolbarTitle="Broadcast"
            />
            {/* Forms */}
            <AppRoute
              amplitudeEvent={eventTypes.viewForms}
              path={ROUTES.FORMS}
              exact
              component={pages.FormListings}
              toolbarTitle="Form"
            />
            <AppRoute
              path={ROUTES.FORMS_ADD}
              exact
              component={pages.AddForm}
              backPath={ROUTES.FORMS}
              toolbarTitle="Add Form"
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewFormLivePreview}
              path={ROUTES.FORMS_ADD_LIVE_PREVIEW}
              exact
              component={pages.AddPreviewForm}
              backPath={ROUTES.FORMS_ADD}
              toolbarTitle="Live Preview"
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewFormDetails}
              path={[
                ROUTES.FORMS_EDIT_ENTRY,
                ROUTES.FORMS_EDIT_REPORT,
                ROUTES.FORMS_EDIT_REPORT_RESPONDENT,
                ROUTES.FORMS_EDIT_REPORT_INDIVIDUAL,
                ROUTES.FORMS_EDIT_REPORT_INDIVIDUAL_PAGE,
              ]}
              exact
              component={pages.EditForm}
              backPath={ROUTES.FORMS}
              toolbarTitle="Edit Form"
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewFormLivePreview}
              path={ROUTES.FORMS_EDIT_LIVE_PREVIEW}
              exact
              component={pages.EditPreviewForm}
              backPath={ROUTES.FORMS_EDIT_ENTRY}
              toolbarTitle="Live Preview"
            />
            <Route
              path={ROUTES.FORM_HASH}
              render={() => renderWithAmplitudeEvent(pages.SubmitForm, eventTypes.viewFormSubmit)}
            />
            {/* Notification */}
            <AppRoute
              amplitudeEvent={eventTypes.viewNotification}
              path={ROUTES.NOTIFICATION}
              exact
              component={pages.Notification}
              toolbarTitle="Notification"
            />
            {/* Settings */}
            <AppRoute path={ROUTES.SETTINGS} exact component={pages.SettingsMenu} toolbarTitle="Settings" />
            <AppRoute
              amplitudeEvent={eventTypes.viewSettingsAccount}
              path={ROUTES.SETTINGS_ACCOUNT}
              exact
              component={pages.SettingsAccount}
              backPath={ROUTES.SETTINGS}
              toolbarTitle="Account"
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewSettingsAddress}
              path={ROUTES.SETTINGS_ADDRESS}
              exact
              component={pages.SettingsAddress}
              backPath={ROUTES.SETTINGS}
              toolbarTitle="Address"
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewSettingsSocialConnect}
              path={ROUTES.SETTINGS_SOCIAL_CONNECT}
              exact
              component={pages.SettingsSocialConnect}
              backPath={ROUTES.SETTINGS}
              toolbarTitle="Social Connect"
            />
            <AppRoute
              path={ROUTES.SETTINGS_SOCIAL_CONNECT_ADD_METHODS}
              exact
              component={pages.SettingsSocialConnectAddMethods}
              backPath={ROUTES.SETTINGS_SOCIAL_CONNECT}
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewSettingsSocialConnectInstagramUnableConnect}
              path={ROUTES.SETTINGS_SOCIAL_CONNECT_IG_UNABLE_CONNECT}
              exact
              component={pages.SettingsInstagramUnableConnect}
              backPath={ROUTES.SETTINGS_SOCIAL_CONNECT}
              toolbarTitle="Unable to connect"
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewSettingsSocialConnectTiktokGrantPermission}
              path={ROUTES.SETTINGS_SOCIAL_CONNECT_TIKTOK_GRANT_PERMISSION}
              exact
              component={pages.SettingsTiktokGrantPermission}
              backPath={ROUTES.SETTINGS_SOCIAL_CONNECT}
              toolbarTitle="Grant permission"
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewSettingsSocialConnectFacebookGrantPermission}
              path={ROUTES.SETTINGS_SOCIAL_CONNECT_FACEBOOK_GRANT_PERMISSION}
              exact
              component={pages.SettingsFacebookGrantPermission}
              backPath={ROUTES.SETTINGS_SOCIAL_CONNECT}
              toolbarTitle="Grant permission"
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewSettingsPayment}
              path={ROUTES.SETTINGS_PAYMENT_INFORMATION}
              exact
              component={pages.SettingsPaymentInformation}
              backPath={ROUTES.SETTINGS}
              toolbarTitle="Payment Information"
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewSettingsUsers}
              path={ROUTES.SETTINGS_USERS}
              exact
              component={pages.SettingsUsers}
              backPath={ROUTES.SETTINGS}
              toolbarTitle="Users"
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewSettingsUserDetails}
              path={ROUTES.SETTINGS_USERS_USER_ID}
              exact
              component={pages.SettingsUserDetail}
              backPath={ROUTES.SETTINGS_USERS}
              toolbarTitle="User Detail"
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewSettingsSnsBudget}
              path={ROUTES.SETTINGS_SNS_BUDGET}
              exact
              component={pages.SettingsSnsBudget}
              backPath={ROUTES.SETTINGS}
              toolbarTitle="SNS budget"
            />
            <AppRoute
              path={ROUTES.SETTINGS_CHANNEL_CONNECT}
              exact
              component={pages.SettingsChannelConnect}
              backPath={ROUTES.SETTINGS}
              toolbarTitle="Channel Connect"
            />
            {/* Payment */}
            <AppRoute
              amplitudeEvent={eventTypes.viewPayment}
              path={ROUTES.PAYMENT}
              exact
              component={pages.Payment}
              toolbarTitle="ToolbarPayment"
            />
            <AppRoute
              amplitudeEvent={eventTypes.viewPaymentHistory}
              path={ROUTES.PAYMENT_HISTORY}
              exact
              component={pages.PaymentHistory}
              backPath={ROUTES.PAYMENT}
              toolbarTitle="Payment History"
            />
            {/* LIFF */}
            <Route path={ROUTES.LIFF_ID} component={pages.LineIdTokenVerification} />
            <PublicRoute path={ROUTES.LIFF_SIGN_IN_ID} component={pages.LineIdTokenVerification} />
            {/* Mobile */}
            <Route path={ROUTES.MOBILE_TIKTOK_CONNECT} component={pages.MobileTiktokConnect} />
            <AppRoute path={ROUTES.MOBILE_TIKTOK_GRANT_PERMISSION} component={pages.MobileTiktokGrantPermission} />
            {/* Help & Contact */}
            <AppRoute amplitudeEvent={eventTypes.viewContact} path={ROUTES.CONTACT} exact component={pages.Contact} />
            <AppRoute path={ROUTES.CONTACT_THANKS} exact component={pages.Thanks} />
            {/* Terms & Privacy */}
            <Route path={ROUTES.TERMS} render={() => renderWithAmplitudeEvent(pages.Terms, eventTypes.viewTerms)} />
            <Route
              path={ROUTES.PRIVACY}
              render={() => renderWithAmplitudeEvent(pages.Privacy, eventTypes.viewPrivacy)}
            />
            {/* Not Found inside the App */}
            <AppRoute component={pages.NotFound} />
          </Switch>
        </ScrollToTop>
      </AuthSetUp>
    </Router>
  );
};

export default Routes;
