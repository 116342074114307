import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { EmailEventStatus } from '@src/__generated__/globalTypes';

interface RecipientStatusIndicatorProps {
  status: EmailEventStatus;
}

const RecipientStatusIndicator = ({ status }: RecipientStatusIndicatorProps) => {
  const { t } = useTranslation();
  const color =
    status === EmailEventStatus.BOUNCE ? '#ffd7d7' : status === EmailEventStatus.CLICK ? '#cde4f9' : '#cfedde';

  return <Container color={color}>{t(status)}</Container>;
};

const Container = styled.div<{ color: string }>`
  align-items: center;
  background-color: ${({ color }) => color};
  border-radius: 35px;
  color: #27313b;
  display: flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  padding: 0 8px;
  width: fit-content;
`;

export default RecipientStatusIndicator;
