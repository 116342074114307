export type PickerDate = Date | null;

export interface Range {
  from: PickerDate;
  to: PickerDate;
  hoverTo: PickerDate;
  hoverFrom: PickerDate;
}

export interface Period {
  startDate: string;
  endDate: string;
}

export const SELECTED_RANGE = {
  TODAY: 'Today',
  YESTERDAY: 'Yesterday',
  THIS_WEEK: 'This week',
  LAST_WEEK: 'Last week',
  LAST_SEVEN_DAYS: 'Last 7 days',
  LAST_TWO_WEEKS: 'Last two weeks',
  LAST_MONTH: 'Last month',
  THIS_MONTH: 'This month',
  TODAY_TO_MONTH_AGO: 'Last 30 days',
  LAST_YEAR: 'Last year',
  WEEK_TO_DATE: 'Week to date',
  MONTH_TO_DATE: 'Month to date',
  YEAR_TO_DATE: 'Year to date',
  CUSTOM: 'Custom',
} as const;

export type ValuesOf<T> = T[keyof T];
export interface RangeListOption {
  value: ValuesOf<typeof SELECTED_RANGE>;
  label?: string;
  range?: Range;
}
export type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];
