import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import addressCard from '@src/assets/icon/chat/addressCard.svg';
import { ThemeButton } from '@src/components/atoms/Button';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import Textarea from '@src/components/atoms/Textarea';
import SingleSelectField from '@src/components/molecules/SingleSelectField';
import TextForm from '@src/components/molecules/TextForm';
import YearMonthDayPicker from '@src/components/molecules/YearMonthDayPicker';
import useProvinceOptions from '@src/components/organisms/FanDetails/FanDetailsForm/useProvinceOptions';
import { getOptions } from '@src/libs/form';
import { ViewportType } from '@src/libs/theme';
import { usePageLayout, useQueryHelper, useTranslateOptions } from '@src/libs/hooks';
import useFanCountries from '@src/pages/Fans/useFanCountries';
import { FanGender } from '@src/__generated__/globalTypes';
import { generatePath, ROUTES } from '@src/shared/routes';
import { mainBlack } from '@src/libs/pallete';
import { closeIcon } from '@src/assets/htmlCodes';
import { Icon } from '@src/components/atoms/Icon';
import Icomoon from '@src/components/atoms/Icomoon';

export interface FanInformation {
  address1: string;
  address2: string;
  birthday: string;
  city: string;
  contactNumber: string;
  countryId: string;
  email: string;
  firstName: string;
  gender: FanGender;
  lastName: string;
  postalCode: string;
  province: string;
  tags: string[];
}

const FanDetail = () => {
  const [tag, setTag] = useState<string>('');
  const { params, t } = useQueryHelper();
  const { activeChat, fanId } = params;
  const { fanCountries } = useFanCountries();
  const { isMobileView } = usePageLayout();
  const pathOptions = activeChat && fanId ? { activeChat, fanId } : undefined;

  const {
    formState: { errors, isSubmitting },
    register,
    setValue,
    watch,
  } = useFormContext<FanInformation>();
  const [birthday, countryId, gender, province, tags] = watch(['birthday', 'countryId', 'gender', 'province', 'tags']);

  const fanCountryOptions = useMemo(() => getOptions(fanCountries), [fanCountries.length]);
  const countryOptions = useTranslateOptions(fanCountryOptions);
  const genderOptions = useTranslateOptions([
    { value: FanGender.FEMALE, label: 'Female' },
    { value: FanGender.MALE, label: 'Male' },
    { value: FanGender.OTHER, label: 'Other' },
  ]);

  const { provinceOptions, refetch } = useProvinceOptions({ countryId });

  useEffect(() => {
    refetch({
      input: {
        countryId,
      },
    });
  }, [countryId]);

  const onClickAddTag = () => {
    if (tag) {
      const items = [...tags];
      items.push(tag);
      setValue('tags', items);
      setTag('');
    }
  };

  const onClickRemoveTag = (index: number) => {
    const items = [...tags];
    items.splice(index, 1);
    setValue('tags', items);
  };

  return (
    <div css={styles.container}>
      {!isMobileView && (
        <div css={styles.navigatorContainer}>
          <Link to={generatePath(ROUTES.CHAT_ACTIVE_ID_FAN_ID, pathOptions)}>
            <Icon>
              <Icomoon css={{ transform: 'rotate(90deg)' }} icon="arrow-down" />
            </Icon>
          </Link>
          <div>
            <img alt="icon" height="32" src={addressCard} width="32" />
          </div>
          <div>{t('MenuTitle.Fan Detail')}</div>
        </div>
      )}

      <ContentContainer isMobileView={isMobileView}>
        <InputContainer>
          <div>{t('TextForm.Name')}:</div>
          <div css={styles.nameFieldsContainer}>
            <div>
              <TextForm error={!!errors.firstName} placeholder="Ryuji" {...register('firstName')} />
              <ErrorMessage message={errors.firstName?.message} />
            </div>
            <div>
              <TextForm error={!!errors.lastName} placeholder="Takemoto" {...register('lastName')} />
              <ErrorMessage message={errors.lastName?.message} />
            </div>
          </div>
        </InputContainer>

        <InputContainer>
          <div>{t('TextForm.Email')}:</div>
          <div>
            <TextForm error={!!errors.email} placeholder="anychat@anymindgroup.com" {...register('email')} />
            <ErrorMessage message={errors.email?.message} />
          </div>
        </InputContainer>

        <InputContainer>
          <div>{t('TextForm.Phone Number')}:</div>
          <TextForm
            error={!!errors.contactNumber}
            placeholder="anychat@anymindgroup.com"
            {...register('contactNumber')}
          />
        </InputContainer>

        <InputContainer>
          <div>{t('Birth day')}:</div>
          <div>
            <YearMonthDayPicker
              css={styles.datepicker}
              format="yyyy-MM-dd"
              maxDate={new Date()}
              value={birthday}
              onChange={value => setValue('birthday', value as string)}
            />
          </div>
        </InputContainer>

        <InputContainer>
          <div>{t('Selector.Gender')}:</div>
          <StyledSingleSelectField
            name="gender"
            options={genderOptions}
            placeholder="Select Gender"
            value={gender}
            setFieldValue={setValue}
          />
        </InputContainer>

        <InputContainer>
          <div>{t('Selector.Country')}:</div>
          <StyledSingleSelectField
            name="countryId"
            options={countryOptions}
            placeholder="Select Country"
            value={countryId}
            setFieldValue={setValue}
          />
        </InputContainer>

        <InputContainer>
          <div>{t('Selector.Province')}:</div>
          <StyledSingleSelectField
            name="province"
            options={provinceOptions}
            placeholder="Select Province"
            value={province}
            setFieldValue={setValue}
          />
        </InputContainer>

        <InputContainer>
          <div>{t('TextForm.City')}:</div>
          <TextForm error={!!errors.city} placeholder={t('TextForm.City')} {...register('city')} />
        </InputContainer>

        <InputContainer>
          <div>{t('TextForm.Postal Code')}:</div>
          <TextForm error={!!errors.postalCode} placeholder="〒106-6131" {...register('postalCode')} />
        </InputContainer>

        <InputContainer>
          <FlexStartLabel>
            <div>{t('Sentence.Address 1')}:</div>
          </FlexStartLabel>
          <div>
            <StyledTextarea
              error={!!errors.address1}
              placeholder="Roppongi Roppongihiruzumoritawa(31-, Minato Ku"
              {...register('address1')}
            />
          </div>
        </InputContainer>

        <InputContainer>
          <FlexStartLabel>
            <div>{t('Sentence.Address 2')}:</div>
          </FlexStartLabel>
          <div>
            <StyledTextarea
              error={!!errors.address2}
              placeholder="Roppongi Roppongihiruzumoritawa(31-, Minato Ku"
              {...register('address2')}
            />
          </div>
        </InputContainer>

        <InputContainer>
          <FlexStartLabel>{t('Label.Tags')}:</FlexStartLabel>
          <div css={styles.tagsFieldContainer}>
            <div>
              <TextForm placeholder="VIP,etc." value={tag} onChange={e => setTag(e.target.value)} />
              <ThemeButton
                disabled={!tag}
                customPalette={mainBlack}
                text="Add Tag"
                width="max-content"
                fontSize="12px"
                onClick={onClickAddTag}
              />
            </div>
            <div css={{ display: 'flex', gap: '4px 8px' }}>
              {tags.map((item: string, index: number) => (
                <div css={styles.tag} key={item}>
                  <div>{item}</div>
                  <span css={styles.close} onClick={() => onClickRemoveTag(index)}>
                    {closeIcon}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </InputContainer>

        <div css={styles.actionContainer}>
          <ThemeButton
            theme="blue"
            disabled={isSubmitting}
            text="Save"
            width="max-content"
            fontSize="12px"
            type="submit"
            css={styles.saveButton}
          />
        </div>
      </ContentContainer>
    </div>
  );
};

const ContentContainer = styled.div<{ isMobileView: boolean }>`
  display: grid;
  height: ${({ isMobileView }) => (isMobileView ? '90vh' : '65vh')};
  overflow-y: auto;
`;

const FlexStartLabel = styled.div`
  align-items: flex-start;
  display: grid;
  height: 100%;

  & > div {
    margin-top: 8px;
  }
`;

const InputContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;

  & > div:nth-of-type(1) {
    color: #27313b;
    font-size: 14px;
    width: 20%;
  }

  & > div:nth-of-type(2) {
    display: flex;
    flex-wrap: wrap;
    width: 80%;

    & > div {
      width: fill-available;
    }
  }
`;

const StyledSingleSelectField = styled(SingleSelectField)`
  & > label {
    font-size: 14px;
  }

  /* stylelint-disable no-descending-specificity */
  & > div > div {
    border-radius: 3px;
    min-height: 32px;

    & > div > div {
      align-items: center;
      display: flex;
    }
  }

  & input {
    border-radius: 3px;
    min-height: 32px;
  }
`;

const StyledTextarea = styled(Textarea)`
  height: 56px;
`;

const styles = {
  actionContainer: css`
    display: flex;
    justify-content: flex-end;
  `,
  container: css`
    display: grid;
  `,
  datepicker: css`
    & > div {
      & > div {
        border-radius: 3px;
        min-height: 32px;
      }

      & > div > div > input {
        min-height: 32px;
      }
    }

    & > div:nth-of-type(2) {
      margin: 0 8px;
    }
  `,
  nameFieldsContainer: css`
    display: flex;
    gap: 8px;

    & > div {
      width: 48% !important;
    }
  `,
  navigatorContainer: css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
    gap: 8px;

    & > a {
      align-items: center;
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0 2px 5px #dee5ec, 0 1px 4px rgba(222, 229, 236, 0.5);
      display: grid;
      height: 32px;
      justify-content: center;
      width: 32px;

      & > i {
        margin: 0;
      }
    }

    & > div:nth-of-type(2) {
      color: #27313b;
      font-size: 16px;
      font-weight: 600;
      margin-left: 8px;
    }
  `,
  saveButton: css`
    @media (max-width: ${ViewportType.TABLET}px) {
      height: 40px;
      width: 100%;
    }
  `,
  tag: css`
    align-items: center;
    background-color: #eef3f7;
    border-radius: 3px;
    display: flex;
    flex-wrap: wrap;
    height: 24px;
    padding: 0 8px;
    width: fit-content;
    gap: 8px;

    & > div {
      color: #27313b;
      font-size: 12px;
    }
  `,
  tagsFieldContainer: css`
    display: flex;
    flex-wrap: wrap;

    & > div:nth-of-type(1) {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 12px;
      gap: 8px;

      & > div {
        display: flex;
        flex: 1;

        & > div {
          width: 100%;
        }
      }

      & > button {
        border-radius: 3px;
        font-size: 12px;
        font-weight: 600;
        height: 32px;
        width: 82px;
      }
    }

    & > div:nth-of-type(2) {
      display: flex;
      flex-wrap: wrap;
    }

    .btn-text {
      padding: 0;
    }
  `,
  close: css`
    font-size: 20px;
    line-height: 20px;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  `,
};

export default FanDetail;
