import React from 'react';
import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Textarea from '@src/components/atoms/Textarea';
import TextForm from '@src/components/molecules/TextForm';
import { useQueryHelper } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/theme';
import { QuestionType } from '@src/__generated__/globalTypes';
import BarChart from './BarChart';
import PieChart from './PieChart';
import GET_FORM_REPORT_SUMMARY from './queries/GetFormReportSummary.graphql';
import { GetFormReportSummary, GetFormReportSummaryVariables } from './queries/__generated__/GetFormReportSummary';

const FormSummary = () => {
  const { enqueueSnackbar, params, t } = useQueryHelper();

  const { data } = useQuery<GetFormReportSummary, GetFormReportSummaryVariables>(GET_FORM_REPORT_SUMMARY, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        id: Number(params.id),
      },
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const answers = data?.getFormReportSummary?.answers || [];

  return (
    <div css={styles.container}>
      {answers.map(answer => {
        const { answerOptions, answerTexts, numberResponses, questionId, questionTitle, questionType } = answer;

        return questionType === QuestionType.CHECKBOX ? (
          <QuestionContainer key={questionId}>
            <div>{questionTitle}</div>
            <div>{t('response', { count: numberResponses })}</div>
            <div css={styles.barChartContainer}>
              <BarChart answerOptions={answerOptions} title={questionTitle} />
            </div>
          </QuestionContainer>
        ) : questionType === QuestionType.DROPDOWN ? (
          <QuestionContainer key={questionId}>
            <div>{questionTitle}</div>
            <div>{t('response', { count: numberResponses })}</div>
            <div css={styles.pieChartContainer}>
              <div>
                <PieChart answerOptions={answerOptions} title={questionTitle} />
              </div>
            </div>
          </QuestionContainer>
        ) : questionType === QuestionType.SHORT_ANSWER ? (
          <QuestionContainer key={questionId}>
            <div>{questionTitle}</div>
            <div>{t('response', { count: numberResponses })}</div>
            {answerTexts.map((text, index) => {
              const isLongerText = text.length >= 85;

              return isLongerText ? (
                <StyledTextarea disabled key={index} placeholder="" value={text} />
              ) : (
                <StyledTextForm disabled key={index} placeholder="" value={text} />
              );
            })}
          </QuestionContainer>
        ) : null;
      })}
    </div>
  );
};

const QuestionContainer = styled.div`
  border-bottom: 1px solid #dde5eb;
  padding: 24px 0;
  width: 100%;

  & > div:nth-of-type(1) {
    color: #27313b;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  & > div:nth-of-type(2) {
    color: #27313b;
    font-weight: 12px;
    margin-bottom: 16px;
  }

  & > textarea:not(:last-child) {
    margin-bottom: 8px;
  }

  @media (max-width: ${ViewportType.SMALL}px) {
    max-width: 80vw;
  }
`;

const StyledTextarea = styled(Textarea)`
  height: 102px;
`;

const StyledTextForm = styled(TextForm)`
  margin-bottom: 8px;

  & > label {
    color: #27313b;
    font-size: 14px;
    font-weight: 600;
  }

  & input {
    border-radius: 3px;
    height: 32px;
  }
`;

const styles = {
  barChartContainer: css`
    display: flex;
    justify-content: center;
  `,
  container: css`
    margin-top: -24px;
  `,
  pieChartContainer: css`
    align-items: center;
    display: flex;
    justify-content: center;

    /* stylelint-disable no-descending-specificity */
    & > div {
      width: 350px;
    }
  `,
};

export default FormSummary;
