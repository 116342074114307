import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { css } from '@emotion/react';
import { ThemeButton } from '@src/components/atoms/Button';
import { useCopy } from '@src/libs/hooks/useCopy';
import TextForm from '@src/components/molecules/TextForm';

interface FormValues {
  liffEndpointUrl: string;
}

const Step5 = ({ getValues }: UseFormReturn<FormValues>) => {
  const { handleCopyHasText, isCopying } = useCopy();
  const liffEndpointUrl = getValues('liffEndpointUrl');

  return (
    <div>
      <TextForm css={styles.textform} disabled placeholder="" title="LIFF endpoint URL" value={liffEndpointUrl} />
      <ThemeButton
        css={styles.copyBtn}
        disabled={isCopying}
        text="Copy"
        onClick={() => handleCopyHasText(liffEndpointUrl)}
        fontSize="12px"
      />
    </div>
  );
};

const styles = {
  copyBtn: css`
    border: 1px solid #dee5ec;
    border-radius: 3px;
    color: #6e7c89;
    margin-top: 16px;
    width: max-content;
  `,
  textform: css`
    & input {
      background-color: #fff;
    }
  `,
};

export default Step5;
