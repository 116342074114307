import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { ThemeButton } from '@src/components/atoms/Button';
import Icomoon from '@src/components/atoms/Icomoon';
import SearchForm from '@src/components/molecules/SearchForm';
import { Popover, MenuItem } from '@src/components/molecules/Popover';
import SingleSelectField from '@src/components/molecules/SingleSelectField';
import { ViewportType } from '@src/libs/theme';
import { EmailListSortableField, EmailTemplateStatus, Order } from '@src/__generated__/globalTypes';
import { FilterItems } from './useFilter';

interface EmailListFilterProps {
  values: FilterItems;
  onChange: (values: FilterItems) => void;
}

const EmailListFilter = ({ values, onChange }: EmailListFilterProps) => {
  const [filter, setFilter] = useState<FilterItems>(values);
  const { t } = useTranslation();
  const sortOptions = [
    { field: EmailListSortableField.OPEN_RATE, label: 'Highest Open Rate', order: Order.DESC },
    { field: EmailListSortableField.OPEN_RATE, label: 'Lowest Open Rate', order: Order.ASC },
    { field: EmailListSortableField.CLICK_RATE, label: 'Highest Click Rate', order: Order.DESC },
    { field: EmailListSortableField.CLICK_RATE, label: 'Lowest Click Rate', order: Order.ASC },
  ];
  const statusOptions = [
    { label: t('Draft'), value: EmailTemplateStatus.DRAFT },
    { label: t('Published'), value: EmailTemplateStatus.PUBLISHED },
    { label: t('Schedule'), value: EmailTemplateStatus.SCHEDULED },
  ];

  useEffect(() => {
    setFilter(values);
  }, [values.keyword, values.orderByField, values.orderBySequence, values.status]);

  let onCloseMenu: () => void;

  const onClickSortOrder = (orderByField: EmailListSortableField, orderBySequence: Order) => {
    onChange({ ...filter, orderByField, orderBySequence });
    onCloseMenu();
  };

  return (
    <div css={styles.container}>
      <SearchForm
        css={styles.searchForm}
        placeholder={t('TextForm.Search by Email Subject')}
        value={filter.keyword}
        onBlur={() => onChange(filter)}
        onChange={e => setFilter({ ...filter, keyword: e.target.value })}
        onEnterKeyPress={() => onChange(filter)}
      />
      <SingleSelectField
        css={styles.singleSelectField}
        name=""
        options={statusOptions}
        placeholder="Status"
        value={filter.status}
        setFieldValue={(_attr, value) => onChange({ ...filter, status: value as EmailTemplateStatus })}
      />

      <Popover
        renderTrigger={() => (
          <div>
            <ThemeButton prefixIcon={<Icomoon icon="sort" size={24} />} text="Sort" width="82px" />
          </div>
        )}
        side="bottom"
        align="end"
        renderButtonBlock={({ closePopup }) => {
          onCloseMenu = closePopup;

          return null;
        }}
      >
        <>
          {sortOptions.map((option, index) => {
            const { field, label, order } = option;

            return (
              <MenuItem key={index} onClick={() => onClickSortOrder(field, order)}>
                {t(`MenuTitle.${label}`)}
              </MenuItem>
            );
          })}
        </>
      </Popover>
    </div>
  );
};

const styles = {
  container: css`
    background-color: #fff;
    border-radius: 5px 5px 0 0;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
    display: flex;
    flex-wrap: wrap;
    padding: 16px;
    gap: 8px;
  `,
  searchForm: css`
    width: 100%;

    @media (min-width: ${ViewportType.MEDIUM}px) {
      flex: 4;
      width: auto;
    }

    & input {
      height: 32px;
    }
  `,
  singleSelectField: css`
    @media (min-width: ${ViewportType.MEDIUM}px) {
      flex: 1;
    }

    & > label {
      font-size: 14px;
    }

    & > div > div {
      border-radius: 2px;
      min-height: 30px;

      & > div > div {
        align-items: center;
        display: flex;
      }
    }

    & input {
      border-radius: 2px;
      min-height: 30px;
    }
  `,
};

export default EmailListFilter;
