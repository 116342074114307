import styled from '@emotion/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import thanks from '@src/assets/icon/contactThanks.svg';
import { ViewportBreakpoint } from '@src/libs/theme';

const Thanks = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Icon src={thanks} alt="letter icon" />
      <Title>{t('We received your inquiry')}</Title>
      <Paragraph>{t('Help.Thank you for your inquiry')}</Paragraph>
    </Container>
  );
};

const Paragraph = styled.p`
  margin: 16px 42px 27px;
  font-size: 13px;
  line-height: 1.38;
  color: #27313b;
  text-align: center;
`;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  height: max-content;
  align-items: center;
  padding: 48px 16px 16px;
  background-color: #fff;
  box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
  border-radius: 5px;
  max-width: 648px;
  margin: 0 auto;

  @media (min-width: ${ViewportBreakpoint.MEDIUM}px) {
    padding: 48px 64px 24px;

    ${Paragraph} {
      text-align: center;
    }
  }
`;

const Icon = styled.img`
  @media (max-width: ${ViewportBreakpoint.MEDIUM}px) {
    width: 160px;
    height: 160px;
  }
`;

const Title = styled.h6`
  margin-top: 24px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #27313b;
`;

export default Thanks;
