import css from '@emotion/css';

export const darkGreen = css`
  background-color: rgb(64, 184, 124);
  color: #fff;
`;

export const darkGrey = css`
  background-color: rgb(110, 124, 137);
  color: #fff;
`;

export const alertLight = css`
  background-color: rgb(255, 95, 95, 0.4);
  color: #fff;
`;

export const darkYellow = css`
  background-color: rgb(255, 182, 61);
  color: #fff;
`;

export const darkPurple = css`
  background-color: rgb(150, 106, 204);
  color: #fff;
`;

export const lightGreen = css`
  background-color: rgb(179, 227, 203);
  color: #fff;
`;

export const darkBlue = css`
  background-color: rgb(56, 146, 229);
  color: #fff;
`;

export const mainRed = css`
  background-color: rgb(255, 43, 82);
  color: #fff;
`;
