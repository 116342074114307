import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import { ViewportType } from '@src/libs/theme';
import { usePageLayout } from '@src/libs/hooks';
import EmailList from './EmailList';
import EmailListFilter from './EmailListFilter';
import useFilter from './useFilter';

const Listings = () => {
  const { filter, setFilter } = useFilter();
  const { t } = useTranslation();
  const { isMobileView } = usePageLayout();

  return (
    <div css={styles.container}>
      {!isMobileView && (
        <div css={styles.navigatorContainer}>
          <div>
            <BackNavigator title="Emails" />
          </div>
          <div>
            <Link to="emails/compose">{t('Button.Compose')}</Link>
          </div>
        </div>
      )}

      <div css={styles.emailListContainer}>
        <EmailListFilter values={filter} onChange={setFilter} />
        <EmailList filter={filter} />
      </div>
    </div>
  );
};

const styles = {
  container: css`
    padding: 24px;

    @media (max-width: ${ViewportType.TABLET}px) {
      padding: 16px;
    }
  `,
  emailListContainer: css`
    margin-top: 16px;
  `,
  navigatorContainer: css`
    display: flex;
    flex-wrap: wrap;

    & > div:nth-of-type(1) {
      display: flex;
      flex-basis: 50%;
    }

    & > div:nth-of-type(2) {
      display: flex;
      flex-basis: 50%;
      justify-content: flex-end;

      & > a {
        align-items: center;
        background-color: #3892e5;
        border-radius: 3px;
        color: #fff;
        display: flex;
        font-size: 12px;
        font-weight: 600;
        height: 32px;
        justify-content: center;
        width: 105px;
      }
    }
  `,
};

export default Listings;
