import React, { useEffect } from 'react';
import InitialLoading from '@src/components/molecules/InitialLoading';
import useSignInInstagram from '@src/components/organisms/LineIdTokenVerification/useSignInInstagramLine';
import { getCallbackUrl } from '@src/libs/auth';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { UNEXPECTED_ERROR } from '@src/libs/error';
import { useQueryHelper } from '@src/libs/hooks';
import { ROUTES } from '@src/shared/routes';
import { AnyXAuthSocialAccountType } from '@src/__generated__/globalTypes';
import { useAuthProviderResponse } from '../hooks';

const LineInstagramSignIn = () => {
  const { enqueueSnackbar, history, t } = useQueryHelper();
  const { getLineIdToken, removeLineIdToken, signInInstagramLine } = useSignInInstagram();

  const { provider, response } = useAuthProviderResponse(ROUTES.SIGN_IN);

  useEffect(() => {
    lineInstagramSignIn();
  }, []);

  const lineInstagramSignIn = () => {
    const lineIdToken = getLineIdToken();
    if (lineIdToken && provider === AnyXAuthSocialAccountType.FACEBOOK) {
      removeLineIdToken();
      signInInstagramLine({
        variables: {
          input: {
            lineIDToken: lineIdToken,
            fbOauth: {
              callbackUrl: getCallbackUrl(provider, FE_REDIRECT_MAPPING.LINE_INSTAGRAM_SIGNIN),
              response,
            },
          },
        },
      });
    } else {
      enqueueSnackbar(t(UNEXPECTED_ERROR), { variant: 'error' });
      history.push(ROUTES.ROOT);
    }
  };

  return <InitialLoading />;
};

export default LineInstagramSignIn;
