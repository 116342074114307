import emptyThumbnail from '@src/assets/icon/emptyThumbnail.svg';
import influencerAvatar from '@src/assets/img/influencerAvatar.jpg';
import influencerCover from '@src/assets/img/influencerCover.jpg';
import emptyCampaignImage from '@src/assets/img/emptyCampaignThumbnail.png';
import emptyVideo from '@src/assets/img/videoThumbnail.png';
import { getFileTypeFromUrl } from './requests';

export const defaultEmptyImage = (url?: string | null, isYouTubeThumbnail?: boolean) => {
  if (url) {
    const fileType = getFileTypeFromUrl(url);
    const isVideo = ['mp4', 'mov', 'avi'].includes(fileType);

    if (isVideo) {
      return emptyVideo;
    }

    return encodeURI(decodeURI(url))
      .replace(/\(/g, '%28') // encode "("
      .replace(/\)/g, '%29') // encode ")"
      .replace(/\*/g, '%2A'); // encode "*"
  }

  return isYouTubeThumbnail ? emptyVideo : emptyThumbnail;
};
export const defaultInfluencerCover = (url: string | null) => (!!url ? encodeURI(url) : influencerCover);
export const defaultInfluencerAvatar = (url?: string | null) => (!!url ? encodeURI(url) : influencerAvatar);
export const defaultCampaignThumbnail = (url: string | null) =>
  !!url ? encodeURI(decodeURI(url)) : emptyCampaignImage;
export const isVideoThumbnail = (url?: string | null) => {
  if (!url) {
    return false;
  }

  const fileType = getFileTypeFromUrl(url);

  return ['avi', 'mp4', 'mov'].includes(fileType);
};
