import { UpdateInfluencerBioInput } from '@src/__generated__/globalTypes';
import { getInfluencerBio_getLinkBioThemeColor } from './queries/__generated__/GetInfluencerBio';

export enum FlowTypes {
  CREATE_MY_FIRST_LINK = 'CREATE_MY_FIRST_LINK',
  EDIT_LINKS = 'EDIT_LINKS',
  EDIT_PROFILE = 'EDIT_PROFILE',
  EDIT_SHARE = 'EDIT_SHARE',
  EDIT_URL = 'EDIT_URL',
  MY_LINK_IN_BIO = 'MY_LINK_IN_BIO',
  SELECT_TEMPLATES = 'SELECT_TEMPLATES',
}

export interface Information extends UpdateInfluencerBioInput {
  themeCustomOptions: getInfluencerBio_getLinkBioThemeColor | null;
}

export const toastContainerId = 'myLinkToast';
