import styled from '@emotion/styled';
import * as React from 'react';
import { SerializedStyles } from '@emotion/core';
import emptyImage from '@src/assets/img/emptyImage.jpg';
import emptyVideo from '@src/assets/img/videoThumbnail.png';
import Icomoon from '@src/components/atoms/Icomoon';
import { getFileTypeFromUrl, getDecodedUrlFileName } from '@src/libs/requests';
import { UploadedFile } from './types';

export interface PreviewProps {
  previewWidth?: string;
  disabled?: boolean;
  uploadProgress: { progress: number; progressUrl: string | null };
  uploadedFiles: UploadedFile[];
  deleteUploadedFile: (index: number) => () => void;
  previewCss?: SerializedStyles;
}

const Preview = (props: PreviewProps) => {
  const { uploadedFiles, deleteUploadedFile, previewWidth, disabled, previewCss } = props;

  return (
    <Container previewWidth={previewWidth} css={previewCss}>
      {uploadedFiles.map(({ url, preview }, index) => {
        const fileType = getFileTypeFromUrl(url);
        const isImage = ['jpeg', 'jpg', 'png', 'gif'].includes(fileType);
        // when we create preview from Blob fileType=[avi, mov, mp4], when we upload file fileType is normal MIME
        const isVideo = ['video/avi', 'avi', 'video/mp4', 'mp4', 'video/quicktime', 'mov'].includes(fileType);
        const isEmptyImage = !(isImage || isVideo);
        const imagePreview = preview && isImage ? preview : emptyImage;

        return (
          <Wrapper previewWidth={previewWidth} key={index}>
            {isImage && (
              <ThumbnailWrapper>
                <Thumbnail src={imagePreview} />
              </ThumbnailWrapper>
            )}
            {isVideo && <StyledImage src={emptyVideo} />}
            {isEmptyImage && (
              <a download={url} href={url}>
                <StyledImage src={imagePreview} />
              </a>
            )}
            {!disabled && <Icomoon icon="clear" onClick={deleteUploadedFile(index)} />}
            <Title title={getDecodedUrlFileName(preview)}>{getDecodedUrlFileName(preview)}</Title>
          </Wrapper>
        );
      })}
    </Container>
  );
};

const Container = styled.div<{ previewWidth?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;

  &::after {
    content: '';
    display: block;
    width: ${props => props.previewWidth || '31.5%'};
  }
`;

const Wrapper = styled.div<{ previewWidth?: string }>`
  overflow: hidden;
  width: ${props => props.previewWidth || '31.5%'};
  margin-top: 12px;
  position: relative;
`;

const ThumbnailWrapper = styled.div`
  position: relative;
  background-color: #27313b;

  &::before {
    content: '';
    display: block;
    padding-top: 56.25%;
  }
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: contain;
`;

const Title = styled.p`
  margin-top: 2px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledImage = styled.img`
  width: 100%;
`;

export default Preview;
