import React from 'react';
import { css } from '@emotion/react';
import { useMutation } from '@apollo/client';
import { useMediaQuery } from 'react-responsive';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { FanSchema } from '@src/libs/validation';
import { useQueryHelper, useUpdateDocumentTitle } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/theme';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import { ROUTES } from '@src/shared/routes';
import AddFanForm from './AddFanForm';
import { FormValues } from './types';
import * as ADD_FAN from './AddFan.graphql';
import { AddFan, AddFanVariables } from './__generated__/AddFan';

const AddFanComponent = () => {
  useUpdateDocumentTitle({ title: 'pageTitle.Add Fan' });
  const isDesktopView = useMediaQuery({ query: `(min-width: ${ViewportType.TABLET}px)` });
  const { enqueueSnackbar, t, history } = useQueryHelper();
  const [addFanMutation] = useMutation<AddFan, AddFanVariables>(ADD_FAN);
  const methods = useForm<FormValues>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      contactNumber: '',
    },
    resolver: yupResolver(FanSchema),
  });

  const onSubmit = async (payload: FormValues) => {
    try {
      const result = await addFanMutation({
        variables: {
          input: payload,
        },
      });
      if (result.data?.addFan?.ok) {
        enqueueSnackbar(t('succeededInCreating'), { variant: 'success' });
        sendAmplitudeEvent(eventTypes.addFan);
        history.push(ROUTES.FANS);
      } else {
        const failureMessage = 'failedToCreate';
        enqueueSnackbar(t(failureMessage), { variant: 'error' });
      }
    } catch (err) {
      const { message } = err.graphQLErrors[0];
      if (message === 'fan already exists') {
        methods.setError('email', { message: 'Fan already exists' });
      }
      enqueueSnackbar(t(message || 'failedToCreate'), { variant: 'error' });
    }
  };

  return (
    <div css={styles.container}>
      {isDesktopView && <BackNavigator to={ROUTES.FANS} title="Add Fan" />}

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <AddFanForm />
        </form>
      </FormProvider>
    </div>
  );
};

const styles = {
  container: css`
    margin: 16px 24px;
  `,
};

export default AddFanComponent;
