import { Fragment, ReactNode } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { localizedDateFormatter } from '@src/components/atoms/List/DateColumn';
import { useTranslation } from 'react-i18next';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import { FanActivityType } from '@src/__generated__/globalTypes';
import { FanActivityCommentSchema } from '@src/libs/validation';
import Icomoon from '@src/components/atoms/Icomoon';
import CommentForm, { FormValues } from './CommentForm';

const ACTIVITY_TIME_FORMAT = 'MM-dd-yyyy h:mm aaa';

interface ActivityRowProps {
  activity: string | null;
  created: string;
  activityType: FanActivityType;
  commenter: string;
  showCommentInsideForm: boolean;
  onClickMore?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onUpdate?: (comment: string) => void;
  popoverNode: ReactNode;
}

const ActivityRow = ({
  activity,
  created,
  activityType,
  commenter,
  showCommentInsideForm,
  popoverNode,
  onUpdate,
}: ActivityRowProps) => {
  const { i18n, t } = useTranslation();
  const methods = useForm<{ comment: string }>({
    defaultValues: { comment: activity || '' },
    resolver: yupResolver(FanActivityCommentSchema),
  });

  const onSubmit = (payload: FormValues) => {
    if (onUpdate) {
      onUpdate(payload.comment);
    }
  };

  const activityTime = localizedDateFormatter(
    new Date(created + 'Z'),
    ACTIVITY_TIME_FORMAT,
    i18n.language as AppLanguage
  );

  if (activityType === FanActivityType.COMMENT_CREATE) {
    if (showCommentInsideForm) {
      return (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <CommentForm />
          </form>
        </FormProvider>
      );
    } else {
      return (
        <Fragment>
          <Row isComment={true}>
            <CommentTime>{activityTime}</CommentTime>
            <CommentIcon icon="clipboard-grey-light" size={24} />
            <DetailsWrapper>
              <PrimaryText css={marginBottom4}>{activity}</PrimaryText>
              <SecondaryText css={marginBottom0}>
                {t('Note by', { ...(!!commenter ? { name: commenter } : {}) })}
              </SecondaryText>
            </DetailsWrapper>
            <div css={{ display: 'flex', alignItems: 'center' }}>{popoverNode}</div>
          </Row>
        </Fragment>
      );
    }
  }

  return (
    <Row>
      <CircleWrapper>
        <Circle />
      </CircleWrapper>
      <DetailsWrapper>
        <SecondaryText>{activityTime}</SecondaryText>
        <PrimaryText>{activity}</PrimaryText>
      </DetailsWrapper>
    </Row>
  );
};

const marginBottom0 = css`
  margin-bottom: 0;
`;
const marginBottom4 = css`
  margin-bottom: 4px;
`;
const CircleWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 9px;

  &::before {
    content: '';
    width: 2px;
    height: 50%;
    position: absolute;
    background-color: #dee5ec;
    left: calc(50% - 1px);
    top: -2px;
  }

  &::after {
    content: '';
    width: 2px;
    height: 50%;
    position: absolute;
    background-color: #dee5ec;
    left: calc(50% - 1px);
    bottom: -2px;
  }
`;
const Circle = styled.div`
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 4px solid #dee5ec;
  border-radius: 8px;
  box-sizing: border-box;
  z-index: 1;
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 8px;
`;
const CommentIcon = styled(Icomoon)`
  align-self: center;
  padding-left: 12px;

  [dir='rtl'] & {
    padding-left: 0;
    padding-right: 12px;
  }
`;
const Row = styled.div<{ isComment?: boolean }>`
  border-radius: 5px;
  margin-bottom: 24px;
  position: relative;
  display: flex;
  align-items: stretch;
  border: 1px solid ${props => (props.isComment ? '#dee5ec' : 'transparent')};
  ${({ isComment }) => (isComment ? 'background-color: #fff;' : '')}

  &:not(:last-of-type) {
    &::after {
      content: '';
      background-color: #dee5ec;
      width: 2px;
      height: 24px;
      position: absolute;
      left: 16px;
      bottom: -25px;

      [dir='rtl'] & {
        left: unset;
        right: 7px;
      }
    }
  }

  &:last-of-type {
    ${CircleWrapper} {
      &::after {
        background-color: transparent;
      }
    }
  }

  &:first-of-type {
    ${CircleWrapper} {
      &::before {
        background-color: transparent;
      }
    }
  }
`;
const PrimaryText = styled.p`
  font-size: 14px;
  color: #27313b;
  line-height: 14px;
`;
const SecondaryText = styled.p`
  font-size: 12px;
  color: #6e7c89;
  line-height: 12px;
  margin-bottom: 4px;
`;
const CommentTime = styled(SecondaryText)`
  position: absolute;
  top: -18px;
  left: 30px;

  [dir='rtl'] & {
    left: unset;
    right: 30px;
  }
`;

export default ActivityRow;
