import React from 'react';
import { css } from '@emotion/core';
import { useParams } from 'react-router';
import { ListIndicator } from '@src/components/molecules/Indicator';
import Empty from '@src/components/atoms/Empty';
import { usePageLayout } from '@src/libs/hooks';
import OrderDetails from './OrderDetails';
import useGetFanOrders from './useGetFanOrders';

const FanOrders = () => {
  const params = useParams<Record<any, string>>();
  const { isMobileView } = usePageLayout();
  const { id } = params as { id: string };
  const { data, loading } = useGetFanOrders({ fanId: Number(id) });

  if (loading) {
    return <ListIndicator />;
  }

  if (!data?.getFanOrders?.orders.length) {
    return <Empty />;
  }

  return (
    <div css={!isMobileView && styles.container}>
      {data?.getFanOrders?.orders.map(item => (
        <div css={styles.wrapper} key={item.orderId}>
          <OrderDetails {...item} />
        </div>
      ))}
    </div>
  );
};

const styles = {
  wrapper: css`
    margin-bottom: 16px;
  `,
  container: css`
    padding: 24px 24px 16px;
  `,
};

export default FanOrders;
