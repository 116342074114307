import styled from '@emotion/styled';
import * as React from 'react';
import Dropzone, { DropEvent, FileRejection } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import Icomoon from '@src/components/atoms/Icomoon';

export interface DropAreaProps extends WrapperProps {
  notes: string[];
  name: string;
  multiple?: boolean;
  accept?: string;
  onDrop: <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void;
  disabled: boolean;
  customContent?: React.ReactNode;
}

const DropArea = (props: DropAreaProps) => {
  const { t } = useTranslation();
  const { notes, name, multiple, accept, error, onDrop, disabled, customContent } = props;

  return (
    <Dropzone multiple={multiple} accept={accept} onDrop={onDrop} disabled={disabled}>
      {({ getRootProps, getInputProps }) => (
        <Wrapper {...getRootProps()} error={error} disabled={disabled}>
          <input name={name} {...getInputProps()} />
          {customContent ? (
            customContent
          ) : (
            <>
              <Icomoon color="#dfe8ed" icon="cloud-upload" size={56} />
              <div className="drop-area-notes">
                {notes.map(note => (
                  <Note key={note}>{note}</Note>
                ))}
              </div>
              <Label disabled={disabled}>{t('Button.Browse to Upload')}</Label>
            </>
          )}
        </Wrapper>
      )}
    </Dropzone>
  );
};

interface WrapperProps {
  error?: boolean;
  disabled?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  border: 1px dashed #e0e8ed;
  border-color: ${props => (props.error ? 'tomato' : '#e0e8ed')};
  border-radius: 2px;
  outline: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: border-color 0.3s ease-in-out;
  padding: 16px;
  box-sizing: border-box;

  &:hover {
    border-color: ${({ disabled }) => (disabled ? '#e0e8ed' : '#179cd7')};
  }

  & > .drop-area-notes {
    display: grid;
    width: 100%;
  }
`;

const Note = styled.p`
  color: #6e7c89;
  font-size: 11px;
  line-height: 1.18;
  width: 100%;
`;

const Label = styled.p<{ disabled: boolean }>`
  margin-top: 28px;
  color: ${({ disabled }) => (disabled ? '#6e7c89' : '#3892e5')};
  font-size: 13px;
  font-weight: 500;

  ${Wrapper}:hover & {
    text-decoration: ${({ disabled }) => (disabled ? 'none' : 'underline')};
  }
`;

export default DropArea;
