import React, { useEffect } from 'react';
import InitialLoading from '@src/components/molecules/InitialLoading';
import { getConnectState } from '@src/libs/auth';
import { ErrorTypes, UNEXPECTED_ERROR } from '@src/libs/error';
import { useQueryHelper } from '@src/libs/hooks';
import useTiktokReconnect from '@src/pages/Redirect/TiktokReconnect/useTiktokReconnect';
import { ROUTES } from '@src/shared/routes';
import { useAuthProviderResponse } from '../hooks';

const MobileTiktokConnect = () => {
  const { enqueueSnackbar, history, t } = useQueryHelper();
  const { provider, response } = useAuthProviderResponse(ROUTES.SIGN_IN);
  const { socialAuthReconnectTikTok } = useTiktokReconnect();

  const connectTiktok = () => {
    if (!provider) {
      enqueueSnackbar(t(UNEXPECTED_ERROR), { variant: 'error' });
      history.push(ROUTES.SIGN_IN);

      return;
    }

    const connectState = getConnectState();
    const variables = {
      input: {
        id: connectState?.reconnectTiktokAccountId || '',
        response,
      },
    };
    socialAuthReconnectTikTok({ variables })
      .then(result => {
        if (result.data?.anyXSocialAuthReconnectTikTok?.ok) {
          history.push(ROUTES.MOBILE_TIKTOK_GRANT_PERMISSION);
        } else {
          history.push(`${ROUTES.MOBILE_TIKTOK_GRANT_PERMISSION}?id=${connectState?.reconnectTiktokAccountId}`);
        }
      })
      .catch(err => {
        if (err.message !== ErrorTypes.VIDEO_PERMISSION_MISSING) {
          enqueueSnackbar(t(err.message), { variant: 'error' });
        }
        history.push(`${ROUTES.MOBILE_TIKTOK_GRANT_PERMISSION}?id=${connectState?.reconnectTiktokAccountId}`);
      });
  };

  useEffect(() => {
    connectTiktok();
  }, []);

  return <InitialLoading />;
};

export default MobileTiktokConnect;
