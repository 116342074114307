import React from 'react';
import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import PageLoading from '@src/components/molecules/PageLoading';
import { ReconnectStatus } from '@src/components/organisms/Profile/ProfileConnectForm/types';
import { getRedirectPath } from '@src/libs/auth';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import { useProviderResponse } from '../hooks';
import * as INFLUENCER_SOCIAL_AUTH_RECONNECT_IG from './InfluencerSocialAuthReconnectInstagramAccountAnalytics.graphql';
import {
  InfluencerSocialAuthReconnectInstagramAccountAnalytics,
  InfluencerSocialAuthReconnectInstagramAccountAnalyticsVariables,
} from './__generated__/InfluencerSocialAuthReconnectInstagramAccountAnalytics';

const AnalyticsReconnect = () => {
  const { t, history, enqueueSnackbar } = useQueryHelper();
  const { provider, response } = useProviderResponse('profile');
  let reconnectStatus: ReconnectStatus;
  let backRoute: string = '';
  try {
    reconnectStatus = JSON.parse(localStorage.getItem('reconnectStatus') || '');
    backRoute = localStorage.getItem('redirectBackRoute') || '';
    if (!reconnectStatus) {
      throw Error('no data');
    }
  } catch (e) {
    enqueueSnackbar(t('Fail to reconnect account, please try again later'), { variant: 'error' });
    localStorage.removeItem('reconnectStatus');
  }

  const [reconnectInfluencerIgUserAnalyticsAccount] = useMutation<
    InfluencerSocialAuthReconnectInstagramAccountAnalytics,
    InfluencerSocialAuthReconnectInstagramAccountAnalyticsVariables
  >(INFLUENCER_SOCIAL_AUTH_RECONNECT_IG);

  const callReconnectAnalyticsAccount = async () => {
    try {
      const { analyticsAccountId } = reconnectStatus.dataForReconnect;

      await reconnectInfluencerIgUserAnalyticsAccount({
        variables: {
          input: {
            analyticsAccountId,
            response,
            // BECAUSE only HERE the callback of instagram is instagream and not facebook (while in the rest of the app,
            // the redirection for instagram is considered facebook)
            callbackUrl: `${location.origin}/${getRedirectPath(
              provider || SocialAccountType.INSTAGRAM,
              FE_REDIRECT_MAPPING.ANALYTICS_IG_RECONNECT
            )}`,
          },
        },
      });
      enqueueSnackbar(t('Your account has been reconnected successfully'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('Fail to reconnect account, please try again later'), { variant: 'error' });
    } finally {
      history.push(`/${backRoute}`);
      localStorage.removeItem('reconnectStatus');
      localStorage.removeItem('redirectBackRoute');
    }
  };
  useEffect(() => {
    callReconnectAnalyticsAccount();
  }, []);

  return <PageLoading />;
};

export default AnalyticsReconnect;
