import { useState } from 'react';
import { useQueryHelper } from '@src/libs/hooks';
import { setUrlParams } from '@src/libs/url';
import { FormStatus } from '@src/__generated__/globalTypes';

export interface FilterItems {
  keyword: string;
  status: FormStatus;
}

const useFilter = () => {
  const { history, pathname, search } = useQueryHelper();
  const searchParams = new URLSearchParams(search);
  const initialFilter = {
    keyword: searchParams.get('keyword') || '',
    status: (searchParams.get('status') || '') as FormStatus,
  };

  const [filter, setInitialFilter] = useState<FilterItems>(initialFilter);

  function setFilter(values: FilterItems) {
    setInitialFilter(values);
    setUrlParams(history, pathname, search, values);
  }

  return {
    filter,
    setFilter,
  };
};

export default useFilter;
