import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { FormStatus } from '@src/__generated__/globalTypes';

interface StatusIndicatorProps {
  status: FormStatus;
}

const StatusIndicator = ({ status }: StatusIndicatorProps) => {
  const { t } = useTranslation();

  const color = status === FormStatus.PRIVATE ? '#6e7c89' : status === FormStatus.DRAFT ? '#ffb63d' : '#40b87c';

  return <Indicator color={color}>{t(status)}</Indicator>;
};

const Indicator = styled.div<{ color: string }>`
  align-items: center;
  background-color: ${({ color }) => color};
  border-radius: 35px;
  display: flex;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  height: 24px;
  justify-content: center;
  padding: 0 16px;
`;

export default StatusIndicator;
