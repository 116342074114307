import { useQuery } from '@apollo/client';
import * as ALL_AVAILABLE_COUNTRIES from '@src/libs/queries/AllAvailableCountries.graphql';
import { AllAvailableCountries } from '@src/libs/queries/__generated__/AllAvailableCountries';
import useQueryHelper from './useQueryHelper';
import useTranslateOptions from './useTranslateOptions';

export default () => {
  const { t, enqueueSnackbar } = useQueryHelper();
  const { data } = useQuery<AllAvailableCountries>(ALL_AVAILABLE_COUNTRIES, {
    fetchPolicy: 'cache-first',
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
  });

  return {
    allTranslatedCountries: useTranslateOptions(data?.allAvailableCountries || []),
  };
};
