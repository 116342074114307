import { format } from 'date-fns';
import React from 'react';
import { Link } from 'react-router-dom';
import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import linkIcon from '@src/assets/icon/link.svg';
import emptyImage from '@src/assets/img/emptyImage.jpg';
import { ThemeButton } from '@src/components/atoms/Button';
import Grid from '@src/components/atoms/Grid';
import Icomoon from '@src/components/atoms/Icomoon';
import { localizedDateFormatter } from '@src/components/atoms/List/DateColumn';
import Carousel from '@src/components/molecules/Carousel';
import RichEditor from '@src/components/molecules/RichEditor';
import TextForm from '@src/components/molecules/TextForm';
import { getSocialMediaUrlByCampaignType } from '@src/libs/campaign';
import { getDayfromDateString } from '@src/libs/date';
import { formatNumberWithCommas, formatPercent } from '@src/libs/format';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { useCopy } from '@src/libs/hooks/useCopy';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import { ViewportType } from '@src/libs/theme';
import { switchImage, switchText } from '@src/libs/SocialMedia';
import {
  AnyXEngagementCampaignStatus,
  AnyXMarketplaceCampaignStatus,
  CampaignPostStatusForInfluencer,
  CampaignSocialMediaType,
  CampaignStatusForInfluencer,
  CampaignType,
  MarketplaceCampaignAppliedStatus,
  MarketplaceCampaignType,
  TTCMStatus,
  WarningReason,
} from '@src/__generated__/globalTypes';
import CampaignBudgetProgressBar from '../CampaignBudgetProgressBar';
import StatusStepper from '../StatusStepper';
import StatusMessage from './StatusMessage';

interface CampaignDetailsProps {
  campaignDetails: {
    anyXStatus: AnyXEngagementCampaignStatus | AnyXMarketplaceCampaignStatus | null;
    appliedStatus?: MarketplaceCampaignAppliedStatus | null;
    campaignType?: MarketplaceCampaignType;
    clickUrl?: string | null;
    commissionRate?: number | null;
    couponCode?: string | null;
    currency: string;
    endDate: any;
    hashtags: string[];
    isApplied?: boolean;
    isCouponCampaign?: boolean | null;
    isGiftingCampaign?: boolean | null;
    isJoined: boolean;
    isTtcmCampaign?: boolean;
    materials:
      | {
          id: number;
          url: string;
        }[]
      | null;
    materialUrl: string | null;
    maximumRevenuePerInfluencer?: number | null;
    postStatus?: CampaignPostStatusForInfluencer | null;
    postUrl?: string | null;
    remainingAmount?: number;
    requirement?: string;
    requirements?: string | null;
    revenuePerAction?: number | null;
    revenuePerClick?: number | null;
    revenuePerComment?: number | null;
    revenuePerFollower?: number | null;
    revenuePerLike?: number | null;
    revenuePerOrder?: number | null;
    revenuePerPost?: number | null;
    revenuePerShare?: number | null;
    revenuePerView?: number | null;
    rewardAmount?: number;
    serviceInformation: string;
    serviceUrl?: string | null;
    socialMedias: CampaignSocialMediaType[];
    startDate: any;
    status: CampaignStatusForInfluencer;
    title: string;
    thumbNail: string;
    trackingUrl: string | null;
    totalAmount?: number;
    ttcmCampaignCode?: string | null;
    ttcmStatus?: TTCMStatus | null;
    type: CampaignType;
    warningReason?: WarningReason | null;
  };
}

const CampaignDetails = ({ campaignDetails }: CampaignDetailsProps) => {
  const {
    anyXStatus,
    appliedStatus,
    campaignType,
    clickUrl,
    commissionRate,
    couponCode,
    currency,
    endDate,
    hashtags,
    isApplied,
    isCouponCampaign,
    isGiftingCampaign,
    isJoined,
    materials,
    materialUrl,
    maximumRevenuePerInfluencer,
    postStatus,
    postUrl,
    remainingAmount,
    requirement,
    requirements,
    revenuePerAction,
    revenuePerClick,
    revenuePerComment,
    revenuePerFollower,
    revenuePerLike,
    revenuePerOrder,
    revenuePerPost,
    revenuePerShare,
    revenuePerView,
    rewardAmount,
    serviceInformation,
    serviceUrl,
    socialMedias,
    startDate,
    status,
    title,
    thumbNail,
    trackingUrl,
    totalAmount,
    ttcmCampaignCode,
    ttcmStatus,
    type,
    warningReason,
  } = campaignDetails;

  // TODO: to enable new flow when marketplace implement TTCM
  // const isTtcmCampaign = campaignDetails.isTtcmCampaign;
  const isTtcmCampaign = false;

  const { i18n, t } = useQueryHelper();
  const { handleCopyHasHashtag, handleCopyHasText } = useCopy();
  const { isMobileView } = usePageLayout();
  const isAffiliateCampaign = campaignType === MarketplaceCampaignType.AFFILIATE;
  const isApplying = appliedStatus === MarketplaceCampaignAppliedStatus.APPLYING;
  const isCampaignFinishWithPosting =
    ((anyXStatus &&
      [AnyXEngagementCampaignStatus.INEFFECTIVE, AnyXMarketplaceCampaignStatus.INEFFECTIVE].includes(anyXStatus)) ||
      appliedStatus === MarketplaceCampaignAppliedStatus.REJECTED ||
      status === CampaignStatusForInfluencer.FINISHED) &&
    anyXStatus &&
    [AnyXEngagementCampaignStatus.POSTING, AnyXMarketplaceCampaignStatus.POSTING].includes(anyXStatus);
  const isCampaignSocialMedias = !!socialMedias.find(socialMedia => socialMedia !== CampaignSocialMediaType.UNSELECT);
  const isJPLanguage = i18n.language === 'ja';
  const isRejected =
    appliedStatus === MarketplaceCampaignAppliedStatus.REJECTED ||
    anyXStatus === AnyXMarketplaceCampaignStatus.INEFFECTIVE;
  const isSearchCampaign = !isApplied && !isJoined; // For selection marketplace and normal marketplace
  const isTtcmApproved = !!isTtcmCampaign && ttcmStatus === TTCMStatus.APPROVED;
  const isTtcmInvitation =
    !!isTtcmCampaign && [TTCMStatus.INVITED, TTCMStatus.REJECTED].includes(ttcmStatus as TTCMStatus);
  const isUpcomingNormalCampaign =
    status === CampaignStatusForInfluencer.UPCOMING && campaignType === MarketplaceCampaignType.NORMAL;
  const revenueRewards = [
    { title: 'RevenuePer.Action', value: revenuePerAction },
    { title: 'RevenuePer.Click', value: revenuePerClick },
    { title: 'RevenuePer.Comment', value: revenuePerComment },
    { title: 'RevenuePer.Follower', value: revenuePerFollower },
    { title: 'RevenuePer.Like', value: revenuePerLike },
    { title: 'RevenuePer.Order', value: revenuePerOrder },
    { title: 'RevenuePer.Post', value: revenuePerPost },
    { title: 'RevenuePer.Share', value: revenuePerShare },
    { title: 'RevenuePer.View', value: revenuePerView },
  ];
  const showBudgetProgressBar =
    !isAffiliateCampaign && !isGiftingCampaign && remainingAmount !== undefined && totalAmount !== undefined;
  const showShipmentMessage =
    isGiftingCampaign &&
    [AnyXMarketplaceCampaignStatus.SHIPPED, AnyXMarketplaceCampaignStatus.WAITING_FOR_SHIPMENT].includes(
      anyXStatus as AnyXMarketplaceCampaignStatus
    );

  const startDay = getDayfromDateString(startDate);
  const startDayNumber = startDay.substring(0, 2);
  const startDayWord = startDay.substring(2, 3);
  const endDay = getDayfromDateString(endDate);
  const endDayNumber = endDay.substring(0, 2);
  const endDayWord = endDay.substring(2, 3);

  return (
    <div css={styles.infoContainer}>
      <div css={styles.imgSection}>
        <img alt="campaignImg" src={thumbNail} />
      </div>

      <div css={styles.infoSection}>
        <Grid md={8} xs={12}>
          {campaignType === MarketplaceCampaignType.SELECTION ? (
            isSearchCampaign || appliedStatus === MarketplaceCampaignAppliedStatus.APPLYING ? (
              <MessageIndicator>
                <MessageSubTitle>{t('Heading.This is a Selection campaign')}</MessageSubTitle>
                <MessageDescription>
                  {t('Annotation.This campaign is only open to those who have applied and been accepted')}
                </MessageDescription>
              </MessageIndicator>
            ) : isRejected ? (
              <MessageIndicator>
                <MessageSubTitle>{t('Heading.Application Rejected')}</MessageSubTitle>
                <MessageDescription>
                  {t(
                    'Annotation.We regret to announce that your application was not approved. We look forward to seeing you again soon!'
                  )}
                </MessageDescription>
              </MessageIndicator>
            ) : null
          ) : isRejected ? (
            <MessageIndicator>
              <MessageSubTitle>{t('Heading.Your post is rejected')}</MessageSubTitle>
              <MessageDescription>
                {t(`Annotation.The post content didn't meet the campaign condition`)}
              </MessageDescription>
            </MessageIndicator>
          ) : showShipmentMessage ? (
            <MessageIndicator>
              {anyXStatus === AnyXMarketplaceCampaignStatus.SHIPPED ? (
                <>
                  <MessageSubTitle>{t('Heading.Item has been shipped')}</MessageSubTitle>
                  <MessageDescription>
                    {t(
                      'Annotation.Your order has been shipped to your registered address and will arrive within 3-7 business days'
                    )}
                  </MessageDescription>
                </>
              ) : (
                <>
                  <MessageSubTitle>{t('Heading.Please wait for the product to be shipped')}</MessageSubTitle>
                  <MessageDescription>
                    {t('Annotation.The AnyCreator team will prepare the goods for shipment')}
                  </MessageDescription>
                </>
              )}
            </MessageIndicator>
          ) : warningReason === WarningReason.COUPON_MISSING ? (
            <MessageIndicator color="#ff5f5f">
              <MessageSubTitle>{t(`Heading.Your post doesn't have coupon code`)}</MessageSubTitle>
              <div css={styles.messageCouponCodeInfo}>
                <div>{t('Label.Coupon code')}</div>
                <div>{couponCode}</div>
              </div>
              <ThemeButton
                css={{ height: 40 }}
                href={postUrl || getSocialMediaUrlByCampaignType(socialMedias[0])}
                targetBlank
                text="Check post"
              />
            </MessageIndicator>
          ) : null}

          {anyXStatus &&
            !isAffiliateCampaign &&
            !isApplying &&
            !isCampaignFinishWithPosting &&
            !isRejected &&
            !isSearchCampaign &&
            !isTtcmInvitation &&
            !isUpcomingNormalCampaign && (
              <div css={[styles.stepperContainer, !isTtcmCampaign ? { borderBottom: '1px solid #eef3f7' } : {}]}>
                <div>
                  <StatusStepper
                    isGiftingCampaign={isGiftingCampaign}
                    isTtcmCampaign={isTtcmCampaign}
                    postStatus={postStatus}
                    status={anyXStatus}
                    type={type}
                  />
                </div>
              </div>
            )}

          {isTtcmApproved && <StatusMessage status={anyXStatus as AnyXEngagementCampaignStatus} />}

          {isTtcmInvitation && <div css={styles.invitationIndicator}>{t('Invitation')}</div>}

          <div css={styles.title}>{title}</div>

          {isMobileView && showBudgetProgressBar && (
            <div css={styles.progressBarContainer}>
              <CampaignBudgetProgressBar remainingAmount={remainingAmount} totalAmount={totalAmount} />
            </div>
          )}

          <div css={styles.rewardBudgetContainer}>
            <Grid sm={showBudgetProgressBar ? 6 : 12} xs={12}>
              <div css={styles.rewardPeriodContainer}>
                <div>
                  <div css={{ gap: '8px' }}>
                    {type === CampaignType.MARKETPLACE && isCampaignSocialMedias && (
                      <img alt="socialMediaIcon" height="15" src={switchImage(socialMedias[0])} width="15" />
                    )}
                    <span>{t('Reward amount')}</span>
                  </div>
                  {isGiftingCampaign ? (
                    <RewardAmount>{t('Gifting')}</RewardAmount>
                  ) : type === CampaignType.ENGAGEMENT ? (
                    rewardAmount && (
                      <div>
                        <RewardAmount large>{formatNumberWithCommas(rewardAmount)}</RewardAmount>
                        &nbsp;
                        <Currency large>{t(currency)}</Currency>
                      </div>
                    )
                  ) : (
                    <div style={{ display: 'grid', justifyContent: 'center' }}>
                      {!!commissionRate && (
                        <RewardAmountField>
                          <RewardAmount>{formatPercent(commissionRate, false, 0)}</RewardAmount>
                          &nbsp;
                          <Currency>{`% / ${t('Commission rate')}`}</Currency>
                        </RewardAmountField>
                      )}
                      {revenueRewards.map((revenue, revenueIndex) => {
                        const { title: revenueTitle, value } = revenue;

                        return (
                          value && (
                            <RewardAmountField key={revenueIndex}>
                              <RewardAmount>{formatNumberWithCommas(value, undefined, currency)}</RewardAmount>
                              &nbsp;
                              <Currency>{`${t(currency)} / ${t(revenueTitle)}`}</Currency>
                            </RewardAmountField>
                          )
                        );
                      })}
                    </div>
                  )}
                </div>
                <div>
                  <div>{t('Campaign Period')}</div>
                  <div>
                    <MonthFont isJPLanguage={isJPLanguage}>
                      {localizedDateFormatter(new Date(startDate), 'MMM', i18n.language as AppLanguage)}
                    </MonthFont>
                    <DayFont>
                      {startDayNumber}
                      <DayWord>{startDayWord}</DayWord>
                    </DayFont>
                    <span css={styles.periodDash}>-</span>
                    <MonthFont isJPLanguage={isJPLanguage}>
                      {localizedDateFormatter(new Date(endDate), 'MMM', i18n.language as AppLanguage)}
                    </MonthFont>
                    <DayFont>
                      {endDayNumber}
                      <DayWord>{endDayWord}</DayWord>
                    </DayFont>
                  </div>
                </div>
              </div>
            </Grid>
            {!isAffiliateCampaign && !isMobileView && showBudgetProgressBar && (
              <Grid xs={6}>
                <CampaignBudgetProgressBar remainingAmount={remainingAmount} totalAmount={totalAmount} />
              </Grid>
            )}
          </div>

          <InfoHeaderTitle>{t('Label.Product information')}</InfoHeaderTitle>
          <InfoField marginBottom>
            <InfoTitle>{t('Label.Product / Service Information')}</InfoTitle>
            <InfoContent>
              <RichEditor
                disable={true}
                height="auto"
                setDefaultStyle="font-size: 14px; padding: 0;"
                setOptions={{ resizingBar: false }}
                showToolbar={false}
                value={serviceInformation}
              />
              {(clickUrl || serviceUrl) && (
                <StyledLink marginTop to={{ pathname: clickUrl || serviceUrl || '' }} target="_blank">
                  <span>{t('Button.More Detail')}</span>
                  <img alt="icon" height="15" src={linkIcon} width="15" />
                </StyledLink>
              )}
            </InfoContent>
          </InfoField>

          <InfoHeaderTitle>{t('Label.Request details')}</InfoHeaderTitle>
          <InfoField>
            <InfoTitle>{t('Label.Posting period')}</InfoTitle>
            <InfoContent>
              {`${format(new Date(startDate), 'yyyy.MM.dd')} -  ${format(new Date(endDate), 'yyyy.MM.dd')}`}
            </InfoContent>
          </InfoField>

          {isCampaignSocialMedias && (
            <InfoField>
              <InfoTitle>{t('Label.Social Media')}</InfoTitle>
              <InfoContent>
                <div css={styles.socialMediasContainer}>
                  {socialMedias.map((socialMedia, index) => (
                    <div css={styles.socialMedia} key={index}>
                      <img height="18" src={switchImage(socialMedia)} width="18" />
                      <span>{switchText(socialMedia)}</span>
                    </div>
                  ))}
                </div>
              </InfoContent>
            </InfoField>
          )}

          <InfoField>
            <InfoTitle>{t('Label.Post Requirements')}</InfoTitle>
            <InfoContent>
              <RichEditor
                disable={true}
                height="auto"
                setDefaultStyle="font-size: 14px; padding: 0;"
                setOptions={{ resizingBar: false }}
                showToolbar={false}
                value={requirement || requirements || ''}
              />
            </InfoContent>
          </InfoField>

          {!!isCouponCampaign && (
            <InfoField>
              <InfoTitle>{t('Label.Coupon code')}</InfoTitle>
              <InfoContent>
                {couponCode ? (
                  <div css={styles.copyCouponCodeContainer}>
                    <div>{couponCode}</div>
                    <ThemeButton text="Copy code" theme="blue" onClick={() => handleCopyHasText(couponCode)} />
                  </div>
                ) : (
                  <div css={styles.couponCodeMessage}>{t('Annotation.Coupon will be displayed after you joined')}</div>
                )}
              </InfoContent>
            </InfoField>
          )}

          {!!campaignDetails.isTtcmCampaign && (
            <InfoField>
              <InfoTitle>{t('Label.TTCM Campaign Code')}</InfoTitle>
              <InfoContent>
                {ttcmCampaignCode ? (
                  <div css={styles.copyCouponCodeContainer}>
                    <div>{ttcmCampaignCode}</div>
                    <ThemeButton text="Copy code" theme="blue" onClick={() => handleCopyHasText(ttcmCampaignCode)} />
                  </div>
                ) : (
                  <div css={styles.couponCodeMessage}>{t('Annotation.Coupon will be displayed after you joined')}</div>
                )}
              </InfoContent>
            </InfoField>
          )}

          {!!trackingUrl && (
            <InfoField>
              <InfoTitle>{t('Label.Tracking URL')}</InfoTitle>
              <InfoContent>
                <div>{t('Annotation.Please add this URL to your post')}</div>
                <div css={styles.trackingUrlContainer}>
                  <TextForm disabled placeholder="" value={trackingUrl} />
                  <ThemeButton text="Copy URL" theme="blue" onClick={() => handleCopyHasText(trackingUrl)} />
                </div>
              </InfoContent>
            </InfoField>
          )}

          {!isAffiliateCampaign && hashtags.length > 0 && (
            <InfoField>
              <InfoTitle>{t('Label.Hash Tags')}</InfoTitle>
              <InfoContent>
                <div css={styles.hashtagsContainer}>
                  {hashtags.map((hastag, index) => (
                    <div css={styles.hashtag} key={index}>
                      {`#${hastag}`}
                    </div>
                  ))}
                </div>
                <ThemeButton
                  css={{ height: 40, width: '100%' }}
                  text="Copy Hashtags"
                  width="max-content"
                  onClick={() => handleCopyHasHashtag(hashtags)}
                />
              </InfoContent>
            </InfoField>
          )}

          {!!maximumRevenuePerInfluencer && (
            <InfoField>
              <InfoTitle>{t('Label.Maximum Revenue')}</InfoTitle>
              <InfoContent>{`${formatNumberWithCommas(maximumRevenuePerInfluencer, 0)} ${t(currency)}`}</InfoContent>
            </InfoField>
          )}

          {!!materialUrl && (
            <InfoField>
              <InfoTitle>{t('Label.Download materials for posting')}</InfoTitle>
              <InfoContent>
                <StyledLink to={{ pathname: materialUrl || '' }} target="_blank">
                  <Icomoon icon="import" size={15} />
                  <span>{t('Button.Download materials')}</span>
                </StyledLink>
              </InfoContent>
            </InfoField>
          )}

          {!!(materials && materials.length > 0) && (
            <InfoField>
              <InfoTitle>{t('Label.Posting Samples')}</InfoTitle>
              <InfoContent css={isMobileView && { display: 'flex', justifyContent: 'center' }}>
                <div css={styles.carouselContainer}>
                  <Carousel height="230px" sources={materials.map(item => item.url) || [emptyImage]} width="300" />
                </div>
              </InfoContent>
            </InfoField>
          )}
        </Grid>
      </div>
    </div>
  );
};

const Currency = styled.span<{ large?: boolean }>`
  color: #6e7c89;
  font-size: ${({ large }) => (large ? 11 : 10)}px;
  word-break: keep-all;
`;

const DayFont = styled.span`
  color: #27313b;
  font-size: 12px;
  font-weight: 600;
`;

const DayWord = styled.span`
  color: #27313b;
  font-size: 11px;
  font-weight: 600;
`;

const InfoContent = styled.div`
  font-size: 14px;
  padding: 16px;

  & .slick-arrow {
    border-radius: 50%;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
    height: 32px !important;
    position: absolute;
    width: 32px !important;

    & > i {
      margin-top: 1px;
    }

    &.slick-prev {
      left: -15px !important;
    }

    &.slick-next {
      right: -15px !important;
    }
  }

  & .slick-dots {
    bottom: -30px !important;
  }

  & .slick-dots li button::before {
    color: #3892e5 !important;
    font-size: 11px !important;
  }
`;

const InfoField = styled.div<{ marginBottom?: boolean }>`
  border: 1px solid #eef3f7;
  ${({ marginBottom }) => (marginBottom ? 'margin-bottom: 16px;' : '')}
`;

const InfoHeaderTitle = styled.div`
  color: #27313b;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
`;

const MessageDescription = styled.div`
  color: #6e7c89;
  font-size: 13px;
`;

const MessageIndicator = styled.div<{ color?: string }>`
  border-left: 5px solid ${({ color }) => color || '#ffb63d'};
  border-radius: 3px;
  box-shadow: 0 1px 5px rgba(39, 49, 59, 0.2);
  margin: 16px 0;
  padding: 16px;
`;

const MessageSubTitle = styled.div`
  color: #27313b;
  font-size: 13px;
  font-weight: 600;
`;

const MonthFont = styled.span<{ isJPLanguage: boolean }>`
  color: #27313b;
  font-size: 12px;
  font-weight: 600;
  ${({ isJPLanguage }) => !isJPLanguage && 'margin-right: 5px;'}
`;

const RewardAmount = styled.span<{ large?: boolean }>`
  color: #27313b;
  font-size: ${({ large }) => (large ? 16 : 14)}px;
  font-weight: 600;
`;

const RewardAmountField = styled.div`
  text-align: start;
`;

const StyledLink = styled(Link, { shouldForwardProp: prop => isPropValid(prop) })<{ marginTop?: boolean }>`
  align-items: center;
  border: 1px solid #dee5ec;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  height: 40px;
  justify-content: center;
  width: 100%;
  ${({ marginTop }) => (marginTop ? 'margin-top: 16px;' : '')}

  /* stylelint-disable no-descending-specificity */
  & > span {
    color: #6e7c89;
    font-weight: 600;
    margin-right: 8px;
  }
`;

const InfoTitle = styled.div`
  background-color: #eef3f7;
  font-size: 12px;
  font-weight: 600;
  padding: 8px;
`;

const styles = {
  carouselContainer: css`
    max-width: 300px;

    & img {
      object-fit: cover;
    }
  `,
  copyCouponCodeContainer: css`
    display: flex;
    gap: 12px;

    & > div {
      align-items: center;
      border: 1px solid #eef3f7;
      border-radius: 3px;
      color: #6e7c89;
      font-size: 16px;
      font-weight: 600;
      display: flex;
      flex: 1;
      height: 40px;
      justify-content: center;
    }

    & > button {
      height: 40px;
      width: 168px;

      @media (max-width: ${ViewportType.TABLET}px) {
        width: fit-content;
      }
    }
  `,
  couponCodeMessage: css`
    color: #97a4af;
    font-size: 14px;
    text-align: center;
  `,
  hashtag: css`
    background-color: #d7e9fb;
    color: #3992e5;
    margin-bottom: 8px;
    padding: 8px;
    width: fit-content;
  `,
  hashtagsContainer: css`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  `,
  imgSection: css`
    background-color: #000;
    display: flex;
    height: 338px;
    width: 100%;

    & > img {
      object-fit: contain;
      width: inherit;
    }
  `,
  infoContainer: css`
    display: flex;
    flex-wrap: wrap;
  `,
  infoSection: css`
    background-color: #fff;
    display: flex;
    justify-content: center;
    padding: 16px;
    width: 100%;

    @media (max-width: ${ViewportType.SMALL}px) {
      border-radius: 24px 24px 0 0;
      margin-bottom: 32px;
      margin-top: -16px;
    }
  `,
  invitationIndicator: css`
    border: 1px solid #40b87c;
    border-radius: 35px;
    color: #40b87c;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 22px;
    padding: 6px 8px;
    width: fit-content;
  `,
  messageCouponCodeInfo: css`
    align-items: center;
    display: flex;
    gap: 7px;
    margin: 12px 0 16px;

    & > div:nth-of-type(1) {
      align-items: center;
      border: 1px solid #6e7c89;
      border-radius: 32px;
      color: #6e7c89;
      display: flex;
      font-size: 12px;
      font-weight: 600;
      height: 24px;
      justify-content: center;
      width: 94px;
    }

    & > div:nth-of-type(2) {
      color: #6e7c89;
      font-size: 18px;
      font-weight: 600;
    }
  `,
  periodDash: css`
    color: #27313b;
    font-weight: 600;
    margin: 0 5px;
  `,
  progressBarContainer: css`
    margin: 16px 0;
  `,
  rewardBudgetContainer: css`
    align-items: center;
    border-bottom: 1px solid #dee5ec;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
    padding-bottom: 16px;
  `,
  rewardPeriodContainer: css`
    display: flex;
    flex-wrap: wrap;

    & > div {
      display: grid;
      flex-basis: 49%;
      text-align: center;

      & > div:nth-of-type(1) {
        align-items: flex-start;
        color: #6e7c89;
        display: flex;
        flex-wrap: wrap;
        font-size: 11px;
        font-weight: 600;
        justify-content: center;
        margin-bottom: 5px;
      }
    }

    & > div:nth-of-type(1) {
      border-right: 1px solid #dee5ec;
    }
  `,
  stepperContainer: css`
    display: flex;
    justify-content: center;
    margin-bottom: 24px;

    & > div {
      display: flex;
      flex-basis: 60%;

      @media (max-width: ${ViewportType.SMALL}px) {
        flex-basis: 100%;
      }
    }
  `,
  socialMedia: css`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 8px;
    margin-right: 16px;
    gap: 8px;
  `,
  socialMediasContainer: css`
    display: flex;
    flex-wrap: wrap;
  `,
  title: css`
    color: #27313b;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 16px;
  `,
  trackingUrlContainer: css`
    display: flex;
    gap: 8px;
    margin-top: 16px;
    justify-content: flex-end;

    @media (max-width: ${ViewportType.TABLET}px) {
      flex-wrap: wrap;
    }

    & > div {
      width: fill-available;
    }

    & > button {
      width: 168px;

      @media (max-width: ${ViewportType.TABLET}px) {
        width: fit-content;
      }
    }
  `,
};

export default CampaignDetails;
