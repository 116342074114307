import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminStore } from '../../Context';

interface UpdateTitleType {
  title: string;
  interpolation?: Record<string, string>;
  pageTitle?: string;
  href?: string;
  skipUpdate?: { title: boolean; pageTitle: boolean };
}

const useUpdateDocumentTitle = ({ title, interpolation, pageTitle = '', href = '', skipUpdate }: UpdateTitleType) => {
  const { t, i18n } = useTranslation();
  const { dispatch } = useContext(AdminStore);

  useEffect(() => {
    if (!skipUpdate?.title && title) {
      document.title = t(title, { ...interpolation });
    }
  }, [i18n.language, i18n.isInitialized, interpolation?.name, skipUpdate?.title, title]);

  useEffect(() => {
    if (!skipUpdate?.pageTitle && pageTitle) {
      dispatch({
        type: 'APP_USER',
        payload: { title: t(pageTitle, { ...interpolation }), href },
      });
    }
  }, [pageTitle, i18n.language, i18n.isInitialized, interpolation?.name, skipUpdate?.pageTitle]);

  const setDocumentAndAppTitles = (props: UpdateTitleType) => {
    if (props.title && props.pageTitle) {
      document.title = t(props.title, { ...props.interpolation });
      dispatch({
        type: 'APP_USER',
        payload: {
          title: t(props.pageTitle, { ...props.interpolation }),
          href: props.href,
        },
      });
    }
  };

  return {
    setDocumentAndAppTitles,
  };
};

export default useUpdateDocumentTitle;
