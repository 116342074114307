import React, { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
  children?: ReactNode;
}
interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      // we can have a fallback UI rendered here if plan to reuse ErrorBoundary elsewhere
      return (
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            width: '100%',
            height: '100%',
            alignItems: 'center',
            placeContent: 'center',
          }}
        >
          <h1>Sorry.. there was an error</h1>
          <p>Try to reload the page or check your network connection</p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
