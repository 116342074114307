import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import emailsEmptyListBg from '@src/assets/img/emailsEmptyListBg.svg';

const EmptyList = () => {
  const { t } = useTranslation();

  return (
    <div css={styles.container}>
      <div>
        <div>
          <div css={styles.addEmailWrapper}>
            <img src={emailsEmptyListBg} css={styles.emptyChat} />
            <div css={styles.addEmail}>
              <span className="plus">+</span>
              <span>{` ${t('Button.Add')}`}</span>
            </div>
          </div>
        </div>
        <div>{t(`Let's compose Emails`)}</div>
        <div>{t('Create your Emails and reach out to them in an effective way')}</div>
        <div>
          <Link to="emails/compose">{t('Button.Compose')}</Link>
        </div>
      </div>
    </div>
  );
};

const styles = {
  addEmail: css`
    position: absolute;
    padding: 0 9px;
    border-radius: 16px;
    background-color: #3892e5;
    color: #fff;
    font-size: 13px;
    font-weight: bold;
    line-height: 140%;
    bottom: 35px;
    left: 50%;
    transform: translate(-50%, -16px);

    span {
      vertical-align: text-bottom;

      &.plus {
        font-size: 18px;
      }
    }
  `,
  addEmailWrapper: css`
    position: relative;
    align-self: center;
  `,
  container: css`
    align-items: center;
    background-color: #fff;
    border-radius: 0 0 3px 3px;
    border-top: none;
    display: flex;
    justify-content: center;
    padding: 126px 0;
    margin: 0 24px;

    & > div {
      & > div:nth-of-type(1) {
        display: flex;
        justify-content: center;
        margin-bottom: 24px;
      }

      & > div:nth-of-type(2) {
        color: #000;
        display: flex;
        font-size: 16px;
        font-weight: 600;
        justify-content: center;
        margin-bottom: 24px;
      }

      & > div:nth-of-type(3) {
        color: #000;
        display: flex;
        font-size: 14px;
        justify-content: center;
        margin-bottom: 24px;
      }

      & > div:nth-of-type(4) {
        display: flex;
        justify-content: center;

        & > a {
          align-items: center;
          background-color: #3892e5;
          border-radius: 3px;
          color: #fff;
          display: flex;
          font-size: 12px;
          font-weight: 600;
          height: 32px;
          justify-content: center;
          width: 97px;
        }
      }
    }
  `,
  emptyChat: css`
    margin: auto;
    width: 180px;
    height: 180px;
  `,
};

export default EmptyList;
