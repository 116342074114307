import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';
import { useLazyQuery } from '@apollo/client';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import Grid from '@src/components/atoms/Grid';
import { ListIndicator } from '@src/components/molecules/Indicator';
import SingleSelectField from '@src/components/molecules/SingleSelectField';
import TextForm from '@src/components/molecules/TextForm';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import { getOptions } from '@src/libs/form';
import { useQueryHelper } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/theme';
import { FormSubmitSection } from '@src/components/shared/FormSubmitSection';
import { ROUTES } from '@src/shared/routes';
import GET_BANK_BRANCHES from './queries/BankBranches.graphql';
import { BankBranches, BankBranchesVariables } from './queries/__generated__/BankBranches';

export interface Information {
  accountName: string;
  accountNumber: string;
  address: string;
  bankId: string;
  branchId: string;
  branchName: string;
  cityId: string;
  email: string;
  fullName: string;
  iban: string;
  postalCode: string;
  subject: string;
  swiftCode: string;
}

interface PaymentInformationProps {
  allBanks: {
    hasBranches: boolean;
    label: string;
    value: string;
  }[];
  allCities: {
    label: string;
    value: string;
  }[];
  isLoading: boolean;
  isMenaInfluencer: boolean;
  isJPInfluencer: boolean;
}

const PaymentInformation = ({
  allBanks,
  allCities,
  isLoading,
  isMenaInfluencer,
  isJPInfluencer,
}: PaymentInformationProps) => {
  const { enqueueSnackbar, t } = useQueryHelper();
  const isDesktopView = useMediaQuery({ query: `(min-width: ${ViewportType.TABLET}px)` });
  const {
    formState: { errors, isSubmitting },
    register,
    setValue,
    watch,
  } = useFormContext<Information>();
  const [bankIdValue, branchIdValue, cityIdValue, subjectValue] = watch(['bankId', 'branchId', 'cityId', 'subject']);

  const subjectOptions = [
    { value: '普通', label: '普通' },
    { value: '当座', label: '当座' },
    { value: '貯蓄', label: '貯蓄' },
  ];

  const [getBankBranches, { data: dataBankBranches }] = useLazyQuery<BankBranches, BankBranchesVariables>(
    GET_BANK_BRANCHES,
    {
      onError: error => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
    }
  );

  useEffect(() => {
    getBankBranches({
      variables: {
        bankId: Number(bankIdValue),
      },
    });
  }, [bankIdValue]);

  const allBranches = dataBankBranches?.allBankBranchesForInfluencer
    ? getOptions(dataBankBranches.allBankBranchesForInfluencer)
    : [];

  return (
    <div css={styles.container}>
      {isDesktopView && <BackNavigator to={ROUTES.SETTINGS} title="Payment Information" />}

      <div css={styles.description}>
        {t('Annotation.View and edit the payment information to receive revenue of sponsorship jobs')}
      </div>

      <div css={styles.formContainer}>
        <Grid md={8} sm={10} xs={12}>
          {isLoading ? (
            <ListIndicator />
          ) : (
            <>
              <div css={styles.formInputSection}>
                {isJPInfluencer ? (
                  <>
                    <InputContainer>
                      <StyledTextForm
                        error={!!errors.fullName}
                        isRequired
                        title="Full Name"
                        placeholder={t('TextForm.Full Name')}
                        {...register('fullName')}
                      />
                      <ErrorMessage message={errors.fullName?.message} />
                    </InputContainer>

                    <InputContainer>
                      <StyledSingleSelectField
                        error={!!errors.bankId}
                        isRequired
                        name="bankId"
                        options={allBanks}
                        title="Bank Name"
                        value={bankIdValue}
                        setFieldValue={setValue}
                      />
                      <ErrorMessage message={errors.bankId?.message} />
                    </InputContainer>

                    <InputContainer>
                      <StyledSingleSelectField
                        error={!!errors.branchId}
                        isRequired
                        name="branchId"
                        options={allBranches}
                        title="Branch"
                        value={branchIdValue}
                        setFieldValue={setValue}
                      />
                      <ErrorMessage message={errors.branchId?.message} />
                    </InputContainer>

                    <InputContainer>
                      <StyledSingleSelectField
                        error={!!errors.subject}
                        isRequired
                        name="subject"
                        options={subjectOptions}
                        title="Subject"
                        value={subjectValue || ''}
                        setFieldValue={setValue}
                      />
                      <ErrorMessage message={errors.subject?.message} />
                    </InputContainer>

                    <InputContainer>
                      <StyledTextForm
                        error={!!errors.accountNumber}
                        isRequired
                        title="Account Number"
                        placeholder={t('TextForm.Account Number')}
                        type="number"
                        {...register('accountNumber')}
                      />
                      <ErrorMessage message={errors.accountNumber?.message} />
                    </InputContainer>

                    <InputContainer>
                      <StyledTextForm
                        error={!!errors.accountName}
                        isRequired
                        title="Account Name"
                        placeholder={t('TextForm.Account Name')}
                        {...register('accountName')}
                      />
                      <ErrorMessage message={errors.accountName?.message} />
                    </InputContainer>
                  </>
                ) : isMenaInfluencer ? (
                  <>
                    <InputContainer>
                      <StyledTextForm
                        error={!!errors.accountName}
                        isRequired
                        title="Account Holder Name"
                        placeholder={t('TextForm.Account Holder Name')}
                        {...register('accountName')}
                      />
                      <ErrorMessage message={errors.accountName?.message} />
                    </InputContainer>

                    <InputContainer>
                      <StyledTextForm
                        error={!!errors.email}
                        isRequired
                        title="Payment Email Address"
                        placeholder={t('TextForm.Payment Email Address')}
                        {...register('email')}
                      />
                      <ErrorMessage message={errors.email?.message} />
                    </InputContainer>

                    <InputContainer>
                      <StyledTextForm
                        error={!!errors.swiftCode}
                        isRequired
                        title="Swift Code"
                        placeholder={t('TextForm.Swift Code')}
                        {...register('swiftCode')}
                      />
                      <ErrorMessage message={errors.swiftCode?.message} />
                    </InputContainer>

                    <InputContainer>
                      <StyledSingleSelectField
                        error={!!errors.bankId}
                        isRequired
                        name="bankId"
                        options={allBanks}
                        title="Bank Name"
                        value={bankIdValue || ''}
                        setFieldValue={setValue}
                      />
                      <ErrorMessage message={errors.bankId?.message} />
                    </InputContainer>

                    <InputContainer>
                      <StyledTextForm
                        error={!errors.branchName}
                        isRequired
                        title="Branch Name"
                        placeholder={t('TextForm.Branch Name')}
                        {...register('branchName')}
                      />
                      <ErrorMessage message={errors.branchName?.message} />
                    </InputContainer>

                    <InputContainer>
                      <StyledTextForm
                        error={!!errors.accountNumber}
                        isRequired
                        title="Account Number"
                        placeholder={t('TextForm.Account Number')}
                        type="number"
                        {...register('accountNumber')}
                      />
                      <ErrorMessage message={errors.accountNumber?.message} />
                    </InputContainer>

                    <InputContainer>
                      <StyledTextForm
                        error={!!errors.iban}
                        isRequired
                        title="IBAN"
                        placeholder={t('TextForm.IBAN')}
                        {...register('iban')}
                      />
                      <ErrorMessage message={errors.iban?.message} />
                    </InputContainer>
                  </>
                ) : (
                  <>
                    <InputContainer>
                      <StyledTextForm
                        error={!!errors.accountName}
                        isRequired
                        title="Account Holder Name(Full Name)"
                        placeholder={t('TextForm.Account Holder Name(Full Name)')}
                        {...register('accountName')}
                      />
                      <ErrorMessage message={errors.accountName?.message} />
                    </InputContainer>

                    <InputContainer>
                      <StyledTextForm
                        error={!!errors.email}
                        isRequired
                        title="Payment Email Address"
                        placeholder={t('TextForm.Payment Email Address')}
                        {...register('email')}
                      />
                      <ErrorMessage message={errors.email?.message} />
                    </InputContainer>

                    <InputContainer>
                      <StyledSingleSelectField
                        error={!!errors.bankId}
                        isRequired
                        name="bankId"
                        options={allBanks}
                        title="Bank Name"
                        value={bankIdValue || ''}
                        setFieldValue={setValue}
                      />
                      <ErrorMessage message={errors.bankId?.message} />
                    </InputContainer>

                    <InputContainer>
                      <StyledTextForm
                        error={!!errors.accountNumber}
                        isRequired
                        title="Account Number"
                        placeholder={t('TextForm.Account Number')}
                        type="number"
                        {...register('accountNumber')}
                      />
                      <ErrorMessage message={errors.accountNumber?.message} />
                    </InputContainer>

                    <InputContainer>
                      <StyledTextForm
                        error={!!errors.address}
                        isRequired
                        title="Your Address"
                        placeholder={t('TextForm.Your Address')}
                        {...register('address')}
                      />
                      <ErrorMessage message={errors.address?.message} />
                    </InputContainer>

                    <InputContainer>
                      <StyledSingleSelectField
                        error={!!errors.cityId}
                        isRequired
                        name="cityId"
                        options={allCities}
                        title="City"
                        value={cityIdValue || ''}
                        setFieldValue={setValue}
                      />
                      <ErrorMessage message={errors.cityId?.message} />
                    </InputContainer>

                    <InputContainer>
                      <StyledTextForm
                        error={!!errors.postalCode}
                        isRequired
                        title="Postal Code"
                        placeholder={t('TextForm.Postal Code')}
                        {...register('postalCode')}
                      />
                      <ErrorMessage message={errors.postalCode?.message} />
                    </InputContainer>
                  </>
                )}
              </div>

              <FormSubmitSection isSubmitting={isSubmitting} />
            </>
          )}
        </Grid>
      </div>
    </div>
  );
};

const InputContainer = styled.div`
  margin-bottom: 24px;
`;

const StyledSingleSelectField = styled(SingleSelectField)`
  & > label {
    font-size: 14px;
  }

  & > div > div {
    border-radius: 2px;
    min-height: 32px;
  }

  & input {
    border-radius: 2px;
    min-height: 32px;
  }
`;

const StyledTextForm = styled(TextForm)`
  & > label {
    font-size: 14px;
  }

  & input {
    border-radius: 2px;
    height: 32px;
  }
`;

const styles = {
  container: css`
    margin: 16px 24px;

    @media (max-width: ${ViewportType.TABLET}px) {
      margin: 16px;
    }
  `,
  description: css`
    color: #27313b;
    font-size: 14px;
    margin-bottom: 24px;
    margin-left: 48px;

    @media (max-width: ${ViewportType.TABLET}px) {
      margin-left: 0;
    }
  `,
  formContainer: css`
    border-radius: 3px;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
    display: flex;
    justify-content: center;
  `,
  formInputSection: css`
    background-color: #fff;
    padding: 16px;
  `,
};

export default PaymentInformation;
