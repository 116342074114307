import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { ShopifyOrderPaymentStatus, ShopifyOrderShippingStatus } from '@src/__generated__/globalTypes';
import { useTranslation } from 'react-i18next';
import { formatNumber } from '@src/libs/format';
import emptyThumbnail from '@src/assets/icon/emptyThumbnail.svg';
import { localizedDateFormatter } from '@src/components/atoms/List/DateColumn';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import { usePageLayout } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/theme';
import { getPaymentStatusText, getShippingStatusText } from './helper';

const ORDER_TIME_FORMAT = `d MMM yyyy 'at' h:mm aaa`;

interface OrderDetailsProps {
  orderId: string;
  paymentStatus: ShopifyOrderPaymentStatus;
  shippingStatus: ShopifyOrderShippingStatus | null;
  orderTime: string;
  currency: string;
  items: { productName: string; image: string | null; variant: string | null; price: number; quantity: number }[];
  total: number;
}

const OrderDetails = (props: OrderDetailsProps) => {
  const { i18n, t } = useTranslation();
  const { isMobileView } = usePageLayout();
  const { orderId, paymentStatus, shippingStatus, orderTime, total, currency, items } = props;

  const paymentStatusText = useMemo(() => getPaymentStatusText(paymentStatus), [paymentStatus]);
  const shippingStatusText = useMemo(() => getShippingStatusText(shippingStatus), [shippingStatus]);

  return (
    <Container>
      <Row css={styles.paddingTop16}>
        <HighlightedText>#{orderId}</HighlightedText>
        <Badge status={paymentStatus}>{t(paymentStatusText)}</Badge>
        <Badge status={shippingStatus}>{t(shippingStatusText)}</Badge>
        <Time>
          {localizedDateFormatter(new Date(orderTime + 'Z'), ORDER_TIME_FORMAT, i18n.language as AppLanguage)}
        </Time>
      </Row>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <Row>
            <FullWidthCol>
              <Thumbnail src={item.image || emptyThumbnail} />
              <BodyWrapper>
                <ContentText>{item.productName}</ContentText>
                <HighlightedText>
                  {t('Size/Color')} : {item.variant}
                </HighlightedText>
              </BodyWrapper>
            </FullWidthCol>
            {!isMobileView && (
              <>
                <div css={styles.verticalLine} />
                <FixedWidthCol css={[styles.lineHeight48, styles.textAlignRight]}>
                  <HighlightedText>
                    {currency}
                    {formatNumber(item.price)} x {item.quantity}
                  </HighlightedText>
                </FixedWidthCol>
                <div css={styles.verticalLine} />
                <FixedWidthCol css={[styles.lineHeight48, styles.textAlign]}>
                  <TotalCount>
                    {currency}
                    {formatNumber(item.price * item.quantity)}
                  </TotalCount>
                </FixedWidthCol>
              </>
            )}
          </Row>
          {isMobileView && (
            <Row>
              <FullWidthCol />
              <div css={styles.verticalLine} />
              <FixedWidthCol css={[styles.lineHeight48, styles.textAlignRight]}>
                <HighlightedText>
                  {currency}
                  {formatNumber(item.price)} x {item.quantity}
                </HighlightedText>
              </FixedWidthCol>
              <div css={styles.verticalLine} />
              <FixedWidthCol css={[styles.lineHeight48, styles.textAlign]}>
                <TotalCount>
                  {currency}
                  {formatNumber(item.price * item.quantity)}
                </TotalCount>
              </FixedWidthCol>
            </Row>
          )}
        </React.Fragment>
      ))}

      <Row css={[styles.borderTop, styles.footerPadding]}>
        <FullWidthCol />
        <FixedWidthCol css={[styles.lineHeight48, styles.textAlign, styles.clearLeftBorder]}>
          <HighlightedText>{t('Total')}</HighlightedText>
        </FixedWidthCol>
        <FixedWidthCol css={[styles.lineHeight48, styles.textAlign, styles.clearLeftBorder]}>
          <TotalCount>
            {currency}
            {formatNumber(total)}
          </TotalCount>
        </FixedWidthCol>
      </Row>
    </Container>
  );
};

const styles = {
  clearLeftBorder: css`
    border-left: 0;
  `,
  footerPadding: css`
    padding: 0 16px 0;
  `,
  paddingTop16: css`
    padding-top: 16px;
  `,
  marginRight8: css`
    margin-right: 8px;
  `,
  textAlign: css`
    text-align: center;
  `,
  textAlignRight: css`
    text-align: right;
    padding-right: 16px;
  `,
  lineHeight48: css`
    line-height: 48px;
  `,
  borderTop: css`
    border-top: 1px solid #eef3f7;
  `,
  verticalLine: css`
    width: 1px;
    background: #eef3f7;
  `,
};

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Thumbnail = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 5px;
  background: #dee5ec;
`;
const ContentText = styled.p`
  font-size: 14px;
  color: #27313b;
  margin-bottom: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 350px;

  @media (max-width: ${ViewportType.TABLET}px) {
    max-width: 65vw;
  }
`;
const HighlightedText = styled.span`
  font-weight: 600;
  font-size: 12px;
  color: #27313b;
`;
const TotalCount = styled(HighlightedText)`
  font-size: 15px;
  text-align: center;
`;
const Time = styled.span`
  font-size: 12px;
  color: #6e7c89;
  flex: 1;
  text-align: right;
`;
const Badge = styled.span<{ status: ShopifyOrderPaymentStatus | ShopifyOrderShippingStatus | null }>`
  height: 16px;
  background-color: ${props =>
    props.status === ShopifyOrderPaymentStatus.PAID || props.status === ShopifyOrderShippingStatus.FULFILLED
      ? '#40b87c'
      : '#FFB63D'};
  border-radius: 40px;
  font-weight: 600;
  font-size: 11px;
  color: #fff;
  padding: 0 8px;
  line-height: 16px;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border: 1px solid #eef3f7;
  border-radius: 5px;

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
`;
const Row = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  padding: 8px 16px;
  display: flex;
  gap: 8px;
`;
const FullWidthCol = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  gap: 8px;
`;
const FixedWidthCol = styled.div`
  width: 115px;
  box-sizing: border-box;
`;

export default OrderDetails;
