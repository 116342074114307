import { useMutation, useQuery } from '@apollo/client';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { useQueryHelper } from '@src/libs/hooks';
import * as GET_FAN from './GetFan.graphql';
import * as UPDATE_FAN from './UpdateFan.graphql';
import { GetFan, GetFanVariables } from './__generated__/GetFan';
import { UpdateFan, UpdateFanVariables } from './__generated__/UpdateFan';

interface UseGetFanProps {
  fanId: number;
}

const useGetFan = ({ fanId }: UseGetFanProps) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  const { data, error, loading } = useQuery<GetFan, GetFanVariables>(GET_FAN, {
    variables: {
      input: {
        fanId,
      },
    },
    fetchPolicy: 'network-only',
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
  });

  const [updateFan] = useMutation<UpdateFan, UpdateFanVariables>(UPDATE_FAN, {
    refetchQueries: ['GetFanHeader'],
    onCompleted: () => {
      enqueueSnackbar(t('succeededInUpdating'), { variant: 'success' });
      sendAmplitudeEvent(eventTypes.editFan, { fanId });
    },
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
  });

  return {
    data,
    error,
    loading,
    updateFan,
  };
};

export default useGetFan;
