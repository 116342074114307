import React from 'react';
import { css } from '@emotion/react';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import { ViewportType } from '@src/libs/theme';
import { usePageLayout } from '@src/libs/hooks';
import { ROUTES } from '@src/shared/routes';
import CreatorAccount from './CreatorAccount';
import YourAccount from './YourAccount';

const Account = () => {
  const { isMobileView } = usePageLayout();

  return (
    <div css={styles.container}>
      {!isMobileView && <BackNavigator title="Account" to={ROUTES.SETTINGS} />}

      <div css={styles.yourAccountFormContainer}>
        <YourAccount />
      </div>

      <CreatorAccount />
    </div>
  );
};

const styles = {
  container: css`
    margin: 16px 24px;

    @media (max-width: ${ViewportType.TABLET}px) {
      margin: 16px;
    }
  `,
  yourAccountFormContainer: css`
    margin: 24px 0;
  `,
};

export default Account;
