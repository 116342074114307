import styled from '@emotion/styled';
import React, { forwardRef, Ref } from 'react';
import { FORM } from '@src/libs/theme';
import { useDirLayout } from '@src/libs/hooks';

interface FieldType {
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: any) => void;
  value?: any;
  name?: string;
  defaultValue?: string;
  readOnly?: boolean;
}

export interface TextareaProps extends FormProps, FieldType {
  placeholder: string;
}

const Textarea = forwardRef((props: TextareaProps, ref: Ref<HTMLTextAreaElement>) => {
  const { placeholder, error, disabled, readOnly, ...field } = props;
  const { dir } = useDirLayout();

  return (
    <Form
      placeholder={!!disabled ? '' : placeholder}
      error={error}
      disabled={disabled}
      ref={ref}
      {...field}
      readOnly={readOnly}
      dir={dir}
    />
  );
});

interface FormProps {
  error?: boolean;
  disabled?: boolean;
}

const Form = styled.textarea<FormProps>`
  box-sizing: border-box;
  width: 100%;
  height: 140px;
  padding: 12px;
  background-color: ${props => (props.disabled ? FORM.DISABLED : '#fff')};
  border: 1px solid #dee5ec;
  border-color: ${props => (props.error ? FORM.ERROR : FORM.BORDER)};
  border-radius: 3px;
  box-shadow: inset 0 1px 2px rgba(39, 49, 59, 0.1);
  ${props => !props.error && `&:focus {border-color: #3892e5}`};
`;

Textarea.displayName = 'Textarea';

export default Textarea;
