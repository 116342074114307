import {
  CampaignPostStatusForInfluencer,
  CampaignStatusForInfluencer,
  EngagementCampaignPostStatus,
  PaymentRequestStatusForInfluencer,
  SocialAccountStatus,
  MarketplaceCampaignPostStatus,
} from '../../__generated__/globalTypes';
import { CampaignStatus } from '../campaign';

export const getCampaignStatusMessage = (status?: CampaignStatus | CampaignStatusForInfluencer | null) => {
  switch (status) {
    case CampaignStatus.UPCOMING:
      return 'Upcoming';

    case CampaignStatus.ONGOING:
      return 'Ongoing';

    case CampaignStatus.SUSPENDED:
      return 'Over Budget';

    case CampaignStatus.FINISHED:
      return 'Finished';

    case CampaignStatus.JOIN_NOW:
      return 'Join Now';

    case CampaignStatus.POST_NOW:
      return 'Post Now';

    default:
      return '';
  }
};

export const getCampaignPostStatusMessage = (status?: CampaignPostStatusForInfluencer | null) => {
  switch (status) {
    case CampaignPostStatusForInfluencer.REVIEWING:
    case CampaignPostStatusForInfluencer.WARNING: // Don't display "WARNING" status.
      return 'Reviewing';

    case CampaignPostStatusForInfluencer.EFFECTIVE:
      return 'Effective';

    case CampaignPostStatusForInfluencer.INEFFECTIVE:
      return 'Ineffective';

    default:
      return '';
  }
};

export const getEngagementPostStatusMessage = (status?: EngagementCampaignPostStatus | null) => {
  switch (status) {
    case EngagementCampaignPostStatus.REVIEWING:
    case EngagementCampaignPostStatus.REJECT:
      return 'Reviewing';

    case EngagementCampaignPostStatus.READY_TO_POST:
      return 'Ready to Post';

    case EngagementCampaignPostStatus.WARNING: // Don't display "WARNING" status.
    case EngagementCampaignPostStatus.WARNING_SOLVED:
    case EngagementCampaignPostStatus.EFFECTIVE:
    case EngagementCampaignPostStatus.APPROVED:
      return 'Effective';

    case EngagementCampaignPostStatus.INEFFECTIVE:
      return 'Ineffective';

    default:
      return '';
  }
};

export const getEngagementPostStatusHistoryMessage = (status?: EngagementCampaignPostStatus | null) => {
  switch (status) {
    case EngagementCampaignPostStatus.READY_TO_POST:
    case EngagementCampaignPostStatus.APPROVED:
      return 'Approved the post';

    case EngagementCampaignPostStatus.REJECT:
      return 'Rejected to Post';

    default:
      return '';
  }
};

export const getMarketplacePostStatusMessage = (status?: MarketplaceCampaignPostStatus | null) => {
  switch (status) {
    case MarketplaceCampaignPostStatus.WARNING: // Don't display "WARNING" status.
      return 'Reviewing';

    case MarketplaceCampaignPostStatus.EFFECTIVE:
      return 'Effective';

    case MarketplaceCampaignPostStatus.WARNING_SOLVED:
      return 'Effective'; // Don't display "WARNING_SOLVED" status.

    case MarketplaceCampaignPostStatus.APPROVED:
      return 'Approved';

    case MarketplaceCampaignPostStatus.INEFFECTIVE:
      return 'Ineffective';

    default:
      return '';
  }
};

export const getPaymentStatusMessage = (status?: PaymentRequestStatusForInfluencer | null) => {
  switch (status) {
    case PaymentRequestStatusForInfluencer.REVIEWING:
      return 'Reviewing';

    case PaymentRequestStatusForInfluencer.TRANSFERRED:
      return 'Paid';

    case PaymentRequestStatusForInfluencer.REJECTED:
      return 'Rejected';

    case PaymentRequestStatusForInfluencer.FAILED:
      return 'Failed';
    default:
      return '';
  }
};

export const getSocialAccountStatusMessage = (status?: SocialAccountStatus | null) => {
  switch (status) {
    case SocialAccountStatus.SIGNED_UP:
      return 'Signed Up';

    case SocialAccountStatus.SCRAPING:
      return 'Not Signed Up';

    default:
      return '';
  }
};
