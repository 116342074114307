import React from 'react';
import { Link } from 'react-router-dom';
import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useDirLayout, useQueryHelper } from '@src/libs/hooks';
import i18n from '@src/libs/i18n';
import { ViewportType } from '@src/libs/theme';
import { ROUTES } from '@src/shared/routes';

const Footer = () => {
  const { t } = useQueryHelper();
  const { dir } = useDirLayout();

  return (
    <div css={styles.container} dir={dir}>
      <div css={styles.copyrightContainer}>
        <span css={styles.copyright}>Copyright © AnyMind Group. All right reserved.</span>
      </div>

      <div css={styles.termsAndConditionContainer}>
        <StyledLink target="_blank" to={ROUTES.PRIVACY}>
          {t('Privacy Policy')}
        </StyledLink>
        <span css={styles.verticalLine} />
        <StyledLink border target="_blank" to={ROUTES.TERMS}>
          {t('Terms of Service')}
        </StyledLink>
        <span css={styles.verticalLine} />
        <Anchor target="_blank" href={`https://any-creator.com/${i18n.language}/contact/`} rel="noopener noreferrer">
          {t('Contact')}
        </Anchor>
      </div>
    </div>
  );
};

const StyledLink = styled(Link, { shouldForwardProp: prop => isPropValid(prop) })<{ border?: boolean }>`
  color: #6e7c89;
  font-size: 12px;
`;
const Anchor = styled.a`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  color: #6e7c89;
  font-size: 12px;
  line-height: 16px;

  &:hover {
    text-decoration: underline;
  }
`;

const styles = {
  container: css`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 16px;

    @media (max-width: ${ViewportType.MEDIUM}px) {
      flex-direction: column;
      gap: 8px;
      flex-wrap: nowrap;
    }
  `,
  copyright: css`
    color: #6e7c89;
    font-size: 12px;
    text-align: center;
  `,
  copyrightContainer: css`
    display: flex;
    flex-basis: 50%;
    align-items: center;
    justify-content: center;
  `,
  termsAndConditionContainer: css`
    display: flex;
    flex-basis: 50%;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;

    @media (max-width: ${ViewportType.MEDIUM}px) {
      flex-basis: 100%;
      justify-content: center;
    }
  `,
  verticalLine: css`
    width: 1px;
    height: 16px;
    background: #dee5ec;
  `,
};

export default Footer;
