import {
  AuthSocialAccountType,
  BioSocialMediaType,
  CampaignSocialMediaType,
  SocialAccountType,
  SocialPostType,
} from '@src/__generated__/globalTypes';
import facebook from '@src/assets/icon/facebook.svg';
import facebookRound from '@src/assets/icon/facebookRound.svg';
import tiktok from '@src/assets/icon/tiktok.svg';
import email from '@src/assets/icon/email.svg';
import facebookWhite from '@src/assets/icon/facebookWhite.svg';
import facebookConnect from '@src/assets/icon/facebookConnect.svg';
import facebookConnectRound from '@src/assets/icon/facebookConnectRound.svg';
import instagram from '@src/assets/icon/instagram.svg';
import instagramLight from '@src/assets/icon/instagramLight.svg';
import instagram_story from '@src/assets/icon/instagram_story.svg';
import instagramWhite from '@src/assets/icon/instagramWhite.svg';
import line from '@src/assets/icon/line_chat.svg';
import twitter from '@src/assets/icon/twitter.svg';
import twitterWhite from '@src/assets/icon/twitterWhite.svg';
import tiktokWhite from '@src/assets/icon/tiktokWhite.svg';
import youtube from '@src/assets/icon/youtube.svg';
import youtubeLight from '@src/assets/icon/youtubeLight.svg';
import youtubeWhite from '@src/assets/icon/youtubeWhite.svg';

export const switchSocialConnectColor = (socialMedia: SocialAccountType): string => {
  switch (socialMedia) {
    case SocialAccountType.FACEBOOK:
    case SocialAccountType.FACEBOOK_PAGE:
      return '#4267b2';
    case SocialAccountType.INSTAGRAM:
      return 'transparent';
    case SocialAccountType.TWITTER:
      return '#38a1f3';
    case SocialAccountType.TIKTOK:
      return '#000';
    default:
      return '#c4302b';
  }
};

export const switchSocialButtonColor = (
  socialMedia?: SocialAccountType | AuthenticationType,
  light?: boolean
): string => {
  switch (socialMedia) {
    case SocialAccountType.FACEBOOK:
      return light ? '#1877f2' : '#4460a0';
    case SocialAccountType.INSTAGRAM:
      return 'transparent';
    case SocialAccountType.TWITTER:
      return '#00aaec';
    case AuthenticationType.EMAIL:
      return '#000';
    case SocialAccountType.YOUTUBE:
      return light ? '#ff0302' : '#ce1312';

    default:
      return '#000';
  }
};

export const switchImage = (
  socialMedia: BioSocialMediaType | CampaignSocialMediaType | SocialAccountType | SocialPostType,
  light?: boolean
): any => {
  switch (socialMedia) {
    case BioSocialMediaType.LINE:
      return line;
    case SocialAccountType.FACEBOOK:
    case SocialAccountType.FACEBOOK_PAGE:
    case SocialPostType.FACEBOOK:
    case SocialPostType.FACEBOOK_PAGE:
      return light ? facebookRound : facebook;
    case SocialAccountType.INSTAGRAM:
    case SocialPostType.INSTAGRAM:
      return light ? instagramLight : instagram;
    case CampaignSocialMediaType.INSTAGRAM_STORY:
    case SocialPostType.INSTAGRAM_STORY:
      return instagram_story;
    case SocialAccountType.TWITTER:
    case SocialPostType.TWITTER:
      return twitter;
    case SocialAccountType.YOUTUBE:
    case SocialPostType.YOUTUBE:
      return light ? youtubeLight : youtube;
    case SocialAccountType.TIKTOK:
    case SocialPostType.TIKTOK:
      return tiktok;
    default:
      return null;
  }
};

export const switchImageWhite = (
  socialMedia: BioSocialMediaType | CampaignSocialMediaType | SocialAccountType
): any => {
  switch (socialMedia) {
    case BioSocialMediaType.FACEBOOK:
    case CampaignSocialMediaType.FACEBOOK:
    case SocialAccountType.FACEBOOK:
      return facebookWhite;
    case BioSocialMediaType.INSTAGRAM:
    case CampaignSocialMediaType.INSTAGRAM:
    case SocialAccountType.INSTAGRAM:
      return instagramWhite;
    case BioSocialMediaType.TIKTOK:
    case CampaignSocialMediaType.TIKTOK:
    case SocialAccountType.TIKTOK:
      return tiktokWhite;
    case BioSocialMediaType.TWITTER:
    case CampaignSocialMediaType.TWITTER:
    case SocialAccountType.TWITTER:
      return twitterWhite;
    case BioSocialMediaType.YOUTUBE:
    case CampaignSocialMediaType.YOUTUBE:
    case SocialAccountType.YOUTUBE:
      return youtubeWhite;

    default:
      return null;
  }
};

export enum AuthenticationType {
  EMAIL = 'EMAIL',
}

export const switchSocialConnectIcon = (
  socialMedia?: SocialAccountType | AuthenticationType,
  round?: boolean
): string | undefined => {
  switch (socialMedia) {
    case SocialAccountType.FACEBOOK:
    case SocialAccountType.FACEBOOK_PAGE:
      return round ? facebookConnectRound : facebookConnect;
    case SocialAccountType.INSTAGRAM:
      return instagramWhite;
    case SocialAccountType.TWITTER:
      return twitterWhite;
    case SocialAccountType.YOUTUBE:
      return youtubeWhite;
    case AuthenticationType.EMAIL:
      return email;
    case SocialAccountType.TIKTOK:
      return tiktokWhite;

    default:
      return undefined;
  }
};

export const switchSocialMediaName = (socialMedia: SocialAccountType | AuthSocialAccountType): string => {
  switch (socialMedia) {
    case SocialAccountType.FACEBOOK:
      return 'facebook';
    case SocialAccountType.INSTAGRAM:
      return 'instagram';
    case SocialAccountType.TWITTER:
      return 'twitter';
    case SocialAccountType.YOUTUBE:
      return 'youtube';
    case SocialAccountType.TIKTOK:
    case AuthSocialAccountType.TTCM:
      return 'tiktok';
    default:
      return '';
  }
};

export const switchMessage = (socialMedia: BioSocialMediaType | CampaignSocialMediaType | null): string => {
  switch (socialMedia) {
    case BioSocialMediaType.FACEBOOK:
    case CampaignSocialMediaType.FACEBOOK:
      return 'Facebook';
    case BioSocialMediaType.INSTAGRAM:
    case CampaignSocialMediaType.INSTAGRAM:
    case CampaignSocialMediaType.INSTAGRAM_STORY:
      return 'Instagram';
    case BioSocialMediaType.TWITTER:
    case CampaignSocialMediaType.TWITTER:
      return 'Twitter';
    case BioSocialMediaType.YOUTUBE:
    case CampaignSocialMediaType.YOUTUBE:
      return 'YouTube';
    case BioSocialMediaType.TIKTOK:
    case CampaignSocialMediaType.TIKTOK:
      return 'TikTok';
    default:
      return '';
  }
};
export const switchText = (
  socialMedia: SocialAccountType | CampaignSocialMediaType | SocialPostType | BioSocialMediaType | null
): string => {
  switch (socialMedia) {
    case CampaignSocialMediaType.FACEBOOK:
    case SocialAccountType.FACEBOOK:
    case SocialAccountType.FACEBOOK_PAGE:
    case SocialPostType.FACEBOOK:
    case SocialPostType.FACEBOOK_PAGE:
      return 'Facebook';
    case SocialAccountType.INSTAGRAM:
    case CampaignSocialMediaType.INSTAGRAM:
    case SocialPostType.INSTAGRAM:
      return 'Instagram';
    case CampaignSocialMediaType.INSTAGRAM_STORY:
    case SocialPostType.INSTAGRAM_STORY:
      return 'Instagram Story';
    case SocialAccountType.TWITTER:
    case CampaignSocialMediaType.TWITTER:
    case SocialPostType.TWITTER:
      return 'Twitter';
    case SocialAccountType.YOUTUBE:
    case CampaignSocialMediaType.YOUTUBE:
    case SocialPostType.YOUTUBE:
      return 'YouTube';
    case SocialAccountType.TIKTOK:
    case CampaignSocialMediaType.TIKTOK:
    case SocialPostType.TIKTOK:
      return 'TikTok';
    case BioSocialMediaType.LINE:
      return 'LINE';
    default:
      return '';
  }
};

export const switchSocialBackgroundColor = (socialMedia: SocialAccountType | string): string => {
  switch (socialMedia) {
    case SocialAccountType.FACEBOOK:
      return '#4460a0';
    case SocialAccountType.INSTAGRAM:
      return 'linear-gradient(94deg, #d82e7c 0%, #ff8404 99%);';
    case SocialAccountType.TWITTER:
      return '#00aaec';
    case SocialAccountType.YOUTUBE:
      return '#ce1312';
    case SocialAccountType.TIKTOK:
      return '#000';
    default:
      return '#4460a0'; // For Facebook pages
  }
};

export const switchFollowersGrowthLineColor = (socialMedia: SocialAccountType | string): string => {
  switch (socialMedia) {
    case SocialAccountType.FACEBOOK:
      return '#4e6baa';
    case SocialAccountType.INSTAGRAM:
      return '#ff2b52';
    case SocialAccountType.TWITTER:
      return '#00aaec';
    case SocialAccountType.YOUTUBE:
      return '#ce1312';
    default:
      return '#4e6baa'; // For Facebook pages
  }
};

export type AccountNameType =
  | 'facebookAccount'
  | 'facebookPages'
  | 'instagramAccounts'
  | 'twitterAccounts'
  | 'youtubeAccounts'
  | 'tiktokAccounts'
  | '__typename';

export const getSocialAccountTypeByAccountName = (accountName: AccountNameType) => {
  switch (accountName) {
    case 'facebookAccount':
      return SocialAccountType.FACEBOOK;
    case 'facebookPages':
      return SocialAccountType.FACEBOOK_PAGE;
    case 'instagramAccounts':
      return SocialAccountType.INSTAGRAM;
    case 'twitterAccounts':
      return SocialAccountType.TWITTER;
    case 'youtubeAccounts':
      return SocialAccountType.YOUTUBE;
    case 'tiktokAccounts':
      return SocialAccountType.TIKTOK;

    default:
      return undefined;
  }
};

export const switchCountUnit = (socialMedia: SocialAccountType): string =>
  socialMedia === SocialAccountType.INSTAGRAM ? 'Followers' : 'Subscribers';
