import { ApolloError } from '@apollo/client';
import React, { useEffect } from 'react';
import { Redirect, useParams, useLocation } from 'react-router';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { useQueryHelper } from '@src/libs/hooks';
import InitialLoading from '@src/components/molecules/InitialLoading';
import { getMessagesFromApolloError, getMessagesFromFetchResult, UNEXPECTED_ERROR } from '@src/libs/error';
import { ConnectType, getCallbackUrl, getConnectState } from '@src/libs/auth';
import { ROUTES } from '@src/shared/routes';
import { getAuthSocialAccountType } from '../utils/utils';
import { useSocialAuthConnectMutations } from './useSocialAuthMutations';

interface Params {
  provider: string;
}

const SignUpConnect = () => {
  const params = useParams<Params>();
  const { search } = useLocation();
  const { t, enqueueSnackbar, history } = useQueryHelper();

  const response = search.substr(1);

  if (!params.provider || !response) {
    return <Redirect to={ROUTES.SIGN_UP} />;
  }

  const { socialAuthConnect, socialAuthSignUpConnect } = useSocialAuthConnectMutations();

  useEffect(() => {
    const handleConnectState = async () => {
      const provider = getAuthSocialAccountType(params.provider);
      if (!provider) {
        enqueueSnackbar(t(UNEXPECTED_ERROR), { variant: 'error' });
        history.push(ROUTES.ROOT);

        return;
      }
      const connectState = await getConnectState();

      // Connect
      const variables = {
        input: {
          provider,
          response,
          callbackUrl: getCallbackUrl(provider, FE_REDIRECT_MAPPING.SIGNUP_CONNECT),
        },
      };

      // When connect SNS is Instagram, we must use a socialAuthConnect mutation not socialAuthSignUpConnect mutation,
      // because the user may already connected FB account.
      if (connectState?.connectType === ConnectType.CONNECT_IG_ACCOUNT) {
        await socialAuthConnect({ variables })
          .then(result => {
            if (result.data?.socialAuthConnect?.ok) {
              return { ok: true, errors: [] };
            } else {
              return { ok: false, errors: getMessagesFromFetchResult(result) };
            }
          })
          .catch((e: ApolloError) => {
            getMessagesFromApolloError(e).forEach(error => {
              console.error(error);
              enqueueSnackbar(t(error), { variant: 'error' });
            });
          });

        // Redirect with state for connecting FB page and an IG account.
        history.push(`${ROUTES.SIGN_UP_CONNECT}?type=ig`);

        return;
      }

      const { ok, errors } = await socialAuthSignUpConnect({ variables })
        .then(result => {
          if (result && result.data && result.data.socialAuthSignUpConnect && result.data.socialAuthSignUpConnect.ok) {
            return { ok: true, errors: [] };
          } else {
            return { ok: false, errors: getMessagesFromFetchResult(result) };
          }
        })
        .catch((e: ApolloError) => ({ ok: false, errors: getMessagesFromApolloError(e) }));

      if (ok) {
        enqueueSnackbar('Success to connect.', { variant: 'success' });
      } else {
        errors.forEach(error => {
          console.error(error);
          enqueueSnackbar(t(error), { variant: 'error' });
        });
      }

      // Redirect with connect type for connecting FB pages and an IG account.
      const query = connectState?.connectType === ConnectType.CONNECT_FB_PAGE ? '?type=fb' : '';
      history.push(`${ROUTES.SIGN_UP_CONNECT}${query}`);
    };

    handleConnectState();
  }, []);

  return <InitialLoading />;
};

export default SignUpConnect;
