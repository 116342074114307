import { useQuery } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import { CustomerTagOrder } from '@src/__generated__/globalTypes';
import GET_CUSTOMER_TAGS from './queries/GetCustomerTags.graphql';
import { GetCustomerTags, GetCustomerTagsVariables } from './queries/__generated__/GetCustomerTags';

const useCustomerTags = (order: CustomerTagOrder = CustomerTagOrder.BY_FREQUENTLY_USED) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  const { data } = useQuery<GetCustomerTags, GetCustomerTagsVariables>(GET_CUSTOMER_TAGS, {
    variables: {
      input: { order },
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  return {
    tags: data?.getCustomerTags?.tags || [],
  };
};

export default useCustomerTags;
