import styled from '@emotion/styled';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import css from '@emotion/css';
import { ThemeButton } from '@src/components/atoms/Button';
import mailOpenIcon from '@src/assets/icon/mailOpenedBlue.svg';
import TextForm from '@src/components/molecules/TextForm';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import { SignUpAndSignInBreakpoint } from '@src/libs/theme';
import { useQueryHelper } from '@src/libs/hooks';
import { ROUTES } from '@src/shared/routes';
import { FormValues, SubmissionStatus } from './types';

interface ResetPasswordFormProps {
  status: string;
}

const ResetPasswordForm = ({ status }: ResetPasswordFormProps) => {
  const { search, t } = useQueryHelper();
  const queryString = new URLSearchParams(search);
  const user = queryString.get('user');
  const {
    formState: { errors, isSubmitting },
    register,
  } = useFormContext<FormValues>();
  const isSuccess = status === SubmissionStatus.OK;

  return (
    <div css={isSuccess ? styles.formSuccessResetPassword : styles.formResetPassword}>
      <Container>
        <Heading>{t('Heading.Reset the password')}</Heading>
        {isSuccess ? (
          <>
            <MailImage src={mailOpenIcon} />

            <InfoText>{t('checkInbox')}</InfoText>

            <ResetPasswordText>{t('resetFollowInstruction')}</ResetPasswordText>
          </>
        ) : (
          <>
            <SubHeading>{t('SubHeading.Please reset the password from the email sent')}</SubHeading>
            <InputWrapper>
              <TextFormStyled
                title="Email"
                placeholder="your@gmail.com"
                isRequired
                error={!!errors.email}
                {...register('email')}
              />
              <ErrorMessage message={errors.email?.message} />
            </InputWrapper>
            <ButtonWrapper>
              <ThemeButton text="Back" href={user === 'staff' ? ROUTES.SIGN_IN_STAFF : ROUTES.ROOT} />
              <ThemeButton text="Submit" theme="blue" loading={isSubmitting} type="submit" />
            </ButtonWrapper>
          </>
        )}
      </Container>
    </div>
  );
};

const Heading = styled.p`
  font-size: 20px;
  line-height: 24px;
  color: #27313b;
  margin-bottom: 16px;
  text-align: center;
`;

const SubHeading = styled.p`
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #27313b;
  margin-bottom: 32px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  gap: 16px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
`;

const InputWrapper = styled.div`
  flex: 1;
  margin-bottom: 24px;
`;

const MailImage = styled.img`
  margin: 42px auto 0;
  width: 36px;
`;

const InfoText = styled.p`
  font-size: 14px;
  color: #27313b;
  line-height: 1.38;
  margin-top: 28px;
  text-align: center;
`;

const ResetPasswordText = styled(InfoText)`
  margin-top: 13px;
`;

const TextFormStyled = styled(TextForm)`
  & label {
    font-size: 14px;
  }

  & input {
    border-radius: 3px;
    height: 32px;

    @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
      height: 40px;
    }
  }
`;

const styles = {
  formResetPassword: css`
    max-width: 348px;
    width: 100%;
  `,
  formSuccessResetPassword: css`
    width: 100%;
  `,
};

export default ResetPasswordForm;
