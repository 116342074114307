import styled from '@emotion/styled';
import { Tooltip } from '@anymindgroup/any-ui-react';
import { useDirLayout } from '@src/libs/hooks';
import * as React from 'react';
import { css } from '@emotion/react';
import Icomoon from '@src/components/atoms/Icomoon';

interface LabelProps {
  className?: string;
  title: string;
  isRequired?: boolean;
  help?: string;
  iconMargin?: string;
}

const Label = (props: LabelProps) => {
  const { className, title, isRequired, help, iconMargin = 'auto' } = props;
  const { dir } = useDirLayout();

  return (
    <Wrapper className={className} dir={dir}>
      {title}
      {isRequired && <RequiredIcon>&nbsp;*</RequiredIcon>}
      {help && (
        <Tooltip content={help} contentProps={{ side: 'left' }} css={{ marginLeft: iconMargin, fontSize: '12px' }}>
          <Icomoon css={styles.icon} icon="help-filled" size={14} />
        </Tooltip>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.label`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 0 2px 4px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #27313b;
`;

const RequiredIcon = styled.span`
  color: tomato;
`;

const styles = {
  icon: css`
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }
  `,
};

export default Label;
