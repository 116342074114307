import React from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/core';
import { useQuery } from '@apollo/client';
import clipboardDarkGray from '@src/assets/icon/clipboardDarkGray.svg';
import Icomoon from '@src/components/atoms/Icomoon';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { localizedDateFormatter } from '@src/components/atoms/List/DateColumn';
import { useTranslation } from 'react-i18next';
import { ListIndicator } from '@src/components/molecules/Indicator';
import Empty from '@src/components/atoms/Empty';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import {
  GetFanParticipationFormList,
  GetFanParticipationFormListVariables,
} from './__generated__/GetFanParticipationFormList';
import * as GET_FAN_PARTICIPATION_FORM_LIST from './GetFanParticipationFormList.graphql';

const FanForms = () => {
  const { i18n } = useTranslation();
  const { t, params, enqueueSnackbar } = useQueryHelper();
  const { isMobileView } = usePageLayout();

  const { id } = params as { id: string };

  const { data, loading } = useQuery<GetFanParticipationFormList, GetFanParticipationFormListVariables>(
    GET_FAN_PARTICIPATION_FORM_LIST,
    {
      variables: {
        input: {
          fanId: id,
        },
      },
      onError: err => {
        enqueueSnackbar(t(err.message), { variant: 'error' });
      },
    }
  );

  if (loading) {
    return <ListIndicator />;
  }

  if (!data?.getFanParticipationFormList?.forms.length) {
    return <Empty />;
  }

  return (
    <div css={[styles.container, isMobileView && styles.padding16]}>
      <h1 css={[styles.heading, isMobileView && styles.marginBottom22]}>{t('Participation form list')}</h1>
      {data.getFanParticipationFormList.forms.map(item => (
        <Link
          css={[styles.row, isMobileView && styles.paddingLeft8]}
          to={`/fans/${id}/forms/${item.submissionId}`}
          key={item.submissionId}
        >
          <img css={styles.icon} src={clipboardDarkGray} />
          <div css={styles.contentWrapper}>
            <p css={styles.title}>{item.formName}</p>
            <p css={styles.subTitle}>
              {localizedDateFormatter(item.submittedAt, 'dd MMM yyyy', i18n.language as AppLanguage)}
            </p>
          </div>
          <Icomoon css={styles.accordion} icon="arrow-down" />
        </Link>
      ))}
    </div>
  );
};

const styles = {
  container: css`
    padding: 24px;
    background-color: #fff;
  `,
  icon: css`
    width: 24px;
    height: 24px;
  `,
  accordion: css`
    transform: rotate(-90deg);
    width: 16px;
    height: 16px;
  `,
  heading: css`
    font-weight: 600;
    font-size: 16px;
    color: #27313b;
    margin-bottom: 24px;
  `,
  contentWrapper: css`
    flex: 1;
    margin-left: 16px;
  `,
  title: css`
    font-weight: 600;
    font-size: 14px;
    color: #27313b;
    margin-bottom: 6px;
    line-height: 100%;
  `,
  subTitle: css`
    font-size: 12px;
    color: #6e7c89;
    line-height: 100%;
  `,
  row: css`
    border: 1px solid #eef3f7;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 16px;

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  `,
  marginBottom22: css`
    margin-bottom: 22px;
  `,
  paddingLeft8: css`
    padding-left: 8px;
  `,
  padding16: css`
    padding: 16px;
  `,
};

export default FanForms;
