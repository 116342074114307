import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { getOptions } from '../../../libs/form';
import { Omit } from '../../../libs/types';
import SingleSelectField, { SingleSelectFieldProps } from '../../molecules/SingleSelectField';
import { AllRegions, AllRegionsVariables } from './__generated__/AllRegions';
import * as ALL_REGIONS from './AllRegions.graphql';

interface RegionSelectorProps extends Omit<SingleSelectFieldProps, 'options'> {
  countryId: string;
}

const RegionSelector = (props: RegionSelectorProps) => {
  const { className, name, value, countryId, disabled, title, hasNativeSelect, ...field } = props;
  const { t } = useTranslation();
  const { data, error } = useQuery<AllRegions, AllRegionsVariables>(ALL_REGIONS, {
    variables: { countryId },
    skip: !countryId,
    fetchPolicy: 'cache-and-network',
  });
  const options = data ? getOptions(data.allRegions).map(item => ({ ...item, label: t(item.label) })) : [];

  return (
    <SingleSelectField
      className={className}
      name={name}
      value={value}
      title={title}
      disabled={disabled || !!error || options.length === 0}
      options={options}
      hasNativeSelect={hasNativeSelect}
      {...field}
    />
  );
};

export default RegionSelector;
