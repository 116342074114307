import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import Icomoon from '@src/components/atoms/Icomoon';
import {
  AnyXEngagementCampaignStatus,
  AnyXMarketplaceCampaignStatus,
  CampaignType,
  TTCMStatus,
} from '@src/__generated__/globalTypes';
import { postProcess } from '@src/libs/i18n';

interface StatusIndicatorProps {
  campaignStatus?: AnyXEngagementCampaignStatus | AnyXMarketplaceCampaignStatus | null;
  campaignType: CampaignType;
  hasPostsCouponMissing?: boolean | null;
  isTtcmCampaign?: boolean;
  ttcmStatus?: TTCMStatus | null;
}

const StatusIndicator = ({
  campaignStatus,
  campaignType,
  hasPostsCouponMissing,
  isTtcmCampaign,
  ttcmStatus,
}: StatusIndicatorProps) => {
  const { t } = useTranslation();
  const isTtcmInvitation =
    isTtcmCampaign && [TTCMStatus.INVITED, TTCMStatus.REJECTED].includes(ttcmStatus as TTCMStatus);

  const statusIndicator = () => {
    if (isTtcmInvitation) {
      return { color: '#40b87c', message: 'Accept TTCM invitation and join', title: 'Invitation' };
    }

    if (hasPostsCouponMissing) {
      return { color: '#ff5f5f', icon: 'flag', message: 'Please add coupon code to post', title: 'Warning' };
    }

    if (campaignType === CampaignType.ENGAGEMENT) {
      switch (campaignStatus) {
        case AnyXEngagementCampaignStatus.DRAFTING:
          return {
            color: '#3892e5',
            icon: 'flag',
            message: isTtcmCampaign ? 'Please make a draft video' : 'Please upload a draft post',
            title: 'Drafting',
          };
        case AnyXEngagementCampaignStatus.EFFECTIVE:
          return {
            color: isTtcmCampaign ? '#40b87c' : '#3892e5',
            icon: isTtcmCampaign ? 'check' : 'flag',
            message: isTtcmCampaign ? 'Collaboration is completed' : 'Please check the report',
            title: isTtcmCampaign ? 'Complete' : 'Effective',
          };
        case AnyXEngagementCampaignStatus.INEFFECTIVE:
          return { color: '#ff5f5f', icon: 'flag', message: 'Your post was not approved', title: 'Ineffective' };
        case AnyXEngagementCampaignStatus.POSTING:
          return {
            color: '#3892e5',
            icon: isTtcmCampaign ? 'film' : 'flag',
            message: isTtcmCampaign ? 'You now can post it' : 'Please make a post',
            title: 'Posting',
          };
        case AnyXEngagementCampaignStatus.REVIEWING:
          return {
            color: isTtcmCampaign ? '#ffb63d' : '#3892e5',
            icon: isTtcmCampaign ? 'clock-anti-clockwise' : 'flag',
            message: isTtcmCampaign ? 'Awaiting review' : 'Please wait for the review',
            title: 'Reviewing',
          };
        default:
          return {
            color: '#ff5f5f',
            icon: isTtcmCampaign ? 'warning-outlined' : 'flag',
            message: isTtcmCampaign ? 'Awaiting review' : 'Please check the comment',
            title: 'Warning',
          };
      }
    } else {
      switch (campaignStatus) {
        case AnyXMarketplaceCampaignStatus.APPROVED:
          return { color: '#3892e5', icon: 'flag', message: 'Your post has been approved', title: 'Approved' };
        case AnyXMarketplaceCampaignStatus.ASSESSING:
          return {
            color: '#f8b74a',
            icon: 'flag',
            message: 'Please wait until the review is complete',
            title: 'Assessing',
          };
        case AnyXMarketplaceCampaignStatus.EFFECTIVE:
          return { color: '#3892e5', icon: 'flag', message: 'Please check the report', title: 'Effective' };
        case AnyXMarketplaceCampaignStatus.INEFFECTIVE:
          return { color: '#ff5f5f', icon: 'flag', message: 'Your post was not approved', title: 'Ineffective' };
        case AnyXMarketplaceCampaignStatus.POSTING:
          return { color: '#3892e5', icon: 'flag', message: 'Please make a post', title: 'Posting' };
        case AnyXMarketplaceCampaignStatus.REVIEWING:
          return {
            color: '#3892e5',
            icon: 'flag',
            message: 'Please wait for your post to be approved',
            title: 'Reviewing',
          };
        case AnyXMarketplaceCampaignStatus.SHIPPED:
          return { color: '#3892e5', icon: 'flag', message: 'Item has been shipped', title: 'Shipped' };
        case AnyXMarketplaceCampaignStatus.WAITING_FOR_SHIPMENT:
          return {
            color: '#ffb63d',
            icon: 'flag',
            message: 'Please wait for the product to be shipped',
            title: 'Waiting',
          };
        case AnyXMarketplaceCampaignStatus.WARNING_EFFECTIVE:
          return { color: '#ff5f5f', icon: 'flag', message: 'Please revise the post', title: 'Warning' };
        default:
          return {
            color: '#ffb63d',
            icon: 'flag',
            message: 'Please wait till information updated',
            title: 'Preparing',
          };
      }
    }
  };

  const { color, icon, message, title } = statusIndicator();

  return (
    <Indicator color={color}>
      <div>
        <div>
          {icon && <Icomoon color="#fff" icon={icon} size={12} />}
          <span>{t(title)}</span>
        </div>
      </div>
      <div>
        <div>{message ? t(`Annotation.${message}`, { postProcess }) : '---'}</div>
      </div>
    </Indicator>
  );
};

const Indicator = styled.div<{ color: string }>`
  display: flex;
  flex-wrap: wrap;
  height: 30px;
  left: 8px;
  position: absolute;
  top: 8px;
  width: 95%;

  & > div:nth-of-type(1) {
    background-color: ${({ color }) => color};
    border-radius: 5px 0 0 5px;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 31%;

    & > div {
      align-items: center;
      color: #fff;
      display: flex;
      flex-wrap: wrap;
      font-size: 12px;
      font-weight: 600;
      gap: 5px;
      height: 100%;
    }
  }

  /* stylelint-disable no-descending-specificity */
  & > div:nth-of-type(2) {
    background-color: #fff;
    border-radius: 0 5px 5px 0;
    height: 100%;
    width: 69%;

    & > div {
      align-items: center;
      color: ${({ color }) => color};
      display: flex;
      font-size: 12px;
      font-weight: 500;
      height: 100%;
      padding: 0 6px;
    }
  }
`;

export default StatusIndicator;
