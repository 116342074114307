import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Omit } from '@src/libs/types';
import Label from '@src/components/atoms/Label';
import { MultiSelector, MultiSelectorProps } from '../Selector';

export interface Option {
  label: string;
  value: string;
}

export interface MultiSelectFieldProps extends Omit<MultiSelectorProps, 'onChange' | 'onClose'> {
  className?: string;
  title?: string;
  isRequired?: boolean;
  help?: string;
  setFieldValue: (field: any, value: string[]) => void; // field: string is not assignable to keyof Values & string  https://github.com/jaredpalmer/formik/issues/1388
}

const MultiSelectField = (props: MultiSelectFieldProps) => {
  const { t } = useTranslation();
  const { className, name, title, isRequired, placeholder, value, options, help, error, hasSelectAll, setFieldValue } =
    props;
  const disabled = props.disabled || options.length === 0;

  const handleSetValues = (items: string[]) => {
    setFieldValue(name, items);
  };

  return (
    <div className={className}>
      {title && <Label title={t(`Selector.${title}`)} isRequired={isRequired} help={help} />}
      <MultiSelector
        options={options}
        name={name}
        disabled={disabled}
        placeholder={disabled ? '' : !!placeholder ? t(`Selector.${placeholder}`) : t('Selector.Please Select')}
        value={value}
        error={error}
        hasSelectAll={hasSelectAll}
        onChange={handleSetValues}
      />
    </div>
  );
};

export default MultiSelectField;
