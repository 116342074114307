import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import bgImg from '@src/assets/img/notificationBackground.png';
import { ThemeButton } from '@src/components/atoms/Button';
import Grid from '@src/components/atoms/Grid';
import Icomoon from '@src/components/atoms/Icomoon';
import { getDateLocal } from '@src/components/atoms/List/DateColumn';
import TextCutter from '@src/components/atoms/TextCutter';
import { ListIndicator } from '@src/components/molecules/Indicator';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import { useQueryHelper } from '@src/libs/hooks';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import { ViewportType } from '@src/libs/theme';
import { notificationIcon, notificationRedirectUrl } from './helper';
import useNotification from './useNotification';

const Notification = () => {
  const { i18n, t } = useQueryHelper();
  const { data, loading, clickNotification, fetchMore, markNotificationsRead } = useNotification();
  const isDesktopView = useMediaQuery({ query: `(min-width: ${ViewportType.TABLET}px)` });

  useEffect(() => {
    markNotificationsRead();
  }, []);

  const notifications = data?.getNotifications?.notifications || [];
  const hasMore = data?.getNotifications?.hasMore || false;
  const checkpoint = data?.getNotifications?.checkpoint || 0;

  const onClickNotification = (id: string) => {
    clickNotification({
      variables: {
        input: { id },
      },
    });
  };

  const onClickViewMore = () => {
    fetchMore({
      variables: {
        input: { checkpoint },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }

        return Object.assign({
          getNotifications: {
            ...fetchMoreResult.getNotifications,
            notifications: [
              ...(prev.getNotifications?.notifications || []),
              ...(fetchMoreResult.getNotifications?.notifications || []),
            ],
          },
        });
      },
    });
  };

  return (
    <div css={styles.container}>
      <div>
        <Grid xs={12}>
          {isDesktopView && (
            <div css={styles.title}>
              <BackNavigator title="Notification" />
            </div>
          )}

          {loading ? (
            <ListIndicator />
          ) : notifications.length > 0 ? (
            <>
              <div css={styles.notificationsContainer}>
                {notifications.map(notification => {
                  const { content, created, id, isClicked, notificationId } = notification;
                  const campaignId = content?.campaign_id || '';
                  const campaignName = content?.campaign_name;
                  const message = t(notificationId.toLowerCase(), {
                    ...(campaignName ? { campaign_name: campaignName } : {}),
                  });

                  return (
                    <NotificationContainer
                      isClicked={isClicked}
                      key={notification.id}
                      to={notificationRedirectUrl(campaignId, notificationId)}
                      onClick={() => (!isClicked ? onClickNotification(id) : null)}
                    >
                      <div>
                        <div css={styles.notificationIcon}>
                          <img alt="campaignIcon" height="30" src={notificationIcon(notificationId)} width="30" />
                        </div>
                      </div>
                      <div>
                        <div css={styles.notificationMsg}>
                          <TextCutter lines={isDesktopView ? 1 : 2} text={message} />
                        </div>
                        <div css={styles.notificationTime}>
                          <Icomoon icon="clock-outlined" size={12} />
                          <span>
                            {formatDistanceToNow(new Date(created), {
                              addSuffix: true,
                              locale: getDateLocal(i18n.language as AppLanguage),
                            })}
                          </span>
                        </div>
                      </div>
                    </NotificationContainer>
                  );
                })}
              </div>

              {hasMore && (
                <div css={styles.actionContainer}>
                  <Grid sm={6} xs={12}>
                    <ThemeButton
                      css={styles.viewMoreBtn}
                      disabled={loading}
                      text="View More"
                      onClick={onClickViewMore}
                    />
                  </Grid>
                </div>
              )}
            </>
          ) : (
            <div css={styles.emptyNotificationContainer}>
              <div>
                <div>
                  <img alt="bgImg" height="137" src={bgImg} width="128" />
                </div>
                <div>{t('Annotation.Empty Notification')}</div>
              </div>
            </div>
          )}
        </Grid>
      </div>
    </div>
  );
};

const NotificationContainer = styled(Link, { shouldForwardProp: prop => isPropValid(prop) })<{ isClicked?: boolean }>`
  align-items: center;
  background-color: ${({ isClicked }) => (isClicked ? '#fff' : '#d8ecff')};
  border: 1px solid #eef3f7;
  border-bottom: ${({ isClicked }) => (isClicked ? '1px solid #eef3f7' : '1px solid #fff')};
  display: flex;
  flex-wrap: wrap;
  height: 55px;
  padding: 0 16px;
  gap: 16px;

  @media (max-width: ${ViewportType.TABLET}px) {
    height: 85px;
  }

  &:hover {
    background-color: ${({ isClicked }) => (isClicked ? '#f6f8fa' : '#edf5fa')};
  }

  & > div:nth-of-type(1) {
    align-items: center;
    display: flex;
  }

  & > div:nth-of-type(2) {
    width: 84%;

    @media (max-width: ${ViewportType.SMALL}px) {
      width: 72%;
    }
  }
`;

const styles = {
  actionContainer: css`
    display: flex;
    justify-content: center;
    margin-top: 16px;
  `,
  container: css`
    padding: 16px;
  `,
  emptyNotificationContainer: css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 70vh;

    /* stylelint-disable no-descending-specificity */
    & > div {
      display: flex;
      flex-wrap: wrap;

      & > div {
        display: flex;
        flex-basis: 100%;
        justify-content: center;
        margin-bottom: 8px;
      }

      & > div:nth-of-type(2) {
        color: #6e7c89;
        font-size: 14px;
        text-align: center;
        width: 110px;
      }
    }
  `,
  notificationIcon: css`
    align-items: center;
    background-color: #fff;
    border-radius: 12px;
    display: flex;
    height: 45px;
    justify-content: center;
    width: 45px;
  `,
  notificationMsg: css`
    color: #27313b;
    display: grid;
    width: 100%;
  `,
  notificationTime: css`
    align-items: center;
    color: #7b8a98;
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    gap: 8px;
  `,
  notificationsContainer: css`
    & > div:not(:first-of-type) {
      border-top: none;
    }

    & > div:nth-of-type(1) {
      border-radius: 10px 10px 0 0;
    }

    & > div:last-child {
      border-radius: 0 0 10px 10px;
    }
  `,
  title: css`
    margin-bottom: 16px;
  `,
  viewMoreBtn: css`
    border-radius: 5px;
    height: 40px;
  `,
};

export default Notification;
