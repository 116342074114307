import { useMutation } from '@apollo/client';
import { SocialAuthSignUpConnect, SocialAuthSignUpConnectVariables } from './__generated__/SocialAuthSignUpConnect';
import * as SOCIAL_AUTH_SIGN_UP_CONNECT from './SocialAuthSignUpConnect.graphql';
import * as SOCIAL_AUTH_CONNECT from './SocialAuthConnectForInstagram.graphql';
import {
  SocialAuthConnectForInstagram,
  SocialAuthConnectForInstagramVariables,
} from './__generated__/SocialAuthConnectForInstagram';

export const useSocialAuthConnectMutations = () => {
  const [socialAuthSignUpConnect] = useMutation<SocialAuthSignUpConnect, SocialAuthSignUpConnectVariables>(
    SOCIAL_AUTH_SIGN_UP_CONNECT
  );
  const [socialAuthConnect] = useMutation<SocialAuthConnectForInstagram, SocialAuthConnectForInstagramVariables>(
    SOCIAL_AUTH_CONNECT
  );

  return { socialAuthSignUpConnect, socialAuthConnect };
};
