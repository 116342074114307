import React, { ReactElement, CSSProperties, PropsWithChildren } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import styled from '@emotion/styled';
import { mainRed } from '@src/libs/pallete';
import Portal from '@src/components/atoms/Portal';
import { closeIcon } from '@src/assets/htmlCodes';
import { useDirLayout } from '@src/libs/hooks';

interface StepsIndicatorProps {
  length: number;
  active: number;
}

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  BackButton?: ReactElement;
  NextButton?: ReactElement;
  isActionsBorderTop?: boolean;
  stepsIndicator?: StepsIndicatorProps | null;
  contentRef?: React.RefObject<HTMLDivElement>;
  closeOnDocumentClick?: boolean;
  className?: string;
  headerNode?: React.ReactNode;
  footerNode?: React.ReactNode;
  contentStyle?: CSSProperties;
  closeIconCss?: CSSProperties;
}
const popupStyles: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  flexShrink: 0,
  width: '90%',
  maxWidth: '480px',
  maxHeight: '80%',
  height: 'max-content',
  padding: '0',
  border: 'none',
  top: '-15px',
  borderRadius: '5px',
};

export const DialogModal = (props: PropsWithChildren<ModalProps>) => {
  const {
    isOpen,
    closeModal,
    children,
    NextButton,
    BackButton,
    contentRef,
    isActionsBorderTop = true,
    stepsIndicator = null,
    closeOnDocumentClick = true,
    className,
    headerNode: HeaderNode,
    footerNode: FooterNode,
    contentStyle,
    closeIconCss,
  } = props;
  const { dir } = useDirLayout();

  return (
    <Portal portalId="portal-root">
      <Popup
        modal
        lockScroll
        closeOnDocumentClick={closeOnDocumentClick}
        open={isOpen}
        onClose={closeModal}
        contentStyle={{ ...popupStyles, ...contentStyle }}
      >
        {(close: () => void) => (
          <>
            <ModalWrapper dir={dir}>
              <Close onClick={close} style={closeIconCss}>
                {closeIcon}
              </Close>
              {HeaderNode}
              <Content ref={contentRef} className={className}>
                {children}
              </Content>
            </ModalWrapper>
            {BackButton || NextButton ? (
              <Actions isActionsBorderTop={isActionsBorderTop}>
                {BackButton}
                {NextButton}
              </Actions>
            ) : (
              FooterNode
            )}
            {!!stepsIndicator && (
              <StepIndicatorWrapper>
                {[...Array(stepsIndicator.length)].map((_, i) => (
                  <StepIndicator key={i} isActive={i === stepsIndicator.active} />
                ))}
              </StepIndicatorWrapper>
            )}
          </>
        )}
      </Popup>
    </Portal>
  );
};

const ModalWrapper = styled.div`
  font-size: 12px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

const Content = styled.div`
  height: 100%;
`;

const Actions = styled.div<{ isActionsBorderTop: boolean }>`
  display: flex;
  justify-content: space-around;

  /* need for mobile Safari */
  align-items: center;
  padding: 15px;
  background-color: #fff;
  border-top: ${({ isActionsBorderTop }) => (isActionsBorderTop ? '1px solid #dee5ec' : 'none')};
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

  & button:first-of-type:not(:last-child) {
    margin-right: 20px;
  }
`;

const StepIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0 20px;
  height: 15px;
`;

const StepIndicator = styled.span<{ isActive: boolean }>`
  width: 8px;
  height: 8px;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.2)};
  background-color: ${mainRed.click};
  border-radius: 50%;
  margin: 0 3px;
`;

const Close = styled.span`
  position: absolute;
  right: -10px;
  top: -10px;
  width: 36px;
  height: 36px;
  background: #fff;
  border-radius: 50%;
  background-color: #2d2d2d;
  cursor: pointer;
  z-index: 100;
  color: #fff;
  font-size: 24px;
  text-align: center;

  &:hover {
    background-color: #6e7c89;
  }

  [dir='rtl'] & {
    right: unset;
    left: -10px;
  }
`;

export const DialogTitle = styled.h1`
  display: flex;
  font-size: 18px;
  font-weight: bold;
  color: #27313b;
  padding-top: 23px;
`;
export const DialogContent = styled.div`
  margin: 16px 0;
  padding-left: 13px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #27313b;
`;
export const WarningIcon = styled.img`
  margin-right: 8px;
`;
