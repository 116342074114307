// Ref: https://github.com/i18next/i18next-browser-languageDetector/blob/master/src/browserLookups/navigator.js
const getLanguageFromNavigator = (): string => {
  if (typeof navigator === 'undefined') {
    return '';
  }

  if (navigator.languages && navigator.languages.length > 0) {
    return navigator.languages[0];
  }

  // For IE
  // @ts-ignore
  // eslint-disable-next-line no-prototype-builtins
  if (navigator.hasOwnProperty('userLanguage') && navigator.userLanguage) {
    // @ts-ignore
    return navigator.userLanguage;
  }

  if (navigator.language) {
    return navigator.language;
  }

  return '';
};

export default getLanguageFromNavigator;
