import { format, parse } from 'date-fns';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ThemeButton } from '@src/components/atoms/Button';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import Label from '@src/components/atoms/Label';
import { RadioIcon } from '@src/components/atoms/RadioButtonElement';
import TimePicker, { timeDefaultFormat } from '@src/components/atoms/TimePicker';
import { DayPickerHandInput } from '@src/components/molecules/DayPicker';
import Dialog from '@src/components/molecules/Dialog';
import RichEditor from '@src/components/molecules/RichEditor';
import TextForm from '@src/components/molecules/TextForm';
import { getDateTimeInPickerFormat } from '@src/libs/date';
import RecipientSelect, { RecipientSelectOption } from '../RecipientSelect';

export const dateFormat = 'dd/MM/yyyy HH:mm';

export interface FormInformation {
  fans: number[];
  id?: number;
  isDraft: boolean;
  message: string;
  scheduleDate: any;
  sender: string;
  sendNow: boolean;
  subject: string;
  tags: number[];
}

interface FormProps {
  onSubmit: (information: FormInformation) => Promise<void>;
}

const Form = ({ onSubmit }: FormProps) => {
  const [date, setDate] = useState<string>('');
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [time, setTime] = useState<string>('');
  const { t } = useTranslation();
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    setValue,
    trigger,
    watch,
  } = useFormContext<FormInformation>();
  const [fans, message, scheduleDate, sendNow, tags] = watch(['fans', 'message', 'scheduleDate', 'sendNow', 'tags']);
  const isScheduleForLater = !sendNow;

  useEffect(() => {
    const selectedDate = parse(scheduleDate, dateFormat, new Date());
    const { date: dateString, time: timeString } = getDateTimeInPickerFormat(selectedDate);

    setDate(dateString);
    setTime(timeString);
  }, [scheduleDate]);

  const onChangeDateTime = (dateString: string, timeString: string) => {
    const parseDate = format(new Date(Date.parse(`${dateString} ${timeString}`)), dateFormat);
    setValue('scheduleDate', parseDate);
  };

  const onClickSubmit = () => {
    if (isScheduleForLater) {
      setValue('isDraft', false);
      handleSubmit(onSubmit)();
    } else {
      trigger().then(isValid => {
        if (isValid) {
          setDialogOpen(true);
        } else {
          handleSubmit(onSubmit)();
        }
      });
    }
  };

  const onClickDialogSendNow = () => {
    setValue('isDraft', false);
    handleSubmit(onSubmit)();
  };

  const onClickSaveDraft = () => {
    setValue('isDraft', true);
    handleSubmit(onSubmit)();
  };

  return (
    <div>
      <Dialog
        execText="Send Now"
        visible={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onExec={onClickDialogSendNow}
      >
        <div css={styles.dialogContent}>
          <div>{t('Title.Email Send Now')}</div>
          <div>{`※ ${t('Annotation.Dialog Send Now 1')}`}</div>
          <div>{`※ ${t('Annotation.Dialog Send Now 2')}`}</div>
        </div>
      </Dialog>

      <div css={styles.container}>
        <div css={styles.infoContainer}>
          <InputContainer>
            <StyledTextForm
              error={!!errors.subject}
              placeholder={t('TextForm.Enter Subject')}
              title="Subject"
              {...register('subject')}
            />
            <ErrorMessage message={errors.subject?.message} />
          </InputContainer>

          <InputContainer>
            <RecipientSelect
              error={!!(errors.fans || errors.tags)}
              fanIds={fans}
              tagIds={tags}
              title="To"
              onChange={value => {
                setValue(
                  'fans',
                  (value as RecipientSelectOption[])?.filter(option => option.isFan).map(option => option.id)
                );
                setValue(
                  'tags',
                  (value as RecipientSelectOption[])?.filter(option => !option.isFan).map(option => option.id)
                );
              }}
            />
            {(errors.fans || errors.tags) && <ErrorMessage message={errors.fans?.message || errors.tags?.message} />}
          </InputContainer>

          <InputContainer>
            <StyledTextForm
              error={!!errors.sender}
              placeholder="sample@anymindgroup.com"
              title="From"
              {...register('sender')}
            />
            <ErrorMessage message={errors.sender?.message} />
          </InputContainer>

          <InputContainer>
            <div css={styles.richEditorContainer}>
              <RichEditor title="Message" value={message} onChange={value => setValue('message', value)} />
            </div>
            <ErrorMessage message={errors.message?.message} />
          </InputContainer>

          <div css={styles.deliveryScheduleContainer}>
            <Label css={styles.label} title={t('Delivery Schedule')} />
            <RadioGroup onClick={() => setValue('sendNow', false)}>
              <RadioIcon isChecked={isScheduleForLater} />
              <span>{t('Schedule for later')}</span>
            </RadioGroup>
            <div css={styles.datetimeContainer}>
              <div css={styles.datePickerContainer}>
                <DayPickerHandInput
                  disabledRange={{ before: new Date() }}
                  handleChangeDay={value => onChangeDateTime(value, time)}
                  value={date}
                />
              </div>
              <TimePicker
                use12Hours
                value={moment(time, timeDefaultFormat)}
                onChange={value => onChangeDateTime(date, value.format(timeDefaultFormat))}
              />
            </div>
            <ErrorMessage message={errors.scheduleDate?.message as string} />
            <RadioGroup onClick={() => setValue('sendNow', true)}>
              <RadioIcon isChecked={!isScheduleForLater} />
              <span>{t('Sent Now')}</span>
            </RadioGroup>
            <Annotation>{`※ ${t('Annotation.Sent Now 1')}`}</Annotation>
            <Annotation>{`※ ${t('Annotation.Sent Now 2')}`}</Annotation>
          </div>
        </div>

        <div css={styles.actionContainer}>
          <ThemeButton
            disabled={isSubmitting}
            text="Save Draft"
            width="max-content"
            fontSize="12px"
            onClick={onClickSaveDraft}
          />
          <ThemeButton
            theme="blue"
            disabled={isSubmitting}
            text={isScheduleForLater ? 'Schedule' : 'Send Now'}
            width="max-content"
            fontSize="12px"
            onClick={onClickSubmit}
          />
        </div>
      </div>
    </div>
  );
};

const Annotation = styled.p`
  color: #27313b;
  font-size: 12px;
  font-weight: 500;
`;

const InputContainer = styled.div`
  margin-bottom: 24px;
  width: 100%;
`;

const RadioGroup = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
  margin-top: 16px;
  width: fit-content;

  & > span {
    color: #27313b;
    font-size: 14px;
    margin-left: 8px;
  }
`;

const StyledTextForm = styled(TextForm)`
  & > label {
    color: #27313b;
    font-size: 14px;
    font-weight: 600;
  }

  & input {
    border-radius: 3px;
    height: 32px;
  }
`;

const styles = {
  actionContainer: css`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding: 16px 24px;

    & > button:nth-of-type(1) {
      margin-right: 8px;
    }
  `,
  container: css`
    background-color: #fff;
  `,
  datePickerContainer: css`
    margin-right: 8px;

    /* stylelint-disable no-descending-specificity */
    & > div > div > div {
      border-color: #dee5ec;
      border-radius: 3px;
      height: 30px;
      width: 150px;

      & > span {
        color: #6e7c89;
        font-size: 24px;
        top: 2px !important;
      }

      & > input {
        height: 32px;
      }
    }
  `,
  datetimeContainer: css`
    display: flex;
    flex-wrap: wrap;
  `,
  deliveryScheduleContainer: css`
    margin-bottom: 8px;
  `,
  dialogContent: css`
    & > div:nth-of-type(1) {
      color: #27313b;
      font-size: 18px;
      margin-bottom: 24px;
    }

    & > div:not(:nth-of-type(1)) {
      color: #ff5f5f;
      font-size: 14px;
      font-weight: 500;
    }
  `,
  label: css`
    color: #27313b;
    font-size: 14px;
    font-weight: 600;
  `,
  infoContainer: css`
    border-bottom: 1px solid #dee5ec;
    padding: 24px;
  `,
  richEditorContainer: css`
    background-color: #fff;

    & > div > label {
      color: #27313b;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 4px;
    }

    & > div > div > div > div > div:nth-of-type(3) {
      max-width: 50vw;
      width: 100%;
    }

    & .se-container {
      border: 1px solid #e4e9ed;
    }

    & .sun-editor-common {
      z-index: 0;
    }

    & .sun-editor-editable {
      height: auto !important;
      min-height: 370px !important;
    }
  `,
};

export default Form;
