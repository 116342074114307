import { useEffect, useRef, useState } from 'react';

export const useClickOutside = <S extends HTMLElement = HTMLDivElement>() => {
  const [clickedOutside, setIsOutside] = useState(false);
  const ref = useRef<S>(null);

  const checkIsClickedOutside = (event: Event) => {
    const isClickedOutside = !!ref.current && !ref.current.contains(event.target as Node);

    return setIsOutside(isClickedOutside);
  };

  useEffect(() => {
    document.addEventListener('click', checkIsClickedOutside, true);

    // Specify how to clean up after this effect:
    return () => {
      document.removeEventListener('click', checkIsClickedOutside, true);
    };
  });

  return { ref, clickedOutside };
};
