import { useMutation, useQuery } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import CREATE_CAMPAIGN_POST from './mutations/CreateCampaignPost.graphql';
import UPDATE_CAMPAIGN_POST from './mutations/UpdateCampaignPost.graphql';
import CAMPAIGN_INFLUENCER_SOCIAL_ACCS from './queries/CampaignInfluencerSocialAccounts.graphql';
import { CreateCampaignPost, CreateCampaignPostVariables } from './mutations/__generated__/CreateCampaignPost';
import { UpdateCampaignPost, UpdateCampaignPostVariables } from './mutations/__generated__/UpdateCampaignPost';
import {
  CampaignInfluencerSocialAccounts,
  CampaignInfluencerSocialAccountsVariables,
  CampaignInfluencerSocialAccounts_engagementPostSocialAccountsForInfluencer,
} from './queries/__generated__/CampaignInfluencerSocialAccounts';

export type { CampaignInfluencerSocialAccounts_engagementPostSocialAccountsForInfluencer };

const usePost = (engagementId: number) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  const { data } = useQuery<CampaignInfluencerSocialAccounts, CampaignInfluencerSocialAccountsVariables>(
    CAMPAIGN_INFLUENCER_SOCIAL_ACCS,
    {
      variables: {
        pk: engagementId,
      },
      onError: error => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
    }
  );

  const [createCampaignPost] = useMutation<CreateCampaignPost, CreateCampaignPostVariables>(CREATE_CAMPAIGN_POST, {
    refetchQueries: ['EngagementCampaignPosts'],
  });

  const [updateCampaignPost] = useMutation<UpdateCampaignPost, UpdateCampaignPostVariables>(UPDATE_CAMPAIGN_POST);

  return {
    socialAccounts: data?.engagementPostSocialAccountsForInfluencer || [],
    createCampaignPost,
    updateCampaignPost,
  };
};

export default usePost;
