import styled from '@emotion/styled';
import * as React from 'react';

interface TermsTitleProps {
  className?: string;
  title: string;
}

const TermsTitle = (props: TermsTitleProps) => {
  const { className, title } = props;

  return <Title className={className}>{title}</Title>;
};

const Title = styled.h2`
  margin-bottom: 40px;
  font-size: 20px;
  font-weight: normal;
  text-align: center;
  color: #27313b;
`;

export default TermsTitle;
