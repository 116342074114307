import React, { MouseEventHandler } from 'react';
import CheckBox from './CheckBox';

export interface CheckBoxForSelectAllProps {
  className?: string;
  name: string;
  label: string;
  disabled?: boolean;
  isCheckedAll: boolean;
  isCheckedNothing: boolean;
  interpolation?: Record<string, string>;
  onChange?: (e?: any) => void;
  handleClick?: MouseEventHandler<HTMLButtonElement> | undefined;
}

const CheckBoxForSelectAll = (props: CheckBoxForSelectAllProps) => {
  const { className, name, disabled, onChange, handleClick, label, isCheckedAll, isCheckedNothing, interpolation } =
    props;

  return (
    <CheckBox
      className={className}
      name={name}
      checked={!isCheckedNothing}
      label={label}
      disabled={disabled}
      onChange={onChange}
      indeterminate={!isCheckedNothing && !isCheckedAll}
      interpolation={interpolation}
      onClick={handleClick}
    />
  );
};

export default CheckBoxForSelectAll;
