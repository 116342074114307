import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ThemeButton } from '@src/components/atoms/Button';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import Grid from '@src/components/atoms/Grid';
import Label from '@src/components/atoms/Label';
import { DayPickerHandInput } from '@src/components/molecules/DayPicker';
import { PostFileField } from '@src/components/molecules/FileField';
import SentenceForm from '@src/components/molecules/SentenceForm';
import SingleSelectField from '@src/components/molecules/SingleSelectField';
import { SOCIAL_MEDIAS } from '@src/libs/constant';
import { switchImage } from '@src/libs/SocialMedia';
import { ViewportType } from '@src/libs/theme';
import {
  CampaignSocialMediaType,
  EngagementCampaignPostStatus,
  SocialAccountType,
} from '@src/__generated__/globalTypes';
import { CampaignInfluencerSocialAccounts_engagementPostSocialAccountsForInfluencer } from './queries/__generated__/CampaignInfluencerSocialAccounts';

export interface PostDetails {
  campaignId: number;
  content: string;
  id?: number;
  materialsUrl: string[];
  planedPostDate: string;
  socialAccountId: number | null;
  socialAccountMedia: SocialAccountType | null;
  socialMedia: CampaignSocialMediaType | null;
}

interface PostFormProps {
  isEdit?: boolean;
  socialAccounts: CampaignInfluencerSocialAccounts_engagementPostSocialAccountsForInfluencer[];
  status: EngagementCampaignPostStatus;
}

const PostForm = ({ isEdit, socialAccounts, status }: PostFormProps) => {
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [selectedSocialMedia, setSelectedSocialMedia] = useState<SocialAccountType | null>(null);
  const { t } = useTranslation();
  const {
    formState: { errors, isSubmitting },
    register,
    setValue,
    watch,
  } = useFormContext<PostDetails>();
  const [materialsUrl, planedPostDate, socialAccountId, socialMedia] = watch([
    'materialsUrl',
    'planedPostDate',
    'socialAccountId',
    'socialMedia',
  ]);
  const disabledFields = ![EngagementCampaignPostStatus.REJECT, EngagementCampaignPostStatus.REVIEWING].includes(
    status
  );

  const igSocialMedias = SOCIAL_MEDIAS.filter(sm =>
    [CampaignSocialMediaType.INSTAGRAM, CampaignSocialMediaType.INSTAGRAM_STORY].includes(sm.value)
  );
  const socialAccountsDropdown =
    socialAccounts.map(socialAccount => ({
      icon: <img alt="icon" css={styles.socialMediaIcon} src={switchImage(socialAccount.socialMedia)} />,
      label: socialAccount.name,
      value: socialAccount.id.toString(),
    })) || [];

  useEffect(() => {
    if (socialAccounts.length > 0 && socialAccountId) {
      const accSocialMedia = socialAccounts.find(socialAcc => socialAcc.id === socialAccountId)?.socialMedia || null;
      setSelectedSocialMedia(accSocialMedia);
      setValue('socialAccountMedia', accSocialMedia);
      const sm =
        accSocialMedia === SocialAccountType.FACEBOOK_PAGE
          ? CampaignSocialMediaType.FACEBOOK
          : accSocialMedia === SocialAccountType.INSTAGRAM
          ? [CampaignSocialMediaType.INSTAGRAM, CampaignSocialMediaType.INSTAGRAM_STORY].includes(
              socialMedia as CampaignSocialMediaType
            )
            ? socialMedia
            : null
          : accSocialMedia;
      setValue('socialMedia', sm as CampaignSocialMediaType);
    }
  }, [socialAccounts, socialAccountId]);

  return (
    <div css={styles.container}>
      <Grid lg={8} md={10} xs={12}>
        <div css={styles.infoContainer}>
          <InputContainer>
            <StyledSingleSelectField
              disabled={disabledFields}
              error={!!errors.socialAccountId}
              isRequired
              name="social account"
              options={socialAccountsDropdown}
              title="Select Social Account"
              value={String(socialAccountId) || ''}
              setFieldValue={(_attr, value) => setValue('socialAccountId', value ? Number(value) : null)}
            />
            <ErrorMessage message={errors.socialAccountId?.message} />
          </InputContainer>

          {selectedSocialMedia === SocialAccountType.INSTAGRAM && (
            <InputContainer>
              <StyledSingleSelectField
                disabled={disabledFields}
                error={!!errors.socialAccountMedia}
                isRequired
                name="post type"
                options={igSocialMedias}
                title="Post Type (Instagram)"
                value={socialMedia || ''}
                setFieldValue={(_attr, value) => setValue('socialMedia', value as CampaignSocialMediaType)}
              />
              <ErrorMessage message={errors.socialAccountMedia?.message} />
            </InputContainer>
          )}

          <InputContainer>
            <Label css={styles.label} title={t('Selector.Plan Date of Post')} isRequired={true} />
            <div css={styles.datepickerContainer}>
              <DayPickerHandInput
                disabled={disabledFields}
                disabledRange={{ before: new Date() }}
                error={!!errors.planedPostDate}
                value={planedPostDate}
                handleChangeDay={value => setValue('planedPostDate', value)}
              />
            </div>
            <ErrorMessage message={errors.planedPostDate?.message} />
          </InputContainer>

          <InputContainer>
            <SentenceForm
              css={styles.sentenceForm}
              disabled={disabledFields}
              error={!!errors.content}
              isRequired={true}
              placeholder="Placeholder"
              title="Caption"
              {...register('content')}
            />
            <ErrorMessage message={errors.content?.message} />
          </InputContainer>

          <InputContainer>
            <PostFileField
              accept="image/gif, image/jpeg, image/png, video/avi, video/mp4, video/quicktime"
              customLabel={<Label title={t('Image/Video')} help={t('Files Help')} />}
              css={styles.fileUploadContainer}
              disabled={disabledFields || isUploading}
              dropAreaCss={styles.fileUploadDropAreaCss}
              initialFileUrls={materialsUrl.map(url => ({ url, preview: url })) || []}
              multiple
              name="materialsUrl"
              notes={[
                t('DragAndDrop.Info'),
                t('DragAndDrop.MaxSize', { maxSize: '10GB' }),
                t('DragAndDrop.FileType', {
                  MIME: 'PNG, GIF, JPEG, AVI, MOV, MP4',
                }),
                t('DragAndDrop.ImageRatio'),
              ]}
              previewCss={styles.fileUploadPreviewCss}
              value={materialsUrl}
              setFieldValue={setValue}
              setIsFileUploading={setIsUploading}
            />
          </InputContainer>

          <div css={styles.formActionSection}>
            <ThemeButton
              theme="blue"
              width="max-content"
              fontSize="12px"
              disabled={isSubmitting}
              type="submit"
              text={isEdit ? 'Upload Post' : 'Upload Draft Post'}
            />
          </div>
        </div>
      </Grid>
    </div>
  );
};

const InputContainer = styled.div`
  margin-bottom: 16px;
`;

const StyledSingleSelectField = styled(SingleSelectField)`
  & > label {
    font-size: 13px;
  }

  & > div > div {
    border-radius: 2px;
    min-height: 32px;

    & > div > div {
      height: inherit;
    }
  }

  & input {
    border-radius: 2px;
    min-height: 32px;
  }
`;

const styles = {
  container: css`
    display: flex;
    justify-content: center;
  `,
  datepickerContainer: css`
    /* stylelint-disable no-descending-specificity */
    & > div > div > div {
      border-radius: 2px;
      height: 32px;

      & > span {
        top: 6px !important;
      }

      & > input {
        height: 32px;
      }
    }
  `,
  fileUploadContainer: css`
    & label {
      font-size: 13px;
    }
  `,
  fileUploadDropAreaCss: css`
    & .drop-area-notes {
      justify-content: center;
    }
  `,
  fileUploadPreviewCss: css`
    position: relative;

    & > div {
      counter-increment: count;
      max-width: 292px;
      width: 45%;

      @media (max-width: ${ViewportType.SMALL}px) {
        width: 100%;
      }

      & > div::after {
        align-items: center;
        background-color: #fff;
        border-radius: 5px;
        color: #3892e5;
        content: 'Image ' counter(count);
        display: flex;
        font-size: 13px;
        font-weight: 600;
        height: 24px;
        left: 16px;
        justify-content: center;
        position: absolute;
        top: 16px;
        width: 62px;
      }
    }
  `,
  formActionSection: css`
    background-color: #fff;
    border-top: 1px solid #eef3f7;
    display: flex;
    justify-content: flex-end;
    padding: 16px;

    & input {
      border-radius: 3px;
      height: 32px;
      line-height: 0;
      text-transform: capitalize;
      width: 160px;

      @media (max-width: ${ViewportType.SMALL}px) {
        width: 100%;
      }
    }

    & > button {
      @media (max-width: ${ViewportType.SMALL}px) {
        height: 40px;
        width: 100%;
      }
    }
  `,
  imagesContainer: css`
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;

    & img {
      background-color: #000;
      height: 219px;
      max-width: 292px;
      object-fit: contain;
      width: 100%;
    }
  `,
  infoContainer: css`
    background-color: #fff;
    padding: 16px;
  `,
  label: css`
    font-size: 13px;
  `,
  sentenceForm: css`
    & > label {
      font-size: 13px;
    }

    & > textarea {
      border-radius: 2px;

      &:focus {
        border-color: #e0e8ed;
      }
    }
  `,
  socialMediaIcon: css`
    height: 18px;
    margin-right: 8px;
    width: 18px;
  `,
};

export default PostForm;
