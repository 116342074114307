import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ThemeButton } from '@src/components/atoms/Button';
import { CheckBox } from '@src/components/atoms/CheckBox';
import Switch from '@src/components/molecules/Switch';
import EditAccess, { defaultWidth } from '@src/components/organisms/SignUpTiktok/EditAccess';
import ManageAccess from '@src/components/organisms/SignUpTiktok/ManageAccess';
import { useDimensions } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/theme';

interface TiktokGrantPermissionProps {
  isConnect?: boolean;
  onClickConnect?: () => void;
  onClickReconnect?: () => void;
}

const TiktokGrantPermission = ({ isConnect, onClickConnect, onClickReconnect }: TiktokGrantPermissionProps) => {
  const [confirmedNotification, setConfirmedNotification] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const { width } = useDimensions(containerRef);

  const imageWidth = width / 2 - 30;
  const imageRequiredWidth = imageWidth > defaultWidth ? defaultWidth : imageWidth;

  const steps = [
    {
      description: <StepDescription>{t('Annotation.Click Edit access')}</StepDescription>,
      image: <EditAccess width={imageRequiredWidth} />,
    },
    {
      description: (
        <StepDescription>
          <div>{t('Annotation.Turn on toggle')}</div>
          <Switch css={styles.switch} customBgColor="#66dca0" isChecked handleChange={() => null} />
        </StepDescription>
      ),
      image: <ManageAccess width={imageRequiredWidth} />,
    },
  ];

  return (
    <div css={{ display: 'flex', justifyContent: 'center' }}>
      <div css={styles.contentContainer}>
        <div css={{ padding: 24 }}>
          <div css={styles.title}>{t('pageTitle.Grant permission')}</div>
          <div css={styles.description}>
            {t(
              'Annotation.In order to fetch your post to the campaign, AnyCreator needs access to your public information'
            )}
          </div>

          <div css={styles.tiktokInfoContainer} ref={containerRef}>
            <div>{t('Tutorial.Text.TiktokGrantPermissionTwo')}</div>
            <div>{t('Annotation.Read your public videos on TikTok')}</div>
            <div>{t('Annotation.TikTok’s access permission page')}</div>
            <div>
              {steps.map((step, index) => {
                const { description, image } = step;

                return (
                  <div css={styles.stepContainer} key={index}>
                    <div>{index + 1}</div>
                    <div>{image}</div>
                    <div>{description}</div>
                  </div>
                );
              })}
            </div>
          </div>

          <div css={{ display: 'flex', justifyContent: 'center' }}>
            <CheckBox
              checked={confirmedNotification}
              label="Label.I have confirmed this notification"
              onCheckedChange={val => setConfirmedNotification(val)}
            />
          </div>
        </div>

        <div css={styles.actionContainer}>
          <div css={styles.buttonContainer}>
            <ThemeButton
              disabled={!confirmedNotification}
              text={isConnect ? 'Connect' : 'Reconnect'}
              theme="blue"
              onClick={isConnect ? onClickConnect : onClickReconnect}
            />
            <div>*{t('Annotation.Enable button with checking a checkbox below')}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const StepDescription = styled.div`
  align-items: center;
  color: #27313b;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  gap: 4px;
`;

const styles = {
  actionContainer: css`
    border-top: 1px solid #dee5ec;
    display: flex;
    justify-content: flex-end;
    padding: 16px;
  `,
  buttonContainer: css`
    display: grid;
    gap: 4px;
    width: 280px;

    @media (max-width: ${ViewportType.TABLET}px) {
      width: 100%;
    }

    & > button {
      @media (max-width: ${ViewportType.TABLET}px) {
        height: 40px;
      }
    }

    & > div {
      color: #6e7c89;
      font-size: 11px;
    }
  `,
  contentContainer: css`
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
    width: 750px;
  `,
  description: css`
    color: #27313b;
    font-size: 14px;
    margin-bottom: 24px;
  `,
  stepContainer: css`
    position: relative;

    & > div:nth-of-type(1) {
      align-items: center;
      background-color: #27313b;
      border-radius: 50%;
      color: #fff;
      display: flex;
      font-size: 12px;
      font-weight: 600;
      height: 24px;
      justify-content: center;
      left: -10px;
      position: absolute;
      top: -10px;
      width: 24px;
      z-index: 1;
    }

    & > div:nth-of-type(2) {
      margin-bottom: 8px;
    }

    & > div:nth-of-type(3) {
      display: flex;
      justify-content: center;
    }
  `,
  switch: css`
    & > button {
      height: 18px;
      width: 42px;

      & > span {
        height: 15px;
        transform: translate(18px) !important;
        width: 15px;
      }

      & > label {
        font-size: 10px;
        top: 3px;

        [dir='rtl'] & {
          right: 22px;
        }
      }

      [dir='rtl'] & {
        direction: ltr;
      }
    }
  `,
  tiktokInfoContainer: css`
    border: 1px solid #dee5ec;
    border-radius: 5px;
    margin-bottom: 32px;
    padding: 24px 16px;
    text-align: center;

    & > div:nth-of-type(1) {
      color: #27313b;
      font-size: 14px;
      margin-bottom: 8px;
    }

    & > div:nth-of-type(2) {
      border-bottom: 1px solid #eef3f7;
      color: #27313b;
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 24px;
      padding-bottom: 16px;
    }

    & > div:nth-of-type(3) {
      color: #27313b;
      font-size: 14px;
      margin-bottom: 24px;
    }

    & > div:nth-of-type(4) {
      display: flex;
      gap: 12px;
      justify-content: center;
    }
  `,
  title: css`
    color: #27313b;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 16px;
  `,
};

export default TiktokGrantPermission;
