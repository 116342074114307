import React from 'react';
import { css } from '@emotion/core';
import SummaryItem, { SummaryInfo } from './SummaryItem';

interface BroadcastListSummaryProps {
  lineBroadcastSummary?: {
    clickRate: SummaryInfo;
    delivered: SummaryInfo;
    openRate: SummaryInfo;
  } | null;
}

const BroadcastListSummary = ({ lineBroadcastSummary }: BroadcastListSummaryProps) => {
  const { clickRate, delivered, openRate } = lineBroadcastSummary || {};

  return (
    <div css={styles.container}>
      <SummaryItem icon="send-orange-light" title="HeaderColumn.Delivered" {...delivered} />
      <SummaryItem icon="chat-check-green-light" isPercentage title="HeaderColumn.Open Rate" {...openRate} />
      <SummaryItem icon="click-red-light" isPercentage title="HeaderColumn.Click Rate" {...clickRate} />
    </div>
  );
};

const styles = {
  container: css`
    background-color: #fff;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    display: flex;
    padding: 16px;

    & > div:last-child {
      border: none;
    }
  `,
};

export default BroadcastListSummary;
