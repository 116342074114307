import axios from 'axios';
import React from 'react';
import SunEditor, { SunEditorReactProps } from 'suneditor-react';
import Lang from 'suneditor-react/types/Lang';
import 'suneditor/src/assets/css/suneditor.css';
import { useApolloClient } from '@apollo/client';
import styled from '@emotion/styled';
import { FORM } from '@src/libs/theme';
import GENERATE_SIGNED_URL from '@src/components/molecules/FileField/GeneratePostFileSignedUrl.graphql';
import {
  GeneratePostFileSignedUrl,
  GeneratePostFileSignedUrlVariables,
} from '@src/components/molecules/FileField/__generated__/GeneratePostFileSignedUrl';
import { useQueryHelper } from '@src/libs/hooks';
import Label from '../../atoms/Label';

interface RichEditorProps extends Omit<SunEditorReactProps, 'setContents'> {
  className?: string;
  title?: string;
  isRequired?: boolean;
  error?: boolean;
  help?: string;
  note?: string;
  value?: string;
}

const RichEditor = (props: RichEditorProps) => {
  const client = useApolloClient();
  const { enqueueSnackbar, i18n, t } = useQueryHelper();
  const { title, isRequired, help, value, error, setOptions } = props;
  const buttonList = [
    ['undo', 'redo'],
    ['bold', 'underline', 'italic', 'strike'],
    ['fontColor', 'hiliteColor'],
    ['removeFormat'],
    ['indent', 'outdent'],
    ['list'],
    ['link'],
    ['image'],
  ];

  return (
    <StyledContent>
      {title && <Label title={t(title)} isRequired={isRequired} help={help} />}
      <EditorContainer error={error}>
        <SunEditor
          lang={i18n.language as Lang}
          setContents={value}
          setOptions={{ buttonList, ...setOptions }}
          onImageUploadBefore={(files, _info, uploadHandler) => {
            const uploadedFile = files[0];
            client
              .query<GeneratePostFileSignedUrl, GeneratePostFileSignedUrlVariables>({
                query: GENERATE_SIGNED_URL,
                variables: { fileName: uploadedFile.name },
              })
              .then(({ data }) => {
                const generatePostFileSignedUrl = data?.generatePostFileSignedUrl;
                if (generatePostFileSignedUrl) {
                  axios(generatePostFileSignedUrl.signedUrl, {
                    method: 'PUT',
                    data: uploadedFile,
                  })
                    .then(() => {
                      const response = {
                        result: [
                          {
                            url: generatePostFileSignedUrl?.signedUrl.split('?')[0] || '',
                            name: uploadedFile.name,
                            size: uploadedFile.size,
                          },
                        ],
                      };

                      uploadHandler(response);
                    })
                    .catch(err => {
                      enqueueSnackbar(t(err.message), { variant: 'error' });
                      uploadHandler(err.message);
                    });
                }
              })
              .catch(err => {
                enqueueSnackbar(t(err.message), { variant: 'error' });
                uploadHandler(err.message);
              });
          }}
          {...props}
        />
      </EditorContainer>
    </StyledContent>
  );
};

// For some reason, all our native css is reseted in our general css.. so need to add back those default styles
const StyledContent = styled.div`
  h1 {
    display: block;
    font-size: 2em;
    margin-top: 0.67em;
    margin-bottom: 0.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  h2 {
    display: block;
    font-size: 1.5em;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  h3 {
    display: block;
    font-size: 1.17em;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  h4 {
    display: block;
    margin-top: 1.33em;
    margin-bottom: 1.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  h5 {
    display: block;
    font-size: 0.83em;
    margin-top: 1.67em;
    margin-bottom: 1.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  h6 {
    display: block;
    font-size: 0.67em;
    margin-top: 2.33em;
    margin-bottom: 2.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  a,
  button,
  i,
  img,
  input,
  label,
  select,
  textarea {
    display: inline-block;
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
  }

  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }

  ul ul,
  ol ul {
    list-style-type: circle;
    list-style-position: inside;
    margin-left: 15px;
  }

  ol ol,
  ul ol {
    list-style-type: lower-latin;
    list-style-position: inside;
    margin-left: 15px;
  }

  li {
    list-style: inherit;
    display: list-item;
  }

  pre {
    background-color: #e0e0e0;
    border-radius: 10px;
    color: rgba(0, 0, 0, 0.9);
    font-family: Consolas, monospace;
    margin: 0;
  }

  blockquote {
    border-left: 5px solid #eee;
    font-family: 'Hoefler Text', 'Georgia', serif;
    color: #666;
    font-style: italic;
  }

  strong {
    font-weight: bold;
  }

  a {
    color: #004cff;
    text-decoration: none;
  }

  a:hover {
    cursor: pointer;
    color: #0093ff;
    text-decoration: underline;
  }

  .sun-editor {
    color: #27313b;
    font-family: 'Inter', sans-serif;
    border: inherit;
  }

  .se-dialog.sun-editor-common {
    z-index: 2;
  }

  .se-wrapper {
    position: unset !important;
    z-index: unset;
  }

  .se-wrapper-inner.se-wrapper-wysiwyg.sun-editor-editable {
    word-break: break-all;
    padding: 16px 16px 0 16px;
  }

  .se-dialog-btn-radio {
    appearance: auto;
  }
`;

const EditorContainer = styled.div<{ error?: boolean }>`
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  border-color: ${props => (props.error ? FORM.ERROR : FORM.BORDER)};
  border-radius: 2px;
  font-size: 13px;
`;

export default RichEditor;
