import { useLazyQuery, useQuery } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import GET_CUSTOMER_TAGS from '@src/pages/Broadcast/Listing/queries/GetCustomerTags.graphql';
import {
  GetCustomerTags,
  GetCustomerTagsVariables,
} from '@src/pages/Broadcast/Listing/queries/__generated__/GetCustomerTags';
import { CustomerTagOrder } from '@src/__generated__/globalTypes';
import GET_ESTIMATE_RECIPIENTS from './queries/GetLineBroadcastEstimateRecipients.graphql';
import {
  GetLineBroadcastEstimateRecipients,
  GetLineBroadcastEstimateRecipientsVariables,
} from './queries/__generated__/GetLineBroadcastEstimateRecipients';

const useFormInput = () => {
  const { enqueueSnackbar, t } = useQueryHelper();

  const { data } = useQuery<GetCustomerTags, GetCustomerTagsVariables>(GET_CUSTOMER_TAGS, {
    variables: {
      input: {
        order: CustomerTagOrder.BY_FREQUENTLY_USED,
      },
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const [getEstimateRecipients] = useLazyQuery<
    GetLineBroadcastEstimateRecipients,
    GetLineBroadcastEstimateRecipientsVariables
  >(GET_ESTIMATE_RECIPIENTS, {
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  return {
    tags: data?.getCustomerTags?.tags || [],
    getEstimateRecipients,
  };
};

export default useFormInput;
