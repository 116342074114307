import { v4 as uuidv4 } from 'uuid';
import React from 'react';
import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { ListIndicator } from '@src/components/molecules/Indicator';
import PrivateForm from '@src/components/organisms/Forms/PrivateForm';
import Form from '@src/components/organisms/Forms/SubmitForm';
import { useQueryHelper } from '@src/libs/hooks';
import GET_FORM_TEMPLATE from './queries/GetFormTemplate.graphql';
import { GetFormTemplate, GetFormTemplateVariables } from './queries/__generated__/GetFormTemplate';

const SubmitForm = () => {
  const { enqueueSnackbar, params, t } = useQueryHelper();
  const hash = params.hash;

  const { data, loading } = useQuery<GetFormTemplate, GetFormTemplateVariables>(GET_FORM_TEMPLATE, {
    variables: {
      input: {
        hash,
      },
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  if (loading) {
    return <ListIndicator />;
  } else if (!data?.getFormTemplate) {
    return <PrivateForm />;
  }

  const description = data?.getFormTemplate?.description || '';
  const id = data?.getFormTemplate?.id || '';
  const questions =
    data?.getFormTemplate?.questions.map(question => {
      const { id: questionId, image, isRequired, options, order, questionType, title: questionTitle } = question;

      return {
        id: questionId,
        genId: uuidv4(),
        image,
        isRequired,
        options,
        order,
        questionType,
        title: questionTitle,
      };
    }) || [];
  const thankDescription = data?.getFormTemplate?.thankDescription || '';
  const thankTitle = data?.getFormTemplate?.thankTitle || '';
  const title = data?.getFormTemplate?.title || '';

  return (
    <div css={styles.container}>
      <Form
        description={description}
        hash={hash}
        id={id}
        questions={questions}
        thankDescription={thankDescription}
        thankTitle={thankTitle}
        title={title}
      />
    </div>
  );
};

const styles = {
  container: css`
    padding-bottom: 40px;
  `,
};

export default SubmitForm;
