import React, { useState, useEffect } from 'react';
import { css } from '@emotion/core';
import { ThemeButton } from '@src/components/atoms/Button';
import Icomoon from '@src/components/atoms/Icomoon';
import { useQueryHelper } from '@src/libs/hooks';
import SearchForm from '@src/components/molecules/SearchForm';
import { ROUTES, generatePath } from '@src/shared/routes';

interface FormIndividualNavigationProps {
  total: number;
}

export interface FormValues {
  page: string;
}

const FormIndividualNavigation = ({ total }: FormIndividualNavigationProps) => {
  const { params, t, history } = useQueryHelper();
  const { id, page = '1' } = params as { id: string; page: string };
  const [pageInput, setPageInput] = useState(page);

  const handleChange = (e: React.ChangeEvent<any>) => {
    setPageInput(e.currentTarget.value);
  };

  const handlePressEnter = () => {
    history.push(generatePath(ROUTES.FORMS_EDIT_REPORT_INDIVIDUAL_PAGE, { id, page: pageInput }));
  };

  useEffect(() => {
    setPageInput(page);
  }, [page]);

  return (
    <div css={styles.navigationWrapper}>
      <ThemeButton
        css={[styles.navButton]}
        href={generatePath(ROUTES.FORMS_EDIT_REPORT_INDIVIDUAL_PAGE, {
          id,
          page: +page - 1,
        })}
        prefixIcon={<Icomoon css={{ transform: 'rotate(90deg)' }} icon="arrow-down" />}
        disabled={Number(page) === 1}
      />

      <SearchForm
        searchable={false}
        css={styles.search}
        placeholder=""
        onChange={handleChange}
        onEnterKeyPress={handlePressEnter}
        value={pageInput}
        width="32px"
        height="32px"
      />

      <span css={styles.count}>
        {t('of')} {total}
      </span>
      <ThemeButton
        css={styles.navButton}
        href={generatePath(ROUTES.FORMS_EDIT_REPORT_INDIVIDUAL_PAGE, {
          id,
          page: +page + 1,
        })}
        prefixIcon={<Icomoon css={{ transform: 'rotate(270deg)' }} icon="arrow-down" />}
        disabled={Number(page) === total}
      />
    </div>
  );
};

const styles = {
  navButton: css`
    & > img {
      width: 14px;
      height: 14px;
    }
  `,
  navigationWrapper: css`
    display: flex;
    align-items: center;
    gap: 8px;
  `,
  count: css`
    color: #6e7c89;
    font-weight: 600;
    font-size: 12px;
    margin: 0 8px;
  `,
  textForm: css`
    flex: 1;

    /* stylelint-disable */
    & input {
      border-radius: 3px;
      height: 32px;
      border: none;
    }
    /* stylelint-enable */
  `,
  search: css`
    .input {
      padding: 0 10px;
    }
  `,
};

export default FormIndividualNavigation;
