import React, { forwardRef, Ref } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import Label from '../../atoms/Label';
import Text, { TextProps } from '../../atoms/InputText/Text';

interface TextFormProps extends TextProps {
  className?: string;
  title?: string;
  isRequired?: boolean;
  help?: string;
  autoFocus?: boolean;
}

const TextForm = forwardRef((props: TextFormProps, ref: Ref<HTMLDivElement>) => {
  const { t } = useTranslation();
  const { className, title, isRequired, help, placeholder, error, autoFocus, ...field } = props;
  const translatedTitle = title ? t(`TextForm.${title}`) : '';

  return (
    <div className={className} ref={ref}>
      {title && <Label css={styles.label} title={translatedTitle} isRequired={isRequired} help={help} />}
      <Text placeholder={placeholder} error={error} {...field} autoFocus={autoFocus} />
    </div>
  );
});

const styles = {
  label: css`
    color: #27313b;
    font-size: 14px;
  `,
};

TextForm.displayName = 'TextForm';

export default TextForm;
