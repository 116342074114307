import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import CampaignPostReports from '@src/components/organisms/CastingMarketplace/YourJob/CampaignPostReports';
import { ListIndicator } from '@src/components/molecules/Indicator';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import { useQueryHelper } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/theme';
import { CampaignType } from '@src/__generated__/globalTypes';
import CAMPAIGN_REPORT from './queries/CampaignReport.graphql';
import {
  EngagementCampaignPostReportForInfluencer,
  EngagementCampaignPostReportForInfluencerVariables,
} from './queries/__generated__/EngagementCampaignPostReportForInfluencer';

const Reports = () => {
  const { enqueueSnackbar, params, t } = useQueryHelper();
  const campaignId = Number(params.id);
  const isDesktopView = useMediaQuery({ query: `(min-width: ${ViewportType.TABLET}px)` });

  const { data, loading } = useQuery<
    EngagementCampaignPostReportForInfluencer,
    EngagementCampaignPostReportForInfluencerVariables
  >(CAMPAIGN_REPORT, {
    variables: {
      campaignId,
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const campaignPostReports = data?.engagementCampaignPostReportForInfluencer;

  return (
    <div css={styles.container}>
      {isDesktopView && <BackNavigator title="Campaign's Report" to={`/casting/your_job/${campaignId}`} />}

      {loading ? (
        <ListIndicator />
      ) : (
        campaignPostReports && (
          <CampaignPostReports campaignPostReports={campaignPostReports} campaignType={CampaignType.ENGAGEMENT} />
        )
      )}
    </div>
  );
};

const styles = {
  container: css`
    margin: 16px;
  `,
};

export default Reports;
