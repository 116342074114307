import styled from '@emotion/styled';
import { JAPANESE_LANG } from '@src/libs/constant';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import PrivacyContentEN from '@src/components/organisms/PrivacyContent/PrivacyContentEN';
import PrivacyContentJP from '@src/components/organisms/PrivacyContent/PrivacyContentJP';
import TopPageFooter from '@src/components/organisms/TopPage/Footer';
import TopPageHeader from '@src/components/organisms/TopPage/Header';
import { usePageLayout, useUpdateDocumentTitle } from '@src/libs/hooks';
import { SignUpAndSignInBreakpoint } from '@src/libs/theme';
import { ROUTES } from '@src/shared/routes';

const Template = () => {
  const { isMobileViewPublicRoutes } = usePageLayout();
  const title = 'pageTitle.Privacy Policy';
  const {
    i18n: { language },
  } = useTranslation();
  useUpdateDocumentTitle({ title });

  return (
    <Wrapper>
      <Container>
        <Header href={ROUTES.ROOT} />
        {language === JAPANESE_LANG ? <PrivacyContentJP /> : <PrivacyContentEN />}
        {!isMobileViewPublicRoutes && <TopPageFooter css={styles.footer} />}
      </Container>
    </Wrapper>
  );
};

const Header = styled(TopPageHeader)`
  padding-top: 16px;

  @media (max-width: ${SignUpAndSignInBreakpoint.MEDIUM}px) {
    background-color: #fff;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 856px;
  align-items: center;
`;

const styles = {
  footer: css`
    width: 100%;
  `,
};

export default Template;
