import styled from '@emotion/styled';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CheckBox } from '@src/components/atoms/CheckBox';
import { DisabledProps } from '../types';

interface MenuItemWithCheckBoxProps extends DisabledProps {
  label: string;
  handleClick: (e: React.MouseEvent<any>) => void;
  isSelected: boolean;
}

const MenuItemWithCheckBox = (props: MenuItemWithCheckBoxProps) => {
  const { label, disabled, handleClick, isSelected } = props;

  return (
    <ItemWrapper disabled={disabled} onClick={handleClick}>
      <CheckBox key={uuidv4()} label={label} checked={isSelected} disabled={disabled} onClick={handleClick} />
    </ItemWrapper>
  );
};

const ItemWrapper = styled.div<DisabledProps>`
  padding: 10px;

  &:hover {
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    background-color: ${props => (props.disabled ? 'transparent' : '#179cd726')};
  }

  .checkbox-root {
    border-radius: 2px;
  }
`;

export default MenuItemWithCheckBox;
