import * as React from 'react';
import { useEffect } from 'react';
import { ApolloProvider } from '@apollo/client';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { IntercomProvider } from 'react-use-intercom';
import { RecoilRoot } from 'recoil';
import { useTranslation } from 'react-i18next';
import { initAmplitude } from './amplitude';
import client from './apollo';
import Notification from './components/organisms/Notification';
import { AdminStoreProvider } from './Context';
import './libs/i18n';
import { Maintenance } from './pages';
import Routes from './Routes';
import { useDirLayout } from './libs/hooks';

// Maintenance Mode
declare const MAINTENANCE: boolean;

// Google Analytics Tracking Code
declare const GA_TRACKING_CODE: string;
// Google Tag Manager ID
declare const GTM_ID: string;

// Intercom ID depends on workspace
declare const INTERCOM_ID: string;

// Amplitude Project ID
declare const AMPLITUDE_ID: string;

// tslint:disable-next-line:ordered-imports
import './assets/css/reset.css';

// Google Tag Manager
const tagManagerArgs = {
  gtmId: GTM_ID,
};
// for axios requests we can set global headers like this, but this cause CORS errors on preflight requests, which is probably our BE issue
// if we need to have it here, please refer this https://github.com/chinesedfan/You-Dont-Know-Axios#nightmares-of-headers-cors-cookies-and-auth
// axios.defaults.headers.common.timezone;

// Initialize Google Analytics(required first in the root component)
const initializeReactGA = (trackingCode: string) => {
  ReactGA.initialize(trackingCode);
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
};

const App = () => {
  const isWithinIframe = window.location !== window.parent.location;
  const { i18n } = useTranslation();
  const { dir } = useDirLayout();
  const root = document.getElementById('container');

  useEffect(() => {
    if (!!GA_TRACKING_CODE && !isWithinIframe) {
      initializeReactGA(GA_TRACKING_CODE);
    }
    if (GTM_ID) {
      TagManager.initialize(tagManagerArgs);
    }
    if (AMPLITUDE_ID) {
      initAmplitude(AMPLITUDE_ID);
    }
  }, []);
  // global RTL setting for App container
  useEffect(() => {
    if (root) {
      root.dir = dir;
    }
  }, [i18n.language]);

  return (
    <ApolloProvider client={client}>
      <AdminStoreProvider>
        <IntercomProvider appId={INTERCOM_ID}>
          <RecoilRoot>
            {MAINTENANCE ? <Maintenance /> : <Routes />}
            <Notification />
            <div id="portal-root" />
          </RecoilRoot>
        </IntercomProvider>
      </AdminStoreProvider>
    </ApolloProvider>
  );
};

export default App;
