import React from 'react';
import ChatCustomerInformation from '@src/components/organisms/ChatCustomerInformation';
import ChatListSection from '@src/components/organisms/ChatListSection';
import ChatView from '@src/components/organisms/ChatView';
import { css } from '@emotion/react';
import { useMediaQuery } from 'react-responsive';
import { ViewportType } from '@src/libs/theme';
import { useQuery } from '@apollo/client';
import { useSetRecoilState, chatMessageTemplatesState } from '@src/recoilAtoms';
import { useQueryHelper, useUpdateDocumentTitle } from '@src/libs/hooks';
import { NoLineConnected } from '@src/components/organisms/ChatView/EmptyChats';
import { ROUTES } from '@src/shared/routes';
import useGetLineChannels from '../Settings/ChannelConnect/useGetLineChannels';
import * as GET_MESSAGE_TEMPALTES from './GetMessageTemplates.graphql';
import { GetMessageTemplates } from './__generated__/GetMessageTemplates';

const Chat = () => {
  const { path } = useQueryHelper();
  const hasMobileLayout = useMediaQuery({ maxWidth: ViewportType.TABLET });
  const setChatTemplates = useSetRecoilState(chatMessageTemplatesState);
  const { setDocumentAndAppTitles } = useUpdateDocumentTitle({
    title: 'Chat',
    skipUpdate: { pageTitle: hasMobileLayout, title: hasMobileLayout },
  });

  const { lineConnectStatus } = useGetLineChannels();

  useQuery<GetMessageTemplates>(GET_MESSAGE_TEMPALTES, {
    onCompleted: ({ getMessageTemplates }) => {
      if (getMessageTemplates?.templates) {
        setChatTemplates(getMessageTemplates.templates);
      }
      if (!hasMobileLayout) {
        setDocumentAndAppTitles({ title: 'Chat' });
      }
    },
  });

  const needsToConnectLine = lineConnectStatus === 'connectLine';

  if (hasMobileLayout) {
    switch (path) {
      case ROUTES.CHAT:
        return (
          <div css={styles.chatsList}>
            <ChatListSection hasMobileLayout needsToConnectLine={needsToConnectLine} />
          </div>
        );
      case ROUTES.CHAT_ACTIVE_ID:
        return (
          <div css={styles.chatWrapperMobile}>
            {/* to make live chatList update continue and PIC & assign|unassign state work */}
            <div css={{ display: 'none' }}>
              <ChatListSection />
            </div>
            {needsToConnectLine ? <NoLineConnected /> : <ChatView hasMobileLayout />}
          </div>
        );

      default:
        return <ChatCustomerInformation />;
    }
  }

  return (
    <div css={styles.chatWrapper}>
      <div css={styles.chatsList}>
        <ChatListSection hasMobileLayout={hasMobileLayout} />
      </div>
      {needsToConnectLine ? <NoLineConnected /> : <ChatView hasMobileLayout={hasMobileLayout} />}
      <ChatCustomerInformation />
    </div>
  );
};

const styles = {
  chatWrapper: css`
    display: grid;
    grid-template-columns: 1.2fr 2fr 2.1fr;
    height: calc(100vh - 10px);
  `,
  chatWrapperMobile: css`
    display: flex;
    height: 100vh;
  `,
  chatsList: css`
    background-color: #fff;
    box-shadow: 0 2px 5px #dee5ec, 0 1px 4px rgba(222, 229, 236, 0.5);
    overflow: hidden;

    @media (max-width: ${ViewportType.TABLET}px) {
      height: calc(100vh - 57px);
    }
  `,
};

export default Chat;
