import { useContext } from 'react';
import { AdminStore, StateType } from '@src/Context';

export default () => {
  const {
    state: { globalLayout },
    dispatch,
  } = useContext(AdminStore);
  // we had an issue related logout https://adasiaholdings.atlassian.net/browse/CATA-543, probably this dispatch somehow got race condition call while unmounted BottomOverflowSection call this hook too
  // we patch it by removing client.resetStore() from onSignout call, since we are not using apollo store
  const updateGlobalLayout = (props: Partial<StateType['globalLayout']>) => {
    dispatch({
      type: 'UPDATE_GLOBAL_LAYOUT',
      payload: {
        globalLayout: (Object.keys(props) as Array<keyof StateType['globalLayout']>).reduce(
          (updatedProps, currentKey) => ({
            ...updatedProps,
            ...(currentKey !== undefined && { [currentKey]: props[currentKey] }),
          }),
          {}
        ),
      },
    });
  };

  return { globalLayout, updateGlobalLayout };
};
