import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import useGetFan from '@src/components/organisms/FanDetails/useGetFan';
import { useDeepCompareEffect } from '@src/libs/hooks';
import { FanDetailsSchema } from '@src/libs/validation';
import { FanGender } from '@src/__generated__/globalTypes';
import FanDetail, { FanInformation } from './FanDetail';

const Index = () => {
  const params = useParams<Record<any, string>>();
  const { fanId } = params;
  const { data, updateFan } = useGetFan({ fanId: Number(fanId) });

  const onSubmit = async (values: FanInformation) => {
    const {
      address1,
      address2,
      birthday,
      city,
      contactNumber,
      countryId,
      email,
      firstName,
      gender,
      lastName,
      postalCode,
      province,
      tags,
    } = values;

    await updateFan({
      variables: {
        input: {
          id: Number(fanId),
          firstName,
          lastName,
          tags,
          address1: address1 || null,
          address2: address2 || null,
          birthday: birthday || null,
          city: city || null,
          contactNumber: contactNumber || null,
          countryId: countryId || null,
          email: email || null,
          gender: gender || FanGender.OTHER,
          postalCode: postalCode || null,
          province: province || null,
        },
      },
    });
  };

  const fanDetails = data?.getFan;
  const defaultValues = {
    address1: fanDetails?.address1 || '',
    address2: fanDetails?.address2 || '',
    birthday: fanDetails?.birthday || '',
    city: fanDetails?.city || '',
    contactNumber: fanDetails?.contactNumber || '',
    countryId: fanDetails?.countryId || '',
    email: fanDetails?.email || '',
    firstName: fanDetails?.firstName || '',
    gender: fanDetails?.gender || FanGender.OTHER,
    lastName: fanDetails?.lastName || '',
    postalCode: fanDetails?.postalCode || '',
    province: fanDetails?.province || '',
    tags: fanDetails?.tags || [],
  };
  const methods = useForm<FanInformation>({
    defaultValues,
    resolver: yupResolver(FanDetailsSchema),
  });

  useDeepCompareEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FanDetail />
      </form>
    </FormProvider>
  );
};

export default Index;
