import { initialPagingParam } from '@src/libs/paging';
import { getParamsFromSearch } from '@src/libs/paging/utils';
import { setUrlParams } from '@src/libs/url';
import { FilterByDateRange } from '@src/__generated__/globalTypes';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { FanListFilterType } from './types';

interface FilterParams {
  kw: string; // Keyword
  cd: string; // Created Date
  lo: string; // Last Order
  mia: string; // Min Amount
  mxa: string; // Max Amount
  exa: string; // Exact Amount
  mio: string; // Min Order
  mxo: string; // Max Order
  exo: string; // Exact Order
  tgs: string; // Tags
}

const getDateIntervalFromUrlParam = (param?: string | null) => {
  switch (param) {
    case 'LAST_7_DAYS':
      return FilterByDateRange.LAST_7_DAYS;
    case 'LAST_30_DAYS':
      return FilterByDateRange.LAST_30_DAYS;
    case 'LAST_90_DAYS':
      return FilterByDateRange.LAST_90_DAYS;
    case 'LAST_12_MONTHS':
      return FilterByDateRange.LAST_12_MONTHS;
    default:
      return null;
  }
};

const getInitialFilter = (search: string) => {
  const params = getParamsFromSearch<FilterParams>(search);

  const keyword = params.kw || '';
  const createdDate = params.cd || '';
  const lastOrderDate = params.lo || '';
  const minAmountSpent = params.mia || '';
  const maxAmountSpent = params.mxa || '';
  const exactAmountSpent = params.exa || '';
  const minNumberOfOrders = params.mio || '';
  const maxNumberOfOrders = params.mxo || '';
  const exactNumberOfOrders = params.exo || '';
  const tags = params.tgs || '';

  const initialFilter: FanListFilterType = {
    keyword,
    createdDate: getDateIntervalFromUrlParam(createdDate),
    lastOrderDate: getDateIntervalFromUrlParam(lastOrderDate),
    minAmountSpent,
    maxAmountSpent,
    exactAmountSpent,
    minNumberOfOrders,
    maxNumberOfOrders,
    exactNumberOfOrders,
    tags,
  };

  return initialFilter;
};

const getFilterParams = (params: FanListFilterType): FilterParams => ({
  kw: params.keyword || '',
  cd: params.createdDate || '',
  lo: params.lastOrderDate || '',
  mia: params.minAmountSpent || '',
  mxa: params.maxAmountSpent || '',
  exa: params.exactAmountSpent || '',
  mio: params.minNumberOfOrders || '',
  mxo: params.maxNumberOfOrders || '',
  exo: params.exactNumberOfOrders || '',
  tgs: params.tags || '',
});

export const useFilter = () => {
  const history = useHistory();
  const { pathname, search } = useLocation();

  const initialFilter = getInitialFilter(search);
  const [filter, baseSetFilter] = useState<FanListFilterType>(initialFilter);

  const setFilter = (newFilter: FanListFilterType) => {
    baseSetFilter(newFilter);

    // Set parameters to Url
    const newUrlParams = {
      ...initialPagingParam,
      ...getFilterParams(newFilter),
    };
    setUrlParams(history, pathname, search, newUrlParams);
  };

  return { filter, setFilter };
};
