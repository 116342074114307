import * as React from 'react';
import { render } from 'react-dom';
import * as Sentry from '@sentry/browser';
import App from './App';
import { config } from './libs/config';
import InitialLoading from './components/molecules/InitialLoading';
import ErrorBoundary from './components/molecules/ErrorBoundary';

// eslint-disable-next-line no-underscore-dangle,no-undef
const __DEV__ = process.env.NODE_ENV === 'development';
if (!__DEV__) {
  Sentry.init(config.sentry);
}

const root = document.getElementById('container');

render(
  <ErrorBoundary>
    <React.Suspense fallback={<InitialLoading />}>
      <App />
    </React.Suspense>
  </ErrorBoundary>,
  root
);
