import { useState } from 'react';
import { execCopy } from '@src/libs/utils';
import useQueryHelper from './useQueryHelper';

export const useCopy = () => {
  const { enqueueSnackbar, t } = useQueryHelper();
  const [isCopying, setCopying] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyHasHashtag = (hashtag?: string | string[]) => {
    if (!hashtag) {
      return;
    }

    const toConvert = Array.isArray(hashtag) ? hashtag : [hashtag || ''];
    const text = toConvert.map(tag => `#${tag}`).join(' ');

    copy(text).then(() => enqueueSnackbar(t('CopiedHashtags'), { variant: 'success' }));
  };

  const handleCopyHasText = (text: string, successMethod?: () => void) => {
    if (!text) {
      return;
    }

    copy(text).then(() => {
      if (successMethod) {
        successMethod();
      } else {
        enqueueSnackbar(t('Copied'), { variant: 'success' });
      }
    });
  };

  const copy = (text: string) => {
    setCopying(true);
    execCopy(text);
    setIsCopied(true);

    return new Promise(resolve => {
      setTimeout(() => {
        setCopying(false);
        resolve(null);
      }, 200);
    });
  };

  return {
    isCopied,
    isCopying,
    handleCopyHasHashtag,
    handleCopyHasText,
  };
};
