import { format } from 'date-fns';
import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Grid from '@src/components/atoms/Grid';
import { ListIndicator } from '@src/components/molecules/Indicator';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import PaymentStatusColumn from '@src/components/organisms/Payment/PaymentStatusColumn';
import { LIMIT } from '@src/libs/constant';
import { ViewportType } from '@src/libs/theme';
import { formatNumberWithCommas } from '@src/libs/format';
import { useAuthData, usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { ROUTES } from '@src/shared/routes';
import PAYMENT_HISTORY from './queries/PaymentHistory.graphql';
import { PaymentHistory, PaymentHistoryVariables } from './queries/__generated__/PaymentHistory';

const History = () => {
  const { userId } = useAuthData();
  const { enqueueSnackbar, t } = useQueryHelper();
  const { isMobileView } = usePageLayout();

  const { data, loading } = useQuery<PaymentHistory, PaymentHistoryVariables>(PAYMENT_HISTORY, {
    variables: {
      limit: LIMIT,
      offset: 0,
      pk: userId as number,
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const currency = data?.influencerPaymentRequests.currency || '';
  const totalNetRevenue = data?.influencerPaymentRequests.totalNetRevenue || 0;
  const paymentRequest = data?.influencerPaymentRequests.paymentRequests || [];
  const isJPInfluencer = data?.influencerProfileV2?.country.id === 'JP';
  const isPaymentRequest = paymentRequest.length > 0;

  return (
    <div css={styles.container}>
      {!isMobileView && <BackNavigator title="Payment History" to={ROUTES.PAYMENT} />}

      <div css={styles.infoContainer}>
        <Grid lg={8} sm={10} xs={12}>
          <>
            <div css={styles.totalRevenueContainer}>
              <div>
                <div>{t('Title.Total Revenue')}</div>
                <div>
                  <span>{formatNumberWithCommas(totalNetRevenue, 0)}&nbsp;</span>
                  <span>{currency}</span>
                </div>
              </div>
            </div>

            {loading ? (
              <ListIndicator />
            ) : isPaymentRequest ? (
              paymentRequest.map(payment => {
                const {
                  campaigns,
                  consumptionTax,
                  dueDate,
                  id: paymentId,
                  fee,
                  grossRevenue,
                  netRevenue,
                  paidDate,
                  requestedDate,
                  status,
                  withholdingFee,
                } = payment;

                return (
                  <div css={styles.paymentInfoContainer} key={paymentId}>
                    <div css={styles.paymentInfoDate}>
                      <div>
                        <span>{t('HeaderColumn.Requested Date')}</span>
                        <span>{requestedDate ? format(new Date(requestedDate), 'MMM dd, yyyy') : '-'}</span>
                      </div>
                      <div>
                        <span>{t('HeaderColumn.Due Date')}</span>
                        <span>{dueDate ? format(new Date(dueDate), 'MMM dd, yyyy') : '-'}</span>
                      </div>
                      <div>
                        <span>{t('HeaderColumn.Paid Date')}</span>
                        <span>{paidDate ? format(new Date(paidDate), 'MMM dd, yyyy') : '-'}</span>
                      </div>
                    </div>

                    <div>
                      <div css={styles.paymentInfoCampaign}>
                        {campaigns?.map(campaign => {
                          const { id: campaignId, revenue, title } = campaign;

                          return (
                            <div key={campaignId}>
                              <div>
                                <Link to={`/marketplace/your_job/${campaignId}`}>{title}</Link>
                              </div>
                              <div>
                                <span>{formatNumberWithCommas(revenue, 0)}&nbsp;</span>
                                <span>{currency}</span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <ChargeInfo>
                        <div>
                          <div>{t('Column.SubTotal')}</div>
                        </div>
                        <div>
                          <span>{formatNumberWithCommas(grossRevenue, 0)}&nbsp;</span>
                          <span>{currency}</span>
                        </div>
                      </ChargeInfo>
                      {isJPInfluencer && (
                        <ChargeInfo>
                          <div>
                            <div>{t('Column.Consumption Tax')}</div>
                          </div>
                          <div>
                            <span>{formatNumberWithCommas(consumptionTax, 0)}&nbsp;</span>
                            <span>{currency}</span>
                          </div>
                        </ChargeInfo>
                      )}
                      <ChargeInfo>
                        <div>
                          <div>{t('Column.Withholding fee')}</div>
                        </div>
                        <div>
                          <span>{`-${formatNumberWithCommas(withholdingFee, 0)}`}&nbsp;</span>
                          <span>{currency}</span>
                        </div>
                      </ChargeInfo>
                      <ChargeInfo>
                        <div>
                          <div>{t('Column.Transfer fee')}</div>
                        </div>
                        <div>
                          <span>{`-${formatNumberWithCommas(fee, 0)}`}&nbsp;</span>
                          <span>{currency}</span>
                        </div>
                      </ChargeInfo>
                      <ChargeInfo>
                        <div>
                          <div>{t('Column.Revenue')}</div>
                        </div>
                        <div>
                          <span>{formatNumberWithCommas(netRevenue, 0)}&nbsp;</span>
                          <span>{currency}</span>
                        </div>
                      </ChargeInfo>
                      <div css={styles.paymentInfoStatus}>
                        <div>{t('Payment Status')}</div>
                        <table>
                          <tbody>
                            <tr>
                              <PaymentStatusColumn status={status} />
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div css={styles.emptyPaymentRequest}>{t('No payment history yet')}</div>
            )}
          </>
        </Grid>
      </div>
    </div>
  );
};

const ChargeInfo = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > div:nth-of-type(1) {
    border-bottom: 1px solid #dee5ec;
    border-right: 1px solid #dee5ec;
    display: flex;
    flex-basis: 50%;
    justify-content: flex-end;

    & > div {
      color: #27313b;
      font-size: 13px;
      padding: 8px;
    }
  }

  /* stylelint-disable no-descending-specificity */
  & > div:nth-of-type(2) {
    border-bottom: 1px solid #dee5ec;
    display: flex;
    flex: 1;
    justify-content: flex-end;
    padding: 8px 0;

    & > span:nth-of-type(1) {
      color: #27313b;
      font-size: 13px;
      font-weight: 600;
    }

    & > span:nth-of-type(2) {
      color: #6e7c89;
      font-size: 11px;
    }
  }
`;

const styles = {
  container: css`
    margin: 32px 80px;

    @media (max-width: ${ViewportType.TABLET}px) {
      margin: 16px 16px 32px 16px;
    }
  `,
  emptyPaymentRequest: css`
    color: #6e7c89;
    display: flex;
    font-size: 14px;
    justify-content: center;
  `,
  infoContainer: css`
    display: flex;
    justify-content: center;
  `,
  paymentInfoContainer: css`
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
    margin-bottom: 16px;

    & > div:nth-of-type(2) {
      padding: 16px;
    }
  `,
  paymentInfoCampaign: css`
    border-bottom: 1px solid #dee5ec;

    & > div {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 12px;

      & > div:nth-of-type(1) {
        display: flex;
        flex-basis: 50%;

        & > a {
          color: #3892e5;
          font-size: 13px;
        }
      }

      & > div:nth-of-type(2) {
        align-items: center;
        display: flex;
        flex-basis: 50%;
        justify-content: flex-end;

        & > span:nth-of-type(1) {
          color: #27313b;
          font-size: 13px;
          font-weight: 600;
        }

        & > span:nth-of-type(2) {
          color: #6e7c89;
          font-size: 11px;
        }
      }
    }

    & > div:nth-last-of-type(1) {
      margin-bottom: 16px;
    }
  `,
  paymentInfoDate: css`
    border-bottom: 1px solid #dee5ec;
    display: flex;
    flex-wrap: wrap;

    & > div:not(:last-child) {
      border-right: 1px solid #dee5ec;
    }

    & > div {
      display: grid;
      flex-basis: 33%;
      justify-content: center;
      padding: 16px 0;
      text-align: center;

      & > span:nth-of-type(1) {
        color: #27313b;
        font-size: 11px;
      }

      & > span:nth-of-type(2) {
        color: #27313b;
        font-size: 13px;
        font-weight: 600;
      }
    }
  `,
  paymentInfoStatus: css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;

    & > div {
      color: #27313b;
      display: flex;
      flex-basis: 50%;
      font-size: 13px;
    }

    & > table {
      display: flex;
      flex-basis: 50%;
      justify-content: flex-end;

      & p {
        border-radius: 35px;
        font-size: 12px;
        font-weight: 600;
      }
    }
  `,
  totalRevenueContainer: css`
    align-items: center;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
    display: flex;
    height: 86px;
    justify-content: center;
    margin: 16px 0;
    text-align: center;
    width: 100%;

    & > div {
      & > div:nth-of-type(1) {
        color: #27313b;
        font-size: 12px;
      }

      & > div:nth-of-type(2) {
        & > span:nth-of-type(1) {
          color: #27313b;
          font-size: 18px;
          font-weight: 600;
        }

        & > span:nth-of-type(2) {
          color: #27313b;
          font-size: 12px;
          font-weight: 600;
        }
      }
    }
  `,
};

export default History;
