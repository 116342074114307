import { SerializedStyles } from '@emotion/serialize';
import styled from '@emotion/styled';
import * as React from 'react';

interface StatusColumnProps extends StatusProps {
  className?: string;
  isTableElement?: boolean;
}

const StatusColumn: React.FC<StatusColumnProps> = props => {
  const { className, statusColor, children, isTableElement = true } = props;
  const StatusWrapper = !!isTableElement ? TableWrapper : Wrapper;

  return (
    <StatusWrapper className={className}>
      <TableData statusColor={statusColor}>{children}</TableData>
    </StatusWrapper>
  );
};

interface StatusProps {
  statusColor?: SerializedStyles;
}

const Wrapper = styled.div`
  height: 24px;
  white-space: nowrap;
`;

const TableWrapper = Wrapper.withComponent('td');

const TableData = styled.p<StatusProps>`
  border-radius: 3px;
  padding: 5px 14px;
  text-align: center;
  font-size: 12px;
  line-height: 1.17;
  letter-spacing: normal;
  ${props => props.statusColor}
`;

export default StatusColumn;
