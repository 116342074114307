import React from 'react';
import Picker, { SKIN_TONE_LIGHT } from 'emoji-picker-react';
import { css } from '@emotion/react';
import { Popover } from '@src/components/molecules/Popover';
import Icomoon from '../Icomoon';

interface EmojiPickerProps {
  onEmojiClick: (emoji: string) => void;
}

const EmojiPicker = ({ onEmojiClick }: EmojiPickerProps) => {
  const onClickEmoji = (emoji: string) => {
    onEmojiClick(emoji);
    onCloseMenu();
  };

  let onCloseMenu: () => void;

  return (
    <Popover
      renderTrigger={() => (
        <div css={styles.iconContainer}>
          <Icomoon icon="smile-grey-light" size={24} />
        </div>
      )}
      renderButtonBlock={({ closePopup }) => {
        onCloseMenu = closePopup;

        return null;
      }}
      side="bottom"
      align="start"
    >
      <Picker
        disableAutoFocus={true}
        groupNames={{ smileys_people: 'PEOPLE' }}
        skinTone={SKIN_TONE_LIGHT}
        onEmojiClick={(_e, emojiObj) => onClickEmoji(emojiObj.emoji)}
      />
    </Popover>
  );
};

const styles = {
  iconContainer: css`
    align-items: center;
    border: 1px solid #dee5ec;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    height: 32px;
    justify-content: center;
    width: 32px;
  `,
};

export default EmojiPicker;
