import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Icomoon from '@src/components/atoms/Icomoon';
import { Icon } from '@src/components/atoms/Icon';

interface Breadcrumbs {
  title: string;
  onClick?: () => void;
}

interface BackNavigatorProps {
  breadcrumbs?: Breadcrumbs[];
  className?: string;
  title?: string;
  to?: string;
  interpolation?: Record<string, string>;
  skipTranslation?: boolean;
  onClick?: (e: SyntheticEvent<HTMLAnchorElement>) => void;
}

const BackNavigator = (props: BackNavigatorProps) => {
  const { t } = useTranslation();
  const { breadcrumbs, title, to, interpolation, skipTranslation, className, onClick } = props;

  return (
    <div className={className} css={styles.container}>
      {to && (
        <Link to={to} onClick={onClick}>
          <Icon>
            <Icomoon css={{ transform: 'rotate(90deg)' }} icon="arrow-down" />
          </Icon>
        </Link>
      )}
      {!!breadcrumbs?.length ? (
        <div css={{ display: 'flex' }}>
          {breadcrumbs.map((breadcrumb, index) => {
            const { title: breadcrumbTitle, onClick: breadcrumbOnClick } = breadcrumb;
            const isActiveTitle = index === breadcrumbs.length - 1;

            return (
              <Breadcrumb
                isActiveTitle={isActiveTitle}
                isClickable={!!breadcrumbOnClick}
                key={index}
                onClick={breadcrumbOnClick}
              >
                <div>{t(`pageTitle.${breadcrumbTitle}`)}</div>
                {!isActiveTitle && (
                  <Icon css={styles.nextIcon}>
                    <Icomoon css={{ transform: 'rotate(270deg)' }} icon="arrow-down" />
                  </Icon>
                )}
              </Breadcrumb>
            );
          })}
        </div>
      ) : title ? (
        <div css={{ fontSize: 18, fontWeight: 600 }}>
          {skipTranslation ? title : t(`pageTitle.${title}`, { ...interpolation })}
        </div>
      ) : null}
    </div>
  );
};

const Breadcrumb = styled.div<{ isActiveTitle: boolean; isClickable: boolean }>`
  display: flex;
  color: ${({ isActiveTitle }) => (isActiveTitle ? '#27313b' : '#6e7C89')};
  font-size: 18px;
  font-weight: 600;
  justify-content: center;

  & > div:hover {
    color: #27313b;
    cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
    text-decoration: ${({ isClickable }) => (isClickable ? 'underline' : 'none')};
  }
`;

const styles = {
  container: css`
    align-items: center;
    display: flex;
    gap: 16px;

    & > a {
      align-items: center;
      background-color: #fff;
      box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
      display: flex;
      height: 32px;
      justify-content: center;
      width: 32px;
    }
  `,
  nextIcon: css`
    cursor: default;
    margin: auto 8px;
  `,
};

export default BackNavigator;
