import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, themeVars } from '@anymindgroup/any-ui-react';
import type { ButtonProps } from '@anymindgroup/any-ui-react';
import { css } from '@emotion/react';
import { PaletteColorType } from '@src/libs/pallete';

/**
 * @description
 * if `href` -> react-router Link
 * if `href + targetBlank` -> regular a tag
 * if `onClick` -> regular button tag
 */
interface ButtonWrapperProps extends ButtonProps {
  skipTranslation?: boolean;
  customPalette?: PaletteColorType;
}

const ThemeButton = forwardRef(
  (
    { text, width = '100%', height = '32px', skipTranslation, customPalette, ...restProps }: ButtonWrapperProps,
    ref
  ) => {
    const { t } = useTranslation();

    return (
      <Button
        {...restProps}
        text={skipTranslation ? text : text ? t(`Button.${text}`) : undefined}
        width={width}
        height={height}
        reactRouterLink={Link as Link<any>}
        css={[
          customPalette
            ? css`
                --background: ${customPalette.normal};
                --border: ${customPalette.borderColor ? `1px solid ${customPalette.borderColor}` : 'none'};
                --color: ${customPalette.textColor};

                border: var(--border);
                background: var(--background);
                color: var(--color);

                &:hover {
                  border: var(--border);
                  background: var(--background);
                  color: var(--color);
                  opacity: 0.9;
                }

                &:active {
                  background: ${customPalette.click};
                  border: var(--border);
                  color: var(--color);
                }

                &:focus {
                  outline: 2px solid ${themeVars.outline};
                  border: var(--border);
                  background: var(--background);
                  color: var(--color);
                }
              `
            : '',
          { position: 'relative' },
        ]}
        ref={ref}
      />
    );
  }
);
ThemeButton.displayName = 'ThemeButton';

export type { ButtonProps };
export default ThemeButton;
