import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ThemeButton } from '@src/components/atoms/Button';
import Icomoon from '@src/components/atoms/Icomoon';
import { PostFileField } from '@src/components/molecules/FileField';
import SingleSelectField, { SingleSelectFieldProps } from '@src/components/molecules/SingleSelectField';
import { FontSize } from '@src/__generated__/globalTypes';
import { fontSizeMapping } from './helpers';

interface DragAndDropProps {
  allowVideo?: boolean;
  disabled?: boolean;
  error?: boolean;
  imageUrl: string;
  onChange: (imageUrl: string) => void;
}

export const DragAndDrop = ({ allowVideo, disabled, error, imageUrl, onChange }: DragAndDropProps) => {
  const { t } = useTranslation();

  return (
    <div css={{ pointerEvents: disabled ? 'none' : 'auto' }}>
      {imageUrl ? (
        <div css={styles.uploadedImageContainer}>
          <div>
            <div onClick={() => onChange('')}>
              <Icomoon color="#fff" icon="clear" />
            </div>
            <img src={imageUrl} />
          </div>
        </div>
      ) : (
        <PostFileField
          accept={`image/gif, image/jpeg, image/png ${allowVideo ? ', video/avi, video/mp4, video/quicktime' : ''}`}
          customContent={
            <div css={styles.dragAndDropContent}>
              <div>
                <ThemeButton text="Upload File" fontSize="12px" width="max-content" css={{ marginBottom: '16px' }} />
              </div>
              <div>{t('Annotation.drop the file and upload')}</div>
              <div>{t('DragAndDrop.MaxSize', { maxSize: '10GB' })}</div>
              <div>
                {t('DragAndDrop.FileType', {
                  MIME: `PNG, JPEG, GIF ${allowVideo ? ', AVI, MOV, MP4' : ''}`,
                })}
              </div>
              <div>{t('DragAndDrop.MaxImageSize1024')}</div>
            </div>
          }
          dropAreaCss={{ ...styles.dragAndDropContainer, ...(error ? styles.errorDragAndDropContainer : {}) }}
          hidePreview
          initialFileUrls={[]}
          multiple={false}
          name=""
          value={[]}
          setFieldValue={(_name, val) => onChange(val[0])}
        />
      )}
    </div>
  );
};

interface FontSizePickerProps {
  disabled?: boolean;
  isBold?: boolean;
  value?: FontSize;
  onChange: ({ isBold, value }: { isBold: boolean; value: FontSize }) => void;
}

export const FontSizePicker = ({ disabled, isBold, value = FontSize.SM, onChange }: FontSizePickerProps) => {
  const fontSizeOptions = Object.keys(fontSizeMapping).map(key => ({
    label: fontSizeMapping[key as FontSize],
    value: key,
  }));

  return (
    <FontSizePickerContainer disabled={disabled} isBold={isBold}>
      <div onClick={() => onChange({ isBold: !isBold, value })}>
        <Icomoon icon="bold" />
      </div>
      <SingleSelectField
        disabled={disabled}
        disableClearValue
        name=""
        options={fontSizeOptions}
        value={value}
        setFieldValue={(_name, val) => onChange({ isBold: !!isBold, value: val as FontSize })}
      />
    </FontSizePickerContainer>
  );
};

const FontSizePickerContainer = styled.div<{ disabled?: boolean; isBold?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  height: 32px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  & > div:nth-of-type(1) {
    align-items: center;
    background-color: ${({ isBold }) => (isBold ? '#f6f8fa' : '#fff')};
    border: 1px solid #dee5ec;
    border-radius: 3px 0 0 3px;
    border-right: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 32px;
  }

  & > div:nth-of-type(2) {
    & > label {
      font-size: 13px;
    }

    & > div > div {
      border-radius: 0 3px 3px 0;
      min-height: 30px;
      width: 56px;

      & > div > div {
        height: inherit;
      }
    }

    & input {
      border-radius: 2px;
      margin: 0;
      min-height: 30px;
      padding: 0 8px;
    }
  }
`;

export const labelFormat = 'HH:mm';
export const valueFormat = 'hh:mm A';

interface TimePickerProps extends Omit<SingleSelectFieldProps, 'options'> {
  labelFormat?: string;
  valueFormat?: string;
}

export const TimePicker = ({ labelFormat: lf, valueFormat: vf, ...selectProps }: TimePickerProps) => {
  const timestamps = Array.from({ length: 48 }, (_, index) => {
    const hour = Math.floor(index / 2);
    const minutes = index % 2 === 0 ? 0 : 30;

    return {
      label: moment({ hour, minutes }).format(lf || labelFormat),
      value: moment({ hour, minutes }).format(vf || valueFormat),
    };
  });

  return <SingleSelectField css={styles.timePickerSelector} {...selectProps} options={timestamps} />;
};

const styles = {
  dragAndDropContainer: css`
    /* stylelint-disable no-descending-specificity */
    & > div {
      background-color: #f6f8fa;
      border-radius: 3px;
      padding: 24px;
    }
  `,
  dragAndDropContent: css`
    & > div {
      color: #6e7c89;
      display: flex;
      font-size: 11px;
      justify-content: center;

      & > button {
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 16px;
      }
    }

    & > div:nth-of-type(2) {
      color: #6e7c89;
      font-size: 14px;
      margin-bottom: 12px;
    }
  `,
  errorDragAndDropContainer: css`
    & > div {
      border-color: #ff6247;

      &:hover {
        border-color: #ff6247;
      }
    }
  `,
  timePickerSelector: css`
    & > label {
      font-size: 13px;
    }

    & > div > div {
      border-radius: 2px;
      min-height: 30px;

      & > div > div {
        height: inherit;
      }
    }

    & input {
      border-radius: 2px;
      min-height: 30px;
    }
  `,
  uploadedImageContainer: css`
    background-color: #f6f8fa;
    border: 2px dashed #dee5ec;
    border-radius: 3px;
    padding: 16px;

    & > div {
      position: relative;
      width: 108px;

      & > div {
        align-items: center;
        background-color: #000;
        border-radius: 50%;
        display: flex;
        height: 16px;
        justify-content: center;
        position: absolute;
        right: -8px;
        top: -8px;
        width: 16px;

        & > img {
          width: 14px;
        }
      }

      & > img {
        background-color: #b3b7ba;
        border-radius: 2px;
        height: 108px;
        object-fit: contain;
        width: 100%;
      }
    }
  `,
};
