import isEqual from 'lodash/isEqual';
import React, { useState, Ref } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import thanksPage from '@src/assets/img/formThanksPage.png';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import Textarea from '@src/components/atoms/Textarea';
import Dialog from '@src/components/molecules/Dialog';
import TextForm from '@src/components/molecules/TextForm';
import { formState, submitFormState, useSetRecoilState, useRecoilValue } from '@src/recoilAtoms';
import { generatePath, ROUTES } from '@src/shared/routes';
import { FormStatus, QuestionType } from '@src/__generated__/globalTypes';
import { ThemeButton } from '@src/components/atoms/Button';
import DynamicInput, { Questions } from './DynamicInput';

export interface FormInformation {
  description: string;
  hash?: string;
  id?: any;
  questions: Questions[];
  status: FormStatus;
  title: string;
  thankDescription: string;
  thankTitle: string;
}

export interface Refs {
  backButtonRef: Ref<HTMLButtonElement>;
  previewButtonRef: Ref<HTMLButtonElement>;
  submitButtonRef: Ref<HTMLButtonElement>;
}

interface FormProps {
  isFormAnswerSubmitted: boolean;
  refs: Refs;
}

const Form = ({ isFormAnswerSubmitted, refs }: FormProps) => {
  const history = useHistory();
  const [isLeaveDialogOpen, setIsLeaveDialogOpen] = useState<boolean>(false);
  // form is able to draft edit/preview without saving, using recoil to save the draft information
  const formInformation = useRecoilValue(formState);
  const setFormState = useSetRecoilState(formState);
  const setSubmitFormState = useSetRecoilState(submitFormState);
  const {
    formState: { defaultValues, errors },
    register,
    setValue,
    trigger,
    watch,
  } = useFormContext<FormInformation>();
  const values = watch();
  const { id, questions } = values;
  const { t } = useTranslation();

  const onChangeInput = (index: number, value: Questions) => {
    const items = [...questions];
    items[index] = value;
    setValue('questions', items);
  };

  const onClickAddInput = () => {
    const nextOrder = questions.length + 1;
    const item = {
      genId: uuidv4(),
      image: null,
      isRequired: false,
      options: [],
      order: nextOrder,
      questionType: QuestionType.SHORT_ANSWER,
      title: '',
    };
    setValue('questions', questions.concat(item));
  };

  const onClickLeave = () => {
    if (formInformation || !isEqual(defaultValues, values)) {
      setIsLeaveDialogOpen(true);
    } else {
      history.push(ROUTES.FORMS);
    }
  };

  const onClickPreview = () => {
    trigger().then(isValid => {
      if (isValid) {
        if (!defaultValues?.id || !isEqual(defaultValues, values)) {
          const { description, status, thankDescription, thankTitle, title } = values;
          const previewItems = {
            description,
            hash: '',
            id: '',
            questions: questions.map(question => {
              const { image, isRequired, options, order: questionOrder, questionType, title: questionTitle } = question;

              return {
                id: uuidv4(),
                image,
                isRequired,
                options: options.map(option => {
                  const { optionTitle, order: optionOrder } = option;

                  return {
                    id: uuidv4(),
                    optionTitle,
                    order: optionOrder,
                  };
                }),
                order: questionOrder,
                questionType,
                title: questionTitle,
              };
            }),
            status,
            thankDescription,
            thankTitle,
            title,
          };

          // check if the initial values is same with current values, if the form is previewing before save
          // save the information into atoms for draft edit/preview
          setFormState(values);
          setSubmitFormState(previewItems);
        }
        history.push(id ? generatePath(ROUTES.FORMS_EDIT_LIVE_PREVIEW, { id }) : ROUTES.FORMS_ADD_LIVE_PREVIEW);
      }
    });
  };

  const onCopyInput = (value: Questions) => {
    let items = [...questions];
    items = items.concat({ ...value, genId: uuidv4(), order: questions.length + 1 });
    setValue('questions', items);
  };

  const onDeleteInput = (index: number) => {
    let items = [...questions];
    items.splice(index, 1);
    items = items.map((item, key) => {
      item.order = key + 1;

      return item;
    });
    setValue('questions', items);
  };

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) {
      return;
    }

    const destinationIndex = destination.index;
    const sourceIndex = source.index;
    let items = [...questions];
    const dragItem = items[sourceIndex];
    items.splice(sourceIndex, 1);
    items.splice(destinationIndex, 0, dragItem);
    items = items.map((item, key) => {
      item.order = key + 1;

      return item;
    });

    setValue('questions', items);
  };

  const isEmailExist = !!questions.find(question => question.questionType === QuestionType.EMAIL);
  const isNameExist = !!questions.find(question => question.questionType === QuestionType.NAME);

  return (
    <div>
      <HiddenButton ref={refs.backButtonRef} type="button" onClick={onClickLeave} />
      <HiddenButton ref={refs.previewButtonRef} type="button" onClick={onClickPreview} />
      <HiddenButton ref={refs.submitButtonRef} type="submit" />
      <Dialog
        execText="Leave"
        visible={isLeaveDialogOpen}
        onClose={() => setIsLeaveDialogOpen(false)}
        onExec={() => history.push(ROUTES.FORMS)}
      >
        <div css={styles.dialogContent}>
          <span>{t('Dialog.Leave Form')}</span>
          <span>{t('Annotation.Changes you made may not be saved')}</span>
        </div>
      </Dialog>
      <div css={styles.container}>
        <div css={styles.formContainer}>
          <InputContainer marginBottom={16}>
            <StyledTextForm
              isRequired
              placeholder={t('TextForm.Form Title')}
              title="Form Title"
              {...register('title')}
            />
            <ErrorMessage message={errors.title?.message} />
          </InputContainer>

          <InputContainer marginBottom={24}>
            <StyledTextForm
              isRequired
              placeholder={t('TextForm.Description')}
              title="Description"
              {...register('description')}
            />
            <ErrorMessage message={errors.description?.message} />
          </InputContainer>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {droppableProvided => (
                <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                  {questions.map((question, index) => {
                    const { genId, questionType } = question;

                    return (
                      <Draggable isDragDisabled={questions.length <= 1} draggableId={genId} index={index} key={genId}>
                        {(draggableProvider, draggableSnapshot) => (
                          <div
                            ref={draggableProvider.innerRef}
                            {...draggableProvider.draggableProps}
                            {...draggableProvider.dragHandleProps}
                          >
                            <DynamicInput
                              error={!!errors.questions}
                              isDragging={draggableSnapshot.isDragging}
                              isEmailExist={questionType !== QuestionType.EMAIL && isEmailExist}
                              isFormAnswerSubmitted={isFormAnswerSubmitted}
                              isNameExist={questionType !== QuestionType.NAME && isNameExist}
                              key={genId}
                              value={{ ...question }}
                              onChange={value => onChangeInput(index, value)}
                              onCopy={value => onCopyInput(value)}
                              onDelete={() => onDeleteInput(index)}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {droppableProvided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <ThemeButton
            onClick={onClickAddInput}
            text="+"
            skipTranslation
            width="max-content"
            fontSize="24px"
            fontWeight="normal"
            css={styles.addBtn}
          />

          <div css={styles.nextPageDivider}>
            <h2>
              <span>{t('Next Page')}</span>
            </h2>
          </div>

          <div css={styles.thanksPageContainer}>
            <div>{t('Thanks Page')}</div>
            <div>
              <img alt="bgImg" height="120" src={thanksPage} width="180" />
            </div>
            <div>
              <InputContainer marginBottom={16}>
                <StyledTextForm
                  css={styles.thanksPageTitle}
                  placeholder={t('Your response has been recorded')}
                  {...register('thankTitle')}
                />
                <ErrorMessage message={errors.thankTitle?.message} />
              </InputContainer>

              <InputContainer marginBottom={0}>
                <StyledTextArea
                  css={styles.textFormTitle}
                  placeholder={t(
                    'Thank you very much for your response. As soon as we confirm the content of your inquiry, our staff will contact you regarding our future actions'
                  )}
                  {...register('thankDescription')}
                />
                <ErrorMessage message={errors.thankDescription?.message} />
              </InputContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const InputContainer = styled.div<{ marginBottom: number }>`
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
`;

const HiddenButton = styled.button`
  display: none;
`;

const StyledTextArea = styled(Textarea)`
  border-radius: 3px;
`;

const StyledTextForm = styled(TextForm)`
  & > label {
    font-size: 14px;
  }

  & input {
    border-radius: 3px;
    height: 32px;
  }
`;

const styles = {
  actionContainer: css`
    position: absolute;
    right: 24px;
    top: 16px;
  `,
  container: css`
    display: flex;
    justify-content: center;
  `,
  dialogContent: css`
    display: grid;

    & > span:nth-of-type(1) {
      color: #27313b;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 24px;
    }

    & > span:nth-of-type(2) {
      color: #ff5f5f;
      font-size: 14px;
      font-weight: 400;
    }
  `,
  formContainer: css`
    background-color: #fff;
    width: 100%;
  `,
  nextPageDivider: css`
    margin-bottom: 32px;

    & > h2 {
      border-bottom: 1px solid #dee5ec;
      line-height: 0.1em;
      text-align: center;
      width: 100%;
    }

    /* stylelint-disable no-descending-specificity */
    & > h2 span {
      background-color: #fff;
      color: #6e7c89;
      font-size: 14px;
      font-weight: 600;
      padding: 0 10px;
    }
  `,
  textFormTitle: css`
    & input {
      color: #27313b;
      font-size: 16px;
      font-weight: 600;
    }
  `,
  thanksPageContainer: css`
    & > div:nth-of-type(1) {
      color: #27313b;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 24px;
    }

    & > div:nth-of-type(2) {
      display: flex;
      justify-content: center;
      margin-bottom: 24px;
    }

    & > div:nth-of-type(3) {
      background-color: #f6f8fa;
      padding: 24px;
    }
  `,
  thanksPageTitle: css`
    & input {
      color: #27313b;
      font-size: 16px;
    }
  `,
  addBtn: css`
    margin: 0 auto 48px;
    border-radius: 5px;

    .btn-text {
      position: relative;
      bottom: 0.05em;
    }
  `,
};

export default Form;
