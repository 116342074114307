import React, { useCallback, useEffect, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
// import BlocksIcon from '@src/assets/icon/chat/blocks.svg';
// import ClipboardIcon from '@src/assets/icon/chat/clipboard.svg';
// import PaperclipIcon from '@src/assets/icon/chat/paperclip.svg';
// import SmileIcon from '@src/assets/icon/chat/smile.svg';
import SendIcon from '@src/assets/icon/chat/send.svg';
import { css } from '@emotion/react';
import { ThemeButton } from '@src/components/atoms/Button';
import { SelectedChatStateType } from '@src/recoilAtoms';
import ConversationArea from './ConversationArea';
import ChatActionBar from './ChatActionBar';
import { useChatMessages } from './useChatMessages';

const ChatWindow = ({ hasMobileLayout, ...recoilState }: SelectedChatStateType & { hasMobileLayout: boolean }) => {
  const [scrollHeight, setScrollHeight] = useState<number | undefined>();

  const {
    fetchOlder,
    handleSendMessages,
    chatEvents,
    channelUserName,
    textareaValue,
    setTextareaValue,
    activeChatId,
    sendMessageLoading,
  } = useChatMessages();

  const setConversationHeight = useCallback(() => {
    const simulatorHeight = document.getElementsByClassName('chat_body_wrapper')[0].clientHeight; // mobile_body_wrapper in anyChat
    const replyAreaHeight = document.getElementsByClassName('reply_area_wrapper')[0].clientHeight;
    const conversationAreaHeight = simulatorHeight - replyAreaHeight;

    setScrollHeight(conversationAreaHeight);
  }, []);

  useEffect(() => {
    setConversationHeight();
  }, [scrollHeight, hasMobileLayout, activeChatId]);

  useEffect(() => () => window.removeEventListener('keypress', readKey), []);
  const readKey = () => new Promise(resolve => window.addEventListener('keypress', resolve, { once: true }));

  return (
    <div css={styles.chatWindow}>
      <div className="chat_body_wrapper" css={styles.chatBodyWrapper}>
        <ConversationArea
          channelUserName={channelUserName}
          scrollHeight={scrollHeight}
          chatEvents={chatEvents}
          fetchOlder={fetchOlder}
        />

        <div
          className="reply_area_wrapper"
          css={[styles.replyAreaWrapper, hasMobileLayout ? { paddingBottom: 0 } : '']}
        >
          <div css={[styles.replyArea, hasMobileLayout ? { margin: 0 } : '']}>
            {/* TODO: Action Popup after tempalates|emojis being implemented */}

            <div css={[styles.textEditorWrapper, hasMobileLayout ? styles.textEditorWrapperMobile : '']}>
              {hasMobileLayout && recoilState.isChartStateMenuOpen ? (
                <ChatActionBar {...recoilState} css={{ borderBottom: 'none' }} />
              ) : (
                <div css={styles.textEditor}>
                  <TextareaAutosize
                    css={styles.textArea}
                    minRows={2}
                    maxRows={10}
                    value={textareaValue}
                    onChange={e => {
                      setTextareaValue(e.target.value);
                    }}
                    // not in the spec but easy for testing, might remove later
                    onKeyPress={async e => {
                      if (e.key === 'Enter' && e.shiftKey) {
                        e.preventDefault();
                        await readKey().then(handleSendMessages);
                      }
                    }}
                  />
                </div>
              )}

              <div css={styles.actionButtons}>
                <div css={styles.toolsBlock}>
                  {/* TODO: implement tools functions */}
                  {/* <img src={SmileIcon} />
                  <img src={BlocksIcon} />
                  <img src={ClipboardIcon} />
                  <img src={PaperclipIcon} /> */}
                </div>
                <ThemeButton
                  text={hasMobileLayout ? '' : 'Send'}
                  onClick={handleSendMessages}
                  loading={sendMessageLoading}
                  width="max-content"
                  css={styles.send}
                  skipTranslation={hasMobileLayout}
                  postfixIcon={
                    hasMobileLayout ? (
                      <img
                        alt="sendIcon"
                        src={SendIcon}
                        css={[styles.sendIcon, { backgroundColor: sendMessageLoading ? '#6E7C89' : '#3892E5' }]}
                      />
                    ) : undefined
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatWindow;

const styles = {
  chatWindow: css`
    display: flex;
    flex: 1;
    flex-grow: 1;
  `,
  chatBodyWrapper: css`
    position: relative;
    height: 100%;
    flex: 1;
  `,
  replyAreaWrapper: css`
    position: absolute;
    bottom: 0;
    padding-bottom: 12px;
    width: 100%;
  `,
  replyArea: css`
    margin: 0 8px;
    background-color: #fff;
  `,
  textEditorWrapper: css`
    margin: 8px 0;
    background-color: #fff;
    border: 1px solid #dee5ec;
    box-sizing: border-box;
    border-radius: 5px;
  `,
  textEditorWrapperMobile: css`
    margin: 0;
    border-right: none;
    border-left: none;
    border-radius: 0;
  `,
  textEditor: css`
    overflow: hidden;
    border-top-right-radius: 5px;
  `,
  textArea: css`
    display: block;
    width: calc(100% - 24px);
    resize: none;
    padding: 12px;
  `,
  actionButtons: css`
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #dee5ec;
  `,
  toolsBlock: css`
    display: flex;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    align-items: center;
  `,
  send: css`
    color: #3892e5;
    font-weight: 600;
    font-size: 14px;
    text-transform: capitalize;
    border: none;

    &:hover {
      opacity: 0.8;
    }
  `,
  sendIcon: css`
    height: 24px;
    width: 56px;
    border-radius: 5px;
  `,
};
