import { atom } from 'recoil';

interface PaymentState {
  isNavigateFromPayment: boolean;
}

export const paymentState = atom<PaymentState>({
  key: 'paymentState',
  default: { isNavigateFromPayment: false },
});
