import { css } from '@emotion/core';
import { useQueryHelper } from '@src/libs/hooks';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import React from 'react';
import { ThemeButton } from '@src/components/atoms/Button';
import { ListIndicator } from '@src/components/molecules/Indicator';
import { getOffset, getPageInfo } from '@src/libs/paging';
import Empty from '@src/components/atoms/Empty';
import { LIMIT } from '@src/libs/constant';
import { SliderComponents } from '@src/components/molecules/SliderTable';
import { ROUTES, generatePath } from '@src/shared/routes';
import GET_FORM_RESPONDENTS_LIST from './GetFormRespondentsList.graphql';
import {
  GetFormRespondentsList,
  GetFormRespondentsListVariables,
  GetFormRespondentsList_getFormRespondentsList_respondents,
} from './__generated__/GetFormRespondentsList';

interface FormRespondentProps {
  currentPage: number;
  total: number;
}

const FormRespondent = (props: FormRespondentProps) => {
  const { currentPage, total } = props;
  const { t, params, enqueueSnackbar } = useQueryHelper();
  const { id } = params as { id: string };
  const offset = getOffset(currentPage);

  const { data, loading } = useQuery<GetFormRespondentsList, GetFormRespondentsListVariables>(
    GET_FORM_RESPONDENTS_LIST,
    {
      fetchPolicy: 'no-cache',
      variables: {
        input: {
          id: Number(id),
          limit: LIMIT,
          offset,
        },
      },
      onError: error => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
    }
  );

  if (loading) {
    return <ListIndicator />;
  }

  if (!data?.getFormRespondentsList?.respondents.length) {
    return <Empty />;
  }

  const pageInfo = getPageInfo(currentPage, total || 0);

  const renderRespondentTitle = (
    respondent: GetFormRespondentsList_getFormRespondentsList_respondents,
    index: number
  ) => {
    const title = respondent.firstName
      ? `${respondent.firstName} ${respondent.lastName || ''}`
      : respondent.email || `${t('Respondent')} ${index + 1 + offset}`;

    if (respondent.fanId) {
      return (
        <Link css={styles.link} to={`/fans/${respondent.fanId}/forms`}>
          {title}
        </Link>
      );
    } else {
      return title;
    }
  };

  return (
    <>
      <div css={styles.wrapper}>
        <div css={[styles.row, styles.heading]}>{t('Respondent')}</div>
        {data.getFormRespondentsList.respondents.map((item, index) => (
          <div css={styles.row} key={item.submissionId}>
            {renderRespondentTitle(item, index)}
            <ThemeButton
              css={styles.button}
              text="Answer"
              fontSize="12px"
              href={item.page ? generatePath(ROUTES.FORMS_EDIT_REPORT_INDIVIDUAL_PAGE, { id, page: item.page }) : '#'}
            />
          </div>
        ))}
      </div>

      <SliderComponents.Pager
        currentPage={currentPage}
        totalPages={pageInfo.totalPages}
        totalCount={pageInfo.totalCount}
        first={pageInfo.firstIndex}
        last={pageInfo.lastIndex}
        css={css`
          margin-top: 4px;
        `}
      />
    </>
  );
};

const styles = {
  wrapper: css`
    border: 1px solid #eef3f7;
  `,
  heading: css`
    height: 48px;
    background-color: #f6f8fa;
    border-bottom: 1px solid #dee5ec;
  `,
  row: css`
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;

    &:not(:last-of-type) {
      border-bottom: 1px solid #eef3f7;
    }
  `,
  link: css`
    color: #3892e5;
    font-size: 14px;
    font-weight: 600;
  `,
  button: css`
    width: fit-content;
    border-radius: 5px;
  `,
};

export default FormRespondent;
