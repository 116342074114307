import { useMutation } from '@apollo/client';
import JOIN_MARKETPLACE from './JoinMarketplace.graphql';
import { JoinMarketplace, JoinMarketplaceVariables } from './__generated__/JoinMarketplace';
import SOCIAL_AUTH_JOIN_CAMPAIGN from './SocialAuthJoinCampaign.graphql';
import {
  SocialAuthForJoinCampaign,
  SocialAuthForJoinCampaignVariables,
} from './__generated__/SocialAuthForJoinCampaign';

const useCampaign = () => {
  const [joinMarketplace] = useMutation<JoinMarketplace, JoinMarketplaceVariables>(JOIN_MARKETPLACE, {
    refetchQueries: ['Marketplace'],
  });
  const [socialAuthJoinCampaign] = useMutation<SocialAuthForJoinCampaign, SocialAuthForJoinCampaignVariables>(
    SOCIAL_AUTH_JOIN_CAMPAIGN,
    {
      refetchQueries: ['Marketplace'],
    }
  );

  return {
    joinMarketplace,
    socialAuthJoinCampaign,
  };
};

export default useCampaign;
