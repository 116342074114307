import { format } from 'date-fns';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useIntercom } from 'react-use-intercom';
import { useMutation, useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { ThemeButton } from '@src/components/atoms/Button';
import Grid from '@src/components/atoms/Grid';
import VideoPreview from '@src/components/atoms/VideoPreview';
import StatusStepper from '@src/components/organisms/CastingMarketplace/YourJob/StatusStepper';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import { LIMIT } from '@src/libs/constant';
import { useQueryHelper } from '@src/libs/hooks';
import { defaultEmptyImage, isVideoThumbnail } from '@src/libs/image';
import { getSocialMediaUrlByCampaignType } from '@src/libs/campaign';
import { switchImage } from '@src/libs/SocialMedia';
import { ViewportType } from '@src/libs/theme';
import CAMPAIGN_DETAILS from '@src/pages/Casting/YourJob/Details/queries/EngagementCampaignDetail.graphql';
import {
  EngagementCampaignDetail,
  EngagementCampaignDetailVariables,
} from '@src/pages/Casting/YourJob/Details/queries/__generated__/EngagementCampaignDetail';
import {
  AnyXEngagementCampaignStatus,
  CampaignSocialMediaType,
  CampaignStatusForInfluencer,
  CampaignType,
  SocialAccountType,
  TTCMStatus,
} from '@src/__generated__/globalTypes';
import { localizedDateFormatter } from '@src/components/atoms/List/DateColumn';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import { ROUTES, generatePath } from '@src/shared/routes';
import { DialogModal } from '@src/components/molecules/DialogModal';
import usePost, {
  CampaignInfluencerSocialAccounts_engagementPostSocialAccountsForInfluencer,
} from '@src/components/organisms/CastingMarketplace/YourJob/PostForm/usePost';
import DELETE_CAMPAIGN_POST from './mutations/DeleteCampaignPost.graphql';
import { DeleteCampaignPost, DeleteCampaignPostVariables } from './mutations/__generated__/DeleteCampaignPost';
import CAMPAIGN_POSTS from './queries/EngagementCampaignPosts.graphql';
import {
  EngagementCampaignPosts,
  EngagementCampaignPostsVariables,
} from './queries/__generated__/EngagementCampaignPosts';
import DialogContent from './DialogContent';
import ReasonWidget from './ReasonWidget';

const Posts = () => {
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState<number | null>(null);
  const [selectedPostId, setSelectedPostId] = useState<number | null>(null);
  const { enqueueSnackbar, params, t, i18n } = useQueryHelper();
  const { show } = useIntercom();
  const isDesktopView = useMediaQuery({ query: `(min-width: ${ViewportType.TABLET}px)` });
  const engagementId = Number(params.id);
  const { socialAccounts } = usePost(engagementId);

  const { data } = useQuery<EngagementCampaignPosts, EngagementCampaignPostsVariables>(CAMPAIGN_POSTS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      engagementId,
      limit: LIMIT,
      offset: 0,
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const { data: dataCampaignDetails } = useQuery<EngagementCampaignDetail, EngagementCampaignDetailVariables>(
    CAMPAIGN_DETAILS,
    {
      variables: {
        pk: engagementId,
      },
      onError: error => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
    }
  );

  const [deletePost] = useMutation<DeleteCampaignPost, DeleteCampaignPostVariables>(DELETE_CAMPAIGN_POST, {
    refetchQueries: ['EngagementCampaignPosts'],
    onCompleted: () => {
      enqueueSnackbar(t('succeededInDeleting'), { variant: 'success' });
      sendAmplitudeEvent(eventTypes.deleteEngagementPost, { postId: Number(selectedDeleteId) });
      setSelectedDeleteId(null);
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const onClickDeletePost = () => {
    setIsDeleting(true);
    deletePost({
      variables: {
        id: Number(selectedDeleteId),
      },
    }).finally(() => setIsDeleting(false));
  };

  const campaignPosts = data?.allEngagementPostsForInfluencer || [];
  const campaignDetails = dataCampaignDetails?.engagementForInfluencer;
  const isCampaignAvailable = campaignDetails?.status !== CampaignStatusForInfluencer.FINISHED;
  // TODO: to enable new flow when marketplace implement TTCM
  // const isTtcmCampaign = campaignDetails?.isTtcmCampaign;
  const isTtcmCampaign = false;

  const { isAddDraftPost, ttcmAccount } = socialAccounts.reduce(
    (accounts, value) => {
      const isTtcmAccount =
        isTtcmCampaign && value.socialMedia === SocialAccountType.TIKTOK && value.ttcmStatus === TTCMStatus.APPROVED;

      return {
        ...accounts,
        ...(!isTtcmAccount && { isAddDraftPost: true }),
        // ...(isTtcmAccount && { ttcmAccount: value }),
      };
    },
    {
      isAddDraftPost: false,
      ttcmAccount: null as CampaignInfluencerSocialAccounts_engagementPostSocialAccountsForInfluencer | null,
    }
  );

  const urlTiktokUsername = `${getSocialMediaUrlByCampaignType(CampaignSocialMediaType.TIKTOK)}${
    ttcmAccount ? `/@${ttcmAccount.username}` : ''
  }`;

  return (
    <>
      <div css={styles.container}>
        <DialogModal
          contentStyle={{
            backgroundColor: 'transparent',
            border: 'none',
            maxWidth: 700,
            width: '90%',
          }}
          closeIconCss={{ top: '16px', right: '2px' }}
          isOpen={!!selectedPostId}
          closeModal={() => setSelectedPostId(null)}
        >
          <div>
            {!!selectedPostId && <DialogContent postId={selectedPostId} onClose={() => setSelectedPostId(null)} />}
          </div>
        </DialogModal>

        <DialogModal isOpen={!!selectedDeleteId} closeModal={() => setSelectedDeleteId(null)}>
          <div css={styles.dialog}>
            <div css={styles.dialogContent}>
              <div css={styles.dialogTitle}>{t('Confirmation required')}</div>
              <div css={styles.dialogMessage}>{t('Dialog.Are you sure you want to delete the post?')}</div>
            </div>
            <div css={styles.dialogAction}>
              <ThemeButton text="Cancel" width="max-content" onClick={() => setSelectedDeleteId(null)} />
              <ThemeButton
                width="max-content"
                theme="red"
                disabled={isDeleting}
                text="Delete"
                onClick={onClickDeletePost}
              />
            </div>
          </div>
        </DialogModal>

        {isDesktopView && (
          <div css={styles.navigatorContainer}>
            <div>
              <BackNavigator title="Post List" to={`/casting/your_job/${engagementId}`} />
            </div>
            {isCampaignAvailable && (
              <div css={styles.actionHeaderContainer}>
                {isAddDraftPost && (
                  <ThemeButton
                    href={generatePath(ROUTES.CASTING_YOUR_JOB_ID_POST_ADD, { id: engagementId })}
                    text="Add Draft Post"
                    theme="blue"
                    width="max-content"
                  />
                )}
                {!!ttcmAccount && (
                  <ThemeButton
                    href={urlTiktokUsername}
                    targetBlank
                    text="Add Draft Video"
                    theme="blue"
                    width="max-content"
                  />
                )}
              </div>
            )}
          </div>
        )}

        {campaignPosts.map(post => {
          const {
            anyXStatus,
            content,
            id: postId,
            inappropriateDetailedReason,
            inappropriateReason,
            planedPostDate,
            postedDate,
            socialMedia,
            thumbNail,
          } = post;

          const isUploadedFromTiktok =
            isTtcmCampaign &&
            campaignDetails?.ttcmStatus === TTCMStatus.APPROVED &&
            socialMedia === CampaignSocialMediaType.TIKTOK;

          const showReason = [
            AnyXEngagementCampaignStatus.WARNING_EFFECTIVE,
            AnyXEngagementCampaignStatus.WARNING_REVIEWING,
          ].includes(anyXStatus);

          return (
            <div css={styles.postsContainer} key={postId}>
              <Grid lg={8} sm={10} xs={12}>
                <div css={styles.postContainer}>
                  <div css={styles.infoSection}>
                    {isDesktopView && showReason && (
                      <ReasonContainer>
                        <ReasonWidget
                          content={inappropriateDetailedReason}
                          status={anyXStatus}
                          title={inappropriateReason}
                        />
                      </ReasonContainer>
                    )}

                    <div css={styles.headerContainer}>
                      <Grid css={styles.stepperContainer} md={6} xs={12}>
                        <HeaderTitle>{t('Post Status')}</HeaderTitle>
                        <StatusStepper status={anyXStatus} type={CampaignType.ENGAGEMENT} />
                      </Grid>

                      {!isDesktopView && showReason && (
                        <ReasonContainer>
                          <ReasonWidget
                            content={inappropriateDetailedReason}
                            status={anyXStatus}
                            title={inappropriateReason}
                          />
                        </ReasonContainer>
                      )}

                      <Grid container md={6} xs={12}>
                        <HeaderInfo flexBasis="20%">
                          <HeaderTitle>{t('SNS')}</HeaderTitle>
                          <HeaderContent>
                            <img alt="socialMedia" height="24" src={switchImage(socialMedia, true)} width="24" />
                          </HeaderContent>
                        </HeaderInfo>
                        <HeaderInfo flexBasis="40%">
                          <HeaderTitle>{t('Planed Post Date')}</HeaderTitle>
                          <HeaderContent>
                            {localizedDateFormatter(
                              new Date(planedPostDate),
                              'MMMM dd, yyyy',
                              i18n.language as AppLanguage
                            )}
                          </HeaderContent>
                        </HeaderInfo>
                        <HeaderInfo flexBasis="40%">
                          <HeaderTitle>{t('Posted Date')}</HeaderTitle>
                          <HeaderContent>
                            {[
                              AnyXEngagementCampaignStatus.DRAFTING,
                              AnyXEngagementCampaignStatus.REVIEWING,
                              AnyXEngagementCampaignStatus.WARNING_REVIEWING,
                            ].includes(anyXStatus)
                              ? '- - -'
                              : postedDate
                              ? format(new Date(postedDate), 'MMMM dd, yyyy')
                              : '- - -'}
                          </HeaderContent>
                        </HeaderInfo>
                      </Grid>
                    </div>

                    <div css={styles.contentSection}>
                      <div>
                        <div>
                          {isVideoThumbnail(thumbNail) ? (
                            <VideoPreview height="86" url={thumbNail} width="152" />
                          ) : (
                            <img alt="postImg" height="86" src={defaultEmptyImage(thumbNail)} width="152" />
                          )}
                        </div>
                      </div>
                      <div onClick={() => setSelectedPostId(postId)}>{content}</div>
                    </div>
                  </div>

                  {![AnyXEngagementCampaignStatus.DRAFTING].includes(anyXStatus) && (
                    <div css={styles.actionSection}>
                      {[
                        AnyXEngagementCampaignStatus.REVIEWING,
                        AnyXEngagementCampaignStatus.WARNING_REVIEWING,
                      ].includes(anyXStatus) ? (
                        <>
                          <ThemeButton text="Delete" css={styles.button} onClick={() => setSelectedDeleteId(postId)} />
                          <ThemeButton
                            text={isUploadedFromTiktok ? 'Check Video' : 'Edit'}
                            theme="blue"
                            css={styles.button}
                            {...(isUploadedFromTiktok
                              ? {
                                  href: urlTiktokUsername,
                                  targetBlank: true,
                                }
                              : {
                                  href: generatePath(ROUTES.CASTING_YOUR_JOB_ID_POST_EDIT, {
                                    id: engagementId,
                                    postId,
                                  }),
                                })}
                          />
                        </>
                      ) : anyXStatus === AnyXEngagementCampaignStatus.POSTING ? (
                        <ThemeButton
                          theme="blue"
                          targetBlank
                          text="Post Now"
                          href={isTtcmCampaign ? urlTiktokUsername : getSocialMediaUrlByCampaignType(socialMedia)}
                          css={styles.button}
                        />
                      ) : anyXStatus === AnyXEngagementCampaignStatus.WARNING_EFFECTIVE ? (
                        <ThemeButton text="Contact" onClick={show} width="max-content" css={styles.button} />
                      ) : (
                        <ThemeButton
                          text="View Report"
                          href={generatePath(ROUTES.CASTING_YOUR_JOB_ID_REPORT, { id: engagementId })}
                          css={styles.button}
                        />
                      )}
                    </div>
                  )}
                </div>
              </Grid>
            </div>
          );
        })}
      </div>

      {!isDesktopView && isCampaignAvailable && (
        <div css={styles.actionContainer}>
          {isAddDraftPost && (
            <ThemeButton
              href={generatePath(ROUTES.CASTING_YOUR_JOB_ID_POST_ADD, { id: engagementId })}
              text="Add Draft Post"
              theme="blue"
              size="large"
            />
          )}
          {!!ttcmAccount && (
            <ThemeButton href={urlTiktokUsername} targetBlank text="Add Draft Video" theme="blue" size="large" />
          )}
        </div>
      )}
    </>
  );
};

const HeaderContent = styled.div`
  color: #27313b;
  font-size: 13px;
  margin-top: 8px;
`;

const HeaderInfo = styled.div<{ flexBasis: string }>`
  display: grid;
  flex-basis: ${({ flexBasis }) => flexBasis};
`;

const HeaderTitle = styled.div`
  color: #27313b;
  font-size: 13px;
  font-weight: 600;
`;

const ReasonContainer = styled.div`
  display: flex;
  flex-basis: 100%;
  margin-bottom: 16px;
`;

const styles = {
  actionContainer: css`
    --paddingSides: 16px;

    align-items: center;
    background-color: #fff;
    bottom: 0;
    box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.2);
    display: flex;
    gap: 8px;
    height: 72px;
    position: fixed;
    width: calc(100% - var(--paddingSides) * 2);
    padding: 0 var(--paddingSides);
  `,
  actionHeaderContainer: css`
    align-items: center;
    display: flex;
    gap: 8px;
    justify-content: flex-end;
  `,
  actionSection: css`
    display: flex;
    justify-content: flex-end;
    padding: 24px;
    gap: 8px;
  `,
  container: css`
    margin: 16px;
  `,
  contentSection: css`
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;

    & > div:nth-of-type(1) {
      height: 86px;
      max-width: 168px;
      width: 35%;

      & > div {
        background-color: #000;
        margin-right: 16px;

        & > img {
          object-fit: contain;
          width: 100%;
        }
      }

      @media (max-width: ${ViewportType.MEDIUM}px) {
        width: 50%;
      }
    }

    /* stylelint-disable no-descending-specificity, value-no-vendor-prefix, property-no-vendor-prefix */
    & > div:nth-of-type(2) {
      color: #3892e5;
      cursor: pointer;
      display: -webkit-box;
      font-size: 13px;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 65%;
      word-break: break-all;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;

      &:hover {
        text-decoration: underline;
      }

      @media (max-width: ${ViewportType.MEDIUM}px) {
        width: 50%;
      }
    }
  `,
  dialog: css`
    background-color: #fff;
    border-radius: 5px;
  `,
  dialogAction: css`
    border-top: 1px solid #dee5ec;
    display: flex;
    padding: 16px;
    justify-content: flex-end;

    & > button:nth-of-type(1) {
      margin-right: 8px;
    }
  `,
  dialogCloseBtn: css`
    align-items: center;
    background-color: #000;
    border-radius: 50%;
    display: flex;
    height: 40px;
    justify-content: center;
    position: absolute;
    right: -12px;
    top: -16px;
    width: 40px;
  `,
  dialogContent: css`
    padding: 16px;
  `,
  dialogMessage: css`
    color: #27313b;
    margin: 8px 0;
  `,
  dialogTitle: css`
    color: #27313b;
    font-size: 18px;
    font-weight: 600;
  `,
  headerContainer: css`
    display: flex;
    flex-wrap: wrap;
  `,
  infoSection: css`
    border-bottom: 1px solid #eef3f7;
    padding: 24px;
  `,
  navigatorContainer: css`
    display: flex;
    flex: wrap;
    margin-bottom: 16px;
    justify-content: space-between;

    & > div {
      display: flex;
      flex-basis: 50%;
    }
  `,
  postContainer: css`
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
  `,
  postsContainer: css`
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  `,
  stepperContainer: css`
    & > div:nth-of-type(2) {
      width: auto;
    }

    @media (max-width: ${ViewportType.TABLET}px) {
      border-bottom: 1px solid #eef3f7;
      margin-bottom: 16px;
      padding-bottom: 8px;
    }
  `,
  button: css`
    width: max-content;

    @media (max-width: ${ViewportType.SMALL}px) {
      height: 40px;
      width: fill-available;
    }
  `,
};

export default Posts;
