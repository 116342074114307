import { PostProcessorModule } from 'i18next';
import 'budoux/module/webcomponents/budoux-ja';

const postProcess: PostProcessorModule = {
  type: 'postProcessor',
  name: 'budoux',
  //   @ts-ignore correct return type is JSX.Element | string
  process(value, _key, _options, translator) {
    if (translator.language === 'ja') {
      return <budoux-ja>{value}</budoux-ja>;
    }

    return value;
  },
};

export default postProcess;
