import { format, formatDistanceToNow, getMonth, getYear, subDays } from 'date-fns';
import { getI18n } from 'react-i18next';
import { getDateLocal } from '@src/components/atoms/List/DateColumn';
import { DEFAULT_FNS_DATE_FORMAT } from './constant';
import { AppLanguage } from './i18n/languageDetector/utils';

export const getNow = (dateFormat: string = 'yyyy-MM-DD HH:mm:ss') => format(new Date(), dateFormat);

export const getToday = (dateFormat: string = DEFAULT_FNS_DATE_FORMAT) => format(new Date(), dateFormat);
// note use 29 day, because today is not included
export const getThirtyDaysAgo = (dateFormat: string = DEFAULT_FNS_DATE_FORMAT) =>
  format(subDays(new Date(), 29), dateFormat);
export const getSevenDaysAgo = (dateFormat: string = DEFAULT_FNS_DATE_FORMAT) =>
  format(subDays(new Date(), 6), dateFormat);

export const getFourteenDaysAgo = (dateFormat: string = DEFAULT_FNS_DATE_FORMAT) =>
  format(subDays(new Date(), 13), dateFormat);

export const getNinetyDaysAgo = (dateFormat: string = DEFAULT_FNS_DATE_FORMAT) =>
  format(subDays(new Date(), 89), dateFormat);

export const getSixtyDaysAgo = (dateFormat: string = DEFAULT_FNS_DATE_FORMAT) =>
  format(subDays(new Date(), 59), dateFormat);
export const getLastDay = (dateFormat: string = DEFAULT_FNS_DATE_FORMAT) => {
  const y = getYear(new Date());
  const m = getMonth(new Date());
  const lastDay = new Date(y, m + 1, 0);

  return format(lastDay, dateFormat);
};

// TODO: Localize Weekdays
const WEEKDAYS_LONG = {
  en: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  ja: ['月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日', '日曜日'],
  th: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  id: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  vi: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  tw: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  ch: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  km: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  my: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  ar: ['الأثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعه', 'السبت', 'الأحد'],
};

export const localizedWeekdays = (locale: AppLanguage = 'en') => WEEKDAYS_LONG[locale];

export const convertDurationToHhMmSs = (seconds: number | null) =>
  seconds === null ? '-' : new Date(seconds * 1000).toISOString().substr(11, 8);

// Calendar library utils
const MONTHS = {
  en: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  ja: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
  ar: [
    'كَانُون ٱلثَّانِي',
    'شُبَاط',
    'آذَار',
    'نَيْسَان',
    'أَيَّار',
    'حَزِيرَان',
    'تَمُّوز',
    'آب',
    'أَيْلُول',
    'تِشْرِين ٱلْأَوَّل',
    'تِشْرِين ٱلثَّانِي',
    'كَانُون ٱلْأَوَّل',
  ],
};
const CALENDAR_WEEKDAYS_LONG = {
  en: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  ja: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
  ar: ['الأحد', 'الأثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعه', 'السبت'],
};
const CALENDAR_WEEKDAYS_SHORT = {
  en: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
  ja: ['日', '月', '火', '水', '木', '金', '土'],
  ar: ['ح', 'ن', 'ث', 'ر', 'خ', 'ج', 'س'],
};

const formatDay = (d: Date, locale: AppLanguage = 'en') =>
  `${CALENDAR_WEEKDAYS_LONG[locale === 'ja' ? 'ja' : 'en'][d.getDay()]}, ${d.getDate()} ${
    MONTHS[locale === 'ja' ? 'ja' : 'en'][d.getMonth()]
  } ${d.getFullYear()}`;
const formatMonthTitle = (d: Date, locale: AppLanguage = 'en') => {
  switch (locale) {
    case 'ja':
      return `${d.getFullYear()}年${MONTHS[locale][d.getMonth()]}`;
    case 'ar':
      return `${MONTHS.ar[d.getMonth()]} ${d.getFullYear()}`;
    default:
      return `${MONTHS.en[d.getMonth()]} ${d.getFullYear()}`;
  }
};
const formatWeekdayShort = (i: number, locale: AppLanguage = 'en') =>
  CALENDAR_WEEKDAYS_SHORT[locale === 'ja' ? 'ja' : locale === 'ar' ? 'ar' : 'en'][i];
const formatWeekdayLong = (i: number, locale: AppLanguage = 'en') =>
  CALENDAR_WEEKDAYS_SHORT[locale === 'ja' ? 'ja' : locale === 'ar' ? 'ar' : 'en'][i];

export const localeUtils = {
  formatDay,
  formatMonthTitle,
  formatWeekdayShort,
  formatWeekdayLong,
};

export const hourIntervals = [
  '12 am',
  '1 am',
  '2 am',
  '3 am',
  '4 am',
  '5 am',
  '6 am',
  '7 am',
  '8 am',
  '9 am',
  '10 am',
  '11 am',
  '12 pm',
  '1 pm',
  '2 pm',
  '3 pm',
  '4 pm',
  '5 pm',
  '6 pm',
  '7 pm',
  '8 pm',
  '9 pm',
  '10 pm',
  '11 pm',
];

export const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const minColor = '#dee5ec';
export const maxColor = '#ff8097';

export const getDayfromDateString = (dateString: string) => {
  const { language } = getI18n();

  return format(new Date(dateString), language === 'ja' ? 'do' : 'dd', {
    locale: getDateLocal(language as AppLanguage),
  });
};

export const getDateTimeInPickerFormat = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minutes = date.getMinutes();
  const isAfternoon = hour >= 12;

  return {
    date: `${year}-${month}-${day}`,
    // if hour equal to 0 mean is 12 midnight and set it to 12
    time: `${String(isAfternoon && hour > 12 ? hour - 12 : hour || 12).padStart(2, '0')}:${String(minutes).padStart(
      2,
      '0'
    )} ${isAfternoon ? 'PM' : 'AM'}`,
  };
};

export const getFormatDistanceToNow = (date: string, isLocal: undefined | boolean = false): string =>
  formatDistanceToNow(new Date(isLocal ? date + 'Z' : date), { addSuffix: true });
