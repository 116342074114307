import React from 'react';
import LineBadge from '@src/assets/icon/chat/lineBadge.svg';
import { css } from '@emotion/react';
import DefaultAvatar from '@src/assets/icon/chat/defaultAvatar.svg';
import Image from '@src/components/atoms/Image';
import { ChatType } from './ChatsList/ChatInfoCard';

export interface ChatAvatarProps {
  type?: ChatType;
  avatar?: string | null;
  className?: string;
}

const ChatAvatar = ({ avatar, type, className }: ChatAvatarProps) => (
  <div css={styles.wrapper}>
    <Image
      src={avatar}
      css={styles.avatar}
      className={className}
      fallbackImg={DefaultAvatar}
      width="32px"
      height="32px"
      hasNoImgText={false}
    />
    {type === ChatType.Line && <img css={styles.badge} src={LineBadge} alt="LINE icon" width="16px" height="16px" />}
  </div>
);

const styles = {
  wrapper: css`
    position: relative;
    width: 32px;
    height: 32px;
  `,
  avatar: css`
    border-radius: 50%;

    img {
      background-color: transparent;
    }
  `,
  badge: css`
    position: absolute;
    top: -2px;
    right: -4px;
  `,
};

export default ChatAvatar;
