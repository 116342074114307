import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { ListIndicator } from '@src/components/molecules/Indicator';
import EmptyJob from '@src/components/organisms/CastingMarketplace/SearchJob/EmptyJob';
import CampaignCards from '@src/components/organisms/CastingMarketplace/YourJob/CampaignCards';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import TutorialTool, { TutorialTypes } from '@src/components/organisms/TutorialTool';
import { useQueryHelper } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/theme';
import MARKETPLACE_SEARCH_CAMPAIGNS from './queries/MarketplaceSearchCampaigns.graphql';
import { MarketplaceSearchCampaigns } from './queries/__generated__/MarketplaceSearchCampaigns';

const SearchJob = () => {
  const { enqueueSnackbar, t } = useQueryHelper();
  const isDesktopView = useMediaQuery({ query: `(min-width: ${ViewportType.TABLET}px)` });

  const { data, loading } = useQuery<MarketplaceSearchCampaigns>(MARKETPLACE_SEARCH_CAMPAIGNS, {
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const campaigns = data?.allCampaignsForSearchJob || [];

  return (
    <div css={styles.container}>
      <div>
        {isDesktopView && (
          <div css={styles.backNavigatorContainer}>
            <div>
              <BackNavigator title="Search Job" />
            </div>
            <div>
              <TutorialTool type={TutorialTypes.MARKETPLACE_SEARCH_JOB} />
            </div>
          </div>
        )}

        {loading ? (
          <ListIndicator />
        ) : campaigns.length > 0 ? (
          <CampaignCards campaigns={campaigns} isSearchCampaigns />
        ) : (
          <EmptyJob />
        )}
      </div>
    </div>
  );
};

const styles = {
  backNavigatorContainer: css`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;

    & > div {
      display: flex;
      flex-basis: 50%;
    }

    & > div:nth-of-type(2) {
      justify-content: flex-end;
    }
  `,
  container: css`
    display: flex;
    justify-content: center;
    margin: 16px;

    /* stylelint-disable no-descending-specificity */
    & > div {
      max-width: 1080px;
      width: 100%;
    }
  `,
};

export default SearchJob;
