import useGlobalLayoutProps from './useGlobalLayoutProps';

export default () => {
  // InitialLoading is used in Suspense to await while Locize will load, globalLayout is undefined on first render
  try {
    const {
      globalLayout: { isUuum },
    } = useGlobalLayoutProps();

    return {
      isUuumView: isUuum,
    };
  } catch (error) {
    return {
      isUuumView: false,
    };
  }
};
