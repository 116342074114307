import { useMutation } from '@apollo/client';
import { VerifyToken, VerifyTokenVariables } from '@src/libs/queries/__generated__/VerifyToken';
import * as VERIFY_TOKEN from '@src/libs/queries/VerifyToken.graphql';
import { InfluencerType, UserRoles } from '@src/__generated__/globalTypes';
import { UNEXPECTED_ERROR } from '@src/libs/error';

export const useVerifyToken = () => {
  const [verifyTokenMutation] = useMutation<VerifyToken, VerifyTokenVariables>(VERIFY_TOKEN);

  const verifyToken = async (
    token: string
  ): Promise<{ creatorType: InfluencerType; userId: number; role: UserRoles }> => {
    try {
      const { data } = await verifyTokenMutation({
        variables: {
          input: {
            token,
          },
        },
      });

      if (!data?.verifyToken?.ok) {
        throw new Error(UNEXPECTED_ERROR);
      }

      const { creatorType, role, userId } = data.verifyToken;

      if (!userId || !role || !creatorType || ![UserRoles.CREATOR_STAFF, UserRoles.INFLUENCER].includes(role)) {
        throw new Error(UNEXPECTED_ERROR);
      }

      return { creatorType, role, userId };
    } catch (error) {
      throw new Error(error);
    }
  };

  return { verifyToken };
};
