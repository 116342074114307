import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { EmailTemplateStatus } from '@src/__generated__/globalTypes';

interface StatusIndicatorProps {
  status: EmailTemplateStatus;
}

const StatusIndicator = ({ status }: StatusIndicatorProps) => {
  const { t } = useTranslation();
  const color =
    status === EmailTemplateStatus.DRAFT ? '#fff0d8' : status === EmailTemplateStatus.PUBLISHED ? '#d9f1e5' : '#eae1f5';

  return <Container color={color}>{t(status)}</Container>;
};

const Container = styled.div<{ color: string }>`
  align-items: center;
  background-color: ${({ color }) => color};
  border-radius: 35px;
  color: #27313b;
  display: flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  padding: 0 8px;
  width: fit-content;
`;

export default StatusIndicator;
