import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import trashIcon from '@src/assets/icon/mylink/trash.svg';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import Icomoon from '@src/components/atoms/Icomoon';
import TextForm from '@src/components/molecules/TextForm';

interface BlockCardProps {
  actionLabel: { label: string; url: string };
  disabled?: boolean;
  error?: boolean;
  isDeletable: boolean;
  isDragging: boolean;
  onChange: ({ label, url }: { label: string; url: string }) => void;
  onDelete: () => void;
}

const BlockCard = ({ actionLabel, disabled, error, isDeletable, isDragging, onChange, onDelete }: BlockCardProps) => {
  const { t } = useTranslation();

  return (
    <Container disabled={disabled}>
      <Block isDragging={isDragging}>
        <Icomoon icon="six-dots-vertical" size={32} />
        <div>
          <div>
            <TextForm
              disabled={disabled}
              placeholder={t('See more')}
              title="Action Label"
              value={actionLabel.label}
              onChange={e => onChange({ label: e.target.value, url: actionLabel.url })}
            />
            {error && !actionLabel.label && <ErrorMessage message={'requiredFieldMessage'} />}
          </div>
          <div>
            <TextForm
              disabled={disabled}
              placeholder="http://anymindgroup.com"
              title="URL"
              value={actionLabel.url}
              onChange={e => onChange({ label: actionLabel.label, url: e.target.value })}
            />
            {error && !actionLabel.url && <ErrorMessage message={'requiredFieldMessage'} />}
          </div>
        </div>
      </Block>
      <Delete disabled={!isDeletable} onClick={() => isDeletable && onDelete()}>
        <img height="16" src={trashIcon} width="16" />
      </Delete>
    </Container>
  );
};

const Block = styled.div<{ error?: boolean; isDragging: boolean }>`
  align-items: center;
  background-color: #fff;
  border: 1px solid #dee5ec;
  cursor: pointer;
  display: flex;
  flex: 1;
  gap: 8px;
  margin-bottom: 8px;
  opacity: ${({ isDragging }) => (isDragging ? '0.8' : '1')};
  padding: 16px 8px;

  & > svg {
    cursor: grab;
  }

  & > div {
    display: grid;
    width: 100%;

    & > div:nth-of-type(1) {
      margin-bottom: 16px;
    }
  }
`;

const Container = styled.div<{ disabled?: boolean }>`
  align-items: flex-start;
  display: flex;
  gap: 8px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

const Delete = styled.div<{ disabled: boolean }>`
  align-items: center;
  background-color: #fff;
  border-radius: 3px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: flex;
  height: 32px;
  justify-content: center;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  width: 32px;
`;

export default BlockCard;
