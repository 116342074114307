import { useQuery } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import CAMPAIGN_DETAIL from './queries/EngagementCampaignDetail.graphql';
import {
  EngagementCampaignDetail,
  EngagementCampaignDetailVariables,
} from './queries/__generated__/EngagementCampaignDetail';

const useCampaign = (engagementId?: number) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  const { data, loading } = useQuery<EngagementCampaignDetail, EngagementCampaignDetailVariables>(CAMPAIGN_DETAIL, {
    fetchPolicy: 'no-cache',
    skip: !engagementId,
    variables: {
      pk: engagementId as number,
    },
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
  });

  return {
    campaignDetails: data?.engagementForInfluencer,
    loading,
  };
};

export default useCampaign;
