import React from 'react';
import { css } from '@emotion/react';
import TextCutter from '@src/components/atoms/TextCutter';
import { chatMessageTemplatesState, useRecoilValue } from '@src/recoilAtoms';
import ReactPlayer from 'react-player';
import { GetChatEvents_getChatEvents_chatEvents } from '../__generated__/GetChatEvents';

interface ChatBubblesProps {
  inquiry?: string;
  timeStr?: string;
  channelUserName?: string;
  text?: string;
  authorName?: string | null;
  templateId?: string;
  typename: GetChatEvents_getChatEvents_chatEvents['__typename'];
  imageUrl?: string;
  contentUrl?: string;
  duration?: number;
}
export const ChatBubbles = ({ typename, ...restProps }: ChatBubblesProps) => {
  const { timeStr, channelUserName, text, authorName, templateId, contentUrl } = restProps;
  const chatTemplates = useRecoilValue(chatMessageTemplatesState);

  switch (typename) {
    case 'UserTextMessageEvent':
      return (
        <div css={[styles.wrapper, styles.justifyStart]}>
          <div css={[styles.bubble, styles.flexStart]}>
            <div css={styles.textSecondary}>
              <TextCutter lines={1} text={channelUserName || ''} css={styles.authorName} />
              <span>{timeStr}</span>
            </div>

            <p css={[styles.text, styles.userText]}>{text}</p>
          </div>
        </div>
      );
    case 'BotTextMessageEvent':
      return (
        <div css={styles.wrapper}>
          <div css={styles.bubble}>
            <div css={styles.textSecondary}>
              <span css={styles.timeStr}>{timeStr}</span>
              <TextCutter lines={1} text={authorName || ''} css={styles.authorName} />
            </div>
            <p css={styles.text}>{text}</p>
          </div>
        </div>
      );

    case 'UserImageMessageEvent':
      return (
        <div css={[styles.wrapper, styles.justifyStart]}>
          <div css={[styles.bubble, styles.flexStart]}>
            <div css={styles.textSecondary}>
              <TextCutter lines={1} text={channelUserName || ''} css={styles.authorName} />
              <span css={styles.timeStr}>{timeStr}</span>
            </div>
            <img src={restProps.imageUrl} css={styles.userMediaUrl} alt="user's media file" />
          </div>
        </div>
      );

    case 'UserAudioMessageEvent':
      return (
        <div css={[styles.wrapper, styles.justifyStart]}>
          <div css={[styles.bubble, styles.flexStart, styles.maxWidthUnset]}>
            <div css={styles.textSecondary}>
              <TextCutter lines={1} text={channelUserName || ''} css={styles.authorName} />
              <span css={styles.timeStr}>{timeStr}</span>
            </div>
            {/* TODO: apply custom design when ready */}
            <audio controls>
              <source src={contentUrl} type="audio/mp4" />
            </audio>
          </div>
        </div>
      );

    case 'UserVideoMessageEvent':
      return (
        <div css={[styles.wrapper, styles.justifyStart]}>
          <div css={[styles.bubble, styles.flexStart]}>
            <div css={styles.textSecondary}>
              <TextCutter lines={1} text={channelUserName || ''} css={styles.authorName} />
              <span css={styles.timeStr}>{timeStr}</span>
            </div>
            {/* TODO: apply custom design when ready */}
            <ReactPlayer url={contentUrl} controls width="100%" css={{ marginTop: '10px' }} />
          </div>
        </div>
      );

    // currently we will show them same, may individually later on demand
    case 'UserPostbackEvent':
    case 'InquiryFinishEvent':
    case 'InquiryEvent':
      return (
        <div css={[styles.wrapper, styles.justifyStart]}>
          <div css={styles.bubble}>
            <div css={styles.textSecondary}>
              <span css={styles.authorName}>{channelUserName}</span>
              <span css={styles.timeStr}>{timeStr}</span>
            </div>
            <p css={styles.text}>{text}</p>
          </div>
        </div>
      );

    case 'MessageTemplateEvent': {
      const templateText = chatTemplates.find(template => template.templateId === templateId)?.text;

      return templateText ? (
        <article css={styles.templateWrapper}>
          <div css={styles.templateBubble}>
            <p css={styles.templateText}>
              {`${templateText} `}
              <span css={styles.timeStr}>{timeStr}</span>
            </p>
          </div>
        </article>
      ) : null;
    }

    case 'StateUpdateEvent':
    default:
      return null;
  }
};

const styles = {
  authorName: css`
    font-weight: 600;
  `,
  timeStr: css`
    font-weight: 400;
  `,
  textSecondary: css`
    display: flex;
    justify-content: flex-end;
    column-gap: 4px;
    color: #6e7c89;
    font-size: 12px;
    line-height: 16px;
  `,
  wrapper: css`
    display: flex;
    justify-content: flex-end;
    padding: 8px 0;
  `,
  justifyStart: css`
    justify-content: flex-start;
  `,
  flexStart: css`
    align-items: flex-start;
  `,
  bubble: css`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-width: 75%;
    overflow: hidden;
  `,
  userText: css`
    background-color: #fff;
    color: #27313b;
  `,
  text: css`
    padding: 12px;
    margin: 2px; /* for box-shadow */
    margin-top: 8px;
    color: #fff;
    white-space: pre-line;
    background: #6e7c89;
    box-shadow: 0 2px 3px #dee5ec;
    border-radius: 15px;
    line-break: anywhere;
  `,
  templateText: css`
    font-weight: 400;
    text-align: center;
  `,
  templateWrapper: css`
    display: flex;
    justify-content: center;
    padding: 8px 0;
  `,
  templateBubble: css`
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
    font-size: 12px;
    line-height: 16px;
  `,
  userMediaUrl: css`
    max-width: 320px;
    width: 100%;
    border-radius: 5px;
    margin-top: 8px;
  `,
  maxWidthUnset: css`
    max-width: unset;
  `,
};
