import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useQueryHelper } from './hooks';

declare const GA_TRACKING_CODE: string;
export const useGaTracker = (isSignedIn: boolean) => {
  const { pathname } = useQueryHelper();

  const trackPage = (page: string) => {
    ReactGA.set({ page });
    ReactGA.send({ hitType: 'pageview', page });
  };

  useEffect(() => {
    if (GA_TRACKING_CODE && isSignedIn) {
      trackPage(pathname);
    }
  }, [pathname]);
};
