import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useMediaQuery } from 'react-responsive';
import { useFormContext } from 'react-hook-form';
import TextForm from '@src/components/molecules/TextForm';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import { useTranslation } from 'react-i18next';
import { ViewportBreakpoint } from '@src/libs/theme';
import { FormSubmitSection } from '@src/components/shared/FormSubmitSection';
import { FormValues } from './types';

const AddFanForm = () => {
  const { t } = useTranslation();
  const showNameInRow = useMediaQuery({ query: `(min-width: ${ViewportBreakpoint.MEDIUM}px)` });

  const {
    formState: { errors, isSubmitting },
    register,
  } = useFormContext<FormValues>();

  return (
    <ResponsiveWrapper>
      <FixedWidthContainer>
        <FormElementsWrapper>
          {showNameInRow ? (
            <InputWrapper css={flexRow}>
              <div css={flex1}>
                <StyledTextForm
                  title="First Name"
                  isRequired={true}
                  error={!!errors.firstName}
                  placeholder={t('First Name')}
                  css={marginRight16}
                  {...register('firstName')}
                />
                <ErrorMessage message={errors.firstName?.message} />
              </div>

              <div css={flex1}>
                <StyledTextForm
                  title="Last Name"
                  isRequired={true}
                  error={!!errors.lastName}
                  placeholder={t('Last Name')}
                  {...register('lastName')}
                />
                <ErrorMessage message={errors.lastName?.message} />
              </div>
            </InputWrapper>
          ) : (
            <>
              <InputWrapper>
                <StyledTextForm
                  title="First Name"
                  isRequired={true}
                  error={!!errors.firstName}
                  placeholder="First Name"
                  {...register('firstName')}
                />
                <ErrorMessage message={errors.firstName?.message} />
              </InputWrapper>
              <InputWrapper>
                <StyledTextForm
                  title="Last Name"
                  isRequired={true}
                  error={!!errors.lastName}
                  placeholder="Last Name"
                  {...register('lastName')}
                />
                <ErrorMessage message={errors.lastName?.message} />
              </InputWrapper>
            </>
          )}
          <InputWrapper>
            <StyledTextForm
              title="Email"
              isRequired={true}
              error={!!errors.email}
              placeholder={t('Your Email Address')}
              {...register('email')}
            />
            <ErrorMessage message={errors.email?.message} />
          </InputWrapper>
          <InputWrapper>
            <StyledTextForm
              title="Phone Number"
              error={!!errors.contactNumber}
              placeholder={t('Phone Number')}
              {...register('contactNumber')}
            />
            <ErrorMessage message={errors.contactNumber?.message} />
          </InputWrapper>
        </FormElementsWrapper>
        <FormSubmitSection isSubmitting={isSubmitting} />
      </FixedWidthContainer>
    </ResponsiveWrapper>
  );
};

const flex1 = css`
  flex: 1;
`;
const marginRight16 = css`
  margin-right: 16px;
`;
const flexRow = css`
  display: flex;
  flex-direction: row;
`;
const ResponsiveWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;
const FixedWidthContainer = styled.div`
  width: 100%;
  background-color: #fff;

  @media (min-width: ${ViewportBreakpoint.MEDIUM}px) {
    max-width: 640px;
  }
`;
const InputWrapper = styled.div`
  margin-bottom: 24px;
`;
const StyledTextForm = styled(TextForm)`
  & > label {
    font-size: 14px;
  }

  & input {
    border-radius: 2px;
    height: 32px;
  }
`;
const FormElementsWrapper = styled.div`
  padding: 24px 24px 0;
`;

export default AddFanForm;
