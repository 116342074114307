import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Icomoon from '@src/components/atoms/Icomoon';
import { AnyXEngagementCampaignStatus } from '@src/__generated__/globalTypes';

interface StatusMessageProps {
  status: AnyXEngagementCampaignStatus;
}

const StatusMessage = ({ status }: StatusMessageProps) => {
  const { t } = useTranslation();

  const { actionColor, description, icon, iconColor, title } = (() => {
    switch (status) {
      case AnyXEngagementCampaignStatus.DRAFTING:
        return {
          actionColor: '#c3def7',
          description: 'Make sure to upload the video with meeting the requrements below',
          icon: 'film-outlined',
          iconColor: '#3992e5',
          title: 'Upload draft video',
        };
      case AnyXEngagementCampaignStatus.POSTING:
        return {
          actionColor: '#c3def7',
          description: 'Make sure to upload the video with meeting the requrements below',
          icon: 'film-outlined',
          iconColor: '#3992e5',
          title: 'Post video',
        };
      case AnyXEngagementCampaignStatus.REVIEWING:
        return {
          actionColor: '',
          description: 'Your video is currently beign reviewed by platform and brand',
          icon: 'clock-anti-clockwise',
          iconColor: '#ffb63d',
          title: 'Awaiting review',
        };
      case AnyXEngagementCampaignStatus.WARNING_REVIEWING:
        return {
          actionColor: '#ffcfcf',
          description: 'See actions available fro your rejected video',
          icon: 'warning',
          iconColor: '#ff5f5f',
          title: `Your video wasn't approved by platform`,
        };
      default:
        return {
          actionColor: '',
          description: 'The collaboration is complete',
          icon: 'check',
          iconColor: '#40b87c',
          title: 'Complete',
        };
    }
  })();

  return (
    <div css={styles.container}>
      {actionColor && <ActionHint color={actionColor}>{t('Action required')}</ActionHint>}
      <div css={styles.titleContainer}>
        <Icomoon color={iconColor} icon={icon} />
        <div>{t(`Title.${title}`)}</div>
      </div>
      <div css={styles.description}>{t(`Annotation.${description}`)}</div>
    </div>
  );
};

const ActionHint = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  border-radius: 35px;
  color: #27313b;
  font-size: 10px;
  margin-bottom: 12px;
  padding: 2px 8px;
  width: fit-content;
`;

const styles = {
  container: css`
    background-color: #fff;
    border-radius: 11px;
    box-shadow: 1px 3px 11px rgba(0, 0, 0, 0.13), 0 1px 1px #dee5ec;
    margin-bottom: 24px;
    padding: 16px;
    width: fill-available;
  `,
  description: css`
    color: #6e7c89;
    font-size: 13px;
  `,
  titleContainer: css`
    align-items: center;
    display: flex;
    gap: 8px;
    margin-bottom: 8px;

    & > div {
      color: #27313b;
      font-size: 16px;
      font-weight: 600;
    }
  `,
};

export default StatusMessage;
