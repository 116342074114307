import { atom } from 'recoil';
import { TOptions } from 'i18next';

interface DynamicTitleStateType {
  title: string;
  interpolation?: TOptions;
}

export const dynamicTitleState = atom<DynamicTitleStateType>({
  key: 'dynamicTitleState',
  default: { title: '...', interpolation: undefined },
});

interface ToolbarBackStateProps {
  backMethod: (() => void) | null;
}

export const toolbarBackState = atom<ToolbarBackStateProps>({
  key: 'toolbarBackState',
  default: { backMethod: null },
});
