import { StateType } from './Context';

type ActionType = any;
const reducer = (state: StateType, { type, payload }: ActionType) => {
  switch (type) {
    case 'SET_READ_TUTORIALS':
    case 'SET_DIALOG':
    case 'TOGGLE_MENU':
    case 'USER_DATA':
    case 'APP_USER':
    case 'AUTH_USER':
    case 'UPDATE_JOB_PAGE_COUNT':
      return {
        ...state,
        ...payload,
      };

    case 'UPDATE_GLOBAL_LAYOUT':
      return {
        ...state,
        globalLayout: {
          ...state.globalLayout,
          ...payload.globalLayout,
        },
      };

    case 'ADD_READ_TUTORIAL':
      return {
        ...state,
        readTutorials: [...state.readTutorials, payload.tutorial],
      };

    default:
      return state;
  }
};

export default reducer;
