import styled from '@emotion/styled';
import React, { ReactElement } from 'react';

interface MultiTextProps {
  className?: string;
  data: string | ReactElement[] | ReactElement;
  title?: string;
}

const MultiTextColumn = (props: MultiTextProps) => {
  const { className, data } = props;
  const title = props.title || (typeof data === 'string' ? data : '');

  return (
    <TableElement className={className} title={title}>
      <Wrapper>
        <TableData>{data}</TableData>
      </Wrapper>
    </TableElement>
  );
};

const TableElement = styled.td`
  padding: 0 8px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TableData = styled.div`
  display: inline-block;
  font-size: 13px;
  line-height: 16px;
`;

export default MultiTextColumn;
