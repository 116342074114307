import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import Icomoon from '@src/components/atoms/Icomoon';
import * as Styled from '@src/components/organisms/AnalyticsShared/Overview/StyledComponents';
import { getGrowthArrow, getGrowthPrefix } from '@src/components/organisms/AnalyticsShared/TooltipTemplates/utils';
import { bigIntFormatter, formatPercent, intlNumberFormat } from '@src/libs/format';
import { Icon } from '@src/components/atoms/Icon';

export interface SummaryInfo {
  count?: number;
  growth?: number | null;
  percentage?: number | null;
}

interface SummaryItemProps extends SummaryInfo {
  icon: string;
  isPercentage?: boolean;
  title: string;
}

const SummaryItem = ({ count, icon, isPercentage, growth, percentage, title }: SummaryItemProps) => {
  const { t } = useTranslation();
  const arrow = getGrowthArrow(growth!);
  const hoverTotal = `${intlNumberFormat(count)}${isPercentage ? '%' : ''}`;
  const hoverGrowth = intlNumberFormat(growth);
  const isInvalid = growth === Infinity || isNaN(growth!);
  const isPositiveGrowth = growth === 0 ? undefined : growth! > 0;
  const growthValue = isPercentage ? formatPercent(growth) : bigIntFormatter(growth, 0);
  const prefix = getGrowthPrefix(growth!) || '';
  const totalValue = isPercentage ? formatPercent(count) : bigIntFormatter(count, 0);

  return (
    <div css={styles.container}>
      <Icon>
        <Icomoon icon={icon} size={32} />
      </Icon>
      <div>
        <div>{t(title)}</div>
        <Styled.TotalNumber title={hoverTotal}>{totalValue}</Styled.TotalNumber>
        {totalValue === '-' ? null : isInvalid ? (
          '-'
        ) : (
          <Styled.Growth
            isPositive={isPositiveGrowth}
            title={hoverGrowth}
          >{`${prefix}${growthValue}  ${arrow}  (${formatPercent(percentage)})`}</Styled.Growth>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: css`
    border-right: 1px solid #eef3f7;
    display: flex;
    flex: 1;
    gap: 8px;
    padding: 0 16px;

    & > div {
      & > div:nth-of-type(1) {
        color: #27313b;
        font-size: 11px;
        margin-bottom: 4px;
      }

      & > span {
        color: #27313b;
        font-size: 20px;
        margin-bottom: 4px;
      }
    }
  `,
};

export default SummaryItem;
