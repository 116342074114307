import React, { useEffect, useState, PropsWithChildren } from 'react';
import { matchPath } from 'react-router';
import InitialLoading from '@src/components/molecules/InitialLoading';
import { getToken, removeToken } from '@src/libs/auth';
import { useQueryHelper } from '@src/libs/hooks';
import { ROUTES } from '@src/shared/routes';
import { useAuthSetup } from './useAuthSetup';

interface AuthSetUpProps {
  hideLoadingPaths?: string[];
}

const AuthSetUp = (props: PropsWithChildren<AuthSetUpProps>) => {
  const { children, hideLoadingPaths } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const { setUp } = useAuthSetup();
  const { t, enqueueSnackbar, history, pathname } = useQueryHelper();
  const hideLoading = !!matchPath(pathname, {
    path: hideLoadingPaths,
    exact: true,
  });

  const executeSetUp = async () => {
    const token = getToken();
    if (!token) {
      setLoading(false);

      return;
    }

    try {
      await setUp(token);
    } catch (e) {
      console.error(e);
      enqueueSnackbar(t(e.message), { variant: 'error' });
      removeToken();
      history.push(ROUTES.ROOT);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    executeSetUp();
  }, []);

  if (loading) {
    return hideLoading ? null : <InitialLoading />;
  }

  return <>{children}</>;
};

export default AuthSetUp;
