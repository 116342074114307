import styled from '@emotion/styled';
import React, { useMemo } from 'react';
import { DropEvent, FileRejection, FileWithPath, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import Icomoon from '@src/components/atoms/Icomoon';

const baseStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  minHeight: '200px',
  border: '2px dashed #dee5ec',
  borderColor: '#dee5ec',
  borderRadius: '3px',
  outline: 'none',
  transition: 'border-color 0.3s ease-in-out',
  padding: '16px',
  boxSizing: 'border-box',
};

const activeStyle: React.CSSProperties = {
  borderColor: '#2196f3',
};

const acceptStyle: React.CSSProperties = {
  borderColor: '#00e676',
};

const rejectStyle: React.CSSProperties = {
  borderColor: 'tomato',
};

export interface DropAreaProps {
  notes: string[];
  multiple?: boolean;
  accept?: string | string[];
  onDrop: (acceptedFiles: File[], rejectedFiles?: FileRejection[], event?: DropEvent) => void;
  disabled: boolean;
}

const DropArea = (props: DropAreaProps) => {
  const { notes, multiple, accept, onDrop, disabled } = props;
  const { t } = useTranslation();
  const { acceptedFiles, getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    multiple,
    accept,
    disabled,
    onDrop,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const acceptedFileItems = (files: FileWithPath[]) =>
    files.map(file => (
      <li key={file.path}>
        {file.path} - {file.size} bytes
      </li>
    ));

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <Center>
          <input {...getInputProps()} />
          {acceptedFiles.length > 0 ? (
            <>
              <ul>{acceptedFileItems(acceptedFiles)}</ul>
            </>
          ) : (
            <>
              <Icomoon color="#dfe8ed" css={{ cursor: 'pointer' }} icon="cloud-upload" size={56} />
              <Label>{t('General.Add file or drop file upload')}</Label>
            </>
          )}
        </Center>

        {notes.map(note => (
          <Note key={note}>{note}</Note>
        ))}
      </div>
    </div>
  );
};

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Label = styled.p`
  color: #3892e5;
`;

const Note = styled.p`
  color: #6e7c89;
  font-size: 11px;
  line-height: 1.18;
  width: 100%;
`;

export default DropArea;
