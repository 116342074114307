import React from 'react';
import styled from '@emotion/styled';

interface ProgressBarProps {
  bgColor?: string;
  completed: number;
  completedColor?: string;
  height?: number;
}

const ProgressBar = ({ bgColor = '#eef3f7', completed, completedColor = '#3892e5', height = 10 }: ProgressBarProps) => (
  <ProgressBarContainer bgColor={bgColor} height={height}>
    <Bar bgColor={completedColor} width={completed} />
  </ProgressBarContainer>
);

const Bar = styled.div<{ bgColor: string; width: number }>`
  background-color: ${({ bgColor }) => bgColor};
  border-radius: ${({ width }) => (width === 100 ? '30px' : '30px 0 0 30px')};
  height: 100%;
  width: ${({ width }) => width}%;
`;

const ProgressBarContainer = styled.div<{ bgColor: string; height: number }>`
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 30px;
  height: ${({ height }) => height}px;
  width: 100%;
`;

export default ProgressBar;
