import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ListIndicator } from '@src/components/molecules/Indicator';
import CampaignCards from '@src/components/organisms/CastingMarketplace/YourJob/CampaignCards';
import EmptyJob from '@src/components/organisms/CastingMarketplace/YourJob/EmptyJob';
import { CampaignTabs } from '@src/components/organisms/CastingMarketplace/YourJob/helpers';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import TutorialTool, { TutorialTypes } from '@src/components/organisms/TutorialTool';
import { useQueryHelper } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/theme';
import useEngagementCampaigns from './useEngagementCampaigns';

const Listings = () => {
  const { history, t, ...location } = useQueryHelper();
  const [tab, setTab] = useState<string>(location.hash.slice(1) || CampaignTabs.IN_PROGRESS);
  const isFinished = tab === CampaignTabs.FINISHED;
  const { campaigns, finishedCampaignCount, loading, progressCampaignCount, refetch } = useEngagementCampaigns({
    isFinished,
  });
  const isDesktopView = useMediaQuery({ query: `(min-width: ${ViewportType.TABLET}px)` });

  useEffect(() => {
    refetch();
  }, [tab]);

  const onClickTab = (value: string) => {
    setTab(value);
    history.push({ hash: value });
  };

  const tabs = [
    {
      count: progressCampaignCount,
      title: 'in progress',
      value: CampaignTabs.IN_PROGRESS,
    },
    {
      count: finishedCampaignCount,
      title: 'finished',
      value: CampaignTabs.FINISHED,
    },
  ];

  return (
    <div css={styles.container}>
      <div>
        {isDesktopView && (
          <div css={styles.navigatorContainer}>
            <div>
              <BackNavigator title="Your Job" />
            </div>
            <div>
              <TutorialTool type={TutorialTypes.CASTING} />
            </div>
          </div>
        )}

        <div css={styles.tabsContainer}>
          {tabs.map((item, index) => {
            const isActive = item.value === tab;

            return (
              <Tab isActive={isActive} key={index} onClick={() => onClickTab(item.value)}>
                <span>{item.count}</span>
                <span>{t(item.title)}</span>
              </Tab>
            );
          })}
        </div>

        {loading ? (
          <ListIndicator />
        ) : campaigns.length > 0 ? (
          <CampaignCards campaigns={campaigns} isFinished={isFinished} />
        ) : (
          <EmptyJob />
        )}
      </div>
    </div>
  );
};

const Tab = styled.div<{ isActive?: boolean }>`
  all: unset;
  background-color: ${({ isActive }) => (isActive ? '#27313b' : '#fff')};
  color: ${({ isActive }) => (isActive ? '#fff' : '#27313b')};
  cursor: pointer;
  display: grid;
  flex-basis: 50%;
  height: 40px;
  padding: 8px 0;
  text-align: center;
  text-transform: uppercase;

  & > span:nth-of-type(1) {
    font-size: 18px;
    font-weight: 600;
  }

  & > span:nth-of-type(2) {
    font-size: 12px;
    font-weight: 600;
  }
`;

const styles = {
  container: css`
    display: flex;
    justify-content: center;
    margin: 16px;

    & > div {
      max-width: 1080px;
      width: 100%;
    }
  `,
  navigatorContainer: css`
    display: flex;
    flex-wrap: wrap;

    & > div {
      display: flex;
      flex-basis: 50%;
    }

    & > div:nth-of-type(2) {
      justify-content: flex-end;
    }
  `,
  tabsContainer: css`
    display: flex;
    flex-wrap: wrap;
    margin: 16px 0;
  `,
};

export default Listings;
