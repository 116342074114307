import { useQuery } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import { LIMIT } from '@src/libs/constant';
import * as GET_FAN_ACTIVITIES from './GetFanActivities.graphql';
import { GetFanActivities, GetFanActivitiesVariables } from './__generated__/GetFanActivities';

interface UseGetFanActivitiesProps {
  fanId: number;
}

const useGetFanActivities = ({ fanId }: UseGetFanActivitiesProps) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  const { data, loading, fetchMore } = useQuery<GetFanActivities, GetFanActivitiesVariables>(GET_FAN_ACTIVITIES, {
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        fanId,
        offset: 0,
        limit: LIMIT,
      },
    },
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
  });

  return {
    data,
    loading,
    fetchMore,
  };
};

export default useGetFanActivities;
