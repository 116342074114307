import React, { ReactNode } from 'react';
import { Tooltip as TooltipPrimitive } from '@anymindgroup/any-ui-react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import Icomoon from '@src/components/atoms/Icomoon';

interface TooltipTypes {
  help: React.ReactNode;
  iconMargin?: string;
  placement?: 'top' | 'right' | 'bottom' | 'left';
  className?: string;
  helpInterpol?: { [key: string]: number };
  children?: ReactNode;
}

export const Tooltip = (props: TooltipTypes) => {
  const { t } = useTranslation();
  const { help, iconMargin, placement = 'left', className, helpInterpol } = props;
  const isHelpString = typeof help === 'string';

  return (
    <TooltipPrimitive
      content={isHelpString ? t(`Tooltip.${help}`, { ...helpInterpol }) || '' : help}
      contentProps={{ side: placement }}
      css={{ marginLeft: iconMargin, fontSize: '12px' }}
      className={className}
    >
      {props.children ? props.children : <Icomoon css={styles.icon} icon="help-filled" size={14} />}
    </TooltipPrimitive>
  );
};

const styles = {
  icon: css`
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }
  `,
};
