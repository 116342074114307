import { Fragment, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CircularProgress } from '@src/components/molecules/CircularProgress';
import attactStaffTag from '@src/assets/img/broadcast/attachStaffTag.svg';
import { ThemeButton } from '@src/components/atoms/Button';
import Avatar from '@src/components/atoms/Avatar';
import Icomoon from '@src/components/atoms/Icomoon';
import Dialog from '@src/components/molecules/Dialog';
import GET_LINE_CHATS from '@src/components/organisms/ChatListSection/GetLineChats.graphql';
import {
  GetLineChats,
  GetLineChatsVariables,
} from '@src/components/organisms/ChatListSection/__generated__/GetLineChats';
import { LIMIT } from '@src/libs/constant';
import { useQueryHelper } from '@src/libs/hooks';
import { ROUTES } from '@src/shared/routes';
import { CRMChatTypeFilter, DeliveryTimingType } from '@src/__generated__/globalTypes';
import { FormInformation } from '../helpers';

interface FormActionProps {
  tags: { name: string }[];
  onSubmit: (information: FormInformation) => Promise<void>;
}

enum DialogStatus {
  ATTACH_TAG = 'ATTACH_TAG',
  CLOSE = 'CLOSE',
  SEND_MESSAGE = 'SEND_MESSAGE',
  SEND_TEST_MESSAGE = 'SEND_TEST_MESSAGE',
}

const FormAction = ({ tags, onSubmit }: FormActionProps) => {
  const [dialogStatus, setDialogStatus] = useState<DialogStatus>(DialogStatus.CLOSE);
  const { history, t } = useQueryHelper();
  const {
    formState: { isSubmitting },
    handleSubmit,
    setValue,
    trigger,
    watch,
  } = useFormContext<FormInformation>();
  const [deliveryTiming, isEditPage] = watch(['deliveryTiming', 'id']);
  const isAttachTagDialog = dialogStatus === DialogStatus.ATTACH_TAG;
  const tagName = 'Staff';
  const isTestMemberRegistered = !!tags.find(tag => tag.name === tagName);

  const { data } = useQuery<GetLineChats, GetLineChatsVariables>(GET_LINE_CHATS, {
    variables: {
      input: {
        keyword: '',
        limit: LIMIT,
        offset: 0,
        tagName,
        typeFilter: CRMChatTypeFilter.ACTIVATED,
      },
      countInput: {
        keyword: '',
      },
    },
  });

  useEffect(() => {
    if (!isSubmitting && dialogStatus !== DialogStatus.CLOSE) {
      setDialogStatus(DialogStatus.CLOSE);
    }
  }, [isSubmitting]);

  const onExec = () => {
    if (isAttachTagDialog) {
      history.push(ROUTES.CHAT);

      return;
    }

    trigger().then(isValid => {
      if (isValid) {
        switch (dialogStatus) {
          // if is send test message, will set testField & sendField to true
          // @ts-ignore
          case DialogStatus.SEND_TEST_MESSAGE:
            setValue('isTest', true);
          default:
            setValue('isSend', true);
        }
        handleSubmit(onSubmit)();
      } else {
        setDialogStatus(DialogStatus.CLOSE);
      }
    });
  };

  const lineChatList = data?.getLineChats?.chats || [];

  return (
    <div css={styles.container}>
      <Dialog
        contentStyle={{ width: dialogStatus === DialogStatus.SEND_MESSAGE ? 480 : 648 }}
        execColor={isAttachTagDialog ? 'white' : 'blue'}
        execDisabled={isSubmitting}
        execText={isAttachTagDialog ? 'Move to Chat Page' : 'Send'}
        hideCancel={isAttachTagDialog}
        visible={dialogStatus !== DialogStatus.CLOSE}
        onClose={() => setDialogStatus(DialogStatus.CLOSE)}
        onExec={onExec}
      >
        <div>
          {isSubmitting && (
            <div css={styles.dialogOverlay}>
              <CircularProgress />
            </div>
          )}
          {dialogStatus === DialogStatus.ATTACH_TAG ? (
            <Fragment>
              <DialogTitle>{t('Dialog.Attach Staff Tag')}</DialogTitle>
              <DialogDescription>
                <Trans i18nKey="Annotation.You need to attach staff tag to the user who you want to send test message from">
                  text<span>text</span>text
                </Trans>
              </DialogDescription>
              <DialogDescription>
                <Trans i18nKey="Annotation.Before you attach the staff tag, you need to be a friend of friend of linked LINE official account">
                  text<span>text</span>text
                </Trans>
              </DialogDescription>
              <DialogImageContainer>
                <img height="397" src={attactStaffTag} width="382" />
              </DialogImageContainer>
            </Fragment>
          ) : dialogStatus === DialogStatus.SEND_MESSAGE ? (
            <Fragment>
              <DialogTitle>{t('Dialog.Send message')}</DialogTitle>
              <DialogDescription>{t('Annotation.Are you sure you want to send this message')}</DialogDescription>
            </Fragment>
          ) : (
            <Fragment>
              <DialogTitle>{t('Dialog.Send test message')}</DialogTitle>
              <DialogDescription>{t('Annotation.Are you sure you want to send message')}</DialogDescription>
              <DialogDescription css={{ marginBottom: 4 }}>
                <Trans i18nKey="Annotation.You can send test message to these users who have staff tag">
                  text<span>text</span>text
                </Trans>
              </DialogDescription>
              <div css={styles.dialogHintContainer} onClick={() => setDialogStatus(DialogStatus.ATTACH_TAG)}>
                <Icomoon color="#c5d0da" icon="help-filled" />
                <div>{t('Annotation.How can I attach the Message Sender tag')}</div>
              </div>
              <div css={styles.lineChatListContainer}>
                <div>
                  {lineChatList.map(item => {
                    const { chatId, userAvatar, userName } = item;

                    return (
                      <div key={chatId}>
                        <Avatar title={userName} size={40} src={userAvatar || ''} />
                        <div>{userName}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </Dialog>
      <div css={styles.btnContainer}>
        <ThemeButton
          theme="blue"
          text={deliveryTiming.type === DeliveryTimingType.IMMEDIATELY_SEND ? 'Send' : 'Schedule'}
          width="max-content"
          disabled={isSubmitting}
          onClick={() => setDialogStatus(DialogStatus.SEND_MESSAGE)}
        />
        <ThemeButton
          text="Test message"
          width="max-content"
          disabled={isSubmitting}
          onClick={() =>
            setDialogStatus(isTestMemberRegistered ? DialogStatus.SEND_TEST_MESSAGE : DialogStatus.ATTACH_TAG)
          }
        />
        <ThemeButton
          text={isEditPage ? 'Update' : 'Save draft'}
          width="max-content"
          disabled={isSubmitting}
          type="submit"
          css={styles.btnEnd}
        />
      </div>
    </div>
  );
};

const DialogDescription = styled.div`
  color: #27313b;
  font-size: 14px;
  margin-bottom: 16px;

  & > span {
    background-color: #eef3f7;
    border-radius: 3px;
    color: #27313b;
    font-size: 12px;
    margin: 0 4px;
    padding: 4px 8px;
    width: fit-content;
  }
`;

const DialogImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const DialogTitle = styled.div`
  color: #27313b;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
`;

const styles = {
  btnContainer: css`
    display: flex;
    flex: 1;
    gap: 8px;
  `,
  container: css`
    display: flex;
  `,
  dialogHintContainer: css`
    align-items: center;
    display: flex;
    gap: 4px;
    margin-bottom: 24px;

    & > div {
      color: #3892e5;
      cursor: pointer;
      font-size: 14px;
      text-decoration: underline;
    }
  `,
  dialogOverlay: css`
    align-items: center;
    background-color: #fff;
    display: flex;
    justify-content: center;
    height: 100%;
    left: 0;
    opacity: 0.7;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 5;

    & svg {
      color: #dfe1e5;
    }
  `,
  lineChatListContainer: css`
    display: flex;
    justify-content: center;

    & > div {
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0 2px 5px #dee5ec, 0 1px 4px #dee5ec;
      max-height: 256px;
      overflow: auto;
      width: 416px;

      & > div {
        align-items: center;
        border-bottom: 1px solid #eef3f7;
        display: flex;
        gap: 8px;
        padding: 8px 16px;

        & > div:nth-of-type(2) {
          color: #27313b;
          font-size: 14px;
        }
      }
    }
  `,
  btnEnd: css`
    margin-left: auto;

    [dir='rtl'] & {
      margin-left: unset;
      margin-right: auto;
    }
  `,
};

export default FormAction;
