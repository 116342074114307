import { ApolloError } from '@apollo/client';
import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import InitialLoading from '@src/components/molecules/InitialLoading';
import { ConnectType, getCallbackUrl, getConnectState, removeToken, setToken } from '@src/libs/auth';
import { getMessagesFromApolloError, getMessagesFromFetchResult, ErrorTypes, UNEXPECTED_ERROR } from '@src/libs/error';
import { useQueryHelper } from '@src/libs/hooks';
import { useAuthSetup } from '@src/components/organisms/AuthSetUp/useAuthSetup';
import { ListIndicator } from '@src/components/molecules/Indicator';
import { ROUTES } from '@src/shared/routes';
import { AnyXAuthSocialAccountType, SocialAccountType } from '@src/__generated__/globalTypes';
import { useAuthProviderResponse } from '../hooks';
import {
  AnyXSocialAuthSignInOrSignUp,
  AnyXSocialAuthSignInOrSignUpVariables,
} from './__generated__/AnyXSocialAuthSignInOrSignUp';
import * as AUTH_SIGNIN_SIGNUP from './AnyXSocialAuthSignInOrSignUp.graphql';

const SignUpEnabledComponent = () => {
  const [authSignInSignUp] = useMutation<AnyXSocialAuthSignInOrSignUp, AnyXSocialAuthSignInOrSignUpVariables>(
    AUTH_SIGNIN_SIGNUP
  );
  const { setUp } = useAuthSetup();

  const { t, enqueueSnackbar, history } = useQueryHelper();

  const { provider, response } = useAuthProviderResponse(ROUTES.SIGN_UP);

  const signUpEnabledCall = async () => {
    if (!provider) {
      enqueueSnackbar(t(UNEXPECTED_ERROR), { variant: 'error' });
      if (window.opener) {
        window.close();
      }
      history.push(ROUTES.ROOT);

      return;
    }

    // Redirect with state for connecting FB pages and an IG account.
    let socialMediaProvider = provider;
    const connectState = await getConnectState();
    const isIgConnectType = connectState && connectState.connectType === ConnectType.CONNECT_IG_ACCOUNT;

    if (isIgConnectType) {
      socialMediaProvider = AnyXAuthSocialAccountType.INSTAGRAM;
    }

    const variables = {
      input: {
        provider: socialMediaProvider,
        response,
        callbackUrl: getCallbackUrl(provider, FE_REDIRECT_MAPPING.SIGNUP_ENABLED),
      },
    };

    const { uuid, name, email, hasIgAccount, token, refreshToken, errors } = await authSignInSignUp({ variables })
      .then(result => {
        if (result && result.data && result.data.anyXSocialAuthSignInOrSignUp) {
          const payload = result.data.anyXSocialAuthSignInOrSignUp;

          return {
            uuid: payload.signUp?.uuid,
            name: payload.signUp?.name,
            email: payload.signUp?.email,
            hasIgAccount: payload.hasIgAccount,
            token: payload.signIn?.token,
            refreshToken: null,
            errors: [],
          };
        } else {
          return {
            uuid: null,
            name: null,
            email: null,
            hasIgAccount: false,
            token: null,
            refreshToken: null,
            errors: getMessagesFromFetchResult(result),
          };
        }
      })
      .catch((e: ApolloError) => {
        if (window.opener) {
          window.opener.postMessage({ redirectPath: ROUTES.ROOT, errorMsg: e.message }, '*');
          window.close();
        }

        return {
          uuid: null,
          name: null,
          email: null,
          hasIgAccount: false,
          token: null,
          refreshToken: null,
          errors: getMessagesFromApolloError(e),
        };
      });

    // User has already an account.
    if (errors.length === 0 && token) {
      // Sign In with token
      setToken(token, refreshToken);

      try {
        await setUp(token);

        if (window.opener) {
          // send redirect url to main app listener, from where we opened this child window
          window.opener.postMessage(
            {
              redirectPath: `${ROUTES.ANALYTICS}?sm=${SocialAccountType.YOUTUBE}`,
            },
            '*'
          );
          window.close();

          return;
        }
        sendAmplitudeEvent(eventTypes.signUp);
        history.push(ROUTES.HOME);
      } catch (e) {
        removeToken();
        console.error(e);
        enqueueSnackbar(t(e.message), { variant: 'error' });
        if (window.opener) {
          window.close();
          window.opener.postMessage({ redirectPath: ROUTES.ROOT, errorMsg: e.message }, '*');

          return;
        }
        history.push(ROUTES.ROOT);
      }

      return;
    }

    // Redirect to SignUp form
    if (errors.length === 0) {
      if (socialMediaProvider === SocialAccountType.INSTAGRAM && !hasIgAccount) {
        enqueueSnackbar(t('Instagram account was not connected to this Facebook account'), { variant: 'error' });
        history.push(ROUTES.SIGN_UP_IG_UNABLE_CONNECT);

        return;
      }

      if (window.opener) {
        window.opener.postMessage(
          {
            redirectPath: ROUTES.SIGN_UP_PROFILE,
            state: {
              socialMedia: provider,
              uuid: uuid || '',
              name: name || '',
              email: email || '',
            },
          },
          '*'
        );
        window.close();

        return;
      }

      history.push({
        pathname: ROUTES.SIGN_UP_PROFILE,
        state: {
          socialMedia: provider,
          uuid: uuid || '',
          name: name || '',
          email: email || '',
        },
      });

      return;
    }

    errors.forEach(error => {
      console.error(error);
      // put if condition on top of enqueueSnackbar to avoid showing alert message
      if (provider === SocialAccountType.TIKTOK && error === ErrorTypes.VIDEO_PERMISSION_MISSING) {
        history.push(ROUTES.SIGN_UP_TIKTOK_GRANT_PERMISSION);

        return;
      }

      if (provider === SocialAccountType.FACEBOOK && error === ErrorTypes.FB_REQUIRED_PERMISSION_MISSING) {
        history.push(ROUTES.SIGN_UP_FACEBOOK_GRANT_PERMISSION);

        return;
      }

      enqueueSnackbar(t(error), { variant: 'error' });
      if (window.opener) {
        window.opener.postMessage({ redirectPath: ROUTES.SIGN_UP, errorMsg: error }, '*');
        window.close();

        return;
      }
      history.push(ROUTES.SIGN_UP);
    });
  };

  useEffect(() => {
    signUpEnabledCall();
  }, []);

  return window.opener ? (
    <ListIndicator height="100vh" text="Authentication is in progress, please wait" />
  ) : (
    <InitialLoading />
  );
};

export default SignUpEnabledComponent;
