import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ThemeButton } from '@src/components/atoms/Button';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import Grid from '@src/components/atoms/Grid';
import { ViewportType } from '@src/libs/theme';
import TextForm from '@src/components/molecules/TextForm';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import { ROUTES } from '@src/shared/routes';
import { GetUserDetail_getUserDetail } from './queries/__generated__/GetUserDetail';

const UserDetail = () => {
  const isDesktopView = useMediaQuery({ query: `(min-width: ${ViewportType.TABLET}px)` });
  const {
    formState: { errors, isSubmitting },
    register,
  } = useFormContext<GetUserDetail_getUserDetail>();

  return (
    <div css={styles.container}>
      {isDesktopView && (
        <div css={styles.navigatorContainer}>
          <BackNavigator title="User Detail" to={ROUTES.SETTINGS_USERS} />
        </div>
      )}

      <div css={styles.formContainer}>
        <Grid md={8} sm={10} xs={12}>
          <div css={styles.formBox}>
            <TextFormContainer>
              <StyledTextForm
                error={!!errors.name}
                isRequired
                placeholder="Display Name"
                title="Display Name"
                {...register('name')}
              />
              <ErrorMessage message={errors.name?.message} />
            </TextFormContainer>

            <TextFormContainer>
              <StyledTextForm
                error={!!errors.email}
                isRequired
                placeholder="Email Address"
                title="Email Address"
                {...register('email')}
              />
              <ErrorMessage message={errors.email?.message} />
            </TextFormContainer>
          </div>

          <div css={styles.actionContainer}>
            <ThemeButton theme="blue" css={styles.submitBtn} disabled={isSubmitting} text="Save" type="submit" />
          </div>
        </Grid>
      </div>
    </div>
  );
};

const StyledTextForm = styled(TextForm)`
  & > label {
    font-size: 14px;
  }

  & input {
    border-radius: 0;
    height: 32px;
  }
`;

const TextFormContainer = styled.div`
  margin-bottom: 16px;
`;

const styles = {
  actionContainer: css`
    background-color: #fff;
    border-top: 1px solid #eef3f7;
    display: flex;
    justify-content: flex-end;
    padding: 16px;
  `,
  container: css`
    margin: 16px 24px;

    @media (max-width: ${ViewportType.SMALL}px) {
      margin: 16px;
    }
  `,
  formBox: css`
    background-color: #fff;
    padding: 24px 24px 0 24px;
  `,
  formContainer: css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  `,
  navigatorContainer: css`
    margin-bottom: 24px;
  `,
  submitBtn: css`
    width: max-content;

    @media (max-width: ${ViewportType.SMALL}px) {
      border-radius: 5px;
      height: 40px;
      width: 100%;
    }
  `,
};

export default UserDetail;
