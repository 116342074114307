import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { ListIndicator } from '@src/components/molecules/Indicator';
import Empty from '@src/components/atoms/Empty';
import { useQueryHelper } from '@src/libs/hooks';
import { FanActivityCommentSchema } from '@src/libs/validation';
import { LIMIT } from '@src/libs/constant';
import { ThemeButton } from '@src/components/atoms/Button';
import { css } from '@emotion/core';
import Dialog from '@src/components/molecules/Dialog';
import { GetCreatorStaffs } from '@src/pages/Settings/Users/queries/__generated__/GetCreatorStaffs';
import * as GET_CREATOR_STAFFS from '@src/pages/Settings/Users/queries/GetCreatorStaffs.graphql';
import { Popover, MenuItem } from '@src/components/molecules/Popover';
import { getActivityList } from '../helper';
import ActivityRow from './ActivityRow';
import CommentForm, { FormValues } from './CommentForm';
import useGetFanActivities from './useGetFanActivities';
import useStateHandler from './useStateHandler';

const ActivityTimeline = () => {
  const { t, params } = useQueryHelper();
  const [creatorStaffs, setCreatorStaffs] = useState<{ [key: string]: string }>({});
  const { id } = params as { id: string };
  const { data, loading, fetchMore } = useGetFanActivities({ fanId: Number(id) });

  useQuery<GetCreatorStaffs>(GET_CREATOR_STAFFS, {
    onCompleted: ({ getCreatorStaffs }) => {
      const res =
        getCreatorStaffs?.staffs.reduce<{ [key: string]: string }>(
          (prev, curr) => ({ ...prev, [curr.id]: curr.name }),
          {
            [getCreatorStaffs.id]: getCreatorStaffs.name,
          }
        ) || {};

      setCreatorStaffs(res);
    },
  });

  const methods = useForm<{ comment: string }>({
    defaultValues: { comment: '' },
    resolver: yupResolver(FanActivityCommentSchema),
  });

  const {
    activity,
    handleAddComment,
    handleUpdateComment,
    handleClickEdit,
    handleClickDelete,
    handleClickMore,
    handleClose,
    handleDeleteConfirm,
  } = useStateHandler(Number(id));

  const handleViewMore = async () => {
    await fetchMore({
      variables: { input: { fanId: Number(id), offset: data?.getFanActivities?.activities.length || 0, limit: LIMIT } },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }

        return {
          ...prev,
          getFanActivities: {
            total: fetchMoreResult.getFanActivities?.total || 0,
            __typename: 'GetFanActivitiesPayload',
            activities: [
              ...(prev.getFanActivities?.activities || []),
              ...(fetchMoreResult.getFanActivities?.activities || []),
            ],
          },
        };
      },
    });
  };

  const onSubmit = (payload: FormValues) => {
    handleAddComment(payload);
    methods.reset();
  };

  if (loading) {
    return <ListIndicator />;
  }

  if (!data?.getFanActivities?.activities.length) {
    return <Empty />;
  }

  const total = data.getFanActivities.total || 0;
  const activities = getActivityList(data.getFanActivities.activities, t);

  return (
    <Container>
      {/* Delete confirmation dialog */}
      <Dialog
        visible={activity.showDeleteConfirmation}
        contentStyle={{ maxWidth: '400px' }}
        onClose={handleClose}
        onExec={handleDeleteConfirm}
        execText="Delete"
      >
        <Heading>{t('Confirmation required')}</Heading>
        <RegularText>{t('Are you sure you want to delete the activity?')}</RegularText>
      </Dialog>
      {/* Add new comment */}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <CommentForm />
        </form>
      </FormProvider>
      {/* List of activities */}
      <div>
        {activities.map((item, index) => (
          <ActivityRow
            key={(item.activity || '') + index}
            created={item.created}
            activity={item.activity}
            activityType={item.activityType}
            commenter={item.commenterId ? creatorStaffs?.[item.commenterId] || '' : ''}
            popoverNode={
              <Popover
                renderTrigger={() => (
                  <div>
                    <ThemeButton
                      skipTranslation
                      prefixIcon="&middot;&middot;&middot;"
                      width="max-content"
                      onClick={() => handleClickMore(item.id)}
                      css={css`
                        align-self: center;
                        border: none;

                        .prefix-icon {
                          font-size: 24px;
                        }
                      `}
                    />
                  </div>
                )}
                renderButtonBlock={() => null}
              >
                <div css={{ minWidth: '80px' }}>
                  <MenuItem onClick={handleClickEdit}>{t('Edit')}</MenuItem>
                  <MenuItem onClick={handleClickDelete}>{t('Delete')}</MenuItem>
                </div>
              </Popover>
            }
            showCommentInsideForm={activity.showCommentInsideForm && item.id === activity.id}
            onUpdate={handleUpdateComment}
          />
        ))}
      </div>
      {data.getFanActivities.activities.length < total && (
        <ButtonWrapper>
          <ThemeButton
            skipTranslation
            prefixIcon="&middot;&middot;&middot;"
            width="max-content"
            onClick={handleViewMore}
            css={css`
              align-self: center;
              border: none;

              .prefix-icon {
                font-size: 24px;
              }
            `}
          />
        </ButtonWrapper>
      )}
    </Container>
  );
};

const Heading = styled.h1`
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #27313b;
`;
const RegularText = styled.p`
  font-size: 14px;
  color: #27313b;
  margin-bottom: 16px;
`;
const Container = styled.div`
  position: relative;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  background: linear-gradient(180deg, rgba(246, 248, 250, 0) 0%, #fff 49.4%);
  height: 92px;
  bottom: -40px;
  position: absolute;
  width: 100%;
  align-items: center;
`;

export default ActivityTimeline;
