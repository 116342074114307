import axios from 'axios';
import styled from '@emotion/styled';
import React, { useState, ReactNode, forwardRef, useImperativeHandle } from 'react';
import { SerializedStyles } from '@emotion/react';
import { useQueryHelper } from '@src/libs/hooks';
import { getToken } from '@src/libs/auth';
import DropArea from './DropArea';
import { DEFAULT_UPLOAD_LIMIT, VIDEO_UPLOAD_LIMIT } from './helpers';
import Preview from './Preview';
import { UploadedFile } from './types';
// Types
export interface DragAndDropProps {
  notes: string[];
  value?: string[];
  initialFileUrls?: UploadedFile[];
  title?: string;
  multiple?: boolean;
  accept?: string | string[];
  description?: React.ReactNode | string;
  error?: boolean;
  disabled?: boolean;
  customLabel?: ReactNode;
  isHidden?: boolean;
  className?: string;
  showPreview?: boolean;
  previewWidth?: string;
  previewCss?: SerializedStyles;
  dropAreaCss?: SerializedStyles;
  uploadOnDrop?: boolean;
  uploadFileName?: string;

  uploadURL?: string;
  isGCSUpload?: boolean;
  generateSignedUrl?: (filename: string) => Promise<{ fileName: string; signedUrl: string } | null>;
  onFileChange?: (values: string[]) => void;
  onFileUploaded?: () => void;
  onUploadStatusChange?: (isUploading: boolean) => void;
}

const FileDragDropComponent = forwardRef((props: DragAndDropProps, ref) => {
  const { t, enqueueSnackbar } = useQueryHelper();
  useImperativeHandle(ref, () => ({
    requestUpload: () => {
      requestUpload();
    },
    newFile,
  }));
  const {
    initialFileUrls = [],
    value = [],
    description,
    title,
    isGCSUpload = false,
    uploadFileName,
    uploadURL,
    uploadOnDrop,
    multiple = false,
    customLabel,
    className,
    previewCss,
    dropAreaCss,
    showPreview = false,
    generateSignedUrl,
    onFileChange = () => null,
    onFileUploaded,
    onUploadStatusChange,
  } = props;

  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>(initialFileUrls);
  const [newFile, setNewFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState<{ progressUrl: string | null; progress: number }>({
    progressUrl: null,
    progress: 0,
  });

  const uploadFile = async (url: string, accepted: File) => {
    let newUploadedFiles = [];

    if (multiple) {
      newUploadedFiles = [...uploadedFiles, { url: URL.createObjectURL(accepted), preview: '' }];
      onFileChange([...value, accepted.name]);
    } else {
      newUploadedFiles = [{ url: URL.createObjectURL(accepted), preview: '' }];
      onFileChange([accepted.name]);
    }

    setUploadedFiles(newUploadedFiles);

    if (onUploadStatusChange) {
      onUploadStatusChange(true);
    }

    try {
      const formData = new FormData();
      formData.append(uploadFileName || '', accepted);
      await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
          authorization: getToken(),
        },
        onUploadProgress: p => {
          setUploadProgress({ progress: (p.loaded / p.total) * 100, progressUrl: accepted.name });
        },
      });

      setUploadProgress({ progress: 100, progressUrl: null });
      enqueueSnackbar(t('fileUploadSuccess'), { variant: 'success' });
      if (onFileUploaded) {
        onFileUploaded();
      }
    } catch (error) {
      enqueueSnackbar(t('fileUploadFail'), { variant: 'error' });
      setUploadedFiles(uploadedFiles);
    }
  };

  const uploadFileToGCS = async ({ signedUrl }: { fileName: string; signedUrl: string }, accepted: File) => {
    const videoUrlName = signedUrl.split('?')[0];
    const preview = URL.createObjectURL(accepted);

    let newUploadedFiles = [];

    if (multiple) {
      newUploadedFiles = [...uploadedFiles, { url: videoUrlName, preview }];
      onFileChange([...value, videoUrlName]);
    } else {
      newUploadedFiles = [{ url: videoUrlName, preview }];
      onFileChange([videoUrlName]);
    }

    setUploadedFiles(newUploadedFiles);

    if (onUploadStatusChange) {
      onUploadStatusChange(true);
    }

    try {
      await axios(signedUrl, {
        method: 'PUT',
        data: accepted,
        onUploadProgress: p => {
          setUploadProgress({ progress: (p.loaded / p.total) * 100, progressUrl: videoUrlName });
        },
      });

      setUploadProgress({ progress: 100, progressUrl: null });
      enqueueSnackbar(t('fileUploadSuccess'), { variant: 'success' });
      if (onFileUploaded) {
        onFileUploaded();
      }
    } catch (error) {
      enqueueSnackbar(t('fileUploadFail'), { variant: 'error' });
      setUploadedFiles(uploadedFiles);
    }

    if (onUploadStatusChange) {
      onUploadStatusChange(false);
    }
  };

  const onDrop = async (accepted: File[]) => {
    const acceptedFile = accepted && accepted.length ? accepted[0] : null;
    const isVideoFile = acceptedFile && ['video/avi', 'video/mp4', 'video/quicktime'].includes(acceptedFile.type);
    const sizeLimit = isVideoFile ? VIDEO_UPLOAD_LIMIT : DEFAULT_UPLOAD_LIMIT;

    if (acceptedFile && acceptedFile.size > sizeLimit) {
      enqueueSnackbar(t('General.UploadSizeError'), { variant: 'error' });

      return;
    }

    setNewFile(acceptedFile);

    if (uploadOnDrop) {
      await requestUpload();
    }
  };

  const requestUpload = async () => {
    if (!newFile) {
      return;
    }

    if (isGCSUpload && generateSignedUrl) {
      const generatedSignedUrl = await generateSignedUrl(newFile.name);
      if (generatedSignedUrl) {
        await uploadFileToGCS(generatedSignedUrl, newFile);
      }
    }
    if (!isGCSUpload && uploadURL) {
      await uploadFile(uploadURL, newFile);
    }
  };

  const deleteUploadedFile = (index: number) => () => {
    const newUploadedFiles = uploadedFiles.filter((_, i) => i !== index);
    const newMaterialsValue = value.filter(materialUrl => materialUrl !== uploadedFiles[index].url);

    setUploadedFiles(newUploadedFiles);
    onFileChange(newMaterialsValue);

    return;
  };

  return (
    <Container>
      <Title>{t(title || '')}</Title>
      <Description>{description}</Description>
      <Wrapper className={className}>
        {!props.isHidden && (
          <DropAreaWrapper css={dropAreaCss}>
            {!!customLabel && customLabel}
            <DropArea
              notes={props.notes}
              multiple={false}
              accept={props.accept}
              onDrop={onDrop}
              disabled={!!props.disabled}
            />
          </DropAreaWrapper>
        )}
        {showPreview && (
          <Preview
            uploadedFiles={uploadedFiles}
            deleteUploadedFile={deleteUploadedFile}
            previewWidth={props.previewWidth}
            disabled={props.disabled}
            uploadProgress={uploadProgress}
            previewCss={previewCss}
          />
        )}
      </Wrapper>
    </Container>
  );
});
FileDragDropComponent.displayName = 'FileDragDropComponent';

const Container = styled.div`
  min-width: 500px;
`;

const Title = styled.h1`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 24px;
`;

const Description = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 8px;
`;
const Wrapper = styled.div``;
const DropAreaWrapper = styled.div``;

export default FileDragDropComponent;
