import { useState } from 'react';
import { useQueryHelper } from '@src/libs/hooks';
import { setUrlParams } from '@src/libs/url';
import { EmailListSortableField, EmailTemplateStatus, Order } from '@src/__generated__/globalTypes';

export interface FilterItems {
  keyword: string;
  orderByField: EmailListSortableField;
  orderBySequence: Order;
  status: EmailTemplateStatus;
}

const useFilter = () => {
  const { history, pathname, search } = useQueryHelper();
  const searchParams = new URLSearchParams(search);
  const initialFilter = {
    keyword: searchParams.get('keyword') || '',
    orderByField: (searchParams.get('orderByField') as EmailListSortableField) || EmailListSortableField.CREATED,
    orderBySequence: (searchParams.get('orderBySequence') as Order) || Order.DESC,
    status: (searchParams.get('status') || '') as EmailTemplateStatus,
  };

  const [filter, setInitialFilter] = useState<FilterItems>(initialFilter);

  const setFilter = (values: FilterItems) => {
    setInitialFilter(values);
    setUrlParams(history, pathname, search, values);
  };

  return {
    filter,
    setFilter,
  };
};

export default useFilter;
