import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import formsEmptyListBg from '@src/assets/img/formsEmptyListBg.svg';

const EmptyList = () => {
  const { t } = useTranslation();

  return (
    <div css={styles.container}>
      <div>
        <div>
          <img alt="bgImg" height="180" src={formsEmptyListBg} width="180" />
        </div>
        <div>{t(`Let's add Form`)}</div>
        <div>{t('Create a form and get to know your fans better')}</div>
        <div>
          <Link to="forms/add">{t('Button.Add Form')}</Link>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: css`
    align-items: center;
    background-color: #fff;
    border-radius: 0 0 3px 3px;
    display: flex;
    justify-content: center;
    padding: 126px 0;

    & > div {
      & > div:nth-of-type(1) {
        display: flex;
        justify-content: center;
        margin-bottom: 24px;
      }

      & > div:nth-of-type(2) {
        color: #000;
        display: flex;
        font-size: 16px;
        font-weight: 600;
        justify-content: center;
        margin-bottom: 24px;
      }

      & > div:nth-of-type(3) {
        color: #000;
        display: flex;
        font-size: 14px;
        justify-content: center;
        margin-bottom: 24px;
      }

      & > div:nth-of-type(4) {
        display: flex;
        justify-content: center;

        & > a {
          align-items: center;
          background-color: #3892e5;
          border-radius: 3px;
          color: #fff;
          display: flex;
          font-size: 12px;
          font-weight: 600;
          height: 32px;
          justify-content: center;
          width: 97px;
        }
      }
    }
  `,
};

export default EmptyList;
