import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ThemeButton } from '@src/components/atoms/Button';
import Icomoon from '@src/components/atoms/Icomoon';
import { RadioIcon } from '@src/components/atoms/RadioButtonElement';
import TextForm from '@src/components/molecules/TextForm';
import { useQueryHelper } from '@src/libs/hooks';
import { useCopy } from '@src/libs/hooks/useCopy';
import { FormStatus } from '@src/__generated__/globalTypes';
import { mainBlack } from '@src/libs/pallete';
import { DialogModal } from '@src/components/molecules/DialogModal';
import UPDATE_FORM_STATUS from './mutation/UpdateFormStatus.graphql';
import { UpdateFormStatus, UpdateFormStatusVariables } from './mutation/__generated__/UpdateFormStatus';

interface ShareButtonProps {
  hash: string;
  id: string;
  status: FormStatus;
}

const ShareButton = ({ hash, id, status }: ShareButtonProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const isShareOptions = [FormStatus.PRIVATE, FormStatus.PUBLIC].includes(status);
  const [selectedOption, setSelectedOption] = useState<FormStatus>(isShareOptions ? status : FormStatus.PRIVATE);
  const { enqueueSnackbar, t } = useQueryHelper();
  const { handleCopyHasText, isCopying } = useCopy();
  const formUrl = `${origin}/form/${hash}`;
  const options = [
    { description: 'Annotation.View only by me', value: FormStatus.PRIVATE },
    { description: 'Annotation.Anyone can access the internet', value: FormStatus.PUBLIC },
  ];

  useEffect(() => {
    setSelectedOption(isShareOptions ? status : FormStatus.PRIVATE);
  }, [status]);

  const [updateFormStatus] = useMutation<UpdateFormStatus, UpdateFormStatusVariables>(UPDATE_FORM_STATUS, {
    refetchQueries: ['GetForm'],
    onCompleted: () => {
      enqueueSnackbar(t('succeededInUpdating'), { variant: 'success' });
      setIsDialogOpen(false);
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const onClickSave = () => {
    setIsUpdating(true);
    updateFormStatus({
      variables: {
        input: {
          id: Number(id),
          status: selectedOption,
        },
      },
    }).finally(() => setIsUpdating(false));
  };

  return (
    <div>
      <DialogModal
        contentStyle={styles.dialog}
        isOpen={isDialogOpen}
        closeModal={() => setIsDialogOpen(false)}
        footerNode={
          <div css={styles.dialogAction}>
            <ThemeButton text="Cancel" width="man-content" onClick={() => setIsDialogOpen(false)} />
            <ThemeButton theme="blue" disabled={isUpdating} text="Save" width="man-content" onClick={onClickSave} />
          </div>
        }
      >
        <>
          <div css={styles.dialogContent}>
            <div>{t('Dialog.Share the form')}</div>
            <div>{t('Public Settings')}</div>
            <div css={styles.dialogOptionsContainer}>
              {options.map(option => {
                const { description, value } = option;
                const isActive = value === selectedOption;

                return (
                  <Option isActive={isActive} key={value}>
                    <div>
                      <RadioIcon isChecked={isActive} onClick={() => setSelectedOption(value)} />
                    </div>
                    <div>
                      <div>
                        <div>{t(value)}</div>
                        <div>{t(description)}</div>
                      </div>
                    </div>
                  </Option>
                );
              })}
            </div>

            {selectedOption === FormStatus.PUBLIC && (
              <div css={styles.publicLinkContainer}>
                <div>{t('Annotation.Get the link')}</div>
                <div>
                  <StyledTextForm disabled placeholder="" value={formUrl} />
                  <ThemeButton
                    disabled={isCopying}
                    text="Copy"
                    width="man-content"
                    onClick={() => handleCopyHasText(formUrl)}
                  />
                </div>
              </div>
            )}
          </div>
        </>
      </DialogModal>
      <ThemeButton
        disabled={!id}
        prefixIcon={<Icomoon color="#fff" icon="share" />}
        text="Share"
        width="max-content"
        customPalette={mainBlack}
        onClick={() => setIsDialogOpen(true)}
        css={css`
          .btn-text {
            padding: 0 8px;
          }
        `}
      />
    </div>
  );
};

const Option = styled.div<{ isActive: boolean }>`
  background-color: ${({ isActive }) => (isActive ? '#eaf5ff' : '#fff')};
  border: 1px solid ${({ isActive }) => (isActive ? '#3892e5' : '#dee5ec')};
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  height: 64px;
  width: fill-available;

  & > div:nth-of-type(1) {
    align-items: center;
    display: flex;
    flex-basis: 15%;
    justify-content: center;
  }

  & > div:nth-of-type(2) {
    align-items: center;
    display: flex;
    flex-basis: 85%;

    & > div {
      & > div:nth-of-type(1) {
        color: #27313b;
        font-size: 14px;
        font-weight: 600;
      }

      & > div:nth-of-type(2) {
        color: #6e7c89;
        font-size: 12px;
      }
    }
  }
`;

const StyledTextForm = styled(TextForm)`
  & > label {
    font-size: 14px;
    font-weight: 600;
  }

  & input {
    border-radius: 3px;
    height: 32px;
  }
`;

const styles = {
  dialog: {
    borderRadius: '5px',
    boxShadow: '0 0 12px rgba(0, 0, 0, 0.12), 0 12px 12px rgba(0, 0, 0, 0.24)',
    maxWidth: 648,
    padding: 0,
    width: '90%',
  },
  dialogAction: css`
    border-top: 1px solid #dee5ec;
    display: flex;
    justify-content: flex-end;
    padding: 16px;

    & > button:nth-of-type(2) {
      margin-left: 16px;
    }
  `,
  dialogContent: css`
    padding: 24px;

    /* stylelint-disable no-descending-specificity */
    & > div:nth-of-type(1) {
      color: ##27313b;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 24px;
    }

    & > div:nth-of-type(2) {
      color: #27313b;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 16px;
    }
  `,
  dialogOptionsContainer: css`
    display: flex;

    & > div:nth-of-type(1) {
      margin-right: 8px;
    }

    & > div:nth-of-type(2) {
      margin-left: 8px;
    }
  `,
  publicLinkContainer: css`
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px;

    & > div:nth-of-type(1) {
      color: #27313b;
      display: flex;
      flex-basis: 100%;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 16px;
    }

    & > div:nth-of-type(2) {
      display: flex;
      flex-basis: 100%;
      flex-wrap: wrap;

      & > div:nth-of-type(1) {
        display: flex;
        flex: 1;
        margin-right: 8px;

        & > div {
          width: fill-available;
        }
      }
    }
  `,
  shareBtn: css`
    border-radius: 3px;
    height: 32px;
    width: 96px;
  `,
};

export default ShareButton;
