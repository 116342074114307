import styled from '@emotion/styled';
import { LocationDescriptor } from 'history';
import React, { ReactElement, ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface MultiTextLinkColumnProps {
  className?: string;
  href: LocationDescriptor;
  data: ReactNode | string;
  children?: ReactElement<any>;
}

const MultiTextLinkColumn = (props: MultiTextLinkColumnProps) => {
  const { className, href, data, children } = props;

  return (
    <TableElement className={className}>
      <Wrapper title={typeof data === 'string' ? data : undefined}>
        <TableData to={href}>{data}</TableData>
      </Wrapper>
      {children && <ChildrenWrapper>{children}</ChildrenWrapper>}
    </TableElement>
  );
};

const TableElement = styled.td`
  padding: 0 8px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TableData = styled(Link)`
  overflow: hidden;
  max-height: 32px;
  font-size: 13px;
  line-height: 16px;
  word-break: break-word;
  text-overflow: ellipsis;
`;

const ChildrenWrapper = styled.div`
  margin: 10px 0;
`;

export default MultiTextLinkColumn;
