import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import emptyImage from '@src/assets/img/emptyImage.jpg';
import Avatar from '@src/components/atoms/Avatar';
import { ThemeButton } from '@src/components/atoms/Button';
import Textarea from '@src/components/atoms/Textarea';
import Carousel from '@src/components/molecules/Carousel';
import { switchImage } from '@src/libs/SocialMedia';
import { ViewportType } from '@src/libs/theme';
import { useCopy } from '@src/libs/hooks/useCopy';
import { usePageLayout } from '@src/libs/hooks';
import { CampaignPostDetailsHistory_engagementPostDetailForInfluencer } from './queries/__generated__/CampaignPostDetailsHistory';

interface PostDetailsProps {
  postDetails: CampaignPostDetailsHistory_engagementPostDetailForInfluencer;
}

const PostDetails = ({ postDetails }: PostDetailsProps) => {
  const { t } = useTranslation();
  const { handleCopyHasText, isCopying } = useCopy();
  const { isMobileView } = usePageLayout();

  return (
    <div css={styles.container}>
      {isMobileView && (
        <div css={styles.infoHeader}>
          <div>
            <img alt="socialMedia" height="24" src={switchImage(postDetails.socialMedia, true)} width="24" />
          </div>
          <div>
            <Avatar
              size={35}
              src={postDetails.engagementPostDetailInfluencer.avatar}
              title={postDetails.engagementPostDetailInfluencer.name}
            />
          </div>
          <div>
            <span>{postDetails.engagementPostDetailInfluencer.name}</span>
            <span>{`${postDetails.engagementPostDetailInfluencer.followersCount} Followers`}</span>
          </div>
        </div>
      )}

      <div css={styles.imagesContainer}>
        <div>
          <Carousel height="276px" sources={postDetails.images || [emptyImage]} width="100%" />
        </div>
      </div>
      <div css={styles.infoContainer}>
        {!isMobileView && (
          <div css={styles.infoHeader}>
            <div>
              <img alt="socialMedia" height="24" src={switchImage(postDetails.socialMedia, true)} width="24" />
            </div>
            <div>
              <Avatar
                size={35}
                src={postDetails.engagementPostDetailInfluencer.avatar}
                title={postDetails.engagementPostDetailInfluencer.name}
              />
            </div>
            <div>
              <span>{postDetails.engagementPostDetailInfluencer.name}</span>
              <span>{`${postDetails.engagementPostDetailInfluencer.followersCount} Followers`}</span>
            </div>
          </div>
        )}

        <div css={styles.infoContent}>
          <div>
            <div>{t('Post Caption')}</div>
            <div>
              <ThemeButton
                css={styles.copyBtn}
                disabled={isCopying}
                size="small"
                text="Copy"
                width="fit-content"
                onClick={() => handleCopyHasText(postDetails.caption)}
              />
            </div>
          </div>
          <div>
            <Textarea value={postDetails.caption} disabled placeholder="" />
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: css`
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    padding: 16px;
  `,
  copyBtn: css`
    border-radius: 5px;
  `,
  imagesContainer: css`
    width: 50%;

    & > div {
      padding-right: 24px;

      @media (max-width: ${ViewportType.MEDIUM}px) {
        padding-right: 0;
      }
    }

    & .slick-arrow {
      border-radius: 50%;
      box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
      height: 32px !important;
      position: absolute;
      width: 32px !important;

      & > i {
        margin-top: 1px;
      }

      &.slick-prev {
        left: -15px !important;
      }

      &.slick-next {
        right: -15px !important;
      }
    }

    & .slick-dots li button::before {
      color: #3892e5 !important;
      font-size: 11px !important;
    }

    @media (max-width: ${ViewportType.MEDIUM}px) {
      width: 100%;
    }
  `,
  infoContainer: css`
    display: flex;
    flex-wrap: wrap;
    width: 50%;

    @media (max-width: ${ViewportType.MEDIUM}px) {
      margin-top: 16px;
      width: 100%;
    }
  `,
  infoContent: css`
    display: flex;
    flex-basis: 100%;
    flex-wrap: wrap;

    & > div:nth-of-type(1) {
      align-items: center;
      display: flex;
      flex-basis: 100%;
      flex-wrap: wrap;

      & > div:nth-of-type(1) {
        color: #27313b;
        display: flex;
        flex-basis: 50%;
        font-size: 13px;
        font-weight: 600;
      }

      & > div:nth-of-type(2) {
        display: flex;
        flex-basis: 50%;
        justify-content: flex-end;
      }
    }

    /* stylelint-disable no-descending-specificity */
    & > div:nth-of-type(2) {
      display: flex;
      flex-basis: 100%;

      & > textarea {
        background-color: #fff;
        border: none;
        height: 200px;
      }
    }
  `,
  infoHeader: css`
    align-items: center;
    display: flex;
    flex-basis: 100%;
    flex-wrap: wrap;
    margin-bottom: 16px;

    & > div {
      display: flex;
      margin-right: 8px;
    }

    & > div:nth-of-type(3) {
      display: grid;

      & > span:nth-of-type(1) {
        color: #27313b;
        font-size: 14px;
      }

      & > span:nth-of-type(2) {
        color: #6e7c89;
        font-size: 12px;
      }
    }
  `,
};

export default PostDetails;
