import React from 'react';
import { NavLink } from 'react-router-dom';
import { css } from '@emotion/core';
import Icomoon from '@src/components/atoms/Icomoon';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';

const FanNavigation = () => {
  const { t, params } = useQueryHelper();
  const { id } = params as { id: string };
  const { isMobileView } = usePageLayout();

  const navOptions = [
    {
      to: `/fans/${id}/details`,
      title: t(`MenuTitle.Fan's Details`),
      icon: 'user-grey',
    },
    {
      to: `/fans/${id}/activity`,
      title: t('MenuTitle.Activity'),
      icon: 'address-book-grey-light',
    },
    {
      to: `/fans/${id}/orders`,
      title: t('MenuTitle.Orders'),
      icon: 'link-grey-light',
    },
    {
      to: `/fans/${id}/forms`,
      title: t('MenuTitle.Forms'),
      icon: 'users-grey-light',
    },
  ];

  return (
    <div css={[styles.wrapper, isMobileView && styles.mobileWrapper]}>
      {navOptions.map(item => (
        <NavLink
          css={[styles.navlink, isMobileView && styles.mobileNavlink]}
          activeClassName="selected"
          to={item.to}
          key={item.to}
        >
          {isMobileView && <Icomoon css={styles.icon} icon={item.icon} size={24} />}
          <span>{t(item.title)}</span>
          {isMobileView && <Icomoon css={styles.accordion} icon="arrow-down" />}
        </NavLink>
      ))}
    </div>
  );
};

const styles = {
  wrapper: css`
    display: flex;
    border-bottom: 1px solid #eef3f7;
    padding-left: 16px;

    span {
      flex: 1;
    }
  `,
  mobileWrapper: css`
    flex-direction: column;
    padding-left: 0;
    border: 0;
  `,
  accordion: css`
    transform: rotate(-90deg);
    width: 16px;
    height: 16px;
    margin: 0;
  `,
  icon: css`
    margin-right: 16px;
  `,
  navlink: css`
    min-width: 70px;
    color: #27313b;
    padding: 14px 8px;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    margin-right: 16px;

    &.selected {
      border-bottom: 4px solid #3892e5;
    }
  `,
  mobileNavlink: css`
    border: 1px solid #dee5ec;
    margin-bottom: 16px;
    border-radius: 5px;
    text-align: left;
    display: flex;
    align-items: center;
    padding: 20px 16px;
    background-color: #fff;
    margin-right: 0;
  `,
};

export default FanNavigation;
