import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import Grid from '@src/components/atoms/Grid';
import TextForm from '@src/components/molecules/TextForm';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import { ViewportType } from '@src/libs/theme';
import { FormSubmitSection } from '@src/components/shared/FormSubmitSection';
import { ROUTES } from '@src/shared/routes';

export interface AddressInformation {
  address: string;
  address2: string;
  city: string;
  fullName: string;
  postalCode: string;
  province: string;
}

const Address = () => {
  const { t } = useTranslation();
  const isDesktopView = useMediaQuery({ query: `(min-width: ${ViewportType.TABLET}px)` });
  const {
    formState: { errors, isSubmitting },
    register,
  } = useFormContext<AddressInformation>();

  return (
    <div css={styles.container}>
      {isDesktopView && <BackNavigator to={ROUTES.SETTINGS} title="Address" />}

      <div css={styles.description}>{t('Annotation.View and edit the address information to ship products')}</div>

      <div css={styles.formContainer}>
        <Grid md={8} sm={10} xs={12}>
          <div css={styles.formInputSection}>
            <InputContainer>
              <StyledTextForm
                error={!!errors.fullName}
                isRequired
                title="Settings Address Full Name"
                placeholder="Full Name"
                {...register('fullName')}
              />
              <ErrorMessage message={errors.fullName?.message} />
            </InputContainer>

            <InputContainer>
              <StyledTextForm
                error={!!errors.postalCode}
                isRequired
                title="Postal Code"
                placeholder="Postal Code"
                {...register('postalCode')}
              />
              <ErrorMessage message={errors.postalCode?.message} />
            </InputContainer>

            <InputContainer>
              <StyledTextForm
                error={!!errors.province}
                isRequired
                title="Province"
                placeholder="Province"
                {...register('province')}
              />
              <ErrorMessage message={errors.province?.message} />
            </InputContainer>

            <InputContainer>
              <StyledTextForm error={!!errors.city} isRequired title="City" placeholder="City" {...register('city')} />
              <ErrorMessage message={errors.city?.message} />
            </InputContainer>

            <InputContainer>
              <StyledTextForm
                error={!!errors.address}
                isRequired
                title="Address"
                placeholder="Address"
                {...register('address')}
              />
              <ErrorMessage message={errors.address?.message} />
            </InputContainer>

            <InputContainer>
              <StyledTextForm
                error={!!errors.address2}
                isRequired
                title="Apartment, suite, etc"
                placeholder="Apartment, Suite, etc"
                {...register('address2')}
              />
              <ErrorMessage message={errors.address2?.message} />
            </InputContainer>
          </div>

          <FormSubmitSection isSubmitting={isSubmitting} />
        </Grid>
      </div>
    </div>
  );
};

const InputContainer = styled.div`
  margin-bottom: 24px;
`;

const StyledTextForm = styled(TextForm)`
  & > label {
    font-size: 14px;
  }

  & input {
    border-radius: 2px;
    height: 32px;
  }
`;

const styles = {
  container: css`
    margin: 16px 24px;

    @media (max-width: ${ViewportType.TABLET}px) {
      margin: 16px;
    }
  `,
  description: css`
    color: #27313b;
    font-size: 14px;
    margin-bottom: 24px;
    margin-left: 48px;

    @media (max-width: ${ViewportType.TABLET}px) {
      margin-left: 0;
    }
  `,
  formContainer: css`
    display: flex;
    justify-content: center;
  `,
  formInputSection: css`
    background-color: #fff;
    padding: 16px;
  `,
};

export default Address;
