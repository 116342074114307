import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Grid from '@src/components/atoms/Grid';
import VideoPreview from '@src/components/atoms/VideoPreview';
import { formatNumberWithCommas } from '@src/libs/format';
import { defaultEmptyImage, isVideoThumbnail } from '@src/libs/image';
import { switchText } from '@src/libs/SocialMedia';
import { ViewportType } from '@src/libs/theme';
import {
  AnyXEngagementCampaignStatus,
  AnyXMarketplaceCampaignStatus,
  CampaignSocialMediaType,
  CampaignType,
  EngagementCampaignPostStatus,
  MarketplaceCampaignPostStatus,
} from '@src/__generated__/globalTypes';
import { usePageLayout } from '@src/libs/hooks';
import StatusStepper from '../StatusStepper';
import ReasonWidget from './ReasonWidget';

interface PostReport {
  anyXStatus: AnyXEngagementCampaignStatus | AnyXMarketplaceCampaignStatus | null;
  click: number | null;
  comment: number;
  conversion: number | null;
  id: number;
  inappropriateDetailedReason?: string | null;
  inappropriateReason?: string | null;
  like: number;
  postCaption: string;
  postSocialMedia: CampaignSocialMediaType;
  postUrl: string;
  share: number;
  status: EngagementCampaignPostStatus | MarketplaceCampaignPostStatus;
  thumbNail: string | null;
  view: number;
}

export interface CampaignPostReportsProps {
  campaignPostReports: {
    currency: string;
    id: number;
    postReport: PostReport[];
    revenue?: number;
    title: string;
  };
  campaignType: CampaignType;
}

const CampaignPostReports = ({ campaignPostReports, campaignType }: CampaignPostReportsProps) => {
  const { currency, postReport, revenue, title } = campaignPostReports;
  const { t } = useTranslation();
  const { isMobileView } = usePageLayout();

  return (
    <div css={styles.reportContainer}>
      <Grid css={styles.infoContainer} lg={8} md={10} xs={12}>
        <div>
          <div css={styles.titleContainer}>
            <div css={styles.title}>{title}</div>

            {revenue && (
              <div css={styles.revenueContainer}>
                <div>{t('Revenue')}</div>
                <div>{`${formatNumberWithCommas(revenue)} ${currency}`}</div>
              </div>
            )}
          </div>

          {postReport.map(post => {
            const {
              anyXStatus,
              click,
              comment,
              conversion,
              like,
              inappropriateDetailedReason,
              inappropriateReason,
              postCaption,
              postSocialMedia,
              postUrl,
              share,
              thumbNail,
              view,
            } = post;
            const showWarning = inappropriateDetailedReason && inappropriateReason;

            return (
              <div css={styles.postContainer} key={post.id}>
                {!isMobileView && showWarning && (
                  <ReasonWidgetContainer>
                    <ReasonWidget content={inappropriateDetailedReason || ''} title={inappropriateReason || ''} />
                  </ReasonWidgetContainer>
                )}

                <div css={styles.postHeader}>{t('Post Status')}</div>
                {anyXStatus && (
                  <div css={styles.stepper}>
                    <StatusStepper status={anyXStatus} type={campaignType} />
                  </div>
                )}

                {isMobileView && showWarning && (
                  <ReasonWidgetContainer>
                    <ReasonWidget content={inappropriateDetailedReason || ''} title={inappropriateReason || ''} />
                  </ReasonWidgetContainer>
                )}

                <div css={styles.contentContainer}>
                  <div>
                    <div>
                      {isVideoThumbnail(thumbNail) ? (
                        <VideoPreview height="85" url={thumbNail} width="151" />
                      ) : (
                        <img alt="postImg" height="85" src={defaultEmptyImage(thumbNail)} width="151" />
                      )}
                    </div>
                  </div>
                  <div>
                    <span>{postCaption}</span>
                    <Link to={{ pathname: postUrl }} target="_blank">{`View on ${switchText(postSocialMedia)}`}</Link>
                  </div>
                </div>

                <div css={styles.engagementContainer}>
                  <EngagementInfo sm={2} xs={4}>
                    <EngagementTitle>
                      {t(postSocialMedia === CampaignSocialMediaType.FACEBOOK ? 'Reactions' : 'Like')}
                    </EngagementTitle>
                    <EngagementValue>{like}</EngagementValue>
                  </EngagementInfo>
                  <EngagementInfo sm={2} xs={4}>
                    <EngagementTitle>{t('Comments')}</EngagementTitle>
                    <EngagementValue>{comment}</EngagementValue>
                  </EngagementInfo>
                  <EngagementInfo sm={2} xs={4}>
                    <EngagementTitle>{t('Share')}</EngagementTitle>
                    <EngagementValue>{share}</EngagementValue>
                  </EngagementInfo>
                  <EngagementInfo sm={2} xs={4}>
                    <EngagementTitle>{t('Click')}</EngagementTitle>
                    <EngagementValue>{click !== null ? click : '-'}</EngagementValue>
                  </EngagementInfo>
                  <EngagementInfo sm={2} xs={4}>
                    <EngagementTitle>{t('Conversion')}</EngagementTitle>
                    <EngagementValue>{conversion !== null ? conversion : '-'}</EngagementValue>
                  </EngagementInfo>
                  <EngagementInfo sm={2} xs={4}>
                    <EngagementTitle>{t('View')}</EngagementTitle>
                    <EngagementValue>{view}</EngagementValue>
                  </EngagementInfo>
                </div>
              </div>
            );
          })}
        </div>
      </Grid>
    </div>
  );
};

const EngagementInfo = styled(Grid)`
  outline: 1px solid #dee5ec;
`;

const EngagementTitle = styled.div`
  align-items: center;
  background-color: #f6f8fa;
  color: #27313b;
  display: flex;
  font-size: 13px;
  font-weight: 600;
  height: 39px;
  justify-content: center;
  width: 100%;
`;

const EngagementValue = styled.div`
  align-items: center;
  color: #27313b;
  display: flex;
  font-size: 13px;
  height: 39px;
  justify-content: center;
  width: 100%;
`;

const ReasonWidgetContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const styles = {
  contentContainer: css`
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;

    & > div:nth-of-type(1) {
      height: 85px;
      max-width: 167px;
      width: 50%;

      & > div {
        background-color: #000;
        margin-right: 16px;

        & > img {
          object-fit: contain;
          width: 100%;
        }
      }
    }

    /* stylelint-disable no-descending-specificity, value-no-vendor-prefix, property-no-vendor-prefix */
    & > div:nth-of-type(2) {
      position: relative;
      width: 65%;

      & > span {
        color: #27313b;
        display: -webkit-box;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      & > a {
        bottom: 5px;
        color: #3892e5;
        position: absolute;
        text-decoration: underline;
      }

      @media (max-width: ${ViewportType.MEDIUM}px) {
        width: 50%;
      }
    }
  `,
  engagementContainer: css`
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
  `,
  infoContainer: css`
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
  `,
  postContainer: css`
    border-top: 1px solid #eef3f7;
    padding: 24px;
  `,
  postHeader: css`
    color: #27313b;
    font-size: 14px;
    font-weight: 600;
  `,
  reportContainer: css`
    display: flex;
    justify-content: center;

    @media (min-width: ${ViewportType.TABLET}px) {
      margin-top: 16px;
    }
  `,
  revenueContainer: css`
    color: #27313b;
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    margin-top: 8px;

    & > div:nth-of-type(1) {
      display: flex;
      flex-basis: 50%;
    }

    & > div:nth-of-type(2) {
      display: flex;
      flex-basis: 50%;
      justify-content: flex-end;
    }
  `,
  stepper: css`
    & > div {
      width: 50%;

      @media (max-width: ${ViewportType.MEDIUM}px) {
        border-bottom: 1px solid #eef3f7;
        margin-bottom: 16px;
        padding-bottom: 24px;
        width: auto;
      }
    }
  `,
  title: css`
    color: #27313b;
    font-size: 16px;
    font-weight: 600;
  `,
  titleContainer: css`
    padding: 24px;
  `,
};

export default CampaignPostReports;
