import styled from '@emotion/styled';
import { css, Global } from '@emotion/react';
import { format, isAfter, isBefore, isSameDay } from 'date-fns';
import React, { useEffect, useState } from 'react';
import DayPicker, { Modifier } from 'react-day-picker';
import classNames from 'react-day-picker/src/classNames';
import 'react-day-picker/lib/style.css';
import { useTranslation } from 'react-i18next';
import { localizedDateFormatter } from '@src/components/atoms/List/DateColumn';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import { localeUtils } from '@src/libs/date';
import { useDirLayout } from '@src/libs/hooks';
import { MenuControlIcon } from '@src/components/atoms/MenuControlIcon/MenuControlIcon';
import { ThemeButton } from '@src/components/atoms/Button';
import Icomoon from '@src/components/atoms/Icomoon';
import { Popover } from '../Popover';
import { Navbar, NavbarProps } from './Components';
import { datesSepatator, getDefaultDate, placeholderMulty } from './helpers';
import { Period, Range, RangeListOption } from './types';
import { useHandlers } from './useDatepickerState';

interface DatePickerProps {
  period: Period;
  handleChangePeriod: (period: Period) => void;
  initialPeriod?: Period;
  error?: boolean;
  disabled?: boolean;
  disabledRange?: Modifier | Modifier[];
  className?: string;
  rangeList?: RangeListOption[];
  hasRemoveIcon?: boolean;
}
const ReactDatePicker = ({
  className,
  period,
  handleChangePeriod,
  error,
  disabled,
  disabledRange,
  rangeList,
  hasRemoveIcon = true,
}: DatePickerProps) => {
  // State
  const defaultInputState: Range = {
    from: getDefaultDate(period.startDate),
    to: getDefaultDate(period.endDate),
    hoverTo: getDefaultDate(period.endDate),
    hoverFrom: getDefaultDate(period.startDate),
  };
  const [state, setState] = useState<Range>(defaultInputState);
  const [datePicketInput, setDatePicketInput] = useState<string>(placeholderMulty);

  const { i18n } = useTranslation();
  const { isRtl } = useDirLayout();
  // if form has default period values, than set picker input
  useEffect(() => {
    if (resultInput) {
      setDatePicketInput(resultInput);
    }
  }, []);

  // Handlers
  const { handleDayClick, handleDayMouseEnter } = useHandlers(state, defaultInputState, setState);
  const handleUpdate = (closePopup: () => void) => {
    setDatePicketInput(resultInput);
    closePopup();
    if (state.from && state.to) {
      const startDate = isBefore(state.from, state.to) ? state.from : state.to;
      const endDate = isAfter(state.to, state.from) ? state.to : state.from;
      handleChangePeriod({ startDate: format(startDate, 'yyyy-MM-dd'), endDate: format(endDate, 'yyyy-MM-dd') });
    }
  };
  const handleClose = () => {
    setDatePicketInput(placeholderMulty);
    setState({ from: null, to: null, hoverTo: null, hoverFrom: null });
    handleChangePeriod({ startDate: '', endDate: '' });
  };

  const { from, hoverTo, hoverFrom } = state;

  const fromForInput = !!hoverFrom ? localizedDateFormatter(hoverFrom, 'PPP', i18n.language as AppLanguage) : '';
  const toForInput = !!hoverTo ? localizedDateFormatter(hoverTo, 'PPP', i18n.language as AppLanguage) : '';
  const showSingleDay = !!hoverTo && !!hoverFrom ? isSameDay(hoverTo, hoverFrom) : false;

  const resultInput =
    !showSingleDay && !!hoverTo && !!hoverFrom
      ? isBefore(hoverFrom, hoverTo)
        ? `${fromForInput}${datesSepatator}${toForInput}`
        : `${toForInput}${datesSepatator}${fromForInput}`
      : fromForInput;

  const modifiers = { start: hoverFrom || undefined, end: hoverTo || undefined };
  const isPlaceholder = datePicketInput === placeholderMulty;

  return (
    <WidgetBlock className={className}>
      <Popover
        renderTrigger={() => (
          <div>
            <StyledPickerInput
              disabled={!!disabled}
              error={!!error}
              css={
                isRtl
                  ? css`
                      span,
                      img {
                        right: 16px;
                      }

                      p {
                        padding: 0 40px 0 0;
                      }
                    `
                  : ''
              }
            >
              <Icomoon css={{ position: 'absolute', transform: 'translate(40%, 4px)' }} icon="calendar" size={21} />
              <DateInput isPlaceholder={isPlaceholder}>{datePicketInput}</DateInput>
              <MenuControlIcon hasRemoveIcon={hasRemoveIcon && !isPlaceholder && !disabled} onClose={handleClose} />
            </StyledPickerInput>
          </div>
        )}
        side="bottom"
        align="center"
        renderButtonBlock={({ closePopup }) => (
          <ButtonBlock>
            <p>{resultInput}</p>
            <Buttons>
              <ThemeButton onClick={closePopup} text="Cancel" width="max-content" />
              <ThemeButton
                theme="blue"
                text="Update"
                disabled={!resultInput}
                onClick={() => handleUpdate(closePopup)}
                width="max-content"
              />
            </Buttons>
          </ButtonBlock>
        )}
      >
        <Wrapper className="RangeExample">
          <StyledDayPicker
            className="Range"
            numberOfMonths={2}
            selectedDays={[from || undefined, { from: hoverFrom as Date, to: hoverTo as Date }]}
            modifiers={modifiers}
            onDayClick={handleDayClick}
            locale={i18n.language}
            // @ts-ignore
            localeUtils={localeUtils}
            navbarElement={(navbarProps: NavbarProps) => (
              <Navbar
                {...navbarProps}
                hoverFrom={hoverFrom}
                hoverTo={hoverTo}
                rangeList={rangeList}
                setState={setState}
                locale={i18n.language}
                // @ts-ignore
                localeUtils={localeUtils}
              />
            )}
            classNames={{ ...classNames, ...classes }}
            onDayMouseEnter={handleDayMouseEnter}
            captionElement={() => null}
            initialMonth={from || new Date()}
            disabledDays={disabledRange || undefined}
          />
        </Wrapper>
      </Popover>
      <Global
        styles={css`
          /* to made portal over FocusedBlock, it is not easy to pass this into Popover itself */
          [data-radix-popper-content-wrapper] {
            z-index: 100 !important;
          }
        `}
      />
    </WidgetBlock>
  );
};

const classes = {
  tooltip: 'tooltip',
  button: 'button',
  actions: 'actions',
  months: 'months',
  today: 'today',
  selected: 'selected',
  day: 'day',
  disabled: 'disabled',
  wrapper: 'wrapper',
  inputWrapper: 'inputWrapper',
};

const ButtonBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  justify-content: space-between;

  p {
    font-size: 14px;
  }
`;
const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .btn-text {
    padding: 0 16px;
  }
`;
const WidgetBlock = styled.div`
  display: flex;
  flex-direction: column;
`;
const Wrapper = styled.div`
  display: flex;
  height: 100%;
  z-index: 10;

  .tooltip {
    font-size: 12px;
  }

  .button {
    color: rgb(23, 156, 215);
  }

  .actions {
    align-items: baseline;
  }

  .months {
    display: flex;
    flex-wrap: nowrap;
  }

  .today {
    color: tomato;
  }

  .selected {
    background-color: #f0f8ff;
    color: #179cd7;
    border-radius: 0 !important;
  }

  .day {
    display: table-cell;
    padding: 0.5em;
    border-radius: 0;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;

    &.start,
    &.end {
      background-color: #179cd7 !important;
      color: #fff !important;
      border-radius: 2px !important;
    }

    &.outside {
      background-color: #fff !important;
    }

    &:hover {
      background-color: e1e2e3;
    }
  }

  .disabled {
    opacity: 0.3;
    background-color: #fff !important;
    color: #000;
    cursor: unset;
    outline: none;

    &:hover {
      background-color: #fff !important;
      color: #000;
    }

    &.start,
    &.end {
      background-color: #fff !important;
      color: #000 !important;
    }
  }

  .wrapper {
    outline: none;
    font-size: 14px;
  }

  .inputWrapper {
    width: 100%;
  }
`;

const StyledDayPicker = styled(DayPicker)`
  border-bottom: 1px solid #e4e5e6;
  overflow: auto;

  > div {
    outline: none;
  }

  & * > div {
    outline: none;
  }

  & * > div > div {
    outline: none;
  }
`;
const StyledPickerInput = styled.div<{ disabled: boolean; error: boolean }>`
  align-self: center;
  width: 100%;
  min-width: 280px;
  height: 32px;
  background-color: #fff;
  position: relative;
  border: 1px solid #e0e8ed;
  border-radius: 2px;
  font-size: 13px;
  line-height: 32px;
  box-sizing: border-box;
  ${({ disabled }) => disabled && 'background-color: #f7f7f7; cursor: default;'};
  ${({ error }) => error && 'border-color: tomato;'};

  p {
    padding-left: 40px;
  }
`;

const DateInput = styled.p<{ isPlaceholder: boolean }>`
  ${({ isPlaceholder }) => isPlaceholder && 'opacity: 0.2;'}
`;

export default ReactDatePicker;
