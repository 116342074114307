import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { ThemeButton } from '@src/components/atoms/Button';
import Grid from '@src/components/atoms/Grid';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import SnsBudgetInput from '@src/components/organisms/Settings/SnsBudget/SnsBudgetInput';
import { ViewportType } from '@src/libs/theme';
import { ROUTES } from '@src/shared/routes';
import { CampaignSocialMediaType } from '@src/__generated__/globalTypes';
import { usePageLayout } from '@src/libs/hooks';

export interface Information {
  facebookPrice: string;
  instagramPrice: string;
  instagramStoryPrice: string;
  tiktokPrice: string;
  twitterPrice: string;
  youtubePrice: string;
}

interface SnsBudgetProps {
  currency: string;
  hasFBAccount: boolean;
  hasIGAccount: boolean;
  hasTTAccount: boolean;
  hasTWAccount: boolean;
  hasYTAccount: boolean;
}

const SnsBudget = ({
  currency,
  hasFBAccount,
  hasIGAccount,
  hasTTAccount,
  hasTWAccount,
  hasYTAccount,
}: SnsBudgetProps) => {
  const { t } = useTranslation();
  const { isMobileView } = usePageLayout();
  const {
    formState: { errors, isSubmitting },
    setValue,
    watch,
  } = useFormContext<Information>();
  const [
    facebookPriceValue,
    instagramPriceValue,
    instagramStoryPriceValue,
    tiktokPriceValue,
    twitterPriceValue,
    youtubePriceValue,
  ] = watch(['facebookPrice', 'instagramPrice', 'instagramStoryPrice', 'tiktokPrice', 'twitterPrice', 'youtubePrice']);

  return (
    <div css={styles.container}>
      {!isMobileView && <BackNavigator title="SNS budget" to={ROUTES.SETTINGS} />}

      <div css={styles.description}>
        {t('Annotation.Connect with your social account to use Analytics data and get sponsorship campaign')}
      </div>

      <div css={styles.formContainer}>
        <Grid md={8} sm={10} xs={12}>
          <div css={styles.formInputSection}>
            <SnsBudgetInput
              currency={currency}
              disabled={!hasFBAccount}
              error={errors.facebookPrice?.message}
              type={CampaignSocialMediaType.FACEBOOK}
              value={facebookPriceValue}
              onChange={value => setValue('facebookPrice', value)}
            />
            <SnsBudgetInput
              currency={currency}
              disabled={!hasIGAccount}
              error={errors.instagramPrice?.message}
              type={CampaignSocialMediaType.INSTAGRAM}
              value={instagramPriceValue}
              onChange={value => setValue('instagramPrice', value)}
            />
            <SnsBudgetInput
              currency={currency}
              disabled={!hasIGAccount}
              error={errors.instagramStoryPrice?.message}
              type={CampaignSocialMediaType.INSTAGRAM_STORY}
              value={instagramStoryPriceValue}
              onChange={value => setValue('instagramStoryPrice', value)}
            />
            <SnsBudgetInput
              currency={currency}
              disabled={!hasTWAccount}
              error={errors.twitterPrice?.message}
              type={CampaignSocialMediaType.TWITTER}
              value={twitterPriceValue}
              onChange={value => setValue('twitterPrice', value)}
            />
            <SnsBudgetInput
              currency={currency}
              disabled={!hasYTAccount}
              error={errors.youtubePrice?.message}
              type={CampaignSocialMediaType.YOUTUBE}
              value={youtubePriceValue}
              onChange={value => setValue('youtubePrice', value)}
            />
            <SnsBudgetInput
              currency={currency}
              disabled={!hasTTAccount}
              error={errors.tiktokPrice?.message}
              type={CampaignSocialMediaType.TIKTOK}
              value={tiktokPriceValue}
              onChange={value => setValue('tiktokPrice', value)}
            />
            <div css={styles.actionContainer}>
              <ThemeButton
                theme="blue"
                css={styles.saveBtn}
                disabled={isSubmitting}
                text="Save"
                type="submit"
                fontSize="12px"
              />
            </div>
          </div>
        </Grid>
      </div>
    </div>
  );
};

const styles = {
  actionContainer: css`
    display: flex;
    justify-content: flex-end;
    padding: 16px;
  `,
  container: css`
    margin: 16px 24px;

    @media (max-width: ${ViewportType.TABLET}px) {
      margin: 16px;
    }
  `,
  description: css`
    color: #27313b;
    font-size: 14px;
    margin-bottom: 24px;
    margin-left: 48px;

    @media (max-width: ${ViewportType.TABLET}px) {
      margin-left: 0;
    }
  `,
  formContainer: css`
    display: flex;
    justify-content: center;
  `,
  formInputSection: css`
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 rgba(222, 229, 236, 0.5), 0 1px 2px #dee5ec;
  `,
  saveBtn: css`
    width: max-content;

    @media (max-width: ${ViewportType.TABLET}px) {
      border-radius: 5px;
      height: 40px;
      width: 100%;
    }
  `,
};

export default SnsBudget;
