import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { ResetPasswordSchema } from '@src/libs/validation';
import { useQueryHelper } from '@src/libs/hooks';
import { UNEXPECTED_ERROR } from '@src/libs/error';
import {
  ResendInfluencerForgotPasswordEmailVariables,
  ResendInfluencerForgotPasswordEmail,
} from './__generated__/ResendInfluencerForgotPasswordEmail';
import * as RESEND_INFLUENCER_SIGN_UP_EMAIL from './ResendInfluencerSignUpEmail.graphql';
import ResetPasswordForm from './ResetPasswordForm';
import { FormValues, SubmissionStatus } from './types';

const ResetPasswordFormComponent = () => {
  const [status, setStatus] = useState<string>('');
  const { t, enqueueSnackbar } = useQueryHelper();
  const methods = useForm<FormValues>({
    defaultValues: { email: '' },
    resolver: yupResolver(ResetPasswordSchema),
  });

  const [doMutation] = useMutation<ResendInfluencerForgotPasswordEmail, ResendInfluencerForgotPasswordEmailVariables>(
    RESEND_INFLUENCER_SIGN_UP_EMAIL
  );

  const handleSubmit = async (payload: FormValues) => {
    try {
      await doMutation({ variables: { input: { email: payload.email } } });
      setStatus(SubmissionStatus.OK);
      enqueueSnackbar(t('succeededInRequest'), { variant: 'success' });
    } catch (error) {
      setStatus(t(UNEXPECTED_ERROR));
      enqueueSnackbar('Failed to resend email, please try again', { variant: 'error' });
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <ResetPasswordForm status={status} />
      </form>
    </FormProvider>
  );
};

export default ResetPasswordFormComponent;
