import { OptionType } from '@src/components/molecules/MultiSearchSelector';
import uniqBy from 'lodash/uniqBy';
import { FanListFilterType, SelectedTagsInfo } from './types';
import { GetFansVariables, GetFans_getFans_fans_tags } from './__generated__/GetFans';

export const getFansVariables = (filter: FanListFilterType): Partial<GetFansVariables> => ({
  ...(!!filter.createdDate && { createdDate: filter.createdDate }),
  ...(!!filter.lastOrderDate && { lastOrderDate: filter.lastOrderDate }),
  ...(!!Number(filter.minAmountSpent) && { minAmountSpent: Number(filter.minAmountSpent) }),
  ...(!!Number(filter.maxAmountSpent) && { maxAmountSpent: Number(filter.maxAmountSpent) }),
  ...(!!Number(filter.exactAmountSpent) && { exactAmountSpent: Number(filter.exactAmountSpent) }),
  ...(!!Number(filter.minNumberOfOrders) && { minNumberOfOrders: Number(filter.minNumberOfOrders) }),
  ...(!!Number(filter.maxNumberOfOrders) && { maxNumberOfOrders: Number(filter.maxNumberOfOrders) }),
  ...(!!Number(filter.exactNumberOfOrders) && { exactNumberOfOrders: Number(filter.exactNumberOfOrders) }),
});

export const getInitialTagsSelection = (tags: SelectedTagsInfo): OptionType[] => {
  const keys = Object.keys(tags);
  if (keys.length === 0) {
    return [];
  }
  let res: GetFans_getFans_fans_tags[] = [];
  keys.forEach(item => {
    res = [...res, ...tags[item]];
  });
  const allTags = res.map(el => ({ label: el.tag, value: el.id.toString() }));

  return uniqBy(allTags, 'value');
};
