import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { ListIndicator } from '@src/components/molecules/Indicator';
import CampaignPostReports from '@src/components/organisms/CastingMarketplace/YourJob/CampaignPostReports';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import { useQueryHelper } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/theme';
import { CampaignType } from '@src/__generated__/globalTypes';
import CAMPAIGN_REPORTS from './queries/MarketplaceCampaignReports.graphql';
import {
  MarketplaceCampaignReports,
  MarketplaceCampaignReportsVariables,
} from './queries/__generated__/MarketplaceCampaignReports';

const Reports = () => {
  const { enqueueSnackbar, params, t } = useQueryHelper();
  const isDesktopView = useMediaQuery({ query: `(min-width: ${ViewportType.TABLET}px)` });
  const campaignId = Number(params.id);

  const { data, loading } = useQuery<MarketplaceCampaignReports, MarketplaceCampaignReportsVariables>(
    CAMPAIGN_REPORTS,
    {
      variables: {
        campaignId,
      },
      onError: error => {
        enqueueSnackbar(t(error.message), { variant: 'error' });
      },
    }
  );

  let campaignPostReports = null;
  if (data?.marketplaceCampaignPostReportForInfluencer) {
    const { currency, id, postReport, revenue, title } = data.marketplaceCampaignPostReportForInfluencer;
    campaignPostReports = {
      currency,
      id,
      postReport: [postReport],
      revenue,
      title,
    };
  }

  return (
    <div css={styles.container}>
      {isDesktopView && (
        <div css={styles.navigatorContainer}>
          <BackNavigator title="Campaign's Report" to={`/marketplace/your_job/${campaignId}`} />
        </div>
      )}

      {loading ? (
        <ListIndicator />
      ) : (
        campaignPostReports && (
          <CampaignPostReports campaignPostReports={campaignPostReports} campaignType={CampaignType.MARKETPLACE} />
        )
      )}
    </div>
  );
};

const styles = {
  container: css`
    margin: 24px;
  `,
  navigatorContainer: css`
    margin-bottom: 16px;
  `,
};

export default Reports;
