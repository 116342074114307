import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import isPropValid from '@emotion/is-prop-valid';
import { DisabledProps } from '../types';

interface InputBoxProps extends InputBoxWrapperProps {
  className?: string;
  searchText: string;
  placeholder?: string;
  hideInput?: boolean;
  isRoundInput?: boolean;
  inputRef: React.RefObject<HTMLInputElement>;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleClickInput: (e: React.MouseEvent<HTMLInputElement>) => void;
  handleClickInputBox: () => void;
  MenuControlIcon?: React.ReactElement;
  renderSelectedValue: () => React.ReactNode;
}

const InputBox = (props: InputBoxProps) => {
  const {
    isMenuOpen,
    isError,
    disabled,
    searchText,
    placeholder,
    isRoundInput,
    inputRef,
    handleChange,
    handleKeyDown,
    handleClickInput,
    handleClickInputBox,
    MenuControlIcon,
    renderSelectedValue,
    className,
    hideInput,
  } = props;
  const { t } = useTranslation();

  return (
    <InputBoxWrapper
      className={className}
      isMenuOpen={isMenuOpen}
      isError={isError}
      disabled={disabled}
      isRoundInput={isRoundInput}
      onClick={handleClickInputBox}
    >
      <InputWrapper>
        {renderSelectedValue()}
        <Input
          css={{ padding: '0 12px 0 16px' }}
          hideInput={hideInput}
          value={searchText}
          ref={inputRef}
          disabled={disabled}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onClick={handleClickInput}
          placeholder={disabled ? '' : placeholder === undefined ? t('Selector.Please Select') : placeholder}
        />
      </InputWrapper>
      {MenuControlIcon}
    </InputBoxWrapper>
  );
};

interface InputBoxWrapperProps extends DisabledProps {
  isMenuOpen: boolean;
  isError?: boolean;
  isRoundInput?: boolean;
}

const InputBoxWrapper = styled.div<InputBoxWrapperProps>`
  position: relative;
  min-height: 32px;
  background-color: ${({ disabled }) => (disabled ? '#f2f2f2' : '#fff')};
  border: ${({ isError, isMenuOpen }) => `1px solid ${isError ? 'tomato' : isMenuOpen ? '#179cd7' : '#dee5ec'}`};
  border-radius: 3px;
  ${({ isMenuOpen }) => isMenuOpen && 'border-bottom-color: #dee5ec;'}
  cursor: pointer;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`;

const Input = styled('input', { shouldForwardProp: prop => isPropValid(prop) })<
  DisabledProps & { hideInput?: boolean }
>`
  flex: 1;
  display: inline-block;
  width: 100%;
  height: 0;
  min-height: 32px;
  outline: 0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
  letter-spacing: 0.02em;
  line-height: 38px;
  word-wrap: break-word;
  white-space: normal;
  margin-left: 6px;
  padding: 0 2em 0 0.5em;
  transition: box-shadow 0.1s ease, width 0.1s ease;
  box-shadow: none;
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'default')};
  visibility: ${({ hideInput }) => (hideInput ? 'hidden' : 'visible')};
`;

export default InputBox;
