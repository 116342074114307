import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuthSetup } from '@src/components/organisms/AuthSetUp/useAuthSetup';
import { removeToken, setToken } from '@src/libs/auth';
import { PasswordResetSchema } from '@src/libs/validation';
import { useQueryHelper } from '@src/libs/hooks';
import { UNEXPECTED_ERROR } from '@src/libs/error';
import { ROUTES } from '@src/shared/routes';
import PasswordForm from './PasswordForm';
import { FormValues } from './types';
import { ResetPasswordAndLogin, ResetPasswordAndLoginVariables } from './__generated__/ResetPasswordAndLogin';
import RESET_PASSWORD_LOGIN from './ResetPasswordAndLogin.graphql';

interface PasswordFormComponentProps {
  isPasswordReset?: boolean;
  token: string;
}

const PasswordFormComponent = (props: PasswordFormComponentProps) => {
  const { isPasswordReset, token } = props;
  const { t, enqueueSnackbar, history } = useQueryHelper();
  const { setUp } = useAuthSetup();
  const methods = useForm<FormValues>({
    defaultValues: { password: '', passwordConfirm: '' },
    resolver: yupResolver(PasswordResetSchema),
  });

  const [resetPasswordAndLogin] = useMutation<ResetPasswordAndLogin, ResetPasswordAndLoginVariables>(
    RESET_PASSWORD_LOGIN
  );

  const handleSubmit = async (payload: FormValues) => {
    const { password } = payload;
    const variables = {
      input: {
        token,
        password,
      },
    };

    try {
      const result = await resetPasswordAndLogin({ variables });
      if (result.data?.resetPasswordAndLogin) {
        const { refreshToken, token: resetPasswordLoginToken } = result.data.resetPasswordAndLogin;
        setToken(resetPasswordLoginToken, refreshToken);
        try {
          await setUp(resetPasswordLoginToken);
          history.push(isPasswordReset ? ROUTES.HOME : `${ROUTES.HOME}?firstEmailSignUp=true`);
        } catch (err) {
          removeToken();
          console.error(err);
          enqueueSnackbar(t(err.message), { variant: 'error' });
          history.push(ROUTES.ROOT);
        }
      } else {
        enqueueSnackbar(t(UNEXPECTED_ERROR), { variant: 'error' });
      }
    } catch (err) {
      enqueueSnackbar(t('failedToUpdatePassword'), { variant: 'error' });
      const errorMessage = err.message || UNEXPECTED_ERROR;
      console.error(errorMessage);
      enqueueSnackbar(t(errorMessage), { variant: 'error' });
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        css={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        onSubmit={methods.handleSubmit(handleSubmit)}
      >
        <PasswordForm isPasswordReset={isPasswordReset} />
      </form>
    </FormProvider>
  );
};

export default PasswordFormComponent;
