import React from 'react';
import IcoMoon, { iconList, IconProps } from 'react-icomoon';
import selection from '@src/assets/icomoon/selection.json';
import selectionColors from '@src/assets/icomoon/selection-colors.json';

const Icomoon = ({ color = '#6e7c89', size = 16, ...restProps }: IconProps) => {
  // to combine selection icons but using the same configuration
  const iconSet = { ...selection, icons: [...selection.icons, ...selectionColors.icons] };

  return <IcoMoon color={color} iconSet={iconSet} size={size} {...restProps} />;
};

export { iconList, selection, selectionColors };
export default Icomoon;
