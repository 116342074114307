import { useMediaQuery } from 'react-responsive';
import { SignUpAndSignInBreakpoint, ViewportBreakpoint, ViewportType } from '../theme';

export default () => {
  const isMobileView = useMediaQuery({ maxWidth: ViewportType.TABLET });
  const isSmallView = useMediaQuery({ maxWidth: ViewportType.SMALL });
  const isDirectionColumn = useMediaQuery({ maxWidth: ViewportBreakpoint.MOBILE });
  const isMobileViewPublicRoutes = useMediaQuery({ maxWidth: SignUpAndSignInBreakpoint.MEDIUM });

  return {
    isSmallView,
    isMobileView,
    isMobileViewPublicRoutes,
    isDirectionColumn,
  };
};
