import { isSameDay } from 'date-fns';
import { RefObject } from 'react';
import { Entries, Period, RangeListOption, SELECTED_RANGE, ValuesOf } from './types';
import { useRange } from './useDatepickerState';

export const datesSepatator = '\u00A0\u00A0\u2013\u00A0\u00A0';
export const placeholderMulty = `Start Date${datesSepatator}End Date`;
export const placeholderSingle = 'Jan 01, 2020';
export const DAYPICKER_INPUT_FORMAT = 'MMM dd, yyyy';

export const getDefaultDate = (date: string) => (date ? new Date(date) : null);

export const CloseOverlayAfterFocus = (inputRef: RefObject<any>) => {
  const activeElement = document.activeElement;
  const overlayContainer = document.getElementsByClassName('DayPickerInput-Overlay');

  if (overlayContainer && !overlayContainer[0].contains(activeElement)) {
    inputRef.current.hideAfterDayClick();
  }
};

interface GetSelectedRange {
  from: Date;
  to: Date;
  initialPeriod?: Period;
  rangeList?: RangeListOption[];
}
export const getSelectedRangeValue = ({ from, to, initialPeriod, rangeList }: GetSelectedRange) => {
  const fromFormat = from === null ? 0 : from;
  const toFormat = to === null ? 0 : to;

  const customValue = rangeList?.find(({ value }) => value === SELECTED_RANGE.CUSTOM);

  let resultValue: { value: ValuesOf<typeof SELECTED_RANGE>; label: string } = {
    value: SELECTED_RANGE.CUSTOM,
    label: customValue?.label || 'Custom',
  };
  const rangesCallbacks = useRange({ initialPeriod });

  (Object.entries(rangesCallbacks) as Entries<typeof rangesCallbacks>).forEach(([rangeName, callback]) => {
    const selectedRange = callback();

    if (isSameDay(selectedRange.from || 0, fromFormat) && isSameDay(selectedRange.to || 0, toFormat)) {
      const rangeValue = rangeList?.find(({ value }) => value === rangeName);
      if (rangeValue) {
        resultValue = { value: rangeValue.value, label: rangeValue.label || rangeValue.value };
      }
    }
  });

  return resultValue;
};
