import React, { useState } from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { arrowLeft, arrowRight } from '@src/assets/htmlCodes';
import anyCreatorBlack from '@src/assets/icon/anyCreatorBlack.svg';
import { ThemeButton } from '@src/components/atoms/Button';
import { CheckBox } from '@src/components/atoms/CheckBox';
import { useDirLayout, usePageLayout, useQueryHelper } from '@src/libs/hooks';
import AccessPermission from './AccessPermission';

interface FacebookInstructionProps {
  backUrl?: string;
  isAuthPage?: boolean;
  onClickEditSettings: () => void;
}

const FacebookInstruction = ({ backUrl, isAuthPage, onClickEditSettings }: FacebookInstructionProps) => {
  const [confirmedNotification, setConfirmedNotification] = useState<boolean>(false);
  const { history, t } = useQueryHelper();
  const { isRtl } = useDirLayout();
  const { isMobileViewPublicRoutes } = usePageLayout();

  return (
    <Container isAuthPageMobileView={!!isAuthPage && isMobileViewPublicRoutes}>
      <ContentContainer isAuthPageMobileView={!!isAuthPage && isMobileViewPublicRoutes}>
        {isMobileViewPublicRoutes && isAuthPage && (
          <div css={styles.logoContainer}>
            <span onClick={() => history.push(backUrl || '')}>{arrowLeft}</span>
            <img alt="logo" src={anyCreatorBlack} width="165" />
          </div>
        )}
        <div css={styles.titleContainer}>
          {!isMobileViewPublicRoutes && isAuthPage && (
            <span onClick={() => history.push(backUrl || '')}>{isRtl ? arrowRight : arrowLeft}</span>
          )}
          <div>{t(isAuthPage ? 'Title.Please grant permission' : 'pageTitle.Grant permission')}</div>
        </div>
        <div css={styles.description}>
          {t(
            'Annotation.In order to fetch your post to the campaign, AnyCreator needs access to your public information'
          )}
        </div>
        <div css={styles.infoContainer}>
          <div>{t('Annotation.Please make sure that you will turn on all toggles on the access permission page')}</div>
          <div>{t('Annotation.Facebook’s access permission page')}</div>
          <div>
            <AccessPermission />
          </div>
        </div>

        <div css={{ display: 'flex', justifyContent: 'center' }}>
          <CheckBox
            checked={confirmedNotification}
            label="I have confirmed this notification"
            size="14px"
            onCheckedChange={checked => setConfirmedNotification(checked)}
          />
        </div>
      </ContentContainer>

      <ActionContainer isMobileView={isMobileViewPublicRoutes}>
        <div>
          <ThemeButton
            disabled={!confirmedNotification}
            text="Edit Settings"
            theme="blue"
            onClick={onClickEditSettings}
          />
          <div>*{t('Annotation.Enable button with checking a checkbox below')}</div>
        </div>
      </ActionContainer>
    </Container>
  );
};

const ActionContainer = styled.div<{ isMobileView: boolean }>`
  background-color: #fff;
  border-top: 1px solid #dee5ec;
  display: flex;
  padding: 16px;
  justify-content: flex-end;

  & > div {
    ${({ isMobileView }) => isMobileView && 'width: 100%;'}

    & > button {
      margin-bottom: 4px;
      ${({ isMobileView }) => isMobileView && 'height: 40px;'}
    }

    & > div {
      color: #6e7c89;
      font-size: 11px;
    }
  }
`;

const Container = styled.div<{ isAuthPageMobileView: boolean }>`
  background-color: #fff;
  border-radius: 5px;
  box-shadow: ${({ isAuthPageMobileView }) =>
    isAuthPageMobileView ? 'none' : '0 2px 5px #dee5ec, 0 1px 4px rgba(222, 229, 236, 0.5)'};
`;

const ContentContainer = styled.div<{ isAuthPageMobileView: boolean }>`
  padding: ${({ isAuthPageMobileView }) => (isAuthPageMobileView ? '16px 24px' : '16px')};
`;

const styles = {
  description: css`
    color: #27313b;
    font-size: 14px;
    margin-bottom: 24px;
  `,
  infoContainer: css`
    border: 1px solid #dee5ec;
    border-radius: 5px;
    margin-bottom: 32px;
    padding: 24px 16px;

    & > div:nth-of-type(1) {
      border-bottom: 1px solid #eef3f7;
      color: #27313b;
      font-size: 14px;
      margin-bottom: 24px;
      padding-bottom: 16px;
    }

    & > div:nth-of-type(2) {
      color: #27313b;
      font-size: 14px;
      margin-bottom: 16px;
      text-align: center;
    }

    & > div:nth-of-type(3) {
      display: flex;
      justify-content: center;
    }
  `,
  logoContainer: css`
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
    position: relative;

    & > span {
      color: #3892e5;
      font-size: 22px;
      left: 0;
      position: absolute;
    }
  `,
  titleContainer: css`
    align-items: center;
    display: flex;
    gap: 8px;
    margin-bottom: 16px;

    & > span {
      color: #3892e5;
      cursor: pointer;
      font-size: 22px;
    }

    /* stylelint-disable no-descending-specificity */
    & > div {
      color: #27313b;
      font-size: 18px;
      font-weight: 600;
    }
  `,
};

export default FacebookInstruction;
