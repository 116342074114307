import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { css } from '@emotion/core';
import SearchForm from '@src/components/molecules/SearchForm';
import Icomoon from '@src/components/atoms/Icomoon';
import { Popover, MenuItem } from '@src/components/molecules/Popover';
import { useQueryHelper } from '@src/libs/hooks';
import { ThemeButton } from '@src/components/atoms/Button';
import { Order } from '@src/__generated__/globalTypes';
import { useApolloClient } from '@apollo/client';
import { ViewportType } from '@src/libs/theme';
import { RadioIcon } from '@src/components/atoms/RadioButtonElement';
import styled from '@emotion/styled';
import useFanList from '../useFanList';
import { FanListFilterType } from '../types';
import { FormValues } from './types';
import useStateHandler from './useStateHandler';
import ImportFansModal from './ImportFansModal';
import * as GET_CREATOR_TAGS_BY_ID from './GetCreatorTagsById.graphql';
import { GetCreatorTagsById, GetCreatorTagsByIdVariables } from './__generated__/GetCreatorTagsById';
import { getTagsIdFromURLString } from './helper';
import { MoreFilters } from './MoreFilters';

interface FanListFilterProps {
  filter: FanListFilterType;
  sortOrder: Order;
  onSort: () => void;
  selectedFansId: Set<number>;
  onSubmit: (information: FormValues) => void;
}

const FanListFilter = (props: FanListFilterProps) => {
  const { t } = useQueryHelper();
  const client = useApolloClient();
  const { selectedFansId, filter, sortOrder, onSort, onSubmit } = props;
  const { exportFanCSV, uploadSelectedFile } = useFanList();
  const { handleSubmit, register, setValue } = useFormContext<FormValues>();

  const setSelectedTags = async (tagIds: number[]) => {
    const { data } = await client.query<GetCreatorTagsById, GetCreatorTagsByIdVariables>({
      query: GET_CREATOR_TAGS_BY_ID,
      variables: { input: { tagIds } },
    });

    setValue(
      'tags',
      data.getCreatorTagsById?.tags.map(item => ({
        label: item.tag,
        value: item.id.toString(),
      })) || []
    );
  };

  useEffect(() => {
    const appliedTagIds = getTagsIdFromURLString(filter.tags);
    if (appliedTagIds.length) {
      setSelectedTags(appliedTagIds);
    } else {
      setValue('tags', []);
    }
  }, [filter.tags]);

  const { showImportModal, closeImportModal, openImportModal } = useStateHandler();
  let onCloseMenu: () => void;

  const handleBlurAndSubmit = () => {
    handleSubmit(onSubmit)();
  };

  const handleClickImport = async (file: File) => {
    await uploadSelectedFile(file);
    closeImportModal();
  };

  const onClickExport = () => {
    exportFanCSV(selectedFansId);
    onCloseMenu();
  };
  const onClickImport = () => {
    openImportModal();
    onCloseMenu();
  };

  return (
    <>
      <ImportFansModal open={showImportModal} onClose={closeImportModal} onImportFile={handleClickImport} />
      <div css={styles.btnWrapper}>
        <SearchForm
          placeholder={t('TextForm.Search by Fan’s Name or Email Address')}
          onEnterKeyPress={handleBlurAndSubmit}
          css={styles.input}
          {...register('keyword')}
          onBlur={handleBlurAndSubmit}
        />

        <MoreFilters filter={filter} onSubmit={onSubmit} />

        <Popover
          renderTrigger={() => (
            <div>
              <ThemeButton
                text="Sort"
                width="max-content"
                prefixIcon={<Icomoon icon="sort" size={24} />}
                css={styles.button}
              />
            </div>
          )}
          renderButtonBlock={() => null}
        >
          <div css={{ width: 'max-content' }}>
            <SortMenuItem onClick={onSort}>
              <RadioIcon isChecked={sortOrder === Order.DESC} />
              {t('Option.Latest Created')}
            </SortMenuItem>
            <SortMenuItem onClick={onSort}>
              <RadioIcon isChecked={sortOrder === Order.ASC} />
              {t('Option.Last Created')}
            </SortMenuItem>
          </div>
        </Popover>
        <Popover
          renderTrigger={() => (
            <ThemeButton prefixIcon="&middot;&middot;&middot;" width="32px" css={styles.downloadBtn} />
          )}
          renderButtonBlock={({ closePopup }) => {
            onCloseMenu = closePopup;

            return null;
          }}
        >
          <SortMenuItem onClick={onClickImport} css={{ gap: '8px' }}>
            <Icomoon icon="import" />
            {t('Import CSV')}
          </SortMenuItem>
          <SortMenuItem onClick={onClickExport} css={{ gap: '8px' }}>
            <Icomoon icon="export" />
            {t('Export CSV')}
          </SortMenuItem>
        </Popover>
      </div>
    </>
  );
};

const SortMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const styles = {
  title: css`
    font-weight: 600;
    font-size: 14px;
    color: #27313b;
  `,
  panelDetails: css`
    padding: 0 !important;
  `,
  wrapper: css`
    display: flex;
    flex-direction: column;
  `,

  form: css`
    height: 100%;
  `,
  submitBtn: css`
    width: fit-content;
    line-height: 32px;
    border-radius: 3px;
    padding: 0 24px;
  `,
  summary: css`
    height: 48px !important;
    min-height: 48px !important;
    padding: 0 !important;
  `,
  heading: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #dee5ec;
  `,
  headingText: css`
    font-weight: 600;
    font-size: 18px;
    color: #27313b;
  `,
  input: css`
    flex: 1;

    @media (max-width: ${ViewportType.TABLET}px) {
      flex-basis: 100%;
    }

    i {
      background-color: transparent;
      height: 30px;
    }

    input {
      line-height: 30px;
    }
  `,
  label: css`
    font-weight: 600;
    font-size: 14px;
    color: #27313b;
  `,
  container: css`
    width: 336px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  `,
  btnWrapper: css`
    padding: 16px;
    margin: 16px 25px 0;
    background-color: #fff;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    box-shadow: 0 1px 2px 0 #dee5ec, 0 0 0 1px rgb(222 229 236 / 50%);
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  `,
  button: css`
    @media (max-width: ${ViewportType.TABLET}px) {
      display: flex;
      flex: 1;
    }
  `,
  downloadBtn: css`
    .prefix-icon {
      font-weight: 600;
      font-size: 22px;
    }
  `,
  footer: css`
    background-color: #f6f8fa;
    padding: 24px 24px 24px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
  `,
  expansion: css`
    background-color: red;

    &.MuiExpansionPanel-root {
      position: unset;
    }

    & .MuiExpansionPanelSummary-expandIcon {
      margin-right: -8px !important;
    }

    &::before {
      height: 0;
    }

    &:last-of-type {
      border-bottom: none;
    }
  `,
  textForm: css`
    width: 240px;
    margin: 0 0 16px 26px;

    /* stylelint-disable */
    & input {
      border-radius: 3px;
      height: 32px;
    }

    label {
      padding-left: 0;
    }
    /* stylelint-enable */
  `,
  checkbox: css`
    padding: 0 0 4px 0;

    label {
      margin-right: 0;
      align-items: flex-start;
    }
  `,
  clearBtn: css`
    margin: 8px 0;
    color: #3892e5;
    font-size: 14px;
    cursor: pointer;
  `,
  filterCount: css`
    width: 24px;
    line-height: 24px;
    border-radius: 50%;
    text-align: center;
    font-size: 12px;
    background-color: #fff;
    font-weight: 600;
  `,
  filterIcon: css`
    border-color: #3892e5;
    background-color: #ecf3fb;

    .btn-text {
      padding: 0;
      color: #3892e5;
    }
  `,
  tagSelector: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 40px;
  `,
};

export default FanListFilter;
