import styled from '@emotion/styled';
import { LocationDescriptor } from 'history';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { ViewportType } from '../../../../libs/theme';

interface MenuLinkProps {
  className?: string;
  href: LocationDescriptor;
  title: string;
}

const MenuLink = (props: MenuLinkProps) => {
  const { className, href, title } = props;

  return (
    <LinkStyle className={className} to={href} target="_blank">
      {title}
    </LinkStyle>
  );
};

const LinkStyle = styled(Link)`
  padding: 0 18px 0 12px;
  border-right: 1px solid #dee5ec;
  color: #6e7c89;
  font-size: 11px;
  letter-spacing: normal;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: ${ViewportType.SMALL}px) {
    padding: 0 12px 0;
  }
`;

export default MenuLink;
