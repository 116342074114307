import { useQuery } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import * as GET_FAN_PROVINCES from './GetFanProvinces.graphql';
import { GetFanProvinces, GetFanProvincesVariables } from './__generated__/GetFanProvinces';

interface UseProvinceOptionsProps {
  countryId: string;
}

const useProvinceOptions = ({ countryId }: UseProvinceOptionsProps) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  const { data, refetch } = useQuery<GetFanProvinces, GetFanProvincesVariables>(GET_FAN_PROVINCES, {
    skip: !countryId,
    variables: {
      input: {
        countryId,
      },
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const provinceOptions = data?.getFanProvinces?.provinces.map(item => ({ label: item.name, value: item.name })) || [];

  return {
    provinceOptions,
    refetch,
  };
};

export default useProvinceOptions;
