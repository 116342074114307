import { useFormContext } from 'react-hook-form';
import { css } from '@emotion/react';
import { ThemeButton } from '@src/components/atoms/Button';
import Icomoon from '@src/components/atoms/Icomoon';
import { Drawer, DialogPrimitives } from '@src/components/molecules/Drawer';
import { ViewportType } from '@src/libs/theme';
import { Accordion } from '@src/components/molecules/Accordion';
import { FilterByDateRange } from '@src/__generated__/globalTypes';
import { CheckBox } from '@src/components/atoms/CheckBox';
import TextForm from '@src/components/molecules/TextForm';
import { useTranslation } from 'react-i18next';
import MultiTagsSearchSelector from '@src/components/molecules/MultiTagsSearchSelector';
import { useGlobalLayoutProps } from '@src/libs/hooks';
import { FanListFilterType } from '../types';
import DateOptionGroup from './DateOptionGroup';
import { FormValues } from './types';
import { getFanFilterCount } from './helper';

const dateGroupOptions = [
  { label: 'Last 7 days', value: FilterByDateRange.LAST_7_DAYS },
  { label: 'Last 30 days', value: FilterByDateRange.LAST_30_DAYS },
  { label: 'Last 90 days', value: FilterByDateRange.LAST_90_DAYS },
  { label: 'Last 12 months', value: FilterByDateRange.LAST_12_MONTHS },
];

interface MoreFiltersProps {
  filter: FanListFilterType;
  onSubmit: (information: FormValues) => void;
}

export const MoreFilters = ({ filter, onSubmit }: MoreFiltersProps) => {
  const { t } = useTranslation();
  const { updateGlobalLayout } = useGlobalLayoutProps();

  const {
    formState: { isSubmitting },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useFormContext<FormValues>();
  const [createdDate, checkedFields, lastOrderDate, tags] = watch([
    'createdDate',
    'checkedFields',
    'lastOrderDate',
    'tags',
  ]);

  const handleClearAllOrders = () => {
    setValue('minNumberOfOrders', '');
    setValue('maxNumberOfOrders', '');
    setValue('exactNumberOfOrders', '');
    setValue('checkedFields', {
      ...checkedFields,
      ...{ minNumberOfOrders: false, maxNumberOfOrders: false, exactNumberOfOrders: false },
    });
  };
  const handleClickClearCreatedDate = () => {
    setValue('createdDate', null);
  };

  const handleClickClearLastOrderDate = () => {
    setValue('lastOrderDate', null);
  };

  const handleClearAllAmount = () => {
    setValue('minAmountSpent', '');
    setValue('maxAmountSpent', '');
    setValue('exactAmountSpent', '');
    setValue('checkedFields', {
      ...checkedFields,
      ...{ minAmountSpent: false, maxAmountSpent: false, exactAmountSpent: false },
    });
  };
  const handleClickOk = () => {
    handleSubmit(onSubmit)();
  };

  const hangleTagsChange = setValue.bind(null, 'tags');

  const filterCount = getFanFilterCount(filter);

  return (
    <Drawer
      triggerNode={
        <ThemeButton
          text="More filters"
          width="max-content"
          css={[styles.button, filterCount ? styles.filterIcon : undefined]}
          prefixIcon={<Icomoon color={filterCount ? '#3992e5' : '#6e7c89'} icon="filter" size={24} />}
          postfixIcon={
            filterCount ? (
              <div css={styles.filterCount}>
                <span>{filterCount}</span>
              </div>
            ) : undefined
          }
        />
      }
      onOpenChange={isOpened => {
        updateGlobalLayout({ isMenuOpen: isOpened });
      }}
      buttonsSection={
        <div css={styles.footer} key="button_section">
          <DialogPrimitives.Close asChild>
            <ThemeButton text="Cancel" width="max-content" />
          </DialogPrimitives.Close>
          <DialogPrimitives.Close asChild>
            <ThemeButton text="OK" theme="blue" width="max-content" onClick={handleClickOk} disabled={isSubmitting} />
          </DialogPrimitives.Close>
        </div>
      }
    >
      <div css={styles.container}>
        <div>
          <div css={styles.heading}>
            <p css={styles.headingText}>{t('More Filters')}</p>
          </div>
          <Accordion
            type="multiple"
            css={styles.summary}
            defaultValue={[
              ...(createdDate ? ['createdDate'] : []),
              ...(lastOrderDate ? ['lastOrderDate'] : []),
              ...(checkedFields.minAmountSpent || checkedFields.maxAmountSpent || checkedFields.exactAmountSpent
                ? ['amount-spent']
                : []),
              ...(checkedFields.minNumberOfOrders ||
              checkedFields.maxNumberOfOrders ||
              checkedFields.exactNumberOfOrders
                ? ['number-of-orders']
                : []),
              ...(tags.length ? ['tags'] : []),
            ]}
            items={[
              {
                trigger: { children: <h2 css={styles.title}>{t('Created Date')}</h2> },
                value: 'createdDate',
                content: (
                  <DateOptionGroup
                    items={dateGroupOptions}
                    value={createdDate}
                    onClear={handleClickClearCreatedDate}
                    onChange={val => setValue('createdDate', val)}
                  />
                ),
              },
              // Last Order Date
              {
                trigger: { children: <h2 css={styles.title}>{t('Last Order Date')}</h2> },
                value: 'lastOrderDate',
                content: (
                  <DateOptionGroup
                    items={dateGroupOptions}
                    value={lastOrderDate}
                    onClear={handleClickClearLastOrderDate}
                    onChange={val => setValue('lastOrderDate', val)}
                  />
                ),
              },
              //  Amount Spent
              {
                trigger: { children: <h2 css={styles.title}>{t('Amount Spent')}</h2> },
                value: 'amount-spent',
                content: (
                  <div css={{ display: 'flex', flexDirection: 'column' }}>
                    {/* Min */}
                    <CheckBox
                      css={styles.checkbox}
                      label={t('Amount.More than this number')}
                      name="minAmountSpent"
                      checked={checkedFields.minAmountSpent}
                      onClick={() => {
                        setValue('checkedFields', {
                          ...checkedFields,
                          minAmountSpent: !checkedFields.minAmountSpent,
                        });
                      }}
                    />
                    {checkedFields.minAmountSpent && (
                      <TextForm css={styles.textForm} placeholder="¥ 0" {...register('minAmountSpent')} />
                    )}
                    {/* Max */}
                    <CheckBox
                      label={t('Amount.Less than this number')}
                      css={styles.checkbox}
                      name="maxAmountSpent"
                      checked={checkedFields.maxAmountSpent}
                      onClick={() => {
                        setValue('checkedFields', {
                          ...checkedFields,
                          maxAmountSpent: !checkedFields.maxAmountSpent,
                        });
                      }}
                    />
                    {checkedFields.maxAmountSpent && (
                      <TextForm css={styles.textForm} placeholder="¥ 0" {...register('maxAmountSpent')} />
                    )}
                    {/* Exact */}
                    <CheckBox
                      label={t('Amount.This exact amount')}
                      css={styles.checkbox}
                      name="exactAmountSpent"
                      checked={checkedFields.exactAmountSpent}
                      onClick={() => {
                        setValue('checkedFields', {
                          ...checkedFields,
                          exactAmountSpent: !checkedFields.exactAmountSpent,
                        });
                      }}
                    />
                    {checkedFields.exactAmountSpent && (
                      <TextForm css={styles.textForm} placeholder="¥ 0" {...register('exactAmountSpent')} />
                    )}
                    <p css={styles.clearBtn} onClick={handleClearAllAmount}>
                      {t('Clear')}
                    </p>
                  </div>
                ),
              },
              // Number of Order
              {
                trigger: { children: <h2 css={styles.title}>{t('Number of Order')}</h2> },
                value: 'number-of-orders',
                content: (
                  <div css={{ display: 'flex', flexDirection: 'column' }}>
                    {/* Min */}
                    <CheckBox
                      css={styles.checkbox}
                      label={t('OrderCount.More than this number')}
                      name="minNumberOfOrders"
                      checked={checkedFields.minNumberOfOrders}
                      onClick={() => {
                        setValue('checkedFields', {
                          ...checkedFields,
                          minNumberOfOrders: !checkedFields.minNumberOfOrders,
                        });
                      }}
                    />
                    {checkedFields.minNumberOfOrders && (
                      <TextForm css={styles.textForm} placeholder="0" {...register('minNumberOfOrders')} />
                    )}
                    {/* Max */}
                    <CheckBox
                      label={t('OrderCount.Less than this number')}
                      css={styles.checkbox}
                      name="maxNumberOfOrders"
                      checked={checkedFields.maxNumberOfOrders}
                      onClick={() => {
                        setValue('checkedFields', {
                          ...checkedFields,
                          maxNumberOfOrders: !checkedFields.maxNumberOfOrders,
                        });
                      }}
                    />
                    {checkedFields.maxNumberOfOrders && (
                      <div>
                        <TextForm css={styles.textForm} placeholder="0" {...register('maxNumberOfOrders')} />
                      </div>
                    )}
                    {/* Exact */}
                    <CheckBox
                      label={t('OrderCount.This exact amount')}
                      css={styles.checkbox}
                      name="exactNumberOfOrders"
                      checked={checkedFields.exactNumberOfOrders}
                      onClick={() => {
                        setValue('checkedFields', {
                          ...checkedFields,
                          exactNumberOfOrders: !checkedFields.exactNumberOfOrders,
                        });
                      }}
                    />
                    {checkedFields.exactNumberOfOrders && (
                      <TextForm css={styles.textForm} placeholder="0" {...register('exactNumberOfOrders')} />
                    )}
                    <p css={styles.clearBtn} onClick={handleClearAllOrders}>
                      {t('Clear')}
                    </p>
                  </div>
                ),
              },
              // Tags
              {
                trigger: { children: <h2 css={styles.title}>{t('Tags')}</h2> },
                value: 'tags',
                content: (
                  <div css={styles.tagSelector}>
                    <MultiTagsSearchSelector
                      placeholder="VIP,etc"
                      isCreatable={false}
                      // @ts-ignore type is TagsValue[]
                      value={tags}
                      setFieldValue={hangleTagsChange}
                    />
                  </div>
                ),
              },
            ]}
          />
        </div>
      </div>
    </Drawer>
  );
};
MoreFilters.displayName = 'MoreFilters';

const styles = {
  title: css`
    font-weight: 600;
    font-size: 14px;
    color: #27313b;
  `,

  wrapper: css`
    display: flex;
    flex-direction: column;
  `,

  form: css`
    height: 100%;
  `,
  submitBtn: css`
    width: fit-content;
    line-height: 32px;
    border-radius: 3px;
    padding: 0 24px;
  `,
  summary: css`
    height: 48px !important;
    min-height: 48px !important;
    padding: 0 !important;
  `,
  heading: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #dee5ec;
  `,
  headingText: css`
    font-weight: 600;
    font-size: 18px;
    color: #27313b;
  `,
  label: css`
    font-weight: 600;
    font-size: 14px;
    color: #27313b;
  `,
  container: css`
    width: 336px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  `,
  button: css`
    @media (max-width: ${ViewportType.TABLET}px) {
      display: flex;
      flex: 1;
    }
  `,
  footer: css`
    background-color: #f6f8fa;
    padding: 24px 24px 24px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
  `,
  expansion: css`
    background-color: red;

    &.MuiExpansionPanel-root {
      position: unset;
    }

    & .MuiExpansionPanelSummary-expandIcon {
      margin-right: -8px !important;
    }

    &::before {
      height: 0;
    }

    &:last-of-type {
      border-bottom: none;
    }
  `,
  textForm: css`
    width: 240px;
    margin: 0 0 16px 26px;

    /* stylelint-disable */
    & input {
      border-radius: 3px;
      height: 32px;
    }

    label {
      padding-left: 0;
    }
    /* stylelint-enable */
  `,
  checkbox: css`
    padding: 0 0 4px 0;

    label {
      margin-right: 0;
      align-items: flex-start;
    }
  `,
  clearBtn: css`
    margin: 8px 0;
    color: #3892e5;
    font-size: 14px;
    cursor: pointer;
  `,
  filterCount: css`
    width: 24px;
    line-height: 24px;
    border-radius: 50%;
    text-align: center;
    font-size: 12px;
    background-color: #fff;
    font-weight: 600;
  `,
  filterIcon: css`
    border-color: #3892e5;
    background-color: #ecf3fb;

    .btn-text {
      padding: 0;
      color: #3892e5;
    }
  `,
  tagSelector: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 40px;
  `,
};
