import { useQuery } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import GET_CREATOR_STAFFS from './queries/GetCreatorStaffs.graphql';
import { GetCreatorStaffs, GetCreatorStaffsVariables } from './queries/__generated__/GetCreatorStaffs';

interface UseGetCreatorStaffsProps {
  limit: number;
  offset: number;
}

const useGetCreatorStaffs = ({ limit, offset }: UseGetCreatorStaffsProps) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  const { data } = useQuery<GetCreatorStaffs, GetCreatorStaffsVariables>(GET_CREATOR_STAFFS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      limit,
      offset,
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  return { data };
};

export default useGetCreatorStaffs;
