import React from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import searchJobBackgroundImg from '@src/assets/img/searchJobBackground.png';
import { ThemeButton } from '@src/components/atoms/Button';
import { DialogModal } from '@src/components/molecules/DialogModal';
import { ListIndicator } from '@src/components/molecules/Indicator';
import CampaignDetails from '@src/components/organisms/CastingMarketplace/YourJob/CampaignDetails';
import AffiliateJoinButton from '@src/components/organisms/CastingMarketplace/YourJob/CampaignDetails/AffiliateJoinButton';
import JoinButton from '@src/components/organisms/CastingMarketplace/YourJob/CampaignDetails/JoinButton';
import PostButton from '@src/components/organisms/CastingMarketplace/YourJob/CampaignDetails/PostButton';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/theme';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import { generatePath, ROUTES } from '@src/shared/routes';
import {
  AnyXMarketplaceCampaignStatus,
  CampaignStatusForInfluencer,
  MarketplaceCampaignAppliedStatus,
  MarketplaceCampaignType,
} from '@src/__generated__/globalTypes';
import useCampaign from './useCampaign';

const Details = () => {
  const { history, params, path, pathname, search, t } = useQueryHelper();
  const queryString = new URLSearchParams(search);
  const isDialogOpen = !!queryString.get('outOfCoupon');
  const { isMobileView } = usePageLayout();
  const campaignId = Number(params.id);
  const backUrl = pathname.split('/');
  backUrl.pop();
  const { data, loading } = useCampaign(campaignId);

  const campaignDetails = data?.marketplaceForInfluencer;

  if (loading) {
    return <ListIndicator />;
  } else if (!campaignDetails) {
    history.push(backUrl.join('/'));

    return null;
  }

  const {
    ableToJoin,
    anyXStatus,
    appliedStatus,
    campaignType,
    isApplied,
    isGiftingCampaign,
    isJoined,
    remainingAmount,
    revenuePerPost,
    selectionEndDate,
    status,
  } = campaignDetails;
  const hasJoinButton =
    !isApplied && // For selection marketplace
    !isJoined && // For normal marketplace
    [
      CampaignStatusForInfluencer.ONGOING,
      CampaignStatusForInfluencer.OUT_OF_COUPON,
      CampaignStatusForInfluencer.UPCOMING,
    ].includes(status);
  const hasPostButton = anyXStatus === AnyXMarketplaceCampaignStatus.POSTING;
  const hasRemainingAmount = remainingAmount >= (revenuePerPost || 0);
  const hasReportButton = [
    AnyXMarketplaceCampaignStatus.APPROVED,
    AnyXMarketplaceCampaignStatus.EFFECTIVE,
    AnyXMarketplaceCampaignStatus.INEFFECTIVE,
    AnyXMarketplaceCampaignStatus.REVIEWING,
    AnyXMarketplaceCampaignStatus.WARNING_EFFECTIVE,
  ].includes(anyXStatus as AnyXMarketplaceCampaignStatus);
  const isAffiliateCampaign = campaignType === MarketplaceCampaignType.AFFILIATE;
  const isApplying = appliedStatus === MarketplaceCampaignAppliedStatus.APPLYING;
  const isCampaignFinish =
    anyXStatus === AnyXMarketplaceCampaignStatus.INEFFECTIVE ||
    appliedStatus === MarketplaceCampaignAppliedStatus.REJECTED ||
    status === CampaignStatusForInfluencer.FINISHED;
  const isRejected =
    appliedStatus === MarketplaceCampaignAppliedStatus.REJECTED ||
    anyXStatus === AnyXMarketplaceCampaignStatus.INEFFECTIVE;
  const isSearchPage = path === ROUTES.MARKETPLACE_SEARCH_JOB_ID;
  const isSelection = campaignType === MarketplaceCampaignType.SELECTION;
  const isSelectionFinish = isSelection
    ? new Date(new Date().setHours(0, 0, 0, 0)) > new Date(selectionEndDate)
    : false;

  const onCloseDialog = () => {
    history.push(generatePath(ROUTES.MARKETPLACE_SEARCH_JOB_ID, { id: params.id }));
  };

  return (
    <>
      <DialogModal
        closeIconCss={{
          backgroundColor: 'transparent',
          color: '#6e7C89',
          right: 0,
          top: 0,
        }}
        contentStyle={{
          borderRadius: 5,
          boxShadow: '0 2px 1px rgba(110, 124, 137, 0.1), 0 3px 5px rgba(110, 124, 137, 0.2)',
          height: isMobileView ? 591 : 424,
          maxWidth: 840,
          position: 'relative',
          width: '90%',
        }}
        isOpen={isDialogOpen}
        closeModal={onCloseDialog}
        footerNode={null}
      >
        <div css={styles.dialogContent}>
          <div>
            <div>
              <img height="124" src={searchJobBackgroundImg} width="120" />
            </div>
            <div>
              <div>{t('Sorry')}</div>
              <div>{t('Annotation.The campaign is temporarily closed')}</div>
            </div>
            <div>{t('Annotation.Please wait until the campaign information will be updated')}</div>
            <div>
              <ThemeButton text="Close" onClick={onCloseDialog} />
            </div>
          </div>
        </div>
      </DialogModal>
      <div css={styles.container}>
        {!isMobileView && (
          <div css={styles.backNavigatorContainer}>
            <div>
              <BackNavigator
                title="Campaigns Detail"
                to={`${backUrl.join('/')}${
                  !hasJoinButton && (isRejected || status === CampaignStatusForInfluencer.FINISHED) ? '#finished' : ''
                }`}
              />
            </div>
            {!isApplying &&
              !isRejected &&
              (!isSelectionFinish && hasJoinButton ? (
                <div>
                  {isAffiliateCampaign ? (
                    <AffiliateJoinButton campaignDetails={campaignDetails} />
                  ) : (
                    <JoinButton campaignDetails={campaignDetails} isAbleToJoin={ableToJoin} isSelection={isSelection} />
                  )}
                </div>
              ) : !isSearchPage && !isCampaignFinish && hasPostButton && (hasRemainingAmount || isGiftingCampaign) ? (
                <div>
                  <PostButton campaignDetails={campaignDetails} campaignId={campaignId} />
                </div>
              ) : !isSearchPage && hasReportButton ? (
                <div>
                  <Link
                    css={styles.viewReportBtn}
                    to={isAffiliateCampaign ? `${pathname}/affiliate_report` : `${pathname}/report`}
                  >
                    {t('Button.View Report')}
                  </Link>
                </div>
              ) : null)}
          </div>
        )}

        {campaignDetails && <CampaignDetails campaignDetails={campaignDetails} />}
      </div>

      {isMobileView &&
        !isApplying &&
        !isRejected &&
        (!isSelectionFinish && hasJoinButton ? (
          <ActionContainer>
            {isAffiliateCampaign ? (
              <AffiliateJoinButton campaignDetails={campaignDetails} />
            ) : (
              <>
                <JoinButton campaignDetails={campaignDetails} isAbleToJoin={ableToJoin} isSelection={isSelection} />
                {status === CampaignStatusForInfluencer.OUT_OF_COUPON && (
                  <div>{`*${t('Annotation.Please wait until campaign is updated')}`}</div>
                )}
              </>
            )}
          </ActionContainer>
        ) : !isSearchPage && !isCampaignFinish && hasPostButton && (hasRemainingAmount || isGiftingCampaign) ? (
          <ActionContainer>
            <PostButton campaignDetails={campaignDetails} campaignId={campaignId} height={40} />
          </ActionContainer>
        ) : !isSearchPage && hasReportButton ? (
          <ActionContainer>
            <div>
              <Link
                css={styles.viewReportBtn}
                to={isAffiliateCampaign ? `${pathname}/affiliate_report` : `${pathname}/report`}
              >
                {t('Button.View Report')}
              </Link>
            </div>
          </ActionContainer>
        ) : null)}
    </>
  );
};

const ActionContainer = styled.div`
  align-items: center;
  background-color: #fff;
  bottom: 0;
  box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.2);
  display: grid;
  gap: 4px;
  padding: 16px 0;
  position: fixed;
  width: fill-available;

  & > div:nth-of-type(1) {
    display: flex;
    flex-basis: 100%;
    justify-content: flex-end;
    margin: 0 16px;

    & > button {
      width: 144px;

      @media (max-width: ${ViewportType.SMALL}px) {
        height: 40px;
        width: fill-available;
      }
    }

    & > a {
      @media (max-width: ${ViewportType.SMALL}px) {
        height: 40px;
        width: fill-available;
      }
    }
  }

  & > div:nth-of-type(2) {
    color: #6e7c89;
    display: flex;
    flex-basis: 100%;
    font-size: 10px;
    justify-content: flex-end;
    margin: 0 16px;
  }
`;

const styles = {
  backNavigatorContainer: css`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;

    /* stylelint-disable no-descending-specificity */
    & > div {
      display: flex;
      flex-basis: 50%;
    }

    & > div:nth-of-type(2) {
      justify-content: flex-end;
    }
  `,
  container: css`
    margin: 16px;

    @media (max-width: ${ViewportType.SMALL}px) {
      margin: 0;
    }
  `,
  dialogContent: css`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 0 32px;

    & > div {
      & > div:nth-of-type(1) {
        display: flex;
        flex-basis: 100%;
        justify-content: center;
        margin-bottom: 32px;
      }

      & > div:nth-of-type(2) {
        align-items: center;
        display: flex;
        flex-basis: 100%;
        margin-bottom: 16px;
        text-align: center;

        @media (max-width: ${ViewportType.TABLET}px) {
          display: grid;
        }

        & > div:nth-of-type(1) {
          color: #27313b;
          font-size: 18px;

          @media (max-width: ${ViewportType.TABLET}px) {
            font-size: 22px;
          }
        }

        & > div:nth-of-type(2) {
          color: #27313b;
          font-size: 18px;

          @media (max-width: ${ViewportType.TABLET}px) {
            font-size: 14px;
          }
        }
      }

      & > div:nth-of-type(3) {
        color: #6e7c89;
        font-size: 14px;
        margin-bottom: 24px;
        text-align: center;
      }

      & > div:nth-of-type(4) {
        display: flex;
        justify-content: center;

        & > button {
          height: 40px;
          width: fit-content;
        }

        @media (max-width: ${ViewportType.TABLET}px) {
          border-top: 1px solid #dee5ec;
          bottom: 0;
          left: 0;
          padding: 16px;
          position: absolute;
          width: fill-available;

          & > button {
            width: 100%;
          }
        }
      }
    }
  `,
  viewReportBtn: css`
    align-items: center;
    background-color: #fff;
    border: 1px solid #dee5ec;
    border-radius: 5px;
    color: #6e7c89;
    display: flex;
    font-size: 13px;
    font-weight: 600;
    height: 32px;
    justify-content: center;
    width: 144px;
  `,
};

export default Details;
