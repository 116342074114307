export const ROUTES = {
  ROOT: '/',
  //   sign_up
  SIGN_UP: '/signup',
  SIGN_UP_EMAIL: '/signup/email',
  SIGN_UP_IG: '/signup/instagram',
  SIGN_UP_IG_UNABLE_CONNECT: '/signup/instagram_unable_connect',
  SIGN_UP_TIKTOK_GRANT_PERMISSION: '/signup/tiktok_grant_permission',
  SIGN_UP_FACEBOOK_GRANT_PERMISSION: '/signup/facebook_grant_permission',
  SIGN_UP_SUCCESS: '/signup/success',
  SIGN_UP_PROFILE: '/signup/profile',
  SIGN_UP_CONNECT: '/signup/connect',
  //   sign_in
  SIGN_IN: '/signin',
  SIGN_IN_IG_UNABLE_CONNECT: '/signin/instagram_unable_connect',
  SIGN_IN_TIKTOK_GRANT_PERMISSION: '/signin/tiktok_grant_permission',
  SIGN_IN_FACEBOOK_GRANT_PERMISSION: '/signin/facebook_grant_permission',
  SIGN_IN_FAILED_CONNECT: '/signin/failed_connect',
  SIGN_IN_EMAIL: '/signin/email',
  SIGN_IN_STAFF: '/signin/staff',
  //   password
  PASSWORD_FORGOT: '/password_forgot',
  PASSWORD_REGISTER_TOKEN: '/password_register/:token',
  PASSWORD_RESET_TOKEN: '/password_reset/:token',
  //   redirect
  REDIRECT_SIGN_UP_ENABLED_PROVIDER: '/redirect/signup-enabled/:provider',
  REDIRECT_RECONNECT_IG: '/redirect/reconnect_ig',
  REDIRECT_TIKTOK_RECONNECT: '/redirect/tiktok_reconnect/:provider',
  REDIRECT_TWITTER: '/redirect/twitter',
  REDIRECT_SIGN_UP_PROVIDER: '/redirect/signup/:provider',
  REDIRECT_SIGN_IN_PROVIDER: '/redirect/signin/:provider',
  REDIRECT_SIGN_UP_CONNECT_PROVIDER: '/redirect/signup-connect/:provider',
  REDIRECT_CONNECT_PROVIDER: '/redirect/connect/:provider',
  REDIRECT_JOIN_CAMPAIGN_PROVIDER: '/redirect/join_campaign/:provider',
  REDIRECT_ANALYTICS_RECONNECT_PROVIDER: '/redirect/analytics-reconnect/:provider',
  REDIRECT_SHOPIFY_CONNECT: '/redirect/shopify_connect',
  REDIRECT_SIGN_IN_WITH_LINE_PROVIDER: '/redirect/signin_with_line/:provider',
  REDIRECT_LINK_IN_BIO_CONNECT: '/redirect/link_in_bio_connect/:provider',
  REDIRECT_MOBILE_TIKTOK_CONNECT: '/redirect/mobile_tiktok_connect/:provider',
  REDIRECT_TTCM_RECONNECT: '/redirect/ttcm_reconnect/:provider',
  //   reconnect
  RECONNECT: '/reconnect',
  //   home
  HOME: '/home',
  //   Link In Bio
  LINK_IN_BIO: '/link_in_bio',
  LINK_IN_BIO_LINKS: '/link_in_bio/links',
  LINK_IN_BIO_THEME_COLOR: '/link_in_bio/theme_color',
  //   Analytics
  ANALYTICS: '/analytics',
  ANALYTICS_LINK_IN_BIO: '/analytics/mylink',
  ANALYTICS_ACTIVE_TAB: '/analytics:active_tab?',
  ANALYTICS_COMPARE: '/analytics:compare',
  ANALYTICS_IG_POST_FEED: '/analytics/instagram_feed_post',
  ANALYTICS_IG_REEL_POSTS: '/analytics/instagram_reel_posts',
  ANALYTICS_IG_STORY_FEED: '/analytics/instagram_story_feed',
  ANALYTICS_FB_POST_FEED: '/analytics/facebook_feed_post',
  ANALYTICS_YT_VIDEOS_FEED: '/analytics/feed_videos',
  ANALYTICS_YT_TAG_RANKING_LIST: '/analytics/tag_ranking_list',
  ANALYTICS_YT_COMPARE_VIDEOS_FEED: '/analytics:compare/youtube_compare_feed_videos',
  ANALYTICS_IG_INTERACTION: '/analytics:ig_interaction',
  ANALYTICS_IG_INTERACTION_USER_ANALYSIS: '/analytics:ig_interaction/user_analysis',
  ANALYTICS_IG_INTERACTION_TAGGED_POSTS: '/analytics:ig_interaction/tagged_posts',
  ANALYTICS_IG_INTERACTION_MENTIONED_POSTS: '/analytics:ig_interaction/mentioned_posts',
  // initial campaign routes
  INITIAL_ENGAGEMENT_JOB_ID: '/job/engagement/:id',
  INITIAL_ENGAGEMENT_JOB_ID_POST: '/job/engagement/:id/post',
  INITIAL_ENGAGEMENT_JOB_ID_REPORT: '/job/engagement/:id/report',
  INITIAL_MARKETPLACE_JOB_ID: '/job/marketplace/:id',
  INITIAL_MARKETPLACE_SEARCH: '/search',
  INITIAL_MARKETPLACE_SEARCH_ID: '/search/marketplace/:id',
  //   marketplace
  MARKETPLACE: '/marketplace',
  MARKETPLACE_SEARCH_JOB: '/marketplace/search_job',
  MARKETPLACE_SEARCH_JOB_ID: '/marketplace/search_job/:id',
  MARKETPLACE_YOUR_JOB: '/marketplace/your_job',
  MARKETPLACE_YOUR_JOB_ID: '/marketplace/your_job/:id',
  MARKETPLACE_YOUR_JOB_ID_REPORT: '/marketplace/your_job/:id/report',
  MARKETPLACE_YOUR_JOB_ID_AFFILIATE_REPORT: '/marketplace/your_job/:id/affiliate_report',
  // casting
  CASTING: '/casting',
  CASTING_YOUR_JOB: '/casting/your_job',
  CASTING_YOUR_JOB_ID: '/casting/your_job/:id',
  CASTING_YOUR_JOB_ID_POST: '/casting/your_job/:id/post',
  CASTING_YOUR_JOB_ID_POST_ADD: '/casting/your_job/:id/post/add',
  CASTING_YOUR_JOB_ID_POST_EDIT: '/casting/your_job/:id/post/:postId',
  CASTING_YOUR_JOB_ID_REPORT: '/casting/your_job/:id/report',
  //   fan management
  FANS: '/fans',
  FANS_ADD: '/fans/add',
  FANS_EDIT: '/fans/:id',
  FANS_EDIT_DETAILS: '/fans/:id/details',
  FANS_EDIT_ACTIVITY: '/fans/:id/activity',
  FANS_EDIT_ORDERS: '/fans/:id/orders',
  FANS_EDIT_FORMS: '/fans/:id/forms',
  FANS_EDIT_FORMS_SUBMISSION_ID: '/fans/:id/forms/:submissionId',
  //   emails
  EMAILS: '/emails',
  EMAILS_COMPOSE: '/emails/compose',
  EMAILS_EDIT_COMPOSE: '/emails/:id/compose',
  EMAILS_EDIT_OVERVIEW: '/emails/:id/overview',
  EMAILS_EDIT_RECIPIENT: '/emails/:id/recipient',
  // message chat | broadcast
  MESSAGE: '/message',
  CHAT: '/message/chat',
  CHAT_ACTIVE_ID: '/message/chat/:activeChat?',
  CHAT_ACTIVE_ID_FAN_ID: '/message/chat/:activeChat?/:fanId',
  CHAT_ACTIVE_ID_FAN_ID_FAN_DETAILS: '/message/chat/:activeChat?/:fanId?/fan_detail',
  CHAT_ACTIVE_ID_FAN_ID_ACTIVITY: '/message/chat/:activeChat?/:fanId?/activity',
  CHAT_ACTIVE_ID_FAN_ID_ORDERS: '/message/chat/:activeChat?/:fanId?/orders',
  //   broadcast
  BROADCAST: '/message/broadcast',
  BROADCAST_ADD: '/message/broadcast/add',
  BROADCAST_EDIT: '/message/broadcast/:id',
  //   forms
  FORMS: '/forms',
  FORMS_ADD: '/forms/add',
  FORMS_ADD_LIVE_PREVIEW: '/forms/add/live_preview',
  FORMS_EDIT_ENTRY: '/forms/:id/entry',
  FORMS_EDIT_REPORT: '/forms/:id/report',
  FORMS_EDIT_REPORT_RESPONDENT: '/forms/:id/report/respondent',
  FORMS_EDIT_REPORT_INDIVIDUAL: '/forms/:id/report/individual',
  FORMS_EDIT_REPORT_INDIVIDUAL_PAGE: '/forms/:id/report/individual/:page',
  FORMS_EDIT_LIVE_PREVIEW: '/forms/:id/live_preview',
  FORM_HASH: '/form/:hash',
  //   notification
  NOTIFICATION: '/notification',
  //   settings
  SETTINGS: '/settings',
  SETTINGS_ACCOUNT: '/settings/account',
  SETTINGS_ADDRESS: '/settings/address',
  SETTINGS_SOCIAL_CONNECT: '/settings/social_connect',
  SETTINGS_SOCIAL_CONNECT_ADD_METHODS: '/settings/social_connect/add_methods',
  SETTINGS_SOCIAL_CONNECT_IG_UNABLE_CONNECT: '/settings/social_connect/instagram_unable_connect',
  SETTINGS_SOCIAL_CONNECT_TIKTOK_GRANT_PERMISSION: '/settings/social_connect/tiktok_grant_permission',
  SETTINGS_SOCIAL_CONNECT_FACEBOOK_GRANT_PERMISSION: '/settings/social_connect/facebook_grant_permission',
  SETTINGS_PAYMENT_INFORMATION: '/settings/payment_information',
  SETTINGS_USERS: '/settings/users',
  SETTINGS_USERS_USER_ID: '/settings/users/:userId',
  SETTINGS_SNS_BUDGET: '/settings/sns_budget',
  SETTINGS_CHANNEL_CONNECT: '/settings/channel_connect',
  //   payment
  PAYMENT: '/payment',
  PAYMENT_HISTORY: '/payment/history',
  //   liff
  LIFF: '/liff',
  LIFF_ID: '/liff/:liffId',
  LIFF_SIGN_IN_ID: '/liff_signin/:liffIdd',
  // mobile
  MOBILE_TIKTOK_CONNECT: '/mobile_tiktok_connect',
  MOBILE_TIKTOK_GRANT_PERMISSION: '/mobile_tiktok_grant_permission',
  // contact
  CONTACT: '/contact',
  CONTACT_THANKS: '/contact/thanks',
  // terms & privacy
  TERMS: '/terms',
  PRIVACY: '/privacy',
} as const;

export { generatePath } from 'react-router-dom';
