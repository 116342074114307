import styled from '@emotion/styled';
import * as React from 'react';
import { useQueryHelper } from '../../../libs/hooks';
import Indicator from './Indicator';

interface ListIndicatorProps {
  border?: boolean;
  height?: string;
  text?: string;
  className?: string;
}

const ListIndicator = ({ border = true, height, text, className }: ListIndicatorProps) => {
  const { t } = useQueryHelper();

  return (
    <Wrapper border={border} height={height} className={className}>
      {text ? <Text>{t(text)}</Text> : null}
      <Indicator />
    </Wrapper>
  );
};

const Wrapper = styled.section<ListIndicatorProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => height || '360px'};
  background-color: #fff;
  ${({ border }) =>
    border &&
    `
    border: 1px solid #efefef;
    border-radius: 2px;
  `};
`;
const Text = styled.span`
  margin-bottom: 16px;
  font-size: 14px;
`;

export default ListIndicator;
