import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Icomoon from '@src/components/atoms/Icomoon';
import SearchForm from '@src/components/molecules/SearchForm';
import { Popover, PopoverPrimitives } from '@src/components/molecules/Popover';
import { css } from '@emotion/react';
import TextCutter from '@src/components/atoms/TextCutter';
import { CRMChatTypeFilter } from '@src/__generated__/globalTypes';
import useDimensions from '@src/libs/hooks/useDimensions';
import { checkmarkCode } from '@src/assets/htmlCodes';
import { useDirLayout } from '@src/libs/hooks';
import { ChatFilterFormValues, LineChatCountsType } from '../types';

export interface ChatListFilterProps extends LineChatCountsType {
  onKeywordSearch: (keyword: string) => void;
  filter: ChatFilterFormValues;
  setFilter: React.Dispatch<React.SetStateAction<ChatFilterFormValues>>;
  disabled?: boolean;
}

const ChatListFilter = ({
  totalNum = 0,
  assignedToMeNum = 0,
  setFilter,
  onKeywordSearch,
  filter: { type, keyword },
  disabled,
}: ChatListFilterProps) => {
  const [showSearch, setShowSearch] = useState(false);
  const { t } = useTranslation();
  const filterRef = useRef<HTMLDivElement | null>(null);
  const { width } = useDimensions(filterRef);
  const { isRtl } = useDirLayout();

  const options = [
    { value: CRMChatTypeFilter.ASSIGNED_TO_ME, label: 'Assigned to me', count: assignedToMeNum },
    { value: CRMChatTypeFilter.ACTIVATED, label: 'All', count: totalNum },
  ];

  const selectedOption = options.find(opt => opt.value === type);

  return (
    <div css={styles.wrapper} className={disabled ? 'disabled' : ''}>
      <div css={{ flex: 1 }} ref={filterRef}>
        {showSearch ? (
          <SearchForm
            placeholder={t('search')}
            autoFocus
            value={keyword}
            onChange={e => {
              const inputText = e.target.value;
              setFilter(prevState => ({ ...prevState, keyword: inputText }));
              onKeywordSearch(inputText);
            }}
          />
        ) : (
          <Popover
            renderTrigger={() => (
              <div css={styles.popupTrigger} className={disabled ? 'disabled' : ''}>
                <Icomoon css={styles.filterDecs} icon="filter" size={24} />
                {selectedOption ? (
                  <TextCutter
                    lines={1}
                    text={`${t(selectedOption.label)} (${selectedOption.count})`}
                    css={styles.filterText}
                  />
                ) : (
                  '-'
                )}
                <Icomoon className="arrow-down" css={[styles.arrowDown]} icon="arrow-down" />
              </div>
            )}
            renderButtonBlock={() => null}
            side="bottom"
            align={isRtl ? 'end' : 'start'}
          >
            <div css={{ width: `${width}px` }} tabIndex={0}>
              {options.map(option => {
                const isSelected = option.value === type;

                return (
                  <PopoverPrimitives.Close
                    key={option.value}
                    css={[styles.popupItem, isSelected ? { backgroundColor: '#f6f8fa' } : '']}
                    onClick={() => {
                      setFilter(prevState => ({ ...prevState, type: option.value }));
                    }}
                  >
                    <div css={styles.checkmark}>{isSelected ? checkmarkCode : null}</div>
                    <div css={[styles.optionText]}>
                      {/* {option.status ? <span css={styles.optionStatus}>{t(option.status)}</span> : null} */}
                      <span
                        css={[
                          styles.optionLabel,
                          isSelected
                            ? css`
                                color: #3892e5;
                                font-weight: 600;
                              `
                            : '',
                        ]}
                      >
                        {`${t(option.label)} (${option.count})`}
                      </span>
                    </div>
                  </PopoverPrimitives.Close>
                );
              })}
            </div>
          </Popover>
        )}
      </div>
      <div css={styles.iconBox} className={disabled ? 'disabled' : ''}>
        {showSearch ? (
          <Icomoon
            color="#808080"
            icon="clear"
            onClick={() => {
              setShowSearch(false);
              setFilter(prevState => ({ ...prevState, keyword: '' }));
              onKeywordSearch('');
            }}
          />
        ) : (
          <Icomoon icon="search" onClick={() => setShowSearch(true)} />
        )}
      </div>
    </div>
  );
};

const styles = {
  wrapper: css`
    display: flex;
    padding: 8px;
    column-gap: 8px;
    background-color: #fff;

    &.disabled {
      opacity: 0.3;
      background-color: #c5d0da;
    }
  `,
  popupTrigger: css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    border: 1px solid #dee5ec;
    box-sizing: border-box;
    border-radius: 3px;
    background-color: #fff;

    &.disabled {
      pointer-events: none;
      background-color: inherit;
    }

    &[data-state='open'] {
      .arrow-down {
        transform: rotate(180deg);
      }
    }
  `,
  filterDecs: css`
    margin: 0 8px 0 4px;
  `,
  filterText: css`
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #27313b;
  `,
  checkmark: css`
    color: #3892e5;
    width: 24px;
    text-align: center;
  `,
  optionText: css`
    display: flex;
    flex-direction: column;
  `,
  optionLabel: css`
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
  `,
  optionStatus: css`
    font-size: 10px;
    color: #6e7c89;
  `,
  popupItem: css`
    display: flex;
    align-items: center;
    height: 48px;
    padding-right: 16px;
    cursor: pointer;
    width: 100%;

    &:hover {
      background-color: #dee5ec;
    }

    [dir='rtl'] & {
      padding: 0 0 0 16px;
    }
  `,
  iconBox: css`
    align-items: center;
    display: flex;
    width: 32px;
    height: 32px;
    background: #fff;
    border: 1px solid #dee5ec;
    box-sizing: border-box;
    border-radius: 3px;
    justify-content: center;

    &.disabled {
      pointer-events: none;
      background-color: inherit;
    }

    &:hover {
      border-color: lightgrey;
      cursor: pointer;
    }
  `,
  arrowDown: css`
    margin-left: auto;
    margin-right: 4px;
    transition: all 0.2s ease-in-out;

    [dir='rtl'] & {
      margin-right: auto;
      margin-left: 4px;
    }
  `,
};

export default ChatListFilter;
