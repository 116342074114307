import { lazy } from 'react';

export const SignIn = lazy(() => import('../SignIn'));
export const SignUp = lazy(() => import('../SignUp'));
export const SignInEmail = lazy(() => import('../SignInEmail'));
export const SignUpEmail = lazy(() => import('../SignUpEmail'));
export const SignUpInstagram = lazy(() => import('../SignUpInstagram'));
export const SignInInstagramUnableConnect = lazy(() => import('../SignInInstagramUnableConnect'));
export const SignInTiktokGrantPermission = lazy(() => import('../SignInTiktokGrantPermission'));
export const SignInFacebookGrantPermission = lazy(() => import('../SignInFacebookGrantPermission'));
export const SignUpSuccess = lazy(() => import('../SignUpEmail/SignUpSuccess'));
export const SignUpInstagramUnableConnect = lazy(() => import('../SignUpInstagramUnableConnect'));
export const SignUpTiktokGrantPermission = lazy(() => import('../SignUpTiktokGrantPermission'));
export const SignUpFacebookGrantPermission = lazy(() => import('../SignUpFacebookGrantPermission'));
export const SignUpProfile = lazy(() => import('../SignUpProfile'));
export const SignUpConnect = lazy(() => import('../SignUpConnect'));
export const SignInFailedConnect = lazy(() => import('../SignIn/FailedConnect'));
export const SignInStaff = lazy(() => import('../SignInStaff'));
