import styled from '@emotion/styled';
import { css } from '@emotion/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import useDoubleClick from '@src/libs/hooks/useDoubleClick';
import { arrowDropDown } from '@src/assets/htmlCodes';
import { Tooltip } from '../Tooltip';
import TextCutter from '../TextCutter';

interface HeaderColumnProps {
  className?: string;
  title: string;
  colSpan?: number;
  activeAsc?: boolean;
  activeDesc?: boolean;
  sortAsc?: () => void;
  sortDesc?: () => void;
  textAlign?: 'left' | 'right' | 'center' | 'justify' | 'initial' | 'inherit';
  help?: string;
  isTable?: boolean;
  handleSingleClick?: () => void;
  handleDoubleClick?: () => void;
  children?: React.ReactNode;
  justifyContent?: 'flex-end' | 'flex-start' | 'center';
}

const HeaderColumn = (props: HeaderColumnProps) => {
  const { t } = useTranslation();
  const {
    className,
    title,
    colSpan,
    activeAsc,
    activeDesc,
    sortAsc,
    sortDesc,
    textAlign,
    help,
    isTable = true,
    handleDoubleClick,
    handleSingleClick,
    children,
  } = props;
  const { onSingleClick, onDoubleClick } = useDoubleClick({
    singleClickCb: handleSingleClick,
    doubleClickCb: handleDoubleClick,
  });

  const translatedTitle = title ? t(`HeaderColumn.${title}`) : '';
  const Wrapper = isTable ? TableWrapper : DivWrapper;
  const hasClickSorting = !!(handleSingleClick && handleDoubleClick);

  return (
    <Wrapper className={className} colSpan={colSpan} textAlign={textAlign}>
      {!!sortAsc && !!sortDesc ? (
        <FlexWrapper>
          {help && <Tooltip help={help} placement="top" />}
          <span
            onClick={onSingleClick}
            onDoubleClick={onDoubleClick}
            css={
              hasClickSorting
                ? css`
                    cursor: pointer;
                  `
                : ''
            }
          >
            {translatedTitle}
          </span>
          <SortWrapper>
            <span css={{ color: activeAsc ? '#3892e5' : '#9b9b9b', transform: 'rotate(180deg);' }} onClick={sortAsc}>
              {arrowDropDown}
            </span>
            <span css={[{ color: activeDesc ? '#3892e5' : '#9b9b9b' }]} onClick={sortDesc}>
              {arrowDropDown}
            </span>
          </SortWrapper>
        </FlexWrapper>
      ) : (
        <FlexWrapper>
          {help ? (
            <FullSizeAligned textAlign={textAlign}>
              <Tooltip help={help} placement="top" iconMargin="0" />
              {translatedTitle}
            </FullSizeAligned>
          ) : (
            <FullSize text={translatedTitle} lines={1} />
          )}
        </FlexWrapper>
      )}
      {children}
    </Wrapper>
  );
};

const DivWrapper = styled.div<{ textAlign?: string }>`
  padding: 0;
  padding-left: 16px;
  text-align: ${props => props.textAlign || 'left'};
  color: #27313b;
  font-size: 11px;
  font-weight: 600;
  line-height: 48px;
  white-space: nowrap;
`;
const TableWrapper = styled.th<{ textAlign?: string }>`
  padding: 0;
  padding-left: 16px;
  text-align: ${props => props.textAlign || 'left'};
  color: #27313b;
  font-size: 11px;
  font-weight: 600;
  line-height: 48px;
  white-space: nowrap;

  [dir='rtl'] & {
    padding-right: 16px;
    text-align: ${props => props.textAlign || 'right'};
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const SortWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 2px;
  margin: auto 5px;
  line-height: initial;

  & > span {
    height: 16px;
    width: 16px;
    text-align: center;
    font-size: 13px;
    cursor: pointer;

    &:hover {
      filter: brightness(0.8);
    }
  }
`;

const FullSizeAligned = styled.div<{ textAlign?: string }>`
  display: inline-flex;
  justify-content: ${props => {
    if (!props.textAlign) {
      return 'flex-start';
    }

    return props.textAlign === 'left' ? 'flex-start' : props.textAlign === 'right' ? 'flex-end' : 'center';
  }};
  align-items: center;
  width: 100%;
`;

const FullSize = styled(TextCutter)`
  width: 100%;
`;

export default HeaderColumn;
