import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { removeToken } from '@src/libs/auth';
import { SocialAccountType, UserRoles } from '@src/__generated__/globalTypes';
import { ROUTES } from '@src/shared/routes';

export enum BadgeType {
  NOTIFICATION = 'NOTIFICATION',
}

export enum DynamicToType {
  HELP = 'HELP',
}

export enum HiddenType {
  CRM = 'CRM',
  INTERCOM = 'INTERCOM',
}

export enum MenuTitle {
  ANALYTICS = 'Analytics',
  CASTING = 'Casting',
  MESSAGE = 'Message',
  CONTACT = 'Contact',
  EMAILS = 'Emails',
  FANS = 'Fans',
  FORMS = 'Forms',
  HELP = 'Help',
  HOME = 'Home',
  MARKETPLACE = 'Marketplace',
  MY_LINK = 'My Link',
  NOTIFICATION = 'Notification',
  PAYMENT = 'Payment',
  SETTINGS = 'Settings',
  SIGN_OUT = 'Sign Out',
}

export enum SmDisconnectStatusType {
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  TWITTER = 'TWITTER',
  YOUTUBE = 'YOUTUBE',
  TIKTOK = 'TIKTOK',
}

export interface Submenus {
  activeIcon: string;
  icon: string;
  disconnectStatusType?: SmDisconnectStatusType;
  hiddenType?: HiddenType[];
  title: string;
  to: string | string[];
  activeSubmenuIcon?: string;
}

export interface MenuItemProps {
  activeIcon?: string;
  badge?: BadgeType;
  dynamicTo?: DynamicToType;
  icon?: string;
  groupTitle?: string;
  hiddenType?: HiddenType[];
  isDivider?: boolean;
  isNewTab?: boolean;
  rolesToAccess?: UserRoles[];
  submenus?: Submenus[];
  title?: MenuTitle;
  to?: string;
  onClick?: () => void;
}

const onClickSignOut = () => {
  removeToken();
  sendAmplitudeEvent(eventTypes.signOut);
  window.location.replace(ROUTES.ROOT);
};

export const menuWidth = 240;

export const menuItems: MenuItemProps[] = [
  { activeIcon: 'home-blue-light', icon: 'home-grey-light', title: MenuTitle.HOME, to: ROUTES.HOME },
  { groupTitle: 'Social media' },
  {
    activeIcon: 'link-blue-light',
    icon: 'link-grey-light',
    title: MenuTitle.MY_LINK,
    to: ROUTES.LINK_IN_BIO,
  },
  {
    activeIcon: 'thunder-move-blue-light',
    icon: 'thunder-move-grey-light',
    rolesToAccess: [UserRoles.INFLUENCER, UserRoles.CREATOR_STAFF],
    submenus: [
      {
        activeIcon: 'link-blue-light',
        icon: 'link-grey-light',
        activeSubmenuIcon: 'link-blue-light',
        title: 'My Link Analytics',
        to: ROUTES.ANALYTICS_LINK_IN_BIO,
      },
      {
        activeIcon: 'instagram',
        icon: 'instagram',
        activeSubmenuIcon: 'instagram-color',
        disconnectStatusType: SmDisconnectStatusType.INSTAGRAM,
        title: 'Instagram',
        to: `${ROUTES.ANALYTICS}?sm=${SocialAccountType.INSTAGRAM}`,
      },
      {
        activeIcon: 'facebook-round-color',
        icon: 'facebook-round',
        activeSubmenuIcon: 'facebook-round-color',
        disconnectStatusType: SmDisconnectStatusType.FACEBOOK,
        title: 'Facebook',
        to: [
          `${ROUTES.ANALYTICS}?sm=${SocialAccountType.FACEBOOK}`,
          `${ROUTES.ANALYTICS}?sm=${SocialAccountType.FACEBOOK_PAGE}`,
        ],
      },
      {
        activeIcon: 'twitter',
        icon: 'twitter',
        activeSubmenuIcon: 'twitter-color',
        disconnectStatusType: SmDisconnectStatusType.TWITTER,
        title: 'Twitter',
        to: `${ROUTES.ANALYTICS}?sm=${SocialAccountType.TWITTER}`,
      },
      {
        activeIcon: 'youtube',
        icon: 'youtube',
        activeSubmenuIcon: 'youtube-color',
        disconnectStatusType: SmDisconnectStatusType.YOUTUBE,
        title: 'Youtube',
        to: `${ROUTES.ANALYTICS}?sm=${SocialAccountType.YOUTUBE}`,
      },
      {
        activeIcon: 'tiktok',
        icon: 'tiktok',
        activeSubmenuIcon: 'tiktok-color',
        disconnectStatusType: SmDisconnectStatusType.TIKTOK,
        title: 'TikTok',
        to: `${ROUTES.ANALYTICS}?sm=${SocialAccountType.TIKTOK}`,
      },
    ],
    title: MenuTitle.ANALYTICS,
    to: ROUTES.ANALYTICS,
  },
  {
    activeIcon: 'gift-blue-light',
    icon: 'gift-grey-light',
    rolesToAccess: [UserRoles.INFLUENCER],
    submenus: [
      {
        activeIcon: 'clipboard-blue-light',
        icon: 'clipboard-grey-light',
        title: 'Your Job',
        to: ROUTES.MARKETPLACE_YOUR_JOB,
      },
      { activeIcon: 'binocular', icon: 'binocular', title: 'Search Job', to: ROUTES.MARKETPLACE_SEARCH_JOB },
    ],
    title: MenuTitle.MARKETPLACE,
    to: ROUTES.MARKETPLACE,
  },
  {
    activeIcon: 'clipboard-blue-light',
    icon: 'clipboard-grey-light',
    rolesToAccess: [UserRoles.INFLUENCER],
    title: MenuTitle.CASTING,
    to: ROUTES.CASTING,
  },
  { hiddenType: [HiddenType.CRM], groupTitle: 'Fan management' },
  {
    activeIcon: 'users-blue-light',
    hiddenType: [HiddenType.CRM],
    icon: 'users-grey-light',
    title: MenuTitle.FANS,
    to: ROUTES.FANS,
  },
  {
    activeIcon: 'mail',
    hiddenType: [HiddenType.CRM],
    icon: 'mail',
    title: MenuTitle.EMAILS,
    to: '/emails',
  },
  {
    activeIcon: 'chat-blue-light',
    hiddenType: [HiddenType.CRM],
    icon: 'chat-grey-light',
    title: MenuTitle.MESSAGE,
    to: ROUTES.MESSAGE,
    submenus: [
      {
        activeIcon: 'chats-blue-light',
        icon: 'chats-grey-light',
        activeSubmenuIcon: 'chats-blue-light',
        title: 'Chat',
        to: ROUTES.CHAT,
      },
      {
        activeIcon: 'broadcast-blue-light',
        icon: 'broadcast-grey-light',
        activeSubmenuIcon: 'broadcast-blue-light',
        title: 'Broadcast for LINE',
        to: ROUTES.BROADCAST,
      },
    ],
  },
  {
    activeIcon: 'clipboard-check-blue-light',
    hiddenType: [HiddenType.CRM],
    icon: 'clipboard-check-grey-light',
    title: MenuTitle.FORMS,
    to: ROUTES.FORMS,
  },
  { groupTitle: 'Account' },
  {
    activeIcon: 'notification-blue-light',
    badge: BadgeType.NOTIFICATION,
    icon: 'notification-grey-light',
    title: MenuTitle.NOTIFICATION,
    to: ROUTES.NOTIFICATION,
  },
  {
    activeIcon: 'contact',
    icon: 'contact',
    title: MenuTitle.SETTINGS,
    to: ROUTES.SETTINGS,
  },
  {
    activeIcon: 'wallet-blue-light',
    icon: 'wallet-grey-light',
    title: MenuTitle.PAYMENT,
    to: ROUTES.PAYMENT,
  },
  {
    activeIcon: 'headphone-blue-light',
    hiddenType: [HiddenType.INTERCOM],
    icon: 'headphone-grey-light',
    title: MenuTitle.CONTACT,
    to: ROUTES.CONTACT,
  },
  {
    dynamicTo: DynamicToType.HELP,
    icon: 'help-filled',
    isDivider: true,
    isNewTab: true,
    title: MenuTitle.HELP,
  },
  { icon: 'exit-grey-light', title: MenuTitle.SIGN_OUT, onClick: onClickSignOut },
];

export const uuumMenuItems: MenuItemProps[] = [
  { groupTitle: 'Social media' },
  {
    activeIcon: 'thunder-move-blue-light',
    icon: 'thunder-move-grey-light',
    rolesToAccess: [UserRoles.INFLUENCER, UserRoles.CREATOR_STAFF],
    submenus: [
      {
        activeIcon: 'youtube',
        icon: 'youtube',
        activeSubmenuIcon: 'youtube-color',
        disconnectStatusType: SmDisconnectStatusType.YOUTUBE,
        title: 'Youtube',
        to: `${ROUTES.ANALYTICS}?sm=${SocialAccountType.YOUTUBE}`,
      },
      {
        activeIcon: 'instagram',
        icon: 'instagram',
        activeSubmenuIcon: 'instagram-color',
        disconnectStatusType: SmDisconnectStatusType.INSTAGRAM,
        title: 'Instagram',
        to: `${ROUTES.ANALYTICS}?sm=${SocialAccountType.INSTAGRAM}`,
      },
      {
        activeIcon: 'facebook-round-color',
        icon: 'facebook-round',
        activeSubmenuIcon: 'facebook-round-color',
        disconnectStatusType: SmDisconnectStatusType.FACEBOOK,
        title: 'Facebook',
        to: [
          `${ROUTES.ANALYTICS}?sm=${SocialAccountType.FACEBOOK}`,
          `${ROUTES.ANALYTICS}?sm=${SocialAccountType.FACEBOOK_PAGE}`,
        ],
      },
      {
        activeIcon: 'twitter',
        icon: 'twitter',
        activeSubmenuIcon: 'twitter-color',
        disconnectStatusType: SmDisconnectStatusType.TWITTER,
        title: 'Twitter',
        to: `${ROUTES.ANALYTICS}?sm=${SocialAccountType.TWITTER}`,
      },
      {
        activeIcon: 'tiktok',
        icon: 'tiktok',
        activeSubmenuIcon: 'tiktok-color',
        disconnectStatusType: SmDisconnectStatusType.TIKTOK,
        title: 'TikTok',
        to: `${ROUTES.ANALYTICS}?sm=${SocialAccountType.TIKTOK}`,
      },
    ],
    title: MenuTitle.ANALYTICS,
    to: ROUTES.ANALYTICS,
  },
  { groupTitle: 'Account' },
  {
    activeIcon: 'contact',
    icon: 'contact',
    isDivider: true,
    title: MenuTitle.SETTINGS,
    to: ROUTES.SETTINGS,
  },
  { icon: 'exit-grey-light', title: MenuTitle.SIGN_OUT, onClick: onClickSignOut },
];

export const checkIsActivePathname = (path: string, checkIsInitialPage?: boolean) => {
  // split url query string into array
  const params = window.location.search.split(/[?&]+/);

  // to split query string from path and form into array
  const pathParams = path.split('?')[1] ? path.split('?')[1].split(/[?&]+/) : [''];
  const pathWithoutParams = path.split('?')[0];
  // for YT compare URl will look like "analytics:compare?sm=YOUTUBE"
  const pathWithoutCompare = window.location.pathname.split(':')[0];

  return checkIsInitialPage
    ? pathWithoutParams === pathWithoutCompare
    : pathWithoutCompare.includes(pathWithoutParams) && pathParams.some(param => params.includes(param));
};
