import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { css } from '@emotion/core';
import anyCreatorLogo from '@src/assets/icon/anyCreatorLogo.svg';
import profileImg from '@src/assets/img/avatarProfile.svg';
import Avatar from '@src/components/atoms/Avatar';
import { ThemeButton } from '@src/components/atoms/Button';
import Icomoon from '@src/components/atoms/Icomoon';
import { useDirLayout } from '@src/libs/hooks';

export const defaultWidth = 247;

interface EditAccessProps {
  width?: number;
}

const EditAccess = ({ width = defaultWidth }: EditAccessProps) => {
  const { t } = useTranslation();
  const { isRtl } = useDirLayout();
  const quotient = width / defaultWidth;

  return (
    <div css={styles.container(quotient)}>
      <div css={styles.title(quotient)}>{t('Title.Authorize STGAnyCreator to access your TikTok account')}</div>

      <div css={styles.profileContainer(quotient)}>
        <div css={styles.avatarContainer(quotient)}>
          <div>
            <Avatar src={profileImg} />
          </div>
          <div>{t('TikTokGrantPermissionSampleUserName')}</div>
          <div>{t('Switch account')}</div>
        </div>
        <div css={styles.exchangeIconContainer(quotient)}>
          <Icomoon color="#b9b9b9" icon="exchange" size={12} />
        </div>
        <div css={styles.avatarContainer(quotient)}>
          <div>
            <Avatar src={anyCreatorLogo} />
          </div>
          <div>STGAnyCreator</div>
        </div>
      </div>

      <div css={styles.descriptionContainer(quotient)}>
        <div>{t('STGAnyCreator would like to')}</div>
        <ul>
          <li>{t('Annotation.Read your profile info')}</li>
          <li>{t('Annotation.Read your public videos on TikTok without quote symbol')}</li>
        </ul>
        <div>{t('Annotation.TikTok manage settings')}</div>
        <div>
          <div>
            <div>{t('Button.Edit access')}</div>
            <Icomoon css={{ transform: isRtl ? 'rotate(90deg)' : 'rotate(270deg)' }} icon="arrow-down" size={7} />
          </div>
        </div>
      </div>

      <div css={styles.agreement(quotient)}>
        <Trans i18nKey="Annotation.TikTok authorize to agree privacy and terms of services">
          Authorize <span>Privacy Policy</span> and <span>Terms of Services</span>
        </Trans>
      </div>

      <ThemeButton css={styles.authorizeBtn(quotient)} text="Authorize" theme="red" />
      <ThemeButton css={styles.cancelBtn(quotient)} text="Cancel" />
    </div>
  );
};

const styles = {
  agreement: (quotient: number) => css`
    color: #4f4f4f;
    font-size: ${quotient * 5}px;
    margin-bottom: ${quotient * 12}px;

    & > span {
      color: #27313b;
      font-weight: 600;
    }
  `,
  authorizeBtn: (quotient: number) => css`
    background-color: #c0364e;
    height: ${quotient * 24}px;
    margin-bottom: ${quotient * 2}px;
    pointer-events: none;

    & > div > span {
      font-size: ${quotient * 8}px;
    }
  `,
  avatarContainer: (quotient: number) => css`
    display: grid;
    gap: ${quotient * 2}px;
    justify-content: center;
    height: fit-content;
    text-align: center;
    width: fit-content;

    & > div:nth-of-type(1) {
      display: flex;
      justify-content: center;

      & > img {
        height: ${quotient * 35}px;
        width: ${quotient * 35}px;
      }
    }

    & > div:nth-of-type(2) {
      color: #27313b;
      font-size: ${quotient * 6}px;
      font-weight: 600;
    }

    & > div:nth-of-type(3) {
      color: #4f4f4f;
      font-size: ${quotient * 6}px;
    }
  `,
  cancelBtn: (quotient: number) => css`
    height: ${quotient * 24}px;
    pointer-events: none;

    & > div > span {
      color: #27313b;
      font-size: ${quotient * 8}px;
    }
  `,
  container: (quotient: number) => css`
    background-image: linear-gradient(#fff 45%, #f5f5f5);
    border: 1px solid #dee5ec;
    border-radius: 5px;
    height: ${quotient * 288}px;
    padding: ${quotient * 16}px;
    width: ${quotient * 213}px;
  `,
  descriptionContainer: (quotient: number) => css`
    border-radius: 5px;
    background-color: #f8f8f8;
    margin-bottom: ${quotient * 24}px;
    padding: ${quotient * 8}px ${quotient * 8}px ${quotient * 24}px ${quotient * 8}px;
    position: relative;

    & > div:nth-of-type(1) {
      color: #27313b;
      font-size: ${quotient * 6}px;
      font-weight: 600;
      margin-bottom: ${quotient * 2}px;
    }

    & > ul {
      padding: 0 ${quotient * 8}px;

      & > li {
        color: #4f4f4f;
        font-size: ${quotient * 6}px;
        list-style-type: disc;
        margin-bottom: ${quotient * 2}px;
      }
    }

    & > div:nth-of-type(2) {
      color: #6f6f6f;
      font-size: ${quotient * 5}px;
    }

    & > div:nth-of-type(3) {
      bottom: ${quotient * 2}px;
      display: flex;
      justify-content: center;
      position: absolute;
      width: 100%;

      & > div {
        align-items: center;
        border: ${quotient * 4}px solid #ff5f5f;
        border-radius: 5px;
        color: #27313b;
        display: flex;
        font-size: ${quotient * 6}px;
        font-weight: 600;
        justify-content: center;
        padding: ${quotient * 4}px ${quotient * 16}px;

        & > img {
          transform: rotate(270deg);
        }
      }
    }
  `,
  exchangeIconContainer: (quotient: number) => css`
    margin-top: ${quotient * 12}px;

    /* stylelint-disable no-descending-specificity */
    & > img {
      height: ${quotient * 12}px;
      width: ${quotient * 12}px;
    }
  `,
  profileContainer: (quotient: number) => css`
    display: flex;
    gap: ${quotient * 4}px;
    justify-content: center;
    margin-bottom: ${quotient * 16}px;
  `,
  title: (quotient: number) => css`
    color: #27313b;
    font-size: ${quotient * 11}px;
    font-weight: 600;
    margin-bottom: ${quotient * 16}px;
    text-align: center;
  `,
};

export default EditAccess;
