import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ThemeButton } from '@src/components/atoms/Button';
import { CheckBox } from '@src/components/atoms/CheckBox';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import Grid from '@src/components/atoms/Grid';
import Label from '@src/components/atoms/Label';
// import { RadioIcon } from '@src/components/atoms/RadioButtonElement';
import SingleSelectField from '@src/components/molecules/SingleSelectField';
import TextForm from '@src/components/molecules/TextForm';
import { QuestionType } from '@src/__generated__/globalTypes';
import { usePageLayout } from '@src/libs/hooks';

declare const RECAPTCHA_SITE_KEY: string;

interface Questions {
  answeredOptionIds: string[];
  answeredTexts: string[];
  image: string | null;
  isRequired: boolean;
  options: {
    id: string;
    optionTitle: string;
  }[];
  questionId: string;
  questionTitle: string;
  questionType: QuestionType;
}

export interface SubmitFormInformation {
  id: string;
  questions: Questions[];
  recaptchaResponse: string;
}

interface FormSubmitProps {
  description: string;
  formValues: SubmitFormInformation;
  hash?: string;
  isMobileMode?: boolean;
  title: string;
  onSubmit: (information: SubmitFormInformation) => Promise<void>;
}

const SubmitForm = ({ description, formValues, hash, isMobileMode, title, onSubmit }: FormSubmitProps) => {
  const { t } = useTranslation();
  const { isMobileView } = usePageLayout();
  const {
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useFormContext<SubmitFormInformation>();
  const [formId, questions] = watch(['id', 'questions']);

  useEffect(() => {
    if (hash) {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/enterprise.js?render=${RECAPTCHA_SITE_KEY}`;
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  const onChangeTexts = (index: number, value: string, textIndex: number) => {
    const items = [...questions];
    items[index].answeredTexts[textIndex] = value;

    setValue('questions', items);
  };

  const onClickCheckbox = (index: number, checked: boolean, id: string) => {
    const items = [...questions];
    const selectedIds = items[index].answeredOptionIds;
    if (checked) {
      const selectedIndex = selectedIds.findIndex(questionId => questionId === id);
      selectedIds.splice(selectedIndex, 1);
    } else {
      selectedIds.push(id);
    }

    setValue('questions', items);
  };

  const onClickCheckBoxDropdown = (index: number, id: string) => {
    const items = [...questions];
    items[index].answeredOptionIds = [id];

    setValue('questions', items);
  };

  const onSubmitWithRecaptcha = async (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e?.preventDefault();
    if (hash) {
      const token = await validateRecaptcha();
      setValue('recaptchaResponse', token as string);
    }
    handleSubmit(onSubmit)();
  };

  const validateRecaptcha = () =>
    new Promise(resolve => {
      // eslint-disable-next-line no-undef
      grecaptcha.enterprise.ready(() => {
        // eslint-disable-next-line no-undef
        grecaptcha.enterprise.execute(RECAPTCHA_SITE_KEY, { action: 'form_submit' }).then((token: string) => {
          resolve(token);
        });
      });
    });

  return (
    <div>
      <div css={styles.headerContainer}>
        <Grid md={isMobileMode ? 12 : 8} sm={isMobileMode ? 12 : 10} xs={12}>
          <div>{title}</div>
          <div>{description}</div>
        </Grid>
      </div>
      <div css={styles.container}>
        <Grid md={isMobileMode ? 12 : 8} sm={isMobileMode ? 12 : 10} xs={12}>
          <div css={styles.formContainer}>
            {formValues.questions.map((value, index) => {
              const { image, isRequired, options, questionId, questionType, questionTitle } = value;
              const idsInput = questions[index]?.answeredOptionIds;
              const firstTextInput = questions[index]?.answeredTexts[0];
              const secondTextInput = questions[index]?.answeredTexts[1];

              // errors possible content questions.answeredTexts with message or message[]
              const { isInvalidEmail, isRequiredEmail } = (
                errors?.questions as unknown as {
                  answeredTexts?: { message: string } | { message: string }[];
                }[]
              )?.reduce(
                (obj, val) => {
                  const isArray = Array.isArray(val.answeredTexts);

                  return {
                    ...obj,
                    ...(isArray &&
                    (val.answeredTexts as { message: string }[])?.find(
                      validation => validation.message === 'invalidEmailMessage'
                    )
                      ? { isInvalidEmail: true }
                      : {}),
                    ...((!isArray && (val.answeredTexts as { message: string })?.message === 'requiredEmailMessage') ||
                    (isArray &&
                      (val.answeredTexts as { message: string }[])?.find(
                        validation => validation.message === 'requiredEmailMessage'
                      ))
                      ? { isRequiredEmail: true }
                      : {}),
                  };
                },
                { isInvalidEmail: false, isRequiredEmail: false }
              ) || { isInvalidEmail: false, isRequiredEmail: false };

              return questionType === QuestionType.NAME ? (
                <NameInputContainer isMobileMode={isMobileMode || isMobileView} key={questionId}>
                  <Grid md={isMobileMode ? 12 : 6} xs={12}>
                    <StyledTextForm
                      error={!!errors.questions && !firstTextInput}
                      isRequired={isRequired}
                      placeholder={t('TextForm.First Name')}
                      title="First Name"
                      value={firstTextInput || ''}
                      onChange={e => onChangeTexts(index, e.target.value, 0)}
                    />
                    {!!errors.questions && isRequired && !firstTextInput && (
                      <ErrorMessage message={'requiredFirstNameMessage'} />
                    )}
                  </Grid>
                  <Grid md={isMobileMode ? 12 : 6} xs={12}>
                    <div>
                      <StyledTextForm
                        error={!!errors.questions && !secondTextInput}
                        isRequired={isRequired}
                        placeholder={t('TextForm.Last Name')}
                        title="Last Name"
                        value={secondTextInput || ''}
                        onChange={e => onChangeTexts(index, e.target.value, 1)}
                      />
                      {!!errors.questions && isRequired && !secondTextInput && (
                        <ErrorMessage message={'requiredLastNameMessage'} />
                      )}
                    </div>
                  </Grid>
                </NameInputContainer>
              ) : questionType === QuestionType.EMAIL ? (
                <InputContainer key={questionId}>
                  <StyledTextForm
                    error={isInvalidEmail || isRequiredEmail}
                    isRequired={isRequired}
                    placeholder="sample@example.com"
                    title="Email Address"
                    value={firstTextInput || ''}
                    onChange={e => onChangeTexts(index, e.target.value, 0)}
                  />
                  {(isInvalidEmail || isRequiredEmail) && (
                    <ErrorMessage message={isInvalidEmail ? 'invalidEmailMessage' : 'requiredEmailMessage'} />
                  )}
                </InputContainer>
              ) : questionType === QuestionType.CHECKBOX ? (
                <InputContainer key={questionId}>
                  <StyledLabel isRequired={isRequired} title={questionTitle} />
                  {image && (
                    <InputImgContainer>
                      <img alt="inputImg" src={image} />
                    </InputImgContainer>
                  )}
                  {/* TODO:??? */}
                  {options.map(({ id: optionId, optionTitle }) => (
                    <CheckBox
                      css={styles.checkbox}
                      key={optionId}
                      label={optionTitle}
                      checked={idsInput ? idsInput.includes(optionId) : false}
                      onCheckedChange={checked => onClickCheckbox(index, !checked, optionId)}
                    />
                  ))}
                  {!!errors.questions && isRequired && idsInput.length <= 0 && (
                    <ErrorMessage message={'requiredOptionMessage'} />
                  )}
                </InputContainer>
              ) : questionType === QuestionType.DROPDOWN ? (
                <InputContainer key={questionId}>
                  <DropdownContainer isMobileMode={isMobileMode || isMobileView}>
                    <StyledLabel isRequired={isRequired} title={questionTitle} />
                    {image && (
                      <InputImgContainer>
                        <img alt="inputImg" src={image} />
                      </InputImgContainer>
                    )}
                    <StyledSingleSelectField
                      error={!!errors.questions && isRequired && idsInput.length <= 0}
                      isRequired={isRequired}
                      name=""
                      options={options.map(({ id, optionTitle }) => ({ label: optionTitle, value: id }))}
                      value={idsInput ? idsInput[0] : ''}
                      setFieldValue={(_attr, val) => onClickCheckBoxDropdown(index, val)}
                    />
                  </DropdownContainer>
                  {!!errors.questions && isRequired && idsInput.length <= 0 && (
                    <ErrorMessage message={'requiredOptionMessage'} />
                  )}
                </InputContainer>
              ) : // applicable for radio group in future
              // : questionType === QuestionType.DROPDOWN ? (
              //   <InputContainer key={questionId}>
              //     <StyledLabel isRequired={isRequired} title={questionTitle} />
              //     {options.map(option => {
              //       const { id: optionId, optionTitle } = option;

              //       return (
              //         <div css={styles.radioContainer} key={optionId}>
              //           <RadioIcon
              //             isChecked={idsInput ? idsInput.includes(optionId) : false}
              //             onClick={() => onClickCheckBoxDropdown(index, optionId)}
              //           />
              //           <span>{optionTitle}</span>
              //         </div>
              //       );
              //     })}
              //     {!!questionsMeta.error && isRequired && idsInput.length <= 0 && (
              //       <ErrorMessage message={t('requiredOptionMessage')} />
              //     )}
              //   </InputContainer>
              // )
              questionType === QuestionType.SHORT_ANSWER ? (
                <InputContainer key={questionId}>
                  <ShortAnswerContainer isMobileMode={isMobileMode || isMobileView}>
                    <StyledLabel isRequired={isRequired} title={questionTitle} />
                    {image && (
                      <InputImgContainer>
                        <img alt="inputImg" src={image} />
                      </InputImgContainer>
                    )}
                    <StyledTextForm
                      error={!!errors.questions && isRequired && !firstTextInput}
                      isRequired={isRequired}
                      placeholder=""
                      value={firstTextInput || ''}
                      onChange={e => onChangeTexts(index, e.target.value, 0)}
                    />
                  </ShortAnswerContainer>
                  {!!errors.questions && isRequired && !firstTextInput && (
                    <ErrorMessage message={'requiredFieldMessage'} />
                  )}
                </InputContainer>
              ) : null;
            })}
            <div css={styles.actionContainer}>
              <ThemeButton
                theme="blue"
                disabled={!formId}
                text="Submit"
                width="max-content"
                onClick={e => onSubmitWithRecaptcha(e)}
                {...((isMobileMode || isMobileView) && {
                  width: '100%',
                  size: 'large',
                })}
              />
            </div>
          </div>
        </Grid>
      </div>
    </div>
  );
};

const DropdownContainer = styled.div<{ isMobileMode: boolean }>`
  width: ${({ isMobileMode }) => (isMobileMode ? '100%' : '40%')};
`;

const InputContainer = styled.div`
  border-bottom: 1px solid #dee5ec;
  margin-bottom: 24px;
  padding-bottom: 24px;
  width: 100%;
`;

const InputImgContainer = styled.div`
  background-color: #000;
  margin: 8px 0;
  width: 213px;

  & > img {
    height: 120px;
    object-fit: contain;
    width: 100%;
  }
`;

const NameInputContainer = styled.div<{ isMobileMode?: boolean }>`
  border-bottom: 1px solid #dee5ec;
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
  margin-bottom: 32px;
  padding-bottom: 24px;

  & > div:nth-of-type(1) {
    & > div {
      ${({ isMobileMode }) => (!isMobileMode ? 'margin-right: 8px;' : '')}
    }
  }

  /* stylelint-disable no-descending-specificity */
  & > div:nth-of-type(2) {
    & > div {
      ${({ isMobileMode }) => (!isMobileMode ? 'margin-left: 8px' : 'margin-top: 24px')};
    }
  }
`;

const ShortAnswerContainer = styled.div<{ isMobileMode: boolean }>`
  width: ${({ isMobileMode }) => (isMobileMode ? '100%' : '50%')};
`;

const StyledLabel = styled(Label)`
  color: #27313b;
  font-size: 14px;
  font-weight: 600;
`;

const StyledSingleSelectField = styled(SingleSelectField)`
  & > label {
    font-size: 14px;
  }

  & > div > div {
    border-radius: 2px;
    min-height: 32px;

    & > div > div {
      align-items: center;
      display: flex;
    }
  }

  & input {
    border-radius: 2px;
    min-height: 32px;
  }
`;

const StyledTextForm = styled(TextForm)`
  & > label {
    color: #27313b;
    font-size: 14px;
    font-weight: 600;
  }

  & input {
    border-radius: 3px;
    height: 32px;
  }
`;

const styles = {
  actionContainer: css`
    display: flex;
    flex: 1;
    justify-content: flex-end;
  `,
  checkbox: css`
    padding: 10px 10px 10px 0;

    & > label {
      color: #27313b;
      font-size: 14px;
    }
  `,
  container: css`
    background-color: #fff;
    display: flex;
    justify-content: center;
    padding: 24px 32px;
  `,
  formContainer: css`
    display: flex;
    flex-wrap: wrap;
  `,
  headerContainer: css`
    display: flex;
    justify-content: center;
    padding: 0 32px;

    & > div {
      & > div:nth-of-type(1) {
        color: #27313b;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 16px;
      }

      & > div:nth-of-type(2) {
        color: #6e7c89;
        font-size: 14px;
        margin-bottom: 16px;
      }
    }
  `,
  radioContainer: css`
    display: flex;
    flex-wrap: wrap;
    padding: 10px 10px 10px 0;

    & > span {
      color: #27313b;
      font-size: 14px;
      margin-left: 8px;
    }
  `,
};

export default SubmitForm;
