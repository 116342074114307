import React from 'react';
import { css } from '@emotion/react';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import { ConnectType } from '@src/libs/auth';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { useQueryHelper, useRedirectWithinIframe, usePageLayout } from '@src/libs/hooks';
import { useRedirectUrl } from '@src/libs/hooks/useRedirectUrl';
import { ViewportType } from '@src/libs/theme';
import { ROUTES } from '@src/shared/routes';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import Instruction from './Instruction';

const TiktokGrantPermission = () => {
  const { enqueueSnackbar, search, t } = useQueryHelper();
  const query = new URLSearchParams(search);
  const socialAccountId = query.get('id');
  const { isMobileView } = usePageLayout();
  const { getRedirectUrl } = useRedirectUrl(FE_REDIRECT_MAPPING.CONNECT);
  const { getRedirectUrl: getRedirectReconnectUrl } = useRedirectUrl(FE_REDIRECT_MAPPING.TIKTOK_RECONNECT);
  const { startRedirectProcess } = useRedirectWithinIframe();

  const onClickConnect = () => {
    redirectProcess(FE_REDIRECT_MAPPING.CONNECT);
  };

  const onClickReconnect = () => {
    if (socialAccountId) {
      localStorage.setItem('connectType', ConnectType.RECONNECT_TIKTOK);
      localStorage.setItem('reconnectTiktokAccountId', socialAccountId);
      redirectProcess(FE_REDIRECT_MAPPING.TIKTOK_RECONNECT);
    }
  };

  const redirectProcess = async (redirectType: FE_REDIRECT_MAPPING) => {
    try {
      const redirectUrl =
        redirectType === FE_REDIRECT_MAPPING.CONNECT
          ? await getRedirectUrl(SocialAccountType.TIKTOK)
          : await getRedirectReconnectUrl(SocialAccountType.TIKTOK);

      if (redirectUrl) {
        startRedirectProcess({ redirectUrl, redirectType });
      }
    } catch (error) {
      enqueueSnackbar(t('UnexpectedError'), { variant: 'error' });
    }
  };

  return (
    <div css={styles.container}>
      {!isMobileView && (
        <BackNavigator css={{ marginBottom: 24 }} title="Grant permission" to={ROUTES.SETTINGS_SOCIAL_CONNECT} />
      )}

      <Instruction isConnect={!socialAccountId} onClickConnect={onClickConnect} onClickReconnect={onClickReconnect} />
    </div>
  );
};

const styles = {
  container: css`
    margin: 16px 24px;

    @media (max-width: ${ViewportType.TABLET}px) {
      margin: 16px;
    }
  `,
};

export default TiktokGrantPermission;
