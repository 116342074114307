import { useMutation, useQuery } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import MARK_NOTIFICATIONS_READ from './mutations/MarkAllNotificationsRead.graphql';
import CLICK_NOTIFICATION from './mutations/ViewNotification.graphql';
import { MarkAllNotificationsRead } from './mutations/__generated__/MarkAllNotificationsRead';
import { ViewNotification, ViewNotificationVariables } from './mutations/__generated__/ViewNotification';
import GET_NOTIFICATIONS from './queries/Notifications.graphql';
import { Notifications, NotificationsVariables } from './queries/__generated__/Notifications';

const useNotification = () => {
  const { enqueueSnackbar, t } = useQueryHelper();

  const { data, loading, fetchMore } = useQuery<Notifications, NotificationsVariables>(GET_NOTIFICATIONS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {},
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const [markNotificationsRead] = useMutation<MarkAllNotificationsRead>(MARK_NOTIFICATIONS_READ, {
    refetchQueries: ['GetUnreadNotificationCount'],
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const [clickNotification] = useMutation<ViewNotification, ViewNotificationVariables>(CLICK_NOTIFICATION, {
    refetchQueries: ['Notifications'],
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  return {
    data,
    loading,
    clickNotification,
    fetchMore,
    markNotificationsRead,
  };
};

export default useNotification;
