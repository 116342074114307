import React, { CSSProperties, PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { ThemeButton, ButtonProps } from '@src/components/atoms/Button';
import Portal from '@src/components/atoms/Portal';
import { ViewportType } from '@src/libs/theme';
import { useDirLayout } from '@src/libs/hooks';
import { css } from '@emotion/react';

interface DialogProps {
  visible: boolean;
  onClose?: () => void;
  execColor?: ButtonProps['theme'];
  execText: string;
  execDisabled?: boolean;
  onExec: () => void;
  contentStyle?: CSSProperties;
  headerNode?: React.ReactNode;
  hideCancel?: boolean;
  hideClose?: boolean;
  closeOnDocumentClick?: boolean;
  cancelText?: string;
  onCancel?: () => void;
}

const popupStyles: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  flexShrink: 0,
  width: '90%',
  maxWidth: '648px',
  height: 'max-content',
  padding: '0',
  border: 'none',
  borderRadius: '3px',
};

const Dialog = (props: PropsWithChildren<DialogProps>) => {
  const {
    visible,
    execText,
    children,
    contentStyle,
    execColor,
    headerNode: HeaderNode,
    execDisabled,
    hideCancel,
    hideClose,
    closeOnDocumentClick = true,
    cancelText,
    onCancel,
    onExec,
    onClose,
  } = props;
  const { isRtl, dir } = useDirLayout();

  return (
    <Portal portalId="portal-root">
      <Popup
        modal
        lockScroll
        closeOnDocumentClick={closeOnDocumentClick}
        open={visible}
        onClose={onClose}
        contentStyle={{ ...popupStyles, ...contentStyle }}
      >
        {(close: () => void) => (
          <>
            {!hideClose && <Close onClick={close} css={isRtl ? { left: '-15px' } : { right: '-15px' }} />}
            {HeaderNode}
            <MainWrapper dir={dir}>{children}</MainWrapper>
            <FooterWrapper dir={dir}>
              {!hideCancel && (
                <ThemeButton
                  text={cancelText || 'Cancel'}
                  height="32px"
                  borderRadius="5px"
                  onClick={onCancel || onClose}
                  css={styles.button}
                />
              )}
              <ThemeButton
                text={execText}
                theme={execColor || 'blue'}
                borderRadius="5px"
                onClick={onExec}
                disabled={execDisabled}
                css={styles.button}
              />
            </FooterWrapper>
          </>
        )}
      </Popup>
    </Portal>
  );
};

const styles = {
  button: css`
    width: fit-content;
    padding: 0;

    .btn-text {
      font-size: 12px;
    }

    @media (max-width: ${ViewportType.TABLET}px) {
      height: 40px;
      width: fill-available;
    }
  `,
};

const MainWrapper = styled.div`
  padding: 24px;
  overflow: auto;
`;
const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px 24px;
  border-top: 1px solid #dee5ec;
  gap: 16px;
`;
const Close = styled.a`
  position: absolute;
  top: -15px;
  display: flex;
  justify-content: center;
  width: 36px;
  height: 36px;
  font-size: 25px;
  line-height: 34px;
  font-weight: 100;
  background: #fff;
  border-radius: 50%;
  background-color: #000;
  cursor: pointer;
  z-index: 100;

  &:hover {
    background-color: #000;
  }

  &::before {
    content: '\\00d7';
    color: #fff;
    font-weight: bold;
  }
`;

export default Dialog;
