import { useMutation } from '@apollo/client';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { useQueryHelper } from '@src/libs/hooks';
import { ROUTES } from '@src/shared/routes';
import { SendContact, SendContactVariables } from './__generated__/SendContact';
import ContactForm from './ContactForm';
import * as SEND_CONTACT from './SendContact.graphql';
import { FormValues } from './types';

const ContactFormComponent = () => {
  const { t, enqueueSnackbar, history } = useQueryHelper();
  const methods = useForm<FormValues>({
    defaultValues: {
      enquiry: '',
    },
  });

  // Mutation
  const [sendContact] = useMutation<SendContact, SendContactVariables>(SEND_CONTACT);

  const onSubmit = async (payload: FormValues) => {
    // Update Account
    const { enquiry } = payload;
    const variables = { input: { enquiry } };
    try {
      const result = await sendContact({ variables });
      if (result.data?.sendContact?.ok) {
        enqueueSnackbar(t('succeededInSending'), { variant: 'success' });
        sendAmplitudeEvent(eventTypes.sendContact);
        history.push(ROUTES.CONTACT_THANKS);
      } else {
        const failureMessage = 'failedToSend';
        enqueueSnackbar(t(failureMessage), { variant: 'error' });
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t('failedToSend'), { variant: 'error' });
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ContactForm />
      </form>
    </FormProvider>
  );
};

export default ContactFormComponent;
