import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import Grid from '@src/components/atoms/Grid';
import Label from '@src/components/atoms/Label';
import { ListIndicator } from '@src/components/molecules/Indicator';
import SingleSelectField from '@src/components/molecules/SingleSelectField';
import TextForm from '@src/components/molecules/TextForm';
import { LANGUAGE_OPTIONS } from '@src/libs/i18n';
import { useAuthData, useUuumInfluencerView } from '@src/libs/hooks';
import { DisplayLanguage, UserRoles } from '@src/__generated__/globalTypes';
import { CheckBox } from '@src/components/atoms/CheckBox';
import { FormSubmitSection } from '@src/components/shared/FormSubmitSection';

export const LanguageMapping = {
  ch: DisplayLanguage.SIMPLIFIED_CHINESE,
  id: DisplayLanguage.BAHASA,
  en: DisplayLanguage.ENGLISH,
  ja: DisplayLanguage.JAPANESE,
  km: DisplayLanguage.CAMBODIA,
  my: DisplayLanguage.MYANMAR,
  th: DisplayLanguage.THAI,
  tw: DisplayLanguage.TRADITIONAL_CHINESE,
  vi: DisplayLanguage.VIETNAMESE,
  ar: DisplayLanguage.ARABIC,
} as Record<string, DisplayLanguage>;

export interface PersonalInformation {
  displayLanguage: DisplayLanguage;
  notificationSetting: boolean | null;
  email: string;
  name: string;
  password: string;
  passwordConfirm: string;
}

interface YourAccountProps {
  isLoading: boolean;
}

const YourAccount = ({ isLoading }: YourAccountProps) => {
  const { t } = useTranslation();
  const { role } = useAuthData();
  const { isUuumView } = useUuumInfluencerView();
  const {
    formState: { errors, isSubmitting },
    register,
    setValue,
    watch,
  } = useFormContext<PersonalInformation>();
  const [displayLanguageValue, notificationSettingValue] = watch(['displayLanguage', 'notificationSetting']);

  const languageOptions = LANGUAGE_OPTIONS.map(language => ({
    value: LanguageMapping[language.value],
    label: language.label,
  }));

  return (
    <div css={styles.container}>
      <Grid md={8} sm={10} xs={12}>
        {isLoading ? (
          <ListIndicator />
        ) : (
          <>
            <div css={styles.formInputSection}>
              <div css={styles.formTitle}>{t('Title.Your Account')}</div>
              <div css={styles.formDescription}>
                {t('Annotation.Edit your information and set notifications to receive')}
              </div>

              <InputContainer>
                <StyledTextForm
                  error={!!errors.name}
                  isRequired
                  title="Name"
                  placeholder="Naoya Kurita"
                  {...register('name')}
                />
                <ErrorMessage message={errors.name?.message} />
              </InputContainer>

              <InputContainer>
                <StyledTextForm
                  error={!!errors.email}
                  isRequired
                  title="Email Address"
                  placeholder="take@anymindgroup.com"
                  {...register('email')}
                />
                <ErrorMessage message={errors.email?.message} />
              </InputContainer>

              <InputContainer>
                <StyledTextForm
                  error={!!errors.password}
                  title="Password"
                  placeholder=""
                  type="password"
                  {...register('password')}
                />
                <ErrorMessage message={errors.password?.message} />
              </InputContainer>

              <InputContainer>
                <StyledTextForm
                  error={!!errors.passwordConfirm}
                  title="Confirm Password"
                  placeholder=""
                  type="password"
                  {...register('passwordConfirm')}
                />
                <ErrorMessage message={errors.passwordConfirm?.message} />
              </InputContainer>

              <InputContainer>
                <StyledSingleSelectField
                  name="displayLanguage"
                  options={languageOptions}
                  title="Display Language"
                  value={displayLanguageValue || ''}
                  setFieldValue={setValue}
                />
                <ErrorMessage message={errors.displayLanguage?.message} />
              </InputContainer>

              {!isUuumView && role === UserRoles.INFLUENCER && (
                <InputContainer>
                  <Label css={styles.inputLabel} title={t('Notification')} />
                  <CheckboxContainer>
                    <StyledCheckbox
                      checked={!!notificationSettingValue}
                      id="notificationSetting"
                      onCheckedChange={checked => setValue('notificationSetting', checked)}
                      size={'20px'}
                      label="Notify me of campaign information and payment"
                    />
                  </CheckboxContainer>
                </InputContainer>
              )}
            </div>

            <FormSubmitSection isSubmitting={isSubmitting} />
          </>
        )}
      </Grid>
    </div>
  );
};

const InputContainer = styled.div`
  margin-bottom: 24px;
`;

const StyledSingleSelectField = styled(SingleSelectField)`
  & > label {
    font-size: 14px;
  }

  & > div > div {
    border-radius: 2px;
    min-height: 32px;

    & > div > div {
      height: auto;
    }
  }

  & input {
    border-radius: 2px;
    min-height: 32px;
  }
`;

const StyledTextForm = styled(TextForm)`
  & > label {
    font-size: 14px;
  }

  & input {
    border-radius: 2px;
    height: 32px;
  }
`;

const CheckboxContainer = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 16px;
  gap: 8px;
`;

const StyledCheckbox = styled(CheckBox)`
  .checkbox-item-label {
    color: #27313b;
    font-size: 14px;
  }
`;

const styles = {
  container: css`
    display: flex;
    justify-content: center;
  `,
  formDescription: css`
    color: #27313b;
    font-size: 14px;
    margin-bottom: 24px;
  `,
  formInputSection: css`
    background-color: #fff;
    padding: 24px;
  `,
  formTitle: css`
    color: #27313b;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px;
  `,
  inputLabel: css`
    color: #27313b;
    font-size: 14px;
    margin-bottom: 16px;
  `,
};

export default YourAccount;
