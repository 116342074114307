import styled from '@emotion/styled';
import * as React from 'react';

// Types
export interface LanguageButtonProps {
  title: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const LanguageButton = (props: LanguageButtonProps) => {
  const { title, onClick } = props;

  return <Button onClick={onClick}>{title}</Button>;
};

const Button = styled.button`
  box-sizing: border-box;
  width: 100%;
  padding: 0 16px;
  text-align: left;
  line-height: 40px;
  cursor: pointer;
  transition: background-color 0.3s ease-out;

  &:hover {
    background-color: #efefef;

    @media (max-width: 450px) {
      background-color: #fff;
    }
  }
`;

export default LanguageButton;
