import { SerializedStyles } from '@emotion/core';
import styled from '@emotion/styled';
import React from 'react';
import DayPickerHandInput from './DayPickerHandInput';

interface RangePickerHandInputProps {
  css?: SerializedStyles;
  startDate: string;
  startDisable?: boolean;
  endDate: string;
  endDisable?: boolean;
  onRangeChange: (start: string, end: string) => void;
}
const RangePickerHandInput = (props: RangePickerHandInputProps) => {
  const { startDate, startDisable, endDate, endDisable, onRangeChange, css } = props;

  return (
    <TwoPickersInARow css={css}>
      <DayPickerHandInput
        value={startDate}
        handleChangeDay={(day: string) => onRangeChange(day, endDate)}
        disabled={startDisable}
      />
      <Separator>{'\u2013'}</Separator>
      <DayPickerHandInput
        value={endDate}
        handleChangeDay={(day: string) => onRangeChange(startDate, day)}
        disabled={endDisable}
      />
    </TwoPickersInARow>
  );
};

const TwoPickersInARow = styled.div`
  display: flex;
  align-items: center;
`;

const Separator = styled.span`
  padding: 0 6px;
`;

export default RangePickerHandInput;
