import React from 'react';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ThemeButton } from '@src/components/atoms/Button';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import SentenceForm from '@src/components/molecules/SentenceForm';
import SingleSelectField from '@src/components/molecules/SingleSelectField';
import TextForm from '@src/components/molecules/TextForm';
import BackNavigator from '@src/components/organisms/Layout/BackNavigator';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { ViewportType } from '@src/libs/theme';
import { ROUTES } from '@src/shared/routes';
import { CampaignPromotionMethod, PromotionMobileAppType } from '@src/__generated__/globalTypes';
import CONNECT_PROMOTION_METHODS from './mutations/ConnectPromotionMethods.graphql';
import {
  ConnectPromotionMethods,
  ConnectPromotionMethodsVariables,
} from './mutations/__generated__/ConnectPromotionMethods';

interface PromotionMethod {
  description?: string;
  email?: string;
  method: CampaignPromotionMethod;
  mobileAppType?: PromotionMobileAppType;
  url?: string;
}

const AddMethods = () => {
  const { enqueueSnackbar, history, search, t } = useQueryHelper();
  const { isMobileView } = usePageLayout();
  const searchParams = new URLSearchParams(search);
  const method = (searchParams.get('method') as CampaignPromotionMethod) || CampaignPromotionMethod.OFFLINE;
  const item = {
    method,
    ...([
      CampaignPromotionMethod.EMAIL_NEWSLETTER,
      CampaignPromotionMethod.OFFLINE,
      CampaignPromotionMethod.PODCAST,
      CampaignPromotionMethod.WEBSITE,
    ].includes(method)
      ? { description: '' }
      : {}),
    ...(method === CampaignPromotionMethod.EMAIL_NEWSLETTER ? { email: '' } : {}),
    ...(method === CampaignPromotionMethod.MOBILE_APP ? { mobileAppType: '' as PromotionMobileAppType } : {}),
    ...([CampaignPromotionMethod.MOBILE_APP, CampaignPromotionMethod.PODCAST, CampaignPromotionMethod.WEBSITE].includes(
      method
    )
      ? { url: '' }
      : {}),
  };
  const mobileType = [
    { label: 'Android', value: PromotionMobileAppType.ANDROID },
    { label: 'Apple', value: PromotionMobileAppType.APPLE },
  ];

  const defaultValues = {
    methods: [item],
  };
  const validationSchema = yup.object().shape({
    methods: yup.array().of(
      yup.object().shape({
        description: yup.string().when('method', {
          is: type =>
            [
              CampaignPromotionMethod.EMAIL_NEWSLETTER,
              CampaignPromotionMethod.OFFLINE,
              CampaignPromotionMethod.PODCAST,
              CampaignPromotionMethod.WEBSITE,
            ].includes(type),
          then: yup.string().required(),
        }),
        email: yup.string().when('method', {
          is: type => type === CampaignPromotionMethod.EMAIL_NEWSLETTER,
          then: yup.string().required(),
        }),
        mobileAppType: yup.string().when('method', {
          is: type => type === CampaignPromotionMethod.MOBILE_APP,
          then: yup.string().required(),
        }),
        url: yup.string().when('method', {
          is: type =>
            [
              CampaignPromotionMethod.MOBILE_APP,
              CampaignPromotionMethod.PODCAST,
              CampaignPromotionMethod.WEBSITE,
            ].includes(type),
          then: yup.string().required(),
        }),
      })
    ),
  });

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const isError = !!errors.methods;
  const promotionMethods = watch('methods');

  const { hint, removeButtonTitle, title } = (() => {
    switch (method) {
      case CampaignPromotionMethod.EMAIL_NEWSLETTER:
        return {
          hint: 'Please input your Email/Newsletter information and tell us what kind of Email/Newsletter you send',
          removeButtonTitle: 'Remove this Email/Newsletter',
          title: 'Email / Newsletter',
        };
      case CampaignPromotionMethod.MOBILE_APP:
        return {
          hint: `Please input your Mobile App's URL below`,
          removeButtonTitle: 'Remove this app',
          title: 'Mobile App',
        };
      case CampaignPromotionMethod.PODCAST:
        return {
          hint: `Please input your Podcast's URL and tell us what kind of Podcast you have`,
          removeButtonTitle: 'Remove this podcast',
          title: 'Podcast',
        };
      case CampaignPromotionMethod.WEBSITE:
        return {
          hint: 'Please input your Website URL and tell us what kind of Website you have',
          removeButtonTitle: 'Remove this website',
          title: 'Website',
        };
      default:
        return {
          hint: 'Please tell us what kind of offline method you will use',
          removeButtonTitle: 'Remove this Offline / Other',
          title: 'Offline / Other',
        };
    }
  })();

  const [connectPromotionMethods] = useMutation<ConnectPromotionMethods, ConnectPromotionMethodsVariables>(
    CONNECT_PROMOTION_METHODS
  );

  const onClickAddMethod = () => {
    const items = [...promotionMethods];
    items.push(item);
    setValue('methods', items);
  };

  const onChangeSetValue = (index: number, attr: keyof PromotionMethod, value: never) => {
    const items = [...promotionMethods];
    items[index][attr] = value;
    setValue('methods', items);
  };

  const onSubmit = async (values: { methods: PromotionMethod[] }) => {
    try {
      await connectPromotionMethods({
        variables: {
          input: {
            methods: values.methods,
          },
        },
      });
      enqueueSnackbar(t('succeededInCreating'), { variant: 'success' });
      history.push(ROUTES.SETTINGS_SOCIAL_CONNECT);
    } catch (err) {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    }
  };

  return (
    <div css={styles.container}>
      {!isMobileView && (
        <BackNavigator
          skipTranslation
          title={t('Add Method', { method: t(title) })}
          to={ROUTES.SETTINGS_SOCIAL_CONNECT}
        />
      )}
      <div css={styles.description}>{t(`Annotation.${hint}`)}</div>

      <form css={{ display: 'flex', justifyContent: 'center', width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
        <div css={styles.contentContainer}>
          {promotionMethods.map((promotionMethod, index) => {
            const { description, email, mobileAppType, url } = promotionMethod;

            return (
              <div css={styles.methodInputContainer} key={index}>
                <div css={{ display: 'grid', gap: 24 }}>
                  {method === CampaignPromotionMethod.EMAIL_NEWSLETTER ? (
                    <>
                      <div>
                        <TextForm
                          error={isError && !email}
                          isRequired
                          placeholder="https://www.myprofile.com"
                          title="Email Address / Register page’s URL"
                          value={email}
                          onChange={e => onChangeSetValue(index, 'email', e.target.value as never)}
                        />
                        {isError && !email && <ErrorMessage message="requiredFieldMessage" />}
                      </div>
                      <div>
                        <StyledSentenceForm
                          error={isError && !description}
                          isRequired
                          placeholder={t('Email / Newsletter Description')}
                          title="Description"
                          value={description}
                          onChange={e => onChangeSetValue(index, 'description', e.target.value as never)}
                        />
                        {isError && !description && <ErrorMessage message="requiredFieldMessage" />}
                      </div>
                    </>
                  ) : method === CampaignPromotionMethod.MOBILE_APP ? (
                    <>
                      <div>
                        <SingleSelectField
                          css={{ width: 240 }}
                          error={isError && !mobileAppType}
                          isRequired
                          name=""
                          options={mobileType}
                          title="Type"
                          value={mobileAppType}
                          setFieldValue={(_name, value) => onChangeSetValue(index, 'mobileAppType', value as never)}
                        />
                        {isError && !mobileAppType && <ErrorMessage message="requiredFieldMessage" />}
                      </div>
                      <div>
                        <TextForm
                          error={isError && !url}
                          isRequired
                          placeholder="https://www.myprofile.com"
                          title="App URL"
                          value={url}
                          onChange={e => onChangeSetValue(index, 'url', e.target.value as never)}
                        />
                        {isError && !url && <ErrorMessage message="requiredFieldMessage" />}
                      </div>
                    </>
                  ) : method === CampaignPromotionMethod.PODCAST ? (
                    <>
                      <div>
                        <TextForm
                          error={isError && !url}
                          isRequired
                          placeholder="https://www.myprofile.com"
                          title="Podcast URL"
                          value={url}
                          onChange={e => onChangeSetValue(index, 'url', e.target.value as never)}
                        />
                        {isError && !url && <ErrorMessage message="requiredFieldMessage" />}
                      </div>
                      <div>
                        <StyledSentenceForm
                          error={isError && !description}
                          isRequired
                          placeholder={t('Podcast Description')}
                          title="Podcast Description"
                          value={description}
                          onChange={e => onChangeSetValue(index, 'description', e.target.value as never)}
                        />
                        {isError && !description && <ErrorMessage message="requiredFieldMessage" />}
                      </div>
                    </>
                  ) : method === CampaignPromotionMethod.WEBSITE ? (
                    <>
                      <div>
                        <TextForm
                          error={isError && !url}
                          isRequired
                          placeholder="https://www.myprofile.com"
                          title="Website URL"
                          value={url}
                          onChange={e => onChangeSetValue(index, 'url', e.target.value as never)}
                        />
                        {isError && !url && <ErrorMessage message="requiredFieldMessage" />}
                      </div>
                      <div>
                        <StyledSentenceForm
                          error={isError && !description}
                          isRequired
                          placeholder={t('Website Description')}
                          title="Description"
                          value={description}
                          onChange={e => onChangeSetValue(index, 'description', e.target.value as never)}
                        />
                        {isError && !description && <ErrorMessage message="requiredFieldMessage" />}
                      </div>
                    </>
                  ) : (
                    <div>
                      <StyledSentenceForm
                        error={isError && !description}
                        isRequired
                        placeholder={t('Offline / Others Description')}
                        title="Description"
                        value={description}
                        onChange={e => onChangeSetValue(index, 'description', e.target.value as never)}
                      />
                      {isError && !description && <ErrorMessage message="requiredFieldMessage" />}
                    </div>
                  )}

                  {promotionMethods.length > 1 && (
                    <div css={styles.removeButtonContainer}>
                      <div
                        onClick={() =>
                          setValue('methods', [
                            ...promotionMethods.slice(0, index),
                            ...promotionMethods.slice(index + 1),
                          ])
                        }
                      >
                        {t(`Button.${removeButtonTitle}`)}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}

          <div css={styles.addBtnContainer}>
            <div onClick={onClickAddMethod}>{`+ ${t('Button.Add Another Method', {
              method: t(title),
            })}`}</div>
          </div>

          {isMobileView ? (
            <div css={styles.actionContainer}>
              <ThemeButton css={{ height: 40 }} disabled={isSubmitting} text="Save" theme="blue" type="submit" />
            </div>
          ) : (
            <ThemeButton css={{ height: 40 }} disabled={isSubmitting} text="Save" theme="blue" type="submit" />
          )}
        </div>
      </form>
    </div>
  );
};

const StyledSentenceForm = styled(SentenceForm)`
  & textarea {
    height: 72px;
  }
`;

const styles = {
  actionContainer: css`
    background-color: #fff;
    bottom: 0;
    box-shadow: 0 -5px 10px rgba(39, 49, 59, 0.1);
    left: 0;
    padding: 12px 24px;
    position: fixed;
    width: fill-available;
  `,
  addBtnContainer: css`
    display: flex;
    margin-bottom: 24px;

    & > div {
      color: #6e7c89;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
    }

    @media (max-width: ${ViewportType.TABLET}px) {
      justify-content: flex-end;
    }
  `,
  container: css`
    padding: 16px 24px;

    @media (max-width: ${ViewportType.TABLET}px) {
      padding: 16px;
    }
  `,
  contentContainer: css`
    max-width: 648px;
    width: 100%;
  `,
  description: css`
    color: #27313b;
    font-size: 14px;
    margin-bottom: 24px;

    @media (min-width: ${ViewportType.TABLET}px) {
      padding: 0 48px;
    }
  `,
  methodInputContainer: css`
    background-color: #fff;
    border: 1px solid #dee5ec;
    border-radius: 5px;
    margin-bottom: 24px;
    padding: 24px;
  `,
  removeButtonContainer: css`
    display: flex;
    justify-content: flex-end;

    & > div {
      color: #3892e5;
      cursor: pointer;
      font-size: 14px;
    }
  `,
};

export default AddMethods;
