import React, { useEffect, useState } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { sendAmplitudeEvent } from '@src/amplitude';
import Layout, { LayoutProps } from '@src/components/organisms/Layout';
import { CampaignPageType } from '@src/libs/campaign';
import { checkSignedIn } from '@src/libs/auth';
import { useGaTracker } from '@src/libs/withTracker';
import { useQueryHelper, useGlobalLayoutProps, useUpdateDocumentTitle } from '@src/libs/hooks';
import { useQuery } from '@apollo/client';
import InitialLoading from '@src/components/molecules/InitialLoading';
import { useUuumInfluencerView } from '@src/libs/hooks';
import { ROUTES } from '@src/shared/routes';
import * as IS_INFLUENCER_UUUM from './IsInfluencerUuum.graphql';
import { IsInfluencerUuum } from './__generated__/IsInfluencerUuum';

export interface AppRouteProps extends LayoutProps, RouteProps {
  amplitudeEvent?: string;
  component: React.FC<any>;
  documentTitle?: string;
  page?: CampaignPageType;
}

const AppRoute = ({
  amplitudeEvent,
  backPath,
  children,
  component: Component,
  documentTitle,
  hideFooter,
  page,
  toolbarTitle,
  skipBottomPadding,
  ...rest
}: AppRouteProps) => {
  const { history, i18n } = useQueryHelper();
  const isSignedIn = checkSignedIn();
  const { isUuumView } = useUuumInfluencerView();
  // we will show initial loading screen if we need to call IS_INFLUENCER_UUUM, but for iframe (where isUuumView == true) we don't need to do it
  const [loading, setLoading] = useState(!isUuumView);
  const { updateGlobalLayout } = useGlobalLayoutProps();
  useUpdateDocumentTitle({ title: (toolbarTitle !== '_dynamic' && (documentTitle || toolbarTitle)) || '' });

  useGaTracker(isSignedIn);

  useQuery<IsInfluencerUuum>(IS_INFLUENCER_UUUM, {
    // for Iframe users isUuumView always true which means we don't need to check it is UUUM influencer
    skip: !isSignedIn || isUuumView,
    onCompleted: ({ isInfluencerUuum }) => {
      updateGlobalLayout({ isUuum: !!isInfluencerUuum?.isUuum });
      setLoading(false);
      if (!!isInfluencerUuum?.isUuum) {
        i18n.changeLanguage('ja');
      }
    },
    onError: () => {
      setLoading(false);
    },
  });

  useEffect(() => {
    if (amplitudeEvent) {
      sendAmplitudeEvent(amplitudeEvent);
    }
  }, [amplitudeEvent]);

  if (!isSignedIn) {
    localStorage.setItem('redirectUrlBeforeSignIn', history.location.pathname);
    history.push({ pathname: ROUTES.ROOT, state: { from: history.location } });
  }
  if (loading) {
    return <InitialLoading />;
  }

  return (
    <Route
      {...rest}
      render={props => (
        <>
          <Layout
            backPath={backPath}
            hideFooter={hideFooter}
            toolbarTitle={toolbarTitle}
            skipBottomPadding={skipBottomPadding}
          >
            <Component {...props} page={page} />
          </Layout>
          {children}
        </>
      )}
    />
  );
};

export default AppRoute;
