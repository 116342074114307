import { History } from 'history';
import { getLocationFromSearch } from './paging';

// Set URL parameters
export const setUrlParams = (history: History<any>, pathname: string, search: string, params: object) => {
  history.push(getLocationFromSearch(pathname, search, params));
};

export const validateUrl = (url?: string | null) => {
  if (!url) {
    return false;
  }

  try {
    new URL(url);

    return encodeURI(url);
  } catch {
    return false;
  }
};

export const getUrlSearchParams = (url: string, param: string) => {
  if (!url && !param) {
    return null;
  }

  const urlParam = new URLSearchParams(url);

  return urlParam.get(param);
};
