import { useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { UNEXPECTED_ERROR } from '@src/libs/error';
import { useQueryHelper } from '@src/libs/hooks';
import SHOPIFY_AUTH_REDIRECT_URL from './queries/ShopifyAuthRedirectUrl.graphql';
import {
  ShopifyAuthRedirectUrl,
  ShopifyAuthRedirectUrlVariables,
} from './queries/__generated__/ShopifyAuthRedirectUrl';

export const useRedirectUrl = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const client = useApolloClient();
  const { enqueueSnackbar, t } = useQueryHelper();

  const getShopifyRedirectUrl = async (shopName: string) => {
    const variables = {
      input: {
        shopName,
        callbackUrl: `${origin}/redirect/shopify_connect`,
      },
    };

    let redirectUri = null;
    setIsLoading(true);
    try {
      const { data } = await client.query<ShopifyAuthRedirectUrl, ShopifyAuthRedirectUrlVariables>({
        errorPolicy: 'none',
        query: SHOPIFY_AUTH_REDIRECT_URL,
        variables,
      });

      if (!data.shopifyAuthRedirectUrl) {
        enqueueSnackbar(t(UNEXPECTED_ERROR), { variant: 'error' });

        return null;
      }
      redirectUri = data.shopifyAuthRedirectUrl.redirectUri;
    } catch (error) {
      enqueueSnackbar(t((error as any).message), { variant: 'error' });
    } finally {
      setIsLoading(false);
    }

    return redirectUri;
  };

  return {
    isLoading,
    getShopifyRedirectUrl,
  };
};
