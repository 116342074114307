import styled from '@emotion/styled';
import React from 'react';
import { ThemeButton } from '@src/components/atoms/Button';

interface ApplyProps {
  className?: string;
  handleClick: (e?: React.SyntheticEvent<HTMLButtonElement>) => void;
}

const Apply = (props: ApplyProps) => {
  const { className, handleClick } = props;

  return (
    <ApplyWrapper className={className}>
      <ThemeButton width="auto" theme="blue" onClick={handleClick} text="Apply" />
    </ApplyWrapper>
  );
};

const ApplyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #e4e4e4;
  padding: 16px;
  border-radius: 0 0 20px 20px;
`;

export default Apply;
