import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import validator from 'validator';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import ErrorMessage from '@src/components/atoms/ErrorMessage';
import Icomoon from '@src/components/atoms/Icomoon';
import { LineMessageType } from '@src/__generated__/globalTypes';
import { LineContent, fontSizeMapping } from '../FormInput/Inputs/helpers';
import Slider from './Slider';

interface PreviewItemProps {
  error: boolean;
  lineContent: LineContent;
}

const PreviewItem = ({ error, lineContent }: PreviewItemProps) => {
  const { buttonMessage, imageMessage, plainTextMessage, richMessage, type } = lineContent;
  const { t } = useTranslation();

  switch (type) {
    case LineMessageType.BUTTON:
      return (
        <ContentContainer css={{ marginBottom: 40 }}>
          {error && <ErrorMessage message={'broadcastErrorMessage'} />}
          <Slider>
            {buttonMessage?.cards.map((item, index) => {
              const { actionLabels, description, imageUrl, title } = item;
              const isAction =
                !!actionLabels?.find(actionLabel => actionLabel.label || actionLabel.url) ||
                !!description.text ||
                !!title.text;

              return (
                <ButtonTypeContainer background={imageUrl} isAction={isAction} key={index}>
                  <div>{!imageUrl && <Icomoon icon="image-grey-light" size={48} />}</div>
                  <div>
                    <ButtonTypeFont
                      fontSize={fontSizeMapping[title.fontSize]}
                      isBold={title.weighted}
                      isEmpty={!!title.text}
                    >
                      {title.text || t('TextForm.Title Text')}
                    </ButtonTypeFont>
                    <ButtonTypeFont
                      fontSize={fontSizeMapping[description.fontSize]}
                      isBold={description.weighted}
                      isEmpty={!!description.text}
                    >
                      {description.text || t('Annotation.Button Type Description')}
                    </ButtonTypeFont>
                    {actionLabels.map((actionLabel, idx) => {
                      const { label, url } = actionLabel;

                      return validator.isURL(url) ? (
                        <Link
                          css={styles.actionLabel(label)}
                          key={idx}
                          rel="noopener noreferrer"
                          target="_blank"
                          to={{ pathname: url }}
                        >
                          {label || t('TextForm.Action Label')}
                        </Link>
                      ) : (
                        <div css={styles.actionLabel(label)} key={idx}>
                          {label || t('TextForm.Action Label')}
                        </div>
                      );
                    })}
                  </div>
                </ButtonTypeContainer>
              );
            })}
          </Slider>
        </ContentContainer>
      );
    case LineMessageType.IMAGES:
      return (
        <ContentContainer css={{ marginBottom: 40 }}>
          {error && <ErrorMessage message={'broadcastErrorMessage'} />}
          <Slider>
            {imageMessage?.images.map((item, index) => {
              const { imageUrl, label, url } = item;

              return validator.isURL(url || '') ? (
                <Link
                  css={styles.imageTypeContainer(imageUrl)}
                  key={index}
                  rel="noopener noreferrer"
                  target="_blank"
                  to={{ pathname: url }}
                >
                  {!imageUrl && <Icomoon icon="image-grey-light" size={72} />}
                  <div>{label || t('TextForm.Action Label')}</div>
                </Link>
              ) : (
                <div css={styles.imageTypeContainer(imageUrl)} key={index}>
                  {!imageUrl && <Icomoon icon="image-grey-light" size={72} />}
                  <div>{label || t('TextForm.Action Label')}</div>
                </div>
              );
            })}
          </Slider>
        </ContentContainer>
      );
    case LineMessageType.PLAIN_TEXT:
      return (
        <ItemContainer>
          {error && <Icomoon icon="warning-grey" />}
          <ContentContainer>
            {error && <ErrorMessage message={'broadcastErrorMessage'} />}
            <PlainTextContainer isEmpty={!plainTextMessage?.text}>
              {plainTextMessage?.text ||
                t('Placeholder.Use this text to share information about your store with your customers')}
            </PlainTextContainer>
          </ContentContainer>
        </ItemContainer>
      );
    default:
      return (
        <ItemContainer>
          {error && <Icomoon icon="warning-grey" />}
          <ContentContainer>
            {error && <ErrorMessage message={'broadcastErrorMessage'} />}
            <RichMessageContainer isEmpty={!richMessage?.imageUrl}>
              {richMessage?.imageUrl ? (
                validator.isURL(richMessage.url || '') ? (
                  <Link rel="noopener noreferrer" target="_blank" to={{ pathname: richMessage.url }}>
                    <RichMessageImage src={richMessage.imageUrl} />
                  </Link>
                ) : (
                  <RichMessageImage src={richMessage.imageUrl} />
                )
              ) : (
                <Icomoon icon="image-grey-light" size={56} />
              )}
            </RichMessageContainer>
          </ContentContainer>
        </ItemContainer>
      );
  }
};

const ButtonTypeContainer = styled.div<{ background: string; isAction: boolean }>`
  background-color: ${({ isAction }) => (isAction ? '#fff' : '#f6f8fa')};
  border: 2px solid #eef3f7;
  border-radius: 15px;
  min-height: 333px;
  width: 262px;

  & > div:nth-of-type(1) {
    align-items: center;
    background-color: #eef3f7;
    background-image: url(${({ background }) => background});
    background-position: center;
    background-size: cover;
    border-radius: 15px 15px 0 0;
    display: flex;
    height: 174px;
    justify-content: center;
    width: 100%;
  }

  & > div:nth-of-type(2) {
    border-radius: 0 15px 15px 15px;
    padding: 24px 18px;
  }
`;

const ButtonTypeFont = styled.div<{ fontSize: string; isBold: boolean; isEmpty: boolean }>`
  color: ${({ isEmpty }) => (isEmpty ? '#27313B' : '#dee5ec')};
  font-size: ${({ fontSize }) => `${fontSize}px`};
  font-weight: ${({ isBold }) => (isBold ? '600' : '400')};
  margin-bottom: 12px;
`;

const ContentContainer = styled.div`
  & > p {
    font-size: 10px;
    font-weight: 700;
    margin-bottom: 4px;
  }
`;

const ItemContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  margin-bottom: 24px;
`;

const PlainTextContainer = styled.div<{ isEmpty: boolean }>`
  background-color: ${({ isEmpty }) => (isEmpty ? '#eef3f7' : '#fff')};
  border-radius: 15px;
  color: ${({ isEmpty }) => (isEmpty ? '#c5d0da' : '#27313b')};
  padding: 12px;
  white-space: pre-line;
  word-break: break-word;
`;

const RichMessageContainer = styled.div<{ isEmpty: boolean }>`
  align-items: center;
  background-color: ${({ isEmpty }) => (isEmpty ? '#eef3f7' : '#b3b7ba')};
  border-radius: 15px;
  display: flex;
  height: 330px;
  justify-content: center;
  width: 330px;
`;

const RichMessageImage = styled.img`
  height: 330px;
  object-fit: contain;
  width: 100%;
`;

const styles = {
  actionLabel: (label: string) => css`
    align-items: center;
    color: ${label ? '#80a0d3' : '#dee5ec'};
    display: flex;
    font-size: 16px;
    font-weight: 600;
    height: 48px;
    justify-content: center;
    width: 100%;
  `,
  imageTypeContainer: (background?: string) => css`
    align-items: center;
    background-color: #eef3f7;
    background-image: url(${background});
    background-position: center;
    background-size: cover;
    border-radius: 15px;
    display: flex !important;
    height: 262px;
    justify-content: center;
    position: relative;
    width: 262px;

    /* stylelint-disable no-descending-specificity */
    & > div {
      background-color: ${background ? '#000' : '#dee5ec'};
      border-radius: 44px;
      bottom: 24px;
      color: ${background ? '#fff' : '#c5d0da'};
      font-size: 16px;
      font-weight: 600;
      opacity: ${background ? '0.7' : '1'};
      overflow: hidden;
      padding: 10px 8px;
      position: absolute;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 147px;
    }
  `,
};

export default PreviewItem;
