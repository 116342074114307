import styled from '@emotion/styled';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CheckBoxForSelectAll } from '@src/components/atoms/CheckBox';
import { DisabledProps } from '../types';

interface SelectAllProps {
  className?: string;
  disabled?: boolean;
  isSelectedAll: boolean;
  isSelectedNoting: boolean;
  handleClick: (e: React.MouseEvent<any>) => void;
}

const SelectAll = (props: SelectAllProps) => {
  const { className, disabled, isSelectedAll, isSelectedNoting, handleClick } = props;

  return (
    <Wrapper className={className} disabled={disabled} onClick={handleClick}>
      <CheckBoxForSelectAll
        key={uuidv4()}
        label="Select all"
        name="Select all"
        handleClick={handleClick}
        disabled={disabled}
        isCheckedAll={isSelectedAll}
        isCheckedNothing={isSelectedNoting}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div<DisabledProps>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  background-color: white;
  border: 1px solid #179cd7;
  padding: 10px;
  border-bottom: 1px solid #e4e4e4;

  .checkbox-root {
    border-radius: 2px;
  }
`;

export default SelectAll;
