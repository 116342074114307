import styled from '@emotion/styled';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { ViewportBreakpoint } from '@src/libs/theme';

interface WrapperProps {
  footerPadding?: number;
  isDesktop?: boolean;
}

const IframeLayout: React.FC<WrapperProps> = props => {
  const { children, footerPadding } = props;
  const isDesktop = useMediaQuery({ minWidth: ViewportBreakpoint.DESKTOP });

  return (
    <Container>
      <Wrapper footerPadding={footerPadding} isDesktop={isDesktop}>
        <PageContent>
          <MainContent>
            <MainContentCentered>{children}</MainContentCentered>
          </MainContent>
        </PageContent>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  min-height: 100%;
  background-color: #27313b;
  flex-direction: column;
`;

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  flex: 1;
`;
const MainContent = styled.div`
  display: flex;
  flex: 1;
  background-color: #f6f8fa;
`;
const MainContentCentered = styled.div`
  width: 100%;
`;
const PageContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export default IframeLayout;
