import React from 'react';
import { css } from '@emotion/react';
import RecipientList from './RecipientList';
import RecipientListFilter from './RecipientListFilter';
import useFilter from './useFilter';

interface RecipientProps {
  emailId: string;
}

const Recipient = ({ emailId }: RecipientProps) => {
  const { filter, setFilter } = useFilter();

  return (
    <div css={styles.container}>
      <RecipientListFilter values={filter} onChange={setFilter} />
      <RecipientList emailId={emailId} filter={filter} />
    </div>
  );
};

const styles = {
  container: css`
    background-color: #fff;
    border-radius: 0 0 3px 3px;
    box-shadow: 0 2px 1px rgba(110, 124, 137, 0.05), 0 0 2px rgba(110, 124, 137, 0.25);
    padding: 24px;
  `,
};

export default Recipient;
