import React, { MediaHTMLAttributes } from 'react';
import emptyVideo from '../../../assets/img/videoThumbnail.png';

interface VideoPreviewProps {
  url: string | null;
  width?: string;
  height?: string;
  preload?: MediaHTMLAttributes<HTMLVideoElement>['preload'];
  className?: string;
}
const VideoPreview = ({ url, width = '160', height = '160', preload = 'metadata', className }: VideoPreviewProps) =>
  url ? (
    <video width={width} height={height} preload={preload} className={className}>
      <source src={`${url}#t=1`} type="video/mp4" />
    </video>
  ) : (
    <img src={emptyVideo} width={width} height={height} style={{ objectFit: 'contain' }} className={className} />
  );

export default VideoPreview;
