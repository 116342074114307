import { format as formatDate, getDaysInMonth } from 'date-fns';
import range from 'lodash/range';
import React, { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import SingleSelector from '../Selector/SingleSelector';

interface YearMonthDayPickerProps {
  className?: string;
  disabled?: boolean;
  error?: boolean;
  format?: string;
  maxDate?: Date;
  minDate?: Date;
  value?: Date | string;
  onChange?: (date: Date | string | null) => void;
}

const YearMonthDayPicker = ({
  className,
  disabled,
  error,
  format,
  maxDate,
  minDate,
  value,
  onChange,
}: YearMonthDayPickerProps) => {
  const [selectedDay, setSelectedDay] = useState<string>('');
  const [selectedMonth, setSelectedMonth] = useState<string>('');
  const [selectedYear, setSelectedYear] = useState<string>('');

  const today = new Date();
  const currentYear = today.getFullYear();

  const days = useMemo(() => {
    if (selectedYear && selectedMonth) {
      const dayList = range(getDaysInMonth(new Date(Number(selectedYear), Number(selectedMonth) - 1)))
        .filter(day => {
          let status = true;

          // to check available days if selected minDate/maxDate year & month
          if (
            minDate &&
            minDate.getFullYear() === Number(selectedYear) &&
            minDate.getMonth() === Number(selectedMonth) - 1
          ) {
            status = minDate.getDate() <= day + 1;
          }
          if (
            status &&
            maxDate &&
            maxDate.getFullYear() === Number(selectedYear) &&
            maxDate.getMonth() === Number(selectedMonth) - 1
          ) {
            status = maxDate.getDate() > day;
          }

          return status;
        })
        .map(day => {
          const val = leftPad(day + 1);

          return { label: val, value: val };
        });

      const isInSelection = dayList.find(day => day.value === selectedDay);
      if (!isInSelection) {
        setSelectedDay('');
      }

      return dayList;
    }

    return [];
  }, [selectedYear, selectedMonth]);

  const months = useMemo(() => {
    if (selectedYear) {
      const monthList = range(12)
        .filter(month => {
          let status = true;

          // to check available months if selected minDate/maxDate year
          if (minDate && minDate.getFullYear() === Number(selectedYear)) {
            status = month >= minDate.getMonth();
          }
          if (status && maxDate && maxDate.getFullYear() === Number(selectedYear)) {
            status = month <= maxDate.getMonth();
          }

          return status;
        })
        .map(month => {
          const val = leftPad(month + 1);

          return { label: val, value: val };
        });

      const isInSelection = monthList.find(month => month.value === selectedMonth);
      if (!isInSelection) {
        setSelectedDay('');
        setSelectedMonth('');
      }

      return monthList;
    }

    return [];
  }, [selectedYear]);

  const years = range(currentYear - 70, currentYear + 70)
    .filter(year => {
      let status = true;

      // to check available years between minDate & maxDate
      if (minDate) {
        status = minDate.getFullYear() <= year;
      }
      if (status && maxDate) {
        status = maxDate.getFullYear() >= year;
      }

      return status;
    })
    .map(year => {
      const val = year.toString();

      return { label: val, value: val };
    });

  useEffect(() => {
    const isValidateDate = value && new Date(value);
    if (isValidateDate) {
      setSelectedDay(leftPad(new Date(value!).getDate()));
      setSelectedMonth(leftPad(new Date(value!).getMonth() + 1));
      setSelectedYear(new Date(value!).getFullYear().toString());
    }
  }, [value]);

  useEffect(() => {
    if (onChange) {
      if (selectedYear && selectedMonth && selectedDay) {
        const date = new Date([selectedYear, selectedMonth, selectedDay].join('-'));
        onChange(format ? formatDate(date, format) : date);
      } else {
        onChange(format ? '' : null);
      }
    }
  }, [selectedYear, selectedMonth, selectedDay]);

  function leftPad(val: number) {
    return val.toString().padStart(2, '0');
  }

  return (
    <div className={className} css={styles.container}>
      <SingleSelector
        autoSelectMatched
        css={styles.yearDropdown}
        disabled={disabled}
        error={error}
        name="year"
        options={years}
        placeholder="Year"
        onChange={val => setSelectedYear(val)}
        value={selectedYear}
      />
      <SingleSelector
        autoSelectMatched
        css={styles.monthDropdown}
        disabled={disabled || !selectedYear}
        error={error}
        name="month"
        options={months}
        placeholder="Month"
        onChange={val => setSelectedMonth(val)}
        value={selectedMonth}
      />
      <SingleSelector
        autoSelectMatched
        css={styles.dayDropdown}
        disabled={disabled || !selectedMonth}
        error={error}
        name="day"
        options={days}
        placeholder="Day"
        onChange={val => setSelectedDay(val)}
        value={selectedDay}
      />
    </div>
  );
};

const styles = {
  container: css`
    display: flex;
  `,
  dayDropdown: css`
    border-radius: 3px;
  `,
  monthDropdown: css`
    border-radius: 3px;
  `,
  yearDropdown: css`
    border-radius: 3px;
  `,
};

export default YearMonthDayPicker;
