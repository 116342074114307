import styled from '@emotion/styled';
import React, { MouseEventHandler } from 'react';
import { CheckBox } from '../CheckBox';

interface HeaderCheckboxColumnProps {
  checked: boolean;
  onCheckedChange?: (checked: boolean) => void;
  handleClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  className?: string;
  fontSizeCheckbox?: string;
  isTable?: boolean;
  indeterminate?: boolean;
}

const HeaderCheckboxColumn = (props: HeaderCheckboxColumnProps) => {
  const {
    className,
    checked,
    handleClick,
    onCheckedChange,
    disabled = false,
    fontSizeCheckbox,
    isTable = true,
    indeterminate,
  } = props;
  const Wrapper = isTable ? TableWrapper : DivWrapper;

  return (
    <Wrapper className={className}>
      <CheckBox
        checked={checked}
        onClick={handleClick}
        onCheckedChange={onCheckedChange}
        disabled={disabled}
        indeterminate={indeterminate}
        size={fontSizeCheckbox}
      />
    </Wrapper>
  );
};

const TableWrapper = styled.th``;
const DivWrapper = styled.div``;

export default HeaderCheckboxColumn;
