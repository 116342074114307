import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useCopy } from '@src/libs/hooks/useCopy';
import { ThemeButton } from '@src/components/atoms/Button';
import { useQuery } from '@apollo/client';
import Textarea from '@src/components/atoms/Textarea';
import Label from '@src/components/atoms/Label';
import { css } from '@emotion/react';
import { useQueryHelper } from '@src/libs/hooks';
import { GetLinePublicKey } from '../queries/__generated__/GetLinePublicKey';
import * as GET_LINE_PUBLIC_KEY from '../queries/GetLinePublicKey.graphql';

interface FormValues {
  publicKeyId: string;
}

const Step2 = ({ setValue }: UseFormReturn<FormValues>) => {
  const { handleCopyHasText, isCopying } = useCopy();
  const { t, enqueueSnackbar } = useQueryHelper();
  const { data, loading } = useQuery<GetLinePublicKey>(GET_LINE_PUBLIC_KEY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    onCompleted: ({ getLinePublicKey }) => {
      setValue('publicKeyId', String(getLinePublicKey?.id));
    },
  });

  return (
    <div>
      <Label title={t('Public Key')} css={styles.label} />
      <Textarea value={data?.getLinePublicKey?.publicKey || ''} placeholder="" readOnly />
      <ThemeButton
        css={styles.copyBtn}
        disabled={isCopying || loading || !data?.getLinePublicKey?.publicKey}
        text="Copy"
        width="fit-content"
        onClick={() => handleCopyHasText(data?.getLinePublicKey?.publicKey || '')}
      />
    </div>
  );
};
export default Step2;

const styles = {
  label: css`
    font-size: 14px;
    padding-left: 0;
  `,
  copyBtn: css`
    margin-top: 16px;
    min-width: 80px;
  `,
};
