import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SocialButton, ThemeButton } from '@src/components/atoms/Button';
import Footer from '@src/components/organisms/TopPage/Footer';
import Header from '@src/components/organisms/TopPage/Header';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { useQueryHelper, useRedirectWithinIframe } from '@src/libs/hooks';
import { useRedirectUrl } from '@src/libs/hooks/useRedirectUrl';
import { ViewportType } from '@src/libs/theme';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import { useHelpCenterUrl } from '@src/libs/help';
import useSignInInstagram from './useSignInInstagramLine';

interface IgLoginFormProps {
  lineIdToken: string;
}

const InstagramLoginForm = ({ lineIdToken }: IgLoginFormProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { enqueueSnackbar, t } = useQueryHelper();
  const { setLineIdToken, signInInstagramLine } = useSignInInstagram();
  const { getRedirectUrl } = useRedirectUrl(FE_REDIRECT_MAPPING.LINE_INSTAGRAM_SIGNIN);
  const { startRedirectProcess } = useRedirectWithinIframe();
  const { switchBusinessAccUrl } = useHelpCenterUrl();

  const onClickConnectWithInstagram = async () => {
    setIsLoading(true);
    setLineIdToken(lineIdToken);

    try {
      const redirectUrl = await getRedirectUrl(SocialAccountType.INSTAGRAM);
      if (redirectUrl) {
        startRedirectProcess({ redirectUrl, redirectType: FE_REDIRECT_MAPPING.SIGNIN });
      }
    } catch (error) {
      console.error('error:', error);
      enqueueSnackbar(t('UnexpectedError'), { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const onClickSkip = () => {
    if (lineIdToken) {
      signInInstagramLine({
        variables: {
          input: {
            fbOauth: null,
            lineIDToken: lineIdToken,
          },
        },
      });
    }
  };

  return (
    <div css={styles.container}>
      <Header />
      <div css={styles.contentContainer}>
        <div css={{ width: 348 }}>
          <div css={styles.contentTitle}>{t('Let’s connect with Instagram')}</div>
          <div css={styles.contentDescription}>
            {t('Annotation.You will receive more campaigns when you connect with Instagram')}
          </div>
          <SocialButton
            css={styles.instagramBtn}
            disabled={isLoading}
            hasIcon
            socialMedia={SocialAccountType.INSTAGRAM}
            title="Connect with Instagram"
            onClick={onClickConnectWithInstagram}
          />
          <ThemeButton
            css={styles.skipBtn}
            disabled={isLoading}
            width="max-content"
            text="Skip it for now"
            onClick={onClickSkip}
          />
          <div>
            <Hint>*{t('Annotation.Switching to a business account (free) is required to link Instagram')}&nbsp;</Hint>
            <Link
              css={styles.learnSwitchBusinessAcc}
              rel="noopener noreferrer"
              target="_blank"
              to={{ pathname: switchBusinessAccUrl }}
            >
              {t('Click here to learn how to switch to a business account')}
            </Link>
          </div>
          <Hint>*{t('Annotation.We will not post to your account')}</Hint>
          <br />
          <Hint>*{t('Annotation.Data will not be used for any purpose other than campaigns')}</Hint>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const Hint = styled.span`
  color: #6e7c89;
  font-size: 12px;
`;

const styles = {
  container: css`
    align-items: center;
    display: grid;
    justify-content: center;
  `,
  contentContainer: css`
    align-items: center;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px #dee5ec, 0 1px 4px rgba(222, 229, 236, 0.5);
    display: flex;
    height: 520px;
    justify-content: center;
    width: 856px;
  `,
  contentDescription: css`
    color: #27313b;
    font-size: 14px;
    margin-bottom: 16px;
    text-align: center;
  `,
  contentTitle: css`
    color: #27313b;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 24px;
    text-align: center;
  `,
  instagramBtn: css`
    background-image: linear-gradient(89.37deg, #ebb752 0.07%, #ff6641 31.78%, #e83262 65.06%, #ce3396 99.89%);
    border-radius: 3px;
    height: 32px;
    margin-bottom: 16px;

    @media (max-width: ${ViewportType.TABLET}px) {
      border-radius: 5px;
      height: 40px;
    }
  `,
  learnSwitchBusinessAcc: css`
    color: #3892e5;
    font-size: 12px;
  `,
  skipBtn: css`
    border: 1px solid #dee5ec;
    border-radius: 3px;
    color: #6e7c89;
    filter: brightness(1);
    font-size: 12px;
    font-weight: 600;
    height: 32px;
    margin-bottom: 16px;

    @media (max-width: ${ViewportType.TABLET}px) {
      border-radius: 5px;
      height: 40px;
    }
  `,
};

export default InstagramLoginForm;
