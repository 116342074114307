import { useQuery } from '@apollo/client';
import { useAuthData, useQueryHelper } from '@src/libs/hooks';
import { UserRoles } from '@src/__generated__/globalTypes';
import GET_AVAILABLE_INFLUENCERS_FOR_STAFF from './queries/GetAvailableInfluencersForStaff.graphql';
import GET_UNREAD_NOTI_COUNT from './queries/GetUnreadNotificationCount.graphql';
import { GetAvailableInfluencersForStaff } from './queries/__generated__/GetAvailableInfluencersForStaff';
import { GetUnreadNotificationCount } from './queries/__generated__/GetUnreadNotificationCount';

const useMenuQuery = () => {
  const { role } = useAuthData();
  const { enqueueSnackbar, t } = useQueryHelper();

  const { data: dataNotification } = useQuery<GetUnreadNotificationCount>(GET_UNREAD_NOTI_COUNT, {
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const { data: dataInfluencers } = useQuery<GetAvailableInfluencersForStaff>(GET_AVAILABLE_INFLUENCERS_FOR_STAFF, {
    skip: role !== UserRoles.CREATOR_STAFF,
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  return {
    availableInfluencers: dataInfluencers?.getAvailableInfluencersForStaff?.influencers || [],
    notificationCount: dataNotification?.countUnreadNotifications?.count || 0,
  };
};

export default useMenuQuery;
