import { atom } from 'recoil';

interface SearchJobState {
  campaignId: number | null;
}

export const searchJobState = atom<SearchJobState>({
  key: 'searchJobState',
  default: { campaignId: null },
});
