import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { arrowTopLeft, arrowTopRight } from '@src/assets/htmlCodes';
import Switch from '@src/components/molecules/Switch';
import { useDirLayout } from '@src/libs/hooks';
import { defaultWidth } from './EditAccess';

export { defaultWidth };

interface ManageAccessProps {
  isMobile?: boolean;
  width?: number;
}

const ManageAccess = ({ isMobile = false, width = defaultWidth }: ManageAccessProps) => {
  const { t } = useTranslation();
  const { isRtl } = useDirLayout();
  const quotient = width / defaultWidth;

  return (
    <div css={styles.container(quotient, isMobile)}>
      <div css={styles.title(quotient)}>{t('Title.Manage access')}</div>
      <div css={styles.information(quotient)}>
        <div>{t('Annotation.Read your profile info')}</div>
        <div>{t('Required')}</div>
      </div>
      <div css={[styles.information(quotient), styles.highlighted(quotient)]}>
        <div>{t('Annotation.Read your public videos on TikTok without quote symbol')}</div>
        <Switch css={styles.switch(quotient)} customBgColor="#66dca0" isChecked labelOn="" handleChange={() => null} />
      </div>
      <span css={styles.arrow(quotient)}>{isRtl ? arrowTopLeft : arrowTopRight}</span>
    </div>
  );
};

const styles = {
  arrow: (quotient: number) => css`
    color: #ff5f5f;
    font-family: 'Proxima Nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: ${quotient * 65}px;
    right: ${quotient * 35}px;
    position: absolute;
    top: ${quotient * 70}px;

    [dir='rtl'] & {
      left: ${quotient * 35}px;
      right: unset;
    }
  `,
  container: (quotient: number, isMobile: boolean) => css`
    background-color: #fff;
    border: 1px solid #dee5ec;
    border-radius: 5px;
    height: ${quotient * (isMobile ? 102 : 288)}px;
    padding: ${quotient * 16}px;
    position: relative;
    width: ${quotient * 213}px;
  `,
  highlighted: (quotient: number) => css`
    border: ${quotient * 4}px solid #ff5f5f;
    border-radius: 5px;
    left: ${quotient * 5}px;
    padding: ${quotient * 8}px;
    position: absolute;
    width: 85%;
  `,
  information: (quotient: number) => css`
    align-items: center;
    display: flex;

    & > div:nth-of-type(1) {
      color: #27313b;
      display: flex;
      flex: 1;
      font-size: ${quotient * 6}px;
    }

    & > div:nth-of-type(2) {
      color: #9d9d9e;
      font-size: ${quotient * 6}px;
    }
  `,
  switch: (quotient: number) => css`
    & > button {
      height: ${quotient * 9}px;
      width: ${quotient * 17}px;

      & > span {
        height: ${quotient * 8}px;
        transform: translate(${quotient * 3}px) !important;
        width: ${quotient * 8}px;

        [dir='rtl'] & {
          transform: translate(${quotient * -8}px) !important;
        }
      }
    }
  `,
  title: (quotient: number) => css`
    color: #27313b;
    font-size: ${quotient * 12}px;
    font-weight: 600;
    margin-bottom: ${quotient * 12}px;
    text-align: center;
  `,
};

export default ManageAccess;
