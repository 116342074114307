import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import send from '@src/assets/icon/chat/send.svg';
import { ThemeButton } from '@src/components/atoms/Button';
import TextForm from '@src/components/molecules/TextForm';
import { usePageLayout } from '@src/libs/hooks';

export interface FormValues {
  comment: string;
}

const AddCommentInput = () => {
  const { t } = useTranslation();
  const { isMobileView } = usePageLayout();
  const {
    formState: { errors, isSubmitting },
    register,
    watch,
  } = useFormContext<FormValues>();
  const comment = watch('comment');

  return (
    <Container isMobileView={isMobileView}>
      <TextForm error={!!errors.comment} placeholder={t('Comment')} {...register('comment')} />
      <ThemeButton
        theme="blue"
        disabled={!comment || isSubmitting}
        prefixIcon={isMobileView ? <img alt="sendIcon" height="24" src={send} width="24" /> : <div />}
        text={!isMobileView ? 'Post' : ''}
        fontSize="12px"
        type="submit"
      />
    </Container>
  );
};

const Container = styled.div<{ isMobileView: boolean }>`
  background-color: #fff;
  border: 1px solid #dee5ec;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
  padding: 8px;
  z-index: 10;
  gap: 8px;
  ${({ isMobileView }) =>
    isMobileView ? 'bottom: -8px; left: -16px; position: absolute; width: calc(100% + 16px);' : ''}

  & > div {
    display: flex;
    flex: 1;

    & > div {
      width: 100%;
    }
  }

  & > button {
    border-radius: 3px;
    font-size: 12px;
    font-weight: 600;
    height: 32px;
    width: ${({ isMobileView }) => (isMobileView ? '56' : '59')}px;
  }
`;

export default AddCommentInput;
