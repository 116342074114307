import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FanDetailsSchema } from '@src/libs/validation';
import { useDeepCompareEffect, usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { ListIndicator } from '@src/components/molecules/Indicator';
import Empty from '@src/components/atoms/Empty';
import FanDetailsForm, { FormValues } from './FanDetailsForm';
import useGetFan from './useGetFan';
import { getInitialFormValues, getUpdateFanInput } from './helper';

const FanDetails = () => {
  const { params, path } = useQueryHelper();
  const { isMobileView } = usePageLayout();
  const { id } = params as { id: string };

  const { data, error, loading, updateFan } = useGetFan({ fanId: Number(id) });

  const defaultValues = getInitialFormValues(data?.getFan);
  const methods = useForm<FormValues>({
    defaultValues,
    resolver: yupResolver(FanDetailsSchema),
  });

  useDeepCompareEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues]);

  const onSubmit = async (payload: FormValues) => {
    const input = getUpdateFanInput(Number(id), payload);
    await updateFan({
      variables: {
        input,
      },
    });
  };

  if (path === '/fans/:id' && isMobileView) {
    // Nothing to return. Only show fan navigation tabs from parent.
    return null;
  }

  if (loading) {
    return <ListIndicator />;
  }

  if (error?.message.includes("fan doesn't exist") || !data?.getFan) {
    return <Empty />;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FanDetailsForm editable={data.getFan.editable} />
      </form>
    </FormProvider>
  );
};

export default FanDetails;
