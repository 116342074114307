import React, { useState, useRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import Avatar from '@src/components/atoms/Avatar';
import { ThemeButton } from '@src/components/atoms/Button';
import { localizedDateFormatter } from '@src/components/atoms/List/DateColumn';
import TextCutter from '@src/components/atoms/TextCutter';
import { DialogModal } from '@src/components/molecules/DialogModal';
import { formatIntNumber, formatNumberWithCommas } from '@src/libs/format';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import { switchImage, switchImageWhite, switchSocialBackgroundColor, switchText } from '@src/libs/SocialMedia';
import { ViewportType } from '@src/libs/theme';
import { searchJobState as searchJobRecoilState, useRecoilState } from '@src/recoilAtoms';
import { generatePath, ROUTES } from '@src/shared/routes';
import {
  CampaignPromotionMethod,
  CampaignSocialMediaType,
  PromotionMobileAppType,
  SocialAccountType,
} from '@src/__generated__/globalTypes';
import useJoinablePromotionMethods from './useJoinablePromotionMethods';

interface PromotionMethods {
  avatar?: string;
  description?: string;
  email?: string;
  followersCount?: number;
  id: string;
  mobileAppType?: PromotionMobileAppType;
  name?: string;
  title?: string;
  type: CampaignPromotionMethod | SocialAccountType;
  url?: string;
}

enum DialogStatus {
  CLOSE = 'CLOSE',
  CHOOSE_PROMOTION_METHODS = 'CHOOSE_PROMOTION_METHODS',
  TERMS_CONDITIONS = 'TERMS_CONDITIONS',
}

interface AffiliateJoinButtonProps {
  campaignDetails: {
    currency: string;
    endDate: any;
    id: number;
    maximumRevenuePerInfluencer: number | null;
    minimumPaymentAmount: number;
    socialMedias: CampaignSocialMediaType[];
    startDate: any;
  };
}

const AffiliateJoinButton = ({ campaignDetails }: AffiliateJoinButtonProps) => {
  const {
    currency,
    endDate,
    id: campaignId,
    maximumRevenuePerInfluencer,
    minimumPaymentAmount,
    socialMedias,
    startDate,
  } = campaignDetails;
  const [dialog, setDialog] = useState<DialogStatus>(DialogStatus.CLOSE);
  const [isJoining, setIsJoining] = useState<boolean>(false);
  const [methodIds, setMethodIds] = useState<string[]>([]);
  const dialogContent = useRef<HTMLDivElement | null>(null);
  const [searchJobState, setSearchJobState] = useRecoilState(searchJobRecoilState);
  const { enqueueSnackbar, history, i18n, t } = useQueryHelper();
  const { isMobileView } = usePageLayout();
  const { data, joinMarletplaceAffiliate } = useJoinablePromotionMethods();
  const isCampaignSocialMedias = !!socialMedias.find(socialMedia => socialMedia !== CampaignSocialMediaType.UNSELECT);

  const marketplaceJoinablePromotionMethods = data?.marketplaceJoinablePromotionMethods;
  const joinableMethods = (() => [
    ...([CampaignSocialMediaType.FACEBOOK, CampaignSocialMediaType.UNSELECT].some(type =>
      socialMedias.includes(type)
    ) && marketplaceJoinablePromotionMethods?.facebookAccount
      ? [{ ...marketplaceJoinablePromotionMethods.facebookAccount, type: SocialAccountType.FACEBOOK }]
      : []),
    ...([CampaignSocialMediaType.FACEBOOK, CampaignSocialMediaType.UNSELECT].some(type =>
      socialMedias.includes(type)
    ) && marketplaceJoinablePromotionMethods?.facebookPages
      ? marketplaceJoinablePromotionMethods.facebookPages.map(page => ({
          ...page,
          type: SocialAccountType.FACEBOOK,
        }))
      : []),
    ...([
      CampaignSocialMediaType.INSTAGRAM,
      CampaignSocialMediaType.INSTAGRAM_STORY,
      CampaignSocialMediaType.UNSELECT,
    ].some(type => socialMedias.includes(type)) && marketplaceJoinablePromotionMethods?.instagramAccounts
      ? marketplaceJoinablePromotionMethods.instagramAccounts.map(account => ({
          ...account,
          type: SocialAccountType.INSTAGRAM,
        }))
      : []),
    ...([CampaignSocialMediaType.TIKTOK, CampaignSocialMediaType.UNSELECT].some(type => socialMedias.includes(type)) &&
    marketplaceJoinablePromotionMethods?.tiktokAccounts
      ? marketplaceJoinablePromotionMethods.tiktokAccounts.map(account => ({
          ...account,
          type: SocialAccountType.TIKTOK,
        }))
      : []),
    ...([CampaignSocialMediaType.TWITTER, CampaignSocialMediaType.UNSELECT].some(type => socialMedias.includes(type)) &&
    marketplaceJoinablePromotionMethods?.twitterAccounts
      ? marketplaceJoinablePromotionMethods.twitterAccounts.map(account => ({
          ...account,
          type: SocialAccountType.TWITTER,
        }))
      : []),
    ...([CampaignSocialMediaType.YOUTUBE, CampaignSocialMediaType.UNSELECT].some(type => socialMedias.includes(type)) &&
    marketplaceJoinablePromotionMethods?.youtubeAccounts
      ? marketplaceJoinablePromotionMethods.youtubeAccounts.map(account => ({
          ...account,
          type: SocialAccountType.YOUTUBE,
        }))
      : []),
    ...(socialMedias.includes(CampaignSocialMediaType.UNSELECT) && marketplaceJoinablePromotionMethods?.emailNewsLetters
      ? marketplaceJoinablePromotionMethods.emailNewsLetters.map(emailNewsLetter => ({
          ...emailNewsLetter,
          title: 'Email / Newsletter',
          type: CampaignPromotionMethod.EMAIL_NEWSLETTER,
        }))
      : []),
    ...(socialMedias.includes(CampaignSocialMediaType.UNSELECT) && marketplaceJoinablePromotionMethods?.mobileApps
      ? marketplaceJoinablePromotionMethods.mobileApps.map(mobileApp => ({
          ...mobileApp,
          title: 'Mobile App',
          type: CampaignPromotionMethod.MOBILE_APP,
        }))
      : []),
    ...(socialMedias.includes(CampaignSocialMediaType.UNSELECT) && marketplaceJoinablePromotionMethods?.offlines
      ? marketplaceJoinablePromotionMethods.offlines.map(offline => ({
          ...offline,
          title: 'Offline / Others',
          type: CampaignPromotionMethod.OFFLINE,
        }))
      : []),
    ...(socialMedias.includes(CampaignSocialMediaType.UNSELECT) && marketplaceJoinablePromotionMethods?.podCasts
      ? marketplaceJoinablePromotionMethods.podCasts.map(podcast => ({
          ...podcast,
          title: 'Podcast',
          type: CampaignPromotionMethod.PODCAST,
        }))
      : []),
    ...(socialMedias.includes(CampaignSocialMediaType.UNSELECT) && marketplaceJoinablePromotionMethods?.websites
      ? marketplaceJoinablePromotionMethods.websites.map(website => ({
          ...website,
          title: 'Website',
          type: CampaignPromotionMethod.WEBSITE,
        }))
      : []),
  ])() as PromotionMethods[];

  const onClickCard = (methodId: string) => {
    const ids = [...methodIds];
    const index = ids.findIndex(id => id === methodId);
    if (index >= 0) {
      ids.splice(index, 1);
    } else {
      ids.push(methodId);
    }
    setMethodIds(ids);
  };

  const onClickRedirectSocialConnect = () => {
    // to remove dialog lock scroll
    setDialog(DialogStatus.CLOSE);
    setSearchJobState({ ...searchJobState, campaignId });
    // redirect after dialog closed 0.1s
    setTimeout(() => {
      history.push(ROUTES.SETTINGS_SOCIAL_CONNECT);
    }, 100);
  };

  const onClickNext = () => {
    if (dialogContent.current) {
      dialogContent.current.scrollIntoView();
    }

    if (dialog === DialogStatus.CHOOSE_PROMOTION_METHODS) {
      setDialog(DialogStatus.TERMS_CONDITIONS);
    } else {
      setIsJoining(true);
      joinMarletplaceAffiliate({
        variables: {
          input: {
            campaignId,
            methodIds: methodIds.map(methodId => Number(methodId)),
          },
        },
      })
        .then(() => {
          sendAmplitudeEvent(eventTypes.joinMarketplace, { campaignId });
          history.push(generatePath(ROUTES.MARKETPLACE_YOUR_JOB_ID, { id: campaignId }));
          // to reload the page after redirect
          history.go(0);
        })
        .catch(err => {
          enqueueSnackbar(t(err.message), { variant: 'error' });
        })
        .finally(() => setIsJoining(false));
    }
  };

  const isJoinableMethods = joinableMethods.length > 0;

  return (
    <div>
      <DialogModal
        closeIconCss={{
          backgroundColor: 'transparent',
          color: '#6e7C89',
          right: 0,
          top: 0,
        }}
        contentRef={dialogContent}
        contentStyle={{
          backgroundColor: '#fff',
          borderRadius: 9,
          boxShadow: '0 2px 1px rgba(110, 124, 137, 0.1), 0 3px 5px rgba(110, 124, 137, 0.2)',
          height: isMobileView ? 588 : 432,
          maxWidth: 600,
          width: '90%',
        }}
        footerNode={
          !isJoinableMethods ? (
            isMobileView ? (
              <DialogActionContainer>
                <ThemeButton
                  css={{ height: 48 }}
                  postfixIcon={<Arrow>&#8963;</Arrow>}
                  text="Add promotion methods"
                  theme="blue"
                  onClick={onClickRedirectSocialConnect}
                />
              </DialogActionContainer>
            ) : null
          ) : (
            <DialogActionContainer>
              <ThemeButton text="Close" onClick={() => setDialog(DialogStatus.CLOSE)} />
              <ThemeButton
                disabled={methodIds.length <= 0 || isJoining}
                text="Next"
                theme="blue"
                onClick={onClickNext}
              />
            </DialogActionContainer>
          )
        }
        isOpen={dialog !== DialogStatus.CLOSE}
        closeModal={() => setDialog(DialogStatus.CLOSE)}
      >
        {dialog === DialogStatus.CHOOSE_PROMOTION_METHODS ? (
          isJoinableMethods ? (
            <div css={{ padding: '40px 24px' }}>
              <DialogTitle>{t('Dialog.Which methods do you use to promote')}</DialogTitle>
              <DialogDescription
                css={[
                  !isCampaignSocialMedias
                    ? { borderBottom: '1px solid #eef3f7', marginBottom: 0, paddingBottom: 16 }
                    : { marginBottom: 0 },
                ]}
              >
                {t('Annotation.Please choose Social Account that applies. You can select multiple')}
              </DialogDescription>
              {isCampaignSocialMedias && (
                <CampaignSocialMedias>
                  <div>{t('Annotation.This campaign can be joined by')}</div>
                  <div>
                    {socialMedias.map(socialMedia => (
                      <div css={styles.socialMediaIndicator} key={socialMedia}>
                        {socialMedia === CampaignSocialMediaType.INSTAGRAM_STORY ? (
                          <img height="16" src={switchImage(socialMedia)} width="16" />
                        ) : (
                          <SocialMediaIndicator
                            color={switchSocialBackgroundColor(socialMedia)}
                            isGradient={socialMedia === CampaignSocialMediaType.INSTAGRAM}
                          >
                            <img height="10" src={switchImageWhite(socialMedia)} width="10" />
                          </SocialMediaIndicator>
                        )}
                        <div>{switchText(socialMedia)}</div>
                      </div>
                    ))}
                  </div>
                </CampaignSocialMedias>
              )}

              <div css={styles.cardContainer}>
                {joinableMethods.map(method => {
                  const { avatar, description, email, followersCount, id, name, title, type, url } = method;
                  const isActive = methodIds.includes(id);
                  const isSocialAccount = [
                    SocialAccountType.FACEBOOK,
                    SocialAccountType.INSTAGRAM,
                    SocialAccountType.TIKTOK,
                    SocialAccountType.TWITTER,
                    SocialAccountType.YOUTUBE,
                  ].includes(type as SocialAccountType);

                  return (
                    <Card isActive={isActive} key={id} onClick={() => onClickCard(id)}>
                      {isSocialAccount && (
                        <div css={{ position: 'relative' }}>
                          <Avatar size={40} src={avatar} title={name} />
                          <SocialMediaIndicator
                            color={switchSocialBackgroundColor(type)}
                            isAbsolute
                            isGradient={type === SocialAccountType.INSTAGRAM}
                          >
                            <img height="10" src={switchImageWhite(type as SocialAccountType)} width="10" />
                          </SocialMediaIndicator>
                        </div>
                      )}
                      <div>
                        <div>{isSocialAccount ? name : t(title || '')}</div>
                        <TextCutter
                          lines={1}
                          text={
                            (isSocialAccount && type !== SocialAccountType.TIKTOK
                              ? t('Total followers', { count: formatNumberWithCommas(followersCount, 0) as never })
                              : [
                                  CampaignPromotionMethod.MOBILE_APP,
                                  CampaignPromotionMethod.PODCAST,
                                  CampaignPromotionMethod.WEBSITE,
                                ].includes(type as CampaignPromotionMethod)
                              ? url
                              : type === CampaignPromotionMethod.EMAIL_NEWSLETTER
                              ? email
                              : description) as string
                          }
                        />
                      </div>
                    </Card>
                  );
                })}
              </div>

              <div css={styles.addSocialAccounts}>
                {/* reactjs-popup will auto scroll to anchor tag */}
                <div onClick={onClickRedirectSocialConnect}>
                  <div>{t('Button.Add Social Accounts')}</div>
                  <Arrow>&#8963;</Arrow>
                </div>
              </div>
            </div>
          ) : (
            <div css={styles.emptyJoinableMethods}>
              <div>
                <div>{t('Dialog.Connect your Social Accounts')}</div>
                <div>{t('Annotation.Please connect Social Account from setting page')}</div>

                {isCampaignSocialMedias && (
                  <CampaignSocialMedias>
                    <div>{t('Annotation.This campaign can be joined by')}</div>
                    <div>
                      {socialMedias.map(socialMedia => (
                        <div css={styles.socialMediaIndicator} key={socialMedia}>
                          {socialMedia === CampaignSocialMediaType.INSTAGRAM_STORY ? (
                            <img height="16" src={switchImage(socialMedia)} width="16" />
                          ) : (
                            <SocialMediaIndicator
                              color={switchSocialBackgroundColor(socialMedia)}
                              isGradient={socialMedia === CampaignSocialMediaType.INSTAGRAM}
                            >
                              <img height="10" src={switchImageWhite(socialMedia)} width="10" />
                            </SocialMediaIndicator>
                          )}
                          <div>{switchText(socialMedia)}</div>
                        </div>
                      ))}
                    </div>
                  </CampaignSocialMedias>
                )}

                {!isMobileView && (
                  <div css={{ display: 'flex', justifyContent: 'center', marginTop: 32 }}>
                    <ThemeButton
                      css={{ height: 44, width: 220 }}
                      postfixIcon={<Arrow>&#8963;</Arrow>}
                      text="Add promotion methods"
                      theme="blue"
                      onClick={onClickRedirectSocialConnect}
                    />
                  </div>
                )}
              </div>
            </div>
          )
        ) : (
          <div css={{ padding: '40px 24px' }}>
            <DialogTitle>{t('TermsConditionsPayment')}</DialogTitle>
            <DialogDescription>
              {t('TheCampaignPeriodIs', {
                start: localizedDateFormatter(startDate, 'PPP', i18n.language as AppLanguage),
                end: localizedDateFormatter(endDate, 'PPP', i18n.language as AppLanguage),
              })}
            </DialogDescription>
            {maximumRevenuePerInfluencer && (
              <DialogDescription>
                {t('RevenueLimitGuideLine', { price: formatIntNumber(maximumRevenuePerInfluencer), currency })}
              </DialogDescription>
            )}
            <DialogDescription>
              {t('RevenueQuestionMask', { price: formatIntNumber(minimumPaymentAmount), currency })}
            </DialogDescription>
            <DialogDescription>{t('Terms1')}</DialogDescription>
            <DialogDescription>{t('Terms2')}</DialogDescription>
            <DialogDescription>{t('Terms3')}</DialogDescription>
            <DialogDescription>{t('Terms4')}</DialogDescription>
          </div>
        )}
      </DialogModal>

      <ThemeButton text="Join Now" theme="blue" onClick={() => setDialog(DialogStatus.CHOOSE_PROMOTION_METHODS)} />
    </div>
  );
};

const Arrow = styled.div`
  font-size: 16px;
  font-weight: 600;
  transform: rotate(90deg);
`;

const CampaignSocialMedias = styled.div`
  border-bottom: 1px solid #eef3f7;
  border-top: 1px solid #eef3f7;
  display: grid;
  gap: 4px;
  justify-content: center;
  margin-top: 24px;
  padding: 12px 0;

  @media (max-width: ${ViewportType.TABLET}px) {
    margin-top: 16px;
    padding: 8px 0;
  }

  /* stylelint-disable no-descending-specificity */
  & > div {
    display: flex;
    flex-basis: 100%;
    gap: 16px;
    justify-content: center;
  }

  & > div:nth-of-type(1) {
    color: #27313b;
    font-size: 12px;
  }
`;

const Card = styled.div<{ isActive: boolean }>`
  align-items: center;
  background-color: ${({ isActive }) => (isActive ? '#eaf5ff' : '#fff')};
  border: 1px solid ${({ isActive }) => (isActive ? '#3892e5' : '#c5d0da')};
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  gap: 16px;
  padding: 12px;

  & > div:last-child {
    display: grid;
    gap: 2px;

    & > div:nth-of-type(1) {
      color: ${({ isActive }) => (isActive ? '#3892e5' : '#27313b')};
      font-size: 14px;
      font-weight: 600;
    }

    & > div:nth-of-type(2) {
      color: ${({ isActive }) => (isActive ? '#3892e5' : '#27313b')};
      font-size: 11px;
    }
  }
`;

const DialogActionContainer = styled.div`
  border-top: 1px solid #dee5ec;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  padding: 16px 24px;

  & > button {
    width: 78px;

    & span {
      padding: 0;
    }
  }

  @media (max-width: ${ViewportType.TABLET}px) {
    gap: 16px;
    padding: 16px;

    & > button {
      height: 40px;
      width: fill-available;
    }
  }
`;

const DialogDescription = styled.div`
  color: #27313b;
  font-size: 13px;
  margin-bottom: 16px;
`;

const DialogTitle = styled.div`
  color: #27313b;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 24px;
`;

const SocialMediaIndicator = styled.div<{ color: string; isAbsolute?: boolean; isGradient: boolean }>`
  align-items: center;
  border: 2px solid #fff;
  border-radius: 50%;
  display: flex;
  height: 16px;
  justify-content: center;
  width: 16px;
  ${({ color, isGradient }) => (isGradient ? `background-image: ${color}` : `background-color: ${color}`)};
  ${({ isAbsolute }) => isAbsolute && 'bottom: 0; position: absolute; right: -8px;'}
`;

const styles = {
  addSocialAccounts: css`
    display: flex;
    justify-content: flex-end;

    & > div {
      align-items: center;
      color: #6e7c89;
      cursor: pointer;
      display: flex;
      font-size: 14px;
      font-weight: 600;
    }
  `,
  cardContainer: css`
    display: grid;
    gap: 8px;
    grid-template-columns: 1fr 1fr;
    margin: 24px 0;

    @media (max-width: ${ViewportType.TABLET}px) {
      grid-template-columns: 1fr;
    }
  `,
  emptyJoinableMethods: css`
    align-items: center;
    display: grid;
    height: 100%;
    justify-content: center;

    & > div {
      max-width: 387px;
      padding: 0 16px;
      text-align: center;

      & > div:nth-of-type(1) {
        color: #27313b;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 24px;
      }

      & > div:nth-of-type(2) {
        color: #27313b;
        font-size: 14px;
        white-space: pre-line;
      }
    }
  `,
  socialMediaIndicator: css`
    align-items: center;
    display: flex;
    gap: 4px;

    & > div:last-of-type {
      color: #27313b;
      font-size: 16px;
      font-weight: 600;
    }
  `,
};

export default AffiliateJoinButton;
