import React, { createContext, Dispatch, useReducer } from 'react';
import { InfluencerType, TutorialName, UserRoles } from './__generated__/globalTypes';
import reducer from './Reducer';

export interface StateType {
  readTutorials: TutorialName[];
  title: string;
  href: string;
  userId: number | null;
  role: UserRoles | null;
  creatorType: InfluencerType | null;
  yourJobPageCount: {
    progress: number;
    finished: number;
  };
  globalLayout: {
    hasBottomSection: boolean;
    hasContactForm: boolean;
    isMenuOpen: boolean;
    isDialogOpen: boolean;
    isUuum: boolean;
  };
}

export interface ContextType {
  state: StateType;
  dispatch: Dispatch<any>;
}

export const initialState: StateType = {
  readTutorials: [],
  title: '',
  href: '',
  userId: null,
  role: null,
  creatorType: null,
  yourJobPageCount: {
    progress: 0,
    finished: 0,
  },
  globalLayout: {
    hasContactForm: false,
    hasBottomSection: false,
    isMenuOpen: false,
    isDialogOpen: false,
    isUuum: window.location !== window.parent.location,
  },
};

export const AdminStore = createContext({} as ContextType);

export const AdminStoreProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer<React.Reducer<StateType, any>>(reducer, initialState);
  const value = { state, dispatch };

  return <AdminStore.Provider value={value}>{children}</AdminStore.Provider>;
};
