import styled from '@emotion/styled';
import * as React from 'react';
import { CampaignSocialMediaType, SocialAccountType } from '../../../__generated__/globalTypes';
import { switchImage } from '../../../libs/SocialMedia';

interface SocialMediaColumnProps {
  className?: string;
  socialMedia: CampaignSocialMediaType | SocialAccountType;
  isTable?: boolean;
}

const SocialMediaColumn = (props: SocialMediaColumnProps) => {
  const { className, socialMedia, isTable = true } = props;
  const MediaWrapper = isTable ? TableData : Wrapper;
  const icon = switchImage(socialMedia) || undefined;

  return (
    <MediaWrapper className={className}>
      <Image src={icon} />
    </MediaWrapper>
  );
};

const Wrapper = styled.div`
  padding: 0 12px;
`;

const TableData = styled.td`
  padding: 0 6px;
`;

const Image = styled.img`
  width: 17px;
  margin: 0 auto;
`;

export default SocialMediaColumn;
