import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { useDeepCompareEffect, useQueryHelper } from '@src/libs/hooks';
import yup, {
  BirthdaySchema,
  // BrandSchema,
  CategoriesSchema,
  CountrySchema,
  GenderSchema,
  NameSchema,
  RegionSchema,
} from '@src/libs/validation';
import { Genders } from '@src/__generated__/globalTypes';
import GET_ACCOUNT_INFORMATION from './queries/GetAccountInformation.graphql';
import UPDATE_ACCOUNT_INFORMATION from './mutations/UpdateAccountInformation.graphql';
import { GetAccountInformation } from './queries/__generated__/GetAccountInformation';
import { UpdateAccountInformationVariables } from './mutations/__generated__/UpdateAccountInformation';
import CreatorAccount, { AccountInformation } from './CreatorAccount';

const Index = () => {
  const { enqueueSnackbar, t } = useQueryHelper();
  const validationSchema = yup.object().shape({
    // brandName: BrandSchema,
    categoryIds: CategoriesSchema,
    countryId: CountrySchema,
    dateOfBirth: BirthdaySchema,
    gender: GenderSchema,
    name: NameSchema,
    regionId: RegionSchema,
  });

  const { data, loading } = useQuery<GetAccountInformation>(GET_ACCOUNT_INFORMATION, {
    fetchPolicy: 'no-cache',
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const [updateAccountInformation] = useMutation<UpdateAccountInformationVariables>(UPDATE_ACCOUNT_INFORMATION, {
    onCompleted: () => {
      enqueueSnackbar(t('succeededInUpdating'), { variant: 'success' });
      sendAmplitudeEvent(eventTypes.editSettingsAccount);
    },
    onError: error => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    },
  });

  const onSubmit = async (values: AccountInformation) => {
    updateAccountInformation({
      variables: {
        // brandName exclude for the first release but is required for API
        input: {
          ...values,
          brandName: '',
          categoryIds: values.categoryIds.map(val => Number(val)),
          regionId: Number(values.regionId),
        },
      },
    });
  };

  const accountInfo = data?.getAccountInformation;
  const defaultValues = {
    brandName: accountInfo?.brandName || '',
    categoryIds: accountInfo?.categoryIds || [],
    countryId: accountInfo?.countryId || '',
    dateOfBirth: accountInfo?.dateOfBirth || '',
    gender: accountInfo?.gender || Genders.MALE,
    introduce: accountInfo?.introduce || '',
    name: accountInfo?.name || '',
    regionId: accountInfo?.regionId || 0,
  };
  const methods = useForm<AccountInformation>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  useDeepCompareEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <CreatorAccount isLoading={loading} />
      </form>
    </FormProvider>
  );
};

export default Index;
