import range from 'lodash/range';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import addressCard from '@src/assets/icon/chat/addressCard.svg';
import clipboard from '@src/assets/icon/chat/clipboardLightOrange.svg';
import clock from '@src/assets/icon/chat/clock.svg';
import emptyAlignCenter from '@src/assets/img/emptyAlignCenter.svg';
import emptyAlignStart from '@src/assets/img/emptyAlignStart.svg';

const Empty = () => {
  const { t } = useTranslation();
  const cards = [
    { color: '#dcf3f6', description: `View your fan's information`, icon: addressCard, title: 'Fan Detail' },
    {
      color: '#f0f3f9',
      description: `View your fan’s activities and you can leave comments`,
      icon: clock,
      title: 'Activity',
    },
    {
      color: '#f6f5f3',
      description: `View the fan’s orders or check the shipping or payment status at any time`,
      icon: clipboard,
      title: 'Orders',
    },
  ];

  return (
    <div>
      <div css={styles.headerContainer}>
        <div css={styles.lineInfoContainer}>
          <div>
            <div />
          </div>
          <div>
            <img alt="emptyName" height="26" src={emptyAlignStart} />
          </div>
        </div>

        <div css={styles.customerInfoContainer}>
          {range(4).map(num => (
            <div key={num}>
              <img alt="emptyDetail" height="20" src={emptyAlignCenter} />
            </div>
          ))}
        </div>
      </div>

      <div css={styles.cardsContainer}>
        {cards.map(card => {
          const { color, description, icon, title } = card;

          return (
            <Card color={color} key={title}>
              <div>
                <img alt="icon" height="37" src={icon} width="37" />
              </div>
              <div>
                <span>{t(`MenuTitle.${title}`)}</span>
                <span>{t(`Annotation.${description}`)}</span>
              </div>
            </Card>
          );
        })}
      </div>
    </div>
  );
};

const Card = styled.div<{ color: string }>`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 32px;
  gap: 16px;

  & > div:nth-of-type(1) {
    align-items: center;
    background-color: ${({ color }) => color};
    border-radius: 7px;
    display: flex;
    height: 56px;
    justify-content: center;
    width: 56px;
  }

  & > div:nth-of-type(2) {
    display: grid;
    flex: 1;
    height: fit-content;

    & > span:nth-of-type(1) {
      color: #27313b;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 4px;
    }

    & > span:nth-of-type(2) {
      color: #27313b;
      font-size: 12px;
    }
  }
`;

const styles = {
  cardsContainer: css`
    padding: 30px;
  `,
  customerInfoContainer: css`
    align-items: center;
    border: 1px solid #eef3f7;
    border-radius: 3px;
    display: flex;
    flex-wrap: wrap;
    padding: 8px;

    /* stylelint-disable no-descending-specificity */
    & > div {
      align-items: center;
      box-shadow: 0 0 0 0 #eef3f7, 1px 0 0 0 #eef3f7;
      display: flex;
      flex-basis: 24.5%;
      height: 32px;
      justify-content: center;

      & > img {
        width: 80%;
      }
    }

    & > div:nth-of-type(4) {
      box-shadow: none;
    }
  `,
  headerContainer: css`
    background-color: #fff;
    border-bottom: 1px solid #dee5ec;
    display: grid;
    padding: 16px;
  `,
  lineInfoContainer: css`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 8px;

    & > div:nth-of-type(1) {
      align-items: center;
      background-color: #fff;
      display: flex;
      height: 64px;
      justify-content: center;
      margin-right: 8px;
      width: 64px;

      & > div {
        background-color: #3892e5;
        border-radius: 50%;
        height: 53px;
        opacity: 0.3;
        width: 53px;
      }
    }

    & > div:nth-of-type(2) {
      align-items: center;
      display: flex;
      flex: 1;

      & > img {
        width: 55%;
      }
    }
  `,
};

export default Empty;
