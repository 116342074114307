import styled from '@emotion/styled';
import { LocationDescriptor } from 'history';
import * as React from 'react';
import { Link } from 'react-router-dom';
import anyCreatorBlack from '@src/assets/icon/anyCreatorBlack.svg';
import { APP_NAME } from '@src/libs/constant';

interface HeaderProps extends WrapperProps {
  className?: string;
  href?: LocationDescriptor;
}

const Logo = (props: HeaderProps) => {
  const { className, href, width } = props;

  return (
    <Wrapper className={className} width={width}>
      {href ? (
        <Anchor to={href}>
          <Image src={anyCreatorBlack} alt={`${APP_NAME} Influencer Marketing Platform`} />
        </Anchor>
      ) : (
        <Image src={anyCreatorBlack} alt={`${APP_NAME} Influencer Marketing Platform`} />
      )}
    </Wrapper>
  );
};

interface WrapperProps {
  width?: string;
}

const Wrapper = styled.div<WrapperProps>`
  margin: 0 auto;
  width: ${props => (props.width ? props.width : '100%')};
`;

const Anchor = styled(Link)`
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 0.7;

    @media (max-width: 450px) {
      opacity: 1;
    }
  }
`;

const Image = styled.img`
  width: 100%;
`;

export default Logo;
